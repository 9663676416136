import { Combobox, Dialog, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { useQuery } from '@tanstack/react-query'
import {
	useGetPropertyManagementCompanyDetails,
	useGetUserPermissions
} from 'api/rentalizeComponents'
import classnames from 'classnames'
import type React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'storage/tokens'
import {
	addAccountURL,
	addApplianceURL,
	addBillURL,
	addBudgetURL,
	addBuildingURL,
	addBulkRentIncreaseNoticeURL,
	generatePOListingReportURL,
	addInventoryItemURL,
	viewInventoryItemsURL,
	addInventoryTransactionURL,
	viewInventoryTransactionsURL,
	addDocumentURL,
	addEmailMessageBatchURL,
	addFeeScheduleURL,
	bulkMarkRentAsPaidURL,
	addJournalEntryURL,
	addLeaseAttachmentURL,
	addLeaseURL,
	addLenderURL,
	addMaintenanceTechnicianURL,
	addManagementFeePaymentURL,
	addManagementFeeReceivableURL,
	addMarkupTypeURL,
	addMortgageURL,
	addParkingSpaceURL,
	addPropertyGroupURL,
	addPropertyOwnerURL,
	addRecurringTaskURL,
	addRentPaymentURL,
	addRentReceivableURL,
	addRentalApplicationFormURL,
	addRentalUnitListingURL,
	addRentalUnitURL,
	addResidentURL,
	addResidentVehicleURL,
	addScheduledReportURL,
	addTaskURL,
	addTimeTrackingEntryURL,
	addVehicleURL,
	addVendorURL,
	addWorkOrderURL,
	askRentalizeAIQuestionURL,
	bulkPayBillURL,
	changePasswordURL,
	contactSupportURL,
	currentVacanciesURL,
	generateBalanceSheetURL,
	generateClosedTasksReportURL,
	generateClosedWorkOrdersReportURL,
	generateIncomeStatementURL,
	generateLossToLeaseReportURL,
	generateOpenTasksReportURL,
	generateOpenWorkOrdersReportURL,
	generateRentRollURL,
	generateReportURL,
	generateTransactionRegisterReportURL,
	inviteMaintenanceTechnicianURL,
	invitePropertyManagerURL,
	recordExpenseURL,
	userSettingsURL,
	viewAccountsURL,
	viewAppliancesURL,
	viewBillsURL,
	viewBudgetsURL,
	viewBuildingsURL,
	viewBulkBillPaymentsURL,
	viewBulkRentIncreaseNoticesURL,
	viewDocumentsURL,
	viewEmailMessageBatchesURL,
	viewFeeSchedulesURL,
	viewFormerResidentsURL,
	viewFutureResidentsURL,
	viewJournalEntriesURL,
	viewLeaseAttachmentsURL,
	viewLeasesURL,
	viewLendersURL,
	viewMaintenanceTechniciansURL,
	viewManagementFeePaymentsURL,
	viewManagementFeeReceivablesURL,
	viewMarkupTypesURL,
	viewMortgagesURL,
	viewParkingSpacesURL,
	viewPropertyGroupsURL,
	viewPropertyManagersURL,
	viewPropertyOwnersURL,
	viewRecurringTasksURL,
	viewRentPaymentsURL,
	viewRentReceivablesURL,
	viewRentalApplicantsURL,
	viewRentalApplicationFormsURL,
	viewRentalApplicationsURL,
	viewRentalUnitListingsURL,
	viewRentalUnitsURL,
	viewResidentVehiclesURL,
	viewResidentsURL,
	viewScheduledReportsURL,
	viewTasksURL,
	viewTimeTrackingEntriesURL,
	viewTransactionsURL,
	viewVehiclesURL,
	viewVendorsURL,
	viewWorkOrdersURL,
	loginURL,
	addInvoiceURL,
	viewInvoicesURL,
	addEquipmentURL,
	viewEquipmentURL,
	addEquipmentRentalRateURL,
	viewEquipmentRentalRatesURL,
	bulkGenerateWorkOrderInvoicesURL,
	addBankAccountURL,
	viewBankAccountsURL,
	addBankReconciliationURL,
	viewBankReconciliationsURL,
	viewStorageFacilitiesURL,
	viewStorageUnitsURL,
	viewStorageCustomersURL,
	viewStorageUnitLeasesURL,
	viewStripeAccountsURL,
	addStripeAccountURL,
	generateJournalTransactionsReportURL,
	generateGeneralLedgerReportURL,
	addResidentPetURL,
	viewResidentPetsURL,
	addPurchaseOrderURL,
	viewPurchaseOrdersURL,
	addUtilityMeterURL,
	viewUtilityMetersURL,
	addUtilityMeterReadingURL,
	viewUtilityMeterReadingsURL,
	viewSignatureRequestsURL,
	viewBillPaymentsURL,
	viewInvoicePaymentsURL
} from 'urls'
import useMousetrap from './MouseTrap'
import { toast } from 'react-toastify'

interface CommandPaletteItem {
	name: string
	url: string
}

function FloatingActionButton({
	onClick
}: {
	onClick?: () => void
}): React.ReactElement {
	return (
		<div className='fixed bottom-0 right-0 p-4'>
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<button
				// label='Open Command Palette'
				className='flex h-10 w-10 items-center justify-center rounded-full bg-blue-800 text-white'
				onClick={onClick}
			>
				<svg
					className='h-6 w-6'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'
				>
					<SearchIcon className='h-6 w-6' />
				</svg>
			</button>
		</div>
	)
}

export default function CommandPalette({
	defaultOpen = false
}: {
	defaultOpen?: boolean
}): React.ReactElement {
	let hardcodedCommandPaletteItems: CommandPaletteItem[] = [
		{ name: 'Add Fee Schedule', url: addFeeScheduleURL },
		{ name: 'View Fee Schedules', url: viewFeeSchedulesURL },

		{ name: 'Add Building', url: addBuildingURL },
		{ name: 'View Buildings', url: viewBuildingsURL },

		{ name: 'Add Pet', url: addResidentPetURL },
		{ name: 'View Pets', url: viewResidentPetsURL },

		{ name: 'Add Rental Unit', url: addRentalUnitURL },
		{ name: 'View Rental Units', url: viewRentalUnitsURL },

		{ name: 'Add Property Owner', url: addPropertyOwnerURL },
		{ name: 'View Property Owners', url: viewPropertyOwnersURL },

		{ name: 'Add Resident', url: addResidentURL },
		{ name: 'View Residents', url: viewResidentsURL },

		{ name: 'Change Password', url: changePasswordURL },

		{ name: 'Add Document', url: addDocumentURL },
		{ name: 'View Documents', url: viewDocumentsURL },

		{ name: 'Record Expense', url: recordExpenseURL },

		{ name: 'Add Appliance', url: addApplianceURL },
		{ name: 'View Appliances', url: viewAppliancesURL },

		{ name: 'Invite Property Manager', url: invitePropertyManagerURL },
		{ name: 'View Property Managers', url: viewPropertyManagersURL },

		{ name: 'Contact Support', url: contactSupportURL },

		{ name: 'Add Property Group', url: addPropertyGroupURL },
		{ name: 'View Property Groups', url: viewPropertyGroupsURL },

		{ name: 'View Former Residents', url: viewFormerResidentsURL },
		{ name: 'View Future Residents', url: viewFutureResidentsURL },

		{ name: 'View Rental Applicants', url: viewRentalApplicantsURL },

		{ name: 'User Settings', url: userSettingsURL },

		{ name: 'Add Mortgage', url: addMortgageURL },
		{ name: 'View Mortgages', url: viewMortgagesURL },

		{ name: 'Add Lender', url: addLenderURL },
		{ name: 'View Lenders', url: viewLendersURL },

		{ name: 'Add Markup Type', url: addMarkupTypeURL },
		{ name: 'View Markup Types', url: viewMarkupTypesURL },

		{ name: 'Add Utility Meter', url: addUtilityMeterURL },
		{ name: 'View Utility Meters', url: viewUtilityMetersURL },

		{ name: 'Add Utilty Meter Reading', url: addUtilityMeterReadingURL },
		{ name: 'View Utilty Meter Readings', url: viewUtilityMeterReadingsURL },

		{
			name: 'Invite Maintenance Technician',
			url: inviteMaintenanceTechnicianURL
		},
		{ name: 'Add Maintenance Technician', url: addMaintenanceTechnicianURL },
		{
			name: 'View Maintenance Technicians',
			url: viewMaintenanceTechniciansURL
		},

		{ name: 'Add Time Tracking Entry', url: addTimeTrackingEntryURL },
		{ name: 'View Time Tracking Entries', url: viewTimeTrackingEntriesURL },

		{ name: 'Record Bulk Bill Payment', url: bulkPayBillURL },
		{ name: 'View Bulk Bill Payment', url: viewBulkBillPaymentsURL },
		{
			name: 'Send Bulk Rent Increase Notice',
			url: addBulkRentIncreaseNoticeURL
		},
		{ name: 'View Bulk Rent Increases', url: viewBulkRentIncreaseNoticesURL },

		{ name: 'Add Vehicles', url: addVehicleURL },
		{ name: 'View Vehicles', url: viewVehiclesURL },

		{ name: 'Add Resident Vehicles', url: addResidentVehicleURL },
		{ name: 'View Resident Vehicles', url: viewResidentVehiclesURL },

		{ name: 'Add Parking Space', url: addParkingSpaceURL },
		{ name: 'View Parking Spaces', url: viewParkingSpacesURL }
	]

	const { data: userPermissions, isSuccess: userPermissionsIsSuccess } =
		useGetUserPermissions({})
	const { data: propertyManagementCompanyDetails } =
		useGetPropertyManagementCompanyDetails({})

	if (userPermissionsIsSuccess) {
		if (userPermissions.has_accounting_permissions) {
			hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
				{ name: 'Add Journal Entry', url: addJournalEntryURL },
				{ name: 'View Journal Entries', url: viewJournalEntriesURL },
				{ name: 'View Transactions', url: viewTransactionsURL },

				{ name: 'Add Account', url: addAccountURL },
				{ name: 'View Accounts', url: viewAccountsURL },

				{ name: 'Add Bill', url: addBillURL },
				{ name: 'View Bills', url: viewBillsURL },

				{ name: 'View Bill Payments', url: viewBillPaymentsURL },

				{ name: 'Add Invoice', url: addInvoiceURL },
				{ name: 'View Invoices', url: viewInvoicesURL },

				{ name: 'View Invoice Payments', url: viewInvoicePaymentsURL },

				{ name: 'Add Vendor', url: addVendorURL },
				{ name: 'View Vendors', url: viewVendorsURL },

				{ name: 'Add Rent Receivable', url: addRentReceivableURL },
				{ name: 'View Rent Receivables', url: viewRentReceivablesURL },

				{ name: 'Add Bank Account', url: addBankAccountURL },
				{ name: 'View Bank Accounts', url: viewBankAccountsURL },

				{ name: 'Add Bank Reconciliation', url: addBankReconciliationURL },
				{ name: 'View Bank Reconciliations', url: viewBankReconciliationsURL },

				{ name: 'Add Rent Payment', url: addRentPaymentURL },
				{ name: 'Record Rent Payment', url: addRentPaymentURL },
				{ name: 'View Rent Payments', url: viewRentPaymentsURL },

				{
					name: 'Add Management Fee Payment',
					url: addManagementFeePaymentURL
				},
				{
					name: 'View Management Fee Payments',
					url: viewManagementFeePaymentsURL
				},

				{
					name: 'Add Management Fee Receivable',
					url: addManagementFeeReceivableURL
				},
				{
					name: 'View Management Fee Receivables',
					url: viewManagementFeeReceivablesURL
				},

				{ name: 'Add Inventory Item', url: addInventoryItemURL },
				{ name: 'View Inventory Items', url: viewInventoryItemsURL },

				{ name: 'Add Purchase Order', url: addPurchaseOrderURL },
				{ name: 'View Purchase Orders', url: viewPurchaseOrdersURL },

				{ name: 'Add Inventory Transaction', url: addInventoryTransactionURL },
				{
					name: 'View Inventory Transactions',
					url: viewInventoryTransactionsURL
				},

				{ name: 'Bulk Mark Rent As Paid', url: bulkMarkRentAsPaidURL },
				{
					name: 'Invoice Work Orders to Property Owners',
					url: bulkGenerateWorkOrderInvoicesURL
				}
			])
		}
		if (userPermissions.has_leasing_permissions) {
			hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
				{ name: 'View Rental Applications', url: viewRentalApplicationsURL },
				{
					name: 'Add Rental Application Form',
					url: addRentalApplicationFormURL
				},

				{ name: 'Add Lease Attachment', url: addLeaseAttachmentURL },
				{ name: 'View Lease Attachments', url: viewLeaseAttachmentsURL },

				// { name: 'Add Signature Request', url: addLeaseAttachmentURL },
				{ name: 'View Signature Requests', url: viewSignatureRequestsURL },

				{ name: 'View Listings', url: viewRentalUnitListingsURL },
				{ name: 'Create Listing', url: addRentalUnitListingURL },

				{ name: 'View Vacancies', url: currentVacanciesURL },
				{
					name: 'View Rental Application Forms',
					url: viewRentalApplicationFormsURL
				},

				{ name: 'Add Lease', url: addLeaseURL },
				{ name: 'View Leases', url: viewLeasesURL }
			])
		}
		if (userPermissions.has_reporting_permissions) {
			hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
				{ name: 'Generate Report', url: generateReportURL },

				{ name: 'Add Scheduled Report', url: addScheduledReportURL },
				{ name: 'View Scheduled Reports', url: viewScheduledReportsURL },

				{ name: 'Generate Balance Sheet', url: generateBalanceSheetURL },
				{ name: 'Generate Income Statement', url: generateIncomeStatementURL },
				{ name: 'Generate Rent Roll', url: generateRentRollURL },
				{
					name: 'Generate Transaction Register',
					url: generateTransactionRegisterReportURL
				},
				{
					name: 'Generate Closed Tasks Report',
					url: generateClosedTasksReportURL
				},
				{
					name: 'Generate Closed Work Orders Report',
					url: generateClosedWorkOrdersReportURL
				},
				{
					name: 'Generate Loss-to-Lease Report',
					url: generateLossToLeaseReportURL
				},
				{ name: 'Generate Open Tasks Report', url: generateOpenTasksReportURL },
				{
					name: 'Generate Open Work Orders Report',
					url: generateOpenWorkOrdersReportURL
				},
				{
					name: 'Generate Journal Transactions Report',
					url: generateJournalTransactionsReportURL
				},
				{
					name: 'Generate General Ledger (GL) Report',
					url: generateGeneralLedgerReportURL
				},
				{ name: 'Generate PO Listing Report', url: generatePOListingReportURL }
			])
			if (userPermissions.has_operations_permissions) {
				hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
					{ name: 'Add Recurring Task', url: addRecurringTaskURL },
					{ name: 'View Recurring Tasks', url: viewRecurringTasksURL },

					{ name: 'Add Task', url: addTaskURL },
					{ name: 'View Tasks', url: viewTasksURL },

					{ name: 'Add Work Order', url: addWorkOrderURL },
					{ name: 'View Work Orders', url: viewWorkOrdersURL },

					{ name: 'Add Budget', url: addBudgetURL },
					{ name: 'View Budgets', url: viewBudgetsURL },

					// Equipment
					{ name: 'Add Equipment', url: addEquipmentURL },
					{ name: 'View Equipment', url: viewEquipmentURL },

					// Equipment rental rates
					{ name: 'Add Equipment Rental Rate', url: addEquipmentRentalRateURL },
					{
						name: 'View Equipment Rental Rates',
						url: viewEquipmentRentalRatesURL
					}
				])
			}
			if (userPermissions.has_communications_permissions) {
				hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
					{ name: 'Send Email', url: addEmailMessageBatchURL },
					{ name: 'View Email', url: viewEmailMessageBatchesURL }
				])
			}
			if (userPermissions.has_storage_permissions) {
				hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
					{ name: 'View Storage Facilities', url: viewStorageFacilitiesURL },
					{ name: 'View Storage Units', url: viewStorageUnitsURL },
					{ name: 'View Storage Customers', url: viewStorageCustomersURL },
					{ name: 'View Storage Leases', url: viewStorageUnitLeasesURL },
					{ name: 'View Stripe Accounts', url: viewStripeAccountsURL },
					{ name: 'Add Stripe Account', url: addStripeAccountURL }
				])
			}
		}
	}

	if (propertyManagementCompanyDetails?.has_rentalize_ai_enabled) {
		hardcodedCommandPaletteItems = hardcodedCommandPaletteItems.concat([
			{ name: 'Ask Rentalize AI', url: askRentalizeAIQuestionURL }
		])
	}

	const [query, setQuery] = useState('')
	const [open, setOpen] = useState(defaultOpen)
	const navigate = useNavigate()

	const { isLoading, data, isSuccess } = useQuery({
		queryKey: ['commandPalette'],
		queryFn: async () =>
			fetch(`${import.meta.env.VITE_BASE_API_URL}/api/command-palette/`, {
				headers: { Authorization: `Bearer ${getAccessToken()}` }
			}).then(async response => {
				if (response.status === 401) {
					localStorage.removeItem('access_token')
					toast.error('Your session has expired, please log in again.')
					navigate(loginURL)
				}

				return response.json()
			}),
		enabled: !!getAccessToken()
	})

	let commandPaletteItems

	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.innerWidth < 768)
		}

		checkMobile()
		window.addEventListener('resize', checkMobile)

		return () => {
			window.removeEventListener('resize', checkMobile)
		}
	}, [])

	if (
		isLoading ||
		!isSuccess ||
		data?.detail === 'Given token not valid for any token type'
	) {
		commandPaletteItems = hardcodedCommandPaletteItems
	} else {
		commandPaletteItems = [...hardcodedCommandPaletteItems, ...data]
	}

	const filteredCommandPaletteItems =
		query === ''
			? []
			: commandPaletteItems.filter(item =>
					item.name.toLowerCase().includes(query.toLowerCase())
			  )
	useMousetrap(
		'ctrl+k',
		() => {
			setOpen(!open)
		},
		'keypress'
	)
	useMousetrap(
		'command+k',
		() => {
			setOpen(!open)
		},
		'keypress'
	)

	return (
		<>
			<Transition.Root
				show={open}
				as={Fragment}
				afterLeave={(): void => setQuery('')}
				appear
			>
				<Dialog as='div' className='relative z-10 ' onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
					</Transition.Child>

					<div className='fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20'>
						<div
							className={
								isMobile
									? 'flex min-h-full items-center justify-center p-4 text-center sm:p-0'
									: ''
							}
						>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'
							>
								<Dialog.Panel className='mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
									<Combobox
										value={query}
										onChange={(item: any): void => {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
											navigate(item.url)
											setOpen(false)
											setQuery('')
										}}
									>
										<div className='relative'>
											<SearchIcon
												className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400'
												aria-hidden='true'
											/>
											<Combobox.Input
												className='mousetrap h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm'
												placeholder='Search...'
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>
												): void => setQuery(event.target.value)}
												autoFocus
											/>
										</div>

										{filteredCommandPaletteItems.length > 0 && (
											<Combobox.Options
												static
												className='max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800'
											>
												{filteredCommandPaletteItems.map(item => (
													<Combobox.Option
														key={item.name}
														value={item}
														className={({ active }): string =>
															classnames(
																'cursor-default select-none px-4 py-2',
																active && 'bg-blue-600 text-white'
															)
														}
													>
														<div className='flex justify-between'>
															<span>{item.name}</span>
															<span className='text-xs italic'>
																{item.object_type}
															</span>
														</div>
													</Combobox.Option>
												))}
											</Combobox.Options>
										)}

										{query !== '' &&
											filteredCommandPaletteItems.length === 0 && (
												<p className='p-4 text-sm text-gray-500'>
													No matches found.
												</p>
											)}
									</Combobox>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<FloatingActionButton onClick={() => setOpen(true)} />
		</>
	)
}
