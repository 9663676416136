import {
	ClipboardListIcon,
	ClockIcon,
	FolderOpenIcon,
	LockClosedIcon,
	PlusIcon
} from '@heroicons/react/outline'

import {
	maintenanceTechnicianAddTimeTrackingEntryURL,
	maintenanceTechnicianAddWorkOrderURL,
	maintenanceTechnicianChangePasswordURL,
	maintenanceTechnicianDashboardURL,
	maintenanceTechnicianViewTimeTrackingEntriesURL,
	maintenanceTechnicianViewWorkOrdersURL
} from 'urls'
import BaseNavBar from './BaseNavBar'

const mainHeadings = [
	{
		heading: 'Work Orders',
		dashboard: maintenanceTechnicianViewWorkOrdersURL,
		icon: ClipboardListIcon,
		subheadings: [
			{
				name: 'View Work Orders',
				description: 'View and manage assigned work orders.',
				href: maintenanceTechnicianViewWorkOrdersURL,
				icon: FolderOpenIcon
			},
			{
				name: 'Add Work Order',
				description: 'Add a new work order.',
				href: maintenanceTechnicianAddWorkOrderURL,
				icon: PlusIcon
			}
		]
	},
	{
		heading: 'Time Tracking',
		dashboard: maintenanceTechnicianViewTimeTrackingEntriesURL,
		icon: ClockIcon,
		subheadings: [
			{
				name: 'Add Time Tracking Entry',
				description: 'Add a time tracking entry for a work order.',
				href: maintenanceTechnicianAddTimeTrackingEntryURL,
				icon: ClockIcon
			},
			{
				name: 'View Time Tracking Entries',
				description: 'View all time tracking entries for a work order.',
				href: maintenanceTechnicianViewTimeTrackingEntriesURL,
				icon: ClockIcon
			}
		]
	},
	{
		heading: 'Account',
		dashboard: maintenanceTechnicianChangePasswordURL,
		icon: LockClosedIcon,
		subheadings: [
			{
				name: 'Change Password',
				description: 'Change your account password',
				href: maintenanceTechnicianChangePasswordURL,
				icon: LockClosedIcon
			}
		]
	}
]

export default function MaintenanceTechnicianNavbar(): React.ReactElement {
	return (
		<BaseNavBar
			homeURL={maintenanceTechnicianDashboardURL}
			mainHeadings={mainHeadings}
		/>
	)
}
