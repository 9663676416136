import { useLocation } from 'react-router-dom'
import {
	aboutPageURL,
	analyticsAndInsightsFeatureURL,
	automaticResidentScreeningFeatureURL,
	blogPageURL,
	careersPageURL,
	companyFinancialsFeatureURL,
	leaseESignatureFeatureURL,
	maintenanceRequestTrackingFeatureURL,
	onlineRentCollectionFeatureURL,
	onlineRentalApplicationFeatureURL,
	openAPIFeatureURL,
	ownerPortalFeatureURL,
	propertyAccountingFeatureURL,
	rentalListingFeatureURL,
	residentCenterFeatureURL,
	taxReportingFeatureURL
} from 'urls'
import LogoComponent from './Logo'

const navigation = {
	accounting: [
		{ name: 'Property Accounting', href: propertyAccountingFeatureURL },
		{ name: 'Company Financials', href: companyFinancialsFeatureURL },
		{ name: 'Online Rent Collection', href: onlineRentCollectionFeatureURL },
		{ name: 'Tax Reporting', href: taxReportingFeatureURL }
	],
	operations: [
		{ name: 'Resident Center', href: residentCenterFeatureURL },
		{ name: 'Owner Portal', href: ownerPortalFeatureURL },
		{
			name: 'Maintenance  Tracking',
			href: maintenanceRequestTrackingFeatureURL
		},
		{ name: 'Analytics & Insights', href: analyticsAndInsightsFeatureURL }
	],
	leasing: [
		{ name: 'Rental Listings', href: rentalListingFeatureURL },
		{
			name: 'Resident Screening',
			href: automaticResidentScreeningFeatureURL
		},
		{
			name: 'Rental Applications',
			href: onlineRentalApplicationFeatureURL
		},
		{ name: 'Lease eSignature', href: leaseESignatureFeatureURL }
	],
	company: [
		{ name: 'About', href: aboutPageURL },
		{ name: 'Blog', href: blogPageURL },
		{ name: 'Careers', href: careersPageURL },
		{ name: 'Open API', href: openAPIFeatureURL }
	]
}

export default function Footer(): React.ReactElement {
	const location = useLocation()

	// Don't show the footer in the Resident Center or the Owner Portal
	if (location.pathname.startsWith('/op')) {
		return <div />
	}

	return (
		<footer className='bg-white' aria-labelledby='footer-heading'>
			<h2 id='footer-heading' className='sr-only'>
				Footer
			</h2>
			<div className='mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
				<div className='xl:grid xl:grid-cols-3 xl:gap-8'>
					<div className='space-y-8'>
						<LogoComponent />
						<p className='text-sm leading-6 text-gray-600'>
							Building better property management software.
						</p>
					</div>
					<div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
						<div className='md:grid md:grid-cols-2 md:gap-8'>
							<div>
								<h3 className='text-sm font-semibold leading-6 text-gray-900'>
									Accounting
								</h3>
								<ul className='mt-6 space-y-4'>
									{navigation.accounting.map(item => (
										<li key={item.name}>
											<a
												href={item.href}
												className='text-sm leading-6 text-gray-600 hover:text-gray-900'
											>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
							<div className='mt-10 md:mt-0'>
								<h3 className='text-sm font-semibold leading-6 text-gray-900'>
									Operations
								</h3>
								<ul className='mt-6 space-y-4'>
									{navigation.operations.map(item => (
										<li key={item.name}>
											<a
												href={item.href}
												className='text-sm leading-6 text-gray-600 hover:text-gray-900'
											>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className='md:grid md:grid-cols-2 md:gap-8'>
							<div>
								<h3 className='text-sm font-semibold leading-6 text-gray-900'>
									Leasing
								</h3>
								<ul className='mt-6 space-y-4'>
									{navigation.leasing.map(item => (
										<li key={item.name}>
											<a
												href={item.href}
												className='text-sm leading-6 text-gray-600 hover:text-gray-900'
											>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
							<div className='mt-10 md:mt-0'>
								<h3 className='text-sm font-semibold leading-6 text-gray-900'>
									Company
								</h3>
								<ul className='mt-6 space-y-4'>
									{navigation.company.map(item => (
										<li key={item.name}>
											<a
												href={item.href}
												className='text-sm leading-6 text-gray-600 hover:text-gray-900'
											>
												{item.name}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className='mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24'>
					<p className='text-xs leading-5 text-gray-500'>
						&copy; 2024 Rentalize Software Systems Inc. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	)
}
