/**
 * Generated by @openapi-codegen
 *
 * @version 0.1
 */
import * as reactQuery from '@tanstack/react-query'
import { useRentalizeContext, RentalizeContext } from './rentalizeContext'
import type * as Fetcher from './rentalizeFetcher'
import { rentalizeFetch } from './rentalizeFetcher'
import type * as Schemas from './rentalizeSchemas'

export type ListAccountRolesQueryParams = {
	building_id?: string | null
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAccountRolesError = Fetcher.ErrorWrapper<undefined>

export type ListAccountRolesVariables = {
	queryParams?: ListAccountRolesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAccountRoles = (
	variables: ListAccountRolesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAccountRoleSchema,
		ListAccountRolesError,
		undefined,
		{},
		ListAccountRolesQueryParams,
		{}
	>({
		url: '/api/accounting/account-roles/',
		method: 'get',
		...variables,
		signal
	})

export const useListAccountRoles = <TData = Schemas.PagedAccountRoleSchema>(
	variables: ListAccountRolesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAccountRoleSchema,
			ListAccountRolesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAccountRoleSchema,
		ListAccountRolesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/account-roles/',
			operationId: 'listAccountRoles',
			variables
		}),
		({ signal }) =>
			fetchListAccountRoles({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateAccountingWorkflowStepError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type CreateAccountingWorkflowStepVariables = {
	body: Schemas.AccountingWorkflowStepCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAccountingWorkflowStep = (
	variables: CreateAccountingWorkflowStepVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAccountingWorkflowStepError,
		Schemas.AccountingWorkflowStepCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/accounting-workflow-steps/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateAccountingWorkflowStep = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAccountingWorkflowStepError,
			CreateAccountingWorkflowStepVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAccountingWorkflowStepError,
		CreateAccountingWorkflowStepVariables
	>(
		(variables: CreateAccountingWorkflowStepVariables) =>
			fetchCreateAccountingWorkflowStep({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAccountingWorkflowStepsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAccountingWorkflowStepsError = Fetcher.ErrorWrapper<undefined>

export type ListAccountingWorkflowStepsVariables = {
	queryParams?: ListAccountingWorkflowStepsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAccountingWorkflowSteps = (
	variables: ListAccountingWorkflowStepsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAccountingWorkflowStepDetailSchema,
		ListAccountingWorkflowStepsError,
		undefined,
		{},
		ListAccountingWorkflowStepsQueryParams,
		{}
	>({
		url: '/api/accounting/accounting-workflow-steps/',
		method: 'get',
		...variables,
		signal
	})

export const useListAccountingWorkflowSteps = <
	TData = Schemas.PagedAccountingWorkflowStepDetailSchema
>(
	variables: ListAccountingWorkflowStepsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAccountingWorkflowStepDetailSchema,
			ListAccountingWorkflowStepsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAccountingWorkflowStepDetailSchema,
		ListAccountingWorkflowStepsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounting-workflow-steps/',
			operationId: 'listAccountingWorkflowSteps',
			variables
		}),
		({ signal }) =>
			fetchListAccountingWorkflowSteps(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAccountingWorkflowStepPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAccountingWorkflowStepError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type DeleteAccountingWorkflowStepVariables = {
	pathParams: DeleteAccountingWorkflowStepPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAccountingWorkflowStep = (
	variables: DeleteAccountingWorkflowStepVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAccountingWorkflowStepError,
		undefined,
		{},
		{},
		DeleteAccountingWorkflowStepPathParams
	>({
		url: '/api/accounting/accounting-workflow-steps/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteAccountingWorkflowStep = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAccountingWorkflowStepError,
			DeleteAccountingWorkflowStepVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAccountingWorkflowStepError,
		DeleteAccountingWorkflowStepVariables
	>(
		(variables: DeleteAccountingWorkflowStepVariables) =>
			fetchDeleteAccountingWorkflowStep({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAccountingWorkflowStepDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAccountingWorkflowStepDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetAccountingWorkflowStepDetailsVariables = {
	pathParams: GetAccountingWorkflowStepDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAccountingWorkflowStepDetails = (
	variables: GetAccountingWorkflowStepDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AccountingWorkflowStepDetailSchema,
		GetAccountingWorkflowStepDetailsError,
		undefined,
		{},
		{},
		GetAccountingWorkflowStepDetailsPathParams
	>({
		url: '/api/accounting/accounting-workflow-steps/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAccountingWorkflowStepDetails = <
	TData = Schemas.AccountingWorkflowStepDetailSchema
>(
	variables: GetAccountingWorkflowStepDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AccountingWorkflowStepDetailSchema,
			GetAccountingWorkflowStepDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AccountingWorkflowStepDetailSchema,
		GetAccountingWorkflowStepDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounting-workflow-steps/{id}/',
			operationId: 'getAccountingWorkflowStepDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAccountingWorkflowStepDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAccountingWorkflowStepPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAccountingWorkflowStepError = Fetcher.ErrorWrapper<undefined>

export type UpdateAccountingWorkflowStepVariables = {
	body: Schemas.AccountingWorkflowStepUpdateSchema
	pathParams: UpdateAccountingWorkflowStepPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAccountingWorkflowStep = (
	variables: UpdateAccountingWorkflowStepVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAccountingWorkflowStepError,
		Schemas.AccountingWorkflowStepUpdateSchema,
		{},
		{},
		UpdateAccountingWorkflowStepPathParams
	>({
		url: '/api/accounting/accounting-workflow-steps/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAccountingWorkflowStep = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAccountingWorkflowStepError,
			UpdateAccountingWorkflowStepVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAccountingWorkflowStepError,
		UpdateAccountingWorkflowStepVariables
	>(
		(variables: UpdateAccountingWorkflowStepVariables) =>
			fetchUpdateAccountingWorkflowStep({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAccountingWorkflowError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type CreateAccountingWorkflowVariables = {
	body: Schemas.AccountingWorkflowCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAccountingWorkflow = (
	variables: CreateAccountingWorkflowVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAccountingWorkflowError,
		Schemas.AccountingWorkflowCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/accounting-workflows/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateAccountingWorkflow = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAccountingWorkflowError,
			CreateAccountingWorkflowVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAccountingWorkflowError,
		CreateAccountingWorkflowVariables
	>(
		(variables: CreateAccountingWorkflowVariables) =>
			fetchCreateAccountingWorkflow({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAccountingWorkflowsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAccountingWorkflowsError = Fetcher.ErrorWrapper<undefined>

export type ListAccountingWorkflowsVariables = {
	queryParams?: ListAccountingWorkflowsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAccountingWorkflows = (
	variables: ListAccountingWorkflowsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAccountingWorkflowDetailSchema,
		ListAccountingWorkflowsError,
		undefined,
		{},
		ListAccountingWorkflowsQueryParams,
		{}
	>({
		url: '/api/accounting/accounting-workflows/',
		method: 'get',
		...variables,
		signal
	})

export const useListAccountingWorkflows = <
	TData = Schemas.PagedAccountingWorkflowDetailSchema
>(
	variables: ListAccountingWorkflowsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAccountingWorkflowDetailSchema,
			ListAccountingWorkflowsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAccountingWorkflowDetailSchema,
		ListAccountingWorkflowsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounting-workflows/',
			operationId: 'listAccountingWorkflows',
			variables
		}),
		({ signal }) =>
			fetchListAccountingWorkflows({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAccountingWorkflowPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAccountingWorkflowError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type DeleteAccountingWorkflowVariables = {
	pathParams: DeleteAccountingWorkflowPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAccountingWorkflow = (
	variables: DeleteAccountingWorkflowVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAccountingWorkflowError,
		undefined,
		{},
		{},
		DeleteAccountingWorkflowPathParams
	>({
		url: '/api/accounting/accounting-workflows/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteAccountingWorkflow = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAccountingWorkflowError,
			DeleteAccountingWorkflowVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAccountingWorkflowError,
		DeleteAccountingWorkflowVariables
	>(
		(variables: DeleteAccountingWorkflowVariables) =>
			fetchDeleteAccountingWorkflow({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAccountingWorkflowDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAccountingWorkflowDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetAccountingWorkflowDetailsVariables = {
	pathParams: GetAccountingWorkflowDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAccountingWorkflowDetails = (
	variables: GetAccountingWorkflowDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AccountingWorkflowDetailSchema,
		GetAccountingWorkflowDetailsError,
		undefined,
		{},
		{},
		GetAccountingWorkflowDetailsPathParams
	>({
		url: '/api/accounting/accounting-workflows/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAccountingWorkflowDetails = <
	TData = Schemas.AccountingWorkflowDetailSchema
>(
	variables: GetAccountingWorkflowDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AccountingWorkflowDetailSchema,
			GetAccountingWorkflowDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AccountingWorkflowDetailSchema,
		GetAccountingWorkflowDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounting-workflows/{id}/',
			operationId: 'getAccountingWorkflowDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAccountingWorkflowDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAccountingWorkflowPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAccountingWorkflowError = Fetcher.ErrorWrapper<undefined>

export type UpdateAccountingWorkflowVariables = {
	body: Schemas.AccountingWorkflowUpdateSchema
	pathParams: UpdateAccountingWorkflowPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAccountingWorkflow = (
	variables: UpdateAccountingWorkflowVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAccountingWorkflowError,
		Schemas.AccountingWorkflowUpdateSchema,
		{},
		{},
		UpdateAccountingWorkflowPathParams
	>({
		url: '/api/accounting/accounting-workflows/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAccountingWorkflow = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAccountingWorkflowError,
			UpdateAccountingWorkflowVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAccountingWorkflowError,
		UpdateAccountingWorkflowVariables
	>(
		(variables: UpdateAccountingWorkflowVariables) =>
			fetchUpdateAccountingWorkflow({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAccountError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type CreateAccountVariables = {
	body: Schemas.AccountCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAccount = (
	variables: CreateAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAccountError,
		Schemas.AccountCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/accounting/accounts/', method: 'post', ...variables, signal })

export const useCreateAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAccountError,
			CreateAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAccountError,
		CreateAccountVariables
	>(
		(variables: CreateAccountVariables) =>
			fetchCreateAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAccountsQueryParams = {
	building_id?: string | null
	rental_unit_id?: string | null
	property_owner_id?: string | null
	/**
	 * @default ACTIVE
	 */
	status?: 'ACTIVE' | 'INACTIVE'
	/**
	 * @default ALL
	 */
	role?:
		| 'ASSET'
		| 'BANK'
		| 'LIABILITY'
		| 'EXPENSE'
		| 'EXPENSE_AND_COGS'
		| 'REVENUE'
		| 'COGS'
		| 'EQUITY'
		| 'ALL'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAccountsError = Fetcher.ErrorWrapper<undefined>

export type ListAccountsVariables = {
	queryParams?: ListAccountsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAccounts = (
	variables: ListAccountsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAccountOutSchema,
		ListAccountsError,
		undefined,
		{},
		ListAccountsQueryParams,
		{}
	>({ url: '/api/accounting/accounts/', method: 'get', ...variables, signal })

export const useListAccounts = <TData = Schemas.PagedAccountOutSchema>(
	variables: ListAccountsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAccountOutSchema,
			ListAccountsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAccountOutSchema,
		ListAccountsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounts/',
			operationId: 'listAccounts',
			variables
		}),
		({ signal }) =>
			fetchListAccounts({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeactivateAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeactivateAccountError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type DeactivateAccountVariables = {
	pathParams: DeactivateAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeactivateAccount = (
	variables: DeactivateAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeactivateAccountError,
		undefined,
		{},
		{},
		DeactivateAccountPathParams
	>({
		url: '/api/accounting/accounts/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeactivateAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeactivateAccountError,
			DeactivateAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeactivateAccountError,
		DeactivateAccountVariables
	>(
		(variables: DeactivateAccountVariables) =>
			fetchDeactivateAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAccountDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAccountDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetAccountDetailsVariables = {
	pathParams: GetAccountDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAccountDetails = (
	variables: GetAccountDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AccountOutSchema,
		GetAccountDetailsError,
		undefined,
		{},
		{},
		GetAccountDetailsPathParams
	>({
		url: '/api/accounting/accounts/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAccountDetails = <TData = Schemas.AccountOutSchema>(
	variables: GetAccountDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AccountOutSchema,
			GetAccountDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AccountOutSchema,
		GetAccountDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/accounts/{id}/',
			operationId: 'getAccountDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAccountDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAccountError = Fetcher.ErrorWrapper<undefined>

export type UpdateAccountVariables = {
	body: Schemas.AccountUpdateSchema
	pathParams: UpdateAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAccount = (
	variables: UpdateAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAccountError,
		Schemas.AccountUpdateSchema,
		{},
		{},
		UpdateAccountPathParams
	>({
		url: '/api/accounting/accounts/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAccountError,
			UpdateAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAccountError,
		UpdateAccountVariables
	>(
		(variables: UpdateAccountVariables) =>
			fetchUpdateAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ReactivateAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ReactivateAccountError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type ReactivateAccountVariables = {
	pathParams: ReactivateAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchReactivateAccount = (
	variables: ReactivateAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		ReactivateAccountError,
		undefined,
		{},
		{},
		ReactivateAccountPathParams
	>({
		url: '/api/accounting/accounts/{id}/reactivate/',
		method: 'post',
		...variables,
		signal
	})

export const useReactivateAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			ReactivateAccountError,
			ReactivateAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		ReactivateAccountError,
		ReactivateAccountVariables
	>(
		(variables: ReactivateAccountVariables) =>
			fetchReactivateAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateApprovalRequestError = Fetcher.ErrorWrapper<undefined>

export type CreateApprovalRequestVariables = {
	body: Schemas.ApprovalRequestCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateApprovalRequest = (
	variables: CreateApprovalRequestVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateApprovalRequestError,
		Schemas.ApprovalRequestCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/approval-requests/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateApprovalRequest = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateApprovalRequestError,
			CreateApprovalRequestVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateApprovalRequestError,
		CreateApprovalRequestVariables
	>(
		(variables: CreateApprovalRequestVariables) =>
			fetchCreateApprovalRequest({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListApprovalRequestsQueryParams = {
	bill_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListApprovalRequestsError = Fetcher.ErrorWrapper<undefined>

export type ListApprovalRequestsVariables = {
	queryParams?: ListApprovalRequestsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListApprovalRequests = (
	variables: ListApprovalRequestsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedApprovalRequestDetailSchema,
		ListApprovalRequestsError,
		undefined,
		{},
		ListApprovalRequestsQueryParams,
		{}
	>({
		url: '/api/accounting/approval-requests/',
		method: 'get',
		...variables,
		signal
	})

export const useListApprovalRequests = <
	TData = Schemas.PagedApprovalRequestDetailSchema
>(
	variables: ListApprovalRequestsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedApprovalRequestDetailSchema,
			ListApprovalRequestsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedApprovalRequestDetailSchema,
		ListApprovalRequestsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/approval-requests/',
			operationId: 'listApprovalRequests',
			variables
		}),
		({ signal }) =>
			fetchListApprovalRequests({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteApprovalRequestPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteApprovalRequestError = Fetcher.ErrorWrapper<undefined>

export type DeleteApprovalRequestVariables = {
	pathParams: DeleteApprovalRequestPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteApprovalRequest = (
	variables: DeleteApprovalRequestVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteApprovalRequestError,
		undefined,
		{},
		{},
		DeleteApprovalRequestPathParams
	>({
		url: '/api/accounting/approval-requests/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteApprovalRequest = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteApprovalRequestError,
			DeleteApprovalRequestVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteApprovalRequestError,
		DeleteApprovalRequestVariables
	>(
		(variables: DeleteApprovalRequestVariables) =>
			fetchDeleteApprovalRequest({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetApprovalRequestDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetApprovalRequestDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetApprovalRequestDetailsVariables = {
	pathParams: GetApprovalRequestDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetApprovalRequestDetails = (
	variables: GetApprovalRequestDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ApprovalRequestSchema,
		GetApprovalRequestDetailsError,
		undefined,
		{},
		{},
		GetApprovalRequestDetailsPathParams
	>({
		url: '/api/accounting/approval-requests/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetApprovalRequestDetails = <
	TData = Schemas.ApprovalRequestSchema
>(
	variables: GetApprovalRequestDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ApprovalRequestSchema,
			GetApprovalRequestDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ApprovalRequestSchema,
		GetApprovalRequestDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/approval-requests/{id}/',
			operationId: 'getApprovalRequestDetails',
			variables
		}),
		({ signal }) =>
			fetchGetApprovalRequestDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateApprovalRequestPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateApprovalRequestError = Fetcher.ErrorWrapper<undefined>

export type UpdateApprovalRequestVariables = {
	body: Schemas.ApprovalRequestSchema
	pathParams: UpdateApprovalRequestPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateApprovalRequest = (
	variables: UpdateApprovalRequestVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateApprovalRequestError,
		Schemas.ApprovalRequestSchema,
		{},
		{},
		UpdateApprovalRequestPathParams
	>({
		url: '/api/accounting/approval-requests/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateApprovalRequest = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateApprovalRequestError,
			UpdateApprovalRequestVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateApprovalRequestError,
		UpdateApprovalRequestVariables
	>(
		(variables: UpdateApprovalRequestVariables) =>
			fetchUpdateApprovalRequest({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBankDepositError = Fetcher.ErrorWrapper<undefined>

export type CreateBankDepositVariables = {
	body: Schemas.BankDepositCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBankDeposit = (
	variables: CreateBankDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBankDepositError,
		Schemas.BankDepositCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/bank-deposits/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBankDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBankDepositError,
			CreateBankDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBankDepositError,
		CreateBankDepositVariables
	>(
		(variables: CreateBankDepositVariables) =>
			fetchCreateBankDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBankDepositsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBankDepositsError = Fetcher.ErrorWrapper<undefined>

export type ListBankDepositsVariables = {
	queryParams?: ListBankDepositsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBankDeposits = (
	variables: ListBankDepositsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBankDepositDetailSchema,
		ListBankDepositsError,
		undefined,
		{},
		ListBankDepositsQueryParams,
		{}
	>({
		url: '/api/accounting/bank-deposits/',
		method: 'get',
		...variables,
		signal
	})

export const useListBankDeposits = <
	TData = Schemas.PagedBankDepositDetailSchema
>(
	variables: ListBankDepositsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBankDepositDetailSchema,
			ListBankDepositsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBankDepositDetailSchema,
		ListBankDepositsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bank-deposits/',
			operationId: 'listBankDeposits',
			variables
		}),
		({ signal }) =>
			fetchListBankDeposits({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBankDepositPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBankDepositError = Fetcher.ErrorWrapper<undefined>

export type DeleteBankDepositVariables = {
	pathParams: DeleteBankDepositPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBankDeposit = (
	variables: DeleteBankDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBankDepositError,
		undefined,
		{},
		{},
		DeleteBankDepositPathParams
	>({
		url: '/api/accounting/bank-deposits/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBankDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBankDepositError,
			DeleteBankDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBankDepositError,
		DeleteBankDepositVariables
	>(
		(variables: DeleteBankDepositVariables) =>
			fetchDeleteBankDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBankDepositDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBankDepositDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBankDepositDetailsVariables = {
	pathParams: GetBankDepositDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBankDepositDetails = (
	variables: GetBankDepositDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BankDepositDetailSchema,
		GetBankDepositDetailsError,
		undefined,
		{},
		{},
		GetBankDepositDetailsPathParams
	>({
		url: '/api/accounting/bank-deposits/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBankDepositDetails = <
	TData = Schemas.BankDepositDetailSchema
>(
	variables: GetBankDepositDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BankDepositDetailSchema,
			GetBankDepositDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BankDepositDetailSchema,
		GetBankDepositDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bank-deposits/{id}/',
			operationId: 'getBankDepositDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBankDepositDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBankDepositPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBankDepositError = Fetcher.ErrorWrapper<undefined>

export type UpdateBankDepositVariables = {
	body: Schemas.BankDepositUpdateSchema
	pathParams: UpdateBankDepositPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBankDeposit = (
	variables: UpdateBankDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBankDepositError,
		Schemas.BankDepositUpdateSchema,
		{},
		{},
		UpdateBankDepositPathParams
	>({
		url: '/api/accounting/bank-deposits/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBankDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBankDepositError,
			UpdateBankDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBankDepositError,
		UpdateBankDepositVariables
	>(
		(variables: UpdateBankDepositVariables) =>
			fetchUpdateBankDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBankReconciliationError = Fetcher.ErrorWrapper<undefined>

export type CreateBankReconciliationVariables = {
	body: Schemas.BankReconciliationCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBankReconciliation = (
	variables: CreateBankReconciliationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBankReconciliationError,
		Schemas.BankReconciliationCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/bank-reconciliations/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBankReconciliation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBankReconciliationError,
			CreateBankReconciliationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBankReconciliationError,
		CreateBankReconciliationVariables
	>(
		(variables: CreateBankReconciliationVariables) =>
			fetchCreateBankReconciliation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBankReconciliationsQueryParams = {
	bank_account_id?: string | null
	status?: ('DRAFT' | 'FINALIZED') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBankReconciliationsError = Fetcher.ErrorWrapper<undefined>

export type ListBankReconciliationsVariables = {
	queryParams?: ListBankReconciliationsQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * List bank reconciliations.
 */
export const fetchListBankReconciliations = (
	variables: ListBankReconciliationsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBankReconciliationDetailSchema,
		ListBankReconciliationsError,
		undefined,
		{},
		ListBankReconciliationsQueryParams,
		{}
	>({
		url: '/api/accounting/bank-reconciliations/',
		method: 'get',
		...variables,
		signal
	})

/**
 * List bank reconciliations.
 */
export const useListBankReconciliations = <
	TData = Schemas.PagedBankReconciliationDetailSchema
>(
	variables: ListBankReconciliationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBankReconciliationDetailSchema,
			ListBankReconciliationsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBankReconciliationDetailSchema,
		ListBankReconciliationsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bank-reconciliations/',
			operationId: 'listBankReconciliations',
			variables
		}),
		({ signal }) =>
			fetchListBankReconciliations({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBankReconciliationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBankReconciliationError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type DeleteBankReconciliationVariables = {
	pathParams: DeleteBankReconciliationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBankReconciliation = (
	variables: DeleteBankReconciliationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBankReconciliationError,
		undefined,
		{},
		{},
		DeleteBankReconciliationPathParams
	>({
		url: '/api/accounting/bank-reconciliations/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBankReconciliation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBankReconciliationError,
			DeleteBankReconciliationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBankReconciliationError,
		DeleteBankReconciliationVariables
	>(
		(variables: DeleteBankReconciliationVariables) =>
			fetchDeleteBankReconciliation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBankReconciliationDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBankReconciliationDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBankReconciliationDetailsVariables = {
	pathParams: GetBankReconciliationDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBankReconciliationDetails = (
	variables: GetBankReconciliationDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BankReconciliationDetailSchema,
		GetBankReconciliationDetailsError,
		undefined,
		{},
		{},
		GetBankReconciliationDetailsPathParams
	>({
		url: '/api/accounting/bank-reconciliations/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBankReconciliationDetails = <
	TData = Schemas.BankReconciliationDetailSchema
>(
	variables: GetBankReconciliationDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BankReconciliationDetailSchema,
			GetBankReconciliationDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BankReconciliationDetailSchema,
		GetBankReconciliationDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bank-reconciliations/{id}/',
			operationId: 'getBankReconciliationDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBankReconciliationDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBankReconciliationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBankReconciliationError = Fetcher.ErrorWrapper<undefined>

export type UpdateBankReconciliationVariables = {
	body: Schemas.BankReconciliationSchema
	pathParams: UpdateBankReconciliationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBankReconciliation = (
	variables: UpdateBankReconciliationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBankReconciliationError,
		Schemas.BankReconciliationSchema,
		{},
		{},
		UpdateBankReconciliationPathParams
	>({
		url: '/api/accounting/bank-reconciliations/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBankReconciliation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBankReconciliationError,
			UpdateBankReconciliationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBankReconciliationError,
		UpdateBankReconciliationVariables
	>(
		(variables: UpdateBankReconciliationVariables) =>
			fetchUpdateBankReconciliation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateBankReconciliationPdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateBankReconciliationPdfError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateBankReconciliationPdfVariables = {
	pathParams: GenerateBankReconciliationPdfPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateBankReconciliationPdf = (
	variables: GenerateBankReconciliationPdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GenerateBankReconciliationPdfError,
		undefined,
		{},
		{},
		GenerateBankReconciliationPdfPathParams
	>({
		url: '/api/bank-reconciliations/{id}/generate-pdf/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateBankReconciliationPdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GenerateBankReconciliationPdfError,
			GenerateBankReconciliationPdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GenerateBankReconciliationPdfError,
		GenerateBankReconciliationPdfVariables
	>(
		(variables: GenerateBankReconciliationPdfVariables) =>
			fetchGenerateBankReconciliationPdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type MarkBankReconciliationAsFinalPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type MarkBankReconciliationAsFinalError = Fetcher.ErrorWrapper<undefined>

export type MarkBankReconciliationAsFinalVariables = {
	body: Schemas.MarkBankReconciliationAsFinalSchema
	pathParams: MarkBankReconciliationAsFinalPathParams
} & RentalizeContext['fetcherOptions']

export const fetchMarkBankReconciliationAsFinal = (
	variables: MarkBankReconciliationAsFinalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		MarkBankReconciliationAsFinalError,
		Schemas.MarkBankReconciliationAsFinalSchema,
		{},
		{},
		MarkBankReconciliationAsFinalPathParams
	>({
		url: '/api/accounting/bank-reconciliations/{id}/mark-as-final/',
		method: 'post',
		...variables,
		signal
	})

export const useMarkBankReconciliationAsFinal = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			MarkBankReconciliationAsFinalError,
			MarkBankReconciliationAsFinalVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		MarkBankReconciliationAsFinalError,
		MarkBankReconciliationAsFinalVariables
	>(
		(variables: MarkBankReconciliationAsFinalVariables) =>
			fetchMarkBankReconciliationAsFinal({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBillPaymentError = Fetcher.ErrorWrapper<undefined>

export type CreateBillPaymentVariables = {
	body: Schemas.BillPaymentInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBillPayment = (
	variables: CreateBillPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBillPaymentError,
		Schemas.BillPaymentInSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/bill-payments/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBillPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBillPaymentError,
			CreateBillPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBillPaymentError,
		CreateBillPaymentVariables
	>(
		(variables: CreateBillPaymentVariables) =>
			fetchCreateBillPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBillPaymentsQueryParams = {
	bill_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBillPaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListBillPaymentsVariables = {
	queryParams?: ListBillPaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBillPayments = (
	variables: ListBillPaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBillPaymentOutSchema,
		ListBillPaymentsError,
		undefined,
		{},
		ListBillPaymentsQueryParams,
		{}
	>({
		url: '/api/accounting/bill-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListBillPayments = <TData = Schemas.PagedBillPaymentOutSchema>(
	variables: ListBillPaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBillPaymentOutSchema,
			ListBillPaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBillPaymentOutSchema,
		ListBillPaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bill-payments/',
			operationId: 'listBillPayments',
			variables
		}),
		({ signal }) =>
			fetchListBillPayments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBillPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBillPaymentError = Fetcher.ErrorWrapper<undefined>

export type DeleteBillPaymentVariables = {
	pathParams: DeleteBillPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBillPayment = (
	variables: DeleteBillPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBillPaymentError,
		undefined,
		{},
		{},
		DeleteBillPaymentPathParams
	>({
		url: '/api/accounting/bill-payments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBillPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBillPaymentError,
			DeleteBillPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBillPaymentError,
		DeleteBillPaymentVariables
	>(
		(variables: DeleteBillPaymentVariables) =>
			fetchDeleteBillPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBillPaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBillPaymentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBillPaymentDetailsVariables = {
	pathParams: GetBillPaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBillPaymentDetails = (
	variables: GetBillPaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BillPaymentOutSchema,
		GetBillPaymentDetailsError,
		undefined,
		{},
		{},
		GetBillPaymentDetailsPathParams
	>({
		url: '/api/accounting/bill-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBillPaymentDetails = <TData = Schemas.BillPaymentOutSchema>(
	variables: GetBillPaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BillPaymentOutSchema,
			GetBillPaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BillPaymentOutSchema,
		GetBillPaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bill-payments/{id}/',
			operationId: 'getBillPaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBillPaymentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBillPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBillPaymentError = Fetcher.ErrorWrapper<undefined>

export type UpdateBillPaymentVariables = {
	body: Schemas.BillPaymentInSchema
	pathParams: UpdateBillPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBillPayment = (
	variables: UpdateBillPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBillPaymentError,
		Schemas.BillPaymentInSchema,
		{},
		{},
		UpdateBillPaymentPathParams
	>({
		url: '/api/accounting/bill-payments/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBillPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBillPaymentError,
			UpdateBillPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBillPaymentError,
		UpdateBillPaymentVariables
	>(
		(variables: UpdateBillPaymentVariables) =>
			fetchUpdateBillPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBillableExpenseError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateBillableExpenseVariables = {
	body: Schemas.BillableExpenseInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBillableExpense = (
	variables: CreateBillableExpenseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBillableExpenseError,
		Schemas.BillableExpenseInSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/billable-expenses/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBillableExpense = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBillableExpenseError,
			CreateBillableExpenseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBillableExpenseError,
		CreateBillableExpenseVariables
	>(
		(variables: CreateBillableExpenseVariables) =>
			fetchCreateBillableExpense({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBillableExpensesQueryParams = {
	is_paid?: boolean | null
	property_owner_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBillableExpensesError = Fetcher.ErrorWrapper<undefined>

export type ListBillableExpensesVariables = {
	queryParams?: ListBillableExpensesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBillableExpenses = (
	variables: ListBillableExpensesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBillableExpenseOutSchema,
		ListBillableExpensesError,
		undefined,
		{},
		ListBillableExpensesQueryParams,
		{}
	>({
		url: '/api/accounting/billable-expenses/',
		method: 'get',
		...variables,
		signal
	})

export const useListBillableExpenses = <
	TData = Schemas.PagedBillableExpenseOutSchema
>(
	variables: ListBillableExpensesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBillableExpenseOutSchema,
			ListBillableExpensesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBillableExpenseOutSchema,
		ListBillableExpensesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/billable-expenses/',
			operationId: 'listBillableExpenses',
			variables
		}),
		({ signal }) =>
			fetchListBillableExpenses({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type BulkMarkBillsAsPaidError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: Schemas.ErrorSchema
	  }
	| {
			status: 404
			payload: Schemas.ErrorSchema
	  }
>

export type BulkMarkBillsAsPaidVariables = {
	body: Schemas.BulkMarkBillAsPaidSchema
} & RentalizeContext['fetcherOptions']

export const fetchBulkMarkBillsAsPaid = (
	variables: BulkMarkBillsAsPaidVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		BulkMarkBillsAsPaidError,
		Schemas.BulkMarkBillAsPaidSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/bills/bulk-mark-as-paid/',
		method: 'post',
		...variables,
		signal
	})

export const useBulkMarkBillsAsPaid = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			BulkMarkBillsAsPaidError,
			BulkMarkBillsAsPaidVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		BulkMarkBillsAsPaidError,
		BulkMarkBillsAsPaidVariables
	>(
		(variables: BulkMarkBillsAsPaidVariables) =>
			fetchBulkMarkBillsAsPaid({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBillError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateBillVariables = {
	body: Schemas.BillCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBill = (
	variables: CreateBillVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBillError,
		Schemas.BillCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/accounting/bills/', method: 'post', ...variables, signal })

export const useCreateBill = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBillError,
			CreateBillVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBillError,
		CreateBillVariables
	>(
		(variables: CreateBillVariables) =>
			fetchCreateBill({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBillsQueryParams = {
	is_paid?: boolean | null
	vendor_id?: string | null
	work_order_id?: string | null
	remittance_letter_id?: string | null
	/**
	 * @default CREATED_DATE
	 */
	order_by?: 'VENDOR_NAME' | 'CREATED_DATE'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBillsError = Fetcher.ErrorWrapper<undefined>

export type ListBillsVariables = {
	queryParams?: ListBillsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBills = (
	variables: ListBillsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBillDetailSchema,
		ListBillsError,
		undefined,
		{},
		ListBillsQueryParams,
		{}
	>({ url: '/api/accounting/bills/', method: 'get', ...variables, signal })

export const useListBills = <TData = Schemas.PagedBillDetailSchema>(
	variables: ListBillsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBillDetailSchema,
			ListBillsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBillDetailSchema,
		ListBillsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bills/',
			operationId: 'listBills',
			variables
		}),
		({ signal }) => fetchListBills({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBillPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBillError = Fetcher.ErrorWrapper<undefined>

export type DeleteBillVariables = {
	pathParams: DeleteBillPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBill = (
	variables: DeleteBillVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBillError,
		undefined,
		{},
		{},
		DeleteBillPathParams
	>({
		url: '/api/accounting/bills/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBill = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBillError,
			DeleteBillVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBillError,
		DeleteBillVariables
	>(
		(variables: DeleteBillVariables) =>
			fetchDeleteBill({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBillDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBillDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBillDetailsVariables = {
	pathParams: GetBillDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBillDetails = (
	variables: GetBillDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BillDetailSchema,
		GetBillDetailsError,
		undefined,
		{},
		{},
		GetBillDetailsPathParams
	>({
		url: '/api/accounting/bills/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBillDetails = <TData = Schemas.BillDetailSchema>(
	variables: GetBillDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BillDetailSchema,
			GetBillDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BillDetailSchema,
		GetBillDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bills/{id}/',
			operationId: 'getBillDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBillDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBillPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBillError = Fetcher.ErrorWrapper<undefined>

export type UpdateBillVariables = {
	body: Schemas.BillDetailSchema
	pathParams: UpdateBillPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBill = (
	variables: UpdateBillVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBillError,
		Schemas.BillDetailSchema,
		{},
		{},
		UpdateBillPathParams
	>({
		url: '/api/accounting/bills/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBill = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBillError,
			UpdateBillVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBillError,
		UpdateBillVariables
	>(
		(variables: UpdateBillVariables) =>
			fetchUpdateBill({ ...fetcherOptions, ...variables }),
		options
	)
}

export type MarkBillAsPaidPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type MarkBillAsPaidError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type MarkBillAsPaidVariables = {
	body: Schemas.MarkBillAsPaidSchema
	pathParams: MarkBillAsPaidPathParams
} & RentalizeContext['fetcherOptions']

export const fetchMarkBillAsPaid = (
	variables: MarkBillAsPaidVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		MarkBillAsPaidError,
		Schemas.MarkBillAsPaidSchema,
		{},
		{},
		MarkBillAsPaidPathParams
	>({
		url: '/api/accounting/bills/{id}/mark-as-paid/',
		method: 'post',
		...variables,
		signal
	})

export const useMarkBillAsPaid = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			MarkBillAsPaidError,
			MarkBillAsPaidVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		MarkBillAsPaidError,
		MarkBillAsPaidVariables
	>(
		(variables: MarkBillAsPaidVariables) =>
			fetchMarkBillAsPaid({ ...fetcherOptions, ...variables }),
		options
	)
}

export type RecordBillPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type RecordBillPaymentError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type RecordBillPaymentVariables = {
	body: Schemas.RecordBillPaymentSchema
	pathParams: RecordBillPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchRecordBillPayment = (
	variables: RecordBillPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RecordBillPaymentError,
		Schemas.RecordBillPaymentSchema,
		{},
		{},
		RecordBillPaymentPathParams
	>({
		url: '/api/bills/{id}/record-payment/',
		method: 'post',
		...variables,
		signal
	})

export const useRecordBillPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RecordBillPaymentError,
			RecordBillPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RecordBillPaymentError,
		RecordBillPaymentVariables
	>(
		(variables: RecordBillPaymentVariables) =>
			fetchRecordBillPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBudgetLineItemChargeError = Fetcher.ErrorWrapper<undefined>

export type CreateBudgetLineItemChargeVariables = {
	body: Schemas.BudgetLineItemChargeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBudgetLineItemCharge = (
	variables: CreateBudgetLineItemChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBudgetLineItemChargeError,
		Schemas.BudgetLineItemChargeSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/budget-line-item-charges/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBudgetLineItemCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBudgetLineItemChargeError,
			CreateBudgetLineItemChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBudgetLineItemChargeError,
		CreateBudgetLineItemChargeVariables
	>(
		(variables: CreateBudgetLineItemChargeVariables) =>
			fetchCreateBudgetLineItemCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBudgetLineItemChargesQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetLineItemChargesError = Fetcher.ErrorWrapper<undefined>

export type ListBudgetLineItemChargesVariables = {
	queryParams?: ListBudgetLineItemChargesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgetLineItemCharges = (
	variables: ListBudgetLineItemChargesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetLineItemChargeSchema,
		ListBudgetLineItemChargesError,
		undefined,
		{},
		ListBudgetLineItemChargesQueryParams,
		{}
	>({
		url: '/api/accounting/budget-line-item-charges/',
		method: 'get',
		...variables,
		signal
	})

export const useListBudgetLineItemCharges = <
	TData = Schemas.PagedBudgetLineItemChargeSchema
>(
	variables: ListBudgetLineItemChargesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetLineItemChargeSchema,
			ListBudgetLineItemChargesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetLineItemChargeSchema,
		ListBudgetLineItemChargesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-item-charges/',
			operationId: 'listBudgetLineItemCharges',
			variables
		}),
		({ signal }) =>
			fetchListBudgetLineItemCharges(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBudgetLineItemChargePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBudgetLineItemChargeError = Fetcher.ErrorWrapper<undefined>

export type DeleteBudgetLineItemChargeVariables = {
	pathParams: DeleteBudgetLineItemChargePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBudgetLineItemCharge = (
	variables: DeleteBudgetLineItemChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBudgetLineItemChargeError,
		undefined,
		{},
		{},
		DeleteBudgetLineItemChargePathParams
	>({
		url: '/api/accounting/budget-line-item-charges/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBudgetLineItemCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBudgetLineItemChargeError,
			DeleteBudgetLineItemChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBudgetLineItemChargeError,
		DeleteBudgetLineItemChargeVariables
	>(
		(variables: DeleteBudgetLineItemChargeVariables) =>
			fetchDeleteBudgetLineItemCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBudgetLineItemChargeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBudgetLineItemChargeDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetBudgetLineItemChargeDetailsVariables = {
	pathParams: GetBudgetLineItemChargeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBudgetLineItemChargeDetails = (
	variables: GetBudgetLineItemChargeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BudgetLineItemChargeSchema,
		GetBudgetLineItemChargeDetailsError,
		undefined,
		{},
		{},
		GetBudgetLineItemChargeDetailsPathParams
	>({
		url: '/api/accounting/budget-line-item-charges/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBudgetLineItemChargeDetails = <
	TData = Schemas.BudgetLineItemChargeSchema
>(
	variables: GetBudgetLineItemChargeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BudgetLineItemChargeSchema,
			GetBudgetLineItemChargeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BudgetLineItemChargeSchema,
		GetBudgetLineItemChargeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-item-charges/{id}/',
			operationId: 'getBudgetLineItemChargeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBudgetLineItemChargeDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBudgetLineItemChargePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBudgetLineItemChargeError = Fetcher.ErrorWrapper<undefined>

export type UpdateBudgetLineItemChargeVariables = {
	body: Schemas.BudgetLineItemChargeSchema
	pathParams: UpdateBudgetLineItemChargePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBudgetLineItemCharge = (
	variables: UpdateBudgetLineItemChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBudgetLineItemChargeError,
		Schemas.BudgetLineItemChargeSchema,
		{},
		{},
		UpdateBudgetLineItemChargePathParams
	>({
		url: '/api/accounting/budget-line-item-charges/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBudgetLineItemCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBudgetLineItemChargeError,
			UpdateBudgetLineItemChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBudgetLineItemChargeError,
		UpdateBudgetLineItemChargeVariables
	>(
		(variables: UpdateBudgetLineItemChargeVariables) =>
			fetchUpdateBudgetLineItemCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBudgetLineItemError = Fetcher.ErrorWrapper<undefined>

export type CreateBudgetLineItemVariables = {
	body: Schemas.BudgetLineItemSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBudgetLineItem = (
	variables: CreateBudgetLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBudgetLineItemError,
		Schemas.BudgetLineItemSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/budget-line-items/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBudgetLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBudgetLineItemError,
			CreateBudgetLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBudgetLineItemError,
		CreateBudgetLineItemVariables
	>(
		(variables: CreateBudgetLineItemVariables) =>
			fetchCreateBudgetLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBudgetLineItemsQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetLineItemsError = Fetcher.ErrorWrapper<undefined>

export type ListBudgetLineItemsVariables = {
	queryParams?: ListBudgetLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgetLineItems = (
	variables: ListBudgetLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetLineItemDetailSchema,
		ListBudgetLineItemsError,
		undefined,
		{},
		ListBudgetLineItemsQueryParams,
		{}
	>({
		url: '/api/accounting/budget-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListBudgetLineItems = <
	TData = Schemas.PagedBudgetLineItemDetailSchema
>(
	variables: ListBudgetLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetLineItemDetailSchema,
			ListBudgetLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetLineItemDetailSchema,
		ListBudgetLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-items/',
			operationId: 'listBudgetLineItems',
			variables
		}),
		({ signal }) =>
			fetchListBudgetLineItems({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBudgetLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBudgetLineItemError = Fetcher.ErrorWrapper<undefined>

export type DeleteBudgetLineItemVariables = {
	pathParams: DeleteBudgetLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBudgetLineItem = (
	variables: DeleteBudgetLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBudgetLineItemError,
		undefined,
		{},
		{},
		DeleteBudgetLineItemPathParams
	>({
		url: '/api/accounting/budget-line-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBudgetLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBudgetLineItemError,
			DeleteBudgetLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBudgetLineItemError,
		DeleteBudgetLineItemVariables
	>(
		(variables: DeleteBudgetLineItemVariables) =>
			fetchDeleteBudgetLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBudgetLineItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBudgetLineItemDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBudgetLineItemDetailsVariables = {
	pathParams: GetBudgetLineItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBudgetLineItemDetails = (
	variables: GetBudgetLineItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BudgetLineItemSchema,
		GetBudgetLineItemDetailsError,
		undefined,
		{},
		{},
		GetBudgetLineItemDetailsPathParams
	>({
		url: '/api/accounting/budget-line-items/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBudgetLineItemDetails = <
	TData = Schemas.BudgetLineItemSchema
>(
	variables: GetBudgetLineItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BudgetLineItemSchema,
			GetBudgetLineItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BudgetLineItemSchema,
		GetBudgetLineItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-items/{id}/',
			operationId: 'getBudgetLineItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBudgetLineItemDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBudgetLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBudgetLineItemError = Fetcher.ErrorWrapper<undefined>

export type UpdateBudgetLineItemVariables = {
	body: Schemas.BudgetLineItemSchema
	pathParams: UpdateBudgetLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBudgetLineItem = (
	variables: UpdateBudgetLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBudgetLineItemError,
		Schemas.BudgetLineItemSchema,
		{},
		{},
		UpdateBudgetLineItemPathParams
	>({
		url: '/api/accounting/budget-line-items/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBudgetLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBudgetLineItemError,
			UpdateBudgetLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBudgetLineItemError,
		UpdateBudgetLineItemVariables
	>(
		(variables: UpdateBudgetLineItemVariables) =>
			fetchUpdateBudgetLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBudgetLineItemCategoriesQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetLineItemCategoriesError = Fetcher.ErrorWrapper<undefined>

export type ListBudgetLineItemCategoriesVariables = {
	queryParams?: ListBudgetLineItemCategoriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgetLineItemCategories = (
	variables: ListBudgetLineItemCategoriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetLineItemCategorySchema,
		ListBudgetLineItemCategoriesError,
		undefined,
		{},
		ListBudgetLineItemCategoriesQueryParams,
		{}
	>({
		url: '/api/accounting/budget-line-items/categories',
		method: 'get',
		...variables,
		signal
	})

export const useListBudgetLineItemCategories = <
	TData = Schemas.PagedBudgetLineItemCategorySchema
>(
	variables: ListBudgetLineItemCategoriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetLineItemCategorySchema,
			ListBudgetLineItemCategoriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetLineItemCategorySchema,
		ListBudgetLineItemCategoriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-items/categories',
			operationId: 'listBudgetLineItemCategories',
			variables
		}),
		({ signal }) =>
			fetchListBudgetLineItemCategories(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListBudgetLineItemSubcategoriesQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetLineItemSubcategoriesError =
	Fetcher.ErrorWrapper<undefined>

export type ListBudgetLineItemSubcategoriesVariables = {
	queryParams?: ListBudgetLineItemSubcategoriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgetLineItemSubcategories = (
	variables: ListBudgetLineItemSubcategoriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetLineItemSubcategorySchema,
		ListBudgetLineItemSubcategoriesError,
		undefined,
		{},
		ListBudgetLineItemSubcategoriesQueryParams,
		{}
	>({
		url: '/api/accounting/budget-line-items/subcategories',
		method: 'get',
		...variables,
		signal
	})

export const useListBudgetLineItemSubcategories = <
	TData = Schemas.PagedBudgetLineItemSubcategorySchema
>(
	variables: ListBudgetLineItemSubcategoriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetLineItemSubcategorySchema,
			ListBudgetLineItemSubcategoriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetLineItemSubcategorySchema,
		ListBudgetLineItemSubcategoriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budget-line-items/subcategories',
			operationId: 'listBudgetLineItemSubcategories',
			variables
		}),
		({ signal }) =>
			fetchListBudgetLineItemSubcategories(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ApproveBudgetPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ApproveBudgetError = Fetcher.ErrorWrapper<{
	status: 403
	payload: Schemas.ErrorSchema
}>

export type ApproveBudgetVariables = {
	pathParams: ApproveBudgetPathParams
} & RentalizeContext['fetcherOptions']

/**
 * Marks a budget as approved.
 *
 * To send requests to this endpoint and actually approve the budget, a User must have two permissions set:
 *
 * * User.has_acccounting_permissions
 * * User.has_budget_approval_authority
 *
 * Lacking either of this permissions will result in this endpoint returning an HTTP 403.
 */
export const fetchApproveBudget = (
	variables: ApproveBudgetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		ApproveBudgetError,
		undefined,
		{},
		{},
		ApproveBudgetPathParams
	>({
		url: '/api/accounting/budgets/{id}/approve/',
		method: 'post',
		...variables,
		signal
	})

/**
 * Marks a budget as approved.
 *
 * To send requests to this endpoint and actually approve the budget, a User must have two permissions set:
 *
 * * User.has_acccounting_permissions
 * * User.has_budget_approval_authority
 *
 * Lacking either of this permissions will result in this endpoint returning an HTTP 403.
 */
export const useApproveBudget = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			ApproveBudgetError,
			ApproveBudgetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		ApproveBudgetError,
		ApproveBudgetVariables
	>(
		(variables: ApproveBudgetVariables) =>
			fetchApproveBudget({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBudgetError = Fetcher.ErrorWrapper<undefined>

export type CreateBudgetVariables = {
	body: Schemas.BudgetCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBudget = (
	variables: CreateBudgetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBudgetError,
		Schemas.BudgetCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/accounting/budgets/', method: 'post', ...variables, signal })

export const useCreateBudget = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBudgetError,
			CreateBudgetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBudgetError,
		CreateBudgetVariables
	>(
		(variables: CreateBudgetVariables) =>
			fetchCreateBudget({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBudgetsQueryParams = {
	/**
	 * @default ALL
	 */
	budget_timeline?:
		| 'ACTIVE_BUDGETS'
		| 'UPCOMING_BUDGETS'
		| 'COMPLETED_BUDGETS'
		| 'ALL'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetsError = Fetcher.ErrorWrapper<undefined>

export type ListBudgetsVariables = {
	queryParams?: ListBudgetsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgets = (
	variables: ListBudgetsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetDetailSchema,
		ListBudgetsError,
		undefined,
		{},
		ListBudgetsQueryParams,
		{}
	>({ url: '/api/accounting/budgets/', method: 'get', ...variables, signal })

export const useListBudgets = <TData = Schemas.PagedBudgetDetailSchema>(
	variables: ListBudgetsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetDetailSchema,
			ListBudgetsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetDetailSchema,
		ListBudgetsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budgets/',
			operationId: 'listBudgets',
			variables
		}),
		({ signal }) =>
			fetchListBudgets({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBudgetPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBudgetError = Fetcher.ErrorWrapper<undefined>

export type DeleteBudgetVariables = {
	pathParams: DeleteBudgetPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBudget = (
	variables: DeleteBudgetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBudgetError,
		undefined,
		{},
		{},
		DeleteBudgetPathParams
	>({
		url: '/api/accounting/budgets/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBudget = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBudgetError,
			DeleteBudgetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBudgetError,
		DeleteBudgetVariables
	>(
		(variables: DeleteBudgetVariables) =>
			fetchDeleteBudget({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBudgetDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBudgetDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBudgetDetailsVariables = {
	pathParams: GetBudgetDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBudgetDetails = (
	variables: GetBudgetDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BudgetDetailSchema,
		GetBudgetDetailsError,
		undefined,
		{},
		{},
		GetBudgetDetailsPathParams
	>({
		url: '/api/accounting/budgets/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBudgetDetails = <TData = Schemas.BudgetDetailSchema>(
	variables: GetBudgetDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BudgetDetailSchema,
			GetBudgetDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BudgetDetailSchema,
		GetBudgetDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budgets/{id}/',
			operationId: 'getBudgetDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBudgetDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBudgetPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBudgetError = Fetcher.ErrorWrapper<undefined>

export type UpdateBudgetVariables = {
	body: Schemas.BudgetUpdateSchema
	pathParams: UpdateBudgetPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBudget = (
	variables: UpdateBudgetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBudgetError,
		Schemas.BudgetUpdateSchema,
		{},
		{},
		UpdateBudgetPathParams
	>({
		url: '/api/accounting/budgets/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBudget = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBudgetError,
			UpdateBudgetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBudgetError,
		UpdateBudgetVariables
	>(
		(variables: UpdateBudgetVariables) =>
			fetchUpdateBudget({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBudgetTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBudgetTypesError = Fetcher.ErrorWrapper<undefined>

export type ListBudgetTypesVariables = {
	queryParams?: ListBudgetTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBudgetTypes = (
	variables: ListBudgetTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBudgetTypeSchema,
		ListBudgetTypesError,
		undefined,
		{},
		ListBudgetTypesQueryParams,
		{}
	>({
		url: '/api/accounting/budgets/budget-types',
		method: 'get',
		...variables,
		signal
	})

export const useListBudgetTypes = <TData = Schemas.PagedBudgetTypeSchema>(
	variables: ListBudgetTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBudgetTypeSchema,
			ListBudgetTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBudgetTypeSchema,
		ListBudgetTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/budgets/budget-types',
			operationId: 'listBudgetTypes',
			variables
		}),
		({ signal }) =>
			fetchListBudgetTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetBulkBillPaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBulkBillPaymentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBulkBillPaymentDetailsVariables = {
	pathParams: GetBulkBillPaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBulkBillPaymentDetails = (
	variables: GetBulkBillPaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BulkBillPaymentDetailSchema,
		GetBulkBillPaymentDetailsError,
		undefined,
		{},
		{},
		GetBulkBillPaymentDetailsPathParams
	>({
		url: '/api/accounting/bulk-bill-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBulkBillPaymentDetails = <
	TData = Schemas.BulkBillPaymentDetailSchema
>(
	variables: GetBulkBillPaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BulkBillPaymentDetailSchema,
			GetBulkBillPaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BulkBillPaymentDetailSchema,
		GetBulkBillPaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bulk-bill-payments/{id}/',
			operationId: 'getBulkBillPaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBulkBillPaymentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListBulkBillPaymentsQueryParams = {
	bill_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBulkBillPaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListBulkBillPaymentsVariables = {
	queryParams?: ListBulkBillPaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBulkBillPayments = (
	variables: ListBulkBillPaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBulkBillPaymentDetailSchema,
		ListBulkBillPaymentsError,
		undefined,
		{},
		ListBulkBillPaymentsQueryParams,
		{}
	>({
		url: '/api/accounting/bulk-bill-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListBulkBillPayments = <
	TData = Schemas.PagedBulkBillPaymentDetailSchema
>(
	variables: ListBulkBillPaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBulkBillPaymentDetailSchema,
			ListBulkBillPaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBulkBillPaymentDetailSchema,
		ListBulkBillPaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bulk-bill-payments/',
			operationId: 'listBulkBillPayments',
			variables
		}),
		({ signal }) =>
			fetchListBulkBillPayments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetBulkInvoicePaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBulkInvoicePaymentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBulkInvoicePaymentDetailsVariables = {
	pathParams: GetBulkInvoicePaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBulkInvoicePaymentDetails = (
	variables: GetBulkInvoicePaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BulkInvoicePaymentSchema,
		GetBulkInvoicePaymentDetailsError,
		undefined,
		{},
		{},
		GetBulkInvoicePaymentDetailsPathParams
	>({
		url: '/api/accounting/bulk-invoice-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBulkInvoicePaymentDetails = <
	TData = Schemas.BulkInvoicePaymentSchema
>(
	variables: GetBulkInvoicePaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BulkInvoicePaymentSchema,
			GetBulkInvoicePaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BulkInvoicePaymentSchema,
		GetBulkInvoicePaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bulk-invoice-payments/{id}/',
			operationId: 'getBulkInvoicePaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBulkInvoicePaymentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListBulkInvoicePaymentsQueryParams = {
	invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBulkInvoicePaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListBulkInvoicePaymentsVariables = {
	queryParams?: ListBulkInvoicePaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBulkInvoicePayments = (
	variables: ListBulkInvoicePaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBulkInvoicePaymentSchema,
		ListBulkInvoicePaymentsError,
		undefined,
		{},
		ListBulkInvoicePaymentsQueryParams,
		{}
	>({
		url: '/api/accounting/bulk-invoice-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListBulkInvoicePayments = <
	TData = Schemas.PagedBulkInvoicePaymentSchema
>(
	variables: ListBulkInvoicePaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBulkInvoicePaymentSchema,
			ListBulkInvoicePaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBulkInvoicePaymentSchema,
		ListBulkInvoicePaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/bulk-invoice-payments/',
			operationId: 'listBulkInvoicePayments',
			variables
		}),
		({ signal }) =>
			fetchListBulkInvoicePayments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateDefaultGlAccountError = Fetcher.ErrorWrapper<undefined>

export type CreateDefaultGlAccountVariables = {
	body: Schemas.DefaultGLAccountSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateDefaultGlAccount = (
	variables: CreateDefaultGlAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateDefaultGlAccountError,
		Schemas.DefaultGLAccountSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/default-gl-accounts/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateDefaultGlAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateDefaultGlAccountError,
			CreateDefaultGlAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateDefaultGlAccountError,
		CreateDefaultGlAccountVariables
	>(
		(variables: CreateDefaultGlAccountVariables) =>
			fetchCreateDefaultGlAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListDefaultGlAccountsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListDefaultGlAccountsError = Fetcher.ErrorWrapper<undefined>

export type ListDefaultGlAccountsVariables = {
	queryParams?: ListDefaultGlAccountsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListDefaultGlAccounts = (
	variables: ListDefaultGlAccountsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedDefaultGLAccountSchema,
		ListDefaultGlAccountsError,
		undefined,
		{},
		ListDefaultGlAccountsQueryParams,
		{}
	>({
		url: '/api/accounting/default-gl-accounts/',
		method: 'get',
		...variables,
		signal
	})

export const useListDefaultGlAccounts = <
	TData = Schemas.PagedDefaultGLAccountSchema
>(
	variables: ListDefaultGlAccountsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedDefaultGLAccountSchema,
			ListDefaultGlAccountsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedDefaultGLAccountSchema,
		ListDefaultGlAccountsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/default-gl-accounts/',
			operationId: 'listDefaultGlAccounts',
			variables
		}),
		({ signal }) =>
			fetchListDefaultGlAccounts({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteDefaultGlAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteDefaultGlAccountError = Fetcher.ErrorWrapper<undefined>

export type DeleteDefaultGlAccountVariables = {
	pathParams: DeleteDefaultGlAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteDefaultGlAccount = (
	variables: DeleteDefaultGlAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteDefaultGlAccountError,
		undefined,
		{},
		{},
		DeleteDefaultGlAccountPathParams
	>({
		url: '/api/accounting/default-gl-accounts/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteDefaultGlAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteDefaultGlAccountError,
			DeleteDefaultGlAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteDefaultGlAccountError,
		DeleteDefaultGlAccountVariables
	>(
		(variables: DeleteDefaultGlAccountVariables) =>
			fetchDeleteDefaultGlAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetDefaultGlAccountDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetDefaultGlAccountDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetDefaultGlAccountDetailsVariables = {
	pathParams: GetDefaultGlAccountDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetDefaultGlAccountDetails = (
	variables: GetDefaultGlAccountDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DefaultGLAccountSchema,
		GetDefaultGlAccountDetailsError,
		undefined,
		{},
		{},
		GetDefaultGlAccountDetailsPathParams
	>({
		url: '/api/accounting/default-gl-accounts/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetDefaultGlAccountDetails = <
	TData = Schemas.DefaultGLAccountSchema
>(
	variables: GetDefaultGlAccountDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DefaultGLAccountSchema,
			GetDefaultGlAccountDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.DefaultGLAccountSchema,
		GetDefaultGlAccountDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/default-gl-accounts/{id}/',
			operationId: 'getDefaultGlAccountDetails',
			variables
		}),
		({ signal }) =>
			fetchGetDefaultGlAccountDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateDefaultGlAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateDefaultGlAccountError = Fetcher.ErrorWrapper<undefined>

export type UpdateDefaultGlAccountVariables = {
	body: Schemas.DefaultGLAccountSchema
	pathParams: UpdateDefaultGlAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateDefaultGlAccount = (
	variables: UpdateDefaultGlAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateDefaultGlAccountError,
		Schemas.DefaultGLAccountSchema,
		{},
		{},
		UpdateDefaultGlAccountPathParams
	>({
		url: '/api/accounting/default-gl-accounts/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateDefaultGlAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateDefaultGlAccountError,
			UpdateDefaultGlAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateDefaultGlAccountError,
		UpdateDefaultGlAccountVariables
	>(
		(variables: UpdateDefaultGlAccountVariables) =>
			fetchUpdateDefaultGlAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateImportedTransactionError = Fetcher.ErrorWrapper<undefined>

export type CreateImportedTransactionVariables = {
	body: Schemas.ImportedTransactionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateImportedTransaction = (
	variables: CreateImportedTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateImportedTransactionError,
		Schemas.ImportedTransactionSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/imported-transactions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateImportedTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateImportedTransactionError,
			CreateImportedTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateImportedTransactionError,
		CreateImportedTransactionVariables
	>(
		(variables: CreateImportedTransactionVariables) =>
			fetchCreateImportedTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListImportedTransactionsQueryParams = {
	account_id?: string | null
	bill_id?: string | null
	journal_entry_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListImportedTransactionsError = Fetcher.ErrorWrapper<undefined>

export type ListImportedTransactionsVariables = {
	queryParams?: ListImportedTransactionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListImportedTransactions = (
	variables: ListImportedTransactionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedImportedTransactionDetailSchema,
		ListImportedTransactionsError,
		undefined,
		{},
		ListImportedTransactionsQueryParams,
		{}
	>({
		url: '/api/accounting/imported-transactions/',
		method: 'get',
		...variables,
		signal
	})

export const useListImportedTransactions = <
	TData = Schemas.PagedImportedTransactionDetailSchema
>(
	variables: ListImportedTransactionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedImportedTransactionDetailSchema,
			ListImportedTransactionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedImportedTransactionDetailSchema,
		ListImportedTransactionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/imported-transactions/',
			operationId: 'listImportedTransactions',
			variables
		}),
		({ signal }) =>
			fetchListImportedTransactions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteImportedTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteImportedTransactionError = Fetcher.ErrorWrapper<undefined>

export type DeleteImportedTransactionVariables = {
	pathParams: DeleteImportedTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteImportedTransaction = (
	variables: DeleteImportedTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteImportedTransactionError,
		undefined,
		{},
		{},
		DeleteImportedTransactionPathParams
	>({
		url: '/api/accounting/imported-transactions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteImportedTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteImportedTransactionError,
			DeleteImportedTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteImportedTransactionError,
		DeleteImportedTransactionVariables
	>(
		(variables: DeleteImportedTransactionVariables) =>
			fetchDeleteImportedTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetImportedTransactionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetImportedTransactionDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetImportedTransactionDetailsVariables = {
	pathParams: GetImportedTransactionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetImportedTransactionDetails = (
	variables: GetImportedTransactionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ImportedTransactionDetailSchema,
		GetImportedTransactionDetailsError,
		undefined,
		{},
		{},
		GetImportedTransactionDetailsPathParams
	>({
		url: '/api/accounting/imported-transactions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetImportedTransactionDetails = <
	TData = Schemas.ImportedTransactionDetailSchema
>(
	variables: GetImportedTransactionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ImportedTransactionDetailSchema,
			GetImportedTransactionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ImportedTransactionDetailSchema,
		GetImportedTransactionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/imported-transactions/{id}/',
			operationId: 'getImportedTransactionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetImportedTransactionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateImportedTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateImportedTransactionError = Fetcher.ErrorWrapper<undefined>

export type UpdateImportedTransactionVariables = {
	body: Schemas.ImportedTransactionSchema
	pathParams: UpdateImportedTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateImportedTransaction = (
	variables: UpdateImportedTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateImportedTransactionError,
		Schemas.ImportedTransactionSchema,
		{},
		{},
		UpdateImportedTransactionPathParams
	>({
		url: '/api/accounting/imported-transactions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateImportedTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateImportedTransactionError,
			UpdateImportedTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateImportedTransactionError,
		UpdateImportedTransactionVariables
	>(
		(variables: UpdateImportedTransactionVariables) =>
			fetchUpdateImportedTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInvoiceLineItemError = Fetcher.ErrorWrapper<undefined>

export type CreateInvoiceLineItemVariables = {
	body: Schemas.InvoiceLineItemCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInvoiceLineItem = (
	variables: CreateInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInvoiceLineItemError,
		Schemas.InvoiceLineItemCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/invoice-line-items/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInvoiceLineItemError,
			CreateInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInvoiceLineItemError,
		CreateInvoiceLineItemVariables
	>(
		(variables: CreateInvoiceLineItemVariables) =>
			fetchCreateInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInvoiceLineItemsQueryParams = {
	invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInvoiceLineItemsError = Fetcher.ErrorWrapper<undefined>

export type ListInvoiceLineItemsVariables = {
	queryParams?: ListInvoiceLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInvoiceLineItems = (
	variables: ListInvoiceLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInvoiceLineItemDetailSchema,
		ListInvoiceLineItemsError,
		undefined,
		{},
		ListInvoiceLineItemsQueryParams,
		{}
	>({
		url: '/api/accounting/invoice-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListInvoiceLineItems = <
	TData = Schemas.PagedInvoiceLineItemDetailSchema
>(
	variables: ListInvoiceLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInvoiceLineItemDetailSchema,
			ListInvoiceLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInvoiceLineItemDetailSchema,
		ListInvoiceLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoice-line-items/',
			operationId: 'listInvoiceLineItems',
			variables
		}),
		({ signal }) =>
			fetchListInvoiceLineItems({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInvoiceLineItemError = Fetcher.ErrorWrapper<undefined>

export type DeleteInvoiceLineItemVariables = {
	pathParams: DeleteInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInvoiceLineItem = (
	variables: DeleteInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInvoiceLineItemError,
		undefined,
		{},
		{},
		DeleteInvoiceLineItemPathParams
	>({
		url: '/api/accounting/invoice-line-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInvoiceLineItemError,
			DeleteInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInvoiceLineItemError,
		DeleteInvoiceLineItemVariables
	>(
		(variables: DeleteInvoiceLineItemVariables) =>
			fetchDeleteInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInvoiceLineItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInvoiceLineItemDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInvoiceLineItemDetailsVariables = {
	pathParams: GetInvoiceLineItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInvoiceLineItemDetails = (
	variables: GetInvoiceLineItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InvoiceLineItemSchema,
		GetInvoiceLineItemDetailsError,
		undefined,
		{},
		{},
		GetInvoiceLineItemDetailsPathParams
	>({
		url: '/api/accounting/invoice-line-items/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInvoiceLineItemDetails = <
	TData = Schemas.InvoiceLineItemSchema
>(
	variables: GetInvoiceLineItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InvoiceLineItemSchema,
			GetInvoiceLineItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InvoiceLineItemSchema,
		GetInvoiceLineItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoice-line-items/{id}/',
			operationId: 'getInvoiceLineItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInvoiceLineItemDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInvoiceLineItemError = Fetcher.ErrorWrapper<undefined>

export type UpdateInvoiceLineItemVariables = {
	body: Schemas.InvoiceLineItemSchema
	pathParams: UpdateInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInvoiceLineItem = (
	variables: UpdateInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInvoiceLineItemError,
		Schemas.InvoiceLineItemSchema,
		{},
		{},
		UpdateInvoiceLineItemPathParams
	>({
		url: '/api/accounting/invoice-line-items/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInvoiceLineItemError,
			UpdateInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInvoiceLineItemError,
		UpdateInvoiceLineItemVariables
	>(
		(variables: UpdateInvoiceLineItemVariables) =>
			fetchUpdateInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInvoicePaymentError = Fetcher.ErrorWrapper<undefined>

export type CreateInvoicePaymentVariables = {
	body: Schemas.InvoicePaymentSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInvoicePayment = (
	variables: CreateInvoicePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInvoicePaymentError,
		Schemas.InvoicePaymentSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/invoice-payments/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateInvoicePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInvoicePaymentError,
			CreateInvoicePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInvoicePaymentError,
		CreateInvoicePaymentVariables
	>(
		(variables: CreateInvoicePaymentVariables) =>
			fetchCreateInvoicePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInvoicePaymentsQueryParams = {
	invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInvoicePaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListInvoicePaymentsVariables = {
	queryParams?: ListInvoicePaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInvoicePayments = (
	variables: ListInvoicePaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInvoicePaymentDetailSchema,
		ListInvoicePaymentsError,
		undefined,
		{},
		ListInvoicePaymentsQueryParams,
		{}
	>({
		url: '/api/accounting/invoice-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListInvoicePayments = <
	TData = Schemas.PagedInvoicePaymentDetailSchema
>(
	variables: ListInvoicePaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInvoicePaymentDetailSchema,
			ListInvoicePaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInvoicePaymentDetailSchema,
		ListInvoicePaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoice-payments/',
			operationId: 'listInvoicePayments',
			variables
		}),
		({ signal }) =>
			fetchListInvoicePayments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInvoicePaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInvoicePaymentError = Fetcher.ErrorWrapper<undefined>

export type DeleteInvoicePaymentVariables = {
	pathParams: DeleteInvoicePaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInvoicePayment = (
	variables: DeleteInvoicePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInvoicePaymentError,
		undefined,
		{},
		{},
		DeleteInvoicePaymentPathParams
	>({
		url: '/api/accounting/invoice-payments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInvoicePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInvoicePaymentError,
			DeleteInvoicePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInvoicePaymentError,
		DeleteInvoicePaymentVariables
	>(
		(variables: DeleteInvoicePaymentVariables) =>
			fetchDeleteInvoicePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInvoicePaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInvoicePaymentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInvoicePaymentDetailsVariables = {
	pathParams: GetInvoicePaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInvoicePaymentDetails = (
	variables: GetInvoicePaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InvoicePaymentSchema,
		GetInvoicePaymentDetailsError,
		undefined,
		{},
		{},
		GetInvoicePaymentDetailsPathParams
	>({
		url: '/api/accounting/invoice-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInvoicePaymentDetails = <
	TData = Schemas.InvoicePaymentSchema
>(
	variables: GetInvoicePaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InvoicePaymentSchema,
			GetInvoicePaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InvoicePaymentSchema,
		GetInvoicePaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoice-payments/{id}/',
			operationId: 'getInvoicePaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInvoicePaymentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInvoicePaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInvoicePaymentError = Fetcher.ErrorWrapper<undefined>

export type UpdateInvoicePaymentVariables = {
	body: Schemas.InvoicePaymentSchema
	pathParams: UpdateInvoicePaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInvoicePayment = (
	variables: UpdateInvoicePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInvoicePaymentError,
		Schemas.InvoicePaymentSchema,
		{},
		{},
		UpdateInvoicePaymentPathParams
	>({
		url: '/api/accounting/invoice-payments/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInvoicePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInvoicePaymentError,
			UpdateInvoicePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInvoicePaymentError,
		UpdateInvoicePaymentVariables
	>(
		(variables: UpdateInvoicePaymentVariables) =>
			fetchUpdateInvoicePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type BulkMarkInvoicesAsPaidError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: Record<string, any>
	  }
	| {
			status: 404
			payload: Record<string, any>
	  }
>

export type BulkMarkInvoicesAsPaidVariables = {
	body: Schemas.BulkMarkInvoiceAsPaidSchema
} & RentalizeContext['fetcherOptions']

export const fetchBulkMarkInvoicesAsPaid = (
	variables: BulkMarkInvoicesAsPaidVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		BulkMarkInvoicesAsPaidError,
		Schemas.BulkMarkInvoiceAsPaidSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/invoices/bulk-mark-as-paid/',
		method: 'post',
		...variables,
		signal
	})

export const useBulkMarkInvoicesAsPaid = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			BulkMarkInvoicesAsPaidError,
			BulkMarkInvoicesAsPaidVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		BulkMarkInvoicesAsPaidError,
		BulkMarkInvoicesAsPaidVariables
	>(
		(variables: BulkMarkInvoicesAsPaidVariables) =>
			fetchBulkMarkInvoicesAsPaid({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInvoiceError = Fetcher.ErrorWrapper<undefined>

export type CreateInvoiceVariables = {
	body: Schemas.InvoiceInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInvoice = (
	variables: CreateInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInvoiceError,
		Schemas.InvoiceInSchema,
		{},
		{},
		{}
	>({ url: '/api/accounting/invoices/', method: 'post', ...variables, signal })

export const useCreateInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInvoiceError,
			CreateInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInvoiceError,
		CreateInvoiceVariables
	>(
		(variables: CreateInvoiceVariables) =>
			fetchCreateInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInvoicesQueryParams = {
	bill_id?: string | null
	work_order_id?: string | null
	is_paid?: boolean | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInvoicesError = Fetcher.ErrorWrapper<undefined>

export type ListInvoicesVariables = {
	queryParams?: ListInvoicesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInvoices = (
	variables: ListInvoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInvoiceDetailSchema,
		ListInvoicesError,
		undefined,
		{},
		ListInvoicesQueryParams,
		{}
	>({ url: '/api/accounting/invoices/', method: 'get', ...variables, signal })

export const useListInvoices = <TData = Schemas.PagedInvoiceDetailSchema>(
	variables: ListInvoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInvoiceDetailSchema,
			ListInvoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInvoiceDetailSchema,
		ListInvoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoices/',
			operationId: 'listInvoices',
			variables
		}),
		({ signal }) =>
			fetchListInvoices({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInvoiceError = Fetcher.ErrorWrapper<undefined>

export type DeleteInvoiceVariables = {
	pathParams: DeleteInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInvoice = (
	variables: DeleteInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInvoiceError,
		undefined,
		{},
		{},
		DeleteInvoicePathParams
	>({
		url: '/api/accounting/invoices/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInvoiceError,
			DeleteInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInvoiceError,
		DeleteInvoiceVariables
	>(
		(variables: DeleteInvoiceVariables) =>
			fetchDeleteInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInvoiceDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInvoiceDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInvoiceDetailsVariables = {
	pathParams: GetInvoiceDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInvoiceDetails = (
	variables: GetInvoiceDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InvoiceDetailSchema,
		GetInvoiceDetailsError,
		undefined,
		{},
		{},
		GetInvoiceDetailsPathParams
	>({
		url: '/api/accounting/invoices/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInvoiceDetails = <TData = Schemas.InvoiceDetailSchema>(
	variables: GetInvoiceDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InvoiceDetailSchema,
			GetInvoiceDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InvoiceDetailSchema,
		GetInvoiceDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/invoices/{id}/',
			operationId: 'getInvoiceDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInvoiceDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInvoiceError = Fetcher.ErrorWrapper<undefined>

export type UpdateInvoiceVariables = {
	body: Schemas.InvoiceInSchema
	pathParams: UpdateInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInvoice = (
	variables: UpdateInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInvoiceError,
		Schemas.InvoiceInSchema,
		{},
		{},
		UpdateInvoicePathParams
	>({
		url: '/api/accounting/invoices/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInvoiceError,
			UpdateInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInvoiceError,
		UpdateInvoiceVariables
	>(
		(variables: UpdateInvoiceVariables) =>
			fetchUpdateInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type DuplicateInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DuplicateInvoiceError = Fetcher.ErrorWrapper<undefined>

export type DuplicateInvoiceVariables = {
	pathParams: DuplicateInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDuplicateInvoice = (
	variables: DuplicateInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DuplicatedInvoiceResponse,
		DuplicateInvoiceError,
		undefined,
		{},
		{},
		DuplicateInvoicePathParams
	>({
		url: '/api/accounting/invoices/{id}/duplicate/',
		method: 'post',
		...variables,
		signal
	})

export const useDuplicateInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DuplicatedInvoiceResponse,
			DuplicateInvoiceError,
			DuplicateInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DuplicatedInvoiceResponse,
		DuplicateInvoiceError,
		DuplicateInvoiceVariables
	>(
		(variables: DuplicateInvoiceVariables) =>
			fetchDuplicateInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateInvoicePdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateInvoicePdfError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateInvoicePdfVariables = {
	pathParams: GenerateInvoicePdfPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateInvoicePdf = (
	variables: GenerateInvoicePdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GenerateInvoicePdfError,
		undefined,
		{},
		{},
		GenerateInvoicePdfPathParams
	>({
		url: '/api/invoices/{id}/generate-pdf/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateInvoicePdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GenerateInvoicePdfError,
			GenerateInvoicePdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GenerateInvoicePdfError,
		GenerateInvoicePdfVariables
	>(
		(variables: GenerateInvoicePdfVariables) =>
			fetchGenerateInvoicePdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type RecordInvoicePaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type RecordInvoicePaymentError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type RecordInvoicePaymentVariables = {
	body: Schemas.RecordInvoicePaymentSchema
	pathParams: RecordInvoicePaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchRecordInvoicePayment = (
	variables: RecordInvoicePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RecordInvoicePaymentError,
		Schemas.RecordInvoicePaymentSchema,
		{},
		{},
		RecordInvoicePaymentPathParams
	>({
		url: '/api/invoices/{id}/record-payment/',
		method: 'post',
		...variables,
		signal
	})

export const useRecordInvoicePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RecordInvoicePaymentError,
			RecordInvoicePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RecordInvoicePaymentError,
		RecordInvoicePaymentVariables
	>(
		(variables: RecordInvoicePaymentVariables) =>
			fetchRecordInvoicePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateJournalEntryError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateJournalEntryVariables = {
	body: Schemas.JournalEntryInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateJournalEntry = (
	variables: CreateJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateJournalEntryError,
		Schemas.JournalEntryInSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/journal-entries/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateJournalEntryError,
			CreateJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateJournalEntryError,
		CreateJournalEntryVariables
	>(
		(variables: CreateJournalEntryVariables) =>
			fetchCreateJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListJournalEntriesQueryParams = {
	bill_id?: string | null
	invoice_id?: string | null
	property_owner_id?: string | null
	storage_unit_invoice_id?: string | null
	bill_payment_id?: string | null
	rent_payment_id?: string | null
	rent_receivable_id?: string | null
	created_manually?: boolean | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListJournalEntriesError = Fetcher.ErrorWrapper<undefined>

export type ListJournalEntriesVariables = {
	queryParams?: ListJournalEntriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListJournalEntries = (
	variables: ListJournalEntriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedJournalEntryOutSchema,
		ListJournalEntriesError,
		undefined,
		{},
		ListJournalEntriesQueryParams,
		{}
	>({
		url: '/api/accounting/journal-entries/',
		method: 'get',
		...variables,
		signal
	})

export const useListJournalEntries = <
	TData = Schemas.PagedJournalEntryOutSchema
>(
	variables: ListJournalEntriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedJournalEntryOutSchema,
			ListJournalEntriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedJournalEntryOutSchema,
		ListJournalEntriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/journal-entries/',
			operationId: 'listJournalEntries',
			variables
		}),
		({ signal }) =>
			fetchListJournalEntries({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteJournalEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteJournalEntryError = Fetcher.ErrorWrapper<undefined>

export type DeleteJournalEntryVariables = {
	pathParams: DeleteJournalEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteJournalEntry = (
	variables: DeleteJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteJournalEntryError,
		undefined,
		{},
		{},
		DeleteJournalEntryPathParams
	>({
		url: '/api/accounting/journal-entries/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteJournalEntryError,
			DeleteJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteJournalEntryError,
		DeleteJournalEntryVariables
	>(
		(variables: DeleteJournalEntryVariables) =>
			fetchDeleteJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetJournalEntryDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetJournalEntryDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetJournalEntryDetailsVariables = {
	pathParams: GetJournalEntryDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetJournalEntryDetails = (
	variables: GetJournalEntryDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.JournalEntryDetailSchema,
		GetJournalEntryDetailsError,
		undefined,
		{},
		{},
		GetJournalEntryDetailsPathParams
	>({
		url: '/api/accounting/journal-entries/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetJournalEntryDetails = <
	TData = Schemas.JournalEntryDetailSchema
>(
	variables: GetJournalEntryDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.JournalEntryDetailSchema,
			GetJournalEntryDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.JournalEntryDetailSchema,
		GetJournalEntryDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/journal-entries/{id}/',
			operationId: 'getJournalEntryDetails',
			variables
		}),
		({ signal }) =>
			fetchGetJournalEntryDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateJournalEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateJournalEntryError = Fetcher.ErrorWrapper<undefined>

export type UpdateJournalEntryVariables = {
	body: Schemas.JournalEntryInSchema
	pathParams: UpdateJournalEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateJournalEntry = (
	variables: UpdateJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateJournalEntryError,
		Schemas.JournalEntryInSchema,
		{},
		{},
		UpdateJournalEntryPathParams
	>({
		url: '/api/accounting/journal-entries/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateJournalEntryError,
			UpdateJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateJournalEntryError,
		UpdateJournalEntryVariables
	>(
		(variables: UpdateJournalEntryVariables) =>
			fetchUpdateJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSalesTaxRatesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSalesTaxRatesError = Fetcher.ErrorWrapper<undefined>

export type ListSalesTaxRatesVariables = {
	queryParams?: ListSalesTaxRatesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSalesTaxRates = (
	variables: ListSalesTaxRatesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSalesTaxRateSchema,
		ListSalesTaxRatesError,
		undefined,
		{},
		ListSalesTaxRatesQueryParams,
		{}
	>({
		url: '/api/accounting/sales-tax-rates/',
		method: 'get',
		...variables,
		signal
	})

export const useListSalesTaxRates = <TData = Schemas.PagedSalesTaxRateSchema>(
	variables: ListSalesTaxRatesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSalesTaxRateSchema,
			ListSalesTaxRatesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSalesTaxRateSchema,
		ListSalesTaxRatesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/sales-tax-rates/',
			operationId: 'listSalesTaxRates',
			variables
		}),
		({ signal }) =>
			fetchListSalesTaxRates({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateMarkupTypeError = Fetcher.ErrorWrapper<undefined>

export type CreateMarkupTypeVariables = {
	body: Schemas.MarkupTypeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMarkupType = (
	variables: CreateMarkupTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMarkupTypeError,
		Schemas.MarkupTypeSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/markup-types/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateMarkupType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMarkupTypeError,
			CreateMarkupTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMarkupTypeError,
		CreateMarkupTypeVariables
	>(
		(variables: CreateMarkupTypeVariables) =>
			fetchCreateMarkupType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMarkupTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMarkupTypesError = Fetcher.ErrorWrapper<undefined>

export type ListMarkupTypesVariables = {
	queryParams?: ListMarkupTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMarkupTypes = (
	variables: ListMarkupTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMarkupTypeDetailSchema,
		ListMarkupTypesError,
		undefined,
		{},
		ListMarkupTypesQueryParams,
		{}
	>({
		url: '/api/accounting/markup-types/',
		method: 'get',
		...variables,
		signal
	})

export const useListMarkupTypes = <TData = Schemas.PagedMarkupTypeDetailSchema>(
	variables: ListMarkupTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMarkupTypeDetailSchema,
			ListMarkupTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMarkupTypeDetailSchema,
		ListMarkupTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/markup-types/',
			operationId: 'listMarkupTypes',
			variables
		}),
		({ signal }) =>
			fetchListMarkupTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMarkupTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMarkupTypeError = Fetcher.ErrorWrapper<undefined>

export type DeleteMarkupTypeVariables = {
	pathParams: DeleteMarkupTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMarkupType = (
	variables: DeleteMarkupTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMarkupTypeError,
		undefined,
		{},
		{},
		DeleteMarkupTypePathParams
	>({
		url: '/api/accounting/markup-types/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMarkupType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMarkupTypeError,
			DeleteMarkupTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMarkupTypeError,
		DeleteMarkupTypeVariables
	>(
		(variables: DeleteMarkupTypeVariables) =>
			fetchDeleteMarkupType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMarkupTypeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMarkupTypeDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMarkupTypeDetailsVariables = {
	pathParams: GetMarkupTypeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMarkupTypeDetails = (
	variables: GetMarkupTypeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MarkupTypeDetailSchema,
		GetMarkupTypeDetailsError,
		undefined,
		{},
		{},
		GetMarkupTypeDetailsPathParams
	>({
		url: '/api/accounting/markup-types/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMarkupTypeDetails = <TData = Schemas.MarkupTypeDetailSchema>(
	variables: GetMarkupTypeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MarkupTypeDetailSchema,
			GetMarkupTypeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MarkupTypeDetailSchema,
		GetMarkupTypeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/markup-types/{id}/',
			operationId: 'getMarkupTypeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMarkupTypeDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMarkupTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMarkupTypeError = Fetcher.ErrorWrapper<undefined>

export type UpdateMarkupTypeVariables = {
	body: Schemas.MarkupTypeSchema
	pathParams: UpdateMarkupTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMarkupType = (
	variables: UpdateMarkupTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMarkupTypeError,
		Schemas.MarkupTypeSchema,
		{},
		{},
		UpdateMarkupTypePathParams
	>({
		url: '/api/accounting/markup-types/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateMarkupType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMarkupTypeError,
			UpdateMarkupTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMarkupTypeError,
		UpdateMarkupTypeVariables
	>(
		(variables: UpdateMarkupTypeVariables) =>
			fetchUpdateMarkupType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePurchaseOrderLineItemError = Fetcher.ErrorWrapper<undefined>

export type CreatePurchaseOrderLineItemVariables = {
	body: Schemas.PurchaseOrderLineItemSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePurchaseOrderLineItem = (
	variables: CreatePurchaseOrderLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePurchaseOrderLineItemError,
		Schemas.PurchaseOrderLineItemSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/purchase-order-line-items/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePurchaseOrderLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePurchaseOrderLineItemError,
			CreatePurchaseOrderLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePurchaseOrderLineItemError,
		CreatePurchaseOrderLineItemVariables
	>(
		(variables: CreatePurchaseOrderLineItemVariables) =>
			fetchCreatePurchaseOrderLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPurchaseOrderLineItemsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPurchaseOrderLineItemsError = Fetcher.ErrorWrapper<undefined>

export type ListPurchaseOrderLineItemsVariables = {
	queryParams?: ListPurchaseOrderLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPurchaseOrderLineItems = (
	variables: ListPurchaseOrderLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPurchaseOrderLineItemSchema,
		ListPurchaseOrderLineItemsError,
		undefined,
		{},
		ListPurchaseOrderLineItemsQueryParams,
		{}
	>({
		url: '/api/accounting/purchase-order-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListPurchaseOrderLineItems = <
	TData = Schemas.PagedPurchaseOrderLineItemSchema
>(
	variables: ListPurchaseOrderLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPurchaseOrderLineItemSchema,
			ListPurchaseOrderLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPurchaseOrderLineItemSchema,
		ListPurchaseOrderLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/purchase-order-line-items/',
			operationId: 'listPurchaseOrderLineItems',
			variables
		}),
		({ signal }) =>
			fetchListPurchaseOrderLineItems(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePurchaseOrderLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePurchaseOrderLineItemError = Fetcher.ErrorWrapper<undefined>

export type DeletePurchaseOrderLineItemVariables = {
	pathParams: DeletePurchaseOrderLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePurchaseOrderLineItem = (
	variables: DeletePurchaseOrderLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePurchaseOrderLineItemError,
		undefined,
		{},
		{},
		DeletePurchaseOrderLineItemPathParams
	>({
		url: '/api/accounting/purchase-order-line-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePurchaseOrderLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePurchaseOrderLineItemError,
			DeletePurchaseOrderLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePurchaseOrderLineItemError,
		DeletePurchaseOrderLineItemVariables
	>(
		(variables: DeletePurchaseOrderLineItemVariables) =>
			fetchDeletePurchaseOrderLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPurchaseOrderLineItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPurchaseOrderLineItemDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPurchaseOrderLineItemDetailsVariables = {
	pathParams: GetPurchaseOrderLineItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPurchaseOrderLineItemDetails = (
	variables: GetPurchaseOrderLineItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PurchaseOrderLineItemSchema,
		GetPurchaseOrderLineItemDetailsError,
		undefined,
		{},
		{},
		GetPurchaseOrderLineItemDetailsPathParams
	>({
		url: '/api/accounting/purchase-order-line-items/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPurchaseOrderLineItemDetails = <
	TData = Schemas.PurchaseOrderLineItemSchema
>(
	variables: GetPurchaseOrderLineItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PurchaseOrderLineItemSchema,
			GetPurchaseOrderLineItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PurchaseOrderLineItemSchema,
		GetPurchaseOrderLineItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/purchase-order-line-items/{id}/',
			operationId: 'getPurchaseOrderLineItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPurchaseOrderLineItemDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePurchaseOrderLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePurchaseOrderLineItemError = Fetcher.ErrorWrapper<undefined>

export type UpdatePurchaseOrderLineItemVariables = {
	body: Schemas.PurchaseOrderLineItemSchema
	pathParams: UpdatePurchaseOrderLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePurchaseOrderLineItem = (
	variables: UpdatePurchaseOrderLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePurchaseOrderLineItemError,
		Schemas.PurchaseOrderLineItemSchema,
		{},
		{},
		UpdatePurchaseOrderLineItemPathParams
	>({
		url: '/api/accounting/purchase-order-line-items/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePurchaseOrderLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePurchaseOrderLineItemError,
			UpdatePurchaseOrderLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePurchaseOrderLineItemError,
		UpdatePurchaseOrderLineItemVariables
	>(
		(variables: UpdatePurchaseOrderLineItemVariables) =>
			fetchUpdatePurchaseOrderLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ApprovePurchaseOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ApprovePurchaseOrderError = Fetcher.ErrorWrapper<{
	status: 403
	payload: Schemas.ErrorSchema
}>

export type ApprovePurchaseOrderVariables = {
	pathParams: ApprovePurchaseOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchApprovePurchaseOrder = (
	variables: ApprovePurchaseOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		ApprovePurchaseOrderError,
		undefined,
		{},
		{},
		ApprovePurchaseOrderPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/approve/',
		method: 'post',
		...variables,
		signal
	})

export const useApprovePurchaseOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			ApprovePurchaseOrderError,
			ApprovePurchaseOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		ApprovePurchaseOrderError,
		ApprovePurchaseOrderVariables
	>(
		(variables: ApprovePurchaseOrderVariables) =>
			fetchApprovePurchaseOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePurchaseOrderError = Fetcher.ErrorWrapper<undefined>

export type CreatePurchaseOrderVariables = {
	body: Schemas.PurchaseOrderInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePurchaseOrder = (
	variables: CreatePurchaseOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePurchaseOrderError,
		Schemas.PurchaseOrderInSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/purchase-orders/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePurchaseOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePurchaseOrderError,
			CreatePurchaseOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePurchaseOrderError,
		CreatePurchaseOrderVariables
	>(
		(variables: CreatePurchaseOrderVariables) =>
			fetchCreatePurchaseOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPurchaseOrdersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPurchaseOrdersError = Fetcher.ErrorWrapper<undefined>

export type ListPurchaseOrdersVariables = {
	queryParams?: ListPurchaseOrdersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPurchaseOrders = (
	variables: ListPurchaseOrdersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedListPurchaseOrdersSchema,
		ListPurchaseOrdersError,
		undefined,
		{},
		ListPurchaseOrdersQueryParams,
		{}
	>({
		url: '/api/accounting/purchase-orders/',
		method: 'get',
		...variables,
		signal
	})

export const useListPurchaseOrders = <
	TData = Schemas.PagedListPurchaseOrdersSchema
>(
	variables: ListPurchaseOrdersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedListPurchaseOrdersSchema,
			ListPurchaseOrdersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedListPurchaseOrdersSchema,
		ListPurchaseOrdersError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/purchase-orders/',
			operationId: 'listPurchaseOrders',
			variables
		}),
		({ signal }) =>
			fetchListPurchaseOrders({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePurchaseOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePurchaseOrderError = Fetcher.ErrorWrapper<undefined>

export type DeletePurchaseOrderVariables = {
	pathParams: DeletePurchaseOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePurchaseOrder = (
	variables: DeletePurchaseOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePurchaseOrderError,
		undefined,
		{},
		{},
		DeletePurchaseOrderPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePurchaseOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePurchaseOrderError,
			DeletePurchaseOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePurchaseOrderError,
		DeletePurchaseOrderVariables
	>(
		(variables: DeletePurchaseOrderVariables) =>
			fetchDeletePurchaseOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPurchaseOrderDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPurchaseOrderDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPurchaseOrderDetailsVariables = {
	pathParams: GetPurchaseOrderDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPurchaseOrderDetails = (
	variables: GetPurchaseOrderDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PurchaseOrderDetailSchema,
		GetPurchaseOrderDetailsError,
		undefined,
		{},
		{},
		GetPurchaseOrderDetailsPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPurchaseOrderDetails = <
	TData = Schemas.PurchaseOrderDetailSchema
>(
	variables: GetPurchaseOrderDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PurchaseOrderDetailSchema,
			GetPurchaseOrderDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PurchaseOrderDetailSchema,
		GetPurchaseOrderDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/purchase-orders/{id}/',
			operationId: 'getPurchaseOrderDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPurchaseOrderDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePurchaseOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePurchaseOrderError = Fetcher.ErrorWrapper<undefined>

export type UpdatePurchaseOrderVariables = {
	body: Schemas.PurchaseOrderInSchema
	pathParams: UpdatePurchaseOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePurchaseOrder = (
	variables: UpdatePurchaseOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePurchaseOrderError,
		Schemas.PurchaseOrderInSchema,
		{},
		{},
		UpdatePurchaseOrderPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePurchaseOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePurchaseOrderError,
			UpdatePurchaseOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePurchaseOrderError,
		UpdatePurchaseOrderVariables
	>(
		(variables: UpdatePurchaseOrderVariables) =>
			fetchUpdatePurchaseOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type MarkPurchaseOrderAsFulfilledPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type MarkPurchaseOrderAsFulfilledError = Fetcher.ErrorWrapper<{
	status: 403
	payload: Schemas.ErrorSchema
}>

export type MarkPurchaseOrderAsFulfilledVariables = {
	pathParams: MarkPurchaseOrderAsFulfilledPathParams
} & RentalizeContext['fetcherOptions']

export const fetchMarkPurchaseOrderAsFulfilled = (
	variables: MarkPurchaseOrderAsFulfilledVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		MarkPurchaseOrderAsFulfilledError,
		undefined,
		{},
		{},
		MarkPurchaseOrderAsFulfilledPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/fulfill/',
		method: 'post',
		...variables,
		signal
	})

export const useMarkPurchaseOrderAsFulfilled = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			MarkPurchaseOrderAsFulfilledError,
			MarkPurchaseOrderAsFulfilledVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		MarkPurchaseOrderAsFulfilledError,
		MarkPurchaseOrderAsFulfilledVariables
	>(
		(variables: MarkPurchaseOrderAsFulfilledVariables) =>
			fetchMarkPurchaseOrderAsFulfilled({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GeneratePurchaseOrderPdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GeneratePurchaseOrderPdfError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GeneratePurchaseOrderPdfVariables = {
	pathParams: GeneratePurchaseOrderPdfPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGeneratePurchaseOrderPdf = (
	variables: GeneratePurchaseOrderPdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GeneratePurchaseOrderPdfError,
		undefined,
		{},
		{},
		GeneratePurchaseOrderPdfPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/generate-pdf/',
		method: 'post',
		...variables,
		signal
	})

export const useGeneratePurchaseOrderPdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GeneratePurchaseOrderPdfError,
			GeneratePurchaseOrderPdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GeneratePurchaseOrderPdfError,
		GeneratePurchaseOrderPdfVariables
	>(
		(variables: GeneratePurchaseOrderPdfVariables) =>
			fetchGeneratePurchaseOrderPdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPurchaseOrderStatusesQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPurchaseOrderStatusesError = Fetcher.ErrorWrapper<undefined>

export type ListPurchaseOrderStatusesVariables = {
	queryParams?: ListPurchaseOrderStatusesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPurchaseOrderStatuses = (
	variables: ListPurchaseOrderStatusesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPurchaseOrderStatusSchema,
		ListPurchaseOrderStatusesError,
		undefined,
		{},
		ListPurchaseOrderStatusesQueryParams,
		{}
	>({
		url: '/api/purchase-orders/statuses',
		method: 'get',
		...variables,
		signal
	})

export const useListPurchaseOrderStatuses = <
	TData = Schemas.PagedPurchaseOrderStatusSchema
>(
	variables: ListPurchaseOrderStatusesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPurchaseOrderStatusSchema,
			ListPurchaseOrderStatusesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPurchaseOrderStatusSchema,
		ListPurchaseOrderStatusesError,
		TData
	>(
		queryKeyFn({
			path: '/api/purchase-orders/statuses',
			operationId: 'listPurchaseOrderStatuses',
			variables
		}),
		({ signal }) =>
			fetchListPurchaseOrderStatuses(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type RejectPurchaseOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type RejectPurchaseOrderError = Fetcher.ErrorWrapper<{
	status: 403
	payload: Schemas.ErrorSchema
}>

export type RejectPurchaseOrderVariables = {
	body: Schemas.RejectPurchaseOrderSchema
	pathParams: RejectPurchaseOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchRejectPurchaseOrder = (
	variables: RejectPurchaseOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RejectPurchaseOrderError,
		Schemas.RejectPurchaseOrderSchema,
		{},
		{},
		RejectPurchaseOrderPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/reject/',
		method: 'post',
		...variables,
		signal
	})

export const useRejectPurchaseOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RejectPurchaseOrderError,
			RejectPurchaseOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RejectPurchaseOrderError,
		RejectPurchaseOrderVariables
	>(
		(variables: RejectPurchaseOrderVariables) =>
			fetchRejectPurchaseOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type SendPurchaseOrderForApprovalPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type SendPurchaseOrderForApprovalError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type SendPurchaseOrderForApprovalVariables = {
	pathParams: SendPurchaseOrderForApprovalPathParams
} & RentalizeContext['fetcherOptions']

export const fetchSendPurchaseOrderForApproval = (
	variables: SendPurchaseOrderForApprovalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		SendPurchaseOrderForApprovalError,
		undefined,
		{},
		{},
		SendPurchaseOrderForApprovalPathParams
	>({
		url: '/api/accounting/purchase-orders/{id}/send-for-approval/',
		method: 'post',
		...variables,
		signal
	})

export const useSendPurchaseOrderForApproval = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			SendPurchaseOrderForApprovalError,
			SendPurchaseOrderForApprovalVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		SendPurchaseOrderForApprovalError,
		SendPurchaseOrderForApprovalVariables
	>(
		(variables: SendPurchaseOrderForApprovalVariables) =>
			fetchSendPurchaseOrderForApproval({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type CreateRecurringInvoiceLineItemVariables = {
	body: Schemas.RecurringInvoiceLineItemCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringInvoiceLineItem = (
	variables: CreateRecurringInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringInvoiceLineItemError,
		Schemas.RecurringInvoiceLineItemCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/recurring-invoice-line-items/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRecurringInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringInvoiceLineItemError,
			CreateRecurringInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringInvoiceLineItemError,
		CreateRecurringInvoiceLineItemVariables
	>(
		(variables: CreateRecurringInvoiceLineItemVariables) =>
			fetchCreateRecurringInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringInvoiceLineItemsQueryParams = {
	invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringInvoiceLineItemsError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringInvoiceLineItemsVariables = {
	queryParams?: ListRecurringInvoiceLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringInvoiceLineItems = (
	variables: ListRecurringInvoiceLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringInvoiceLineItemDetailSchema,
		ListRecurringInvoiceLineItemsError,
		undefined,
		{},
		ListRecurringInvoiceLineItemsQueryParams,
		{}
	>({
		url: '/api/accounting/recurring-invoice-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListRecurringInvoiceLineItems = <
	TData = Schemas.PagedRecurringInvoiceLineItemDetailSchema
>(
	variables: ListRecurringInvoiceLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringInvoiceLineItemDetailSchema,
			ListRecurringInvoiceLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringInvoiceLineItemDetailSchema,
		ListRecurringInvoiceLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-invoice-line-items/',
			operationId: 'listRecurringInvoiceLineItems',
			variables
		}),
		({ signal }) =>
			fetchListRecurringInvoiceLineItems(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringInvoiceLineItemVariables = {
	pathParams: DeleteRecurringInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringInvoiceLineItem = (
	variables: DeleteRecurringInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringInvoiceLineItemError,
		undefined,
		{},
		{},
		DeleteRecurringInvoiceLineItemPathParams
	>({
		url: '/api/accounting/recurring-invoice-line-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringInvoiceLineItemError,
			DeleteRecurringInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringInvoiceLineItemError,
		DeleteRecurringInvoiceLineItemVariables
	>(
		(variables: DeleteRecurringInvoiceLineItemVariables) =>
			fetchDeleteRecurringInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringInvoiceLineItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringInvoiceLineItemDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRecurringInvoiceLineItemDetailsVariables = {
	pathParams: GetRecurringInvoiceLineItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringInvoiceLineItemDetails = (
	variables: GetRecurringInvoiceLineItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringInvoiceLineItemSchema,
		GetRecurringInvoiceLineItemDetailsError,
		undefined,
		{},
		{},
		GetRecurringInvoiceLineItemDetailsPathParams
	>({
		url: '/api/accounting/recurring-invoice-line-items/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRecurringInvoiceLineItemDetails = <
	TData = Schemas.RecurringInvoiceLineItemSchema
>(
	variables: GetRecurringInvoiceLineItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringInvoiceLineItemSchema,
			GetRecurringInvoiceLineItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringInvoiceLineItemSchema,
		GetRecurringInvoiceLineItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-invoice-line-items/{id}/',
			operationId: 'getRecurringInvoiceLineItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringInvoiceLineItemDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringInvoiceLineItemVariables = {
	body: Schemas.RecurringInvoiceLineItemSchema
	pathParams: UpdateRecurringInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringInvoiceLineItem = (
	variables: UpdateRecurringInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringInvoiceLineItemError,
		Schemas.RecurringInvoiceLineItemSchema,
		{},
		{},
		UpdateRecurringInvoiceLineItemPathParams
	>({
		url: '/api/accounting/recurring-invoice-line-items/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRecurringInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringInvoiceLineItemError,
			UpdateRecurringInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringInvoiceLineItemError,
		UpdateRecurringInvoiceLineItemVariables
	>(
		(variables: UpdateRecurringInvoiceLineItemVariables) =>
			fetchUpdateRecurringInvoiceLineItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringInvoiceError = Fetcher.ErrorWrapper<undefined>

export type CreateRecurringInvoiceVariables = {
	body: Schemas.RecurringInvoiceCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringInvoice = (
	variables: CreateRecurringInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringInvoiceError,
		Schemas.RecurringInvoiceCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/recurring-invoices/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRecurringInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringInvoiceError,
			CreateRecurringInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringInvoiceError,
		CreateRecurringInvoiceVariables
	>(
		(variables: CreateRecurringInvoiceVariables) =>
			fetchCreateRecurringInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringInvoicesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringInvoicesError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringInvoicesVariables = {
	queryParams?: ListRecurringInvoicesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringInvoices = (
	variables: ListRecurringInvoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringInvoiceDetailSchema,
		ListRecurringInvoicesError,
		undefined,
		{},
		ListRecurringInvoicesQueryParams,
		{}
	>({
		url: '/api/accounting/recurring-invoices/',
		method: 'get',
		...variables,
		signal
	})

export const useListRecurringInvoices = <
	TData = Schemas.PagedRecurringInvoiceDetailSchema
>(
	variables: ListRecurringInvoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringInvoiceDetailSchema,
			ListRecurringInvoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringInvoiceDetailSchema,
		ListRecurringInvoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-invoices/',
			operationId: 'listRecurringInvoices',
			variables
		}),
		({ signal }) =>
			fetchListRecurringInvoices({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringInvoiceError = Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringInvoiceVariables = {
	pathParams: DeleteRecurringInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringInvoice = (
	variables: DeleteRecurringInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringInvoiceError,
		undefined,
		{},
		{},
		DeleteRecurringInvoicePathParams
	>({
		url: '/api/accounting/recurring-invoices/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringInvoiceError,
			DeleteRecurringInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringInvoiceError,
		DeleteRecurringInvoiceVariables
	>(
		(variables: DeleteRecurringInvoiceVariables) =>
			fetchDeleteRecurringInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringInvoiceDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringInvoiceDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRecurringInvoiceDetailsVariables = {
	pathParams: GetRecurringInvoiceDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringInvoiceDetails = (
	variables: GetRecurringInvoiceDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringInvoiceSchema,
		GetRecurringInvoiceDetailsError,
		undefined,
		{},
		{},
		GetRecurringInvoiceDetailsPathParams
	>({
		url: '/api/accounting/recurring-invoices/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRecurringInvoiceDetails = <
	TData = Schemas.RecurringInvoiceSchema
>(
	variables: GetRecurringInvoiceDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringInvoiceSchema,
			GetRecurringInvoiceDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringInvoiceSchema,
		GetRecurringInvoiceDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-invoices/{id}/',
			operationId: 'getRecurringInvoiceDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringInvoiceDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringInvoiceError = Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringInvoiceVariables = {
	body: Schemas.RecurringInvoiceSchema
	pathParams: UpdateRecurringInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringInvoice = (
	variables: UpdateRecurringInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringInvoiceError,
		Schemas.RecurringInvoiceSchema,
		{},
		{},
		UpdateRecurringInvoicePathParams
	>({
		url: '/api/accounting/recurring-invoices/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRecurringInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringInvoiceError,
			UpdateRecurringInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringInvoiceError,
		UpdateRecurringInvoiceVariables
	>(
		(variables: UpdateRecurringInvoiceVariables) =>
			fetchUpdateRecurringInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringTransactionError = Fetcher.ErrorWrapper<undefined>

export type CreateRecurringTransactionVariables = {
	body: Schemas.RecurringTransactionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringTransaction = (
	variables: CreateRecurringTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringTransactionError,
		Schemas.RecurringTransactionSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/recurring-transactions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRecurringTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringTransactionError,
			CreateRecurringTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringTransactionError,
		CreateRecurringTransactionVariables
	>(
		(variables: CreateRecurringTransactionVariables) =>
			fetchCreateRecurringTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringTransactionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringTransactionsError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringTransactionsVariables = {
	queryParams?: ListRecurringTransactionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringTransactions = (
	variables: ListRecurringTransactionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringTransactionDetailSchema,
		ListRecurringTransactionsError,
		undefined,
		{},
		ListRecurringTransactionsQueryParams,
		{}
	>({
		url: '/api/accounting/recurring-transactions/',
		method: 'get',
		...variables,
		signal
	})

export const useListRecurringTransactions = <
	TData = Schemas.PagedRecurringTransactionDetailSchema
>(
	variables: ListRecurringTransactionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringTransactionDetailSchema,
			ListRecurringTransactionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringTransactionDetailSchema,
		ListRecurringTransactionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-transactions/',
			operationId: 'listRecurringTransactions',
			variables
		}),
		({ signal }) =>
			fetchListRecurringTransactions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringTransactionError = Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringTransactionVariables = {
	pathParams: DeleteRecurringTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringTransaction = (
	variables: DeleteRecurringTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringTransactionError,
		undefined,
		{},
		{},
		DeleteRecurringTransactionPathParams
	>({
		url: '/api/accounting/recurring-transactions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringTransactionError,
			DeleteRecurringTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringTransactionError,
		DeleteRecurringTransactionVariables
	>(
		(variables: DeleteRecurringTransactionVariables) =>
			fetchDeleteRecurringTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringTransactionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringTransactionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRecurringTransactionDetailsVariables = {
	pathParams: GetRecurringTransactionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringTransactionDetails = (
	variables: GetRecurringTransactionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringTransactionSchema,
		GetRecurringTransactionDetailsError,
		undefined,
		{},
		{},
		GetRecurringTransactionDetailsPathParams
	>({
		url: '/api/accounting/recurring-transactions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRecurringTransactionDetails = <
	TData = Schemas.RecurringTransactionSchema
>(
	variables: GetRecurringTransactionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringTransactionSchema,
			GetRecurringTransactionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringTransactionSchema,
		GetRecurringTransactionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/recurring-transactions/{id}/',
			operationId: 'getRecurringTransactionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringTransactionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringTransactionError = Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringTransactionVariables = {
	body: Schemas.RecurringTransactionSchema
	pathParams: UpdateRecurringTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringTransaction = (
	variables: UpdateRecurringTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringTransactionError,
		Schemas.RecurringTransactionSchema,
		{},
		{},
		UpdateRecurringTransactionPathParams
	>({
		url: '/api/accounting/recurring-transactions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRecurringTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringTransactionError,
			UpdateRecurringTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringTransactionError,
		UpdateRecurringTransactionVariables
	>(
		(variables: UpdateRecurringTransactionVariables) =>
			fetchUpdateRecurringTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRemittanceLetterError = Fetcher.ErrorWrapper<undefined>

export type CreateRemittanceLetterVariables = {
	body: Schemas.RemittanceLetterSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRemittanceLetter = (
	variables: CreateRemittanceLetterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRemittanceLetterError,
		Schemas.RemittanceLetterSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/remittance-letters/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRemittanceLetter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRemittanceLetterError,
			CreateRemittanceLetterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRemittanceLetterError,
		CreateRemittanceLetterVariables
	>(
		(variables: CreateRemittanceLetterVariables) =>
			fetchCreateRemittanceLetter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRemittanceLettersQueryParams = {
	bulk_bill_payment_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRemittanceLettersError = Fetcher.ErrorWrapper<undefined>

export type ListRemittanceLettersVariables = {
	queryParams?: ListRemittanceLettersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRemittanceLetters = (
	variables: ListRemittanceLettersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRemittanceLetterDetailSchema,
		ListRemittanceLettersError,
		undefined,
		{},
		ListRemittanceLettersQueryParams,
		{}
	>({
		url: '/api/accounting/remittance-letters/',
		method: 'get',
		...variables,
		signal
	})

export const useListRemittanceLetters = <
	TData = Schemas.PagedRemittanceLetterDetailSchema
>(
	variables: ListRemittanceLettersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRemittanceLetterDetailSchema,
			ListRemittanceLettersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRemittanceLetterDetailSchema,
		ListRemittanceLettersError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/remittance-letters/',
			operationId: 'listRemittanceLetters',
			variables
		}),
		({ signal }) =>
			fetchListRemittanceLetters({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRemittanceLetterPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRemittanceLetterError = Fetcher.ErrorWrapper<undefined>

export type DeleteRemittanceLetterVariables = {
	pathParams: DeleteRemittanceLetterPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRemittanceLetter = (
	variables: DeleteRemittanceLetterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRemittanceLetterError,
		undefined,
		{},
		{},
		DeleteRemittanceLetterPathParams
	>({
		url: '/api/accounting/remittance-letters/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRemittanceLetter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRemittanceLetterError,
			DeleteRemittanceLetterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRemittanceLetterError,
		DeleteRemittanceLetterVariables
	>(
		(variables: DeleteRemittanceLetterVariables) =>
			fetchDeleteRemittanceLetter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRemittanceLetterDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRemittanceLetterDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRemittanceLetterDetailsVariables = {
	pathParams: GetRemittanceLetterDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRemittanceLetterDetails = (
	variables: GetRemittanceLetterDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RemittanceLetterDetailSchema,
		GetRemittanceLetterDetailsError,
		undefined,
		{},
		{},
		GetRemittanceLetterDetailsPathParams
	>({
		url: '/api/accounting/remittance-letters/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRemittanceLetterDetails = <
	TData = Schemas.RemittanceLetterDetailSchema
>(
	variables: GetRemittanceLetterDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RemittanceLetterDetailSchema,
			GetRemittanceLetterDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RemittanceLetterDetailSchema,
		GetRemittanceLetterDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/remittance-letters/{id}/',
			operationId: 'getRemittanceLetterDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRemittanceLetterDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRemittanceLetterPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRemittanceLetterError = Fetcher.ErrorWrapper<undefined>

export type UpdateRemittanceLetterVariables = {
	body: Schemas.RemittanceLetterSchema
	pathParams: UpdateRemittanceLetterPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRemittanceLetter = (
	variables: UpdateRemittanceLetterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRemittanceLetterError,
		Schemas.RemittanceLetterSchema,
		{},
		{},
		UpdateRemittanceLetterPathParams
	>({
		url: '/api/accounting/remittance-letters/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRemittanceLetter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRemittanceLetterError,
			UpdateRemittanceLetterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRemittanceLetterError,
		UpdateRemittanceLetterVariables
	>(
		(variables: UpdateRemittanceLetterVariables) =>
			fetchUpdateRemittanceLetter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type EmailRemittanceLetterToVendorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type EmailRemittanceLetterToVendorError = Fetcher.ErrorWrapper<undefined>

export type EmailRemittanceLetterToVendorVariables = {
	pathParams: EmailRemittanceLetterToVendorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchEmailRemittanceLetterToVendor = (
	variables: EmailRemittanceLetterToVendorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		EmailRemittanceLetterToVendorError,
		undefined,
		{},
		{},
		EmailRemittanceLetterToVendorPathParams
	>({
		url: '/api/accounting/remittance-letters/{id}/email-to-vendor/',
		method: 'post',
		...variables,
		signal
	})

export const useEmailRemittanceLetterToVendor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			EmailRemittanceLetterToVendorError,
			EmailRemittanceLetterToVendorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		EmailRemittanceLetterToVendorError,
		EmailRemittanceLetterToVendorVariables
	>(
		(variables: EmailRemittanceLetterToVendorVariables) =>
			fetchEmailRemittanceLetterToVendor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateTransactionImportLogError = Fetcher.ErrorWrapper<undefined>

export type CreateTransactionImportLogVariables = {
	body: Schemas.TransactionImportLogSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateTransactionImportLog = (
	variables: CreateTransactionImportLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateTransactionImportLogError,
		Schemas.TransactionImportLogSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/transaction-import-logs/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateTransactionImportLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateTransactionImportLogError,
			CreateTransactionImportLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateTransactionImportLogError,
		CreateTransactionImportLogVariables
	>(
		(variables: CreateTransactionImportLogVariables) =>
			fetchCreateTransactionImportLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListTransactionImportLogsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListTransactionImportLogsError = Fetcher.ErrorWrapper<undefined>

export type ListTransactionImportLogsVariables = {
	queryParams?: ListTransactionImportLogsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListTransactionImportLogs = (
	variables: ListTransactionImportLogsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedTransactionImportLogSchema,
		ListTransactionImportLogsError,
		undefined,
		{},
		ListTransactionImportLogsQueryParams,
		{}
	>({
		url: '/api/accounting/transaction-import-logs/',
		method: 'get',
		...variables,
		signal
	})

export const useListTransactionImportLogs = <
	TData = Schemas.PagedTransactionImportLogSchema
>(
	variables: ListTransactionImportLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedTransactionImportLogSchema,
			ListTransactionImportLogsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedTransactionImportLogSchema,
		ListTransactionImportLogsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/transaction-import-logs/',
			operationId: 'listTransactionImportLogs',
			variables
		}),
		({ signal }) =>
			fetchListTransactionImportLogs(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteTransactionImportLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteTransactionImportLogError = Fetcher.ErrorWrapper<undefined>

export type DeleteTransactionImportLogVariables = {
	pathParams: DeleteTransactionImportLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteTransactionImportLog = (
	variables: DeleteTransactionImportLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteTransactionImportLogError,
		undefined,
		{},
		{},
		DeleteTransactionImportLogPathParams
	>({
		url: '/api/accounting/transaction-import-logs/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteTransactionImportLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteTransactionImportLogError,
			DeleteTransactionImportLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteTransactionImportLogError,
		DeleteTransactionImportLogVariables
	>(
		(variables: DeleteTransactionImportLogVariables) =>
			fetchDeleteTransactionImportLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetTransactionImportLogDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetTransactionImportLogDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetTransactionImportLogDetailsVariables = {
	pathParams: GetTransactionImportLogDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetTransactionImportLogDetails = (
	variables: GetTransactionImportLogDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.TransactionImportLogSchema,
		GetTransactionImportLogDetailsError,
		undefined,
		{},
		{},
		GetTransactionImportLogDetailsPathParams
	>({
		url: '/api/accounting/transaction-import-logs/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetTransactionImportLogDetails = <
	TData = Schemas.TransactionImportLogSchema
>(
	variables: GetTransactionImportLogDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransactionImportLogSchema,
			GetTransactionImportLogDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.TransactionImportLogSchema,
		GetTransactionImportLogDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/transaction-import-logs/{id}/',
			operationId: 'getTransactionImportLogDetails',
			variables
		}),
		({ signal }) =>
			fetchGetTransactionImportLogDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateTransactionImportLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateTransactionImportLogError = Fetcher.ErrorWrapper<undefined>

export type UpdateTransactionImportLogVariables = {
	body: Schemas.TransactionImportLogSchema
	pathParams: UpdateTransactionImportLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateTransactionImportLog = (
	variables: UpdateTransactionImportLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateTransactionImportLogError,
		Schemas.TransactionImportLogSchema,
		{},
		{},
		UpdateTransactionImportLogPathParams
	>({
		url: '/api/accounting/transaction-import-logs/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateTransactionImportLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateTransactionImportLogError,
			UpdateTransactionImportLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateTransactionImportLogError,
		UpdateTransactionImportLogVariables
	>(
		(variables: UpdateTransactionImportLogVariables) =>
			fetchUpdateTransactionImportLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateTransactionError = Fetcher.ErrorWrapper<undefined>

export type CreateTransactionVariables = {
	body: Schemas.TransactionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateTransaction = (
	variables: CreateTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateTransactionError,
		Schemas.TransactionSchema,
		{},
		{},
		{}
	>({
		url: '/api/accounting/transactions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateTransactionError,
			CreateTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateTransactionError,
		CreateTransactionVariables
	>(
		(variables: CreateTransactionVariables) =>
			fetchCreateTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListTransactionsQueryParams = {
	start_date?: string | null
	end_date?: string | null
	account_id?: string | null
	bill_id?: string | null
	journal_entry_id?: string | null
	bank_reconciliation_id?: string | null
	tx_type?: ('debit' | 'credit') | null
	bank_account_id?: string | null
	/**
	 * @default false
	 */
	only_unreconciled_transactions?: boolean
	limit?: number | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListTransactionsError = Fetcher.ErrorWrapper<undefined>

export type ListTransactionsVariables = {
	queryParams?: ListTransactionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListTransactions = (
	variables: ListTransactionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedTransactionDetailSchema,
		ListTransactionsError,
		undefined,
		{},
		ListTransactionsQueryParams,
		{}
	>({
		url: '/api/accounting/transactions/',
		method: 'get',
		...variables,
		signal
	})

export const useListTransactions = <
	TData = Schemas.PagedTransactionDetailSchema
>(
	variables: ListTransactionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedTransactionDetailSchema,
			ListTransactionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedTransactionDetailSchema,
		ListTransactionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/transactions/',
			operationId: 'listTransactions',
			variables
		}),
		({ signal }) =>
			fetchListTransactions({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteTransactionError = Fetcher.ErrorWrapper<undefined>

export type DeleteTransactionVariables = {
	pathParams: DeleteTransactionPathParams
} & RentalizeContext['fetcherOptions']

/**
 * Deletes a transaction, and the associated JournalEntry (and other transactions for that JournalEntry)
 */
export const fetchDeleteTransaction = (
	variables: DeleteTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteTransactionError,
		undefined,
		{},
		{},
		DeleteTransactionPathParams
	>({
		url: '/api/accounting/transactions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

/**
 * Deletes a transaction, and the associated JournalEntry (and other transactions for that JournalEntry)
 */
export const useDeleteTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteTransactionError,
			DeleteTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteTransactionError,
		DeleteTransactionVariables
	>(
		(variables: DeleteTransactionVariables) =>
			fetchDeleteTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetTransactionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetTransactionDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetTransactionDetailsVariables = {
	pathParams: GetTransactionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetTransactionDetails = (
	variables: GetTransactionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.TransactionDetailSchema,
		GetTransactionDetailsError,
		undefined,
		{},
		{},
		GetTransactionDetailsPathParams
	>({
		url: '/api/accounting/transactions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetTransactionDetails = <
	TData = Schemas.TransactionDetailSchema
>(
	variables: GetTransactionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TransactionDetailSchema,
			GetTransactionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.TransactionDetailSchema,
		GetTransactionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/accounting/transactions/{id}/',
			operationId: 'getTransactionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetTransactionDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateTransactionError = Fetcher.ErrorWrapper<undefined>

export type UpdateTransactionVariables = {
	body: Schemas.TransactionSchema
	pathParams: UpdateTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateTransaction = (
	variables: UpdateTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateTransactionError,
		Schemas.TransactionSchema,
		{},
		{},
		UpdateTransactionPathParams
	>({
		url: '/api/accounting/transactions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateTransactionError,
			UpdateTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateTransactionError,
		UpdateTransactionVariables
	>(
		(variables: UpdateTransactionVariables) =>
			fetchUpdateTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAiQuestionError = Fetcher.ErrorWrapper<undefined>

export type CreateAiQuestionVariables = {
	body: Schemas.AIQuestionCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAiQuestion = (
	variables: CreateAiQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AIQuestionSchema,
		CreateAiQuestionError,
		Schemas.AIQuestionCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/ai-questions/', method: 'post', ...variables, signal })

export const useCreateAiQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.AIQuestionSchema,
			CreateAiQuestionError,
			CreateAiQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.AIQuestionSchema,
		CreateAiQuestionError,
		CreateAiQuestionVariables
	>(
		(variables: CreateAiQuestionVariables) =>
			fetchCreateAiQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAiQuestionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAiQuestionsError = Fetcher.ErrorWrapper<undefined>

export type ListAiQuestionsVariables = {
	queryParams?: ListAiQuestionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAiQuestions = (
	variables: ListAiQuestionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAIQuestionDetailSchema,
		ListAiQuestionsError,
		undefined,
		{},
		ListAiQuestionsQueryParams,
		{}
	>({ url: '/api/ai-questions/', method: 'get', ...variables, signal })

export const useListAiQuestions = <TData = Schemas.PagedAIQuestionDetailSchema>(
	variables: ListAiQuestionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAIQuestionDetailSchema,
			ListAiQuestionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAIQuestionDetailSchema,
		ListAiQuestionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-questions/',
			operationId: 'listAiQuestions',
			variables
		}),
		({ signal }) =>
			fetchListAiQuestions({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAiQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAiQuestionError = Fetcher.ErrorWrapper<undefined>

export type DeleteAiQuestionVariables = {
	pathParams: DeleteAiQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAiQuestion = (
	variables: DeleteAiQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAiQuestionError,
		undefined,
		{},
		{},
		DeleteAiQuestionPathParams
	>({ url: '/api/ai-questions/{id}/', method: 'delete', ...variables, signal })

export const useDeleteAiQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAiQuestionError,
			DeleteAiQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAiQuestionError,
		DeleteAiQuestionVariables
	>(
		(variables: DeleteAiQuestionVariables) =>
			fetchDeleteAiQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAiQuestionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAiQuestionDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetAiQuestionDetailsVariables = {
	pathParams: GetAiQuestionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAiQuestionDetails = (
	variables: GetAiQuestionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AIQuestionDetailSchema,
		GetAiQuestionDetailsError,
		undefined,
		{},
		{},
		GetAiQuestionDetailsPathParams
	>({ url: '/api/ai-questions/{id}/', method: 'get', ...variables, signal })

export const useGetAiQuestionDetails = <TData = Schemas.AIQuestionDetailSchema>(
	variables: GetAiQuestionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AIQuestionDetailSchema,
			GetAiQuestionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AIQuestionDetailSchema,
		GetAiQuestionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-questions/{id}/',
			operationId: 'getAiQuestionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAiQuestionDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAiQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAiQuestionError = Fetcher.ErrorWrapper<undefined>

export type UpdateAiQuestionVariables = {
	body: Schemas.AIQuestionSchema
	pathParams: UpdateAiQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAiQuestion = (
	variables: UpdateAiQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAiQuestionError,
		Schemas.AIQuestionSchema,
		{},
		{},
		UpdateAiQuestionPathParams
	>({ url: '/api/ai-questions/{id}/', method: 'put', ...variables, signal })

export const useUpdateAiQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAiQuestionError,
			UpdateAiQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAiQuestionError,
		UpdateAiQuestionVariables
	>(
		(variables: UpdateAiQuestionVariables) =>
			fetchUpdateAiQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAssistantThreadMessageError = Fetcher.ErrorWrapper<undefined>

export type CreateAssistantThreadMessageVariables = {
	body: Schemas.AssistantThreadMessageSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAssistantThreadMessage = (
	variables: CreateAssistantThreadMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAssistantThreadMessageError,
		Schemas.AssistantThreadMessageSchema,
		{},
		{},
		{}
	>({
		url: '/api/ai-assistant-thread-messages/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateAssistantThreadMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAssistantThreadMessageError,
			CreateAssistantThreadMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAssistantThreadMessageError,
		CreateAssistantThreadMessageVariables
	>(
		(variables: CreateAssistantThreadMessageVariables) =>
			fetchCreateAssistantThreadMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAssistantThreadMessagesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAssistantThreadMessagesError = Fetcher.ErrorWrapper<undefined>

export type ListAssistantThreadMessagesVariables = {
	queryParams?: ListAssistantThreadMessagesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAssistantThreadMessages = (
	variables: ListAssistantThreadMessagesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAssistantThreadMessageDetailSchema,
		ListAssistantThreadMessagesError,
		undefined,
		{},
		ListAssistantThreadMessagesQueryParams,
		{}
	>({
		url: '/api/ai-assistant-thread-messages/',
		method: 'get',
		...variables,
		signal
	})

export const useListAssistantThreadMessages = <
	TData = Schemas.PagedAssistantThreadMessageDetailSchema
>(
	variables: ListAssistantThreadMessagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAssistantThreadMessageDetailSchema,
			ListAssistantThreadMessagesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAssistantThreadMessageDetailSchema,
		ListAssistantThreadMessagesError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-assistant-thread-messages/',
			operationId: 'listAssistantThreadMessages',
			variables
		}),
		({ signal }) =>
			fetchListAssistantThreadMessages(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAssistantThreadMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAssistantThreadMessageError = Fetcher.ErrorWrapper<undefined>

export type DeleteAssistantThreadMessageVariables = {
	pathParams: DeleteAssistantThreadMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAssistantThreadMessage = (
	variables: DeleteAssistantThreadMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAssistantThreadMessageError,
		undefined,
		{},
		{},
		DeleteAssistantThreadMessagePathParams
	>({
		url: '/api/ai-assistant-thread-messages/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteAssistantThreadMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAssistantThreadMessageError,
			DeleteAssistantThreadMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAssistantThreadMessageError,
		DeleteAssistantThreadMessageVariables
	>(
		(variables: DeleteAssistantThreadMessageVariables) =>
			fetchDeleteAssistantThreadMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAssistantThreadMessageDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAssistantThreadMessageDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetAssistantThreadMessageDetailsVariables = {
	pathParams: GetAssistantThreadMessageDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAssistantThreadMessageDetails = (
	variables: GetAssistantThreadMessageDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AssistantThreadMessageDetailSchema,
		GetAssistantThreadMessageDetailsError,
		undefined,
		{},
		{},
		GetAssistantThreadMessageDetailsPathParams
	>({
		url: '/api/ai-assistant-thread-messages/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAssistantThreadMessageDetails = <
	TData = Schemas.AssistantThreadMessageDetailSchema
>(
	variables: GetAssistantThreadMessageDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AssistantThreadMessageDetailSchema,
			GetAssistantThreadMessageDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AssistantThreadMessageDetailSchema,
		GetAssistantThreadMessageDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-assistant-thread-messages/{id}/',
			operationId: 'getAssistantThreadMessageDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAssistantThreadMessageDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAssistantThreadMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAssistantThreadMessageError = Fetcher.ErrorWrapper<undefined>

export type UpdateAssistantThreadMessageVariables = {
	body: Schemas.AssistantThreadMessageSchema
	pathParams: UpdateAssistantThreadMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAssistantThreadMessage = (
	variables: UpdateAssistantThreadMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAssistantThreadMessageError,
		Schemas.AssistantThreadMessageSchema,
		{},
		{},
		UpdateAssistantThreadMessagePathParams
	>({
		url: '/api/ai-assistant-thread-messages/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAssistantThreadMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAssistantThreadMessageError,
			UpdateAssistantThreadMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAssistantThreadMessageError,
		UpdateAssistantThreadMessageVariables
	>(
		(variables: UpdateAssistantThreadMessageVariables) =>
			fetchUpdateAssistantThreadMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAssistantThreadError = Fetcher.ErrorWrapper<undefined>

export type CreateAssistantThreadVariables = {
	body: Schemas.AssistantThreadSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAssistantThread = (
	variables: CreateAssistantThreadVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAssistantThreadError,
		Schemas.AssistantThreadSchema,
		{},
		{},
		{}
	>({
		url: '/api/ai-assistant-threads/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateAssistantThread = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAssistantThreadError,
			CreateAssistantThreadVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAssistantThreadError,
		CreateAssistantThreadVariables
	>(
		(variables: CreateAssistantThreadVariables) =>
			fetchCreateAssistantThread({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAssistantThreadsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAssistantThreadsError = Fetcher.ErrorWrapper<undefined>

export type ListAssistantThreadsVariables = {
	queryParams?: ListAssistantThreadsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAssistantThreads = (
	variables: ListAssistantThreadsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAssistantThreadDetailSchema,
		ListAssistantThreadsError,
		undefined,
		{},
		ListAssistantThreadsQueryParams,
		{}
	>({ url: '/api/ai-assistant-threads/', method: 'get', ...variables, signal })

export const useListAssistantThreads = <
	TData = Schemas.PagedAssistantThreadDetailSchema
>(
	variables: ListAssistantThreadsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAssistantThreadDetailSchema,
			ListAssistantThreadsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAssistantThreadDetailSchema,
		ListAssistantThreadsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-assistant-threads/',
			operationId: 'listAssistantThreads',
			variables
		}),
		({ signal }) =>
			fetchListAssistantThreads({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAssistantThreadPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAssistantThreadError = Fetcher.ErrorWrapper<undefined>

export type DeleteAssistantThreadVariables = {
	pathParams: DeleteAssistantThreadPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAssistantThread = (
	variables: DeleteAssistantThreadVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAssistantThreadError,
		undefined,
		{},
		{},
		DeleteAssistantThreadPathParams
	>({
		url: '/api/ai-assistant-threads/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteAssistantThread = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAssistantThreadError,
			DeleteAssistantThreadVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAssistantThreadError,
		DeleteAssistantThreadVariables
	>(
		(variables: DeleteAssistantThreadVariables) =>
			fetchDeleteAssistantThread({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAssistantThreadDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAssistantThreadDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetAssistantThreadDetailsVariables = {
	pathParams: GetAssistantThreadDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAssistantThreadDetails = (
	variables: GetAssistantThreadDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AssistantThreadDetailSchema,
		GetAssistantThreadDetailsError,
		undefined,
		{},
		{},
		GetAssistantThreadDetailsPathParams
	>({
		url: '/api/ai-assistant-threads/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAssistantThreadDetails = <
	TData = Schemas.AssistantThreadDetailSchema
>(
	variables: GetAssistantThreadDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AssistantThreadDetailSchema,
			GetAssistantThreadDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AssistantThreadDetailSchema,
		GetAssistantThreadDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ai-assistant-threads/{id}/',
			operationId: 'getAssistantThreadDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAssistantThreadDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAssistantThreadPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAssistantThreadError = Fetcher.ErrorWrapper<undefined>

export type UpdateAssistantThreadVariables = {
	body: Schemas.AssistantThreadSchema
	pathParams: UpdateAssistantThreadPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAssistantThread = (
	variables: UpdateAssistantThreadVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAssistantThreadError,
		Schemas.AssistantThreadSchema,
		{},
		{},
		UpdateAssistantThreadPathParams
	>({
		url: '/api/ai-assistant-threads/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAssistantThread = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAssistantThreadError,
			UpdateAssistantThreadVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAssistantThreadError,
		UpdateAssistantThreadVariables
	>(
		(variables: UpdateAssistantThreadVariables) =>
			fetchUpdateAssistantThread({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateApiKeyError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: Schemas.ErrorSchema
	  }
	| {
			status: 403
			payload: Schemas.ErrorSchema
	  }
>

export type CreateApiKeyVariables = {
	body: Schemas.CreateAPIKeySchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateApiKey = (
	variables: CreateApiKeyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		string,
		CreateApiKeyError,
		Schemas.CreateAPIKeySchema,
		{},
		{},
		{}
	>({ url: '/api/api-keys/', method: 'post', ...variables, signal })

export const useCreateApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			string,
			CreateApiKeyError,
			CreateApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		string,
		CreateApiKeyError,
		CreateApiKeyVariables
	>(
		(variables: CreateApiKeyVariables) =>
			fetchCreateApiKey({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListApiKeysQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListApiKeysError = Fetcher.ErrorWrapper<undefined>

export type ListApiKeysVariables = {
	queryParams?: ListApiKeysQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListApiKeys = (
	variables: ListApiKeysVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAPIKeySchema,
		ListApiKeysError,
		undefined,
		{},
		ListApiKeysQueryParams,
		{}
	>({ url: '/api/api-keys/', method: 'get', ...variables, signal })

export const useListApiKeys = <TData = Schemas.PagedAPIKeySchema>(
	variables: ListApiKeysVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAPIKeySchema,
			ListApiKeysError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAPIKeySchema,
		ListApiKeysError,
		TData
	>(
		queryKeyFn({
			path: '/api/api-keys/',
			operationId: 'listApiKeys',
			variables
		}),
		({ signal }) =>
			fetchListApiKeys({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteApiKeyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteApiKeyError = Fetcher.ErrorWrapper<undefined>

export type DeleteApiKeyVariables = {
	pathParams: DeleteApiKeyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteApiKey = (
	variables: DeleteApiKeyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteApiKeyError,
		undefined,
		{},
		{},
		DeleteApiKeyPathParams
	>({ url: '/api/api-keys/{id}/', method: 'delete', ...variables, signal })

export const useDeleteApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteApiKeyError,
			DeleteApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteApiKeyError,
		DeleteApiKeyVariables
	>(
		(variables: DeleteApiKeyVariables) =>
			fetchDeleteApiKey({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetApiKeyDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetApiKeyDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetApiKeyDetailsVariables = {
	pathParams: GetApiKeyDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetApiKeyDetails = (
	variables: GetApiKeyDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.APIKeySchema,
		GetApiKeyDetailsError,
		undefined,
		{},
		{},
		GetApiKeyDetailsPathParams
	>({ url: '/api/api-keys/{id}/', method: 'get', ...variables, signal })

export const useGetApiKeyDetails = <TData = Schemas.APIKeySchema>(
	variables: GetApiKeyDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.APIKeySchema,
			GetApiKeyDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.APIKeySchema,
		GetApiKeyDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/api-keys/{id}/',
			operationId: 'getApiKeyDetails',
			variables
		}),
		({ signal }) =>
			fetchGetApiKeyDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateApiKeyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateApiKeyError = Fetcher.ErrorWrapper<undefined>

export type UpdateApiKeyVariables = {
	body: Schemas.APIKeySchema
	pathParams: UpdateApiKeyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateApiKey = (
	variables: UpdateApiKeyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateApiKeyError,
		Schemas.APIKeySchema,
		{},
		{},
		UpdateApiKeyPathParams
	>({ url: '/api/api-keys/{id}/', method: 'put', ...variables, signal })

export const useUpdateApiKey = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateApiKeyError,
			UpdateApiKeyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateApiKeyError,
		UpdateApiKeyVariables
	>(
		(variables: UpdateApiKeyVariables) =>
			fetchUpdateApiKey({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CheckApiStatusError = Fetcher.ErrorWrapper<undefined>

export type CheckApiStatusVariables = RentalizeContext['fetcherOptions']

export const fetchCheckApiStatus = (
	variables: CheckApiStatusVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.APIStatusSchema,
		CheckApiStatusError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/', method: 'get', ...variables, signal })

export const useCheckApiStatus = <TData = Schemas.APIStatusSchema>(
	variables: CheckApiStatusVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.APIStatusSchema,
			CheckApiStatusError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.APIStatusSchema,
		CheckApiStatusError,
		TData
	>(
		queryKeyFn({ path: '/api/', operationId: 'checkApiStatus', variables }),
		({ signal }) =>
			fetchCheckApiStatus({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateApplianceError = Fetcher.ErrorWrapper<undefined>

export type CreateApplianceVariables = {
	body: Schemas.ApplianceSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAppliance = (
	variables: CreateApplianceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateApplianceError,
		Schemas.ApplianceSchema,
		{},
		{},
		{}
	>({ url: '/api/appliances/', method: 'post', ...variables, signal })

export const useCreateAppliance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateApplianceError,
			CreateApplianceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateApplianceError,
		CreateApplianceVariables
	>(
		(variables: CreateApplianceVariables) =>
			fetchCreateAppliance({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAppliancesQueryParams = {
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAppliancesError = Fetcher.ErrorWrapper<undefined>

export type ListAppliancesVariables = {
	queryParams?: ListAppliancesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAppliances = (
	variables: ListAppliancesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedApplianceSchema,
		ListAppliancesError,
		undefined,
		{},
		ListAppliancesQueryParams,
		{}
	>({ url: '/api/appliances/', method: 'get', ...variables, signal })

export const useListAppliances = <TData = Schemas.PagedApplianceSchema>(
	variables: ListAppliancesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedApplianceSchema,
			ListAppliancesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedApplianceSchema,
		ListAppliancesError,
		TData
	>(
		queryKeyFn({
			path: '/api/appliances/',
			operationId: 'listAppliances',
			variables
		}),
		({ signal }) =>
			fetchListAppliances({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAppliancePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteApplianceError = Fetcher.ErrorWrapper<undefined>

export type DeleteApplianceVariables = {
	pathParams: DeleteAppliancePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAppliance = (
	variables: DeleteApplianceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteApplianceError,
		undefined,
		{},
		{},
		DeleteAppliancePathParams
	>({ url: '/api/appliances/{id}/', method: 'delete', ...variables, signal })

export const useDeleteAppliance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteApplianceError,
			DeleteApplianceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteApplianceError,
		DeleteApplianceVariables
	>(
		(variables: DeleteApplianceVariables) =>
			fetchDeleteAppliance({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetApplianceDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetApplianceDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetApplianceDetailsVariables = {
	pathParams: GetApplianceDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetApplianceDetails = (
	variables: GetApplianceDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ApplianceSchema,
		GetApplianceDetailsError,
		undefined,
		{},
		{},
		GetApplianceDetailsPathParams
	>({ url: '/api/appliances/{id}/', method: 'get', ...variables, signal })

export const useGetApplianceDetails = <TData = Schemas.ApplianceSchema>(
	variables: GetApplianceDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ApplianceSchema,
			GetApplianceDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ApplianceSchema,
		GetApplianceDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/appliances/{id}/',
			operationId: 'getApplianceDetails',
			variables
		}),
		({ signal }) =>
			fetchGetApplianceDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAppliancePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateApplianceError = Fetcher.ErrorWrapper<undefined>

export type UpdateApplianceVariables = {
	body: Schemas.ApplianceSchema
	pathParams: UpdateAppliancePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAppliance = (
	variables: UpdateApplianceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateApplianceError,
		Schemas.ApplianceSchema,
		{},
		{},
		UpdateAppliancePathParams
	>({ url: '/api/appliances/{id}/', method: 'put', ...variables, signal })

export const useUpdateAppliance = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateApplianceError,
			UpdateApplianceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateApplianceError,
		UpdateApplianceVariables
	>(
		(variables: UpdateApplianceVariables) =>
			fetchUpdateAppliance({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListApplianceTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListApplianceTypesError = Fetcher.ErrorWrapper<undefined>

export type ListApplianceTypesVariables = {
	queryParams?: ListApplianceTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListApplianceTypes = (
	variables: ListApplianceTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedApplianceTypeSchema,
		ListApplianceTypesError,
		undefined,
		{},
		ListApplianceTypesQueryParams,
		{}
	>({ url: '/api/appliance-types/', method: 'get', ...variables, signal })

export const useListApplianceTypes = <TData = Schemas.PagedApplianceTypeSchema>(
	variables: ListApplianceTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedApplianceTypeSchema,
			ListApplianceTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedApplianceTypeSchema,
		ListApplianceTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/appliance-types/',
			operationId: 'listApplianceTypes',
			variables
		}),
		({ signal }) =>
			fetchListApplianceTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ChangePasswordError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type ChangePasswordVariables = {
	body: Schemas.ChangePasswordSchema
} & RentalizeContext['fetcherOptions']

export const fetchChangePassword = (
	variables: ChangePasswordVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		ChangePasswordError,
		Schemas.ChangePasswordSchema,
		{},
		{},
		{}
	>({ url: '/api/auth/change-password', method: 'put', ...variables, signal })

export const useChangePassword = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			ChangePasswordError,
			ChangePasswordVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		ChangePasswordError,
		ChangePasswordVariables
	>(
		(variables: ChangePasswordVariables) =>
			fetchChangePassword({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetUserTypeError = Fetcher.ErrorWrapper<undefined>

export type GetUserTypeVariables = RentalizeContext['fetcherOptions']

export const fetchGetUserType = (
	variables: GetUserTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.GetUserTypeSchema,
		GetUserTypeError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/get-user-type/', method: 'get', ...variables, signal })

export const useGetUserType = <TData = Schemas.GetUserTypeSchema>(
	variables: GetUserTypeVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.GetUserTypeSchema,
			GetUserTypeError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.GetUserTypeSchema,
		GetUserTypeError,
		TData
	>(
		queryKeyFn({
			path: '/api/get-user-type/',
			operationId: 'getUserType',
			variables
		}),
		({ signal }) =>
			fetchGetUserType({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type LoginUserError = Fetcher.ErrorWrapper<undefined>

export type LoginUserVariables = {
	body: Schemas.LoginSchemaIn
} & RentalizeContext['fetcherOptions']

export const fetchLoginUser = (
	variables: LoginUserVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LoginSchemaOut,
		LoginUserError,
		Schemas.LoginSchemaIn,
		{},
		{},
		{}
	>({ url: '/api/login/', method: 'post', ...variables, signal })

export const useLoginUser = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.LoginSchemaOut,
			LoginUserError,
			LoginUserVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.LoginSchemaOut,
		LoginUserError,
		LoginUserVariables
	>(
		(variables: LoginUserVariables) =>
			fetchLoginUser({ ...fetcherOptions, ...variables }),
		options
	)
}

export type PasswordResetError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: Schemas.ErrorSchema
	  }
	| {
			status: 404
			payload: Schemas.ErrorSchema
	  }
>

export type PasswordResetVariables = {
	body: Schemas.PasswordResetSchema
} & RentalizeContext['fetcherOptions']

export const fetchPasswordReset = (
	variables: PasswordResetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PasswordResetSchemaOut,
		PasswordResetError,
		Schemas.PasswordResetSchema,
		{},
		{},
		{}
	>({ url: '/api/auth/password-reset/', method: 'post', ...variables, signal })

export const usePasswordReset = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.PasswordResetSchemaOut,
			PasswordResetError,
			PasswordResetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.PasswordResetSchemaOut,
		PasswordResetError,
		PasswordResetVariables
	>(
		(variables: PasswordResetVariables) =>
			fetchPasswordReset({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAvailabilitySlotRuleError = Fetcher.ErrorWrapper<undefined>

export type CreateAvailabilitySlotRuleVariables = {
	body: Schemas.AvailabilitySlotRuleSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAvailabilitySlotRule = (
	variables: CreateAvailabilitySlotRuleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAvailabilitySlotRuleError,
		Schemas.AvailabilitySlotRuleSchema,
		{},
		{},
		{}
	>({
		url: '/api/availability-slot-rules/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateAvailabilitySlotRule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAvailabilitySlotRuleError,
			CreateAvailabilitySlotRuleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAvailabilitySlotRuleError,
		CreateAvailabilitySlotRuleVariables
	>(
		(variables: CreateAvailabilitySlotRuleVariables) =>
			fetchCreateAvailabilitySlotRule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAvailabilitySlotRulesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAvailabilitySlotRulesError = Fetcher.ErrorWrapper<undefined>

export type ListAvailabilitySlotRulesVariables = {
	queryParams?: ListAvailabilitySlotRulesQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all AvailabilitySlotRule objects for this User's PropertyManagementCompany.
 */
export const fetchListAvailabilitySlotRules = (
	variables: ListAvailabilitySlotRulesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAvailabilitySlotRuleSchema,
		ListAvailabilitySlotRulesError,
		undefined,
		{},
		ListAvailabilitySlotRulesQueryParams,
		{}
	>({
		url: '/api/availability-slot-rules/',
		method: 'get',
		...variables,
		signal
	})

/**
 * Returns a list of all AvailabilitySlotRule objects for this User's PropertyManagementCompany.
 */
export const useListAvailabilitySlotRules = <
	TData = Schemas.PagedAvailabilitySlotRuleSchema
>(
	variables: ListAvailabilitySlotRulesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAvailabilitySlotRuleSchema,
			ListAvailabilitySlotRulesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAvailabilitySlotRuleSchema,
		ListAvailabilitySlotRulesError,
		TData
	>(
		queryKeyFn({
			path: '/api/availability-slot-rules/',
			operationId: 'listAvailabilitySlotRules',
			variables
		}),
		({ signal }) =>
			fetchListAvailabilitySlotRules(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteAvailabilitySlotRulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteAvailabilitySlotRuleError = Fetcher.ErrorWrapper<undefined>

export type DeleteAvailabilitySlotRuleVariables = {
	pathParams: DeleteAvailabilitySlotRulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteAvailabilitySlotRule = (
	variables: DeleteAvailabilitySlotRuleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteAvailabilitySlotRuleError,
		undefined,
		{},
		{},
		DeleteAvailabilitySlotRulePathParams
	>({
		url: '/api/availability-slot-rules/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteAvailabilitySlotRule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteAvailabilitySlotRuleError,
			DeleteAvailabilitySlotRuleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteAvailabilitySlotRuleError,
		DeleteAvailabilitySlotRuleVariables
	>(
		(variables: DeleteAvailabilitySlotRuleVariables) =>
			fetchDeleteAvailabilitySlotRule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetAvailabilitySlotRuleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetAvailabilitySlotRuleDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetAvailabilitySlotRuleDetailsVariables = {
	pathParams: GetAvailabilitySlotRuleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetAvailabilitySlotRuleDetails = (
	variables: GetAvailabilitySlotRuleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AvailabilitySlotRuleSchema,
		GetAvailabilitySlotRuleDetailsError,
		undefined,
		{},
		{},
		GetAvailabilitySlotRuleDetailsPathParams
	>({
		url: '/api/availability-slot-rules/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetAvailabilitySlotRuleDetails = <
	TData = Schemas.AvailabilitySlotRuleSchema
>(
	variables: GetAvailabilitySlotRuleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AvailabilitySlotRuleSchema,
			GetAvailabilitySlotRuleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AvailabilitySlotRuleSchema,
		GetAvailabilitySlotRuleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/availability-slot-rules/{id}/',
			operationId: 'getAvailabilitySlotRuleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetAvailabilitySlotRuleDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateAvailabilitySlotRulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateAvailabilitySlotRuleError = Fetcher.ErrorWrapper<undefined>

export type UpdateAvailabilitySlotRuleVariables = {
	body: Schemas.AvailabilitySlotRuleSchema
	pathParams: UpdateAvailabilitySlotRulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateAvailabilitySlotRule = (
	variables: UpdateAvailabilitySlotRuleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateAvailabilitySlotRuleError,
		Schemas.AvailabilitySlotRuleSchema,
		{},
		{},
		UpdateAvailabilitySlotRulePathParams
	>({
		url: '/api/availability-slot-rules/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateAvailabilitySlotRule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateAvailabilitySlotRuleError,
			UpdateAvailabilitySlotRuleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateAvailabilitySlotRuleError,
		UpdateAvailabilitySlotRuleVariables
	>(
		(variables: UpdateAvailabilitySlotRuleVariables) =>
			fetchUpdateAvailabilitySlotRule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBackgroundCheckError = Fetcher.ErrorWrapper<undefined>

export type CreateBackgroundCheckVariables = {
	body: Schemas.BackgroundCheckCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBackgroundCheck = (
	variables: CreateBackgroundCheckVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBackgroundCheckError,
		Schemas.BackgroundCheckCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/background-checks/', method: 'post', ...variables, signal })

export const useCreateBackgroundCheck = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBackgroundCheckError,
			CreateBackgroundCheckVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBackgroundCheckError,
		CreateBackgroundCheckVariables
	>(
		(variables: CreateBackgroundCheckVariables) =>
			fetchCreateBackgroundCheck({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBackgroundChecksQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBackgroundChecksError = Fetcher.ErrorWrapper<undefined>

export type ListBackgroundChecksVariables = {
	queryParams?: ListBackgroundChecksQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBackgroundChecks = (
	variables: ListBackgroundChecksVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBackgroundCheckSchema,
		ListBackgroundChecksError,
		undefined,
		{},
		ListBackgroundChecksQueryParams,
		{}
	>({ url: '/api/background-checks/', method: 'get', ...variables, signal })

export const useListBackgroundChecks = <
	TData = Schemas.PagedBackgroundCheckSchema
>(
	variables: ListBackgroundChecksVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBackgroundCheckSchema,
			ListBackgroundChecksError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBackgroundCheckSchema,
		ListBackgroundChecksError,
		TData
	>(
		queryKeyFn({
			path: '/api/background-checks/',
			operationId: 'listBackgroundChecks',
			variables
		}),
		({ signal }) =>
			fetchListBackgroundChecks({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBackgroundCheckPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBackgroundCheckError = Fetcher.ErrorWrapper<undefined>

export type DeleteBackgroundCheckVariables = {
	pathParams: DeleteBackgroundCheckPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBackgroundCheck = (
	variables: DeleteBackgroundCheckVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBackgroundCheckError,
		undefined,
		{},
		{},
		DeleteBackgroundCheckPathParams
	>({
		url: '/api/background-checks/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBackgroundCheck = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBackgroundCheckError,
			DeleteBackgroundCheckVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBackgroundCheckError,
		DeleteBackgroundCheckVariables
	>(
		(variables: DeleteBackgroundCheckVariables) =>
			fetchDeleteBackgroundCheck({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBackgroundCheckDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBackgroundCheckDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBackgroundCheckDetailsVariables = {
	pathParams: GetBackgroundCheckDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBackgroundCheckDetails = (
	variables: GetBackgroundCheckDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BackgroundCheckSchema,
		GetBackgroundCheckDetailsError,
		undefined,
		{},
		{},
		GetBackgroundCheckDetailsPathParams
	>({
		url: '/api/background-checks/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBackgroundCheckDetails = <
	TData = Schemas.BackgroundCheckSchema
>(
	variables: GetBackgroundCheckDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BackgroundCheckSchema,
			GetBackgroundCheckDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BackgroundCheckSchema,
		GetBackgroundCheckDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/background-checks/{id}/',
			operationId: 'getBackgroundCheckDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBackgroundCheckDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBackgroundCheckPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBackgroundCheckError = Fetcher.ErrorWrapper<undefined>

export type UpdateBackgroundCheckVariables = {
	body: Schemas.BackgroundCheckSchema
	pathParams: UpdateBackgroundCheckPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBackgroundCheck = (
	variables: UpdateBackgroundCheckVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBackgroundCheckError,
		Schemas.BackgroundCheckSchema,
		{},
		{},
		UpdateBackgroundCheckPathParams
	>({
		url: '/api/background-checks/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBackgroundCheck = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBackgroundCheckError,
			UpdateBackgroundCheckVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBackgroundCheckError,
		UpdateBackgroundCheckVariables
	>(
		(variables: UpdateBackgroundCheckVariables) =>
			fetchUpdateBackgroundCheck({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBankAccountError = Fetcher.ErrorWrapper<undefined>

export type CreateBankAccountVariables = {
	body: Schemas.BankAccountInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBankAccount = (
	variables: CreateBankAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBankAccountError,
		Schemas.BankAccountInSchema,
		{},
		{},
		{}
	>({ url: '/api/bank-accounts/', method: 'post', ...variables, signal })

export const useCreateBankAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBankAccountError,
			CreateBankAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBankAccountError,
		CreateBankAccountVariables
	>(
		(variables: CreateBankAccountVariables) =>
			fetchCreateBankAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBankAccountsQueryParams = {
	status?: ('ACTIVE' | 'INACTIVE') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBankAccountsError = Fetcher.ErrorWrapper<undefined>

export type ListBankAccountsVariables = {
	queryParams?: ListBankAccountsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBankAccounts = (
	variables: ListBankAccountsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBankAccountOutSchema,
		ListBankAccountsError,
		undefined,
		{},
		ListBankAccountsQueryParams,
		{}
	>({ url: '/api/bank-accounts/', method: 'get', ...variables, signal })

export const useListBankAccounts = <TData = Schemas.PagedBankAccountOutSchema>(
	variables: ListBankAccountsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBankAccountOutSchema,
			ListBankAccountsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBankAccountOutSchema,
		ListBankAccountsError,
		TData
	>(
		queryKeyFn({
			path: '/api/bank-accounts/',
			operationId: 'listBankAccounts',
			variables
		}),
		({ signal }) =>
			fetchListBankAccounts({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeactivateBankAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeactivateBankAccountError = Fetcher.ErrorWrapper<undefined>

export type DeactivateBankAccountVariables = {
	pathParams: DeactivateBankAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeactivateBankAccount = (
	variables: DeactivateBankAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeactivateBankAccountError,
		undefined,
		{},
		{},
		DeactivateBankAccountPathParams
	>({
		url: '/api/bank-accounts/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeactivateBankAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeactivateBankAccountError,
			DeactivateBankAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeactivateBankAccountError,
		DeactivateBankAccountVariables
	>(
		(variables: DeactivateBankAccountVariables) =>
			fetchDeactivateBankAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBankAccountDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBankAccountDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBankAccountDetailsVariables = {
	pathParams: GetBankAccountDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBankAccountDetails = (
	variables: GetBankAccountDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BankAccountOutSchema,
		GetBankAccountDetailsError,
		undefined,
		{},
		{},
		GetBankAccountDetailsPathParams
	>({ url: '/api/bank-accounts/{id}/', method: 'get', ...variables, signal })

export const useGetBankAccountDetails = <TData = Schemas.BankAccountOutSchema>(
	variables: GetBankAccountDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BankAccountOutSchema,
			GetBankAccountDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BankAccountOutSchema,
		GetBankAccountDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/bank-accounts/{id}/',
			operationId: 'getBankAccountDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBankAccountDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBankAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBankAccountError = Fetcher.ErrorWrapper<undefined>

export type UpdateBankAccountVariables = {
	body: Schemas.BankAccountInSchema
	pathParams: UpdateBankAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBankAccount = (
	variables: UpdateBankAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBankAccountError,
		Schemas.BankAccountInSchema,
		{},
		{},
		UpdateBankAccountPathParams
	>({ url: '/api/bank-accounts/{id}/', method: 'put', ...variables, signal })

export const useUpdateBankAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBankAccountError,
			UpdateBankAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBankAccountError,
		UpdateBankAccountVariables
	>(
		(variables: UpdateBankAccountVariables) =>
			fetchUpdateBankAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ReactivateBankAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ReactivateBankAccountError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type ReactivateBankAccountVariables = {
	pathParams: ReactivateBankAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchReactivateBankAccount = (
	variables: ReactivateBankAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		ReactivateBankAccountError,
		undefined,
		{},
		{},
		ReactivateBankAccountPathParams
	>({
		url: '/api/bank-accounts/{id}/reactivate/',
		method: 'post',
		...variables,
		signal
	})

export const useReactivateBankAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			ReactivateBankAccountError,
			ReactivateBankAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		ReactivateBankAccountError,
		ReactivateBankAccountVariables
	>(
		(variables: ReactivateBankAccountVariables) =>
			fetchReactivateBankAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSavedBiQueryError = Fetcher.ErrorWrapper<undefined>

export type CreateSavedBiQueryVariables = {
	body: Schemas.SavedBIQuerySchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSavedBiQuery = (
	variables: CreateSavedBiQueryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSavedBiQueryError,
		Schemas.SavedBIQuerySchema,
		{},
		{},
		{}
	>({ url: '/api/saved-bi-queries/', method: 'post', ...variables, signal })

export const useCreateSavedBiQuery = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSavedBiQueryError,
			CreateSavedBiQueryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSavedBiQueryError,
		CreateSavedBiQueryVariables
	>(
		(variables: CreateSavedBiQueryVariables) =>
			fetchCreateSavedBiQuery({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSavedBiQueriesQueryParams = {
	property_owner_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSavedBiQueriesError = Fetcher.ErrorWrapper<undefined>

export type ListSavedBiQueriesVariables = {
	queryParams?: ListSavedBiQueriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSavedBiQueries = (
	variables: ListSavedBiQueriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSavedBIQuerySchema,
		ListSavedBiQueriesError,
		undefined,
		{},
		ListSavedBiQueriesQueryParams,
		{}
	>({ url: '/api/saved-bi-queries/', method: 'get', ...variables, signal })

export const useListSavedBiQueries = <TData = Schemas.PagedSavedBIQuerySchema>(
	variables: ListSavedBiQueriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSavedBIQuerySchema,
			ListSavedBiQueriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSavedBIQuerySchema,
		ListSavedBiQueriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/saved-bi-queries/',
			operationId: 'listSavedBiQueries',
			variables
		}),
		({ signal }) =>
			fetchListSavedBiQueries({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSavedBiQueryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSavedBiQueryError = Fetcher.ErrorWrapper<undefined>

export type DeleteSavedBiQueryVariables = {
	pathParams: DeleteSavedBiQueryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteSavedBiQuery = (
	variables: DeleteSavedBiQueryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSavedBiQueryError,
		undefined,
		{},
		{},
		DeleteSavedBiQueryPathParams
	>({
		url: '/api/saved-bi-queries/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteSavedBiQuery = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSavedBiQueryError,
			DeleteSavedBiQueryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSavedBiQueryError,
		DeleteSavedBiQueryVariables
	>(
		(variables: DeleteSavedBiQueryVariables) =>
			fetchDeleteSavedBiQuery({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSavedBiQueryDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSavedBiQueryDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSavedBiQueryDetailsVariables = {
	pathParams: GetSavedBiQueryDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSavedBiQueryDetails = (
	variables: GetSavedBiQueryDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SavedBIQuerySchema,
		GetSavedBiQueryDetailsError,
		undefined,
		{},
		{},
		GetSavedBiQueryDetailsPathParams
	>({
		url: '/api/saved-bi-queries/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSavedBiQueryDetails = <TData = Schemas.SavedBIQuerySchema>(
	variables: GetSavedBiQueryDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SavedBIQuerySchema,
			GetSavedBiQueryDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SavedBIQuerySchema,
		GetSavedBiQueryDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/saved-bi-queries/{id}/',
			operationId: 'getSavedBiQueryDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSavedBiQueryDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSavedBiQueryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSavedBiQueryError = Fetcher.ErrorWrapper<undefined>

export type UpdateSavedBiQueryVariables = {
	body: Schemas.SavedBIQuerySchema
	pathParams: UpdateSavedBiQueryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSavedBiQuery = (
	variables: UpdateSavedBiQueryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSavedBiQueryError,
		Schemas.SavedBIQuerySchema,
		{},
		{},
		UpdateSavedBiQueryPathParams
	>({
		url: '/api/saved-bi-queries/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSavedBiQuery = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSavedBiQueryError,
			UpdateSavedBiQueryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSavedBiQueryError,
		UpdateSavedBiQueryVariables
	>(
		(variables: UpdateSavedBiQueryVariables) =>
			fetchUpdateSavedBiQuery({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBuildingError = Fetcher.ErrorWrapper<undefined>

export type CreateBuildingVariables = {
	body: Schemas.BuildingInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBuilding = (
	variables: CreateBuildingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBuildingError,
		Schemas.BuildingInSchema,
		{},
		{},
		{}
	>({ url: '/api/buildings/', method: 'post', ...variables, signal })

export const useCreateBuilding = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBuildingError,
			CreateBuildingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBuildingError,
		CreateBuildingVariables
	>(
		(variables: CreateBuildingVariables) =>
			fetchCreateBuilding({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBuildingsQueryParams = {
	property_owner_id?: string | null
	building_type?: ('APARTMENT' | 'STORAGE') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBuildingsError = Fetcher.ErrorWrapper<undefined>

export type ListBuildingsVariables = {
	queryParams?: ListBuildingsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBuildings = (
	variables: ListBuildingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBuildingOutSchema,
		ListBuildingsError,
		undefined,
		{},
		ListBuildingsQueryParams,
		{}
	>({ url: '/api/buildings/', method: 'get', ...variables, signal })

export const useListBuildings = <TData = Schemas.PagedBuildingOutSchema>(
	variables: ListBuildingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBuildingOutSchema,
			ListBuildingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBuildingOutSchema,
		ListBuildingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/buildings/',
			operationId: 'listBuildings',
			variables
		}),
		({ signal }) =>
			fetchListBuildings({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBuildingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBuildingError = Fetcher.ErrorWrapper<undefined>

export type DeleteBuildingVariables = {
	pathParams: DeleteBuildingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBuilding = (
	variables: DeleteBuildingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBuildingError,
		undefined,
		{},
		{},
		DeleteBuildingPathParams
	>({ url: '/api/buildings/{id}/', method: 'delete', ...variables, signal })

export const useDeleteBuilding = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBuildingError,
			DeleteBuildingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBuildingError,
		DeleteBuildingVariables
	>(
		(variables: DeleteBuildingVariables) =>
			fetchDeleteBuilding({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBuildingDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBuildingDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetBuildingDetailsVariables = {
	pathParams: GetBuildingDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBuildingDetails = (
	variables: GetBuildingDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BuildingOutSchema,
		GetBuildingDetailsError,
		undefined,
		{},
		{},
		GetBuildingDetailsPathParams
	>({ url: '/api/buildings/{id}/', method: 'get', ...variables, signal })

export const useGetBuildingDetails = <TData = Schemas.BuildingOutSchema>(
	variables: GetBuildingDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BuildingOutSchema,
			GetBuildingDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BuildingOutSchema,
		GetBuildingDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/buildings/{id}/',
			operationId: 'getBuildingDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBuildingDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBuildingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBuildingError = Fetcher.ErrorWrapper<undefined>

export type UpdateBuildingVariables = {
	body: Schemas.BuildingInSchema
	pathParams: UpdateBuildingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBuilding = (
	variables: UpdateBuildingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBuildingError,
		Schemas.BuildingInSchema,
		{},
		{},
		UpdateBuildingPathParams
	>({ url: '/api/buildings/{id}/', method: 'put', ...variables, signal })

export const useUpdateBuilding = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBuildingError,
			UpdateBuildingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBuildingError,
		UpdateBuildingVariables
	>(
		(variables: UpdateBuildingVariables) =>
			fetchUpdateBuilding({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateBulkRentIncreaseNoticeError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Record<string, any>
}>

export type CreateBulkRentIncreaseNoticeVariables = {
	body: Schemas.BulkRentIncreaseNoticeCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateBulkRentIncreaseNotice = (
	variables: CreateBulkRentIncreaseNoticeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateBulkRentIncreaseNoticeError,
		Schemas.BulkRentIncreaseNoticeCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/bulk-rent-increase-notices/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateBulkRentIncreaseNotice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateBulkRentIncreaseNoticeError,
			CreateBulkRentIncreaseNoticeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateBulkRentIncreaseNoticeError,
		CreateBulkRentIncreaseNoticeVariables
	>(
		(variables: CreateBulkRentIncreaseNoticeVariables) =>
			fetchCreateBulkRentIncreaseNotice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListBulkRentIncreaseNoticesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListBulkRentIncreaseNoticesError = Fetcher.ErrorWrapper<undefined>

export type ListBulkRentIncreaseNoticesVariables = {
	queryParams?: ListBulkRentIncreaseNoticesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListBulkRentIncreaseNotices = (
	variables: ListBulkRentIncreaseNoticesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedBulkRentIncreaseNoticeDetailSchema,
		ListBulkRentIncreaseNoticesError,
		undefined,
		{},
		ListBulkRentIncreaseNoticesQueryParams,
		{}
	>({
		url: '/api/bulk-rent-increase-notices/',
		method: 'get',
		...variables,
		signal
	})

export const useListBulkRentIncreaseNotices = <
	TData = Schemas.PagedBulkRentIncreaseNoticeDetailSchema
>(
	variables: ListBulkRentIncreaseNoticesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedBulkRentIncreaseNoticeDetailSchema,
			ListBulkRentIncreaseNoticesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedBulkRentIncreaseNoticeDetailSchema,
		ListBulkRentIncreaseNoticesError,
		TData
	>(
		queryKeyFn({
			path: '/api/bulk-rent-increase-notices/',
			operationId: 'listBulkRentIncreaseNotices',
			variables
		}),
		({ signal }) =>
			fetchListBulkRentIncreaseNotices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteBulkRentIncreaseNoticePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteBulkRentIncreaseNoticeError = Fetcher.ErrorWrapper<undefined>

export type DeleteBulkRentIncreaseNoticeVariables = {
	pathParams: DeleteBulkRentIncreaseNoticePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteBulkRentIncreaseNotice = (
	variables: DeleteBulkRentIncreaseNoticeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteBulkRentIncreaseNoticeError,
		undefined,
		{},
		{},
		DeleteBulkRentIncreaseNoticePathParams
	>({
		url: '/api/bulk-rent-increase-notices/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteBulkRentIncreaseNotice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteBulkRentIncreaseNoticeError,
			DeleteBulkRentIncreaseNoticeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteBulkRentIncreaseNoticeError,
		DeleteBulkRentIncreaseNoticeVariables
	>(
		(variables: DeleteBulkRentIncreaseNoticeVariables) =>
			fetchDeleteBulkRentIncreaseNotice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetBulkRentIncreaseNoticeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetBulkRentIncreaseNoticeDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetBulkRentIncreaseNoticeDetailsVariables = {
	pathParams: GetBulkRentIncreaseNoticeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetBulkRentIncreaseNoticeDetails = (
	variables: GetBulkRentIncreaseNoticeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.BulkRentIncreaseNoticeSchema,
		GetBulkRentIncreaseNoticeDetailsError,
		undefined,
		{},
		{},
		GetBulkRentIncreaseNoticeDetailsPathParams
	>({
		url: '/api/bulk-rent-increase-notices/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetBulkRentIncreaseNoticeDetails = <
	TData = Schemas.BulkRentIncreaseNoticeSchema
>(
	variables: GetBulkRentIncreaseNoticeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.BulkRentIncreaseNoticeSchema,
			GetBulkRentIncreaseNoticeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.BulkRentIncreaseNoticeSchema,
		GetBulkRentIncreaseNoticeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/bulk-rent-increase-notices/{id}/',
			operationId: 'getBulkRentIncreaseNoticeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetBulkRentIncreaseNoticeDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateBulkRentIncreaseNoticePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateBulkRentIncreaseNoticeError = Fetcher.ErrorWrapper<undefined>

export type UpdateBulkRentIncreaseNoticeVariables = {
	body: Schemas.BulkRentIncreaseNoticeSchema
	pathParams: UpdateBulkRentIncreaseNoticePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateBulkRentIncreaseNotice = (
	variables: UpdateBulkRentIncreaseNoticeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateBulkRentIncreaseNoticeError,
		Schemas.BulkRentIncreaseNoticeSchema,
		{},
		{},
		UpdateBulkRentIncreaseNoticePathParams
	>({
		url: '/api/bulk-rent-increase-notices/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateBulkRentIncreaseNotice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateBulkRentIncreaseNoticeError,
			UpdateBulkRentIncreaseNoticeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateBulkRentIncreaseNoticeError,
		UpdateBulkRentIncreaseNoticeVariables
	>(
		(variables: UpdateBulkRentIncreaseNoticeVariables) =>
			fetchUpdateBulkRentIncreaseNotice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateCalendarEventError = Fetcher.ErrorWrapper<undefined>

export type CreateCalendarEventVariables = {
	body: Schemas.CalendarEventInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateCalendarEvent = (
	variables: CreateCalendarEventVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateCalendarEventError,
		Schemas.CalendarEventInSchema,
		{},
		{},
		{}
	>({ url: '/api/calendar-events/', method: 'post', ...variables, signal })

export const useCreateCalendarEvent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateCalendarEventError,
			CreateCalendarEventVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateCalendarEventError,
		CreateCalendarEventVariables
	>(
		(variables: CreateCalendarEventVariables) =>
			fetchCreateCalendarEvent({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListCalendarEventsQueryParams = {
	user_id?: string | null
	work_order_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListCalendarEventsError = Fetcher.ErrorWrapper<undefined>

export type ListCalendarEventsVariables = {
	queryParams?: ListCalendarEventsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListCalendarEvents = (
	variables: ListCalendarEventsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedCalendarEventOutSchema,
		ListCalendarEventsError,
		undefined,
		{},
		ListCalendarEventsQueryParams,
		{}
	>({ url: '/api/calendar-events/', method: 'get', ...variables, signal })

export const useListCalendarEvents = <
	TData = Schemas.PagedCalendarEventOutSchema
>(
	variables: ListCalendarEventsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedCalendarEventOutSchema,
			ListCalendarEventsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedCalendarEventOutSchema,
		ListCalendarEventsError,
		TData
	>(
		queryKeyFn({
			path: '/api/calendar-events/',
			operationId: 'listCalendarEvents',
			variables
		}),
		({ signal }) =>
			fetchListCalendarEvents({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteCalendarEventPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteCalendarEventError = Fetcher.ErrorWrapper<undefined>

export type DeleteCalendarEventVariables = {
	pathParams: DeleteCalendarEventPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteCalendarEvent = (
	variables: DeleteCalendarEventVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteCalendarEventError,
		undefined,
		{},
		{},
		DeleteCalendarEventPathParams
	>({
		url: '/api/calendar-events/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteCalendarEvent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteCalendarEventError,
			DeleteCalendarEventVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteCalendarEventError,
		DeleteCalendarEventVariables
	>(
		(variables: DeleteCalendarEventVariables) =>
			fetchDeleteCalendarEvent({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetCalendarEventDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetCalendarEventDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetCalendarEventDetailsVariables = {
	pathParams: GetCalendarEventDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetCalendarEventDetails = (
	variables: GetCalendarEventDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.CalendarEventOutSchema,
		GetCalendarEventDetailsError,
		undefined,
		{},
		{},
		GetCalendarEventDetailsPathParams
	>({ url: '/api/calendar-events/{id}/', method: 'get', ...variables, signal })

export const useGetCalendarEventDetails = <
	TData = Schemas.CalendarEventOutSchema
>(
	variables: GetCalendarEventDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CalendarEventOutSchema,
			GetCalendarEventDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.CalendarEventOutSchema,
		GetCalendarEventDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/calendar-events/{id}/',
			operationId: 'getCalendarEventDetails',
			variables
		}),
		({ signal }) =>
			fetchGetCalendarEventDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateCalendarEventPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateCalendarEventError = Fetcher.ErrorWrapper<undefined>

export type UpdateCalendarEventVariables = {
	body: Schemas.CalendarEventInSchema
	pathParams: UpdateCalendarEventPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateCalendarEvent = (
	variables: UpdateCalendarEventVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateCalendarEventError,
		Schemas.CalendarEventInSchema,
		{},
		{},
		UpdateCalendarEventPathParams
	>({ url: '/api/calendar-events/{id}/', method: 'put', ...variables, signal })

export const useUpdateCalendarEvent = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateCalendarEventError,
			UpdateCalendarEventVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateCalendarEventError,
		UpdateCalendarEventVariables
	>(
		(variables: UpdateCalendarEventVariables) =>
			fetchUpdateCalendarEvent({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateCalendarSharingRelationshipError =
	Fetcher.ErrorWrapper<undefined>

export type CreateCalendarSharingRelationshipVariables = {
	body: Schemas.CalendarSharingRelationshipSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateCalendarSharingRelationship = (
	variables: CreateCalendarSharingRelationshipVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateCalendarSharingRelationshipError,
		Schemas.CalendarSharingRelationshipSchema,
		{},
		{},
		{}
	>({
		url: '/api/calendar-sharing-relationships/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateCalendarSharingRelationship = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateCalendarSharingRelationshipError,
			CreateCalendarSharingRelationshipVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateCalendarSharingRelationshipError,
		CreateCalendarSharingRelationshipVariables
	>(
		(variables: CreateCalendarSharingRelationshipVariables) =>
			fetchCreateCalendarSharingRelationship({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListCalendarSharingRelationshipsQueryParams = {
	user_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListCalendarSharingRelationshipsError =
	Fetcher.ErrorWrapper<undefined>

export type ListCalendarSharingRelationshipsVariables = {
	queryParams?: ListCalendarSharingRelationshipsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListCalendarSharingRelationships = (
	variables: ListCalendarSharingRelationshipsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedCalendarSharingRelationshipDetailSchema,
		ListCalendarSharingRelationshipsError,
		undefined,
		{},
		ListCalendarSharingRelationshipsQueryParams,
		{}
	>({
		url: '/api/calendar-sharing-relationships/',
		method: 'get',
		...variables,
		signal
	})

export const useListCalendarSharingRelationships = <
	TData = Schemas.PagedCalendarSharingRelationshipDetailSchema
>(
	variables: ListCalendarSharingRelationshipsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedCalendarSharingRelationshipDetailSchema,
			ListCalendarSharingRelationshipsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedCalendarSharingRelationshipDetailSchema,
		ListCalendarSharingRelationshipsError,
		TData
	>(
		queryKeyFn({
			path: '/api/calendar-sharing-relationships/',
			operationId: 'listCalendarSharingRelationships',
			variables
		}),
		({ signal }) =>
			fetchListCalendarSharingRelationships(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteCalendarSharingRelationshipPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteCalendarSharingRelationshipError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteCalendarSharingRelationshipVariables = {
	pathParams: DeleteCalendarSharingRelationshipPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteCalendarSharingRelationship = (
	variables: DeleteCalendarSharingRelationshipVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteCalendarSharingRelationshipError,
		undefined,
		{},
		{},
		DeleteCalendarSharingRelationshipPathParams
	>({
		url: '/api/calendar-sharing-relationships/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteCalendarSharingRelationship = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteCalendarSharingRelationshipError,
			DeleteCalendarSharingRelationshipVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteCalendarSharingRelationshipError,
		DeleteCalendarSharingRelationshipVariables
	>(
		(variables: DeleteCalendarSharingRelationshipVariables) =>
			fetchDeleteCalendarSharingRelationship({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetCalendarSharingRelationshipDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetCalendarSharingRelationshipDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetCalendarSharingRelationshipDetailsVariables = {
	pathParams: GetCalendarSharingRelationshipDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetCalendarSharingRelationshipDetails = (
	variables: GetCalendarSharingRelationshipDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.CalendarSharingRelationshipDetailSchema,
		GetCalendarSharingRelationshipDetailsError,
		undefined,
		{},
		{},
		GetCalendarSharingRelationshipDetailsPathParams
	>({
		url: '/api/calendar-sharing-relationships/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetCalendarSharingRelationshipDetails = <
	TData = Schemas.CalendarSharingRelationshipDetailSchema
>(
	variables: GetCalendarSharingRelationshipDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CalendarSharingRelationshipDetailSchema,
			GetCalendarSharingRelationshipDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.CalendarSharingRelationshipDetailSchema,
		GetCalendarSharingRelationshipDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/calendar-sharing-relationships/{id}/',
			operationId: 'getCalendarSharingRelationshipDetails',
			variables
		}),
		({ signal }) =>
			fetchGetCalendarSharingRelationshipDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateCalendarSharingRelationshipPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateCalendarSharingRelationshipError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateCalendarSharingRelationshipVariables = {
	body: Schemas.CalendarSharingRelationshipSchema
	pathParams: UpdateCalendarSharingRelationshipPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateCalendarSharingRelationship = (
	variables: UpdateCalendarSharingRelationshipVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateCalendarSharingRelationshipError,
		Schemas.CalendarSharingRelationshipSchema,
		{},
		{},
		UpdateCalendarSharingRelationshipPathParams
	>({
		url: '/api/calendar-sharing-relationships/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateCalendarSharingRelationship = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateCalendarSharingRelationshipError,
			UpdateCalendarSharingRelationshipVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateCalendarSharingRelationshipError,
		UpdateCalendarSharingRelationshipVariables
	>(
		(variables: UpdateCalendarSharingRelationshipVariables) =>
			fetchUpdateCalendarSharingRelationship({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetCommandPaletteDataError = Fetcher.ErrorWrapper<undefined>

export type GetCommandPaletteDataResponse = Schemas.CommandPaletteSchema[]

export type GetCommandPaletteDataVariables = RentalizeContext['fetcherOptions']

export const fetchGetCommandPaletteData = (
	variables: GetCommandPaletteDataVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		GetCommandPaletteDataResponse,
		GetCommandPaletteDataError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/command-palette/', method: 'get', ...variables, signal })

export const useGetCommandPaletteData = <TData = GetCommandPaletteDataResponse>(
	variables: GetCommandPaletteDataVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetCommandPaletteDataResponse,
			GetCommandPaletteDataError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		GetCommandPaletteDataResponse,
		GetCommandPaletteDataError,
		TData
	>(
		queryKeyFn({
			path: '/api/command-palette/',
			operationId: 'getCommandPaletteData',
			variables
		}),
		({ signal }) =>
			fetchGetCommandPaletteData({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateCommentError = Fetcher.ErrorWrapper<undefined>

export type CreateCommentVariables = {
	body: Schemas.CommentCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateComment = (
	variables: CreateCommentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateCommentError,
		Schemas.CommentCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/comments/', method: 'post', ...variables, signal })

export const useCreateComment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateCommentError,
			CreateCommentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateCommentError,
		CreateCommentVariables
	>(
		(variables: CreateCommentVariables) =>
			fetchCreateComment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListCommentsQueryParams = {
	work_order_id?: string | null
	task_id?: string | null
	rental_application_submission_id?: string | null
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListCommentsError = Fetcher.ErrorWrapper<undefined>

export type ListCommentsVariables = {
	queryParams?: ListCommentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListComments = (
	variables: ListCommentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedCommentSchema,
		ListCommentsError,
		undefined,
		{},
		ListCommentsQueryParams,
		{}
	>({ url: '/api/comments/', method: 'get', ...variables, signal })

export const useListComments = <TData = Schemas.PagedCommentSchema>(
	variables: ListCommentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedCommentSchema,
			ListCommentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedCommentSchema,
		ListCommentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/comments/',
			operationId: 'listComments',
			variables
		}),
		({ signal }) =>
			fetchListComments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteCommentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteCommentError = Fetcher.ErrorWrapper<undefined>

export type DeleteCommentVariables = {
	pathParams: DeleteCommentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteComment = (
	variables: DeleteCommentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteCommentError,
		undefined,
		{},
		{},
		DeleteCommentPathParams
	>({ url: '/api/comments/{id}/', method: 'delete', ...variables, signal })

export const useDeleteComment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteCommentError,
			DeleteCommentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteCommentError,
		DeleteCommentVariables
	>(
		(variables: DeleteCommentVariables) =>
			fetchDeleteComment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetCommentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetCommentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetCommentDetailsVariables = {
	pathParams: GetCommentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetCommentDetails = (
	variables: GetCommentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.CommentSchema,
		GetCommentDetailsError,
		undefined,
		{},
		{},
		GetCommentDetailsPathParams
	>({ url: '/api/comments/{id}/', method: 'get', ...variables, signal })

export const useGetCommentDetails = <TData = Schemas.CommentSchema>(
	variables: GetCommentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CommentSchema,
			GetCommentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.CommentSchema,
		GetCommentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/comments/{id}/',
			operationId: 'getCommentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetCommentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateCommentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateCommentError = Fetcher.ErrorWrapper<undefined>

export type UpdateCommentVariables = {
	body: Schemas.CommentCreateSchema
	pathParams: UpdateCommentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateComment = (
	variables: UpdateCommentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateCommentError,
		Schemas.CommentCreateSchema,
		{},
		{},
		UpdateCommentPathParams
	>({ url: '/api/comments/{id}/', method: 'put', ...variables, signal })

export const useUpdateComment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateCommentError,
			UpdateCommentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateCommentError,
		UpdateCommentVariables
	>(
		(variables: UpdateCommentVariables) =>
			fetchUpdateComment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateConversationError = Fetcher.ErrorWrapper<undefined>

export type CreateConversationVariables = {
	body: Schemas.ConversationSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateConversation = (
	variables: CreateConversationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateConversationError,
		Schemas.ConversationSchema,
		{},
		{},
		{}
	>({ url: '/api/conversations/', method: 'post', ...variables, signal })

export const useCreateConversation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateConversationError,
			CreateConversationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateConversationError,
		CreateConversationVariables
	>(
		(variables: CreateConversationVariables) =>
			fetchCreateConversation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListConversationsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListConversationsError = Fetcher.ErrorWrapper<undefined>

export type ListConversationsVariables = {
	queryParams?: ListConversationsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListConversations = (
	variables: ListConversationsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedConversationSchema,
		ListConversationsError,
		undefined,
		{},
		ListConversationsQueryParams,
		{}
	>({ url: '/api/conversations/', method: 'get', ...variables, signal })

export const useListConversations = <TData = Schemas.PagedConversationSchema>(
	variables: ListConversationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedConversationSchema,
			ListConversationsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedConversationSchema,
		ListConversationsError,
		TData
	>(
		queryKeyFn({
			path: '/api/conversations/',
			operationId: 'listConversations',
			variables
		}),
		({ signal }) =>
			fetchListConversations({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteConversationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteConversationError = Fetcher.ErrorWrapper<undefined>

export type DeleteConversationVariables = {
	pathParams: DeleteConversationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteConversation = (
	variables: DeleteConversationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteConversationError,
		undefined,
		{},
		{},
		DeleteConversationPathParams
	>({
		url: '/api/conversations/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteConversation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteConversationError,
			DeleteConversationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteConversationError,
		DeleteConversationVariables
	>(
		(variables: DeleteConversationVariables) =>
			fetchDeleteConversation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetConversationDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetConversationDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetConversationDetailsVariables = {
	pathParams: GetConversationDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetConversationDetails = (
	variables: GetConversationDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ConversationSchema,
		GetConversationDetailsError,
		undefined,
		{},
		{},
		GetConversationDetailsPathParams
	>({ url: '/api/conversations/{id}/', method: 'get', ...variables, signal })

export const useGetConversationDetails = <TData = Schemas.ConversationSchema>(
	variables: GetConversationDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ConversationSchema,
			GetConversationDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ConversationSchema,
		GetConversationDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/conversations/{id}/',
			operationId: 'getConversationDetails',
			variables
		}),
		({ signal }) =>
			fetchGetConversationDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateConversationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateConversationError = Fetcher.ErrorWrapper<undefined>

export type UpdateConversationVariables = {
	body: Schemas.ConversationSchema
	pathParams: UpdateConversationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateConversation = (
	variables: UpdateConversationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateConversationError,
		Schemas.ConversationSchema,
		{},
		{},
		UpdateConversationPathParams
	>({ url: '/api/conversations/{id}/', method: 'put', ...variables, signal })

export const useUpdateConversation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateConversationError,
			UpdateConversationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateConversationError,
		UpdateConversationVariables
	>(
		(variables: UpdateConversationVariables) =>
			fetchUpdateConversation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateCreditCardError = Fetcher.ErrorWrapper<undefined>

export type CreateCreditCardVariables = {
	body: Schemas.CreditCardInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateCreditCard = (
	variables: CreateCreditCardVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateCreditCardError,
		Schemas.CreditCardInSchema,
		{},
		{},
		{}
	>({ url: '/api/credit-cards/', method: 'post', ...variables, signal })

export const useCreateCreditCard = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateCreditCardError,
			CreateCreditCardVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateCreditCardError,
		CreateCreditCardVariables
	>(
		(variables: CreateCreditCardVariables) =>
			fetchCreateCreditCard({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListCreditCardsQueryParams = {
	status?: ('ACTIVE' | 'INACTIVE') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListCreditCardsError = Fetcher.ErrorWrapper<undefined>

export type ListCreditCardsVariables = {
	queryParams?: ListCreditCardsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListCreditCards = (
	variables: ListCreditCardsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedCreditCardOutSchema,
		ListCreditCardsError,
		undefined,
		{},
		ListCreditCardsQueryParams,
		{}
	>({ url: '/api/credit-cards/', method: 'get', ...variables, signal })

export const useListCreditCards = <TData = Schemas.PagedCreditCardOutSchema>(
	variables: ListCreditCardsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedCreditCardOutSchema,
			ListCreditCardsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedCreditCardOutSchema,
		ListCreditCardsError,
		TData
	>(
		queryKeyFn({
			path: '/api/credit-cards/',
			operationId: 'listCreditCards',
			variables
		}),
		({ signal }) =>
			fetchListCreditCards({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeactivateCreditCardPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeactivateCreditCardError = Fetcher.ErrorWrapper<undefined>

export type DeactivateCreditCardVariables = {
	pathParams: DeactivateCreditCardPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeactivateCreditCard = (
	variables: DeactivateCreditCardVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeactivateCreditCardError,
		undefined,
		{},
		{},
		DeactivateCreditCardPathParams
	>({ url: '/api/credit-cards/{id}/', method: 'delete', ...variables, signal })

export const useDeactivateCreditCard = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeactivateCreditCardError,
			DeactivateCreditCardVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeactivateCreditCardError,
		DeactivateCreditCardVariables
	>(
		(variables: DeactivateCreditCardVariables) =>
			fetchDeactivateCreditCard({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetCreditCardDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetCreditCardDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetCreditCardDetailsVariables = {
	pathParams: GetCreditCardDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetCreditCardDetails = (
	variables: GetCreditCardDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.CreditCardOutSchema,
		GetCreditCardDetailsError,
		undefined,
		{},
		{},
		GetCreditCardDetailsPathParams
	>({ url: '/api/credit-cards/{id}/', method: 'get', ...variables, signal })

export const useGetCreditCardDetails = <TData = Schemas.CreditCardOutSchema>(
	variables: GetCreditCardDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.CreditCardOutSchema,
			GetCreditCardDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.CreditCardOutSchema,
		GetCreditCardDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/credit-cards/{id}/',
			operationId: 'getCreditCardDetails',
			variables
		}),
		({ signal }) =>
			fetchGetCreditCardDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateCreditCardPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateCreditCardError = Fetcher.ErrorWrapper<undefined>

export type UpdateCreditCardVariables = {
	body: Schemas.CreditCardInSchema
	pathParams: UpdateCreditCardPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateCreditCard = (
	variables: UpdateCreditCardVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateCreditCardError,
		Schemas.CreditCardInSchema,
		{},
		{},
		UpdateCreditCardPathParams
	>({ url: '/api/credit-cards/{id}/', method: 'put', ...variables, signal })

export const useUpdateCreditCard = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateCreditCardError,
			UpdateCreditCardVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateCreditCardError,
		UpdateCreditCardVariables
	>(
		(variables: UpdateCreditCardVariables) =>
			fetchUpdateCreditCard({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ReactivateCreditCardPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ReactivateCreditCardError = Fetcher.ErrorWrapper<{
	status: 403
	payload: string
}>

export type ReactivateCreditCardVariables = {
	pathParams: ReactivateCreditCardPathParams
} & RentalizeContext['fetcherOptions']

export const fetchReactivateCreditCard = (
	variables: ReactivateCreditCardVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		ReactivateCreditCardError,
		undefined,
		{},
		{},
		ReactivateCreditCardPathParams
	>({
		url: '/api/credit-cards/{id}/reactivate/',
		method: 'post',
		...variables,
		signal
	})

export const useReactivateCreditCard = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			ReactivateCreditCardError,
			ReactivateCreditCardVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		ReactivateCreditCardError,
		ReactivateCreditCardVariables
	>(
		(variables: ReactivateCreditCardVariables) =>
			fetchReactivateCreditCard({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetDashboardStatsError = Fetcher.ErrorWrapper<undefined>

export type GetDashboardStatsVariables = RentalizeContext['fetcherOptions']

/**
 * Returns dashboard data depending on user type.
 */
export const fetchGetDashboardStats = (
	variables: GetDashboardStatsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DashboardSchema,
		GetDashboardStatsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/dashboard/', method: 'get', ...variables, signal })

/**
 * Returns dashboard data depending on user type.
 */
export const useGetDashboardStats = <TData = Schemas.DashboardSchema>(
	variables: GetDashboardStatsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DashboardSchema,
			GetDashboardStatsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.DashboardSchema,
		GetDashboardStatsError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard/',
			operationId: 'getDashboardStats',
			variables
		}),
		({ signal }) =>
			fetchGetDashboardStats({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetDashboardStatsForResidentError = Fetcher.ErrorWrapper<undefined>

export type GetDashboardStatsForResidentVariables =
	RentalizeContext['fetcherOptions']

export const fetchGetDashboardStatsForResident = (
	variables: GetDashboardStatsForResidentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		GetDashboardStatsForResidentError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/resident-dashboard/', method: 'get', ...variables, signal })

export const useGetDashboardStatsForResident = <TData = Record<string, any>>(
	variables: GetDashboardStatsForResidentVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Record<string, any>,
			GetDashboardStatsForResidentError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Record<string, any>,
		GetDashboardStatsForResidentError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-dashboard/',
			operationId: 'getDashboardStatsForResident',
			variables
		}),
		({ signal }) =>
			fetchGetDashboardStatsForResident(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateDashboardMetricSubscriptionError =
	Fetcher.ErrorWrapper<undefined>

export type CreateDashboardMetricSubscriptionVariables = {
	body: Schemas.DashboardMetricSelectionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateDashboardMetricSubscription = (
	variables: CreateDashboardMetricSubscriptionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateDashboardMetricSubscriptionError,
		Schemas.DashboardMetricSelectionSchema,
		{},
		{},
		{}
	>({
		url: '/api/dashboard-metric-selections/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateDashboardMetricSubscription = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateDashboardMetricSubscriptionError,
			CreateDashboardMetricSubscriptionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateDashboardMetricSubscriptionError,
		CreateDashboardMetricSubscriptionVariables
	>(
		(variables: CreateDashboardMetricSubscriptionVariables) =>
			fetchCreateDashboardMetricSubscription({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListDashboardMetricSubscriptionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListDashboardMetricSubscriptionsError =
	Fetcher.ErrorWrapper<undefined>

export type ListDashboardMetricSubscriptionsVariables = {
	queryParams?: ListDashboardMetricSubscriptionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListDashboardMetricSubscriptions = (
	variables: ListDashboardMetricSubscriptionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedDashboardMetricSelectionSchema,
		ListDashboardMetricSubscriptionsError,
		undefined,
		{},
		ListDashboardMetricSubscriptionsQueryParams,
		{}
	>({
		url: '/api/dashboard-metric-selections/',
		method: 'get',
		...variables,
		signal
	})

export const useListDashboardMetricSubscriptions = <
	TData = Schemas.PagedDashboardMetricSelectionSchema
>(
	variables: ListDashboardMetricSubscriptionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedDashboardMetricSelectionSchema,
			ListDashboardMetricSubscriptionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedDashboardMetricSelectionSchema,
		ListDashboardMetricSubscriptionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard-metric-selections/',
			operationId: 'listDashboardMetricSubscriptions',
			variables
		}),
		({ signal }) =>
			fetchListDashboardMetricSubscriptions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteDashboardMetricSubscriptionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteDashboardMetricSubscriptionError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteDashboardMetricSubscriptionVariables = {
	pathParams: DeleteDashboardMetricSubscriptionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteDashboardMetricSubscription = (
	variables: DeleteDashboardMetricSubscriptionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteDashboardMetricSubscriptionError,
		undefined,
		{},
		{},
		DeleteDashboardMetricSubscriptionPathParams
	>({
		url: '/api/dashboard-metric-selections/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteDashboardMetricSubscription = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteDashboardMetricSubscriptionError,
			DeleteDashboardMetricSubscriptionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteDashboardMetricSubscriptionError,
		DeleteDashboardMetricSubscriptionVariables
	>(
		(variables: DeleteDashboardMetricSubscriptionVariables) =>
			fetchDeleteDashboardMetricSubscription({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetDashboardMetricSubscriptionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetDashboardMetricSubscriptionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetDashboardMetricSubscriptionDetailsVariables = {
	pathParams: GetDashboardMetricSubscriptionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetDashboardMetricSubscriptionDetails = (
	variables: GetDashboardMetricSubscriptionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DashboardMetricSelectionSchema,
		GetDashboardMetricSubscriptionDetailsError,
		undefined,
		{},
		{},
		GetDashboardMetricSubscriptionDetailsPathParams
	>({
		url: '/api/dashboard-metric-selections/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetDashboardMetricSubscriptionDetails = <
	TData = Schemas.DashboardMetricSelectionSchema
>(
	variables: GetDashboardMetricSubscriptionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DashboardMetricSelectionSchema,
			GetDashboardMetricSubscriptionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.DashboardMetricSelectionSchema,
		GetDashboardMetricSubscriptionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard-metric-selections/{id}/',
			operationId: 'getDashboardMetricSubscriptionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetDashboardMetricSubscriptionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateDashboardMetricSubscriptionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateDashboardMetricSubscriptionError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateDashboardMetricSubscriptionVariables = {
	body: Schemas.DashboardMetricSelectionSchema
	pathParams: UpdateDashboardMetricSubscriptionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateDashboardMetricSubscription = (
	variables: UpdateDashboardMetricSubscriptionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateDashboardMetricSubscriptionError,
		Schemas.DashboardMetricSelectionSchema,
		{},
		{},
		UpdateDashboardMetricSubscriptionPathParams
	>({
		url: '/api/dashboard-metric-selections/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateDashboardMetricSubscription = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateDashboardMetricSubscriptionError,
			UpdateDashboardMetricSubscriptionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateDashboardMetricSubscriptionError,
		UpdateDashboardMetricSubscriptionVariables
	>(
		(variables: UpdateDashboardMetricSubscriptionVariables) =>
			fetchUpdateDashboardMetricSubscription({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListSummaryDashboardMetricChoicesError =
	Fetcher.ErrorWrapper<undefined>

export type ListSummaryDashboardMetricChoicesResponse =
	Schemas.DashboardMetricChoiceSchema[]

export type ListSummaryDashboardMetricChoicesVariables =
	RentalizeContext['fetcherOptions']

export const fetchListSummaryDashboardMetricChoices = (
	variables: ListSummaryDashboardMetricChoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListSummaryDashboardMetricChoicesResponse,
		ListSummaryDashboardMetricChoicesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/dashboard-metric-choices/summary/',
		method: 'get',
		...variables,
		signal
	})

export const useListSummaryDashboardMetricChoices = <
	TData = ListSummaryDashboardMetricChoicesResponse
>(
	variables: ListSummaryDashboardMetricChoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListSummaryDashboardMetricChoicesResponse,
			ListSummaryDashboardMetricChoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		ListSummaryDashboardMetricChoicesResponse,
		ListSummaryDashboardMetricChoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard-metric-choices/summary/',
			operationId: 'listSummaryDashboardMetricChoices',
			variables
		}),
		({ signal }) =>
			fetchListSummaryDashboardMetricChoices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListLeasingDashboardMetricChoicesError =
	Fetcher.ErrorWrapper<undefined>

export type ListLeasingDashboardMetricChoicesResponse =
	Schemas.DashboardMetricChoiceSchema[]

export type ListLeasingDashboardMetricChoicesVariables =
	RentalizeContext['fetcherOptions']

export const fetchListLeasingDashboardMetricChoices = (
	variables: ListLeasingDashboardMetricChoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListLeasingDashboardMetricChoicesResponse,
		ListLeasingDashboardMetricChoicesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/dashboard-metric-choices/leasing/',
		method: 'get',
		...variables,
		signal
	})

export const useListLeasingDashboardMetricChoices = <
	TData = ListLeasingDashboardMetricChoicesResponse
>(
	variables: ListLeasingDashboardMetricChoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListLeasingDashboardMetricChoicesResponse,
			ListLeasingDashboardMetricChoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		ListLeasingDashboardMetricChoicesResponse,
		ListLeasingDashboardMetricChoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard-metric-choices/leasing/',
			operationId: 'listLeasingDashboardMetricChoices',
			variables
		}),
		({ signal }) =>
			fetchListLeasingDashboardMetricChoices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListOperationsDashboardMetricChoicesError =
	Fetcher.ErrorWrapper<undefined>

export type ListOperationsDashboardMetricChoicesResponse =
	Schemas.DashboardMetricChoiceSchema[]

export type ListOperationsDashboardMetricChoicesVariables =
	RentalizeContext['fetcherOptions']

export const fetchListOperationsDashboardMetricChoices = (
	variables: ListOperationsDashboardMetricChoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListOperationsDashboardMetricChoicesResponse,
		ListOperationsDashboardMetricChoicesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/dashboard-metric-choices/operations/',
		method: 'get',
		...variables,
		signal
	})

export const useListOperationsDashboardMetricChoices = <
	TData = ListOperationsDashboardMetricChoicesResponse
>(
	variables: ListOperationsDashboardMetricChoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListOperationsDashboardMetricChoicesResponse,
			ListOperationsDashboardMetricChoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		ListOperationsDashboardMetricChoicesResponse,
		ListOperationsDashboardMetricChoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/dashboard-metric-choices/operations/',
			operationId: 'listOperationsDashboardMetricChoices',
			variables
		}),
		({ signal }) =>
			fetchListOperationsDashboardMetricChoices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateDocumentError = Fetcher.ErrorWrapper<undefined>

export type CreateDocumentVariables = {
	body: Schemas.DocumentCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateDocument = (
	variables: CreateDocumentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateDocumentError,
		Schemas.DocumentCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/documents/', method: 'post', ...variables, signal })

export const useCreateDocument = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateDocumentError,
			CreateDocumentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateDocumentError,
		CreateDocumentVariables
	>(
		(variables: CreateDocumentVariables) =>
			fetchCreateDocument({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListDocumentsQueryParams = {
	property_owner_id?: string | null
	lease_id?: string | null
	bill_id?: string | null
	invoice_id?: string | null
	resident_id?: string | null
	mortgage_id?: string | null
	rent_increase_id?: string | null
	journal_entry_id?: string | null
	rental_application_submission_id?: string | null
	inspection_report_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListDocumentsError = Fetcher.ErrorWrapper<undefined>

export type ListDocumentsVariables = {
	queryParams?: ListDocumentsQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all Document objects for this User's PropertyManagementCompany.
 */
export const fetchListDocuments = (
	variables: ListDocumentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedDocumentSchema,
		ListDocumentsError,
		undefined,
		{},
		ListDocumentsQueryParams,
		{}
	>({ url: '/api/documents/', method: 'get', ...variables, signal })

/**
 * Returns a list of all Document objects for this User's PropertyManagementCompany.
 */
export const useListDocuments = <TData = Schemas.PagedDocumentSchema>(
	variables: ListDocumentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedDocumentSchema,
			ListDocumentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedDocumentSchema,
		ListDocumentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/documents/',
			operationId: 'listDocuments',
			variables
		}),
		({ signal }) =>
			fetchListDocuments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteDocumentsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteDocumentsError = Fetcher.ErrorWrapper<undefined>

export type DeleteDocumentsVariables = {
	pathParams: DeleteDocumentsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteDocuments = (
	variables: DeleteDocumentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteDocumentsError,
		undefined,
		{},
		{},
		DeleteDocumentsPathParams
	>({ url: '/api/documents/{id}/', method: 'delete', ...variables, signal })

export const useDeleteDocuments = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteDocumentsError,
			DeleteDocumentsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteDocumentsError,
		DeleteDocumentsVariables
	>(
		(variables: DeleteDocumentsVariables) =>
			fetchDeleteDocuments({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetDocumentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetDocumentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetDocumentDetailsVariables = {
	pathParams: GetDocumentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetDocumentDetails = (
	variables: GetDocumentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GetDocumentDetailsError,
		undefined,
		{},
		{},
		GetDocumentDetailsPathParams
	>({ url: '/api/documents/{id}/', method: 'get', ...variables, signal })

export const useGetDocumentDetails = <TData = Schemas.DocumentSchema>(
	variables: GetDocumentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.DocumentSchema,
			GetDocumentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.DocumentSchema,
		GetDocumentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/documents/{id}/',
			operationId: 'getDocumentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetDocumentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateDocumentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateDocumentError = Fetcher.ErrorWrapper<undefined>

export type UpdateDocumentVariables = {
	body: Schemas.DocumentSchema
	pathParams: UpdateDocumentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateDocument = (
	variables: UpdateDocumentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateDocumentError,
		Schemas.DocumentSchema,
		{},
		{},
		UpdateDocumentPathParams
	>({ url: '/api/documents/{id}/', method: 'put', ...variables, signal })

export const useUpdateDocument = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateDocumentError,
			UpdateDocumentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateDocumentError,
		UpdateDocumentVariables
	>(
		(variables: UpdateDocumentVariables) =>
			fetchUpdateDocument({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListDocumentTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListDocumentTypesError = Fetcher.ErrorWrapper<undefined>

export type ListDocumentTypesVariables = {
	queryParams?: ListDocumentTypesQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all Document objects for this User's PropertyManagementCompany.
 */
export const fetchListDocumentTypes = (
	variables: ListDocumentTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedDocumentTypeSchema,
		ListDocumentTypesError,
		undefined,
		{},
		ListDocumentTypesQueryParams,
		{}
	>({ url: '/api/document-types/', method: 'get', ...variables, signal })

/**
 * Returns a list of all Document objects for this User's PropertyManagementCompany.
 */
export const useListDocumentTypes = <TData = Schemas.PagedDocumentTypeSchema>(
	variables: ListDocumentTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedDocumentTypeSchema,
			ListDocumentTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedDocumentTypeSchema,
		ListDocumentTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/document-types/',
			operationId: 'listDocumentTypes',
			variables
		}),
		({ signal }) =>
			fetchListDocumentTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateEmailAttachmentError = Fetcher.ErrorWrapper<undefined>

export type CreateEmailAttachmentVariables = {
	body: Schemas.EmailAttachmentSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailAttachment = (
	variables: CreateEmailAttachmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailAttachmentError,
		Schemas.EmailAttachmentSchema,
		{},
		{},
		{}
	>({ url: '/api/email-attachments/', method: 'post', ...variables, signal })

export const useCreateEmailAttachment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailAttachmentError,
			CreateEmailAttachmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailAttachmentError,
		CreateEmailAttachmentVariables
	>(
		(variables: CreateEmailAttachmentVariables) =>
			fetchCreateEmailAttachment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailAttachmentsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailAttachmentsError = Fetcher.ErrorWrapper<undefined>

export type ListEmailAttachmentsVariables = {
	queryParams?: ListEmailAttachmentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailAttachments = (
	variables: ListEmailAttachmentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailAttachmentSchema,
		ListEmailAttachmentsError,
		undefined,
		{},
		ListEmailAttachmentsQueryParams,
		{}
	>({ url: '/api/email-attachments/', method: 'get', ...variables, signal })

export const useListEmailAttachments = <
	TData = Schemas.PagedEmailAttachmentSchema
>(
	variables: ListEmailAttachmentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailAttachmentSchema,
			ListEmailAttachmentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailAttachmentSchema,
		ListEmailAttachmentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-attachments/',
			operationId: 'listEmailAttachments',
			variables
		}),
		({ signal }) =>
			fetchListEmailAttachments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetEmailAttachmentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailAttachmentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailAttachmentDetailsVariables = {
	pathParams: GetEmailAttachmentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailAttachmentDetails = (
	variables: GetEmailAttachmentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailAttachmentSchema,
		GetEmailAttachmentDetailsError,
		undefined,
		{},
		{},
		GetEmailAttachmentDetailsPathParams
	>({
		url: '/api/email-attachments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetEmailAttachmentDetails = <
	TData = Schemas.EmailAttachmentSchema
>(
	variables: GetEmailAttachmentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailAttachmentSchema,
			GetEmailAttachmentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailAttachmentSchema,
		GetEmailAttachmentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-attachments/{id}/',
			operationId: 'getEmailAttachmentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailAttachmentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateEmailDomainError = Fetcher.ErrorWrapper<undefined>

export type CreateEmailDomainVariables = {
	body: Schemas.EmailDomainSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailDomain = (
	variables: CreateEmailDomainVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailDomainError,
		Schemas.EmailDomainSchema,
		{},
		{},
		{}
	>({ url: '/api/email-domains/', method: 'post', ...variables, signal })

export const useCreateEmailDomain = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailDomainError,
			CreateEmailDomainVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailDomainError,
		CreateEmailDomainVariables
	>(
		(variables: CreateEmailDomainVariables) =>
			fetchCreateEmailDomain({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailDomainsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailDomainsError = Fetcher.ErrorWrapper<undefined>

export type ListEmailDomainsVariables = {
	queryParams?: ListEmailDomainsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailDomains = (
	variables: ListEmailDomainsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailDomainSchema,
		ListEmailDomainsError,
		undefined,
		{},
		ListEmailDomainsQueryParams,
		{}
	>({ url: '/api/email-domains/', method: 'get', ...variables, signal })

export const useListEmailDomains = <TData = Schemas.PagedEmailDomainSchema>(
	variables: ListEmailDomainsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailDomainSchema,
			ListEmailDomainsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailDomainSchema,
		ListEmailDomainsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-domains/',
			operationId: 'listEmailDomains',
			variables
		}),
		({ signal }) =>
			fetchListEmailDomains({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEmailDomainPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEmailDomainError = Fetcher.ErrorWrapper<undefined>

export type DeleteEmailDomainVariables = {
	pathParams: DeleteEmailDomainPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEmailDomain = (
	variables: DeleteEmailDomainVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEmailDomainError,
		undefined,
		{},
		{},
		DeleteEmailDomainPathParams
	>({
		url: '/api/email-domains/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteEmailDomain = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEmailDomainError,
			DeleteEmailDomainVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEmailDomainError,
		DeleteEmailDomainVariables
	>(
		(variables: DeleteEmailDomainVariables) =>
			fetchDeleteEmailDomain({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEmailDomainDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailDomainDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailDomainDetailsVariables = {
	pathParams: GetEmailDomainDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailDomainDetails = (
	variables: GetEmailDomainDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailDomainSchema,
		GetEmailDomainDetailsError,
		undefined,
		{},
		{},
		GetEmailDomainDetailsPathParams
	>({ url: '/api/email-domains/{id}/', method: 'get', ...variables, signal })

export const useGetEmailDomainDetails = <TData = Schemas.EmailDomainSchema>(
	variables: GetEmailDomainDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailDomainSchema,
			GetEmailDomainDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailDomainSchema,
		GetEmailDomainDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-domains/{id}/',
			operationId: 'getEmailDomainDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailDomainDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEmailDomainPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEmailDomainError = Fetcher.ErrorWrapper<undefined>

export type UpdateEmailDomainVariables = {
	body: Schemas.EmailDomainSchema
	pathParams: UpdateEmailDomainPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEmailDomain = (
	variables: UpdateEmailDomainVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEmailDomainError,
		Schemas.EmailDomainSchema,
		{},
		{},
		UpdateEmailDomainPathParams
	>({ url: '/api/email-domains/{id}/', method: 'put', ...variables, signal })

export const useUpdateEmailDomain = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEmailDomainError,
			UpdateEmailDomainVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEmailDomainError,
		UpdateEmailDomainVariables
	>(
		(variables: UpdateEmailDomainVariables) =>
			fetchUpdateEmailDomain({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEmailMessageBatchError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateEmailMessageBatchVariables = {
	body: Schemas.EmailMessageBatchInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailMessageBatch = (
	variables: CreateEmailMessageBatchVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailMessageBatchError,
		Schemas.EmailMessageBatchInSchema,
		{},
		{},
		{}
	>({
		url: '/api/email-message-batches/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateEmailMessageBatch = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailMessageBatchError,
			CreateEmailMessageBatchVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailMessageBatchError,
		CreateEmailMessageBatchVariables
	>(
		(variables: CreateEmailMessageBatchVariables) =>
			fetchCreateEmailMessageBatch({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailMessageBatchesQueryParams = {
	status?: ('DRAFT' | 'SENT' | 'SCHEDULED') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailMessageBatchesError = Fetcher.ErrorWrapper<undefined>

export type ListEmailMessageBatchesVariables = {
	queryParams?: ListEmailMessageBatchesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailMessageBatches = (
	variables: ListEmailMessageBatchesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailMessageBatchOutSchema,
		ListEmailMessageBatchesError,
		undefined,
		{},
		ListEmailMessageBatchesQueryParams,
		{}
	>({
		url: '/api/email-message-batches/',
		method: 'get',
		...variables,
		signal
	})

export const useListEmailMessageBatches = <
	TData = Schemas.PagedEmailMessageBatchOutSchema
>(
	variables: ListEmailMessageBatchesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailMessageBatchOutSchema,
			ListEmailMessageBatchesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailMessageBatchOutSchema,
		ListEmailMessageBatchesError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-message-batches/',
			operationId: 'listEmailMessageBatches',
			variables
		}),
		({ signal }) =>
			fetchListEmailMessageBatches({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetEmailMessageBatchDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailMessageBatchDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailMessageBatchDetailsVariables = {
	pathParams: GetEmailMessageBatchDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailMessageBatchDetails = (
	variables: GetEmailMessageBatchDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailMessageBatchOutSchema,
		GetEmailMessageBatchDetailsError,
		undefined,
		{},
		{},
		GetEmailMessageBatchDetailsPathParams
	>({
		url: '/api/email-message-batches/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetEmailMessageBatchDetails = <
	TData = Schemas.EmailMessageBatchOutSchema
>(
	variables: GetEmailMessageBatchDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailMessageBatchOutSchema,
			GetEmailMessageBatchDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailMessageBatchOutSchema,
		GetEmailMessageBatchDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-message-batches/{id}/',
			operationId: 'getEmailMessageBatchDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailMessageBatchDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEmailMessageBatchPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEmailMessageBatchError = Fetcher.ErrorWrapper<undefined>

export type UpdateEmailMessageBatchVariables = {
	body: Schemas.EmailMessageBatchInSchema
	pathParams: UpdateEmailMessageBatchPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEmailMessageBatch = (
	variables: UpdateEmailMessageBatchVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEmailMessageBatchError,
		Schemas.EmailMessageBatchInSchema,
		{},
		{},
		UpdateEmailMessageBatchPathParams
	>({
		url: '/api/email-message-batches/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateEmailMessageBatch = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEmailMessageBatchError,
			UpdateEmailMessageBatchVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEmailMessageBatchError,
		UpdateEmailMessageBatchVariables
	>(
		(variables: UpdateEmailMessageBatchVariables) =>
			fetchUpdateEmailMessageBatch({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEmailMessageError = Fetcher.ErrorWrapper<undefined>

export type CreateEmailMessageVariables = {
	body: Schemas.EmailMessageCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailMessage = (
	variables: CreateEmailMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailMessageError,
		Schemas.EmailMessageCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/email-messages/', method: 'post', ...variables, signal })

export const useCreateEmailMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailMessageError,
			CreateEmailMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailMessageError,
		CreateEmailMessageVariables
	>(
		(variables: CreateEmailMessageVariables) =>
			fetchCreateEmailMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailMessagesQueryParams = {
	resident_id?: string | null
	email_message_batch_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailMessagesError = Fetcher.ErrorWrapper<undefined>

export type ListEmailMessagesVariables = {
	queryParams?: ListEmailMessagesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailMessages = (
	variables: ListEmailMessagesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailMessageSchema,
		ListEmailMessagesError,
		undefined,
		{},
		ListEmailMessagesQueryParams,
		{}
	>({ url: '/api/email-messages/', method: 'get', ...variables, signal })

export const useListEmailMessages = <TData = Schemas.PagedEmailMessageSchema>(
	variables: ListEmailMessagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailMessageSchema,
			ListEmailMessagesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailMessageSchema,
		ListEmailMessagesError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-messages/',
			operationId: 'listEmailMessages',
			variables
		}),
		({ signal }) =>
			fetchListEmailMessages({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetEmailMessageDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailMessageDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailMessageDetailsVariables = {
	pathParams: GetEmailMessageDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailMessageDetails = (
	variables: GetEmailMessageDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailMessageSchema,
		GetEmailMessageDetailsError,
		undefined,
		{},
		{},
		GetEmailMessageDetailsPathParams
	>({ url: '/api/email-messages/{id}/', method: 'get', ...variables, signal })

export const useGetEmailMessageDetails = <TData = Schemas.EmailMessageSchema>(
	variables: GetEmailMessageDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailMessageSchema,
			GetEmailMessageDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailMessageSchema,
		GetEmailMessageDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-messages/{id}/',
			operationId: 'getEmailMessageDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailMessageDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEmailMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEmailMessageError = Fetcher.ErrorWrapper<undefined>

export type UpdateEmailMessageVariables = {
	body: Schemas.EmailMessageSchema
	pathParams: UpdateEmailMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEmailMessage = (
	variables: UpdateEmailMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEmailMessageError,
		Schemas.EmailMessageSchema,
		{},
		{},
		UpdateEmailMessagePathParams
	>({ url: '/api/email-messages/{id}/', method: 'put', ...variables, signal })

export const useUpdateEmailMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEmailMessageError,
			UpdateEmailMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEmailMessageError,
		UpdateEmailMessageVariables
	>(
		(variables: UpdateEmailMessageVariables) =>
			fetchUpdateEmailMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEmailSenderError = Fetcher.ErrorWrapper<undefined>

export type CreateEmailSenderVariables = {
	body: Schemas.EmailSenderCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailSender = (
	variables: CreateEmailSenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailSenderError,
		Schemas.EmailSenderCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/email-senders/', method: 'post', ...variables, signal })

export const useCreateEmailSender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailSenderError,
			CreateEmailSenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailSenderError,
		CreateEmailSenderVariables
	>(
		(variables: CreateEmailSenderVariables) =>
			fetchCreateEmailSender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailSendersQueryParams = {
	email_domain_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailSendersError = Fetcher.ErrorWrapper<undefined>

export type ListEmailSendersVariables = {
	queryParams?: ListEmailSendersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailSenders = (
	variables: ListEmailSendersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailSenderSchema,
		ListEmailSendersError,
		undefined,
		{},
		ListEmailSendersQueryParams,
		{}
	>({ url: '/api/email-senders/', method: 'get', ...variables, signal })

export const useListEmailSenders = <TData = Schemas.PagedEmailSenderSchema>(
	variables: ListEmailSendersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailSenderSchema,
			ListEmailSendersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailSenderSchema,
		ListEmailSendersError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-senders/',
			operationId: 'listEmailSenders',
			variables
		}),
		({ signal }) =>
			fetchListEmailSenders({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEmailSenderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEmailSenderError = Fetcher.ErrorWrapper<undefined>

export type DeleteEmailSenderVariables = {
	pathParams: DeleteEmailSenderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEmailSender = (
	variables: DeleteEmailSenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEmailSenderError,
		undefined,
		{},
		{},
		DeleteEmailSenderPathParams
	>({
		url: '/api/email-senders/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteEmailSender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEmailSenderError,
			DeleteEmailSenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEmailSenderError,
		DeleteEmailSenderVariables
	>(
		(variables: DeleteEmailSenderVariables) =>
			fetchDeleteEmailSender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEmailSenderDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailSenderDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailSenderDetailsVariables = {
	pathParams: GetEmailSenderDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailSenderDetails = (
	variables: GetEmailSenderDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailSenderSchema,
		GetEmailSenderDetailsError,
		undefined,
		{},
		{},
		GetEmailSenderDetailsPathParams
	>({ url: '/api/email-senders/{id}/', method: 'get', ...variables, signal })

export const useGetEmailSenderDetails = <TData = Schemas.EmailSenderSchema>(
	variables: GetEmailSenderDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailSenderSchema,
			GetEmailSenderDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailSenderSchema,
		GetEmailSenderDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-senders/{id}/',
			operationId: 'getEmailSenderDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailSenderDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEmailSenderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEmailSenderError = Fetcher.ErrorWrapper<undefined>

export type UpdateEmailSenderVariables = {
	body: Schemas.EmailSenderSchema
	pathParams: UpdateEmailSenderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEmailSender = (
	variables: UpdateEmailSenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEmailSenderError,
		Schemas.EmailSenderSchema,
		{},
		{},
		UpdateEmailSenderPathParams
	>({ url: '/api/email-senders/{id}/', method: 'put', ...variables, signal })

export const useUpdateEmailSender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEmailSenderError,
			UpdateEmailSenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEmailSenderError,
		UpdateEmailSenderVariables
	>(
		(variables: UpdateEmailSenderVariables) =>
			fetchUpdateEmailSender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEmailTemplateError = Fetcher.ErrorWrapper<undefined>

export type CreateEmailTemplateVariables = {
	body: Schemas.EmailTemplateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEmailTemplate = (
	variables: CreateEmailTemplateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEmailTemplateError,
		Schemas.EmailTemplateSchema,
		{},
		{},
		{}
	>({ url: '/api/email-templates/', method: 'post', ...variables, signal })

export const useCreateEmailTemplate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEmailTemplateError,
			CreateEmailTemplateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEmailTemplateError,
		CreateEmailTemplateVariables
	>(
		(variables: CreateEmailTemplateVariables) =>
			fetchCreateEmailTemplate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailTemplatesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailTemplatesError = Fetcher.ErrorWrapper<undefined>

export type ListEmailTemplatesVariables = {
	queryParams?: ListEmailTemplatesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailTemplates = (
	variables: ListEmailTemplatesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailTemplateDetailSchema,
		ListEmailTemplatesError,
		undefined,
		{},
		ListEmailTemplatesQueryParams,
		{}
	>({ url: '/api/email-templates/', method: 'get', ...variables, signal })

export const useListEmailTemplates = <
	TData = Schemas.PagedEmailTemplateDetailSchema
>(
	variables: ListEmailTemplatesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailTemplateDetailSchema,
			ListEmailTemplatesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailTemplateDetailSchema,
		ListEmailTemplatesError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-templates/',
			operationId: 'listEmailTemplates',
			variables
		}),
		({ signal }) =>
			fetchListEmailTemplates({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEmailTemplatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEmailTemplateError = Fetcher.ErrorWrapper<undefined>

export type DeleteEmailTemplateVariables = {
	pathParams: DeleteEmailTemplatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEmailTemplate = (
	variables: DeleteEmailTemplateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEmailTemplateError,
		undefined,
		{},
		{},
		DeleteEmailTemplatePathParams
	>({
		url: '/api/email-templates/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteEmailTemplate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEmailTemplateError,
			DeleteEmailTemplateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEmailTemplateError,
		DeleteEmailTemplateVariables
	>(
		(variables: DeleteEmailTemplateVariables) =>
			fetchDeleteEmailTemplate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEmailTemplateDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEmailTemplateDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEmailTemplateDetailsVariables = {
	pathParams: GetEmailTemplateDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEmailTemplateDetails = (
	variables: GetEmailTemplateDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EmailTemplateSchema,
		GetEmailTemplateDetailsError,
		undefined,
		{},
		{},
		GetEmailTemplateDetailsPathParams
	>({ url: '/api/email-templates/{id}/', method: 'get', ...variables, signal })

export const useGetEmailTemplateDetails = <TData = Schemas.EmailTemplateSchema>(
	variables: GetEmailTemplateDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EmailTemplateSchema,
			GetEmailTemplateDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EmailTemplateSchema,
		GetEmailTemplateDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-templates/{id}/',
			operationId: 'getEmailTemplateDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEmailTemplateDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEmailTemplatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEmailTemplateError = Fetcher.ErrorWrapper<undefined>

export type UpdateEmailTemplateVariables = {
	body: Schemas.EmailTemplateSchema
	pathParams: UpdateEmailTemplatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEmailTemplate = (
	variables: UpdateEmailTemplateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEmailTemplateError,
		Schemas.EmailTemplateSchema,
		{},
		{},
		UpdateEmailTemplatePathParams
	>({ url: '/api/email-templates/{id}/', method: 'put', ...variables, signal })

export const useUpdateEmailTemplate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEmailTemplateError,
			UpdateEmailTemplateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEmailTemplateError,
		UpdateEmailTemplateVariables
	>(
		(variables: UpdateEmailTemplateVariables) =>
			fetchUpdateEmailTemplate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEmailTemplateTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEmailTemplateTypesError = Fetcher.ErrorWrapper<undefined>

export type ListEmailTemplateTypesVariables = {
	queryParams?: ListEmailTemplateTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEmailTemplateTypes = (
	variables: ListEmailTemplateTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEmailTemplateTypeSchema,
		ListEmailTemplateTypesError,
		undefined,
		{},
		ListEmailTemplateTypesQueryParams,
		{}
	>({ url: '/api/email-template-types/', method: 'get', ...variables, signal })

export const useListEmailTemplateTypes = <
	TData = Schemas.PagedEmailTemplateTypeSchema
>(
	variables: ListEmailTemplateTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEmailTemplateTypeSchema,
			ListEmailTemplateTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEmailTemplateTypeSchema,
		ListEmailTemplateTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/email-template-types/',
			operationId: 'listEmailTemplateTypes',
			variables
		}),
		({ signal }) =>
			fetchListEmailTemplateTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateEquipmentError = Fetcher.ErrorWrapper<undefined>

export type CreateEquipmentVariables = {
	body: Schemas.EquipmentSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEquipment = (
	variables: CreateEquipmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEquipmentError,
		Schemas.EquipmentSchema,
		{},
		{},
		{}
	>({ url: '/api/equipment/', method: 'post', ...variables, signal })

export const useCreateEquipment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEquipmentError,
			CreateEquipmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEquipmentError,
		CreateEquipmentVariables
	>(
		(variables: CreateEquipmentVariables) =>
			fetchCreateEquipment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEquipmentQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEquipmentError = Fetcher.ErrorWrapper<undefined>

export type ListEquipmentVariables = {
	queryParams?: ListEquipmentQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEquipment = (
	variables: ListEquipmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEquipmentSchema,
		ListEquipmentError,
		undefined,
		{},
		ListEquipmentQueryParams,
		{}
	>({ url: '/api/equipment/', method: 'get', ...variables, signal })

export const useListEquipment = <TData = Schemas.PagedEquipmentSchema>(
	variables: ListEquipmentVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEquipmentSchema,
			ListEquipmentError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEquipmentSchema,
		ListEquipmentError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment/',
			operationId: 'listEquipment',
			variables
		}),
		({ signal }) =>
			fetchListEquipment({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEquipmentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEquipmentError = Fetcher.ErrorWrapper<undefined>

export type DeleteEquipmentVariables = {
	pathParams: DeleteEquipmentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEquipment = (
	variables: DeleteEquipmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEquipmentError,
		undefined,
		{},
		{},
		DeleteEquipmentPathParams
	>({ url: '/api/equipment/{id}/', method: 'delete', ...variables, signal })

export const useDeleteEquipment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEquipmentError,
			DeleteEquipmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEquipmentError,
		DeleteEquipmentVariables
	>(
		(variables: DeleteEquipmentVariables) =>
			fetchDeleteEquipment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEquipmentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEquipmentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEquipmentDetailsVariables = {
	pathParams: GetEquipmentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEquipmentDetails = (
	variables: GetEquipmentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EquipmentSchema,
		GetEquipmentDetailsError,
		undefined,
		{},
		{},
		GetEquipmentDetailsPathParams
	>({ url: '/api/equipment/{id}/', method: 'get', ...variables, signal })

export const useGetEquipmentDetails = <TData = Schemas.EquipmentSchema>(
	variables: GetEquipmentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EquipmentSchema,
			GetEquipmentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EquipmentSchema,
		GetEquipmentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment/{id}/',
			operationId: 'getEquipmentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEquipmentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEquipmentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEquipmentError = Fetcher.ErrorWrapper<undefined>

export type UpdateEquipmentVariables = {
	body: Schemas.EquipmentSchema
	pathParams: UpdateEquipmentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEquipment = (
	variables: UpdateEquipmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEquipmentError,
		Schemas.EquipmentSchema,
		{},
		{},
		UpdateEquipmentPathParams
	>({ url: '/api/equipment/{id}/', method: 'put', ...variables, signal })

export const useUpdateEquipment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEquipmentError,
			UpdateEquipmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEquipmentError,
		UpdateEquipmentVariables
	>(
		(variables: UpdateEquipmentVariables) =>
			fetchUpdateEquipment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEquipmentRentalRateError = Fetcher.ErrorWrapper<undefined>

export type CreateEquipmentRentalRateVariables = {
	body: Schemas.EquipmentRentalRateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEquipmentRentalRate = (
	variables: CreateEquipmentRentalRateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEquipmentRentalRateError,
		Schemas.EquipmentRentalRateSchema,
		{},
		{},
		{}
	>({
		url: '/api/equipment-rental-rates/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateEquipmentRentalRate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEquipmentRentalRateError,
			CreateEquipmentRentalRateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEquipmentRentalRateError,
		CreateEquipmentRentalRateVariables
	>(
		(variables: CreateEquipmentRentalRateVariables) =>
			fetchCreateEquipmentRentalRate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEquipmentRentalRateQueryParams = {
	equipment_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEquipmentRentalRateError = Fetcher.ErrorWrapper<undefined>

export type ListEquipmentRentalRateVariables = {
	queryParams?: ListEquipmentRentalRateQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEquipmentRentalRate = (
	variables: ListEquipmentRentalRateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEquipmentRentalRateDetailSchema,
		ListEquipmentRentalRateError,
		undefined,
		{},
		ListEquipmentRentalRateQueryParams,
		{}
	>({
		url: '/api/equipment-rental-rates/',
		method: 'get',
		...variables,
		signal
	})

export const useListEquipmentRentalRate = <
	TData = Schemas.PagedEquipmentRentalRateDetailSchema
>(
	variables: ListEquipmentRentalRateVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEquipmentRentalRateDetailSchema,
			ListEquipmentRentalRateError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEquipmentRentalRateDetailSchema,
		ListEquipmentRentalRateError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment-rental-rates/',
			operationId: 'listEquipmentRentalRate',
			variables
		}),
		({ signal }) =>
			fetchListEquipmentRentalRate({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEquipmentRentalRatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEquipmentRentalRateError = Fetcher.ErrorWrapper<undefined>

export type DeleteEquipmentRentalRateVariables = {
	pathParams: DeleteEquipmentRentalRatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEquipmentRentalRate = (
	variables: DeleteEquipmentRentalRateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEquipmentRentalRateError,
		undefined,
		{},
		{},
		DeleteEquipmentRentalRatePathParams
	>({
		url: '/api/equipment-rental-rates/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteEquipmentRentalRate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEquipmentRentalRateError,
			DeleteEquipmentRentalRateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEquipmentRentalRateError,
		DeleteEquipmentRentalRateVariables
	>(
		(variables: DeleteEquipmentRentalRateVariables) =>
			fetchDeleteEquipmentRentalRate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEquipmentRentalRateDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEquipmentRentalRateDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEquipmentRentalRateDetailsVariables = {
	pathParams: GetEquipmentRentalRateDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEquipmentRentalRateDetails = (
	variables: GetEquipmentRentalRateDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EquipmentRentalRateSchema,
		GetEquipmentRentalRateDetailsError,
		undefined,
		{},
		{},
		GetEquipmentRentalRateDetailsPathParams
	>({
		url: '/api/equipment-rental-rates/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetEquipmentRentalRateDetails = <
	TData = Schemas.EquipmentRentalRateSchema
>(
	variables: GetEquipmentRentalRateDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EquipmentRentalRateSchema,
			GetEquipmentRentalRateDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EquipmentRentalRateSchema,
		GetEquipmentRentalRateDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment-rental-rates/{id}/',
			operationId: 'getEquipmentRentalRateDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEquipmentRentalRateDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEquipmentRentalRatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEquipmentRentalRateError = Fetcher.ErrorWrapper<undefined>

export type UpdateEquipmentRentalRateVariables = {
	body: Schemas.EquipmentRentalRateSchema
	pathParams: UpdateEquipmentRentalRatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEquipmentRentalRate = (
	variables: UpdateEquipmentRentalRateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEquipmentRentalRateError,
		Schemas.EquipmentRentalRateSchema,
		{},
		{},
		UpdateEquipmentRentalRatePathParams
	>({
		url: '/api/equipment-rental-rates/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateEquipmentRentalRate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEquipmentRentalRateError,
			UpdateEquipmentRentalRateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEquipmentRentalRateError,
		UpdateEquipmentRentalRateVariables
	>(
		(variables: UpdateEquipmentRentalRateVariables) =>
			fetchUpdateEquipmentRentalRate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateEquipmentRentalError = Fetcher.ErrorWrapper<undefined>

export type CreateEquipmentRentalVariables = {
	body: Schemas.EquipmentRentalCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateEquipmentRental = (
	variables: CreateEquipmentRentalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateEquipmentRentalError,
		Schemas.EquipmentRentalCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/equipment-rentals/', method: 'post', ...variables, signal })

export const useCreateEquipmentRental = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateEquipmentRentalError,
			CreateEquipmentRentalVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateEquipmentRentalError,
		CreateEquipmentRentalVariables
	>(
		(variables: CreateEquipmentRentalVariables) =>
			fetchCreateEquipmentRental({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListEquipmentRentalQueryParams = {
	equipment_id?: string | null
	equipment_rental_rate_id?: string | null
	work_order_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEquipmentRentalError = Fetcher.ErrorWrapper<undefined>

export type ListEquipmentRentalVariables = {
	queryParams?: ListEquipmentRentalQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEquipmentRental = (
	variables: ListEquipmentRentalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedEquipmentRentalDetailSchema,
		ListEquipmentRentalError,
		undefined,
		{},
		ListEquipmentRentalQueryParams,
		{}
	>({ url: '/api/equipment-rentals/', method: 'get', ...variables, signal })

export const useListEquipmentRental = <
	TData = Schemas.PagedEquipmentRentalDetailSchema
>(
	variables: ListEquipmentRentalVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedEquipmentRentalDetailSchema,
			ListEquipmentRentalError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedEquipmentRentalDetailSchema,
		ListEquipmentRentalError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment-rentals/',
			operationId: 'listEquipmentRental',
			variables
		}),
		({ signal }) =>
			fetchListEquipmentRental({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteEquipmentRentalPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteEquipmentRentalError = Fetcher.ErrorWrapper<undefined>

export type DeleteEquipmentRentalVariables = {
	pathParams: DeleteEquipmentRentalPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteEquipmentRental = (
	variables: DeleteEquipmentRentalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteEquipmentRentalError,
		undefined,
		{},
		{},
		DeleteEquipmentRentalPathParams
	>({
		url: '/api/equipment-rentals/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteEquipmentRental = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteEquipmentRentalError,
			DeleteEquipmentRentalVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteEquipmentRentalError,
		DeleteEquipmentRentalVariables
	>(
		(variables: DeleteEquipmentRentalVariables) =>
			fetchDeleteEquipmentRental({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetEquipmentRentalDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetEquipmentRentalDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetEquipmentRentalDetailsVariables = {
	pathParams: GetEquipmentRentalDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetEquipmentRentalDetails = (
	variables: GetEquipmentRentalDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.EquipmentRentalSchema,
		GetEquipmentRentalDetailsError,
		undefined,
		{},
		{},
		GetEquipmentRentalDetailsPathParams
	>({
		url: '/api/equipment-rentals/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetEquipmentRentalDetails = <
	TData = Schemas.EquipmentRentalSchema
>(
	variables: GetEquipmentRentalDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.EquipmentRentalSchema,
			GetEquipmentRentalDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.EquipmentRentalSchema,
		GetEquipmentRentalDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/equipment-rentals/{id}/',
			operationId: 'getEquipmentRentalDetails',
			variables
		}),
		({ signal }) =>
			fetchGetEquipmentRentalDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateEquipmentRentalPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateEquipmentRentalError = Fetcher.ErrorWrapper<undefined>

export type UpdateEquipmentRentalVariables = {
	body: Schemas.EquipmentRentalSchema
	pathParams: UpdateEquipmentRentalPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateEquipmentRental = (
	variables: UpdateEquipmentRentalVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateEquipmentRentalError,
		Schemas.EquipmentRentalSchema,
		{},
		{},
		UpdateEquipmentRentalPathParams
	>({
		url: '/api/equipment-rentals/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateEquipmentRental = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateEquipmentRentalError,
			UpdateEquipmentRentalVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateEquipmentRentalError,
		UpdateEquipmentRentalVariables
	>(
		(variables: UpdateEquipmentRentalVariables) =>
			fetchUpdateEquipmentRental({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListFeatureFlagsError = Fetcher.ErrorWrapper<undefined>

export type ListFeatureFlagsVariables = RentalizeContext['fetcherOptions']

export const fetchListFeatureFlags = (
	variables: ListFeatureFlagsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.FeatureFlagSchema,
		ListFeatureFlagsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/features/', method: 'get', ...variables, signal })

export const useListFeatureFlags = <TData = Schemas.FeatureFlagSchema>(
	variables: ListFeatureFlagsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.FeatureFlagSchema,
			ListFeatureFlagsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.FeatureFlagSchema,
		ListFeatureFlagsError,
		TData
	>(
		queryKeyFn({
			path: '/api/features/',
			operationId: 'listFeatureFlags',
			variables
		}),
		({ signal }) =>
			fetchListFeatureFlags({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateGateAccessCodeError = Fetcher.ErrorWrapper<undefined>

export type CreateGateAccessCodeVariables = {
	body: Schemas.GateAccessCodeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateGateAccessCode = (
	variables: CreateGateAccessCodeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateGateAccessCodeError,
		Schemas.GateAccessCodeSchema,
		{},
		{},
		{}
	>({ url: '/api/gate-access-codes/', method: 'post', ...variables, signal })

export const useCreateGateAccessCode = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateGateAccessCodeError,
			CreateGateAccessCodeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateGateAccessCodeError,
		CreateGateAccessCodeVariables
	>(
		(variables: CreateGateAccessCodeVariables) =>
			fetchCreateGateAccessCode({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListGateAccessCodesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListGateAccessCodesError = Fetcher.ErrorWrapper<undefined>

export type ListGateAccessCodesVariables = {
	queryParams?: ListGateAccessCodesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListGateAccessCodes = (
	variables: ListGateAccessCodesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedGateAccessCodeSchema,
		ListGateAccessCodesError,
		undefined,
		{},
		ListGateAccessCodesQueryParams,
		{}
	>({ url: '/api/gate-access-codes/', method: 'get', ...variables, signal })

export const useListGateAccessCodes = <
	TData = Schemas.PagedGateAccessCodeSchema
>(
	variables: ListGateAccessCodesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedGateAccessCodeSchema,
			ListGateAccessCodesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedGateAccessCodeSchema,
		ListGateAccessCodesError,
		TData
	>(
		queryKeyFn({
			path: '/api/gate-access-codes/',
			operationId: 'listGateAccessCodes',
			variables
		}),
		({ signal }) =>
			fetchListGateAccessCodes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteGateAccessCodesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteGateAccessCodesError = Fetcher.ErrorWrapper<undefined>

export type DeleteGateAccessCodesVariables = {
	pathParams: DeleteGateAccessCodesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteGateAccessCodes = (
	variables: DeleteGateAccessCodesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteGateAccessCodesError,
		undefined,
		{},
		{},
		DeleteGateAccessCodesPathParams
	>({
		url: '/api/gate-access-codes/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteGateAccessCodes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteGateAccessCodesError,
			DeleteGateAccessCodesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteGateAccessCodesError,
		DeleteGateAccessCodesVariables
	>(
		(variables: DeleteGateAccessCodesVariables) =>
			fetchDeleteGateAccessCodes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetGateAccessCodeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetGateAccessCodeDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetGateAccessCodeDetailsVariables = {
	pathParams: GetGateAccessCodeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetGateAccessCodeDetails = (
	variables: GetGateAccessCodeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.GateAccessCodeSchema,
		GetGateAccessCodeDetailsError,
		undefined,
		{},
		{},
		GetGateAccessCodeDetailsPathParams
	>({
		url: '/api/gate-access-codes/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetGateAccessCodeDetails = <
	TData = Schemas.GateAccessCodeSchema
>(
	variables: GetGateAccessCodeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.GateAccessCodeSchema,
			GetGateAccessCodeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.GateAccessCodeSchema,
		GetGateAccessCodeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/gate-access-codes/{id}/',
			operationId: 'getGateAccessCodeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetGateAccessCodeDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateGateAccessCodePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateGateAccessCodeError = Fetcher.ErrorWrapper<undefined>

export type UpdateGateAccessCodeVariables = {
	body: Schemas.GateAccessCodeSchema
	pathParams: UpdateGateAccessCodePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateGateAccessCode = (
	variables: UpdateGateAccessCodeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateGateAccessCodeError,
		Schemas.GateAccessCodeSchema,
		{},
		{},
		UpdateGateAccessCodePathParams
	>({
		url: '/api/gate-access-codes/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateGateAccessCode = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateGateAccessCodeError,
			UpdateGateAccessCodeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateGateAccessCodeError,
		UpdateGateAccessCodeVariables
	>(
		(variables: UpdateGateAccessCodeVariables) =>
			fetchUpdateGateAccessCode({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateGateAccessLogError = Fetcher.ErrorWrapper<undefined>

export type CreateGateAccessLogVariables = {
	body: Schemas.GateAccessLogSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateGateAccessLog = (
	variables: CreateGateAccessLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateGateAccessLogError,
		Schemas.GateAccessLogSchema,
		{},
		{},
		{}
	>({ url: '/api/gate-access-logs/', method: 'post', ...variables, signal })

export const useCreateGateAccessLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateGateAccessLogError,
			CreateGateAccessLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateGateAccessLogError,
		CreateGateAccessLogVariables
	>(
		(variables: CreateGateAccessLogVariables) =>
			fetchCreateGateAccessLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListGateAccessLogsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListGateAccessLogsError = Fetcher.ErrorWrapper<undefined>

export type ListGateAccessLogsVariables = {
	queryParams?: ListGateAccessLogsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListGateAccessLogs = (
	variables: ListGateAccessLogsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedGateAccessLogSchema,
		ListGateAccessLogsError,
		undefined,
		{},
		ListGateAccessLogsQueryParams,
		{}
	>({ url: '/api/gate-access-logs/', method: 'get', ...variables, signal })

export const useListGateAccessLogs = <TData = Schemas.PagedGateAccessLogSchema>(
	variables: ListGateAccessLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedGateAccessLogSchema,
			ListGateAccessLogsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedGateAccessLogSchema,
		ListGateAccessLogsError,
		TData
	>(
		queryKeyFn({
			path: '/api/gate-access-logs/',
			operationId: 'listGateAccessLogs',
			variables
		}),
		({ signal }) =>
			fetchListGateAccessLogs({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteGateAccessLogsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteGateAccessLogsError = Fetcher.ErrorWrapper<undefined>

export type DeleteGateAccessLogsVariables = {
	pathParams: DeleteGateAccessLogsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteGateAccessLogs = (
	variables: DeleteGateAccessLogsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteGateAccessLogsError,
		undefined,
		{},
		{},
		DeleteGateAccessLogsPathParams
	>({
		url: '/api/gate-access-logs/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteGateAccessLogs = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteGateAccessLogsError,
			DeleteGateAccessLogsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteGateAccessLogsError,
		DeleteGateAccessLogsVariables
	>(
		(variables: DeleteGateAccessLogsVariables) =>
			fetchDeleteGateAccessLogs({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetGateAccessLogDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetGateAccessLogDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetGateAccessLogDetailsVariables = {
	pathParams: GetGateAccessLogDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetGateAccessLogDetails = (
	variables: GetGateAccessLogDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.GateAccessLogSchema,
		GetGateAccessLogDetailsError,
		undefined,
		{},
		{},
		GetGateAccessLogDetailsPathParams
	>({
		url: '/api/gate-access-logs/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetGateAccessLogDetails = <TData = Schemas.GateAccessLogSchema>(
	variables: GetGateAccessLogDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.GateAccessLogSchema,
			GetGateAccessLogDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.GateAccessLogSchema,
		GetGateAccessLogDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/gate-access-logs/{id}/',
			operationId: 'getGateAccessLogDetails',
			variables
		}),
		({ signal }) =>
			fetchGetGateAccessLogDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateGateAccessLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateGateAccessLogError = Fetcher.ErrorWrapper<undefined>

export type UpdateGateAccessLogVariables = {
	body: Schemas.GateAccessLogSchema
	pathParams: UpdateGateAccessLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateGateAccessLog = (
	variables: UpdateGateAccessLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateGateAccessLogError,
		Schemas.GateAccessLogSchema,
		{},
		{},
		UpdateGateAccessLogPathParams
	>({
		url: '/api/gate-access-logs/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateGateAccessLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateGateAccessLogError,
			UpdateGateAccessLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateGateAccessLogError,
		UpdateGateAccessLogVariables
	>(
		(variables: UpdateGateAccessLogVariables) =>
			fetchUpdateGateAccessLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListCitiesQueryParams = {
	state?: string | null
}

export type ListCitiesError = Fetcher.ErrorWrapper<undefined>

export type ListCitiesResponse = Schemas.CitySchema[]

export type ListCitiesVariables = {
	queryParams?: ListCitiesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListCities = (
	variables: ListCitiesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListCitiesResponse,
		ListCitiesError,
		undefined,
		{},
		ListCitiesQueryParams,
		{}
	>({ url: '/api/geo/cities/', method: 'get', ...variables, signal })

export const useListCities = <TData = ListCitiesResponse>(
	variables: ListCitiesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<ListCitiesResponse, ListCitiesError, TData>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<ListCitiesResponse, ListCitiesError, TData>(
		queryKeyFn({
			path: '/api/geo/cities/',
			operationId: 'listCities',
			variables
		}),
		({ signal }) =>
			fetchListCities({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListCountriesError = Fetcher.ErrorWrapper<undefined>

export type ListCountriesResponse = Schemas.CountrySchema[]

export type ListCountriesVariables = RentalizeContext['fetcherOptions']

export const fetchListCountries = (
	variables: ListCountriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListCountriesResponse,
		ListCountriesError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/geo/countries/', method: 'get', ...variables, signal })

export const useListCountries = <TData = ListCountriesResponse>(
	variables: ListCountriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListCountriesResponse,
			ListCountriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<ListCountriesResponse, ListCountriesError, TData>(
		queryKeyFn({
			path: '/api/geo/countries/',
			operationId: 'listCountries',
			variables
		}),
		({ signal }) =>
			fetchListCountries({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListStatesQueryParams = {
	country?: string | null
	code?: string | null
}

export type ListStatesError = Fetcher.ErrorWrapper<undefined>

export type ListStatesResponse = Schemas.StateSchema[]

export type ListStatesVariables = {
	queryParams?: ListStatesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStates = (
	variables: ListStatesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListStatesResponse,
		ListStatesError,
		undefined,
		{},
		ListStatesQueryParams,
		{}
	>({ url: '/api/geo/states/', method: 'get', ...variables, signal })

export const useListStates = <TData = ListStatesResponse>(
	variables: ListStatesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<ListStatesResponse, ListStatesError, TData>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<ListStatesResponse, ListStatesError, TData>(
		queryKeyFn({
			path: '/api/geo/states/',
			operationId: 'listStates',
			variables
		}),
		({ signal }) =>
			fetchListStates({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateNotificationError = Fetcher.ErrorWrapper<undefined>

export type CreateNotificationVariables = {
	body: Schemas.InAppNotificationSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateNotification = (
	variables: CreateNotificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateNotificationError,
		Schemas.InAppNotificationSchema,
		{},
		{},
		{}
	>({ url: '/api/notifications/', method: 'post', ...variables, signal })

export const useCreateNotification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateNotificationError,
			CreateNotificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateNotificationError,
		CreateNotificationVariables
	>(
		(variables: CreateNotificationVariables) =>
			fetchCreateNotification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListNotificationsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListNotificationsError = Fetcher.ErrorWrapper<undefined>

export type ListNotificationsVariables = {
	queryParams?: ListNotificationsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListNotifications = (
	variables: ListNotificationsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInAppNotificationSchema,
		ListNotificationsError,
		undefined,
		{},
		ListNotificationsQueryParams,
		{}
	>({ url: '/api/notifications/', method: 'get', ...variables, signal })

export const useListNotifications = <
	TData = Schemas.PagedInAppNotificationSchema
>(
	variables: ListNotificationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInAppNotificationSchema,
			ListNotificationsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInAppNotificationSchema,
		ListNotificationsError,
		TData
	>(
		queryKeyFn({
			path: '/api/notifications/',
			operationId: 'listNotifications',
			variables
		}),
		({ signal }) =>
			fetchListNotifications({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteNotificationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteNotificationError = Fetcher.ErrorWrapper<undefined>

export type DeleteNotificationVariables = {
	pathParams: DeleteNotificationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteNotification = (
	variables: DeleteNotificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteNotificationError,
		undefined,
		{},
		{},
		DeleteNotificationPathParams
	>({
		url: '/api/notifications/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteNotification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteNotificationError,
			DeleteNotificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteNotificationError,
		DeleteNotificationVariables
	>(
		(variables: DeleteNotificationVariables) =>
			fetchDeleteNotification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetNotificationDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetNotificationDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetNotificationDetailsVariables = {
	pathParams: GetNotificationDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetNotificationDetails = (
	variables: GetNotificationDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InAppNotificationSchema,
		GetNotificationDetailsError,
		undefined,
		{},
		{},
		GetNotificationDetailsPathParams
	>({ url: '/api/notifications/{id}/', method: 'get', ...variables, signal })

export const useGetNotificationDetails = <
	TData = Schemas.InAppNotificationSchema
>(
	variables: GetNotificationDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InAppNotificationSchema,
			GetNotificationDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InAppNotificationSchema,
		GetNotificationDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/notifications/{id}/',
			operationId: 'getNotificationDetails',
			variables
		}),
		({ signal }) =>
			fetchGetNotificationDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateNotificationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateNotificationError = Fetcher.ErrorWrapper<undefined>

export type UpdateNotificationVariables = {
	body: Schemas.InAppNotificationSchema
	pathParams: UpdateNotificationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateNotification = (
	variables: UpdateNotificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateNotificationError,
		Schemas.InAppNotificationSchema,
		{},
		{},
		UpdateNotificationPathParams
	>({ url: '/api/notifications/{id}/', method: 'put', ...variables, signal })

export const useUpdateNotification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateNotificationError,
			UpdateNotificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateNotificationError,
		UpdateNotificationVariables
	>(
		(variables: UpdateNotificationVariables) =>
			fetchUpdateNotification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInspectionReportError = Fetcher.ErrorWrapper<undefined>

export type CreateInspectionReportVariables = {
	body: Schemas.InspectionReportInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInspectionReport = (
	variables: CreateInspectionReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInspectionReportError,
		Schemas.InspectionReportInSchema,
		{},
		{},
		{}
	>({ url: '/api/inspection-reports/', method: 'post', ...variables, signal })

export const useCreateInspectionReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInspectionReportError,
			CreateInspectionReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInspectionReportError,
		CreateInspectionReportVariables
	>(
		(variables: CreateInspectionReportVariables) =>
			fetchCreateInspectionReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInspectionReportsQueryParams = {
	building_id?: string | null
	rental_unit_id?: string | null
	lease_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInspectionReportsError = Fetcher.ErrorWrapper<undefined>

export type ListInspectionReportsVariables = {
	queryParams?: ListInspectionReportsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInspectionReports = (
	variables: ListInspectionReportsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInspectionReportOutSchema,
		ListInspectionReportsError,
		undefined,
		{},
		ListInspectionReportsQueryParams,
		{}
	>({ url: '/api/inspection-reports/', method: 'get', ...variables, signal })

export const useListInspectionReports = <
	TData = Schemas.PagedInspectionReportOutSchema
>(
	variables: ListInspectionReportsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInspectionReportOutSchema,
			ListInspectionReportsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInspectionReportOutSchema,
		ListInspectionReportsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inspection-reports/',
			operationId: 'listInspectionReports',
			variables
		}),
		({ signal }) =>
			fetchListInspectionReports({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInspectionReportPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInspectionReportError = Fetcher.ErrorWrapper<undefined>

export type DeleteInspectionReportVariables = {
	pathParams: DeleteInspectionReportPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInspectionReport = (
	variables: DeleteInspectionReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInspectionReportError,
		undefined,
		{},
		{},
		DeleteInspectionReportPathParams
	>({
		url: '/api/inspection-reports/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInspectionReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInspectionReportError,
			DeleteInspectionReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInspectionReportError,
		DeleteInspectionReportVariables
	>(
		(variables: DeleteInspectionReportVariables) =>
			fetchDeleteInspectionReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInspectionReportDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInspectionReportDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInspectionReportDetailsVariables = {
	pathParams: GetInspectionReportDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInspectionReportDetails = (
	variables: GetInspectionReportDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InspectionReportOutSchema,
		GetInspectionReportDetailsError,
		undefined,
		{},
		{},
		GetInspectionReportDetailsPathParams
	>({
		url: '/api/inspection-reports/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInspectionReportDetails = <
	TData = Schemas.InspectionReportOutSchema
>(
	variables: GetInspectionReportDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InspectionReportOutSchema,
			GetInspectionReportDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InspectionReportOutSchema,
		GetInspectionReportDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inspection-reports/{id}/',
			operationId: 'getInspectionReportDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInspectionReportDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInspectionReportPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInspectionReportError = Fetcher.ErrorWrapper<undefined>

export type UpdateInspectionReportVariables = {
	body: Schemas.InspectionReportInSchema
	pathParams: UpdateInspectionReportPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInspectionReport = (
	variables: UpdateInspectionReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInspectionReportError,
		Schemas.InspectionReportInSchema,
		{},
		{},
		UpdateInspectionReportPathParams
	>({
		url: '/api/inspection-reports/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInspectionReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInspectionReportError,
			UpdateInspectionReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInspectionReportError,
		UpdateInspectionReportVariables
	>(
		(variables: UpdateInspectionReportVariables) =>
			fetchUpdateInspectionReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateInspectionReportPdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateInspectionReportPdfError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateInspectionReportPdfVariables = {
	pathParams: GenerateInspectionReportPdfPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateInspectionReportPdf = (
	variables: GenerateInspectionReportPdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GenerateInspectionReportPdfError,
		undefined,
		{},
		{},
		GenerateInspectionReportPdfPathParams
	>({
		url: '/api/inspection-reports/{id}/generate-pdf/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateInspectionReportPdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GenerateInspectionReportPdfError,
			GenerateInspectionReportPdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GenerateInspectionReportPdfError,
		GenerateInspectionReportPdfVariables
	>(
		(variables: GenerateInspectionReportPdfVariables) =>
			fetchGenerateInspectionReportPdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInsuranceAccountError = Fetcher.ErrorWrapper<undefined>

export type CreateInsuranceAccountVariables = {
	body: Schemas.InsuranceAccountSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInsuranceAccount = (
	variables: CreateInsuranceAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInsuranceAccountError,
		Schemas.InsuranceAccountSchema,
		{},
		{},
		{}
	>({ url: '/api/insurance-accounts/', method: 'post', ...variables, signal })

export const useCreateInsuranceAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInsuranceAccountError,
			CreateInsuranceAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInsuranceAccountError,
		CreateInsuranceAccountVariables
	>(
		(variables: CreateInsuranceAccountVariables) =>
			fetchCreateInsuranceAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInsuranceAccountsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInsuranceAccountsError = Fetcher.ErrorWrapper<undefined>

export type ListInsuranceAccountsVariables = {
	queryParams?: ListInsuranceAccountsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInsuranceAccounts = (
	variables: ListInsuranceAccountsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInsuranceAccountSchema,
		ListInsuranceAccountsError,
		undefined,
		{},
		ListInsuranceAccountsQueryParams,
		{}
	>({ url: '/api/insurance-accounts/', method: 'get', ...variables, signal })

export const useListInsuranceAccounts = <
	TData = Schemas.PagedInsuranceAccountSchema
>(
	variables: ListInsuranceAccountsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInsuranceAccountSchema,
			ListInsuranceAccountsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInsuranceAccountSchema,
		ListInsuranceAccountsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-accounts/',
			operationId: 'listInsuranceAccounts',
			variables
		}),
		({ signal }) =>
			fetchListInsuranceAccounts({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInsuranceAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInsuranceAccountError = Fetcher.ErrorWrapper<undefined>

export type DeleteInsuranceAccountVariables = {
	pathParams: DeleteInsuranceAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInsuranceAccount = (
	variables: DeleteInsuranceAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInsuranceAccountError,
		undefined,
		{},
		{},
		DeleteInsuranceAccountPathParams
	>({
		url: '/api/insurance-accounts/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInsuranceAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInsuranceAccountError,
			DeleteInsuranceAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInsuranceAccountError,
		DeleteInsuranceAccountVariables
	>(
		(variables: DeleteInsuranceAccountVariables) =>
			fetchDeleteInsuranceAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInsuranceAccountDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInsuranceAccountDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInsuranceAccountDetailsVariables = {
	pathParams: GetInsuranceAccountDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInsuranceAccountDetails = (
	variables: GetInsuranceAccountDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InsuranceAccountSchema,
		GetInsuranceAccountDetailsError,
		undefined,
		{},
		{},
		GetInsuranceAccountDetailsPathParams
	>({
		url: '/api/insurance-accounts/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInsuranceAccountDetails = <
	TData = Schemas.InsuranceAccountSchema
>(
	variables: GetInsuranceAccountDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InsuranceAccountSchema,
			GetInsuranceAccountDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InsuranceAccountSchema,
		GetInsuranceAccountDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-accounts/{id}/',
			operationId: 'getInsuranceAccountDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInsuranceAccountDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInsuranceAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInsuranceAccountError = Fetcher.ErrorWrapper<undefined>

export type UpdateInsuranceAccountVariables = {
	body: Schemas.InsuranceAccountSchema
	pathParams: UpdateInsuranceAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInsuranceAccount = (
	variables: UpdateInsuranceAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInsuranceAccountError,
		Schemas.InsuranceAccountSchema,
		{},
		{},
		UpdateInsuranceAccountPathParams
	>({
		url: '/api/insurance-accounts/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInsuranceAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInsuranceAccountError,
			UpdateInsuranceAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInsuranceAccountError,
		UpdateInsuranceAccountVariables
	>(
		(variables: UpdateInsuranceAccountVariables) =>
			fetchUpdateInsuranceAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInsuranceClaimError = Fetcher.ErrorWrapper<undefined>

export type CreateInsuranceClaimVariables = {
	body: Schemas.InsuranceClaimSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInsuranceClaim = (
	variables: CreateInsuranceClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInsuranceClaimError,
		Schemas.InsuranceClaimSchema,
		{},
		{},
		{}
	>({ url: '/api/insurance-claims/', method: 'post', ...variables, signal })

export const useCreateInsuranceClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInsuranceClaimError,
			CreateInsuranceClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInsuranceClaimError,
		CreateInsuranceClaimVariables
	>(
		(variables: CreateInsuranceClaimVariables) =>
			fetchCreateInsuranceClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInsuranceClaimsQueryParams = {
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInsuranceClaimsError = Fetcher.ErrorWrapper<undefined>

export type ListInsuranceClaimsVariables = {
	queryParams?: ListInsuranceClaimsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInsuranceClaims = (
	variables: ListInsuranceClaimsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInsuranceClaimSchema,
		ListInsuranceClaimsError,
		undefined,
		{},
		ListInsuranceClaimsQueryParams,
		{}
	>({ url: '/api/insurance-claims/', method: 'get', ...variables, signal })

export const useListInsuranceClaims = <
	TData = Schemas.PagedInsuranceClaimSchema
>(
	variables: ListInsuranceClaimsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInsuranceClaimSchema,
			ListInsuranceClaimsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInsuranceClaimSchema,
		ListInsuranceClaimsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-claims/',
			operationId: 'listInsuranceClaims',
			variables
		}),
		({ signal }) =>
			fetchListInsuranceClaims({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInsuranceClaimPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInsuranceClaimError = Fetcher.ErrorWrapper<undefined>

export type DeleteInsuranceClaimVariables = {
	pathParams: DeleteInsuranceClaimPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInsuranceClaim = (
	variables: DeleteInsuranceClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInsuranceClaimError,
		undefined,
		{},
		{},
		DeleteInsuranceClaimPathParams
	>({
		url: '/api/insurance-claims/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInsuranceClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInsuranceClaimError,
			DeleteInsuranceClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInsuranceClaimError,
		DeleteInsuranceClaimVariables
	>(
		(variables: DeleteInsuranceClaimVariables) =>
			fetchDeleteInsuranceClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInsuranceClaimDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInsuranceClaimDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInsuranceClaimDetailsVariables = {
	pathParams: GetInsuranceClaimDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInsuranceClaimDetails = (
	variables: GetInsuranceClaimDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InsuranceClaimSchema,
		GetInsuranceClaimDetailsError,
		undefined,
		{},
		{},
		GetInsuranceClaimDetailsPathParams
	>({
		url: '/api/insurance-claims/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInsuranceClaimDetails = <
	TData = Schemas.InsuranceClaimSchema
>(
	variables: GetInsuranceClaimDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InsuranceClaimSchema,
			GetInsuranceClaimDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InsuranceClaimSchema,
		GetInsuranceClaimDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-claims/{id}/',
			operationId: 'getInsuranceClaimDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInsuranceClaimDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInsuranceClaimPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInsuranceClaimError = Fetcher.ErrorWrapper<undefined>

export type UpdateInsuranceClaimVariables = {
	body: Schemas.InsuranceClaimSchema
	pathParams: UpdateInsuranceClaimPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInsuranceClaim = (
	variables: UpdateInsuranceClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInsuranceClaimError,
		Schemas.InsuranceClaimSchema,
		{},
		{},
		UpdateInsuranceClaimPathParams
	>({
		url: '/api/insurance-claims/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInsuranceClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInsuranceClaimError,
			UpdateInsuranceClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInsuranceClaimError,
		UpdateInsuranceClaimVariables
	>(
		(variables: UpdateInsuranceClaimVariables) =>
			fetchUpdateInsuranceClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInsurancePolicyError = Fetcher.ErrorWrapper<undefined>

export type CreateInsurancePolicyVariables = {
	body: Schemas.InsurancePolicySchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInsurancePolicy = (
	variables: CreateInsurancePolicyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInsurancePolicyError,
		Schemas.InsurancePolicySchema,
		{},
		{},
		{}
	>({ url: '/api/insurance-policies/', method: 'post', ...variables, signal })

export const useCreateInsurancePolicy = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInsurancePolicyError,
			CreateInsurancePolicyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInsurancePolicyError,
		CreateInsurancePolicyVariables
	>(
		(variables: CreateInsurancePolicyVariables) =>
			fetchCreateInsurancePolicy({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInsurancePolicysQueryParams = {
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInsurancePolicysError = Fetcher.ErrorWrapper<undefined>

export type ListInsurancePolicysVariables = {
	queryParams?: ListInsurancePolicysQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInsurancePolicys = (
	variables: ListInsurancePolicysVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInsurancePolicySchema,
		ListInsurancePolicysError,
		undefined,
		{},
		ListInsurancePolicysQueryParams,
		{}
	>({ url: '/api/insurance-policies/', method: 'get', ...variables, signal })

export const useListInsurancePolicys = <
	TData = Schemas.PagedInsurancePolicySchema
>(
	variables: ListInsurancePolicysVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInsurancePolicySchema,
			ListInsurancePolicysError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInsurancePolicySchema,
		ListInsurancePolicysError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-policies/',
			operationId: 'listInsurancePolicys',
			variables
		}),
		({ signal }) =>
			fetchListInsurancePolicys({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInsurancePolicyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInsurancePolicyError = Fetcher.ErrorWrapper<undefined>

export type DeleteInsurancePolicyVariables = {
	pathParams: DeleteInsurancePolicyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInsurancePolicy = (
	variables: DeleteInsurancePolicyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInsurancePolicyError,
		undefined,
		{},
		{},
		DeleteInsurancePolicyPathParams
	>({
		url: '/api/insurance-policies/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInsurancePolicy = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInsurancePolicyError,
			DeleteInsurancePolicyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInsurancePolicyError,
		DeleteInsurancePolicyVariables
	>(
		(variables: DeleteInsurancePolicyVariables) =>
			fetchDeleteInsurancePolicy({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInsurancePolicyDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInsurancePolicyDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInsurancePolicyDetailsVariables = {
	pathParams: GetInsurancePolicyDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInsurancePolicyDetails = (
	variables: GetInsurancePolicyDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InsurancePolicySchema,
		GetInsurancePolicyDetailsError,
		undefined,
		{},
		{},
		GetInsurancePolicyDetailsPathParams
	>({
		url: '/api/insurance-policies/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInsurancePolicyDetails = <
	TData = Schemas.InsurancePolicySchema
>(
	variables: GetInsurancePolicyDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InsurancePolicySchema,
			GetInsurancePolicyDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InsurancePolicySchema,
		GetInsurancePolicyDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-policies/{id}/',
			operationId: 'getInsurancePolicyDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInsurancePolicyDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInsurancePolicyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInsurancePolicyError = Fetcher.ErrorWrapper<undefined>

export type UpdateInsurancePolicyVariables = {
	body: Schemas.InsurancePolicySchema
	pathParams: UpdateInsurancePolicyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInsurancePolicy = (
	variables: UpdateInsurancePolicyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInsurancePolicyError,
		Schemas.InsurancePolicySchema,
		{},
		{},
		UpdateInsurancePolicyPathParams
	>({
		url: '/api/insurance-policies/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInsurancePolicy = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInsurancePolicyError,
			UpdateInsurancePolicyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInsurancePolicyError,
		UpdateInsurancePolicyVariables
	>(
		(variables: UpdateInsurancePolicyVariables) =>
			fetchUpdateInsurancePolicy({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInsuranceSubmissionError = Fetcher.ErrorWrapper<undefined>

export type CreateInsuranceSubmissionVariables = {
	body: Schemas.InsuranceSubmissionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInsuranceSubmission = (
	variables: CreateInsuranceSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInsuranceSubmissionError,
		Schemas.InsuranceSubmissionSchema,
		{},
		{},
		{}
	>({
		url: '/api/insurance-submissions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateInsuranceSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInsuranceSubmissionError,
			CreateInsuranceSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInsuranceSubmissionError,
		CreateInsuranceSubmissionVariables
	>(
		(variables: CreateInsuranceSubmissionVariables) =>
			fetchCreateInsuranceSubmission({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInsuranceSubmissionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInsuranceSubmissionsError = Fetcher.ErrorWrapper<undefined>

export type ListInsuranceSubmissionsVariables = {
	queryParams?: ListInsuranceSubmissionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInsuranceSubmissions = (
	variables: ListInsuranceSubmissionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInsuranceSubmissionSchema,
		ListInsuranceSubmissionsError,
		undefined,
		{},
		ListInsuranceSubmissionsQueryParams,
		{}
	>({
		url: '/api/insurance-submissions/',
		method: 'get',
		...variables,
		signal
	})

export const useListInsuranceSubmissions = <
	TData = Schemas.PagedInsuranceSubmissionSchema
>(
	variables: ListInsuranceSubmissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInsuranceSubmissionSchema,
			ListInsuranceSubmissionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInsuranceSubmissionSchema,
		ListInsuranceSubmissionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-submissions/',
			operationId: 'listInsuranceSubmissions',
			variables
		}),
		({ signal }) =>
			fetchListInsuranceSubmissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInsuranceSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInsuranceSubmissionError = Fetcher.ErrorWrapper<undefined>

export type DeleteInsuranceSubmissionVariables = {
	pathParams: DeleteInsuranceSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInsuranceSubmission = (
	variables: DeleteInsuranceSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInsuranceSubmissionError,
		undefined,
		{},
		{},
		DeleteInsuranceSubmissionPathParams
	>({
		url: '/api/insurance-submissions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInsuranceSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInsuranceSubmissionError,
			DeleteInsuranceSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInsuranceSubmissionError,
		DeleteInsuranceSubmissionVariables
	>(
		(variables: DeleteInsuranceSubmissionVariables) =>
			fetchDeleteInsuranceSubmission({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInsuranceSubmissionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInsuranceSubmissionDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInsuranceSubmissionDetailsVariables = {
	pathParams: GetInsuranceSubmissionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInsuranceSubmissionDetails = (
	variables: GetInsuranceSubmissionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InsuranceSubmissionSchema,
		GetInsuranceSubmissionDetailsError,
		undefined,
		{},
		{},
		GetInsuranceSubmissionDetailsPathParams
	>({
		url: '/api/insurance-submissions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInsuranceSubmissionDetails = <
	TData = Schemas.InsuranceSubmissionSchema
>(
	variables: GetInsuranceSubmissionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InsuranceSubmissionSchema,
			GetInsuranceSubmissionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InsuranceSubmissionSchema,
		GetInsuranceSubmissionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/insurance-submissions/{id}/',
			operationId: 'getInsuranceSubmissionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInsuranceSubmissionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInsuranceSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInsuranceSubmissionError = Fetcher.ErrorWrapper<undefined>

export type UpdateInsuranceSubmissionVariables = {
	body: Schemas.InsuranceSubmissionSchema
	pathParams: UpdateInsuranceSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInsuranceSubmission = (
	variables: UpdateInsuranceSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInsuranceSubmissionError,
		Schemas.InsuranceSubmissionSchema,
		{},
		{},
		UpdateInsuranceSubmissionPathParams
	>({
		url: '/api/insurance-submissions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInsuranceSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInsuranceSubmissionError,
			UpdateInsuranceSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInsuranceSubmissionError,
		UpdateInsuranceSubmissionVariables
	>(
		(variables: UpdateInsuranceSubmissionVariables) =>
			fetchUpdateInsuranceSubmission({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInventoryItemError = Fetcher.ErrorWrapper<undefined>

export type CreateInventoryItemVariables = {
	body: Schemas.InventoryItemCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInventoryItem = (
	variables: CreateInventoryItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInventoryItemError,
		Schemas.InventoryItemCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/inventory-items/', method: 'post', ...variables, signal })

export const useCreateInventoryItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInventoryItemError,
			CreateInventoryItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInventoryItemError,
		CreateInventoryItemVariables
	>(
		(variables: CreateInventoryItemVariables) =>
			fetchCreateInventoryItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInventoryItemsQueryParams = {
	/**
	 * @default true
	 */
	is_active?: boolean
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInventoryItemsError = Fetcher.ErrorWrapper<undefined>

export type ListInventoryItemsVariables = {
	queryParams?: ListInventoryItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInventoryItems = (
	variables: ListInventoryItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInventoryItemSchema,
		ListInventoryItemsError,
		undefined,
		{},
		ListInventoryItemsQueryParams,
		{}
	>({ url: '/api/inventory-items/', method: 'get', ...variables, signal })

export const useListInventoryItems = <TData = Schemas.PagedInventoryItemSchema>(
	variables: ListInventoryItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInventoryItemSchema,
			ListInventoryItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInventoryItemSchema,
		ListInventoryItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inventory-items/',
			operationId: 'listInventoryItems',
			variables
		}),
		({ signal }) =>
			fetchListInventoryItems({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInventoryItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInventoryItemError = Fetcher.ErrorWrapper<undefined>

export type DeleteInventoryItemVariables = {
	pathParams: DeleteInventoryItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInventoryItem = (
	variables: DeleteInventoryItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInventoryItemError,
		undefined,
		{},
		{},
		DeleteInventoryItemPathParams
	>({
		url: '/api/inventory-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInventoryItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInventoryItemError,
			DeleteInventoryItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInventoryItemError,
		DeleteInventoryItemVariables
	>(
		(variables: DeleteInventoryItemVariables) =>
			fetchDeleteInventoryItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInventoryItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInventoryItemDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInventoryItemDetailsVariables = {
	pathParams: GetInventoryItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInventoryItemDetails = (
	variables: GetInventoryItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InventoryItemSchema,
		GetInventoryItemDetailsError,
		undefined,
		{},
		{},
		GetInventoryItemDetailsPathParams
	>({ url: '/api/inventory-items/{id}/', method: 'get', ...variables, signal })

export const useGetInventoryItemDetails = <TData = Schemas.InventoryItemSchema>(
	variables: GetInventoryItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InventoryItemSchema,
			GetInventoryItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InventoryItemSchema,
		GetInventoryItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inventory-items/{id}/',
			operationId: 'getInventoryItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInventoryItemDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInventoryItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInventoryItemError = Fetcher.ErrorWrapper<undefined>

export type UpdateInventoryItemVariables = {
	body: Schemas.InventoryItemSchema
	pathParams: UpdateInventoryItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInventoryItem = (
	variables: UpdateInventoryItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInventoryItemError,
		Schemas.InventoryItemSchema,
		{},
		{},
		UpdateInventoryItemPathParams
	>({ url: '/api/inventory-items/{id}/', method: 'put', ...variables, signal })

export const useUpdateInventoryItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInventoryItemError,
			UpdateInventoryItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInventoryItemError,
		UpdateInventoryItemVariables
	>(
		(variables: UpdateInventoryItemVariables) =>
			fetchUpdateInventoryItem({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInventoryTransactionError = Fetcher.ErrorWrapper<undefined>

export type CreateInventoryTransactionVariables = {
	body: Schemas.InventoryTransactionCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInventoryTransaction = (
	variables: CreateInventoryTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInventoryTransactionError,
		Schemas.InventoryTransactionCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/inventory-transactions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateInventoryTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInventoryTransactionError,
			CreateInventoryTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInventoryTransactionError,
		CreateInventoryTransactionVariables
	>(
		(variables: CreateInventoryTransactionVariables) =>
			fetchCreateInventoryTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInventoryTransactionsQueryParams = {
	inventory_item_id?: string | null
	work_order_id?: string | null
	purchase_order_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInventoryTransactionsError = Fetcher.ErrorWrapper<undefined>

export type ListInventoryTransactionsVariables = {
	queryParams?: ListInventoryTransactionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInventoryTransactions = (
	variables: ListInventoryTransactionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedInventoryTransactionDetailsSchema,
		ListInventoryTransactionsError,
		undefined,
		{},
		ListInventoryTransactionsQueryParams,
		{}
	>({
		url: '/api/inventory-transactions/',
		method: 'get',
		...variables,
		signal
	})

export const useListInventoryTransactions = <
	TData = Schemas.PagedInventoryTransactionDetailsSchema
>(
	variables: ListInventoryTransactionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedInventoryTransactionDetailsSchema,
			ListInventoryTransactionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedInventoryTransactionDetailsSchema,
		ListInventoryTransactionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inventory-transactions/',
			operationId: 'listInventoryTransactions',
			variables
		}),
		({ signal }) =>
			fetchListInventoryTransactions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInventoryTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInventoryTransactionError = Fetcher.ErrorWrapper<undefined>

export type DeleteInventoryTransactionVariables = {
	pathParams: DeleteInventoryTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInventoryTransaction = (
	variables: DeleteInventoryTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInventoryTransactionError,
		undefined,
		{},
		{},
		DeleteInventoryTransactionPathParams
	>({
		url: '/api/inventory-transactions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteInventoryTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInventoryTransactionError,
			DeleteInventoryTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInventoryTransactionError,
		DeleteInventoryTransactionVariables
	>(
		(variables: DeleteInventoryTransactionVariables) =>
			fetchDeleteInventoryTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInventoryTransactionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInventoryTransactionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetInventoryTransactionDetailsVariables = {
	pathParams: GetInventoryTransactionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInventoryTransactionDetails = (
	variables: GetInventoryTransactionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InventoryTransactionSchema,
		GetInventoryTransactionDetailsError,
		undefined,
		{},
		{},
		GetInventoryTransactionDetailsPathParams
	>({
		url: '/api/inventory-transactions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetInventoryTransactionDetails = <
	TData = Schemas.InventoryTransactionSchema
>(
	variables: GetInventoryTransactionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.InventoryTransactionSchema,
			GetInventoryTransactionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.InventoryTransactionSchema,
		GetInventoryTransactionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/inventory-transactions/{id}/',
			operationId: 'getInventoryTransactionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInventoryTransactionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInventoryTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInventoryTransactionError = Fetcher.ErrorWrapper<undefined>

export type UpdateInventoryTransactionVariables = {
	body: Schemas.InventoryTransactionSchema
	pathParams: UpdateInventoryTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInventoryTransaction = (
	variables: UpdateInventoryTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInventoryTransactionError,
		Schemas.InventoryTransactionSchema,
		{},
		{},
		UpdateInventoryTransactionPathParams
	>({
		url: '/api/inventory-transactions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateInventoryTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInventoryTransactionError,
			UpdateInventoryTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInventoryTransactionError,
		UpdateInventoryTransactionVariables
	>(
		(variables: UpdateInventoryTransactionVariables) =>
			fetchUpdateInventoryTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePropertyOwnerError = Fetcher.ErrorWrapper<undefined>

export type CreatePropertyOwnerVariables = {
	body: Schemas.PropertyOwnerInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyOwner = (
	variables: CreatePropertyOwnerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyOwnerError,
		Schemas.PropertyOwnerInSchema,
		{},
		{},
		{}
	>({ url: '/api/property-owners/', method: 'post', ...variables, signal })

export const useCreatePropertyOwner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyOwnerError,
			CreatePropertyOwnerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyOwnerError,
		CreatePropertyOwnerVariables
	>(
		(variables: CreatePropertyOwnerVariables) =>
			fetchCreatePropertyOwner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPropertyOwnersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyOwnersError = Fetcher.ErrorWrapper<undefined>

export type ListPropertyOwnersVariables = {
	queryParams?: ListPropertyOwnersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyOwners = (
	variables: ListPropertyOwnersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyOwnerOutSchema,
		ListPropertyOwnersError,
		undefined,
		{},
		ListPropertyOwnersQueryParams,
		{}
	>({ url: '/api/property-owners/', method: 'get', ...variables, signal })

export const useListPropertyOwners = <
	TData = Schemas.PagedPropertyOwnerOutSchema
>(
	variables: ListPropertyOwnersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyOwnerOutSchema,
			ListPropertyOwnersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyOwnerOutSchema,
		ListPropertyOwnersError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-owners/',
			operationId: 'listPropertyOwners',
			variables
		}),
		({ signal }) =>
			fetchListPropertyOwners({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyOwnerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyOwnerError = Fetcher.ErrorWrapper<undefined>

export type DeletePropertyOwnerVariables = {
	pathParams: DeletePropertyOwnerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyOwner = (
	variables: DeletePropertyOwnerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyOwnerError,
		undefined,
		{},
		{},
		DeletePropertyOwnerPathParams
	>({
		url: '/api/property-owners/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyOwner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyOwnerError,
			DeletePropertyOwnerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyOwnerError,
		DeletePropertyOwnerVariables
	>(
		(variables: DeletePropertyOwnerVariables) =>
			fetchDeletePropertyOwner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPropertyOwnerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyOwnerDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPropertyOwnerDetailsVariables = {
	pathParams: GetPropertyOwnerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyOwnerDetails = (
	variables: GetPropertyOwnerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyOwnerOutSchema,
		GetPropertyOwnerDetailsError,
		undefined,
		{},
		{},
		GetPropertyOwnerDetailsPathParams
	>({ url: '/api/property-owners/{id}/', method: 'get', ...variables, signal })

export const useGetPropertyOwnerDetails = <
	TData = Schemas.PropertyOwnerOutSchema
>(
	variables: GetPropertyOwnerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyOwnerOutSchema,
			GetPropertyOwnerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyOwnerOutSchema,
		GetPropertyOwnerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-owners/{id}/',
			operationId: 'getPropertyOwnerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyOwnerDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyOwnerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyOwnerError = Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyOwnerVariables = {
	body: Schemas.PropertyOwnerInSchema
	pathParams: UpdatePropertyOwnerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyOwner = (
	variables: UpdatePropertyOwnerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyOwnerError,
		Schemas.PropertyOwnerInSchema,
		{},
		{},
		UpdatePropertyOwnerPathParams
	>({ url: '/api/property-owners/{id}/', method: 'put', ...variables, signal })

export const useUpdatePropertyOwner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyOwnerError,
			UpdatePropertyOwnerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyOwnerError,
		UpdatePropertyOwnerVariables
	>(
		(variables: UpdatePropertyOwnerVariables) =>
			fetchUpdatePropertyOwner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateInvestorError = Fetcher.ErrorWrapper<undefined>

export type CreateInvestorVariables = {
	body: Schemas.MaintenanceTechnicianCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateInvestor = (
	variables: CreateInvestorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateInvestorError,
		Schemas.MaintenanceTechnicianCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/investors/', method: 'post', ...variables, signal })

export const useCreateInvestor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateInvestorError,
			CreateInvestorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateInvestorError,
		CreateInvestorVariables
	>(
		(variables: CreateInvestorVariables) =>
			fetchCreateInvestor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInvestorsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInvestorsError = Fetcher.ErrorWrapper<undefined>

export type ListInvestorsVariables = {
	queryParams?: ListInvestorsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInvestors = (
	variables: ListInvestorsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMaintenanceTechnicianSchema,
		ListInvestorsError,
		undefined,
		{},
		ListInvestorsQueryParams,
		{}
	>({ url: '/api/investors/', method: 'get', ...variables, signal })

export const useListInvestors = <
	TData = Schemas.PagedMaintenanceTechnicianSchema
>(
	variables: ListInvestorsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMaintenanceTechnicianSchema,
			ListInvestorsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMaintenanceTechnicianSchema,
		ListInvestorsError,
		TData
	>(
		queryKeyFn({
			path: '/api/investors/',
			operationId: 'listInvestors',
			variables
		}),
		({ signal }) =>
			fetchListInvestors({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteInvestorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteInvestorError = Fetcher.ErrorWrapper<undefined>

export type DeleteInvestorVariables = {
	pathParams: DeleteInvestorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteInvestor = (
	variables: DeleteInvestorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteInvestorError,
		undefined,
		{},
		{},
		DeleteInvestorPathParams
	>({ url: '/api/investors/{id}/', method: 'delete', ...variables, signal })

export const useDeleteInvestor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteInvestorError,
			DeleteInvestorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteInvestorError,
		DeleteInvestorVariables
	>(
		(variables: DeleteInvestorVariables) =>
			fetchDeleteInvestor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetInvestorDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetInvestorDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetInvestorDetailsVariables = {
	pathParams: GetInvestorDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetInvestorDetails = (
	variables: GetInvestorDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MaintenanceTechnicianSchema,
		GetInvestorDetailsError,
		undefined,
		{},
		{},
		GetInvestorDetailsPathParams
	>({ url: '/api/investors/{id}/', method: 'get', ...variables, signal })

export const useGetInvestorDetails = <
	TData = Schemas.MaintenanceTechnicianSchema
>(
	variables: GetInvestorDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MaintenanceTechnicianSchema,
			GetInvestorDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MaintenanceTechnicianSchema,
		GetInvestorDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/investors/{id}/',
			operationId: 'getInvestorDetails',
			variables
		}),
		({ signal }) =>
			fetchGetInvestorDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateInvestorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateInvestorError = Fetcher.ErrorWrapper<undefined>

export type UpdateInvestorVariables = {
	body: Schemas.MaintenanceTechnicianUpdateSchema
	pathParams: UpdateInvestorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateInvestor = (
	variables: UpdateInvestorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateInvestorError,
		Schemas.MaintenanceTechnicianUpdateSchema,
		{},
		{},
		UpdateInvestorPathParams
	>({ url: '/api/investors/{id}/', method: 'put', ...variables, signal })

export const useUpdateInvestor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateInvestorError,
			UpdateInvestorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateInvestorError,
		UpdateInvestorVariables
	>(
		(variables: UpdateInvestorVariables) =>
			fetchUpdateInvestor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type SendInvitationToExistingInvestorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type SendInvitationToExistingInvestorError =
	Fetcher.ErrorWrapper<undefined>

export type SendInvitationToExistingInvestorVariables = {
	pathParams: SendInvitationToExistingInvestorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchSendInvitationToExistingInvestor = (
	variables: SendInvitationToExistingInvestorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		SendInvitationToExistingInvestorError,
		undefined,
		{},
		{},
		SendInvitationToExistingInvestorPathParams
	>({
		url: '/api/investors/{id}/send-invitation/',
		method: 'post',
		...variables,
		signal
	})

export const useSendInvitationToExistingInvestor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			SendInvitationToExistingInvestorError,
			SendInvitationToExistingInvestorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		SendInvitationToExistingInvestorError,
		SendInvitationToExistingInvestorVariables
	>(
		(variables: SendInvitationToExistingInvestorVariables) =>
			fetchSendInvitationToExistingInvestor({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type InviteInvestorError = Fetcher.ErrorWrapper<undefined>

export type InviteInvestorVariables = {
	body: Schemas.UserInvitationSchema
} & RentalizeContext['fetcherOptions']

export const fetchInviteInvestor = (
	variables: InviteInvestorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InviteInvestorError,
		Schemas.UserInvitationSchema,
		{},
		{},
		{}
	>({ url: '/api/invite-investor/', method: 'post', ...variables, signal })

export const useInviteInvestor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InviteInvestorError,
			InviteInvestorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InviteInvestorError,
		InviteInvestorVariables
	>(
		(variables: InviteInvestorVariables) =>
			fetchInviteInvestor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateLateFeeScheduleError = Fetcher.ErrorWrapper<undefined>

export type CreateLateFeeScheduleVariables = {
	body: Schemas.LateFeeScheduleSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateLateFeeSchedule = (
	variables: CreateLateFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateLateFeeScheduleError,
		Schemas.LateFeeScheduleSchema,
		{},
		{},
		{}
	>({ url: '/api/late-fee-schedules/', method: 'post', ...variables, signal })

export const useCreateLateFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateLateFeeScheduleError,
			CreateLateFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateLateFeeScheduleError,
		CreateLateFeeScheduleVariables
	>(
		(variables: CreateLateFeeScheduleVariables) =>
			fetchCreateLateFeeSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListLateFeeSchedulesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListLateFeeSchedulesError = Fetcher.ErrorWrapper<undefined>

export type ListLateFeeSchedulesVariables = {
	queryParams?: ListLateFeeSchedulesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListLateFeeSchedules = (
	variables: ListLateFeeSchedulesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedLateFeeScheduleSchema,
		ListLateFeeSchedulesError,
		undefined,
		{},
		ListLateFeeSchedulesQueryParams,
		{}
	>({ url: '/api/late-fee-schedules/', method: 'get', ...variables, signal })

export const useListLateFeeSchedules = <
	TData = Schemas.PagedLateFeeScheduleSchema
>(
	variables: ListLateFeeSchedulesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLateFeeScheduleSchema,
			ListLateFeeSchedulesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedLateFeeScheduleSchema,
		ListLateFeeSchedulesError,
		TData
	>(
		queryKeyFn({
			path: '/api/late-fee-schedules/',
			operationId: 'listLateFeeSchedules',
			variables
		}),
		({ signal }) =>
			fetchListLateFeeSchedules({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteLateFeeSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteLateFeeScheduleError = Fetcher.ErrorWrapper<undefined>

export type DeleteLateFeeScheduleVariables = {
	pathParams: DeleteLateFeeSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteLateFeeSchedule = (
	variables: DeleteLateFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteLateFeeScheduleError,
		undefined,
		{},
		{},
		DeleteLateFeeSchedulePathParams
	>({
		url: '/api/late-fee-schedules/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteLateFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteLateFeeScheduleError,
			DeleteLateFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteLateFeeScheduleError,
		DeleteLateFeeScheduleVariables
	>(
		(variables: DeleteLateFeeScheduleVariables) =>
			fetchDeleteLateFeeSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetLateFeeScheduleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetLateFeeScheduleDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetLateFeeScheduleDetailsVariables = {
	pathParams: GetLateFeeScheduleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetLateFeeScheduleDetails = (
	variables: GetLateFeeScheduleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LateFeeScheduleSchema,
		GetLateFeeScheduleDetailsError,
		undefined,
		{},
		{},
		GetLateFeeScheduleDetailsPathParams
	>({
		url: '/api/late-fee-schedules/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetLateFeeScheduleDetails = <
	TData = Schemas.LateFeeScheduleSchema
>(
	variables: GetLateFeeScheduleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LateFeeScheduleSchema,
			GetLateFeeScheduleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LateFeeScheduleSchema,
		GetLateFeeScheduleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/late-fee-schedules/{id}/',
			operationId: 'getLateFeeScheduleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetLateFeeScheduleDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateLateFeeSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateLateFeeScheduleError = Fetcher.ErrorWrapper<undefined>

export type UpdateLateFeeScheduleVariables = {
	body: Schemas.LateFeeScheduleSchema
	pathParams: UpdateLateFeeSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateLateFeeSchedule = (
	variables: UpdateLateFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateLateFeeScheduleError,
		Schemas.LateFeeScheduleSchema,
		{},
		{},
		UpdateLateFeeSchedulePathParams
	>({
		url: '/api/late-fee-schedules/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateLateFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateLateFeeScheduleError,
			UpdateLateFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateLateFeeScheduleError,
		UpdateLateFeeScheduleVariables
	>(
		(variables: UpdateLateFeeScheduleVariables) =>
			fetchUpdateLateFeeSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateLeaseAttachmentError = Fetcher.ErrorWrapper<undefined>

export type CreateLeaseAttachmentVariables = {
	body: Schemas.LeaseAttachmentSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateLeaseAttachment = (
	variables: CreateLeaseAttachmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateLeaseAttachmentError,
		Schemas.LeaseAttachmentSchema,
		{},
		{},
		{}
	>({ url: '/api/lease-attachments/', method: 'post', ...variables, signal })

export const useCreateLeaseAttachment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateLeaseAttachmentError,
			CreateLeaseAttachmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateLeaseAttachmentError,
		CreateLeaseAttachmentVariables
	>(
		(variables: CreateLeaseAttachmentVariables) =>
			fetchCreateLeaseAttachment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListLeaseAttachmentsQueryParams = {
	building_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListLeaseAttachmentsError = Fetcher.ErrorWrapper<undefined>

export type ListLeaseAttachmentsVariables = {
	queryParams?: ListLeaseAttachmentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListLeaseAttachments = (
	variables: ListLeaseAttachmentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedLeaseAttachmentDetailSchema,
		ListLeaseAttachmentsError,
		undefined,
		{},
		ListLeaseAttachmentsQueryParams,
		{}
	>({ url: '/api/lease-attachments/', method: 'get', ...variables, signal })

export const useListLeaseAttachments = <
	TData = Schemas.PagedLeaseAttachmentDetailSchema
>(
	variables: ListLeaseAttachmentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLeaseAttachmentDetailSchema,
			ListLeaseAttachmentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedLeaseAttachmentDetailSchema,
		ListLeaseAttachmentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/lease-attachments/',
			operationId: 'listLeaseAttachments',
			variables
		}),
		({ signal }) =>
			fetchListLeaseAttachments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteLeaseAttachmentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteLeaseAttachmentError = Fetcher.ErrorWrapper<undefined>

export type DeleteLeaseAttachmentVariables = {
	pathParams: DeleteLeaseAttachmentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteLeaseAttachment = (
	variables: DeleteLeaseAttachmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteLeaseAttachmentError,
		undefined,
		{},
		{},
		DeleteLeaseAttachmentPathParams
	>({
		url: '/api/lease-attachments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteLeaseAttachment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteLeaseAttachmentError,
			DeleteLeaseAttachmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteLeaseAttachmentError,
		DeleteLeaseAttachmentVariables
	>(
		(variables: DeleteLeaseAttachmentVariables) =>
			fetchDeleteLeaseAttachment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetLeaseAttachmentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetLeaseAttachmentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetLeaseAttachmentDetailsVariables = {
	pathParams: GetLeaseAttachmentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetLeaseAttachmentDetails = (
	variables: GetLeaseAttachmentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LeaseAttachmentDetailSchema,
		GetLeaseAttachmentDetailsError,
		undefined,
		{},
		{},
		GetLeaseAttachmentDetailsPathParams
	>({
		url: '/api/lease-attachments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetLeaseAttachmentDetails = <
	TData = Schemas.LeaseAttachmentDetailSchema
>(
	variables: GetLeaseAttachmentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LeaseAttachmentDetailSchema,
			GetLeaseAttachmentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LeaseAttachmentDetailSchema,
		GetLeaseAttachmentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/lease-attachments/{id}/',
			operationId: 'getLeaseAttachmentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetLeaseAttachmentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateLeaseAttachmentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateLeaseAttachmentError = Fetcher.ErrorWrapper<undefined>

export type UpdateLeaseAttachmentVariables = {
	body: Schemas.LeaseAttachmentDetailSchema
	pathParams: UpdateLeaseAttachmentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateLeaseAttachment = (
	variables: UpdateLeaseAttachmentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateLeaseAttachmentError,
		Schemas.LeaseAttachmentDetailSchema,
		{},
		{},
		UpdateLeaseAttachmentPathParams
	>({
		url: '/api/lease-attachments/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateLeaseAttachment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateLeaseAttachmentError,
			UpdateLeaseAttachmentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateLeaseAttachmentError,
		UpdateLeaseAttachmentVariables
	>(
		(variables: UpdateLeaseAttachmentVariables) =>
			fetchUpdateLeaseAttachment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type AddResidentToLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type AddResidentToLeaseError = Fetcher.ErrorWrapper<undefined>

export type AddResidentToLeaseVariables = {
	body: Schemas.AddResidentToLeaseSchema
	pathParams: AddResidentToLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchAddResidentToLease = (
	variables: AddResidentToLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		AddResidentToLeaseError,
		Schemas.AddResidentToLeaseSchema,
		{},
		{},
		AddResidentToLeasePathParams
	>({
		url: '/api/leases/{id}/add-resident-to-lease/',
		method: 'post',
		...variables,
		signal
	})

export const useAddResidentToLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			AddResidentToLeaseError,
			AddResidentToLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		AddResidentToLeaseError,
		AddResidentToLeaseVariables
	>(
		(variables: AddResidentToLeaseVariables) =>
			fetchAddResidentToLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateLeaseError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateLeaseVariables = {
	body: Schemas.CreateLeaseSchema
} & RentalizeContext['fetcherOptions']

/**
 * Creates a new Lease objects in our database.
 */
export const fetchCreateLease = (
	variables: CreateLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateLeaseError,
		Schemas.CreateLeaseSchema,
		{},
		{},
		{}
	>({ url: '/api/leases/', method: 'post', ...variables, signal })

/**
 * Creates a new Lease objects in our database.
 */
export const useCreateLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateLeaseError,
			CreateLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateLeaseError,
		CreateLeaseVariables
	>(
		(variables: CreateLeaseVariables) =>
			fetchCreateLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListLeasesQueryParams = {
	/**
	 * @default ALL
	 */
	lease_type?: 'CURRENT_LEASE' | 'FORMER_LEASE' | 'FUTURE_LEASE' | 'ALL'
	days_before_expiration?: number | null
	resident_id?: string | null
	rental_unit_id?: string | null
	building_id?: string | null
	/**
	 * @default false
	 */
	only_those_with_no_make_ready?: boolean
	unit_type?: ('APARTMENT' | 'STORAGE') | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListLeasesError = Fetcher.ErrorWrapper<undefined>

export type ListLeasesVariables = {
	queryParams?: ListLeasesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListLeases = (
	variables: ListLeasesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedListLeasesSchema,
		ListLeasesError,
		undefined,
		{},
		ListLeasesQueryParams,
		{}
	>({ url: '/api/leases/', method: 'get', ...variables, signal })

export const useListLeases = <TData = Schemas.PagedListLeasesSchema>(
	variables: ListLeasesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedListLeasesSchema,
			ListLeasesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedListLeasesSchema,
		ListLeasesError,
		TData
	>(
		queryKeyFn({ path: '/api/leases/', operationId: 'listLeases', variables }),
		({ signal }) =>
			fetchListLeases({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteLeaseError = Fetcher.ErrorWrapper<undefined>

export type DeleteLeaseVariables = {
	pathParams: DeleteLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteLease = (
	variables: DeleteLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteLeaseError,
		undefined,
		{},
		{},
		DeleteLeasePathParams
	>({ url: '/api/leases/{id}/', method: 'delete', ...variables, signal })

export const useDeleteLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteLeaseError,
			DeleteLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteLeaseError,
		DeleteLeaseVariables
	>(
		(variables: DeleteLeaseVariables) =>
			fetchDeleteLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetLeaseDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetLeaseDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetLeaseDetailsVariables = {
	pathParams: GetLeaseDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetLeaseDetails = (
	variables: GetLeaseDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LeaseDetailSchema,
		GetLeaseDetailsError,
		undefined,
		{},
		{},
		GetLeaseDetailsPathParams
	>({ url: '/api/leases/{id}/', method: 'get', ...variables, signal })

export const useGetLeaseDetails = <TData = Schemas.LeaseDetailSchema>(
	variables: GetLeaseDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LeaseDetailSchema,
			GetLeaseDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LeaseDetailSchema,
		GetLeaseDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/leases/{id}/',
			operationId: 'getLeaseDetails',
			variables
		}),
		({ signal }) =>
			fetchGetLeaseDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateLeaseError = Fetcher.ErrorWrapper<undefined>

export type UpdateLeaseVariables = {
	body: Schemas.LeaseSchema
	pathParams: UpdateLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateLease = (
	variables: UpdateLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateLeaseError,
		Schemas.LeaseSchema,
		{},
		{},
		UpdateLeasePathParams
	>({ url: '/api/leases/{id}/', method: 'put', ...variables, signal })

export const useUpdateLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateLeaseError,
			UpdateLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateLeaseError,
		UpdateLeaseVariables
	>(
		(variables: UpdateLeaseVariables) =>
			fetchUpdateLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateLeasePdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateLeasePdfQueryParams = {
	region: string
}

export type GenerateLeasePdfError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateLeasePdfVariables = {
	pathParams: GenerateLeasePdfPathParams
	queryParams: GenerateLeasePdfQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateLeasePdf = (
	variables: GenerateLeasePdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GenerateLeasePdfError,
		undefined,
		{},
		GenerateLeasePdfQueryParams,
		GenerateLeasePdfPathParams
	>({
		url: '/api/leases/{id}/generate-pdf/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateLeasePdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GenerateLeasePdfError,
			GenerateLeasePdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GenerateLeasePdfError,
		GenerateLeasePdfVariables
	>(
		(variables: GenerateLeasePdfVariables) =>
			fetchGenerateLeasePdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateSignatureRequestForLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateSignatureRequestForLeaseQueryParams = {
	region: string
}

export type GenerateSignatureRequestForLeaseError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateSignatureRequestForLeaseVariables = {
	pathParams: GenerateSignatureRequestForLeasePathParams
	queryParams: GenerateSignatureRequestForLeaseQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateSignatureRequestForLease = (
	variables: GenerateSignatureRequestForLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SignatureRequestInSchema,
		GenerateSignatureRequestForLeaseError,
		undefined,
		{},
		GenerateSignatureRequestForLeaseQueryParams,
		GenerateSignatureRequestForLeasePathParams
	>({
		url: '/api/leases/{id}/generate-signature-request/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateSignatureRequestForLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.SignatureRequestInSchema,
			GenerateSignatureRequestForLeaseError,
			GenerateSignatureRequestForLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.SignatureRequestInSchema,
		GenerateSignatureRequestForLeaseError,
		GenerateSignatureRequestForLeaseVariables
	>(
		(variables: GenerateSignatureRequestForLeaseVariables) =>
			fetchGenerateSignatureRequestForLease({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetLeaseRentAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetLeaseRentAccountError = Fetcher.ErrorWrapper<undefined>

export type GetLeaseRentAccountVariables = {
	pathParams: GetLeaseRentAccountPathParams
} & RentalizeContext['fetcherOptions']

/**
 * An endpoint that shows the rent account for a lease. This includes all rent charges (both payments and receivables) for the lease, as well as the current balance outstanding.
 */
export const fetchGetLeaseRentAccount = (
	variables: GetLeaseRentAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LeaseRentAccountSchema,
		GetLeaseRentAccountError,
		undefined,
		{},
		{},
		GetLeaseRentAccountPathParams
	>({
		url: '/api/leases/{id}/rent-account/',
		method: 'get',
		...variables,
		signal
	})

/**
 * An endpoint that shows the rent account for a lease. This includes all rent charges (both payments and receivables) for the lease, as well as the current balance outstanding.
 */
export const useGetLeaseRentAccount = <TData = Schemas.LeaseRentAccountSchema>(
	variables: GetLeaseRentAccountVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LeaseRentAccountSchema,
			GetLeaseRentAccountError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LeaseRentAccountSchema,
		GetLeaseRentAccountError,
		TData
	>(
		queryKeyFn({
			path: '/api/leases/{id}/rent-account/',
			operationId: 'getLeaseRentAccount',
			variables
		}),
		({ signal }) =>
			fetchGetLeaseRentAccount({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetMakeReadyBoardError = Fetcher.ErrorWrapper<undefined>

export type GetMakeReadyBoardVariables = RentalizeContext['fetcherOptions']

export const fetchGetMakeReadyBoard = (
	variables: GetMakeReadyBoardVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MakeReadyBoardSchema,
		GetMakeReadyBoardError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/leases/make-ready-board',
		method: 'get',
		...variables,
		signal
	})

export const useGetMakeReadyBoard = <TData = Schemas.MakeReadyBoardSchema>(
	variables: GetMakeReadyBoardVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MakeReadyBoardSchema,
			GetMakeReadyBoardError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MakeReadyBoardSchema,
		GetMakeReadyBoardError,
		TData
	>(
		queryKeyFn({
			path: '/api/leases/make-ready-board',
			operationId: 'getMakeReadyBoard',
			variables
		}),
		({ signal }) =>
			fetchGetMakeReadyBoard({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetMyLeaseDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMyLeaseDetailsVariables = RentalizeContext['fetcherOptions']

/**
 * An endpoint for a resident to get details about their current lease.
 */
export const fetchGetMyLeaseDetails = (
	variables: GetMyLeaseDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LeaseDetailSchema,
		GetMyLeaseDetailsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/get-my-lease-details/', method: 'get', ...variables, signal })

/**
 * An endpoint for a resident to get details about their current lease.
 */
export const useGetMyLeaseDetails = <TData = Schemas.LeaseDetailSchema>(
	variables: GetMyLeaseDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LeaseDetailSchema,
			GetMyLeaseDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LeaseDetailSchema,
		GetMyLeaseDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/get-my-lease-details/',
			operationId: 'getMyLeaseDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMyLeaseDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetUpcomingVacanciesQueryParams = {
	/**
	 * @default 0
	 */
	months?: number
}

export type GetUpcomingVacanciesError = Fetcher.ErrorWrapper<undefined>

export type GetUpcomingVacanciesResponse = Schemas.RentalUnitOutSchema[]

export type GetUpcomingVacanciesVariables = {
	queryParams?: GetUpcomingVacanciesQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all RentalUnit objects for this User's PropertyManagementCompany,
 * where there is an upcoming vacancy during the next `months` number of months.
 *
 * If `months` == 0, then returns units that are currently vacant.
 */
export const fetchGetUpcomingVacancies = (
	variables: GetUpcomingVacanciesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		GetUpcomingVacanciesResponse,
		GetUpcomingVacanciesError,
		undefined,
		{},
		GetUpcomingVacanciesQueryParams,
		{}
	>({ url: '/api/vacancies/', method: 'get', ...variables, signal })

/**
 * Returns a list of all RentalUnit objects for this User's PropertyManagementCompany,
 * where there is an upcoming vacancy during the next `months` number of months.
 *
 * If `months` == 0, then returns units that are currently vacant.
 */
export const useGetUpcomingVacancies = <TData = GetUpcomingVacanciesResponse>(
	variables: GetUpcomingVacanciesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetUpcomingVacanciesResponse,
			GetUpcomingVacanciesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		GetUpcomingVacanciesResponse,
		GetUpcomingVacanciesError,
		TData
	>(
		queryKeyFn({
			path: '/api/vacancies/',
			operationId: 'getUpcomingVacancies',
			variables
		}),
		({ signal }) =>
			fetchGetUpcomingVacancies({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListEligibleRentIncreasesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListEligibleRentIncreasesError = Fetcher.ErrorWrapper<undefined>

export type ListEligibleRentIncreasesVariables = {
	queryParams?: ListEligibleRentIncreasesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListEligibleRentIncreases = (
	variables: ListEligibleRentIncreasesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedLeaseDetailSchema,
		ListEligibleRentIncreasesError,
		undefined,
		{},
		ListEligibleRentIncreasesQueryParams,
		{}
	>({
		url: '/api/eligible-rent-increases/',
		method: 'get',
		...variables,
		signal
	})

export const useListEligibleRentIncreases = <
	TData = Schemas.PagedLeaseDetailSchema
>(
	variables: ListEligibleRentIncreasesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLeaseDetailSchema,
			ListEligibleRentIncreasesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedLeaseDetailSchema,
		ListEligibleRentIncreasesError,
		TData
	>(
		queryKeyFn({
			path: '/api/eligible-rent-increases/',
			operationId: 'listEligibleRentIncreases',
			variables
		}),
		({ signal }) =>
			fetchListEligibleRentIncreases(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type RemoveResidentFromLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type RemoveResidentFromLeaseError = Fetcher.ErrorWrapper<undefined>

export type RemoveResidentFromLeaseVariables = {
	body: Schemas.RemoveResidentFromLeaseSchema
	pathParams: RemoveResidentFromLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchRemoveResidentFromLease = (
	variables: RemoveResidentFromLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RemoveResidentFromLeaseError,
		Schemas.RemoveResidentFromLeaseSchema,
		{},
		{},
		RemoveResidentFromLeasePathParams
	>({
		url: '/api/leases/{id}/remove-resident-from-lease/',
		method: 'post',
		...variables,
		signal
	})

export const useRemoveResidentFromLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RemoveResidentFromLeaseError,
			RemoveResidentFromLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RemoveResidentFromLeaseError,
		RemoveResidentFromLeaseVariables
	>(
		(variables: RemoveResidentFromLeaseVariables) =>
			fetchRemoveResidentFromLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateLenderError = Fetcher.ErrorWrapper<undefined>

export type CreateLenderVariables = {
	body: Schemas.LenderCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateLender = (
	variables: CreateLenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateLenderError,
		Schemas.LenderCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/lenders/', method: 'post', ...variables, signal })

export const useCreateLender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateLenderError,
			CreateLenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateLenderError,
		CreateLenderVariables
	>(
		(variables: CreateLenderVariables) =>
			fetchCreateLender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListLendersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListLendersError = Fetcher.ErrorWrapper<undefined>

export type ListLendersVariables = {
	queryParams?: ListLendersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListLenders = (
	variables: ListLendersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedLenderSchema,
		ListLendersError,
		undefined,
		{},
		ListLendersQueryParams,
		{}
	>({ url: '/api/lenders/', method: 'get', ...variables, signal })

export const useListLenders = <TData = Schemas.PagedLenderSchema>(
	variables: ListLendersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLenderSchema,
			ListLendersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedLenderSchema,
		ListLendersError,
		TData
	>(
		queryKeyFn({
			path: '/api/lenders/',
			operationId: 'listLenders',
			variables
		}),
		({ signal }) =>
			fetchListLenders({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteLenderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteLenderError = Fetcher.ErrorWrapper<undefined>

export type DeleteLenderVariables = {
	pathParams: DeleteLenderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteLender = (
	variables: DeleteLenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteLenderError,
		undefined,
		{},
		{},
		DeleteLenderPathParams
	>({ url: '/api/lenders/{id}/', method: 'delete', ...variables, signal })

export const useDeleteLender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteLenderError,
			DeleteLenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteLenderError,
		DeleteLenderVariables
	>(
		(variables: DeleteLenderVariables) =>
			fetchDeleteLender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetLenderDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetLenderDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetLenderDetailsVariables = {
	pathParams: GetLenderDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetLenderDetails = (
	variables: GetLenderDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LenderSchema,
		GetLenderDetailsError,
		undefined,
		{},
		{},
		GetLenderDetailsPathParams
	>({ url: '/api/lenders/{id}/', method: 'get', ...variables, signal })

export const useGetLenderDetails = <TData = Schemas.LenderSchema>(
	variables: GetLenderDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LenderSchema,
			GetLenderDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LenderSchema,
		GetLenderDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/lenders/{id}/',
			operationId: 'getLenderDetails',
			variables
		}),
		({ signal }) =>
			fetchGetLenderDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateLenderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateLenderError = Fetcher.ErrorWrapper<undefined>

export type UpdateLenderVariables = {
	body: Schemas.LenderSchema
	pathParams: UpdateLenderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateLender = (
	variables: UpdateLenderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateLenderError,
		Schemas.LenderSchema,
		{},
		{},
		UpdateLenderPathParams
	>({ url: '/api/lenders/{id}/', method: 'put', ...variables, signal })

export const useUpdateLender = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateLenderError,
			UpdateLenderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateLenderError,
		UpdateLenderVariables
	>(
		(variables: UpdateLenderVariables) =>
			fetchUpdateLender({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListDaysOfWeekQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListDaysOfWeekError = Fetcher.ErrorWrapper<undefined>

export type ListDaysOfWeekVariables = {
	queryParams?: ListDaysOfWeekQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListDaysOfWeek = (
	variables: ListDaysOfWeekVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedDayOfWeekSchema,
		ListDaysOfWeekError,
		undefined,
		{},
		ListDaysOfWeekQueryParams,
		{}
	>({ url: '/api/days-of-week', method: 'get', ...variables, signal })

export const useListDaysOfWeek = <TData = Schemas.PagedDayOfWeekSchema>(
	variables: ListDaysOfWeekVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedDayOfWeekSchema,
			ListDaysOfWeekError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedDayOfWeekSchema,
		ListDaysOfWeekError,
		TData
	>(
		queryKeyFn({
			path: '/api/days-of-week',
			operationId: 'listDaysOfWeek',
			variables
		}),
		({ signal }) =>
			fetchListDaysOfWeek({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListWorkspaceWarningsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkspaceWarningsError = Fetcher.ErrorWrapper<undefined>

export type ListWorkspaceWarningsVariables = {
	queryParams?: ListWorkspaceWarningsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkspaceWarnings = (
	variables: ListWorkspaceWarningsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkspaceWarningSchema,
		ListWorkspaceWarningsError,
		undefined,
		{},
		ListWorkspaceWarningsQueryParams,
		{}
	>({ url: '/api/workspace-warnings/', method: 'get', ...variables, signal })

export const useListWorkspaceWarnings = <
	TData = Schemas.PagedWorkspaceWarningSchema
>(
	variables: ListWorkspaceWarningsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkspaceWarningSchema,
			ListWorkspaceWarningsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkspaceWarningSchema,
		ListWorkspaceWarningsError,
		TData
	>(
		queryKeyFn({
			path: '/api/workspace-warnings/',
			operationId: 'listWorkspaceWarnings',
			variables
		}),
		({ signal }) =>
			fetchListWorkspaceWarnings({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateRentalUnitListingError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateRentalUnitListingVariables = {
	body: Schemas.RentalUnitListingCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalUnitListing = (
	variables: CreateRentalUnitListingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalUnitListingError,
		Schemas.RentalUnitListingCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/rental-unit-listings/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentalUnitListing = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalUnitListingError,
			CreateRentalUnitListingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalUnitListingError,
		CreateRentalUnitListingVariables
	>(
		(variables: CreateRentalUnitListingVariables) =>
			fetchCreateRentalUnitListing({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalUnitListingsQueryParams = {
	/**
	 * @default false
	 */
	archived?: boolean
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalUnitListingsError = Fetcher.ErrorWrapper<undefined>

export type ListRentalUnitListingsVariables = {
	queryParams?: ListRentalUnitListingsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalUnitListings = (
	variables: ListRentalUnitListingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalUnitListingDetailSchema,
		ListRentalUnitListingsError,
		undefined,
		{},
		ListRentalUnitListingsQueryParams,
		{}
	>({ url: '/api/rental-unit-listings/', method: 'get', ...variables, signal })

export const useListRentalUnitListings = <
	TData = Schemas.PagedRentalUnitListingDetailSchema
>(
	variables: ListRentalUnitListingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalUnitListingDetailSchema,
			ListRentalUnitListingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalUnitListingDetailSchema,
		ListRentalUnitListingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-unit-listings/',
			operationId: 'listRentalUnitListings',
			variables
		}),
		({ signal }) =>
			fetchListRentalUnitListings({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentalUnitListingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalUnitListingError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentalUnitListingVariables = {
	pathParams: DeleteRentalUnitListingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalUnitListing = (
	variables: DeleteRentalUnitListingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalUnitListingError,
		undefined,
		{},
		{},
		DeleteRentalUnitListingPathParams
	>({
		url: '/api/rental-unit-listings/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentalUnitListing = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalUnitListingError,
			DeleteRentalUnitListingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalUnitListingError,
		DeleteRentalUnitListingVariables
	>(
		(variables: DeleteRentalUnitListingVariables) =>
			fetchDeleteRentalUnitListing({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentalUnitListingDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalUnitListingDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentalUnitListingDetailsVariables = {
	pathParams: GetRentalUnitListingDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalUnitListingDetails = (
	variables: GetRentalUnitListingDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalUnitListingDetailSchema,
		GetRentalUnitListingDetailsError,
		undefined,
		{},
		{},
		GetRentalUnitListingDetailsPathParams
	>({
		url: '/api/rental-unit-listings/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalUnitListingDetails = <
	TData = Schemas.RentalUnitListingDetailSchema
>(
	variables: GetRentalUnitListingDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalUnitListingDetailSchema,
			GetRentalUnitListingDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalUnitListingDetailSchema,
		GetRentalUnitListingDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-unit-listings/{id}/',
			operationId: 'getRentalUnitListingDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalUnitListingDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalUnitListingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalUnitListingError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentalUnitListingVariables = {
	body: Schemas.RentalUnitListingCreateSchema
	pathParams: UpdateRentalUnitListingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalUnitListing = (
	variables: UpdateRentalUnitListingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalUnitListingError,
		Schemas.RentalUnitListingCreateSchema,
		{},
		{},
		UpdateRentalUnitListingPathParams
	>({
		url: '/api/rental-unit-listings/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentalUnitListing = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalUnitListingError,
			UpdateRentalUnitListingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalUnitListingError,
		UpdateRentalUnitListingVariables
	>(
		(variables: UpdateRentalUnitListingVariables) =>
			fetchUpdateRentalUnitListing({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalUnitListingPhotosPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ListRentalUnitListingPhotosQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalUnitListingPhotosError = Fetcher.ErrorWrapper<undefined>

export type ListRentalUnitListingPhotosVariables = {
	pathParams: ListRentalUnitListingPhotosPathParams
	queryParams?: ListRentalUnitListingPhotosQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalUnitListingPhotos = (
	variables: ListRentalUnitListingPhotosVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPhotoSchema,
		ListRentalUnitListingPhotosError,
		undefined,
		{},
		ListRentalUnitListingPhotosQueryParams,
		ListRentalUnitListingPhotosPathParams
	>({
		url: '/api/rental-unit-listing-photos/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalUnitListingPhotos = <
	TData = Schemas.PagedPhotoSchema
>(
	variables: ListRentalUnitListingPhotosVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPhotoSchema,
			ListRentalUnitListingPhotosError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPhotoSchema,
		ListRentalUnitListingPhotosError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-unit-listing-photos/{id}/',
			operationId: 'listRentalUnitListingPhotos',
			variables
		}),
		({ signal }) =>
			fetchListRentalUnitListingPhotos(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateMaintenanceProjectError = Fetcher.ErrorWrapper<undefined>

export type CreateMaintenanceProjectVariables = {
	body: Schemas.MaintenanceProjectCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMaintenanceProject = (
	variables: CreateMaintenanceProjectVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMaintenanceProjectError,
		Schemas.MaintenanceProjectCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/maintenance-projects/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateMaintenanceProject = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMaintenanceProjectError,
			CreateMaintenanceProjectVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMaintenanceProjectError,
		CreateMaintenanceProjectVariables
	>(
		(variables: CreateMaintenanceProjectVariables) =>
			fetchCreateMaintenanceProject({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMaintenanceProjectsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMaintenanceProjectsError = Fetcher.ErrorWrapper<undefined>

export type ListMaintenanceProjectsVariables = {
	queryParams?: ListMaintenanceProjectsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMaintenanceProjects = (
	variables: ListMaintenanceProjectsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMaintenanceProjectSchema,
		ListMaintenanceProjectsError,
		undefined,
		{},
		ListMaintenanceProjectsQueryParams,
		{}
	>({ url: '/api/maintenance-projects/', method: 'get', ...variables, signal })

export const useListMaintenanceProjects = <
	TData = Schemas.PagedMaintenanceProjectSchema
>(
	variables: ListMaintenanceProjectsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMaintenanceProjectSchema,
			ListMaintenanceProjectsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMaintenanceProjectSchema,
		ListMaintenanceProjectsError,
		TData
	>(
		queryKeyFn({
			path: '/api/maintenance-projects/',
			operationId: 'listMaintenanceProjects',
			variables
		}),
		({ signal }) =>
			fetchListMaintenanceProjects({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMaintenanceProjectPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMaintenanceProjectError = Fetcher.ErrorWrapper<undefined>

export type DeleteMaintenanceProjectVariables = {
	pathParams: DeleteMaintenanceProjectPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMaintenanceProject = (
	variables: DeleteMaintenanceProjectVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMaintenanceProjectError,
		undefined,
		{},
		{},
		DeleteMaintenanceProjectPathParams
	>({
		url: '/api/maintenance-projects/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMaintenanceProject = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMaintenanceProjectError,
			DeleteMaintenanceProjectVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMaintenanceProjectError,
		DeleteMaintenanceProjectVariables
	>(
		(variables: DeleteMaintenanceProjectVariables) =>
			fetchDeleteMaintenanceProject({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMaintenanceProjectDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMaintenanceProjectDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMaintenanceProjectDetailsVariables = {
	pathParams: GetMaintenanceProjectDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMaintenanceProjectDetails = (
	variables: GetMaintenanceProjectDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MaintenanceProjectSchema,
		GetMaintenanceProjectDetailsError,
		undefined,
		{},
		{},
		GetMaintenanceProjectDetailsPathParams
	>({
		url: '/api/maintenance-projects/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMaintenanceProjectDetails = <
	TData = Schemas.MaintenanceProjectSchema
>(
	variables: GetMaintenanceProjectDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MaintenanceProjectSchema,
			GetMaintenanceProjectDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MaintenanceProjectSchema,
		GetMaintenanceProjectDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/maintenance-projects/{id}/',
			operationId: 'getMaintenanceProjectDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMaintenanceProjectDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMaintenanceProjectPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMaintenanceProjectError = Fetcher.ErrorWrapper<undefined>

export type UpdateMaintenanceProjectVariables = {
	body: Schemas.MaintenanceProjectSchema
	pathParams: UpdateMaintenanceProjectPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMaintenanceProject = (
	variables: UpdateMaintenanceProjectVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMaintenanceProjectError,
		Schemas.MaintenanceProjectSchema,
		{},
		{},
		UpdateMaintenanceProjectPathParams
	>({
		url: '/api/maintenance-projects/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateMaintenanceProject = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMaintenanceProjectError,
			UpdateMaintenanceProjectVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMaintenanceProjectError,
		UpdateMaintenanceProjectVariables
	>(
		(variables: UpdateMaintenanceProjectVariables) =>
			fetchUpdateMaintenanceProject({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateMaintenanceTechnicianError = Fetcher.ErrorWrapper<undefined>

export type CreateMaintenanceTechnicianVariables = {
	body: Schemas.MaintenanceTechnicianCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMaintenanceTechnician = (
	variables: CreateMaintenanceTechnicianVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMaintenanceTechnicianError,
		Schemas.MaintenanceTechnicianCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/maintenance-technicians/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateMaintenanceTechnician = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMaintenanceTechnicianError,
			CreateMaintenanceTechnicianVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMaintenanceTechnicianError,
		CreateMaintenanceTechnicianVariables
	>(
		(variables: CreateMaintenanceTechnicianVariables) =>
			fetchCreateMaintenanceTechnician({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMaintenanceTechniciansQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMaintenanceTechniciansError = Fetcher.ErrorWrapper<undefined>

export type ListMaintenanceTechniciansVariables = {
	queryParams?: ListMaintenanceTechniciansQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMaintenanceTechnicians = (
	variables: ListMaintenanceTechniciansVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMaintenanceTechnicianSchema,
		ListMaintenanceTechniciansError,
		undefined,
		{},
		ListMaintenanceTechniciansQueryParams,
		{}
	>({
		url: '/api/maintenance-technicians/',
		method: 'get',
		...variables,
		signal
	})

export const useListMaintenanceTechnicians = <
	TData = Schemas.PagedMaintenanceTechnicianSchema
>(
	variables: ListMaintenanceTechniciansVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMaintenanceTechnicianSchema,
			ListMaintenanceTechniciansError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMaintenanceTechnicianSchema,
		ListMaintenanceTechniciansError,
		TData
	>(
		queryKeyFn({
			path: '/api/maintenance-technicians/',
			operationId: 'listMaintenanceTechnicians',
			variables
		}),
		({ signal }) =>
			fetchListMaintenanceTechnicians(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMaintenanceTechnicianPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMaintenanceTechnicianError = Fetcher.ErrorWrapper<undefined>

export type DeleteMaintenanceTechnicianVariables = {
	pathParams: DeleteMaintenanceTechnicianPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMaintenanceTechnician = (
	variables: DeleteMaintenanceTechnicianVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMaintenanceTechnicianError,
		undefined,
		{},
		{},
		DeleteMaintenanceTechnicianPathParams
	>({
		url: '/api/maintenance-technicians/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMaintenanceTechnician = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMaintenanceTechnicianError,
			DeleteMaintenanceTechnicianVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMaintenanceTechnicianError,
		DeleteMaintenanceTechnicianVariables
	>(
		(variables: DeleteMaintenanceTechnicianVariables) =>
			fetchDeleteMaintenanceTechnician({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMaintenanceTechnicianDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMaintenanceTechnicianDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetMaintenanceTechnicianDetailsVariables = {
	pathParams: GetMaintenanceTechnicianDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMaintenanceTechnicianDetails = (
	variables: GetMaintenanceTechnicianDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MaintenanceTechnicianSchema,
		GetMaintenanceTechnicianDetailsError,
		undefined,
		{},
		{},
		GetMaintenanceTechnicianDetailsPathParams
	>({
		url: '/api/maintenance-technicians/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMaintenanceTechnicianDetails = <
	TData = Schemas.MaintenanceTechnicianSchema
>(
	variables: GetMaintenanceTechnicianDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MaintenanceTechnicianSchema,
			GetMaintenanceTechnicianDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MaintenanceTechnicianSchema,
		GetMaintenanceTechnicianDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/maintenance-technicians/{id}/',
			operationId: 'getMaintenanceTechnicianDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMaintenanceTechnicianDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMaintenanceTechnicianPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMaintenanceTechnicianError = Fetcher.ErrorWrapper<undefined>

export type UpdateMaintenanceTechnicianVariables = {
	body: Schemas.MaintenanceTechnicianUpdateSchema
	pathParams: UpdateMaintenanceTechnicianPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMaintenanceTechnician = (
	variables: UpdateMaintenanceTechnicianVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMaintenanceTechnicianError,
		Schemas.MaintenanceTechnicianUpdateSchema,
		{},
		{},
		UpdateMaintenanceTechnicianPathParams
	>({
		url: '/api/maintenance-technicians/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateMaintenanceTechnician = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMaintenanceTechnicianError,
			UpdateMaintenanceTechnicianVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMaintenanceTechnicianError,
		UpdateMaintenanceTechnicianVariables
	>(
		(variables: UpdateMaintenanceTechnicianVariables) =>
			fetchUpdateMaintenanceTechnician({ ...fetcherOptions, ...variables }),
		options
	)
}

export type SendInvitationToExistingMaintenanceTechnicianPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type SendInvitationToExistingMaintenanceTechnicianError =
	Fetcher.ErrorWrapper<undefined>

export type SendInvitationToExistingMaintenanceTechnicianVariables = {
	pathParams: SendInvitationToExistingMaintenanceTechnicianPathParams
} & RentalizeContext['fetcherOptions']

export const fetchSendInvitationToExistingMaintenanceTechnician = (
	variables: SendInvitationToExistingMaintenanceTechnicianVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		SendInvitationToExistingMaintenanceTechnicianError,
		undefined,
		{},
		{},
		SendInvitationToExistingMaintenanceTechnicianPathParams
	>({
		url: '/api/maintenance-technicians/{id}/send-invitation/',
		method: 'post',
		...variables,
		signal
	})

export const useSendInvitationToExistingMaintenanceTechnician = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			SendInvitationToExistingMaintenanceTechnicianError,
			SendInvitationToExistingMaintenanceTechnicianVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		SendInvitationToExistingMaintenanceTechnicianError,
		SendInvitationToExistingMaintenanceTechnicianVariables
	>(
		(variables: SendInvitationToExistingMaintenanceTechnicianVariables) =>
			fetchSendInvitationToExistingMaintenanceTechnician({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type InviteMaintenanceTechnicianError = Fetcher.ErrorWrapper<undefined>

export type InviteMaintenanceTechnicianVariables = {
	body: Schemas.MaintenanceTechnicianInvitationSchema
} & RentalizeContext['fetcherOptions']

export const fetchInviteMaintenanceTechnician = (
	variables: InviteMaintenanceTechnicianVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InviteMaintenanceTechnicianError,
		Schemas.MaintenanceTechnicianInvitationSchema,
		{},
		{},
		{}
	>({
		url: '/api/invite-maintenance-technician/',
		method: 'post',
		...variables,
		signal
	})

export const useInviteMaintenanceTechnician = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InviteMaintenanceTechnicianError,
			InviteMaintenanceTechnicianVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InviteMaintenanceTechnicianError,
		InviteMaintenanceTechnicianVariables
	>(
		(variables: InviteMaintenanceTechnicianVariables) =>
			fetchInviteMaintenanceTechnician({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateMakeReadyError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateMakeReadyVariables = {
	body: Schemas.MakeReadySchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMakeReady = (
	variables: CreateMakeReadyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMakeReadyError,
		Schemas.MakeReadySchema,
		{},
		{},
		{}
	>({
		url: '/api/leasing/make-readies/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateMakeReady = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMakeReadyError,
			CreateMakeReadyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMakeReadyError,
		CreateMakeReadyVariables
	>(
		(variables: CreateMakeReadyVariables) =>
			fetchCreateMakeReady({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMakeReadiesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMakeReadiesError = Fetcher.ErrorWrapper<undefined>

export type ListMakeReadiesVariables = {
	queryParams?: ListMakeReadiesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMakeReadies = (
	variables: ListMakeReadiesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMakeReadyDetailSchema,
		ListMakeReadiesError,
		undefined,
		{},
		ListMakeReadiesQueryParams,
		{}
	>({ url: '/api/leasing/make-readies/', method: 'get', ...variables, signal })

export const useListMakeReadies = <TData = Schemas.PagedMakeReadyDetailSchema>(
	variables: ListMakeReadiesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMakeReadyDetailSchema,
			ListMakeReadiesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMakeReadyDetailSchema,
		ListMakeReadiesError,
		TData
	>(
		queryKeyFn({
			path: '/api/leasing/make-readies/',
			operationId: 'listMakeReadies',
			variables
		}),
		({ signal }) =>
			fetchListMakeReadies({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMakeReadyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMakeReadyError = Fetcher.ErrorWrapper<undefined>

export type DeleteMakeReadyVariables = {
	pathParams: DeleteMakeReadyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMakeReady = (
	variables: DeleteMakeReadyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMakeReadyError,
		undefined,
		{},
		{},
		DeleteMakeReadyPathParams
	>({
		url: '/api/leasing/make-readies/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMakeReady = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMakeReadyError,
			DeleteMakeReadyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMakeReadyError,
		DeleteMakeReadyVariables
	>(
		(variables: DeleteMakeReadyVariables) =>
			fetchDeleteMakeReady({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMakeReadyDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMakeReadyDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMakeReadyDetailsVariables = {
	pathParams: GetMakeReadyDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMakeReadyDetails = (
	variables: GetMakeReadyDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MakeReadyDetailSchema,
		GetMakeReadyDetailsError,
		undefined,
		{},
		{},
		GetMakeReadyDetailsPathParams
	>({
		url: '/api/leasing/make-readies/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMakeReadyDetails = <TData = Schemas.MakeReadyDetailSchema>(
	variables: GetMakeReadyDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MakeReadyDetailSchema,
			GetMakeReadyDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MakeReadyDetailSchema,
		GetMakeReadyDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/leasing/make-readies/{id}/',
			operationId: 'getMakeReadyDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMakeReadyDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMakeReadyPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMakeReadyError = Fetcher.ErrorWrapper<undefined>

export type UpdateMakeReadyVariables = {
	body: Schemas.MakeReadySchema
	pathParams: UpdateMakeReadyPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMakeReady = (
	variables: UpdateMakeReadyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMakeReadyError,
		Schemas.MakeReadySchema,
		{},
		{},
		UpdateMakeReadyPathParams
	>({
		url: '/api/leasing/make-readies/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateMakeReady = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMakeReadyError,
			UpdateMakeReadyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMakeReadyError,
		UpdateMakeReadyVariables
	>(
		(variables: UpdateMakeReadyVariables) =>
			fetchUpdateMakeReady({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMakeReadyTaskCategoriesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMakeReadyTaskCategoriesError = Fetcher.ErrorWrapper<undefined>

export type ListMakeReadyTaskCategoriesVariables = {
	queryParams?: ListMakeReadyTaskCategoriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMakeReadyTaskCategories = (
	variables: ListMakeReadyTaskCategoriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMakeReadyTaskCategorySchema,
		ListMakeReadyTaskCategoriesError,
		undefined,
		{},
		ListMakeReadyTaskCategoriesQueryParams,
		{}
	>({
		url: '/api/make-ready-task-categories/',
		method: 'get',
		...variables,
		signal
	})

export const useListMakeReadyTaskCategories = <
	TData = Schemas.PagedMakeReadyTaskCategorySchema
>(
	variables: ListMakeReadyTaskCategoriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMakeReadyTaskCategorySchema,
			ListMakeReadyTaskCategoriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMakeReadyTaskCategorySchema,
		ListMakeReadyTaskCategoriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/make-ready-task-categories/',
			operationId: 'listMakeReadyTaskCategories',
			variables
		}),
		({ signal }) =>
			fetchListMakeReadyTaskCategories(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateManagementFeePaymentError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateManagementFeePaymentVariables = {
	body: Schemas.ManagementFeePaymentSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateManagementFeePayment = (
	variables: CreateManagementFeePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateManagementFeePaymentError,
		Schemas.ManagementFeePaymentSchema,
		{},
		{},
		{}
	>({
		url: '/api/management-fee-payments/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateManagementFeePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateManagementFeePaymentError,
			CreateManagementFeePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateManagementFeePaymentError,
		CreateManagementFeePaymentVariables
	>(
		(variables: CreateManagementFeePaymentVariables) =>
			fetchCreateManagementFeePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListManagementFeePaymentsQueryParams = {
	management_fee_receivable_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListManagementFeePaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListManagementFeePaymentsVariables = {
	queryParams?: ListManagementFeePaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListManagementFeePayments = (
	variables: ListManagementFeePaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedManagementFeePaymentDetailSchema,
		ListManagementFeePaymentsError,
		undefined,
		{},
		ListManagementFeePaymentsQueryParams,
		{}
	>({
		url: '/api/management-fee-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListManagementFeePayments = <
	TData = Schemas.PagedManagementFeePaymentDetailSchema
>(
	variables: ListManagementFeePaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedManagementFeePaymentDetailSchema,
			ListManagementFeePaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedManagementFeePaymentDetailSchema,
		ListManagementFeePaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/management-fee-payments/',
			operationId: 'listManagementFeePayments',
			variables
		}),
		({ signal }) =>
			fetchListManagementFeePayments(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteManagementFeePaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteManagementFeePaymentError = Fetcher.ErrorWrapper<undefined>

export type DeleteManagementFeePaymentVariables = {
	pathParams: DeleteManagementFeePaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteManagementFeePayment = (
	variables: DeleteManagementFeePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteManagementFeePaymentError,
		undefined,
		{},
		{},
		DeleteManagementFeePaymentPathParams
	>({
		url: '/api/management-fee-payments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteManagementFeePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteManagementFeePaymentError,
			DeleteManagementFeePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteManagementFeePaymentError,
		DeleteManagementFeePaymentVariables
	>(
		(variables: DeleteManagementFeePaymentVariables) =>
			fetchDeleteManagementFeePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetManagementFeePaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetManagementFeePaymentDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetManagementFeePaymentDetailsVariables = {
	pathParams: GetManagementFeePaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetManagementFeePaymentDetails = (
	variables: GetManagementFeePaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ManagementFeePaymentDetailSchema,
		GetManagementFeePaymentDetailsError,
		undefined,
		{},
		{},
		GetManagementFeePaymentDetailsPathParams
	>({
		url: '/api/management-fee-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetManagementFeePaymentDetails = <
	TData = Schemas.ManagementFeePaymentDetailSchema
>(
	variables: GetManagementFeePaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ManagementFeePaymentDetailSchema,
			GetManagementFeePaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ManagementFeePaymentDetailSchema,
		GetManagementFeePaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/management-fee-payments/{id}/',
			operationId: 'getManagementFeePaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetManagementFeePaymentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateManagementFeePaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateManagementFeePaymentError = Fetcher.ErrorWrapper<undefined>

export type UpdateManagementFeePaymentVariables = {
	body: Schemas.ManagementFeePaymentSchema
	pathParams: UpdateManagementFeePaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateManagementFeePayment = (
	variables: UpdateManagementFeePaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateManagementFeePaymentError,
		Schemas.ManagementFeePaymentSchema,
		{},
		{},
		UpdateManagementFeePaymentPathParams
	>({
		url: '/api/management-fee-payments/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateManagementFeePayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateManagementFeePaymentError,
			UpdateManagementFeePaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateManagementFeePaymentError,
		UpdateManagementFeePaymentVariables
	>(
		(variables: UpdateManagementFeePaymentVariables) =>
			fetchUpdateManagementFeePayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateManagementFeeReceivableError = Fetcher.ErrorWrapper<undefined>

export type CreateManagementFeeReceivableVariables = {
	body: Schemas.ManagementFeeReceivableSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateManagementFeeReceivable = (
	variables: CreateManagementFeeReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateManagementFeeReceivableError,
		Schemas.ManagementFeeReceivableSchema,
		{},
		{},
		{}
	>({
		url: '/api/management-fee-receivables/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateManagementFeeReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateManagementFeeReceivableError,
			CreateManagementFeeReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateManagementFeeReceivableError,
		CreateManagementFeeReceivableVariables
	>(
		(variables: CreateManagementFeeReceivableVariables) =>
			fetchCreateManagementFeeReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListManagementFeeReceivablesQueryParams = {
	rental_unit_id?: string | null
	is_outstanding?: boolean | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListManagementFeeReceivablesError = Fetcher.ErrorWrapper<undefined>

export type ListManagementFeeReceivablesVariables = {
	queryParams?: ListManagementFeeReceivablesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListManagementFeeReceivables = (
	variables: ListManagementFeeReceivablesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedManagementFeeReceivableDetailSchema,
		ListManagementFeeReceivablesError,
		undefined,
		{},
		ListManagementFeeReceivablesQueryParams,
		{}
	>({
		url: '/api/management-fee-receivables/',
		method: 'get',
		...variables,
		signal
	})

export const useListManagementFeeReceivables = <
	TData = Schemas.PagedManagementFeeReceivableDetailSchema
>(
	variables: ListManagementFeeReceivablesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedManagementFeeReceivableDetailSchema,
			ListManagementFeeReceivablesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedManagementFeeReceivableDetailSchema,
		ListManagementFeeReceivablesError,
		TData
	>(
		queryKeyFn({
			path: '/api/management-fee-receivables/',
			operationId: 'listManagementFeeReceivables',
			variables
		}),
		({ signal }) =>
			fetchListManagementFeeReceivables(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteManagementFeeReceivablePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteManagementFeeReceivableError = Fetcher.ErrorWrapper<undefined>

export type DeleteManagementFeeReceivableVariables = {
	pathParams: DeleteManagementFeeReceivablePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteManagementFeeReceivable = (
	variables: DeleteManagementFeeReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteManagementFeeReceivableError,
		undefined,
		{},
		{},
		DeleteManagementFeeReceivablePathParams
	>({
		url: '/api/management-fee-receivables/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteManagementFeeReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteManagementFeeReceivableError,
			DeleteManagementFeeReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteManagementFeeReceivableError,
		DeleteManagementFeeReceivableVariables
	>(
		(variables: DeleteManagementFeeReceivableVariables) =>
			fetchDeleteManagementFeeReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetManagementFeeReceivableDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetManagementFeeReceivableDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetManagementFeeReceivableDetailsVariables = {
	pathParams: GetManagementFeeReceivableDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetManagementFeeReceivableDetails = (
	variables: GetManagementFeeReceivableDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ManagementFeeReceivableDetailSchema,
		GetManagementFeeReceivableDetailsError,
		undefined,
		{},
		{},
		GetManagementFeeReceivableDetailsPathParams
	>({
		url: '/api/management-fee-receivables/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetManagementFeeReceivableDetails = <
	TData = Schemas.ManagementFeeReceivableDetailSchema
>(
	variables: GetManagementFeeReceivableDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ManagementFeeReceivableDetailSchema,
			GetManagementFeeReceivableDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ManagementFeeReceivableDetailSchema,
		GetManagementFeeReceivableDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/management-fee-receivables/{id}/',
			operationId: 'getManagementFeeReceivableDetails',
			variables
		}),
		({ signal }) =>
			fetchGetManagementFeeReceivableDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateManagementFeeReceivablePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateManagementFeeReceivableError = Fetcher.ErrorWrapper<undefined>

export type UpdateManagementFeeReceivableVariables = {
	body: Schemas.ManagementFeeReceivableSchema
	pathParams: UpdateManagementFeeReceivablePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateManagementFeeReceivable = (
	variables: UpdateManagementFeeReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateManagementFeeReceivableError,
		Schemas.ManagementFeeReceivableSchema,
		{},
		{},
		UpdateManagementFeeReceivablePathParams
	>({
		url: '/api/management-fee-receivables/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateManagementFeeReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateManagementFeeReceivableError,
			UpdateManagementFeeReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateManagementFeeReceivableError,
		UpdateManagementFeeReceivableVariables
	>(
		(variables: UpdateManagementFeeReceivableVariables) =>
			fetchUpdateManagementFeeReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateMeetingMinutesError = Fetcher.ErrorWrapper<undefined>

export type CreateMeetingMinutesVariables = {
	body: Schemas.MeetingMinutesSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMeetingMinutes = (
	variables: CreateMeetingMinutesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMeetingMinutesError,
		Schemas.MeetingMinutesSchema,
		{},
		{},
		{}
	>({ url: '/api/meeting-minutes/', method: 'post', ...variables, signal })

export const useCreateMeetingMinutes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMeetingMinutesError,
			CreateMeetingMinutesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMeetingMinutesError,
		CreateMeetingMinutesVariables
	>(
		(variables: CreateMeetingMinutesVariables) =>
			fetchCreateMeetingMinutes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMeetingMinutessQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMeetingMinutessError = Fetcher.ErrorWrapper<undefined>

export type ListMeetingMinutessVariables = {
	queryParams?: ListMeetingMinutessQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMeetingMinutess = (
	variables: ListMeetingMinutessVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMeetingMinutesSchema,
		ListMeetingMinutessError,
		undefined,
		{},
		ListMeetingMinutessQueryParams,
		{}
	>({ url: '/api/meeting-minutes/', method: 'get', ...variables, signal })

export const useListMeetingMinutess = <
	TData = Schemas.PagedMeetingMinutesSchema
>(
	variables: ListMeetingMinutessVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMeetingMinutesSchema,
			ListMeetingMinutessError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMeetingMinutesSchema,
		ListMeetingMinutessError,
		TData
	>(
		queryKeyFn({
			path: '/api/meeting-minutes/',
			operationId: 'listMeetingMinutess',
			variables
		}),
		({ signal }) =>
			fetchListMeetingMinutess({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMeetingMinutesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMeetingMinutesError = Fetcher.ErrorWrapper<undefined>

export type DeleteMeetingMinutesVariables = {
	pathParams: DeleteMeetingMinutesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMeetingMinutes = (
	variables: DeleteMeetingMinutesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMeetingMinutesError,
		undefined,
		{},
		{},
		DeleteMeetingMinutesPathParams
	>({
		url: '/api/meeting-minutes/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMeetingMinutes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMeetingMinutesError,
			DeleteMeetingMinutesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMeetingMinutesError,
		DeleteMeetingMinutesVariables
	>(
		(variables: DeleteMeetingMinutesVariables) =>
			fetchDeleteMeetingMinutes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMeetingMinutesDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMeetingMinutesDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMeetingMinutesDetailsVariables = {
	pathParams: GetMeetingMinutesDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMeetingMinutesDetails = (
	variables: GetMeetingMinutesDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MeetingMinutesSchema,
		GetMeetingMinutesDetailsError,
		undefined,
		{},
		{},
		GetMeetingMinutesDetailsPathParams
	>({ url: '/api/meeting-minutes/{id}/', method: 'get', ...variables, signal })

export const useGetMeetingMinutesDetails = <
	TData = Schemas.MeetingMinutesSchema
>(
	variables: GetMeetingMinutesDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MeetingMinutesSchema,
			GetMeetingMinutesDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MeetingMinutesSchema,
		GetMeetingMinutesDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/meeting-minutes/{id}/',
			operationId: 'getMeetingMinutesDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMeetingMinutesDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMeetingMinutesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMeetingMinutesError = Fetcher.ErrorWrapper<undefined>

export type UpdateMeetingMinutesVariables = {
	body: Schemas.MeetingMinutesSchema
	pathParams: UpdateMeetingMinutesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMeetingMinutes = (
	variables: UpdateMeetingMinutesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMeetingMinutesError,
		Schemas.MeetingMinutesSchema,
		{},
		{},
		UpdateMeetingMinutesPathParams
	>({ url: '/api/meeting-minutes/{id}/', method: 'put', ...variables, signal })

export const useUpdateMeetingMinutes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMeetingMinutesError,
			UpdateMeetingMinutesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMeetingMinutesError,
		UpdateMeetingMinutesVariables
	>(
		(variables: UpdateMeetingMinutesVariables) =>
			fetchUpdateMeetingMinutes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateMessageError = Fetcher.ErrorWrapper<undefined>

export type CreateMessageVariables = {
	body: Schemas.MessageSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMessage = (
	variables: CreateMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMessageError,
		Schemas.MessageSchema,
		{},
		{},
		{}
	>({ url: '/api/messages/', method: 'post', ...variables, signal })

export const useCreateMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMessageError,
			CreateMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMessageError,
		CreateMessageVariables
	>(
		(variables: CreateMessageVariables) =>
			fetchCreateMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMessagesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMessagesError = Fetcher.ErrorWrapper<undefined>

export type ListMessagesVariables = {
	queryParams?: ListMessagesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMessages = (
	variables: ListMessagesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMessageSchema,
		ListMessagesError,
		undefined,
		{},
		ListMessagesQueryParams,
		{}
	>({ url: '/api/messages/', method: 'get', ...variables, signal })

export const useListMessages = <TData = Schemas.PagedMessageSchema>(
	variables: ListMessagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMessageSchema,
			ListMessagesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMessageSchema,
		ListMessagesError,
		TData
	>(
		queryKeyFn({
			path: '/api/messages/',
			operationId: 'listMessages',
			variables
		}),
		({ signal }) =>
			fetchListMessages({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMessageError = Fetcher.ErrorWrapper<undefined>

export type DeleteMessageVariables = {
	pathParams: DeleteMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMessage = (
	variables: DeleteMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMessageError,
		undefined,
		{},
		{},
		DeleteMessagePathParams
	>({ url: '/api/messages/{id}/', method: 'delete', ...variables, signal })

export const useDeleteMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMessageError,
			DeleteMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMessageError,
		DeleteMessageVariables
	>(
		(variables: DeleteMessageVariables) =>
			fetchDeleteMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMessageDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMessageDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMessageDetailsVariables = {
	pathParams: GetMessageDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMessageDetails = (
	variables: GetMessageDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MessageSchema,
		GetMessageDetailsError,
		undefined,
		{},
		{},
		GetMessageDetailsPathParams
	>({ url: '/api/messages/{id}/', method: 'get', ...variables, signal })

export const useGetMessageDetails = <TData = Schemas.MessageSchema>(
	variables: GetMessageDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MessageSchema,
			GetMessageDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MessageSchema,
		GetMessageDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/messages/{id}/',
			operationId: 'getMessageDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMessageDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMessageError = Fetcher.ErrorWrapper<undefined>

export type UpdateMessageVariables = {
	body: Schemas.MessageSchema
	pathParams: UpdateMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMessage = (
	variables: UpdateMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMessageError,
		Schemas.MessageSchema,
		{},
		{},
		UpdateMessagePathParams
	>({ url: '/api/messages/{id}/', method: 'put', ...variables, signal })

export const useUpdateMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMessageError,
			UpdateMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMessageError,
		UpdateMessageVariables
	>(
		(variables: UpdateMessageVariables) =>
			fetchUpdateMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ConfirmSms2faError = Fetcher.ErrorWrapper<{
	status: 400
	payload: string
}>

export type ConfirmSms2faVariables = {
	body: Schemas.ConfirmSMS2FASchema
} & RentalizeContext['fetcherOptions']

export const fetchConfirmSms2fa = (
	variables: ConfirmSms2faVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		ConfirmSms2faError,
		Schemas.ConfirmSMS2FASchema,
		{},
		{},
		{}
	>({ url: '/api/sms-2fa/confirm/', method: 'post', ...variables, signal })

export const useConfirmSms2fa = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			ConfirmSms2faError,
			ConfirmSms2faVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		ConfirmSms2faError,
		ConfirmSms2faVariables
	>(
		(variables: ConfirmSms2faVariables) =>
			fetchConfirmSms2fa({ ...fetcherOptions, ...variables }),
		options
	)
}

export type InitiateSms2faError = Fetcher.ErrorWrapper<undefined>

export type InitiateSms2faVariables = RentalizeContext['fetcherOptions']

export const fetchInitiateSms2fa = (
	variables: InitiateSms2faVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InitiateSms2faError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/sms-2fa/initiate/', method: 'post', ...variables, signal })

export const useInitiateSms2fa = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InitiateSms2faError,
			InitiateSms2faVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InitiateSms2faError,
		InitiateSms2faVariables
	>(
		(variables: InitiateSms2faVariables) =>
			fetchInitiateSms2fa({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateMileageReadingError = Fetcher.ErrorWrapper<undefined>

export type CreateMileageReadingVariables = {
	body: Schemas.MileageReadingSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMileageReading = (
	variables: CreateMileageReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMileageReadingError,
		Schemas.MileageReadingSchema,
		{},
		{},
		{}
	>({ url: '/api/mileage-readings/', method: 'post', ...variables, signal })

export const useCreateMileageReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMileageReadingError,
			CreateMileageReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMileageReadingError,
		CreateMileageReadingVariables
	>(
		(variables: CreateMileageReadingVariables) =>
			fetchCreateMileageReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMileageReadingsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMileageReadingsError = Fetcher.ErrorWrapper<undefined>

export type ListMileageReadingsVariables = {
	queryParams?: ListMileageReadingsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMileageReadings = (
	variables: ListMileageReadingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMileageReadingSchema,
		ListMileageReadingsError,
		undefined,
		{},
		ListMileageReadingsQueryParams,
		{}
	>({ url: '/api/mileage-readings/', method: 'get', ...variables, signal })

export const useListMileageReadings = <
	TData = Schemas.PagedMileageReadingSchema
>(
	variables: ListMileageReadingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMileageReadingSchema,
			ListMileageReadingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMileageReadingSchema,
		ListMileageReadingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/mileage-readings/',
			operationId: 'listMileageReadings',
			variables
		}),
		({ signal }) =>
			fetchListMileageReadings({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMileageReadingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMileageReadingError = Fetcher.ErrorWrapper<undefined>

export type DeleteMileageReadingVariables = {
	pathParams: DeleteMileageReadingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMileageReading = (
	variables: DeleteMileageReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMileageReadingError,
		undefined,
		{},
		{},
		DeleteMileageReadingPathParams
	>({
		url: '/api/mileage-readings/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteMileageReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMileageReadingError,
			DeleteMileageReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMileageReadingError,
		DeleteMileageReadingVariables
	>(
		(variables: DeleteMileageReadingVariables) =>
			fetchDeleteMileageReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMileageReadingDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMileageReadingDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMileageReadingDetailsVariables = {
	pathParams: GetMileageReadingDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMileageReadingDetails = (
	variables: GetMileageReadingDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MileageReadingSchema,
		GetMileageReadingDetailsError,
		undefined,
		{},
		{},
		GetMileageReadingDetailsPathParams
	>({
		url: '/api/mileage-readings/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMileageReadingDetails = <
	TData = Schemas.MileageReadingSchema
>(
	variables: GetMileageReadingDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MileageReadingSchema,
			GetMileageReadingDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MileageReadingSchema,
		GetMileageReadingDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/mileage-readings/{id}/',
			operationId: 'getMileageReadingDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMileageReadingDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMileageReadingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMileageReadingError = Fetcher.ErrorWrapper<undefined>

export type UpdateMileageReadingVariables = {
	body: Schemas.MileageReadingSchema
	pathParams: UpdateMileageReadingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMileageReading = (
	variables: UpdateMileageReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMileageReadingError,
		Schemas.MileageReadingSchema,
		{},
		{},
		UpdateMileageReadingPathParams
	>({
		url: '/api/mileage-readings/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateMileageReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMileageReadingError,
			UpdateMileageReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMileageReadingError,
		UpdateMileageReadingVariables
	>(
		(variables: UpdateMileageReadingVariables) =>
			fetchUpdateMileageReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMvftError = Fetcher.ErrorWrapper<undefined>

export type GetMvftVariables = RentalizeContext['fetcherOptions']

export const fetchGetMvft = (
	variables: GetMvftVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<number, GetMvftError, undefined, {}, {}, {}>({
		url: '/api/mvft/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMvft = <TData = number>(
	variables: GetMvftVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<number, GetMvftError, TData>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<number, GetMvftError, TData>(
		queryKeyFn({ path: '/api/mvft/', operationId: 'getMvft', variables }),
		({ signal }) => fetchGetMvft({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateMortgageError = Fetcher.ErrorWrapper<undefined>

export type CreateMortgageVariables = {
	body: Schemas.MortgageSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateMortgage = (
	variables: CreateMortgageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateMortgageError,
		Schemas.MortgageSchema,
		{},
		{},
		{}
	>({ url: '/api/mortgages/', method: 'post', ...variables, signal })

export const useCreateMortgage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateMortgageError,
			CreateMortgageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateMortgageError,
		CreateMortgageVariables
	>(
		(variables: CreateMortgageVariables) =>
			fetchCreateMortgage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListMortgagesQueryParams = {
	lender_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListMortgagesError = Fetcher.ErrorWrapper<undefined>

export type ListMortgagesVariables = {
	queryParams?: ListMortgagesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListMortgages = (
	variables: ListMortgagesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedMortgageDetailSchema,
		ListMortgagesError,
		undefined,
		{},
		ListMortgagesQueryParams,
		{}
	>({ url: '/api/mortgages/', method: 'get', ...variables, signal })

export const useListMortgages = <TData = Schemas.PagedMortgageDetailSchema>(
	variables: ListMortgagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedMortgageDetailSchema,
			ListMortgagesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedMortgageDetailSchema,
		ListMortgagesError,
		TData
	>(
		queryKeyFn({
			path: '/api/mortgages/',
			operationId: 'listMortgages',
			variables
		}),
		({ signal }) =>
			fetchListMortgages({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteMortgagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteMortgageError = Fetcher.ErrorWrapper<undefined>

export type DeleteMortgageVariables = {
	pathParams: DeleteMortgagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteMortgage = (
	variables: DeleteMortgageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteMortgageError,
		undefined,
		{},
		{},
		DeleteMortgagePathParams
	>({ url: '/api/mortgages/{id}/', method: 'delete', ...variables, signal })

export const useDeleteMortgage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteMortgageError,
			DeleteMortgageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteMortgageError,
		DeleteMortgageVariables
	>(
		(variables: DeleteMortgageVariables) =>
			fetchDeleteMortgage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMortgageDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMortgageDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetMortgageDetailsVariables = {
	pathParams: GetMortgageDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMortgageDetails = (
	variables: GetMortgageDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.MortgageDetailSchema,
		GetMortgageDetailsError,
		undefined,
		{},
		{},
		GetMortgageDetailsPathParams
	>({ url: '/api/mortgages/{id}/', method: 'get', ...variables, signal })

export const useGetMortgageDetails = <TData = Schemas.MortgageDetailSchema>(
	variables: GetMortgageDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.MortgageDetailSchema,
			GetMortgageDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.MortgageDetailSchema,
		GetMortgageDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/mortgages/{id}/',
			operationId: 'getMortgageDetails',
			variables
		}),
		({ signal }) =>
			fetchGetMortgageDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateMortgagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateMortgageError = Fetcher.ErrorWrapper<undefined>

export type UpdateMortgageVariables = {
	body: Schemas.MortgageSchema
	pathParams: UpdateMortgagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateMortgage = (
	variables: UpdateMortgageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateMortgageError,
		Schemas.MortgageSchema,
		{},
		{},
		UpdateMortgagePathParams
	>({ url: '/api/mortgages/{id}/', method: 'put', ...variables, signal })

export const useUpdateMortgage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateMortgageError,
			UpdateMortgageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateMortgageError,
		UpdateMortgageVariables
	>(
		(variables: UpdateMortgageVariables) =>
			fetchUpdateMortgage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetMortgageAmortizationSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetMortgageAmortizationScheduleError =
	Fetcher.ErrorWrapper<undefined>

export type GetMortgageAmortizationScheduleResponse =
	Schemas.MortgageAmortizationScheduleSchema[]

export type GetMortgageAmortizationScheduleVariables = {
	pathParams: GetMortgageAmortizationSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetMortgageAmortizationSchedule = (
	variables: GetMortgageAmortizationScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		GetMortgageAmortizationScheduleResponse,
		GetMortgageAmortizationScheduleError,
		undefined,
		{},
		{},
		GetMortgageAmortizationSchedulePathParams
	>({
		url: '/api/mortgages/{id}/amortization-schedule/',
		method: 'get',
		...variables,
		signal
	})

export const useGetMortgageAmortizationSchedule = <
	TData = GetMortgageAmortizationScheduleResponse
>(
	variables: GetMortgageAmortizationScheduleVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			GetMortgageAmortizationScheduleResponse,
			GetMortgageAmortizationScheduleError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		GetMortgageAmortizationScheduleResponse,
		GetMortgageAmortizationScheduleError,
		TData
	>(
		queryKeyFn({
			path: '/api/mortgages/{id}/amortization-schedule/',
			operationId: 'getMortgageAmortizationSchedule',
			variables
		}),
		({ signal }) =>
			fetchGetMortgageAmortizationSchedule(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListLoanAmortizationsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListLoanAmortizationsError = Fetcher.ErrorWrapper<undefined>

export type ListLoanAmortizationsVariables = {
	queryParams?: ListLoanAmortizationsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListLoanAmortizations = (
	variables: ListLoanAmortizationsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedLoanAmortizationSchema,
		ListLoanAmortizationsError,
		undefined,
		{},
		ListLoanAmortizationsQueryParams,
		{}
	>({ url: '/api/loan-amortizations/', method: 'get', ...variables, signal })

export const useListLoanAmortizations = <
	TData = Schemas.PagedLoanAmortizationSchema
>(
	variables: ListLoanAmortizationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedLoanAmortizationSchema,
			ListLoanAmortizationsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedLoanAmortizationSchema,
		ListLoanAmortizationsError,
		TData
	>(
		queryKeyFn({
			path: '/api/loan-amortizations/',
			operationId: 'listLoanAmortizations',
			variables
		}),
		({ signal }) =>
			fetchListLoanAmortizations({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListPaymentFrequenciesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPaymentFrequenciesError = Fetcher.ErrorWrapper<undefined>

export type ListPaymentFrequenciesVariables = {
	queryParams?: ListPaymentFrequenciesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPaymentFrequencies = (
	variables: ListPaymentFrequenciesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPaymentFrequencySchema,
		ListPaymentFrequenciesError,
		undefined,
		{},
		ListPaymentFrequenciesQueryParams,
		{}
	>({ url: '/api/payment-frequencies/', method: 'get', ...variables, signal })

export const useListPaymentFrequencies = <
	TData = Schemas.PagedPaymentFrequencySchema
>(
	variables: ListPaymentFrequenciesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPaymentFrequencySchema,
			ListPaymentFrequenciesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPaymentFrequencySchema,
		ListPaymentFrequenciesError,
		TData
	>(
		queryKeyFn({
			path: '/api/payment-frequencies/',
			operationId: 'listPaymentFrequencies',
			variables
		}),
		({ signal }) =>
			fetchListPaymentFrequencies({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateNoteError = Fetcher.ErrorWrapper<undefined>

export type CreateNoteVariables = {
	body: Schemas.NoteCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateNote = (
	variables: CreateNoteVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateNoteError,
		Schemas.NoteCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/notes/', method: 'post', ...variables, signal })

export const useCreateNote = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateNoteError,
			CreateNoteVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateNoteError,
		CreateNoteVariables
	>(
		(variables: CreateNoteVariables) =>
			fetchCreateNote({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListNotesQueryParams = {
	building_id?: string | null
	lease_id?: string | null
	rental_unit_id?: string | null
	property_owner_id?: string | null
	resident_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListNotesError = Fetcher.ErrorWrapper<undefined>

export type ListNotesVariables = {
	queryParams?: ListNotesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListNotes = (
	variables: ListNotesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedNoteDetailSchema,
		ListNotesError,
		undefined,
		{},
		ListNotesQueryParams,
		{}
	>({ url: '/api/notes/', method: 'get', ...variables, signal })

export const useListNotes = <TData = Schemas.PagedNoteDetailSchema>(
	variables: ListNotesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedNoteDetailSchema,
			ListNotesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedNoteDetailSchema,
		ListNotesError,
		TData
	>(
		queryKeyFn({ path: '/api/notes/', operationId: 'listNotes', variables }),
		({ signal }) => fetchListNotes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteNotesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteNotesError = Fetcher.ErrorWrapper<undefined>

export type DeleteNotesVariables = {
	pathParams: DeleteNotesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteNotes = (
	variables: DeleteNotesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteNotesError,
		undefined,
		{},
		{},
		DeleteNotesPathParams
	>({ url: '/api/notes/{id}/', method: 'delete', ...variables, signal })

export const useDeleteNotes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteNotesError,
			DeleteNotesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteNotesError,
		DeleteNotesVariables
	>(
		(variables: DeleteNotesVariables) =>
			fetchDeleteNotes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetNoteDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetNoteDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetNoteDetailsVariables = {
	pathParams: GetNoteDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetNoteDetails = (
	variables: GetNoteDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.NoteDetailSchema,
		GetNoteDetailsError,
		undefined,
		{},
		{},
		GetNoteDetailsPathParams
	>({ url: '/api/notes/{id}/', method: 'get', ...variables, signal })

export const useGetNoteDetails = <TData = Schemas.NoteDetailSchema>(
	variables: GetNoteDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.NoteDetailSchema,
			GetNoteDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.NoteDetailSchema,
		GetNoteDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/notes/{id}/',
			operationId: 'getNoteDetails',
			variables
		}),
		({ signal }) =>
			fetchGetNoteDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateNotePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateNoteError = Fetcher.ErrorWrapper<undefined>

export type UpdateNoteVariables = {
	body: Schemas.NoteSchema
	pathParams: UpdateNotePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateNote = (
	variables: UpdateNoteVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateNoteError,
		Schemas.NoteSchema,
		{},
		{},
		UpdateNotePathParams
	>({ url: '/api/notes/{id}/', method: 'put', ...variables, signal })

export const useUpdateNote = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateNoteError,
			UpdateNoteVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateNoteError,
		UpdateNoteVariables
	>(
		(variables: UpdateNoteVariables) =>
			fetchUpdateNote({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateNpsSurveyResponseError = Fetcher.ErrorWrapper<undefined>

export type CreateNpsSurveyResponseVariables = {
	body: Schemas.NPSSurveyResponseSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateNpsSurveyResponse = (
	variables: CreateNpsSurveyResponseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateNpsSurveyResponseError,
		Schemas.NPSSurveyResponseSchema,
		{},
		{},
		{}
	>({ url: '/api/nps/', method: 'post', ...variables, signal })

export const useCreateNpsSurveyResponse = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateNpsSurveyResponseError,
			CreateNpsSurveyResponseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateNpsSurveyResponseError,
		CreateNpsSurveyResponseVariables
	>(
		(variables: CreateNpsSurveyResponseVariables) =>
			fetchCreateNpsSurveyResponse({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListNpsSurveyResponsesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListNpsSurveyResponsesError = Fetcher.ErrorWrapper<undefined>

export type ListNpsSurveyResponsesVariables = {
	queryParams?: ListNpsSurveyResponsesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListNpsSurveyResponses = (
	variables: ListNpsSurveyResponsesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedNPSSurveyResponseSchema,
		ListNpsSurveyResponsesError,
		undefined,
		{},
		ListNpsSurveyResponsesQueryParams,
		{}
	>({ url: '/api/nps/', method: 'get', ...variables, signal })

export const useListNpsSurveyResponses = <
	TData = Schemas.PagedNPSSurveyResponseSchema
>(
	variables: ListNpsSurveyResponsesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedNPSSurveyResponseSchema,
			ListNpsSurveyResponsesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedNPSSurveyResponseSchema,
		ListNpsSurveyResponsesError,
		TData
	>(
		queryKeyFn({
			path: '/api/nps/',
			operationId: 'listNpsSurveyResponses',
			variables
		}),
		({ signal }) =>
			fetchListNpsSurveyResponses({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateOwnershipStakesError = Fetcher.ErrorWrapper<undefined>

export type CreateOwnershipStakesVariables = {
	body: Schemas.OwnershipStakeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateOwnershipStakes = (
	variables: CreateOwnershipStakesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateOwnershipStakesError,
		Schemas.OwnershipStakeSchema,
		{},
		{},
		{}
	>({ url: '/api/ownership-stakes/', method: 'post', ...variables, signal })

export const useCreateOwnershipStakes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateOwnershipStakesError,
			CreateOwnershipStakesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateOwnershipStakesError,
		CreateOwnershipStakesVariables
	>(
		(variables: CreateOwnershipStakesVariables) =>
			fetchCreateOwnershipStakes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListOwnershipStakessQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListOwnershipStakessError = Fetcher.ErrorWrapper<undefined>

export type ListOwnershipStakessVariables = {
	queryParams?: ListOwnershipStakessQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListOwnershipStakess = (
	variables: ListOwnershipStakessVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedOwnershipStakeSchema,
		ListOwnershipStakessError,
		undefined,
		{},
		ListOwnershipStakessQueryParams,
		{}
	>({ url: '/api/ownership-stakes/', method: 'get', ...variables, signal })

export const useListOwnershipStakess = <
	TData = Schemas.PagedOwnershipStakeSchema
>(
	variables: ListOwnershipStakessVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedOwnershipStakeSchema,
			ListOwnershipStakessError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedOwnershipStakeSchema,
		ListOwnershipStakessError,
		TData
	>(
		queryKeyFn({
			path: '/api/ownership-stakes/',
			operationId: 'listOwnershipStakess',
			variables
		}),
		({ signal }) =>
			fetchListOwnershipStakess({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteOwnershipStakesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteOwnershipStakesError = Fetcher.ErrorWrapper<undefined>

export type DeleteOwnershipStakesVariables = {
	pathParams: DeleteOwnershipStakesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteOwnershipStakes = (
	variables: DeleteOwnershipStakesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteOwnershipStakesError,
		undefined,
		{},
		{},
		DeleteOwnershipStakesPathParams
	>({
		url: '/api/ownership-stakes/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteOwnershipStakes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteOwnershipStakesError,
			DeleteOwnershipStakesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteOwnershipStakesError,
		DeleteOwnershipStakesVariables
	>(
		(variables: DeleteOwnershipStakesVariables) =>
			fetchDeleteOwnershipStakes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetOwnershipStakesDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetOwnershipStakesDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetOwnershipStakesDetailsVariables = {
	pathParams: GetOwnershipStakesDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetOwnershipStakesDetails = (
	variables: GetOwnershipStakesDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.OwnershipStakeSchema,
		GetOwnershipStakesDetailsError,
		undefined,
		{},
		{},
		GetOwnershipStakesDetailsPathParams
	>({
		url: '/api/ownership-stakes/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetOwnershipStakesDetails = <
	TData = Schemas.OwnershipStakeSchema
>(
	variables: GetOwnershipStakesDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.OwnershipStakeSchema,
			GetOwnershipStakesDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.OwnershipStakeSchema,
		GetOwnershipStakesDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/ownership-stakes/{id}/',
			operationId: 'getOwnershipStakesDetails',
			variables
		}),
		({ signal }) =>
			fetchGetOwnershipStakesDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateOwnershipStakesPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateOwnershipStakesError = Fetcher.ErrorWrapper<undefined>

export type UpdateOwnershipStakesVariables = {
	body: Schemas.OwnershipStakeSchema
	pathParams: UpdateOwnershipStakesPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateOwnershipStakes = (
	variables: UpdateOwnershipStakesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateOwnershipStakesError,
		Schemas.OwnershipStakeSchema,
		{},
		{},
		UpdateOwnershipStakesPathParams
	>({
		url: '/api/ownership-stakes/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateOwnershipStakes = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateOwnershipStakesError,
			UpdateOwnershipStakesVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateOwnershipStakesError,
		UpdateOwnershipStakesVariables
	>(
		(variables: UpdateOwnershipStakesVariables) =>
			fetchUpdateOwnershipStakes({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateParkingSpaceError = Fetcher.ErrorWrapper<undefined>

export type CreateParkingSpaceVariables = {
	body: Schemas.ParkingSpaceInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateParkingSpace = (
	variables: CreateParkingSpaceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateParkingSpaceError,
		Schemas.ParkingSpaceInSchema,
		{},
		{},
		{}
	>({ url: '/api/parking-spaces/', method: 'post', ...variables, signal })

export const useCreateParkingSpace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateParkingSpaceError,
			CreateParkingSpaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateParkingSpaceError,
		CreateParkingSpaceVariables
	>(
		(variables: CreateParkingSpaceVariables) =>
			fetchCreateParkingSpace({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListParkingSpacesQueryParams = {
	building_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListParkingSpacesError = Fetcher.ErrorWrapper<undefined>

export type ListParkingSpacesVariables = {
	queryParams?: ListParkingSpacesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListParkingSpaces = (
	variables: ListParkingSpacesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedParkingSpaceOutSchema,
		ListParkingSpacesError,
		undefined,
		{},
		ListParkingSpacesQueryParams,
		{}
	>({ url: '/api/parking-spaces/', method: 'get', ...variables, signal })

export const useListParkingSpaces = <
	TData = Schemas.PagedParkingSpaceOutSchema
>(
	variables: ListParkingSpacesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedParkingSpaceOutSchema,
			ListParkingSpacesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedParkingSpaceOutSchema,
		ListParkingSpacesError,
		TData
	>(
		queryKeyFn({
			path: '/api/parking-spaces/',
			operationId: 'listParkingSpaces',
			variables
		}),
		({ signal }) =>
			fetchListParkingSpaces({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteParkingSpacePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteParkingSpaceError = Fetcher.ErrorWrapper<undefined>

export type DeleteParkingSpaceVariables = {
	pathParams: DeleteParkingSpacePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteParkingSpace = (
	variables: DeleteParkingSpaceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteParkingSpaceError,
		undefined,
		{},
		{},
		DeleteParkingSpacePathParams
	>({
		url: '/api/parking-spaces/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteParkingSpace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteParkingSpaceError,
			DeleteParkingSpaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteParkingSpaceError,
		DeleteParkingSpaceVariables
	>(
		(variables: DeleteParkingSpaceVariables) =>
			fetchDeleteParkingSpace({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetParkingSpaceDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetParkingSpaceDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetParkingSpaceDetailsVariables = {
	pathParams: GetParkingSpaceDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetParkingSpaceDetails = (
	variables: GetParkingSpaceDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ParkingSpaceOutSchema,
		GetParkingSpaceDetailsError,
		undefined,
		{},
		{},
		GetParkingSpaceDetailsPathParams
	>({ url: '/api/parking-spaces/{id}/', method: 'get', ...variables, signal })

export const useGetParkingSpaceDetails = <
	TData = Schemas.ParkingSpaceOutSchema
>(
	variables: GetParkingSpaceDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ParkingSpaceOutSchema,
			GetParkingSpaceDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ParkingSpaceOutSchema,
		GetParkingSpaceDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/parking-spaces/{id}/',
			operationId: 'getParkingSpaceDetails',
			variables
		}),
		({ signal }) =>
			fetchGetParkingSpaceDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateParkingSpacePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateParkingSpaceError = Fetcher.ErrorWrapper<undefined>

export type UpdateParkingSpaceVariables = {
	body: Schemas.ParkingSpaceInSchema
	pathParams: UpdateParkingSpacePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateParkingSpace = (
	variables: UpdateParkingSpaceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateParkingSpaceError,
		Schemas.ParkingSpaceInSchema,
		{},
		{},
		UpdateParkingSpacePathParams
	>({ url: '/api/parking-spaces/{id}/', method: 'put', ...variables, signal })

export const useUpdateParkingSpace = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateParkingSpaceError,
			UpdateParkingSpaceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateParkingSpaceError,
		UpdateParkingSpaceVariables
	>(
		(variables: UpdateParkingSpaceVariables) =>
			fetchUpdateParkingSpace({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListParkingSpaceTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListParkingSpaceTypesError = Fetcher.ErrorWrapper<undefined>

export type ListParkingSpaceTypesVariables = {
	queryParams?: ListParkingSpaceTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListParkingSpaceTypes = (
	variables: ListParkingSpaceTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedParkingSpaceTypeSchema,
		ListParkingSpaceTypesError,
		undefined,
		{},
		ListParkingSpaceTypesQueryParams,
		{}
	>({ url: '/api/parking-space-types', method: 'get', ...variables, signal })

export const useListParkingSpaceTypes = <
	TData = Schemas.PagedParkingSpaceTypeSchema
>(
	variables: ListParkingSpaceTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedParkingSpaceTypeSchema,
			ListParkingSpaceTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedParkingSpaceTypeSchema,
		ListParkingSpaceTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/parking-space-types',
			operationId: 'listParkingSpaceTypes',
			variables
		}),
		({ signal }) =>
			fetchListParkingSpaceTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreatePhoneCallLogError = Fetcher.ErrorWrapper<undefined>

export type CreatePhoneCallLogVariables = {
	body: Schemas.PhoneCallLogSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePhoneCallLog = (
	variables: CreatePhoneCallLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePhoneCallLogError,
		Schemas.PhoneCallLogSchema,
		{},
		{},
		{}
	>({ url: '/api/phone-call-logs/', method: 'post', ...variables, signal })

export const useCreatePhoneCallLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePhoneCallLogError,
			CreatePhoneCallLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePhoneCallLogError,
		CreatePhoneCallLogVariables
	>(
		(variables: CreatePhoneCallLogVariables) =>
			fetchCreatePhoneCallLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPhoneCallLogsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPhoneCallLogsError = Fetcher.ErrorWrapper<undefined>

export type ListPhoneCallLogsVariables = {
	queryParams?: ListPhoneCallLogsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPhoneCallLogs = (
	variables: ListPhoneCallLogsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPhoneCallLogSchema,
		ListPhoneCallLogsError,
		undefined,
		{},
		ListPhoneCallLogsQueryParams,
		{}
	>({ url: '/api/phone-call-logs/', method: 'get', ...variables, signal })

export const useListPhoneCallLogs = <TData = Schemas.PagedPhoneCallLogSchema>(
	variables: ListPhoneCallLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPhoneCallLogSchema,
			ListPhoneCallLogsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPhoneCallLogSchema,
		ListPhoneCallLogsError,
		TData
	>(
		queryKeyFn({
			path: '/api/phone-call-logs/',
			operationId: 'listPhoneCallLogs',
			variables
		}),
		({ signal }) =>
			fetchListPhoneCallLogs({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePhoneCallLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePhoneCallLogError = Fetcher.ErrorWrapper<undefined>

export type DeletePhoneCallLogVariables = {
	pathParams: DeletePhoneCallLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePhoneCallLog = (
	variables: DeletePhoneCallLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePhoneCallLogError,
		undefined,
		{},
		{},
		DeletePhoneCallLogPathParams
	>({
		url: '/api/phone-call-logs/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePhoneCallLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePhoneCallLogError,
			DeletePhoneCallLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePhoneCallLogError,
		DeletePhoneCallLogVariables
	>(
		(variables: DeletePhoneCallLogVariables) =>
			fetchDeletePhoneCallLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPhoneCallLogDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPhoneCallLogDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPhoneCallLogDetailsVariables = {
	pathParams: GetPhoneCallLogDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPhoneCallLogDetails = (
	variables: GetPhoneCallLogDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PhoneCallLogSchema,
		GetPhoneCallLogDetailsError,
		undefined,
		{},
		{},
		GetPhoneCallLogDetailsPathParams
	>({ url: '/api/phone-call-logs/{id}/', method: 'get', ...variables, signal })

export const useGetPhoneCallLogDetails = <TData = Schemas.PhoneCallLogSchema>(
	variables: GetPhoneCallLogDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PhoneCallLogSchema,
			GetPhoneCallLogDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PhoneCallLogSchema,
		GetPhoneCallLogDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/phone-call-logs/{id}/',
			operationId: 'getPhoneCallLogDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPhoneCallLogDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePhoneCallLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePhoneCallLogError = Fetcher.ErrorWrapper<undefined>

export type UpdatePhoneCallLogVariables = {
	body: Schemas.PhoneCallLogSchema
	pathParams: UpdatePhoneCallLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePhoneCallLog = (
	variables: UpdatePhoneCallLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePhoneCallLogError,
		Schemas.PhoneCallLogSchema,
		{},
		{},
		UpdatePhoneCallLogPathParams
	>({ url: '/api/phone-call-logs/{id}/', method: 'put', ...variables, signal })

export const useUpdatePhoneCallLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePhoneCallLogError,
			UpdatePhoneCallLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePhoneCallLogError,
		UpdatePhoneCallLogVariables
	>(
		(variables: UpdatePhoneCallLogVariables) =>
			fetchUpdatePhoneCallLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePhotoError = Fetcher.ErrorWrapper<undefined>

export type CreatePhotoVariables = {
	body: Schemas.PhotoCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePhoto = (
	variables: CreatePhotoVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePhotoError,
		Schemas.PhotoCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/photos/', method: 'post', ...variables, signal })

export const useCreatePhoto = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePhotoError,
			CreatePhotoVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePhotoError,
		CreatePhotoVariables
	>(
		(variables: CreatePhotoVariables) =>
			fetchCreatePhoto({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPhotosQueryParams = {
	building_id?: string | null
	rental_unit_id?: string | null
	work_order_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPhotosError = Fetcher.ErrorWrapper<undefined>

export type ListPhotosVariables = {
	queryParams?: ListPhotosQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPhotos = (
	variables: ListPhotosVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPhotoDetailSchema,
		ListPhotosError,
		undefined,
		{},
		ListPhotosQueryParams,
		{}
	>({ url: '/api/photos/', method: 'get', ...variables, signal })

export const useListPhotos = <TData = Schemas.PagedPhotoDetailSchema>(
	variables: ListPhotosVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPhotoDetailSchema,
			ListPhotosError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPhotoDetailSchema,
		ListPhotosError,
		TData
	>(
		queryKeyFn({ path: '/api/photos/', operationId: 'listPhotos', variables }),
		({ signal }) =>
			fetchListPhotos({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePhotoPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePhotoError = Fetcher.ErrorWrapper<undefined>

export type DeletePhotoVariables = {
	pathParams: DeletePhotoPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePhoto = (
	variables: DeletePhotoVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePhotoError,
		undefined,
		{},
		{},
		DeletePhotoPathParams
	>({ url: '/api/photos/{id}/', method: 'delete', ...variables, signal })

export const useDeletePhoto = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePhotoError,
			DeletePhotoVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePhotoError,
		DeletePhotoVariables
	>(
		(variables: DeletePhotoVariables) =>
			fetchDeletePhoto({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPhotoDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPhotoDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPhotoDetailsVariables = {
	pathParams: GetPhotoDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPhotoDetails = (
	variables: GetPhotoDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PhotoDetailSchema,
		GetPhotoDetailsError,
		undefined,
		{},
		{},
		GetPhotoDetailsPathParams
	>({ url: '/api/photos/{id}/', method: 'get', ...variables, signal })

export const useGetPhotoDetails = <TData = Schemas.PhotoDetailSchema>(
	variables: GetPhotoDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PhotoDetailSchema,
			GetPhotoDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PhotoDetailSchema,
		GetPhotoDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/photos/{id}/',
			operationId: 'getPhotoDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPhotoDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePhotoPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePhotoError = Fetcher.ErrorWrapper<undefined>

export type UpdatePhotoVariables = {
	body: Schemas.PhotoSchema
	pathParams: UpdatePhotoPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePhoto = (
	variables: UpdatePhotoVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePhotoError,
		Schemas.PhotoSchema,
		{},
		{},
		UpdatePhotoPathParams
	>({ url: '/api/photos/{id}/', method: 'put', ...variables, signal })

export const useUpdatePhoto = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePhotoError,
			UpdatePhotoVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePhotoError,
		UpdatePhotoVariables
	>(
		(variables: UpdatePhotoVariables) =>
			fetchUpdatePhoto({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePropertyGroupError = Fetcher.ErrorWrapper<undefined>

export type CreatePropertyGroupVariables = {
	body: Schemas.PropertyGroupSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyGroup = (
	variables: CreatePropertyGroupVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyGroupError,
		Schemas.PropertyGroupSchema,
		{},
		{},
		{}
	>({ url: '/api/property-groups/', method: 'post', ...variables, signal })

export const useCreatePropertyGroup = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyGroupError,
			CreatePropertyGroupVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyGroupError,
		CreatePropertyGroupVariables
	>(
		(variables: CreatePropertyGroupVariables) =>
			fetchCreatePropertyGroup({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPropertyGroupsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyGroupsError = Fetcher.ErrorWrapper<undefined>

export type ListPropertyGroupsVariables = {
	queryParams?: ListPropertyGroupsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyGroups = (
	variables: ListPropertyGroupsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyGroupSchema,
		ListPropertyGroupsError,
		undefined,
		{},
		ListPropertyGroupsQueryParams,
		{}
	>({ url: '/api/property-groups/', method: 'get', ...variables, signal })

export const useListPropertyGroups = <TData = Schemas.PagedPropertyGroupSchema>(
	variables: ListPropertyGroupsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyGroupSchema,
			ListPropertyGroupsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyGroupSchema,
		ListPropertyGroupsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-groups/',
			operationId: 'listPropertyGroups',
			variables
		}),
		({ signal }) =>
			fetchListPropertyGroups({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyGroupPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyGroupError = Fetcher.ErrorWrapper<undefined>

export type DeletePropertyGroupVariables = {
	pathParams: DeletePropertyGroupPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyGroup = (
	variables: DeletePropertyGroupVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyGroupError,
		undefined,
		{},
		{},
		DeletePropertyGroupPathParams
	>({
		url: '/api/property-groups/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyGroup = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyGroupError,
			DeletePropertyGroupVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyGroupError,
		DeletePropertyGroupVariables
	>(
		(variables: DeletePropertyGroupVariables) =>
			fetchDeletePropertyGroup({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPropertyGroupDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyGroupDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPropertyGroupDetailsVariables = {
	pathParams: GetPropertyGroupDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyGroupDetails = (
	variables: GetPropertyGroupDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyGroupSchema,
		GetPropertyGroupDetailsError,
		undefined,
		{},
		{},
		GetPropertyGroupDetailsPathParams
	>({ url: '/api/property-groups/{id}/', method: 'get', ...variables, signal })

export const useGetPropertyGroupDetails = <TData = Schemas.PropertyGroupSchema>(
	variables: GetPropertyGroupDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyGroupSchema,
			GetPropertyGroupDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyGroupSchema,
		GetPropertyGroupDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-groups/{id}/',
			operationId: 'getPropertyGroupDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyGroupDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyGroupPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyGroupError = Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyGroupVariables = {
	body: Schemas.PropertyGroupSchema
	pathParams: UpdatePropertyGroupPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyGroup = (
	variables: UpdatePropertyGroupVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyGroupError,
		Schemas.PropertyGroupSchema,
		{},
		{},
		UpdatePropertyGroupPathParams
	>({ url: '/api/property-groups/{id}/', method: 'put', ...variables, signal })

export const useUpdatePropertyGroup = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyGroupError,
			UpdatePropertyGroupVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyGroupError,
		UpdatePropertyGroupVariables
	>(
		(variables: UpdatePropertyGroupVariables) =>
			fetchUpdatePropertyGroup({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePropertyInspectionQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type CreatePropertyInspectionQuestionAnswerVariables = {
	body: Schemas.PropertyInspectionQuestionAnswerSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyInspectionQuestionAnswer = (
	variables: CreatePropertyInspectionQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyInspectionQuestionAnswerError,
		Schemas.PropertyInspectionQuestionAnswerSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-inspection-question-answers/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePropertyInspectionQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyInspectionQuestionAnswerError,
			CreatePropertyInspectionQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyInspectionQuestionAnswerError,
		CreatePropertyInspectionQuestionAnswerVariables
	>(
		(variables: CreatePropertyInspectionQuestionAnswerVariables) =>
			fetchCreatePropertyInspectionQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyInspectionQuestionAnswersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyInspectionQuestionAnswersError =
	Fetcher.ErrorWrapper<undefined>

export type ListPropertyInspectionQuestionAnswersVariables = {
	queryParams?: ListPropertyInspectionQuestionAnswersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyInspectionQuestionAnswers = (
	variables: ListPropertyInspectionQuestionAnswersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyInspectionQuestionAnswerSchema,
		ListPropertyInspectionQuestionAnswersError,
		undefined,
		{},
		ListPropertyInspectionQuestionAnswersQueryParams,
		{}
	>({
		url: '/api/property-inspection-question-answers/',
		method: 'get',
		...variables,
		signal
	})

export const useListPropertyInspectionQuestionAnswers = <
	TData = Schemas.PagedPropertyInspectionQuestionAnswerSchema
>(
	variables: ListPropertyInspectionQuestionAnswersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyInspectionQuestionAnswerSchema,
			ListPropertyInspectionQuestionAnswersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyInspectionQuestionAnswerSchema,
		ListPropertyInspectionQuestionAnswersError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-question-answers/',
			operationId: 'listPropertyInspectionQuestionAnswers',
			variables
		}),
		({ signal }) =>
			fetchListPropertyInspectionQuestionAnswers(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyInspectionQuestionAnswerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyInspectionQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type DeletePropertyInspectionQuestionAnswerVariables = {
	pathParams: DeletePropertyInspectionQuestionAnswerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyInspectionQuestionAnswer = (
	variables: DeletePropertyInspectionQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyInspectionQuestionAnswerError,
		undefined,
		{},
		{},
		DeletePropertyInspectionQuestionAnswerPathParams
	>({
		url: '/api/property-inspection-question-answers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyInspectionQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyInspectionQuestionAnswerError,
			DeletePropertyInspectionQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyInspectionQuestionAnswerError,
		DeletePropertyInspectionQuestionAnswerVariables
	>(
		(variables: DeletePropertyInspectionQuestionAnswerVariables) =>
			fetchDeletePropertyInspectionQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetPropertyInspectionQuestionAnswerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyInspectionQuestionAnswerDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPropertyInspectionQuestionAnswerDetailsVariables = {
	pathParams: GetPropertyInspectionQuestionAnswerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyInspectionQuestionAnswerDetails = (
	variables: GetPropertyInspectionQuestionAnswerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyInspectionQuestionAnswerSchema,
		GetPropertyInspectionQuestionAnswerDetailsError,
		undefined,
		{},
		{},
		GetPropertyInspectionQuestionAnswerDetailsPathParams
	>({
		url: '/api/property-inspection-question-answers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyInspectionQuestionAnswerDetails = <
	TData = Schemas.PropertyInspectionQuestionAnswerSchema
>(
	variables: GetPropertyInspectionQuestionAnswerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyInspectionQuestionAnswerSchema,
			GetPropertyInspectionQuestionAnswerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyInspectionQuestionAnswerSchema,
		GetPropertyInspectionQuestionAnswerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-question-answers/{id}/',
			operationId: 'getPropertyInspectionQuestionAnswerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyInspectionQuestionAnswerDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyInspectionQuestionAnswerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyInspectionQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyInspectionQuestionAnswerVariables = {
	body: Schemas.PropertyInspectionQuestionAnswerSchema
	pathParams: UpdatePropertyInspectionQuestionAnswerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyInspectionQuestionAnswer = (
	variables: UpdatePropertyInspectionQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyInspectionQuestionAnswerError,
		Schemas.PropertyInspectionQuestionAnswerSchema,
		{},
		{},
		UpdatePropertyInspectionQuestionAnswerPathParams
	>({
		url: '/api/property-inspection-question-answers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyInspectionQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyInspectionQuestionAnswerError,
			UpdatePropertyInspectionQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyInspectionQuestionAnswerError,
		UpdatePropertyInspectionQuestionAnswerVariables
	>(
		(variables: UpdatePropertyInspectionQuestionAnswerVariables) =>
			fetchUpdatePropertyInspectionQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type CreatePropertyInspectionQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type CreatePropertyInspectionQuestionVariables = {
	body: Schemas.PropertyInspectionQuestionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyInspectionQuestion = (
	variables: CreatePropertyInspectionQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyInspectionQuestionError,
		Schemas.PropertyInspectionQuestionSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-inspection-questions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePropertyInspectionQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyInspectionQuestionError,
			CreatePropertyInspectionQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyInspectionQuestionError,
		CreatePropertyInspectionQuestionVariables
	>(
		(variables: CreatePropertyInspectionQuestionVariables) =>
			fetchCreatePropertyInspectionQuestion({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyInspectionQuestionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyInspectionQuestionsError =
	Fetcher.ErrorWrapper<undefined>

export type ListPropertyInspectionQuestionsVariables = {
	queryParams?: ListPropertyInspectionQuestionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyInspectionQuestions = (
	variables: ListPropertyInspectionQuestionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyInspectionQuestionSchema,
		ListPropertyInspectionQuestionsError,
		undefined,
		{},
		ListPropertyInspectionQuestionsQueryParams,
		{}
	>({
		url: '/api/property-inspection-questions/',
		method: 'get',
		...variables,
		signal
	})

export const useListPropertyInspectionQuestions = <
	TData = Schemas.PagedPropertyInspectionQuestionSchema
>(
	variables: ListPropertyInspectionQuestionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyInspectionQuestionSchema,
			ListPropertyInspectionQuestionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyInspectionQuestionSchema,
		ListPropertyInspectionQuestionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-questions/',
			operationId: 'listPropertyInspectionQuestions',
			variables
		}),
		({ signal }) =>
			fetchListPropertyInspectionQuestions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyInspectionQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyInspectionQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type DeletePropertyInspectionQuestionVariables = {
	pathParams: DeletePropertyInspectionQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyInspectionQuestion = (
	variables: DeletePropertyInspectionQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyInspectionQuestionError,
		undefined,
		{},
		{},
		DeletePropertyInspectionQuestionPathParams
	>({
		url: '/api/property-inspection-questions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyInspectionQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyInspectionQuestionError,
			DeletePropertyInspectionQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyInspectionQuestionError,
		DeletePropertyInspectionQuestionVariables
	>(
		(variables: DeletePropertyInspectionQuestionVariables) =>
			fetchDeletePropertyInspectionQuestion({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetPropertyInspectionQuestionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyInspectionQuestionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPropertyInspectionQuestionDetailsVariables = {
	pathParams: GetPropertyInspectionQuestionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyInspectionQuestionDetails = (
	variables: GetPropertyInspectionQuestionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyInspectionQuestionSchema,
		GetPropertyInspectionQuestionDetailsError,
		undefined,
		{},
		{},
		GetPropertyInspectionQuestionDetailsPathParams
	>({
		url: '/api/property-inspection-questions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyInspectionQuestionDetails = <
	TData = Schemas.PropertyInspectionQuestionSchema
>(
	variables: GetPropertyInspectionQuestionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyInspectionQuestionSchema,
			GetPropertyInspectionQuestionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyInspectionQuestionSchema,
		GetPropertyInspectionQuestionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-questions/{id}/',
			operationId: 'getPropertyInspectionQuestionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyInspectionQuestionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyInspectionQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyInspectionQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyInspectionQuestionVariables = {
	body: Schemas.PropertyInspectionQuestionSchema
	pathParams: UpdatePropertyInspectionQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyInspectionQuestion = (
	variables: UpdatePropertyInspectionQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyInspectionQuestionError,
		Schemas.PropertyInspectionQuestionSchema,
		{},
		{},
		UpdatePropertyInspectionQuestionPathParams
	>({
		url: '/api/property-inspection-questions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyInspectionQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyInspectionQuestionError,
			UpdatePropertyInspectionQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyInspectionQuestionError,
		UpdatePropertyInspectionQuestionVariables
	>(
		(variables: UpdatePropertyInspectionQuestionVariables) =>
			fetchUpdatePropertyInspectionQuestion({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyInspectionQuestionChoicesError =
	Fetcher.ErrorWrapper<undefined>

export type ListPropertyInspectionQuestionChoicesResponse =
	Schemas.PropertyInspectionQuestionChoicesSchema[]

export type ListPropertyInspectionQuestionChoicesVariables =
	RentalizeContext['fetcherOptions']

/**
 * An API to list possible types of questions on a PropertyInspection form
 * Populated from PropertyInspectionQuestion.TYPE_FIELD
 */
export const fetchListPropertyInspectionQuestionChoices = (
	variables: ListPropertyInspectionQuestionChoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListPropertyInspectionQuestionChoicesResponse,
		ListPropertyInspectionQuestionChoicesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/property-inspection-question-choices/',
		method: 'get',
		...variables,
		signal
	})

/**
 * An API to list possible types of questions on a PropertyInspection form
 * Populated from PropertyInspectionQuestion.TYPE_FIELD
 */
export const useListPropertyInspectionQuestionChoices = <
	TData = ListPropertyInspectionQuestionChoicesResponse
>(
	variables: ListPropertyInspectionQuestionChoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListPropertyInspectionQuestionChoicesResponse,
			ListPropertyInspectionQuestionChoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		ListPropertyInspectionQuestionChoicesResponse,
		ListPropertyInspectionQuestionChoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-question-choices/',
			operationId: 'listPropertyInspectionQuestionChoices',
			variables
		}),
		({ signal }) =>
			fetchListPropertyInspectionQuestionChoices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreatePropertyInspectionSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type CreatePropertyInspectionSubmissionVariables = {
	body: Schemas.PropertyInspectionSubmissionCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyInspectionSubmission = (
	variables: CreatePropertyInspectionSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyInspectionSubmissionError,
		Schemas.PropertyInspectionSubmissionCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-inspection-submissions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePropertyInspectionSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyInspectionSubmissionError,
			CreatePropertyInspectionSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyInspectionSubmissionError,
		CreatePropertyInspectionSubmissionVariables
	>(
		(variables: CreatePropertyInspectionSubmissionVariables) =>
			fetchCreatePropertyInspectionSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyInspectionSubmissionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyInspectionSubmissionsError =
	Fetcher.ErrorWrapper<undefined>

export type ListPropertyInspectionSubmissionsVariables = {
	queryParams?: ListPropertyInspectionSubmissionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyInspectionSubmissions = (
	variables: ListPropertyInspectionSubmissionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyInspectionSubmissionDetailSchema,
		ListPropertyInspectionSubmissionsError,
		undefined,
		{},
		ListPropertyInspectionSubmissionsQueryParams,
		{}
	>({
		url: '/api/property-inspection-submissions/',
		method: 'get',
		...variables,
		signal
	})

export const useListPropertyInspectionSubmissions = <
	TData = Schemas.PagedPropertyInspectionSubmissionDetailSchema
>(
	variables: ListPropertyInspectionSubmissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyInspectionSubmissionDetailSchema,
			ListPropertyInspectionSubmissionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyInspectionSubmissionDetailSchema,
		ListPropertyInspectionSubmissionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-submissions/',
			operationId: 'listPropertyInspectionSubmissions',
			variables
		}),
		({ signal }) =>
			fetchListPropertyInspectionSubmissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyInspectionSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyInspectionSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type DeletePropertyInspectionSubmissionVariables = {
	pathParams: DeletePropertyInspectionSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyInspectionSubmission = (
	variables: DeletePropertyInspectionSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyInspectionSubmissionError,
		undefined,
		{},
		{},
		DeletePropertyInspectionSubmissionPathParams
	>({
		url: '/api/property-inspection-submissions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyInspectionSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyInspectionSubmissionError,
			DeletePropertyInspectionSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyInspectionSubmissionError,
		DeletePropertyInspectionSubmissionVariables
	>(
		(variables: DeletePropertyInspectionSubmissionVariables) =>
			fetchDeletePropertyInspectionSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetPropertyInspectionSubmissionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyInspectionSubmissionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPropertyInspectionSubmissionDetailsVariables = {
	pathParams: GetPropertyInspectionSubmissionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyInspectionSubmissionDetails = (
	variables: GetPropertyInspectionSubmissionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyInspectionSubmissionDetailSchema,
		GetPropertyInspectionSubmissionDetailsError,
		undefined,
		{},
		{},
		GetPropertyInspectionSubmissionDetailsPathParams
	>({
		url: '/api/property-inspection-submissions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyInspectionSubmissionDetails = <
	TData = Schemas.PropertyInspectionSubmissionDetailSchema
>(
	variables: GetPropertyInspectionSubmissionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyInspectionSubmissionDetailSchema,
			GetPropertyInspectionSubmissionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyInspectionSubmissionDetailSchema,
		GetPropertyInspectionSubmissionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspection-submissions/{id}/',
			operationId: 'getPropertyInspectionSubmissionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyInspectionSubmissionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreatePropertyInspectionError = Fetcher.ErrorWrapper<undefined>

export type CreatePropertyInspectionVariables = {
	body: Schemas.PropertyInspectionFormSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyInspection = (
	variables: CreatePropertyInspectionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyInspectionError,
		Schemas.PropertyInspectionFormSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-inspections/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePropertyInspection = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyInspectionError,
			CreatePropertyInspectionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyInspectionError,
		CreatePropertyInspectionVariables
	>(
		(variables: CreatePropertyInspectionVariables) =>
			fetchCreatePropertyInspection({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPropertyInspectionsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyInspectionsError = Fetcher.ErrorWrapper<undefined>

export type ListPropertyInspectionsVariables = {
	queryParams?: ListPropertyInspectionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyInspections = (
	variables: ListPropertyInspectionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyInspectionFormSchema,
		ListPropertyInspectionsError,
		undefined,
		{},
		ListPropertyInspectionsQueryParams,
		{}
	>({ url: '/api/property-inspections/', method: 'get', ...variables, signal })

export const useListPropertyInspections = <
	TData = Schemas.PagedPropertyInspectionFormSchema
>(
	variables: ListPropertyInspectionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyInspectionFormSchema,
			ListPropertyInspectionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyInspectionFormSchema,
		ListPropertyInspectionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspections/',
			operationId: 'listPropertyInspections',
			variables
		}),
		({ signal }) =>
			fetchListPropertyInspections({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyInspectionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyInspectionError = Fetcher.ErrorWrapper<undefined>

export type DeletePropertyInspectionVariables = {
	pathParams: DeletePropertyInspectionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyInspection = (
	variables: DeletePropertyInspectionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyInspectionError,
		undefined,
		{},
		{},
		DeletePropertyInspectionPathParams
	>({
		url: '/api/property-inspections/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyInspection = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyInspectionError,
			DeletePropertyInspectionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyInspectionError,
		DeletePropertyInspectionVariables
	>(
		(variables: DeletePropertyInspectionVariables) =>
			fetchDeletePropertyInspection({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPropertyInspectionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyInspectionDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPropertyInspectionDetailsVariables = {
	pathParams: GetPropertyInspectionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyInspectionDetails = (
	variables: GetPropertyInspectionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyInspectionFormSchema,
		GetPropertyInspectionDetailsError,
		undefined,
		{},
		{},
		GetPropertyInspectionDetailsPathParams
	>({
		url: '/api/property-inspections/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyInspectionDetails = <
	TData = Schemas.PropertyInspectionFormSchema
>(
	variables: GetPropertyInspectionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyInspectionFormSchema,
			GetPropertyInspectionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyInspectionFormSchema,
		GetPropertyInspectionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-inspections/{id}/',
			operationId: 'getPropertyInspectionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyInspectionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyInspectionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyInspectionError = Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyInspectionVariables = {
	body: Schemas.PropertyInspectionFormSchema
	pathParams: UpdatePropertyInspectionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyInspection = (
	variables: UpdatePropertyInspectionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyInspectionError,
		Schemas.PropertyInspectionFormSchema,
		{},
		{},
		UpdatePropertyInspectionPathParams
	>({
		url: '/api/property-inspections/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyInspection = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyInspectionError,
			UpdatePropertyInspectionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyInspectionError,
		UpdatePropertyInspectionVariables
	>(
		(variables: UpdatePropertyInspectionVariables) =>
			fetchUpdatePropertyInspection({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPropertyManagementCompanyDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPropertyManagementCompanyDetailsVariables =
	RentalizeContext['fetcherOptions']

export const fetchGetPropertyManagementCompanyDetails = (
	variables: GetPropertyManagementCompanyDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyManagementCompanySchema,
		GetPropertyManagementCompanyDetailsError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/property-management-company/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyManagementCompanyDetails = <
	TData = Schemas.PropertyManagementCompanySchema
>(
	variables: GetPropertyManagementCompanyDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyManagementCompanySchema,
			GetPropertyManagementCompanyDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyManagementCompanySchema,
		GetPropertyManagementCompanyDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-management-company/',
			operationId: 'getPropertyManagementCompanyDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyManagementCompanyDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyManagementCompanyError =
	Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyManagementCompanyVariables = {
	body: Schemas.PropertyManagementCompanyInSchema
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyManagementCompany = (
	variables: UpdatePropertyManagementCompanyVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyManagementCompanyError,
		Schemas.PropertyManagementCompanyInSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-management-company/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyManagementCompany = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyManagementCompanyError,
			UpdatePropertyManagementCompanyVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyManagementCompanyError,
		UpdatePropertyManagementCompanyVariables
	>(
		(variables: UpdatePropertyManagementCompanyVariables) =>
			fetchUpdatePropertyManagementCompany({ ...fetcherOptions, ...variables }),
		options
	)
}

export type PublicGetPropertyManagementCompanyDetailsPathParams = {
	slug: string
}

export type PublicGetPropertyManagementCompanyDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type PublicGetPropertyManagementCompanyDetailsVariables = {
	pathParams: PublicGetPropertyManagementCompanyDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchPublicGetPropertyManagementCompanyDetails = (
	variables: PublicGetPropertyManagementCompanyDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PublicPropertyManagementCompanySchema,
		PublicGetPropertyManagementCompanyDetailsError,
		undefined,
		{},
		{},
		PublicGetPropertyManagementCompanyDetailsPathParams
	>({
		url: '/api/property-management-company/{slug}/',
		method: 'get',
		...variables,
		signal
	})

export const usePublicGetPropertyManagementCompanyDetails = <
	TData = Schemas.PublicPropertyManagementCompanySchema
>(
	variables: PublicGetPropertyManagementCompanyDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PublicPropertyManagementCompanySchema,
			PublicGetPropertyManagementCompanyDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PublicPropertyManagementCompanySchema,
		PublicGetPropertyManagementCompanyDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-management-company/{slug}/',
			operationId: 'publicGetPropertyManagementCompanyDetails',
			variables
		}),
		({ signal }) =>
			fetchPublicGetPropertyManagementCompanyDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreatePropertyManagementFeeScheduleError =
	Fetcher.ErrorWrapper<undefined>

export type CreatePropertyManagementFeeScheduleVariables = {
	body: Schemas.PropertyManagementFeeScheduleSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyManagementFeeSchedule = (
	variables: CreatePropertyManagementFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyManagementFeeScheduleError,
		Schemas.PropertyManagementFeeScheduleSchema,
		{},
		{},
		{}
	>({
		url: '/api/property-management-fee-schedules/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePropertyManagementFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyManagementFeeScheduleError,
			CreatePropertyManagementFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyManagementFeeScheduleError,
		CreatePropertyManagementFeeScheduleVariables
	>(
		(variables: CreatePropertyManagementFeeScheduleVariables) =>
			fetchCreatePropertyManagementFeeSchedule({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyManagementFeeSchedulesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyManagementFeeSchedulesError =
	Fetcher.ErrorWrapper<undefined>

export type ListPropertyManagementFeeSchedulesVariables = {
	queryParams?: ListPropertyManagementFeeSchedulesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyManagementFeeSchedules = (
	variables: ListPropertyManagementFeeSchedulesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyManagementFeeScheduleSchema,
		ListPropertyManagementFeeSchedulesError,
		undefined,
		{},
		ListPropertyManagementFeeSchedulesQueryParams,
		{}
	>({
		url: '/api/property-management-fee-schedules/',
		method: 'get',
		...variables,
		signal
	})

export const useListPropertyManagementFeeSchedules = <
	TData = Schemas.PagedPropertyManagementFeeScheduleSchema
>(
	variables: ListPropertyManagementFeeSchedulesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyManagementFeeScheduleSchema,
			ListPropertyManagementFeeSchedulesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyManagementFeeScheduleSchema,
		ListPropertyManagementFeeSchedulesError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-management-fee-schedules/',
			operationId: 'listPropertyManagementFeeSchedules',
			variables
		}),
		({ signal }) =>
			fetchListPropertyManagementFeeSchedules(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyManagementFeeSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyManagementFeeScheduleError =
	Fetcher.ErrorWrapper<undefined>

export type DeletePropertyManagementFeeScheduleVariables = {
	pathParams: DeletePropertyManagementFeeSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyManagementFeeSchedule = (
	variables: DeletePropertyManagementFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyManagementFeeScheduleError,
		undefined,
		{},
		{},
		DeletePropertyManagementFeeSchedulePathParams
	>({
		url: '/api/property-management-fee-schedules/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyManagementFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyManagementFeeScheduleError,
			DeletePropertyManagementFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyManagementFeeScheduleError,
		DeletePropertyManagementFeeScheduleVariables
	>(
		(variables: DeletePropertyManagementFeeScheduleVariables) =>
			fetchDeletePropertyManagementFeeSchedule({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetPropertyManagementFeeScheduleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyManagementFeeScheduleDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPropertyManagementFeeScheduleDetailsVariables = {
	pathParams: GetPropertyManagementFeeScheduleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyManagementFeeScheduleDetails = (
	variables: GetPropertyManagementFeeScheduleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyManagementFeeScheduleSchema,
		GetPropertyManagementFeeScheduleDetailsError,
		undefined,
		{},
		{},
		GetPropertyManagementFeeScheduleDetailsPathParams
	>({
		url: '/api/property-management-fee-schedules/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyManagementFeeScheduleDetails = <
	TData = Schemas.PropertyManagementFeeScheduleSchema
>(
	variables: GetPropertyManagementFeeScheduleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyManagementFeeScheduleSchema,
			GetPropertyManagementFeeScheduleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyManagementFeeScheduleSchema,
		GetPropertyManagementFeeScheduleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-management-fee-schedules/{id}/',
			operationId: 'getPropertyManagementFeeScheduleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyManagementFeeScheduleDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyManagementFeeSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyManagementFeeScheduleError =
	Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyManagementFeeScheduleVariables = {
	body: Schemas.PropertyManagementFeeScheduleSchema
	pathParams: UpdatePropertyManagementFeeSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyManagementFeeSchedule = (
	variables: UpdatePropertyManagementFeeScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyManagementFeeScheduleError,
		Schemas.PropertyManagementFeeScheduleSchema,
		{},
		{},
		UpdatePropertyManagementFeeSchedulePathParams
	>({
		url: '/api/property-management-fee-schedules/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyManagementFeeSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyManagementFeeScheduleError,
			UpdatePropertyManagementFeeScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyManagementFeeScheduleError,
		UpdatePropertyManagementFeeScheduleVariables
	>(
		(variables: UpdatePropertyManagementFeeScheduleVariables) =>
			fetchUpdatePropertyManagementFeeSchedule({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListPropertyManagementRevenueQueryParams = {
	building_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyManagementRevenueError = Fetcher.ErrorWrapper<undefined>

export type ListPropertyManagementRevenueVariables = {
	queryParams?: ListPropertyManagementRevenueQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyManagementRevenue = (
	variables: ListPropertyManagementRevenueVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyManagementRevenueSchema,
		ListPropertyManagementRevenueError,
		undefined,
		{},
		ListPropertyManagementRevenueQueryParams,
		{}
	>({
		url: '/api/property-management-revenue/',
		method: 'get',
		...variables,
		signal
	})

export const useListPropertyManagementRevenue = <
	TData = Schemas.PagedPropertyManagementRevenueSchema
>(
	variables: ListPropertyManagementRevenueVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyManagementRevenueSchema,
			ListPropertyManagementRevenueError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyManagementRevenueSchema,
		ListPropertyManagementRevenueError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-management-revenue/',
			operationId: 'listPropertyManagementRevenue',
			variables
		}),
		({ signal }) =>
			fetchListPropertyManagementRevenue(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type AcceptInvitationError = Fetcher.ErrorWrapper<undefined>

export type AcceptInvitationVariables = {
	body: Schemas.AcceptInvitationSchema
} & RentalizeContext['fetcherOptions']

export const fetchAcceptInvitation = (
	variables: AcceptInvitationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		AcceptInvitationError,
		Schemas.AcceptInvitationSchema,
		{},
		{},
		{}
	>({ url: '/api/accept-invitation/', method: 'post', ...variables, signal })

export const useAcceptInvitation = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			AcceptInvitationError,
			AcceptInvitationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		AcceptInvitationError,
		AcceptInvitationVariables
	>(
		(variables: AcceptInvitationVariables) =>
			fetchAcceptInvitation({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePropertyManagerError = Fetcher.ErrorWrapper<undefined>

export type CreatePropertyManagerVariables = {
	body: Schemas.PropertyManagerSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePropertyManager = (
	variables: CreatePropertyManagerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePropertyManagerError,
		Schemas.PropertyManagerSchema,
		{},
		{},
		{}
	>({ url: '/api/property-managers/', method: 'post', ...variables, signal })

export const useCreatePropertyManager = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePropertyManagerError,
			CreatePropertyManagerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePropertyManagerError,
		CreatePropertyManagerVariables
	>(
		(variables: CreatePropertyManagerVariables) =>
			fetchCreatePropertyManager({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPropertyManagersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPropertyManagersError = Fetcher.ErrorWrapper<undefined>

export type ListPropertyManagersVariables = {
	queryParams?: ListPropertyManagersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPropertyManagers = (
	variables: ListPropertyManagersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPropertyManagerSchema,
		ListPropertyManagersError,
		undefined,
		{},
		ListPropertyManagersQueryParams,
		{}
	>({ url: '/api/property-managers/', method: 'get', ...variables, signal })

export const useListPropertyManagers = <
	TData = Schemas.PagedPropertyManagerSchema
>(
	variables: ListPropertyManagersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPropertyManagerSchema,
			ListPropertyManagersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPropertyManagerSchema,
		ListPropertyManagersError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-managers/',
			operationId: 'listPropertyManagers',
			variables
		}),
		({ signal }) =>
			fetchListPropertyManagers({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePropertyManagerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePropertyManagerError = Fetcher.ErrorWrapper<undefined>

export type DeletePropertyManagerVariables = {
	pathParams: DeletePropertyManagerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePropertyManager = (
	variables: DeletePropertyManagerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePropertyManagerError,
		undefined,
		{},
		{},
		DeletePropertyManagerPathParams
	>({
		url: '/api/property-managers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePropertyManager = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePropertyManagerError,
			DeletePropertyManagerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePropertyManagerError,
		DeletePropertyManagerVariables
	>(
		(variables: DeletePropertyManagerVariables) =>
			fetchDeletePropertyManager({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPropertyManagerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPropertyManagerDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetPropertyManagerDetailsVariables = {
	pathParams: GetPropertyManagerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPropertyManagerDetails = (
	variables: GetPropertyManagerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PropertyManagerSchema,
		GetPropertyManagerDetailsError,
		undefined,
		{},
		{},
		GetPropertyManagerDetailsPathParams
	>({
		url: '/api/property-managers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPropertyManagerDetails = <
	TData = Schemas.PropertyManagerSchema
>(
	variables: GetPropertyManagerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PropertyManagerSchema,
			GetPropertyManagerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PropertyManagerSchema,
		GetPropertyManagerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/property-managers/{id}/',
			operationId: 'getPropertyManagerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPropertyManagerDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePropertyManagerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePropertyManagerError = Fetcher.ErrorWrapper<undefined>

export type UpdatePropertyManagerVariables = {
	body: Schemas.PropertyManagerSchema
	pathParams: UpdatePropertyManagerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePropertyManager = (
	variables: UpdatePropertyManagerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePropertyManagerError,
		Schemas.PropertyManagerSchema,
		{},
		{},
		UpdatePropertyManagerPathParams
	>({
		url: '/api/property-managers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePropertyManager = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePropertyManagerError,
			UpdatePropertyManagerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePropertyManagerError,
		UpdatePropertyManagerVariables
	>(
		(variables: UpdatePropertyManagerVariables) =>
			fetchUpdatePropertyManager({ ...fetcherOptions, ...variables }),
		options
	)
}

export type InvitePropertyManagerError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type InvitePropertyManagerVariables = {
	body: Schemas.PropertyManagerInvitationSchema
} & RentalizeContext['fetcherOptions']

export const fetchInvitePropertyManager = (
	variables: InvitePropertyManagerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InvitePropertyManagerError,
		Schemas.PropertyManagerInvitationSchema,
		{},
		{},
		{}
	>({
		url: '/api/invite-property-manager/',
		method: 'post',
		...variables,
		signal
	})

export const useInvitePropertyManager = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InvitePropertyManagerError,
			InvitePropertyManagerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InvitePropertyManagerError,
		InvitePropertyManagerVariables
	>(
		(variables: InvitePropertyManagerVariables) =>
			fetchInvitePropertyManager({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreatePublicCalendarEventTypeError = Fetcher.ErrorWrapper<undefined>

export type CreatePublicCalendarEventTypeVariables = {
	body: Schemas.PublicCalendarEventTypeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreatePublicCalendarEventType = (
	variables: CreatePublicCalendarEventTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreatePublicCalendarEventTypeError,
		Schemas.PublicCalendarEventTypeSchema,
		{},
		{},
		{}
	>({
		url: '/api/public-calendar-event-types/',
		method: 'post',
		...variables,
		signal
	})

export const useCreatePublicCalendarEventType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreatePublicCalendarEventTypeError,
			CreatePublicCalendarEventTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreatePublicCalendarEventTypeError,
		CreatePublicCalendarEventTypeVariables
	>(
		(variables: CreatePublicCalendarEventTypeVariables) =>
			fetchCreatePublicCalendarEventType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPublicCalendarEventTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPublicCalendarEventTypesError = Fetcher.ErrorWrapper<undefined>

export type ListPublicCalendarEventTypesVariables = {
	queryParams?: ListPublicCalendarEventTypesQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all PublicCalendarEventType objects for this User's PropertyManagementCompany.
 */
export const fetchListPublicCalendarEventTypes = (
	variables: ListPublicCalendarEventTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPublicCalendarEventTypeSchema,
		ListPublicCalendarEventTypesError,
		undefined,
		{},
		ListPublicCalendarEventTypesQueryParams,
		{}
	>({
		url: '/api/public-calendar-event-types/',
		method: 'get',
		...variables,
		signal
	})

/**
 * Returns a list of all PublicCalendarEventType objects for this User's PropertyManagementCompany.
 */
export const useListPublicCalendarEventTypes = <
	TData = Schemas.PagedPublicCalendarEventTypeSchema
>(
	variables: ListPublicCalendarEventTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPublicCalendarEventTypeSchema,
			ListPublicCalendarEventTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPublicCalendarEventTypeSchema,
		ListPublicCalendarEventTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/public-calendar-event-types/',
			operationId: 'listPublicCalendarEventTypes',
			variables
		}),
		({ signal }) =>
			fetchListPublicCalendarEventTypes(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeletePublicCalendarEventTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeletePublicCalendarEventTypeError = Fetcher.ErrorWrapper<undefined>

export type DeletePublicCalendarEventTypeVariables = {
	pathParams: DeletePublicCalendarEventTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeletePublicCalendarEventType = (
	variables: DeletePublicCalendarEventTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeletePublicCalendarEventTypeError,
		undefined,
		{},
		{},
		DeletePublicCalendarEventTypePathParams
	>({
		url: '/api/public-calendar-event-types/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeletePublicCalendarEventType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeletePublicCalendarEventTypeError,
			DeletePublicCalendarEventTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeletePublicCalendarEventTypeError,
		DeletePublicCalendarEventTypeVariables
	>(
		(variables: DeletePublicCalendarEventTypeVariables) =>
			fetchDeletePublicCalendarEventType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetPublicCalendarEventTypeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetPublicCalendarEventTypeDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetPublicCalendarEventTypeDetailsVariables = {
	pathParams: GetPublicCalendarEventTypeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetPublicCalendarEventTypeDetails = (
	variables: GetPublicCalendarEventTypeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PublicCalendarEventTypeSchema,
		GetPublicCalendarEventTypeDetailsError,
		undefined,
		{},
		{},
		GetPublicCalendarEventTypeDetailsPathParams
	>({
		url: '/api/public-calendar-event-types/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetPublicCalendarEventTypeDetails = <
	TData = Schemas.PublicCalendarEventTypeSchema
>(
	variables: GetPublicCalendarEventTypeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PublicCalendarEventTypeSchema,
			GetPublicCalendarEventTypeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PublicCalendarEventTypeSchema,
		GetPublicCalendarEventTypeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/public-calendar-event-types/{id}/',
			operationId: 'getPublicCalendarEventTypeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetPublicCalendarEventTypeDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdatePublicCalendarEventTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdatePublicCalendarEventTypeError = Fetcher.ErrorWrapper<undefined>

export type UpdatePublicCalendarEventTypeVariables = {
	body: Schemas.PublicCalendarEventTypeSchema
	pathParams: UpdatePublicCalendarEventTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdatePublicCalendarEventType = (
	variables: UpdatePublicCalendarEventTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdatePublicCalendarEventTypeError,
		Schemas.PublicCalendarEventTypeSchema,
		{},
		{},
		UpdatePublicCalendarEventTypePathParams
	>({
		url: '/api/public-calendar-event-types/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdatePublicCalendarEventType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdatePublicCalendarEventTypeError,
			UpdatePublicCalendarEventTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdatePublicCalendarEventTypeError,
		UpdatePublicCalendarEventTypeVariables
	>(
		(variables: UpdatePublicCalendarEventTypeVariables) =>
			fetchUpdatePublicCalendarEventType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type RecordFrontendCacheRefreshError = Fetcher.ErrorWrapper<undefined>

export type RecordFrontendCacheRefreshVariables =
	RentalizeContext['fetcherOptions']

export const fetchRecordFrontendCacheRefresh = (
	variables: RecordFrontendCacheRefreshVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RecordFrontendCacheRefreshError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/record-frontend-cache-refresh/',
		method: 'post',
		...variables,
		signal
	})

export const useRecordFrontendCacheRefresh = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RecordFrontendCacheRefreshError,
			RecordFrontendCacheRefreshVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RecordFrontendCacheRefreshError,
		RecordFrontendCacheRefreshVariables
	>(
		(variables: RecordFrontendCacheRefreshVariables) =>
			fetchRecordFrontendCacheRefresh({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringJournalEntryError = Fetcher.ErrorWrapper<undefined>

export type CreateRecurringJournalEntryVariables = {
	body: Schemas.RecurringJournalEntryCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringJournalEntry = (
	variables: CreateRecurringJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringJournalEntryError,
		Schemas.RecurringJournalEntryCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/recurring-journal-entries/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRecurringJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringJournalEntryError,
			CreateRecurringJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringJournalEntryError,
		CreateRecurringJournalEntryVariables
	>(
		(variables: CreateRecurringJournalEntryVariables) =>
			fetchCreateRecurringJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringJournalEntriesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringJournalEntriesError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringJournalEntriesVariables = {
	queryParams?: ListRecurringJournalEntriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringJournalEntries = (
	variables: ListRecurringJournalEntriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringJournalEntryDetailSchema,
		ListRecurringJournalEntriesError,
		undefined,
		{},
		ListRecurringJournalEntriesQueryParams,
		{}
	>({
		url: '/api/recurring-journal-entries/',
		method: 'get',
		...variables,
		signal
	})

export const useListRecurringJournalEntries = <
	TData = Schemas.PagedRecurringJournalEntryDetailSchema
>(
	variables: ListRecurringJournalEntriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringJournalEntryDetailSchema,
			ListRecurringJournalEntriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringJournalEntryDetailSchema,
		ListRecurringJournalEntriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-journal-entries/',
			operationId: 'listRecurringJournalEntries',
			variables
		}),
		({ signal }) =>
			fetchListRecurringJournalEntries(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringJournalEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringJournalEntryError = Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringJournalEntryVariables = {
	pathParams: DeleteRecurringJournalEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringJournalEntry = (
	variables: DeleteRecurringJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringJournalEntryError,
		undefined,
		{},
		{},
		DeleteRecurringJournalEntryPathParams
	>({
		url: '/api/recurring-journal-entries/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringJournalEntryError,
			DeleteRecurringJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringJournalEntryError,
		DeleteRecurringJournalEntryVariables
	>(
		(variables: DeleteRecurringJournalEntryVariables) =>
			fetchDeleteRecurringJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringJournalEntryDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringJournalEntryDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRecurringJournalEntryDetailsVariables = {
	pathParams: GetRecurringJournalEntryDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringJournalEntryDetails = (
	variables: GetRecurringJournalEntryDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringJournalEntrySchema,
		GetRecurringJournalEntryDetailsError,
		undefined,
		{},
		{},
		GetRecurringJournalEntryDetailsPathParams
	>({
		url: '/api/recurring-journal-entries/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRecurringJournalEntryDetails = <
	TData = Schemas.RecurringJournalEntrySchema
>(
	variables: GetRecurringJournalEntryDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringJournalEntrySchema,
			GetRecurringJournalEntryDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringJournalEntrySchema,
		GetRecurringJournalEntryDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-journal-entries/{id}/',
			operationId: 'getRecurringJournalEntryDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringJournalEntryDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringJournalEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringJournalEntryError = Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringJournalEntryVariables = {
	body: Schemas.RecurringJournalEntrySchema
	pathParams: UpdateRecurringJournalEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringJournalEntry = (
	variables: UpdateRecurringJournalEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringJournalEntryError,
		Schemas.RecurringJournalEntrySchema,
		{},
		{},
		UpdateRecurringJournalEntryPathParams
	>({
		url: '/api/recurring-journal-entries/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRecurringJournalEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringJournalEntryError,
			UpdateRecurringJournalEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringJournalEntryError,
		UpdateRecurringJournalEntryVariables
	>(
		(variables: UpdateRecurringJournalEntryVariables) =>
			fetchUpdateRecurringJournalEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringTaskError = Fetcher.ErrorWrapper<undefined>

export type CreateRecurringTaskVariables = {
	body: Schemas.RecurringTaskSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringTask = (
	variables: CreateRecurringTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringTaskError,
		Schemas.RecurringTaskSchema,
		{},
		{},
		{}
	>({ url: '/api/recurring-tasks/', method: 'post', ...variables, signal })

export const useCreateRecurringTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringTaskError,
			CreateRecurringTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringTaskError,
		CreateRecurringTaskVariables
	>(
		(variables: CreateRecurringTaskVariables) =>
			fetchCreateRecurringTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringTasksQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringTasksError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringTasksVariables = {
	queryParams?: ListRecurringTasksQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringTasks = (
	variables: ListRecurringTasksVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringTaskSchema,
		ListRecurringTasksError,
		undefined,
		{},
		ListRecurringTasksQueryParams,
		{}
	>({ url: '/api/recurring-tasks/', method: 'get', ...variables, signal })

export const useListRecurringTasks = <TData = Schemas.PagedRecurringTaskSchema>(
	variables: ListRecurringTasksVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringTaskSchema,
			ListRecurringTasksError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringTaskSchema,
		ListRecurringTasksError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-tasks/',
			operationId: 'listRecurringTasks',
			variables
		}),
		({ signal }) =>
			fetchListRecurringTasks({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringTaskPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringTaskError = Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringTaskVariables = {
	pathParams: DeleteRecurringTaskPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringTask = (
	variables: DeleteRecurringTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringTaskError,
		undefined,
		{},
		{},
		DeleteRecurringTaskPathParams
	>({
		url: '/api/recurring-tasks/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringTaskError,
			DeleteRecurringTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringTaskError,
		DeleteRecurringTaskVariables
	>(
		(variables: DeleteRecurringTaskVariables) =>
			fetchDeleteRecurringTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringTaskDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringTaskDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRecurringTaskDetailsVariables = {
	pathParams: GetRecurringTaskDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringTaskDetails = (
	variables: GetRecurringTaskDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringTaskSchema,
		GetRecurringTaskDetailsError,
		undefined,
		{},
		{},
		GetRecurringTaskDetailsPathParams
	>({ url: '/api/recurring-tasks/{id}/', method: 'get', ...variables, signal })

export const useGetRecurringTaskDetails = <TData = Schemas.RecurringTaskSchema>(
	variables: GetRecurringTaskDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringTaskSchema,
			GetRecurringTaskDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringTaskSchema,
		GetRecurringTaskDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-tasks/{id}/',
			operationId: 'getRecurringTaskDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringTaskDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringTaskPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringTaskError = Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringTaskVariables = {
	body: Schemas.RecurringTaskSchema
	pathParams: UpdateRecurringTaskPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringTask = (
	variables: UpdateRecurringTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringTaskError,
		Schemas.RecurringTaskSchema,
		{},
		{},
		UpdateRecurringTaskPathParams
	>({ url: '/api/recurring-tasks/{id}/', method: 'put', ...variables, signal })

export const useUpdateRecurringTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringTaskError,
			UpdateRecurringTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringTaskError,
		UpdateRecurringTaskVariables
	>(
		(variables: UpdateRecurringTaskVariables) =>
			fetchUpdateRecurringTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRecurringWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type CreateRecurringWorkOrderVariables = {
	body: Schemas.RecurringWorkOrderCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRecurringWorkOrder = (
	variables: CreateRecurringWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRecurringWorkOrderError,
		Schemas.RecurringWorkOrderCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/recurring-work-orders/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRecurringWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRecurringWorkOrderError,
			CreateRecurringWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRecurringWorkOrderError,
		CreateRecurringWorkOrderVariables
	>(
		(variables: CreateRecurringWorkOrderVariables) =>
			fetchCreateRecurringWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRecurringWorkOrdersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRecurringWorkOrdersError = Fetcher.ErrorWrapper<undefined>

export type ListRecurringWorkOrdersVariables = {
	queryParams?: ListRecurringWorkOrdersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRecurringWorkOrders = (
	variables: ListRecurringWorkOrdersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRecurringWorkOrderSchema,
		ListRecurringWorkOrdersError,
		undefined,
		{},
		ListRecurringWorkOrdersQueryParams,
		{}
	>({
		url: '/api/recurring-work-orders/',
		method: 'get',
		...variables,
		signal
	})

export const useListRecurringWorkOrders = <
	TData = Schemas.PagedRecurringWorkOrderSchema
>(
	variables: ListRecurringWorkOrdersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRecurringWorkOrderSchema,
			ListRecurringWorkOrdersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRecurringWorkOrderSchema,
		ListRecurringWorkOrdersError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-work-orders/',
			operationId: 'listRecurringWorkOrders',
			variables
		}),
		({ signal }) =>
			fetchListRecurringWorkOrders({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRecurringWorkOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRecurringWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type DeleteRecurringWorkOrderVariables = {
	pathParams: DeleteRecurringWorkOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRecurringWorkOrder = (
	variables: DeleteRecurringWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRecurringWorkOrderError,
		undefined,
		{},
		{},
		DeleteRecurringWorkOrderPathParams
	>({
		url: '/api/recurring-work-orders/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRecurringWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRecurringWorkOrderError,
			DeleteRecurringWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRecurringWorkOrderError,
		DeleteRecurringWorkOrderVariables
	>(
		(variables: DeleteRecurringWorkOrderVariables) =>
			fetchDeleteRecurringWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRecurringWorkOrderDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRecurringWorkOrderDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRecurringWorkOrderDetailsVariables = {
	pathParams: GetRecurringWorkOrderDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRecurringWorkOrderDetails = (
	variables: GetRecurringWorkOrderDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RecurringWorkOrderSchema,
		GetRecurringWorkOrderDetailsError,
		undefined,
		{},
		{},
		GetRecurringWorkOrderDetailsPathParams
	>({
		url: '/api/recurring-work-orders/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRecurringWorkOrderDetails = <
	TData = Schemas.RecurringWorkOrderSchema
>(
	variables: GetRecurringWorkOrderDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RecurringWorkOrderSchema,
			GetRecurringWorkOrderDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RecurringWorkOrderSchema,
		GetRecurringWorkOrderDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/recurring-work-orders/{id}/',
			operationId: 'getRecurringWorkOrderDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRecurringWorkOrderDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRecurringWorkOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRecurringWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type UpdateRecurringWorkOrderVariables = {
	body: Schemas.RecurringWorkOrderSchema
	pathParams: UpdateRecurringWorkOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRecurringWorkOrder = (
	variables: UpdateRecurringWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRecurringWorkOrderError,
		Schemas.RecurringWorkOrderSchema,
		{},
		{},
		UpdateRecurringWorkOrderPathParams
	>({
		url: '/api/recurring-work-orders/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRecurringWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRecurringWorkOrderError,
			UpdateRecurringWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRecurringWorkOrderError,
		UpdateRecurringWorkOrderVariables
	>(
		(variables: UpdateRecurringWorkOrderVariables) =>
			fetchUpdateRecurringWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateAffiliateError = Fetcher.ErrorWrapper<undefined>

export type CreateAffiliateVariables = {
	body: Schemas.AffiliateCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateAffiliate = (
	variables: CreateAffiliateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateAffiliateError,
		Schemas.AffiliateCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/referral/affiliates/', method: 'post', ...variables, signal })

export const useCreateAffiliate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateAffiliateError,
			CreateAffiliateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateAffiliateError,
		CreateAffiliateVariables
	>(
		(variables: CreateAffiliateVariables) =>
			fetchCreateAffiliate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListAffiliatesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAffiliatesError = Fetcher.ErrorWrapper<undefined>

export type ListAffiliatesVariables = {
	queryParams?: ListAffiliatesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAffiliates = (
	variables: ListAffiliatesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAffiliateSchema,
		ListAffiliatesError,
		undefined,
		{},
		ListAffiliatesQueryParams,
		{}
	>({ url: '/api/referral/affiliates/', method: 'get', ...variables, signal })

export const useListAffiliates = <TData = Schemas.PagedAffiliateSchema>(
	variables: ListAffiliatesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAffiliateSchema,
			ListAffiliatesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAffiliateSchema,
		ListAffiliatesError,
		TData
	>(
		queryKeyFn({
			path: '/api/referral/affiliates/',
			operationId: 'listAffiliates',
			variables
		}),
		({ signal }) =>
			fetchListAffiliates({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListAffiliateIncentivesQueryParams = {
	affiliate_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAffiliateIncentivesError = Fetcher.ErrorWrapper<undefined>

export type ListAffiliateIncentivesVariables = {
	queryParams?: ListAffiliateIncentivesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAffiliateIncentives = (
	variables: ListAffiliateIncentivesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAffiliateIncentiveSchema,
		ListAffiliateIncentivesError,
		undefined,
		{},
		ListAffiliateIncentivesQueryParams,
		{}
	>({
		url: '/api/referral/affiliate-incentives/',
		method: 'get',
		...variables,
		signal
	})

export const useListAffiliateIncentives = <
	TData = Schemas.PagedAffiliateIncentiveSchema
>(
	variables: ListAffiliateIncentivesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAffiliateIncentiveSchema,
			ListAffiliateIncentivesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAffiliateIncentiveSchema,
		ListAffiliateIncentivesError,
		TData
	>(
		queryKeyFn({
			path: '/api/referral/affiliate-incentives/',
			operationId: 'listAffiliateIncentives',
			variables
		}),
		({ signal }) =>
			fetchListAffiliateIncentives({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListAffiliateInvoiceLineItemsQueryParams = {
	affiliate_invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAffiliateInvoiceLineItemsError = Fetcher.ErrorWrapper<undefined>

export type ListAffiliateInvoiceLineItemsVariables = {
	queryParams?: ListAffiliateInvoiceLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAffiliateInvoiceLineItems = (
	variables: ListAffiliateInvoiceLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAffiliateInvoiceLineItemSchema,
		ListAffiliateInvoiceLineItemsError,
		undefined,
		{},
		ListAffiliateInvoiceLineItemsQueryParams,
		{}
	>({
		url: '/api/referral/affiliate-invoice-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListAffiliateInvoiceLineItems = <
	TData = Schemas.PagedAffiliateInvoiceLineItemSchema
>(
	variables: ListAffiliateInvoiceLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAffiliateInvoiceLineItemSchema,
			ListAffiliateInvoiceLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAffiliateInvoiceLineItemSchema,
		ListAffiliateInvoiceLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/referral/affiliate-invoice-line-items/',
			operationId: 'listAffiliateInvoiceLineItems',
			variables
		}),
		({ signal }) =>
			fetchListAffiliateInvoiceLineItems(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListAffiliateInvoicesQueryParams = {
	affiliate_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListAffiliateInvoicesError = Fetcher.ErrorWrapper<undefined>

export type ListAffiliateInvoicesVariables = {
	queryParams?: ListAffiliateInvoicesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListAffiliateInvoices = (
	variables: ListAffiliateInvoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedAffiliateInvoiceSchema,
		ListAffiliateInvoicesError,
		undefined,
		{},
		ListAffiliateInvoicesQueryParams,
		{}
	>({
		url: '/api/referral/affiliate-invoices/',
		method: 'get',
		...variables,
		signal
	})

export const useListAffiliateInvoices = <
	TData = Schemas.PagedAffiliateInvoiceSchema
>(
	variables: ListAffiliateInvoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedAffiliateInvoiceSchema,
			ListAffiliateInvoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedAffiliateInvoiceSchema,
		ListAffiliateInvoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/referral/affiliate-invoices/',
			operationId: 'listAffiliateInvoices',
			variables
		}),
		({ signal }) =>
			fetchListAffiliateInvoices({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type RegisterError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type RegisterVariables = {
	body: Schemas.RegistrationSchema
} & RentalizeContext['fetcherOptions']

export const fetchRegister = (
	variables: RegisterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		RegisterError,
		Schemas.RegistrationSchema,
		{},
		{},
		{}
	>({ url: '/api/register/', method: 'post', ...variables, signal })

export const useRegister = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			RegisterError,
			RegisterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		RegisterError,
		RegisterVariables
	>(
		(variables: RegisterVariables) =>
			fetchRegister({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentChargeError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateRentChargeVariables = {
	body: Schemas.RentChargeCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentCharge = (
	variables: CreateRentChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentChargeError,
		Schemas.RentChargeCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/rent-charges/', method: 'post', ...variables, signal })

export const useCreateRentCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentChargeError,
			CreateRentChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentChargeError,
		CreateRentChargeVariables
	>(
		(variables: CreateRentChargeVariables) =>
			fetchCreateRentCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentChargesQueryParams = {
	lease_id?: string | null
	rent_receivable_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentChargesError = Fetcher.ErrorWrapper<undefined>

export type ListRentChargesVariables = {
	queryParams?: ListRentChargesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentCharges = (
	variables: ListRentChargesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentChargeDetailSchema,
		ListRentChargesError,
		undefined,
		{},
		ListRentChargesQueryParams,
		{}
	>({ url: '/api/rent-charges/', method: 'get', ...variables, signal })

export const useListRentCharges = <TData = Schemas.PagedRentChargeDetailSchema>(
	variables: ListRentChargesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentChargeDetailSchema,
			ListRentChargesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentChargeDetailSchema,
		ListRentChargesError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-charges/',
			operationId: 'listRentCharges',
			variables
		}),
		({ signal }) =>
			fetchListRentCharges({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentChargePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentChargeError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentChargeVariables = {
	pathParams: DeleteRentChargePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentCharge = (
	variables: DeleteRentChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentChargeError,
		undefined,
		{},
		{},
		DeleteRentChargePathParams
	>({ url: '/api/rent-charges/{id}/', method: 'delete', ...variables, signal })

export const useDeleteRentCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentChargeError,
			DeleteRentChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentChargeError,
		DeleteRentChargeVariables
	>(
		(variables: DeleteRentChargeVariables) =>
			fetchDeleteRentCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentChargeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentChargeDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentChargeDetailsVariables = {
	pathParams: GetRentChargeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentChargeDetails = (
	variables: GetRentChargeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentChargeDetailSchema,
		GetRentChargeDetailsError,
		undefined,
		{},
		{},
		GetRentChargeDetailsPathParams
	>({ url: '/api/rent-charges/{id}/', method: 'get', ...variables, signal })

export const useGetRentChargeDetails = <TData = Schemas.RentChargeDetailSchema>(
	variables: GetRentChargeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentChargeDetailSchema,
			GetRentChargeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentChargeDetailSchema,
		GetRentChargeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-charges/{id}/',
			operationId: 'getRentChargeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentChargeDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentChargePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentChargeError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentChargeVariables = {
	body: Schemas.RentChargeSchema
	pathParams: UpdateRentChargePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentCharge = (
	variables: UpdateRentChargeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentChargeError,
		Schemas.RentChargeSchema,
		{},
		{},
		UpdateRentChargePathParams
	>({ url: '/api/rent-charges/{id}/', method: 'put', ...variables, signal })

export const useUpdateRentCharge = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentChargeError,
			UpdateRentChargeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentChargeError,
		UpdateRentChargeVariables
	>(
		(variables: UpdateRentChargeVariables) =>
			fetchUpdateRentCharge({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentIncreaseError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.CreateRentIncreaseErrorSchema
}>

export type CreateRentIncreaseVariables = {
	body: Schemas.RentIncreaseCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentIncrease = (
	variables: CreateRentIncreaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentIncreaseError,
		Schemas.RentIncreaseCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/rent-increases/', method: 'post', ...variables, signal })

export const useCreateRentIncrease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentIncreaseError,
			CreateRentIncreaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentIncreaseError,
		CreateRentIncreaseVariables
	>(
		(variables: CreateRentIncreaseVariables) =>
			fetchCreateRentIncrease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentIncreasesQueryParams = {
	lease_id?: string | null
	bulk_rent_increase_notice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentIncreasesError = Fetcher.ErrorWrapper<undefined>

export type ListRentIncreasesVariables = {
	queryParams?: ListRentIncreasesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentIncreases = (
	variables: ListRentIncreasesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentIncreaseSchema,
		ListRentIncreasesError,
		undefined,
		{},
		ListRentIncreasesQueryParams,
		{}
	>({ url: '/api/rent-increases/', method: 'get', ...variables, signal })

export const useListRentIncreases = <TData = Schemas.PagedRentIncreaseSchema>(
	variables: ListRentIncreasesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentIncreaseSchema,
			ListRentIncreasesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentIncreaseSchema,
		ListRentIncreasesError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-increases/',
			operationId: 'listRentIncreases',
			variables
		}),
		({ signal }) =>
			fetchListRentIncreases({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentIncreasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentIncreaseError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentIncreaseVariables = {
	pathParams: DeleteRentIncreasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentIncrease = (
	variables: DeleteRentIncreaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentIncreaseError,
		undefined,
		{},
		{},
		DeleteRentIncreasePathParams
	>({
		url: '/api/rent-increases/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentIncrease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentIncreaseError,
			DeleteRentIncreaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentIncreaseError,
		DeleteRentIncreaseVariables
	>(
		(variables: DeleteRentIncreaseVariables) =>
			fetchDeleteRentIncrease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentIncreaseDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentIncreaseDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentIncreaseDetailsVariables = {
	pathParams: GetRentIncreaseDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentIncreaseDetails = (
	variables: GetRentIncreaseDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentIncreaseSchema,
		GetRentIncreaseDetailsError,
		undefined,
		{},
		{},
		GetRentIncreaseDetailsPathParams
	>({ url: '/api/rent-increases/{id}/', method: 'get', ...variables, signal })

export const useGetRentIncreaseDetails = <TData = Schemas.RentIncreaseSchema>(
	variables: GetRentIncreaseDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentIncreaseSchema,
			GetRentIncreaseDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentIncreaseSchema,
		GetRentIncreaseDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-increases/{id}/',
			operationId: 'getRentIncreaseDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentIncreaseDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentIncreasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentIncreaseError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentIncreaseVariables = {
	body: Schemas.RentIncreaseSchema
	pathParams: UpdateRentIncreasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentIncrease = (
	variables: UpdateRentIncreaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentIncreaseError,
		Schemas.RentIncreaseSchema,
		{},
		{},
		UpdateRentIncreasePathParams
	>({ url: '/api/rent-increases/{id}/', method: 'put', ...variables, signal })

export const useUpdateRentIncrease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentIncreaseError,
			UpdateRentIncreaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentIncreaseError,
		UpdateRentIncreaseVariables
	>(
		(variables: UpdateRentIncreaseVariables) =>
			fetchUpdateRentIncrease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentPaymentScheduleError = Fetcher.ErrorWrapper<undefined>

export type CreateRentPaymentScheduleVariables = {
	body: Schemas.RentPaymentScheduleCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentPaymentSchedule = (
	variables: CreateRentPaymentScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentPaymentScheduleError,
		Schemas.RentPaymentScheduleCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/rent-payment-schedules/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentPaymentSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentPaymentScheduleError,
			CreateRentPaymentScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentPaymentScheduleError,
		CreateRentPaymentScheduleVariables
	>(
		(variables: CreateRentPaymentScheduleVariables) =>
			fetchCreateRentPaymentSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentPaymentSchedulesQueryParams = {
	lease_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentPaymentSchedulesError = Fetcher.ErrorWrapper<undefined>

export type ListRentPaymentSchedulesVariables = {
	queryParams?: ListRentPaymentSchedulesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentPaymentSchedules = (
	variables: ListRentPaymentSchedulesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentPaymentScheduleDetailSchema,
		ListRentPaymentSchedulesError,
		undefined,
		{},
		ListRentPaymentSchedulesQueryParams,
		{}
	>({
		url: '/api/rent-payment-schedules/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentPaymentSchedules = <
	TData = Schemas.PagedRentPaymentScheduleDetailSchema
>(
	variables: ListRentPaymentSchedulesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentPaymentScheduleDetailSchema,
			ListRentPaymentSchedulesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentPaymentScheduleDetailSchema,
		ListRentPaymentSchedulesError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-payment-schedules/',
			operationId: 'listRentPaymentSchedules',
			variables
		}),
		({ signal }) =>
			fetchListRentPaymentSchedules(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentPaymentSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentPaymentScheduleError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentPaymentScheduleVariables = {
	pathParams: DeleteRentPaymentSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentPaymentSchedule = (
	variables: DeleteRentPaymentScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentPaymentScheduleError,
		undefined,
		{},
		{},
		DeleteRentPaymentSchedulePathParams
	>({
		url: '/api/rent-payment-schedules/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentPaymentSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentPaymentScheduleError,
			DeleteRentPaymentScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentPaymentScheduleError,
		DeleteRentPaymentScheduleVariables
	>(
		(variables: DeleteRentPaymentScheduleVariables) =>
			fetchDeleteRentPaymentSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentPaymentScheduleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentPaymentScheduleDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentPaymentScheduleDetailsVariables = {
	pathParams: GetRentPaymentScheduleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentPaymentScheduleDetails = (
	variables: GetRentPaymentScheduleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentPaymentScheduleDetailSchema,
		GetRentPaymentScheduleDetailsError,
		undefined,
		{},
		{},
		GetRentPaymentScheduleDetailsPathParams
	>({
		url: '/api/rent-payment-schedules/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentPaymentScheduleDetails = <
	TData = Schemas.RentPaymentScheduleDetailSchema
>(
	variables: GetRentPaymentScheduleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentPaymentScheduleDetailSchema,
			GetRentPaymentScheduleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentPaymentScheduleDetailSchema,
		GetRentPaymentScheduleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-payment-schedules/{id}/',
			operationId: 'getRentPaymentScheduleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentPaymentScheduleDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentPaymentSchedulePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentPaymentScheduleError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentPaymentScheduleVariables = {
	body: Schemas.RentPaymentScheduleSchema
	pathParams: UpdateRentPaymentSchedulePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentPaymentSchedule = (
	variables: UpdateRentPaymentScheduleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentPaymentScheduleError,
		Schemas.RentPaymentScheduleSchema,
		{},
		{},
		UpdateRentPaymentSchedulePathParams
	>({
		url: '/api/rent-payment-schedules/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentPaymentSchedule = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentPaymentScheduleError,
			UpdateRentPaymentScheduleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentPaymentScheduleError,
		UpdateRentPaymentScheduleVariables
	>(
		(variables: UpdateRentPaymentScheduleVariables) =>
			fetchUpdateRentPaymentSchedule({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GeneratePadAgreementPdfPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GeneratePadAgreementPdfError = Fetcher.ErrorWrapper<undefined>

export type GeneratePadAgreementPdfVariables = {
	pathParams: GeneratePadAgreementPdfPathParams
} & RentalizeContext['fetcherOptions']

/**
 * An endpoint use to generate a Pre-Authorized Debit agreement (as a PDF) for a rent payment schedule.
 */
export const fetchGeneratePadAgreementPdf = (
	variables: GeneratePadAgreementPdfVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GeneratePadAgreementPdfError,
		undefined,
		{},
		{},
		GeneratePadAgreementPdfPathParams
	>({
		url: '/api/rent-payment-schedules/{id}/generate-pad-agreement/',
		method: 'post',
		...variables,
		signal
	})

/**
 * An endpoint use to generate a Pre-Authorized Debit agreement (as a PDF) for a rent payment schedule.
 */
export const useGeneratePadAgreementPdf = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GeneratePadAgreementPdfError,
			GeneratePadAgreementPdfVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GeneratePadAgreementPdfError,
		GeneratePadAgreementPdfVariables
	>(
		(variables: GeneratePadAgreementPdfVariables) =>
			fetchGeneratePadAgreementPdf({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentPaymentError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateRentPaymentVariables = {
	body: Schemas.RentPaymentInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentPayment = (
	variables: CreateRentPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentPaymentError,
		Schemas.RentPaymentInSchema,
		{},
		{},
		{}
	>({ url: '/api/rent-payments/', method: 'post', ...variables, signal })

export const useCreateRentPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentPaymentError,
			CreateRentPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentPaymentError,
		CreateRentPaymentVariables
	>(
		(variables: CreateRentPaymentVariables) =>
			fetchCreateRentPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentPaymentsQueryParams = {
	lease_id?: string | null
	rent_receivable_id?: string | null
	property_owner_id?: string | null
	building_id?: string | null
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentPaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListRentPaymentsVariables = {
	queryParams?: ListRentPaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentPayments = (
	variables: ListRentPaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentPaymentOutSchema,
		ListRentPaymentsError,
		undefined,
		{},
		ListRentPaymentsQueryParams,
		{}
	>({ url: '/api/rent-payments/', method: 'get', ...variables, signal })

export const useListRentPayments = <TData = Schemas.PagedRentPaymentOutSchema>(
	variables: ListRentPaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentPaymentOutSchema,
			ListRentPaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentPaymentOutSchema,
		ListRentPaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-payments/',
			operationId: 'listRentPayments',
			variables
		}),
		({ signal }) =>
			fetchListRentPayments({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentPaymentError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentPaymentVariables = {
	pathParams: DeleteRentPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentPayment = (
	variables: DeleteRentPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentPaymentError,
		undefined,
		{},
		{},
		DeleteRentPaymentPathParams
	>({
		url: '/api/rent-payments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentPaymentError,
			DeleteRentPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentPaymentError,
		DeleteRentPaymentVariables
	>(
		(variables: DeleteRentPaymentVariables) =>
			fetchDeleteRentPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentPaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentPaymentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentPaymentDetailsVariables = {
	pathParams: GetRentPaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentPaymentDetails = (
	variables: GetRentPaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentPaymentOutSchema,
		GetRentPaymentDetailsError,
		undefined,
		{},
		{},
		GetRentPaymentDetailsPathParams
	>({ url: '/api/rent-payments/{id}/', method: 'get', ...variables, signal })

export const useGetRentPaymentDetails = <TData = Schemas.RentPaymentOutSchema>(
	variables: GetRentPaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentPaymentOutSchema,
			GetRentPaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentPaymentOutSchema,
		GetRentPaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-payments/{id}/',
			operationId: 'getRentPaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentPaymentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentPaymentError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentPaymentVariables = {
	body: Schemas.RentPaymentInSchema
	pathParams: UpdateRentPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentPayment = (
	variables: UpdateRentPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentPaymentError,
		Schemas.RentPaymentInSchema,
		{},
		{},
		UpdateRentPaymentPathParams
	>({ url: '/api/rent-payments/{id}/', method: 'put', ...variables, signal })

export const useUpdateRentPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentPaymentError,
			UpdateRentPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentPaymentError,
		UpdateRentPaymentVariables
	>(
		(variables: UpdateRentPaymentVariables) =>
			fetchUpdateRentPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type BulkMarkRentReceivablesAsPaidError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: Record<string, any>
	  }
	| {
			status: 404
			payload: Record<string, any>
	  }
>

export type BulkMarkRentReceivablesAsPaidVariables = {
	body: Schemas.BulkMarkRentReceivableAsPaidSchema
} & RentalizeContext['fetcherOptions']

export const fetchBulkMarkRentReceivablesAsPaid = (
	variables: BulkMarkRentReceivablesAsPaidVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		BulkMarkRentReceivablesAsPaidError,
		Schemas.BulkMarkRentReceivableAsPaidSchema,
		{},
		{},
		{}
	>({
		url: '/api/rent-receivables/bulk-mark-as-paid',
		method: 'post',
		...variables,
		signal
	})

export const useBulkMarkRentReceivablesAsPaid = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			BulkMarkRentReceivablesAsPaidError,
			BulkMarkRentReceivablesAsPaidVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		BulkMarkRentReceivablesAsPaidError,
		BulkMarkRentReceivablesAsPaidVariables
	>(
		(variables: BulkMarkRentReceivablesAsPaidVariables) =>
			fetchBulkMarkRentReceivablesAsPaid({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentReceivableError = Fetcher.ErrorWrapper<undefined>

export type CreateRentReceivableVariables = {
	body: Schemas.RentReceivableSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentReceivable = (
	variables: CreateRentReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentReceivableError,
		Schemas.RentReceivableSchema,
		{},
		{},
		{}
	>({ url: '/api/rent-receivables/', method: 'post', ...variables, signal })

export const useCreateRentReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentReceivableError,
			CreateRentReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentReceivableError,
		CreateRentReceivableVariables
	>(
		(variables: CreateRentReceivableVariables) =>
			fetchCreateRentReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentReceivablesQueryParams = {
	property_owner_id?: string | null
	building_id?: string | null
	rental_unit_id?: string | null
	lease_id?: string | null
	is_outstanding?: boolean | null
	order_by?: 'BUILDING_ADDRESS' | null
	limit?: number | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentReceivablesError = Fetcher.ErrorWrapper<undefined>

export type ListRentReceivablesVariables = {
	queryParams?: ListRentReceivablesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentReceivables = (
	variables: ListRentReceivablesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentReceivableDetailSchema,
		ListRentReceivablesError,
		undefined,
		{},
		ListRentReceivablesQueryParams,
		{}
	>({ url: '/api/rent-receivables/', method: 'get', ...variables, signal })

export const useListRentReceivables = <
	TData = Schemas.PagedRentReceivableDetailSchema
>(
	variables: ListRentReceivablesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentReceivableDetailSchema,
			ListRentReceivablesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentReceivableDetailSchema,
		ListRentReceivablesError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-receivables/',
			operationId: 'listRentReceivables',
			variables
		}),
		({ signal }) =>
			fetchListRentReceivables({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentReceivablePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentReceivableError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentReceivableVariables = {
	pathParams: DeleteRentReceivablePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentReceivable = (
	variables: DeleteRentReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentReceivableError,
		undefined,
		{},
		{},
		DeleteRentReceivablePathParams
	>({
		url: '/api/rent-receivables/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentReceivableError,
			DeleteRentReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentReceivableError,
		DeleteRentReceivableVariables
	>(
		(variables: DeleteRentReceivableVariables) =>
			fetchDeleteRentReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentReceivableDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentReceivableDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentReceivableDetailsVariables = {
	pathParams: GetRentReceivableDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentReceivableDetails = (
	variables: GetRentReceivableDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentReceivableDetailSchema,
		GetRentReceivableDetailsError,
		undefined,
		{},
		{},
		GetRentReceivableDetailsPathParams
	>({
		url: '/api/rent-receivables/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentReceivableDetails = <
	TData = Schemas.RentReceivableDetailSchema
>(
	variables: GetRentReceivableDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentReceivableDetailSchema,
			GetRentReceivableDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentReceivableDetailSchema,
		GetRentReceivableDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rent-receivables/{id}/',
			operationId: 'getRentReceivableDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentReceivableDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentReceivablePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentReceivableError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentReceivableVariables = {
	body: Schemas.RentReceivableSchema
	pathParams: UpdateRentReceivablePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentReceivable = (
	variables: UpdateRentReceivableVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentReceivableError,
		Schemas.RentReceivableSchema,
		{},
		{},
		UpdateRentReceivablePathParams
	>({
		url: '/api/rent-receivables/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentReceivable = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentReceivableError,
			UpdateRentReceivableVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentReceivableError,
		UpdateRentReceivableVariables
	>(
		(variables: UpdateRentReceivableVariables) =>
			fetchUpdateRentReceivable({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentalApplicationFormError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateRentalApplicationFormVariables = {
	body: Schemas.RentalApplicationCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalApplicationForm = (
	variables: CreateRentalApplicationFormVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalApplicationFormError,
		Schemas.RentalApplicationCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/rental-application-forms/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentalApplicationForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalApplicationFormError,
			CreateRentalApplicationFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalApplicationFormError,
		CreateRentalApplicationFormVariables
	>(
		(variables: CreateRentalApplicationFormVariables) =>
			fetchCreateRentalApplicationForm({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalApplicationFormsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalApplicationFormsError = Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationFormsVariables = {
	queryParams?: ListRentalApplicationFormsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalApplicationForms = (
	variables: ListRentalApplicationFormsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalApplicationFormSchema,
		ListRentalApplicationFormsError,
		undefined,
		{},
		ListRentalApplicationFormsQueryParams,
		{}
	>({
		url: '/api/rental-application-forms/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalApplicationForms = <
	TData = Schemas.PagedRentalApplicationFormSchema
>(
	variables: ListRentalApplicationFormsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalApplicationFormSchema,
			ListRentalApplicationFormsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalApplicationFormSchema,
		ListRentalApplicationFormsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-forms/',
			operationId: 'listRentalApplicationForms',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationForms(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentalApplicationFormPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalApplicationFormError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentalApplicationFormVariables = {
	pathParams: DeleteRentalApplicationFormPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalApplicationForm = (
	variables: DeleteRentalApplicationFormVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalApplicationFormError,
		undefined,
		{},
		{},
		DeleteRentalApplicationFormPathParams
	>({
		url: '/api/rental-application-forms/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentalApplicationForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalApplicationFormError,
			DeleteRentalApplicationFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalApplicationFormError,
		DeleteRentalApplicationFormVariables
	>(
		(variables: DeleteRentalApplicationFormVariables) =>
			fetchDeleteRentalApplicationForm({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentalApplicationFormDetailsPathParams = {
	id: string
}

export type GetRentalApplicationFormDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRentalApplicationFormDetailsVariables = {
	pathParams: GetRentalApplicationFormDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalApplicationFormDetails = (
	variables: GetRentalApplicationFormDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalApplicationDetailSchema,
		GetRentalApplicationFormDetailsError,
		undefined,
		{},
		{},
		GetRentalApplicationFormDetailsPathParams
	>({
		url: '/api/rental-application-forms/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalApplicationFormDetails = <
	TData = Schemas.RentalApplicationDetailSchema
>(
	variables: GetRentalApplicationFormDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalApplicationDetailSchema,
			GetRentalApplicationFormDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalApplicationDetailSchema,
		GetRentalApplicationFormDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-forms/{id}/',
			operationId: 'getRentalApplicationFormDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalApplicationFormDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalApplicationFormPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalApplicationFormError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentalApplicationFormVariables = {
	body: Schemas.RentalApplicationFormSchema
	pathParams: UpdateRentalApplicationFormPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalApplicationForm = (
	variables: UpdateRentalApplicationFormVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalApplicationFormError,
		Schemas.RentalApplicationFormSchema,
		{},
		{},
		UpdateRentalApplicationFormPathParams
	>({
		url: '/api/rental-application-forms/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentalApplicationForm = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalApplicationFormError,
			UpdateRentalApplicationFormVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalApplicationFormError,
		UpdateRentalApplicationFormVariables
	>(
		(variables: UpdateRentalApplicationFormVariables) =>
			fetchUpdateRentalApplicationForm({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalApplicationFormListingsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ListRentalApplicationFormListingsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalApplicationFormListingsError =
	Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationFormListingsVariables = {
	pathParams: ListRentalApplicationFormListingsPathParams
	queryParams?: ListRentalApplicationFormListingsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalApplicationFormListings = (
	variables: ListRentalApplicationFormListingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalUnitListingDetailSchema,
		ListRentalApplicationFormListingsError,
		undefined,
		{},
		ListRentalApplicationFormListingsQueryParams,
		ListRentalApplicationFormListingsPathParams
	>({
		url: '/api/rental-application-form-listings/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalApplicationFormListings = <
	TData = Schemas.PagedRentalUnitListingDetailSchema
>(
	variables: ListRentalApplicationFormListingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalUnitListingDetailSchema,
			ListRentalApplicationFormListingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalUnitListingDetailSchema,
		ListRentalApplicationFormListingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-form-listings/{id}/',
			operationId: 'listRentalApplicationFormListings',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationFormListings(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateRentalApplicationQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type CreateRentalApplicationQuestionAnswerVariables = {
	body: Schemas.RentalApplicationQuestionAnswerSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalApplicationQuestionAnswer = (
	variables: CreateRentalApplicationQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalApplicationQuestionAnswerError,
		Schemas.RentalApplicationQuestionAnswerSchema,
		{},
		{},
		{}
	>({
		url: '/api/rental-application-question-answers/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentalApplicationQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalApplicationQuestionAnswerError,
			CreateRentalApplicationQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalApplicationQuestionAnswerError,
		CreateRentalApplicationQuestionAnswerVariables
	>(
		(variables: CreateRentalApplicationQuestionAnswerVariables) =>
			fetchCreateRentalApplicationQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListRentalApplicationQuestionAnswersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalApplicationQuestionAnswersError =
	Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationQuestionAnswersVariables = {
	queryParams?: ListRentalApplicationQuestionAnswersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalApplicationQuestionAnswers = (
	variables: ListRentalApplicationQuestionAnswersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalApplicationQuestionAnswerSchema,
		ListRentalApplicationQuestionAnswersError,
		undefined,
		{},
		ListRentalApplicationQuestionAnswersQueryParams,
		{}
	>({
		url: '/api/rental-application-question-answers/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalApplicationQuestionAnswers = <
	TData = Schemas.PagedRentalApplicationQuestionAnswerSchema
>(
	variables: ListRentalApplicationQuestionAnswersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalApplicationQuestionAnswerSchema,
			ListRentalApplicationQuestionAnswersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalApplicationQuestionAnswerSchema,
		ListRentalApplicationQuestionAnswersError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-question-answers/',
			operationId: 'listRentalApplicationQuestionAnswers',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationQuestionAnswers(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentalApplicationQuestionAnswerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalApplicationQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteRentalApplicationQuestionAnswerVariables = {
	pathParams: DeleteRentalApplicationQuestionAnswerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalApplicationQuestionAnswer = (
	variables: DeleteRentalApplicationQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalApplicationQuestionAnswerError,
		undefined,
		{},
		{},
		DeleteRentalApplicationQuestionAnswerPathParams
	>({
		url: '/api/rental-application-question-answers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentalApplicationQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalApplicationQuestionAnswerError,
			DeleteRentalApplicationQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalApplicationQuestionAnswerError,
		DeleteRentalApplicationQuestionAnswerVariables
	>(
		(variables: DeleteRentalApplicationQuestionAnswerVariables) =>
			fetchDeleteRentalApplicationQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetRentalApplicationQuestionAnswerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalApplicationQuestionAnswerDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRentalApplicationQuestionAnswerDetailsVariables = {
	pathParams: GetRentalApplicationQuestionAnswerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalApplicationQuestionAnswerDetails = (
	variables: GetRentalApplicationQuestionAnswerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalApplicationQuestionAnswerSchema,
		GetRentalApplicationQuestionAnswerDetailsError,
		undefined,
		{},
		{},
		GetRentalApplicationQuestionAnswerDetailsPathParams
	>({
		url: '/api/rental-application-question-answers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalApplicationQuestionAnswerDetails = <
	TData = Schemas.RentalApplicationQuestionAnswerSchema
>(
	variables: GetRentalApplicationQuestionAnswerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalApplicationQuestionAnswerSchema,
			GetRentalApplicationQuestionAnswerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalApplicationQuestionAnswerSchema,
		GetRentalApplicationQuestionAnswerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-question-answers/{id}/',
			operationId: 'getRentalApplicationQuestionAnswerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalApplicationQuestionAnswerDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalApplicationQuestionAnswerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalApplicationQuestionAnswerError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateRentalApplicationQuestionAnswerVariables = {
	body: Schemas.RentalApplicationQuestionAnswerSchema
	pathParams: UpdateRentalApplicationQuestionAnswerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalApplicationQuestionAnswer = (
	variables: UpdateRentalApplicationQuestionAnswerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalApplicationQuestionAnswerError,
		Schemas.RentalApplicationQuestionAnswerSchema,
		{},
		{},
		UpdateRentalApplicationQuestionAnswerPathParams
	>({
		url: '/api/rental-application-question-answers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentalApplicationQuestionAnswer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalApplicationQuestionAnswerError,
			UpdateRentalApplicationQuestionAnswerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalApplicationQuestionAnswerError,
		UpdateRentalApplicationQuestionAnswerVariables
	>(
		(variables: UpdateRentalApplicationQuestionAnswerVariables) =>
			fetchUpdateRentalApplicationQuestionAnswer({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type CreateRentalApplicationQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type CreateRentalApplicationQuestionVariables = {
	body: Schemas.RentalApplicationQuestionCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalApplicationQuestion = (
	variables: CreateRentalApplicationQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalApplicationQuestionError,
		Schemas.RentalApplicationQuestionCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/rental-application-questions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentalApplicationQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalApplicationQuestionError,
			CreateRentalApplicationQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalApplicationQuestionError,
		CreateRentalApplicationQuestionVariables
	>(
		(variables: CreateRentalApplicationQuestionVariables) =>
			fetchCreateRentalApplicationQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalApplicationQuestionsQueryParams = {
	rental_application_form_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalApplicationQuestionsError =
	Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationQuestionsVariables = {
	queryParams?: ListRentalApplicationQuestionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalApplicationQuestions = (
	variables: ListRentalApplicationQuestionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalApplicationQuestionSchema,
		ListRentalApplicationQuestionsError,
		undefined,
		{},
		ListRentalApplicationQuestionsQueryParams,
		{}
	>({
		url: '/api/rental-application-questions/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalApplicationQuestions = <
	TData = Schemas.PagedRentalApplicationQuestionSchema
>(
	variables: ListRentalApplicationQuestionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalApplicationQuestionSchema,
			ListRentalApplicationQuestionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalApplicationQuestionSchema,
		ListRentalApplicationQuestionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-questions/',
			operationId: 'listRentalApplicationQuestions',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationQuestions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentalApplicationQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalApplicationQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteRentalApplicationQuestionVariables = {
	pathParams: DeleteRentalApplicationQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalApplicationQuestion = (
	variables: DeleteRentalApplicationQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalApplicationQuestionError,
		undefined,
		{},
		{},
		DeleteRentalApplicationQuestionPathParams
	>({
		url: '/api/rental-application-questions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentalApplicationQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalApplicationQuestionError,
			DeleteRentalApplicationQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalApplicationQuestionError,
		DeleteRentalApplicationQuestionVariables
	>(
		(variables: DeleteRentalApplicationQuestionVariables) =>
			fetchDeleteRentalApplicationQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentalApplicationQuestionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalApplicationQuestionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRentalApplicationQuestionDetailsVariables = {
	pathParams: GetRentalApplicationQuestionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalApplicationQuestionDetails = (
	variables: GetRentalApplicationQuestionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalApplicationQuestionSchema,
		GetRentalApplicationQuestionDetailsError,
		undefined,
		{},
		{},
		GetRentalApplicationQuestionDetailsPathParams
	>({
		url: '/api/rental-application-questions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalApplicationQuestionDetails = <
	TData = Schemas.RentalApplicationQuestionSchema
>(
	variables: GetRentalApplicationQuestionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalApplicationQuestionSchema,
			GetRentalApplicationQuestionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalApplicationQuestionSchema,
		GetRentalApplicationQuestionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-questions/{id}/',
			operationId: 'getRentalApplicationQuestionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalApplicationQuestionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalApplicationQuestionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalApplicationQuestionError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateRentalApplicationQuestionVariables = {
	body: Schemas.RentalApplicationQuestionSchema
	pathParams: UpdateRentalApplicationQuestionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalApplicationQuestion = (
	variables: UpdateRentalApplicationQuestionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalApplicationQuestionError,
		Schemas.RentalApplicationQuestionSchema,
		{},
		{},
		UpdateRentalApplicationQuestionPathParams
	>({
		url: '/api/rental-application-questions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentalApplicationQuestion = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalApplicationQuestionError,
			UpdateRentalApplicationQuestionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalApplicationQuestionError,
		UpdateRentalApplicationQuestionVariables
	>(
		(variables: UpdateRentalApplicationQuestionVariables) =>
			fetchUpdateRentalApplicationQuestion({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalApplicationQuestionChoicesError =
	Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationQuestionChoicesResponse =
	Schemas.RentalApplicationQuestionChoicesSchema[]

export type ListRentalApplicationQuestionChoicesVariables =
	RentalizeContext['fetcherOptions']

/**
 * An API to list possible types of questions on a RentalApplication form
 * Populated from RentalApplicationQuestion.TYPE_FIELD
 */
export const fetchListRentalApplicationQuestionChoices = (
	variables: ListRentalApplicationQuestionChoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		ListRentalApplicationQuestionChoicesResponse,
		ListRentalApplicationQuestionChoicesError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/rental-application-question-choices/',
		method: 'get',
		...variables,
		signal
	})

/**
 * An API to list possible types of questions on a RentalApplication form
 * Populated from RentalApplicationQuestion.TYPE_FIELD
 */
export const useListRentalApplicationQuestionChoices = <
	TData = ListRentalApplicationQuestionChoicesResponse
>(
	variables: ListRentalApplicationQuestionChoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			ListRentalApplicationQuestionChoicesResponse,
			ListRentalApplicationQuestionChoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		ListRentalApplicationQuestionChoicesResponse,
		ListRentalApplicationQuestionChoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-question-choices/',
			operationId: 'listRentalApplicationQuestionChoices',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationQuestionChoices(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type AddResidentToRentalApplicationSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type AddResidentToRentalApplicationSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type AddResidentToRentalApplicationSubmissionVariables = {
	body: Schemas.AddResidentToRentalApplicationSubmissionSchema
	pathParams: AddResidentToRentalApplicationSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchAddResidentToRentalApplicationSubmission = (
	variables: AddResidentToRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		AddResidentToRentalApplicationSubmissionError,
		Schemas.AddResidentToRentalApplicationSubmissionSchema,
		{},
		{},
		AddResidentToRentalApplicationSubmissionPathParams
	>({
		url: '/api/rental-application-submissions/{id}/add-applicant/',
		method: 'post',
		...variables,
		signal
	})

export const useAddResidentToRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			AddResidentToRentalApplicationSubmissionError,
			AddResidentToRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		AddResidentToRentalApplicationSubmissionError,
		AddResidentToRentalApplicationSubmissionVariables
	>(
		(variables: AddResidentToRentalApplicationSubmissionVariables) =>
			fetchAddResidentToRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ApproveRentalApplicationSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type ApproveRentalApplicationSubmissionError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type ApproveRentalApplicationSubmissionVariables = {
	pathParams: ApproveRentalApplicationSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchApproveRentalApplicationSubmission = (
	variables: ApproveRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		ApproveRentalApplicationSubmissionError,
		undefined,
		{},
		{},
		ApproveRentalApplicationSubmissionPathParams
	>({
		url: '/api/rental-application-submissions/{id}/approve/',
		method: 'post',
		...variables,
		signal
	})

export const useApproveRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			ApproveRentalApplicationSubmissionError,
			ApproveRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		ApproveRentalApplicationSubmissionError,
		ApproveRentalApplicationSubmissionVariables
	>(
		(variables: ApproveRentalApplicationSubmissionVariables) =>
			fetchApproveRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type CreateRentalApplicationSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type CreateRentalApplicationSubmissionVariables = {
	body: Schemas.RentalApplicationSubmissionCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalApplicationSubmission = (
	variables: CreateRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalApplicationSubmissionError,
		Schemas.RentalApplicationSubmissionCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/rental-application-submissions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalApplicationSubmissionError,
			CreateRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalApplicationSubmissionError,
		CreateRentalApplicationSubmissionVariables
	>(
		(variables: CreateRentalApplicationSubmissionVariables) =>
			fetchCreateRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListRentalApplicationSubmissionsQueryParams = {
	/**
	 * @default NEW
	 */
	status?: 'NEW' | 'DECLINED' | 'APPROVED' | 'ARCHIVED' | 'ALL'
	rental_unit_listing_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalApplicationSubmissionsError =
	Fetcher.ErrorWrapper<undefined>

export type ListRentalApplicationSubmissionsVariables = {
	queryParams?: ListRentalApplicationSubmissionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalApplicationSubmissions = (
	variables: ListRentalApplicationSubmissionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalApplicationSubmissionDetailSchema,
		ListRentalApplicationSubmissionsError,
		undefined,
		{},
		ListRentalApplicationSubmissionsQueryParams,
		{}
	>({
		url: '/api/rental-application-submissions/',
		method: 'get',
		...variables,
		signal
	})

export const useListRentalApplicationSubmissions = <
	TData = Schemas.PagedRentalApplicationSubmissionDetailSchema
>(
	variables: ListRentalApplicationSubmissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalApplicationSubmissionDetailSchema,
			ListRentalApplicationSubmissionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalApplicationSubmissionDetailSchema,
		ListRentalApplicationSubmissionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-submissions/',
			operationId: 'listRentalApplicationSubmissions',
			variables
		}),
		({ signal }) =>
			fetchListRentalApplicationSubmissions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeclineRentalApplicationSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeclineRentalApplicationSubmissionQueryParams = {
	/**
	 * @default false
	 */
	auto_generate_pdf?: boolean
}

export type DeclineRentalApplicationSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type DeclineRentalApplicationSubmissionVariables = {
	pathParams: DeclineRentalApplicationSubmissionPathParams
	queryParams?: DeclineRentalApplicationSubmissionQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchDeclineRentalApplicationSubmission = (
	variables: DeclineRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		DeclineRentalApplicationSubmissionError,
		undefined,
		{},
		DeclineRentalApplicationSubmissionQueryParams,
		DeclineRentalApplicationSubmissionPathParams
	>({
		url: '/api/rental-application-submissions/{id}/decline/',
		method: 'post',
		...variables,
		signal
	})

export const useDeclineRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			DeclineRentalApplicationSubmissionError,
			DeclineRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		DeclineRentalApplicationSubmissionError,
		DeclineRentalApplicationSubmissionVariables
	>(
		(variables: DeclineRentalApplicationSubmissionVariables) =>
			fetchDeclineRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type DeleteRentalApplicationSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalApplicationSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteRentalApplicationSubmissionVariables = {
	pathParams: DeleteRentalApplicationSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalApplicationSubmission = (
	variables: DeleteRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalApplicationSubmissionError,
		undefined,
		{},
		{},
		DeleteRentalApplicationSubmissionPathParams
	>({
		url: '/api/rental-application-submissions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalApplicationSubmissionError,
			DeleteRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalApplicationSubmissionError,
		DeleteRentalApplicationSubmissionVariables
	>(
		(variables: DeleteRentalApplicationSubmissionVariables) =>
			fetchDeleteRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetRentalApplicationSubmissionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalApplicationSubmissionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetRentalApplicationSubmissionDetailsVariables = {
	pathParams: GetRentalApplicationSubmissionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalApplicationSubmissionDetails = (
	variables: GetRentalApplicationSubmissionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalApplicationSubmissionDetailSchema,
		GetRentalApplicationSubmissionDetailsError,
		undefined,
		{},
		{},
		GetRentalApplicationSubmissionDetailsPathParams
	>({
		url: '/api/rental-application-submissions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalApplicationSubmissionDetails = <
	TData = Schemas.RentalApplicationSubmissionDetailSchema
>(
	variables: GetRentalApplicationSubmissionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalApplicationSubmissionDetailSchema,
			GetRentalApplicationSubmissionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalApplicationSubmissionDetailSchema,
		GetRentalApplicationSubmissionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-application-submissions/{id}/',
			operationId: 'getRentalApplicationSubmissionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalApplicationSubmissionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalApplicationSubmissionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalApplicationSubmissionError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateRentalApplicationSubmissionVariables = {
	body: Schemas.RentalApplicationSubmissionUpdateSchema
	pathParams: UpdateRentalApplicationSubmissionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalApplicationSubmission = (
	variables: UpdateRentalApplicationSubmissionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalApplicationSubmissionError,
		Schemas.RentalApplicationSubmissionUpdateSchema,
		{},
		{},
		UpdateRentalApplicationSubmissionPathParams
	>({
		url: '/api/rental-application-submissions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateRentalApplicationSubmission = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalApplicationSubmissionError,
			UpdateRentalApplicationSubmissionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalApplicationSubmissionError,
		UpdateRentalApplicationSubmissionVariables
	>(
		(variables: UpdateRentalApplicationSubmissionVariables) =>
			fetchUpdateRentalApplicationSubmission({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type CreateWaitlistSubscriberError = Fetcher.ErrorWrapper<undefined>

export type CreateWaitlistSubscriberVariables = {
	body: Schemas.WaitlistSubscriberSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateWaitlistSubscriber = (
	variables: CreateWaitlistSubscriberVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateWaitlistSubscriberError,
		Schemas.WaitlistSubscriberSchema,
		{},
		{},
		{}
	>({
		url: '/api/waitlist-subscribers/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateWaitlistSubscriber = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateWaitlistSubscriberError,
			CreateWaitlistSubscriberVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateWaitlistSubscriberError,
		CreateWaitlistSubscriberVariables
	>(
		(variables: CreateWaitlistSubscriberVariables) =>
			fetchCreateWaitlistSubscriber({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListWaitlistSubscribersQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWaitlistSubscribersError = Fetcher.ErrorWrapper<undefined>

export type ListWaitlistSubscribersVariables = {
	queryParams?: ListWaitlistSubscribersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWaitlistSubscribers = (
	variables: ListWaitlistSubscribersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWaitlistSubscriberSchema,
		ListWaitlistSubscribersError,
		undefined,
		{},
		ListWaitlistSubscribersQueryParams,
		{}
	>({ url: '/api/waitlist-subscribers/', method: 'get', ...variables, signal })

export const useListWaitlistSubscribers = <
	TData = Schemas.PagedWaitlistSubscriberSchema
>(
	variables: ListWaitlistSubscribersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWaitlistSubscriberSchema,
			ListWaitlistSubscribersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWaitlistSubscriberSchema,
		ListWaitlistSubscribersError,
		TData
	>(
		queryKeyFn({
			path: '/api/waitlist-subscribers/',
			operationId: 'listWaitlistSubscribers',
			variables
		}),
		({ signal }) =>
			fetchListWaitlistSubscribers({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteWaitlistSubscriberPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteWaitlistSubscriberError = Fetcher.ErrorWrapper<undefined>

export type DeleteWaitlistSubscriberVariables = {
	pathParams: DeleteWaitlistSubscriberPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteWaitlistSubscriber = (
	variables: DeleteWaitlistSubscriberVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteWaitlistSubscriberError,
		undefined,
		{},
		{},
		DeleteWaitlistSubscriberPathParams
	>({
		url: '/api/waitlist-subscribers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteWaitlistSubscriber = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteWaitlistSubscriberError,
			DeleteWaitlistSubscriberVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteWaitlistSubscriberError,
		DeleteWaitlistSubscriberVariables
	>(
		(variables: DeleteWaitlistSubscriberVariables) =>
			fetchDeleteWaitlistSubscriber({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetWaitlistSubscriberDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetWaitlistSubscriberDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetWaitlistSubscriberDetailsVariables = {
	pathParams: GetWaitlistSubscriberDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetWaitlistSubscriberDetails = (
	variables: GetWaitlistSubscriberDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.WaitlistSubscriberSchema,
		GetWaitlistSubscriberDetailsError,
		undefined,
		{},
		{},
		GetWaitlistSubscriberDetailsPathParams
	>({
		url: '/api/waitlist-subscribers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetWaitlistSubscriberDetails = <
	TData = Schemas.WaitlistSubscriberSchema
>(
	variables: GetWaitlistSubscriberDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.WaitlistSubscriberSchema,
			GetWaitlistSubscriberDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.WaitlistSubscriberSchema,
		GetWaitlistSubscriberDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/waitlist-subscribers/{id}/',
			operationId: 'getWaitlistSubscriberDetails',
			variables
		}),
		({ signal }) =>
			fetchGetWaitlistSubscriberDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateWaitlistSubscriberPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateWaitlistSubscriberError = Fetcher.ErrorWrapper<undefined>

export type UpdateWaitlistSubscriberVariables = {
	body: Schemas.WaitlistSubscriberSchema
	pathParams: UpdateWaitlistSubscriberPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateWaitlistSubscriber = (
	variables: UpdateWaitlistSubscriberVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateWaitlistSubscriberError,
		Schemas.WaitlistSubscriberSchema,
		{},
		{},
		UpdateWaitlistSubscriberPathParams
	>({
		url: '/api/waitlist-subscribers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateWaitlistSubscriber = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateWaitlistSubscriberError,
			UpdateWaitlistSubscriberVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateWaitlistSubscriberError,
		UpdateWaitlistSubscriberVariables
	>(
		(variables: UpdateWaitlistSubscriberVariables) =>
			fetchUpdateWaitlistSubscriber({ ...fetcherOptions, ...variables }),
		options
	)
}

export type PublicCreateWaitlistSubscriberPathParams = {
	slug: string
}

export type PublicCreateWaitlistSubscriberError =
	Fetcher.ErrorWrapper<undefined>

export type PublicCreateWaitlistSubscriberVariables = {
	body: Schemas.WaitlistSubscriberSchema
	pathParams: PublicCreateWaitlistSubscriberPathParams
} & RentalizeContext['fetcherOptions']

export const fetchPublicCreateWaitlistSubscriber = (
	variables: PublicCreateWaitlistSubscriberVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		PublicCreateWaitlistSubscriberError,
		Schemas.WaitlistSubscriberSchema,
		{},
		{},
		PublicCreateWaitlistSubscriberPathParams
	>({
		url: '/api/{slug}/waitlist-subscribers/',
		method: 'post',
		...variables,
		signal
	})

export const usePublicCreateWaitlistSubscriber = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			PublicCreateWaitlistSubscriberError,
			PublicCreateWaitlistSubscriberVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		PublicCreateWaitlistSubscriberError,
		PublicCreateWaitlistSubscriberVariables
	>(
		(variables: PublicCreateWaitlistSubscriberVariables) =>
			fetchPublicCreateWaitlistSubscriber({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateRentalUnitError = Fetcher.ErrorWrapper<undefined>

export type CreateRentalUnitVariables = {
	body: Schemas.RentalUnitInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateRentalUnit = (
	variables: CreateRentalUnitVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateRentalUnitError,
		Schemas.RentalUnitInSchema,
		{},
		{},
		{}
	>({ url: '/api/rental-units/', method: 'post', ...variables, signal })

export const useCreateRentalUnit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateRentalUnitError,
			CreateRentalUnitVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateRentalUnitError,
		CreateRentalUnitVariables
	>(
		(variables: CreateRentalUnitVariables) =>
			fetchCreateRentalUnit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListRentalUnitsQueryParams = {
	building_id?: string | null
	property_management_fee_schedule_id?: string | null
	/**
	 * @default ALL
	 */
	unit_type?: 'ALL' | 'APARTMENT' | 'COMMERCIAL' | 'MHP' | 'STORAGE'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListRentalUnitsError = Fetcher.ErrorWrapper<undefined>

export type ListRentalUnitsVariables = {
	queryParams?: ListRentalUnitsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListRentalUnits = (
	variables: ListRentalUnitsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedRentalUnitOutSchema,
		ListRentalUnitsError,
		undefined,
		{},
		ListRentalUnitsQueryParams,
		{}
	>({ url: '/api/rental-units/', method: 'get', ...variables, signal })

export const useListRentalUnits = <TData = Schemas.PagedRentalUnitOutSchema>(
	variables: ListRentalUnitsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedRentalUnitOutSchema,
			ListRentalUnitsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedRentalUnitOutSchema,
		ListRentalUnitsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-units/',
			operationId: 'listRentalUnits',
			variables
		}),
		({ signal }) =>
			fetchListRentalUnits({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteRentalUnitPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteRentalUnitError = Fetcher.ErrorWrapper<undefined>

export type DeleteRentalUnitVariables = {
	pathParams: DeleteRentalUnitPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteRentalUnit = (
	variables: DeleteRentalUnitVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteRentalUnitError,
		undefined,
		{},
		{},
		DeleteRentalUnitPathParams
	>({ url: '/api/rental-units/{id}/', method: 'delete', ...variables, signal })

export const useDeleteRentalUnit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteRentalUnitError,
			DeleteRentalUnitVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteRentalUnitError,
		DeleteRentalUnitVariables
	>(
		(variables: DeleteRentalUnitVariables) =>
			fetchDeleteRentalUnit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentalUnitDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalUnitDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetRentalUnitDetailsVariables = {
	pathParams: GetRentalUnitDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalUnitDetails = (
	variables: GetRentalUnitDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.RentalUnitOutSchema,
		GetRentalUnitDetailsError,
		undefined,
		{},
		{},
		GetRentalUnitDetailsPathParams
	>({ url: '/api/rental-units/{id}/', method: 'get', ...variables, signal })

export const useGetRentalUnitDetails = <TData = Schemas.RentalUnitOutSchema>(
	variables: GetRentalUnitDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.RentalUnitOutSchema,
			GetRentalUnitDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.RentalUnitOutSchema,
		GetRentalUnitDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-units/{id}/',
			operationId: 'getRentalUnitDetails',
			variables
		}),
		({ signal }) =>
			fetchGetRentalUnitDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateRentalUnitPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateRentalUnitError = Fetcher.ErrorWrapper<undefined>

export type UpdateRentalUnitVariables = {
	body: Schemas.RentalUnitInSchema
	pathParams: UpdateRentalUnitPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateRentalUnit = (
	variables: UpdateRentalUnitVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateRentalUnitError,
		Schemas.RentalUnitInSchema,
		{},
		{},
		UpdateRentalUnitPathParams
	>({ url: '/api/rental-units/{id}/', method: 'put', ...variables, signal })

export const useUpdateRentalUnit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateRentalUnitError,
			UpdateRentalUnitVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateRentalUnitError,
		UpdateRentalUnitVariables
	>(
		(variables: UpdateRentalUnitVariables) =>
			fetchUpdateRentalUnit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetRentalUnitCurrentLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetRentalUnitCurrentLeaseError = Fetcher.ErrorWrapper<undefined>

export type GetRentalUnitCurrentLeaseVariables = {
	pathParams: GetRentalUnitCurrentLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetRentalUnitCurrentLease = (
	variables: GetRentalUnitCurrentLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.LeaseDetailSchema | Schemas.ErrorSchema,
		GetRentalUnitCurrentLeaseError,
		undefined,
		{},
		{},
		GetRentalUnitCurrentLeasePathParams
	>({
		url: '/api/rental-units/{id}/current-lease/',
		method: 'get',
		...variables,
		signal
	})

export const useGetRentalUnitCurrentLease = <
	TData = Schemas.LeaseDetailSchema | Schemas.ErrorSchema
>(
	variables: GetRentalUnitCurrentLeaseVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.LeaseDetailSchema | Schemas.ErrorSchema,
			GetRentalUnitCurrentLeaseError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.LeaseDetailSchema | Schemas.ErrorSchema,
		GetRentalUnitCurrentLeaseError,
		TData
	>(
		queryKeyFn({
			path: '/api/rental-units/{id}/current-lease/',
			operationId: 'getRentalUnitCurrentLease',
			variables
		}),
		({ signal }) =>
			fetchGetRentalUnitCurrentLease(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListUnitTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListUnitTypesError = Fetcher.ErrorWrapper<undefined>

export type ListUnitTypesVariables = {
	queryParams?: ListUnitTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListUnitTypes = (
	variables: ListUnitTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedUnitTypeSchema,
		ListUnitTypesError,
		undefined,
		{},
		ListUnitTypesQueryParams,
		{}
	>({ url: '/api/unit-types/', method: 'get', ...variables, signal })

export const useListUnitTypes = <TData = Schemas.PagedUnitTypeSchema>(
	variables: ListUnitTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedUnitTypeSchema,
			ListUnitTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedUnitTypeSchema,
		ListUnitTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/unit-types/',
			operationId: 'listUnitTypes',
			variables
		}),
		({ signal }) =>
			fetchListUnitTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GenerateReportError = Fetcher.ErrorWrapper<
	| {
			status: 400
			payload: string
	  }
	| {
			status: 403
			payload: 'Insufficient permissions to generate this report type.'
	  }
	| {
			status: 404
			payload: string
	  }
>

export type GenerateReportVariables = {
	body: Schemas.GenerateReportInSchema
} & RentalizeContext['fetcherOptions']

export const fetchGenerateReport = (
	variables: GenerateReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DocumentSchema,
		GenerateReportError,
		Schemas.GenerateReportInSchema,
		{},
		{},
		{}
	>({
		url: '/api/reporting/generate-report/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DocumentSchema,
			GenerateReportError,
			GenerateReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DocumentSchema,
		GenerateReportError,
		GenerateReportVariables
	>(
		(variables: GenerateReportVariables) =>
			fetchGenerateReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListReportTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListReportTypesError = Fetcher.ErrorWrapper<undefined>

export type ListReportTypesVariables = {
	queryParams?: ListReportTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListReportTypes = (
	variables: ListReportTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedReportTypeSchema,
		ListReportTypesError,
		undefined,
		{},
		ListReportTypesQueryParams,
		{}
	>({
		url: '/api/reporting/report-types/',
		method: 'get',
		...variables,
		signal
	})

export const useListReportTypes = <TData = Schemas.PagedReportTypeSchema>(
	variables: ListReportTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedReportTypeSchema,
			ListReportTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedReportTypeSchema,
		ListReportTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/reporting/report-types/',
			operationId: 'listReportTypes',
			variables
		}),
		({ signal }) =>
			fetchListReportTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateResidentIdentificationError = Fetcher.ErrorWrapper<undefined>

export type CreateResidentIdentificationVariables = {
	body: Schemas.ResidentIdentificationSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateResidentIdentification = (
	variables: CreateResidentIdentificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateResidentIdentificationError,
		Schemas.ResidentIdentificationSchema,
		{},
		{},
		{}
	>({
		url: '/api/resident-identification/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateResidentIdentification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateResidentIdentificationError,
			CreateResidentIdentificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateResidentIdentificationError,
		CreateResidentIdentificationVariables
	>(
		(variables: CreateResidentIdentificationVariables) =>
			fetchCreateResidentIdentification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListResidentIdentificationsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListResidentIdentificationsError = Fetcher.ErrorWrapper<undefined>

export type ListResidentIdentificationsVariables = {
	queryParams?: ListResidentIdentificationsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListResidentIdentifications = (
	variables: ListResidentIdentificationsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedResidentIdentificationSchema,
		ListResidentIdentificationsError,
		undefined,
		{},
		ListResidentIdentificationsQueryParams,
		{}
	>({
		url: '/api/resident-identification/',
		method: 'get',
		...variables,
		signal
	})

export const useListResidentIdentifications = <
	TData = Schemas.PagedResidentIdentificationSchema
>(
	variables: ListResidentIdentificationsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedResidentIdentificationSchema,
			ListResidentIdentificationsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedResidentIdentificationSchema,
		ListResidentIdentificationsError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-identification/',
			operationId: 'listResidentIdentifications',
			variables
		}),
		({ signal }) =>
			fetchListResidentIdentifications(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteResidentIdentificationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteResidentIdentificationError = Fetcher.ErrorWrapper<undefined>

export type DeleteResidentIdentificationVariables = {
	pathParams: DeleteResidentIdentificationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteResidentIdentification = (
	variables: DeleteResidentIdentificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteResidentIdentificationError,
		undefined,
		{},
		{},
		DeleteResidentIdentificationPathParams
	>({
		url: '/api/resident-identification/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteResidentIdentification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteResidentIdentificationError,
			DeleteResidentIdentificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteResidentIdentificationError,
		DeleteResidentIdentificationVariables
	>(
		(variables: DeleteResidentIdentificationVariables) =>
			fetchDeleteResidentIdentification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetResidentIdentificationDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetResidentIdentificationDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetResidentIdentificationDetailsVariables = {
	pathParams: GetResidentIdentificationDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetResidentIdentificationDetails = (
	variables: GetResidentIdentificationDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ResidentIdentificationSchema,
		GetResidentIdentificationDetailsError,
		undefined,
		{},
		{},
		GetResidentIdentificationDetailsPathParams
	>({
		url: '/api/resident-identification/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetResidentIdentificationDetails = <
	TData = Schemas.ResidentIdentificationSchema
>(
	variables: GetResidentIdentificationDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResidentIdentificationSchema,
			GetResidentIdentificationDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ResidentIdentificationSchema,
		GetResidentIdentificationDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-identification/{id}/',
			operationId: 'getResidentIdentificationDetails',
			variables
		}),
		({ signal }) =>
			fetchGetResidentIdentificationDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateResidentIdentificationPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateResidentIdentificationError = Fetcher.ErrorWrapper<undefined>

export type UpdateResidentIdentificationVariables = {
	body: Schemas.ResidentIdentificationSchema
	pathParams: UpdateResidentIdentificationPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateResidentIdentification = (
	variables: UpdateResidentIdentificationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateResidentIdentificationError,
		Schemas.ResidentIdentificationSchema,
		{},
		{},
		UpdateResidentIdentificationPathParams
	>({
		url: '/api/resident-identification/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateResidentIdentification = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateResidentIdentificationError,
			UpdateResidentIdentificationVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateResidentIdentificationError,
		UpdateResidentIdentificationVariables
	>(
		(variables: UpdateResidentIdentificationVariables) =>
			fetchUpdateResidentIdentification({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateResidentPetError = Fetcher.ErrorWrapper<undefined>

export type CreateResidentPetVariables = {
	body: Schemas.ResidentPetCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateResidentPet = (
	variables: CreateResidentPetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateResidentPetError,
		Schemas.ResidentPetCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/resident-pets/', method: 'post', ...variables, signal })

export const useCreateResidentPet = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateResidentPetError,
			CreateResidentPetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateResidentPetError,
		CreateResidentPetVariables
	>(
		(variables: CreateResidentPetVariables) =>
			fetchCreateResidentPet({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListResidentPetsQueryParams = {
	resident_id?: string | null
	rental_unit_id?: string | null
	lease_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListResidentPetsError = Fetcher.ErrorWrapper<undefined>

export type ListResidentPetsVariables = {
	queryParams?: ListResidentPetsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListResidentPets = (
	variables: ListResidentPetsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedResidentPetDetailSchema,
		ListResidentPetsError,
		undefined,
		{},
		ListResidentPetsQueryParams,
		{}
	>({ url: '/api/resident-pets/', method: 'get', ...variables, signal })

export const useListResidentPets = <
	TData = Schemas.PagedResidentPetDetailSchema
>(
	variables: ListResidentPetsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedResidentPetDetailSchema,
			ListResidentPetsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedResidentPetDetailSchema,
		ListResidentPetsError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-pets/',
			operationId: 'listResidentPets',
			variables
		}),
		({ signal }) =>
			fetchListResidentPets({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteResidentPetPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteResidentPetError = Fetcher.ErrorWrapper<undefined>

export type DeleteResidentPetVariables = {
	pathParams: DeleteResidentPetPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteResidentPet = (
	variables: DeleteResidentPetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteResidentPetError,
		undefined,
		{},
		{},
		DeleteResidentPetPathParams
	>({
		url: '/api/resident-pets/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteResidentPet = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteResidentPetError,
			DeleteResidentPetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteResidentPetError,
		DeleteResidentPetVariables
	>(
		(variables: DeleteResidentPetVariables) =>
			fetchDeleteResidentPet({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetResidentPetDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetResidentPetDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetResidentPetDetailsVariables = {
	pathParams: GetResidentPetDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetResidentPetDetails = (
	variables: GetResidentPetDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ResidentPetDetailSchema,
		GetResidentPetDetailsError,
		undefined,
		{},
		{},
		GetResidentPetDetailsPathParams
	>({ url: '/api/resident-pets/{id}/', method: 'get', ...variables, signal })

export const useGetResidentPetDetails = <
	TData = Schemas.ResidentPetDetailSchema
>(
	variables: GetResidentPetDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResidentPetDetailSchema,
			GetResidentPetDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ResidentPetDetailSchema,
		GetResidentPetDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-pets/{id}/',
			operationId: 'getResidentPetDetails',
			variables
		}),
		({ signal }) =>
			fetchGetResidentPetDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateResidentPetPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateResidentPetError = Fetcher.ErrorWrapper<undefined>

export type UpdateResidentPetVariables = {
	body: Schemas.ResidentPetSchema
	pathParams: UpdateResidentPetPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateResidentPet = (
	variables: UpdateResidentPetVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateResidentPetError,
		Schemas.ResidentPetSchema,
		{},
		{},
		UpdateResidentPetPathParams
	>({ url: '/api/resident-pets/{id}/', method: 'put', ...variables, signal })

export const useUpdateResidentPet = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateResidentPetError,
			UpdateResidentPetVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateResidentPetError,
		UpdateResidentPetVariables
	>(
		(variables: UpdateResidentPetVariables) =>
			fetchUpdateResidentPet({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListPetTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListPetTypesError = Fetcher.ErrorWrapper<undefined>

export type ListPetTypesVariables = {
	queryParams?: ListPetTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListPetTypes = (
	variables: ListPetTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedPetTypeSchema,
		ListPetTypesError,
		undefined,
		{},
		ListPetTypesQueryParams,
		{}
	>({ url: '/api/pet-types/', method: 'get', ...variables, signal })

export const useListPetTypes = <TData = Schemas.PagedPetTypeSchema>(
	variables: ListPetTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedPetTypeSchema,
			ListPetTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedPetTypeSchema,
		ListPetTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/pet-types/',
			operationId: 'listPetTypes',
			variables
		}),
		({ signal }) =>
			fetchListPetTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateResidentVehicleError = Fetcher.ErrorWrapper<undefined>

export type CreateResidentVehicleVariables = {
	body: Schemas.ResidentVehicleCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateResidentVehicle = (
	variables: CreateResidentVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateResidentVehicleError,
		Schemas.ResidentVehicleCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/resident-vehicles/', method: 'post', ...variables, signal })

export const useCreateResidentVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateResidentVehicleError,
			CreateResidentVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateResidentVehicleError,
		CreateResidentVehicleVariables
	>(
		(variables: CreateResidentVehicleVariables) =>
			fetchCreateResidentVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListResidentVehiclesQueryParams = {
	resident_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListResidentVehiclesError = Fetcher.ErrorWrapper<undefined>

export type ListResidentVehiclesVariables = {
	queryParams?: ListResidentVehiclesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListResidentVehicles = (
	variables: ListResidentVehiclesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedResidentVehicleDetailSchema,
		ListResidentVehiclesError,
		undefined,
		{},
		ListResidentVehiclesQueryParams,
		{}
	>({ url: '/api/resident-vehicles/', method: 'get', ...variables, signal })

export const useListResidentVehicles = <
	TData = Schemas.PagedResidentVehicleDetailSchema
>(
	variables: ListResidentVehiclesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedResidentVehicleDetailSchema,
			ListResidentVehiclesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedResidentVehicleDetailSchema,
		ListResidentVehiclesError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-vehicles/',
			operationId: 'listResidentVehicles',
			variables
		}),
		({ signal }) =>
			fetchListResidentVehicles({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteResidentVehiclePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteResidentVehicleError = Fetcher.ErrorWrapper<undefined>

export type DeleteResidentVehicleVariables = {
	pathParams: DeleteResidentVehiclePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteResidentVehicle = (
	variables: DeleteResidentVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteResidentVehicleError,
		undefined,
		{},
		{},
		DeleteResidentVehiclePathParams
	>({
		url: '/api/resident-vehicles/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteResidentVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteResidentVehicleError,
			DeleteResidentVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteResidentVehicleError,
		DeleteResidentVehicleVariables
	>(
		(variables: DeleteResidentVehicleVariables) =>
			fetchDeleteResidentVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetResidentVehicleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetResidentVehicleDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetResidentVehicleDetailsVariables = {
	pathParams: GetResidentVehicleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetResidentVehicleDetails = (
	variables: GetResidentVehicleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ResidentVehicleSchema,
		GetResidentVehicleDetailsError,
		undefined,
		{},
		{},
		GetResidentVehicleDetailsPathParams
	>({
		url: '/api/resident-vehicles/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetResidentVehicleDetails = <
	TData = Schemas.ResidentVehicleSchema
>(
	variables: GetResidentVehicleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResidentVehicleSchema,
			GetResidentVehicleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ResidentVehicleSchema,
		GetResidentVehicleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/resident-vehicles/{id}/',
			operationId: 'getResidentVehicleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetResidentVehicleDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateResidentVehiclePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateResidentVehicleError = Fetcher.ErrorWrapper<undefined>

export type UpdateResidentVehicleVariables = {
	body: Schemas.ResidentVehicleSchema
	pathParams: UpdateResidentVehiclePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateResidentVehicle = (
	variables: UpdateResidentVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateResidentVehicleError,
		Schemas.ResidentVehicleSchema,
		{},
		{},
		UpdateResidentVehiclePathParams
	>({
		url: '/api/resident-vehicles/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateResidentVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateResidentVehicleError,
			UpdateResidentVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateResidentVehicleError,
		UpdateResidentVehicleVariables
	>(
		(variables: UpdateResidentVehicleVariables) =>
			fetchUpdateResidentVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateResidentError = Fetcher.ErrorWrapper<undefined>

export type CreateResidentVariables = {
	body: Schemas.ResidentInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateResident = (
	variables: CreateResidentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateResidentError,
		Schemas.ResidentInSchema,
		{},
		{},
		{}
	>({ url: '/api/residents/', method: 'post', ...variables, signal })

export const useCreateResident = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateResidentError,
			CreateResidentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateResidentError,
		CreateResidentVariables
	>(
		(variables: CreateResidentVariables) =>
			fetchCreateResident({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListResidentsQueryParams = {
	lease_id?: string | null
	rental_unit_id?: string | null
	rental_application_submission_id?: string | null
	/**
	 * @default ALL
	 */
	resident_type?:
		| 'CURRENT_RESIDENT'
		| 'FORMER_RESIDENT'
		| 'FUTURE_RESIDENT'
		| 'RENTAL_APPLICANT'
		| 'ALL'
	/**
	 * @default NAME
	 */
	sort_by?: 'NAME' | 'CREATED_DATE'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListResidentsError = Fetcher.ErrorWrapper<undefined>

export type ListResidentsVariables = {
	queryParams?: ListResidentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListResidents = (
	variables: ListResidentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedListResidentsSchema,
		ListResidentsError,
		undefined,
		{},
		ListResidentsQueryParams,
		{}
	>({ url: '/api/residents/', method: 'get', ...variables, signal })

export const useListResidents = <TData = Schemas.PagedListResidentsSchema>(
	variables: ListResidentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedListResidentsSchema,
			ListResidentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedListResidentsSchema,
		ListResidentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/residents/',
			operationId: 'listResidents',
			variables
		}),
		({ signal }) =>
			fetchListResidents({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteResidentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteResidentError = Fetcher.ErrorWrapper<undefined>

export type DeleteResidentVariables = {
	pathParams: DeleteResidentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteResident = (
	variables: DeleteResidentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteResidentError,
		undefined,
		{},
		{},
		DeleteResidentPathParams
	>({ url: '/api/residents/{id}/', method: 'delete', ...variables, signal })

export const useDeleteResident = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteResidentError,
			DeleteResidentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteResidentError,
		DeleteResidentVariables
	>(
		(variables: DeleteResidentVariables) =>
			fetchDeleteResident({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetResidentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetResidentDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetResidentDetailsVariables = {
	pathParams: GetResidentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetResidentDetails = (
	variables: GetResidentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ResidentDetailSchema,
		GetResidentDetailsError,
		undefined,
		{},
		{},
		GetResidentDetailsPathParams
	>({ url: '/api/residents/{id}/', method: 'get', ...variables, signal })

export const useGetResidentDetails = <TData = Schemas.ResidentDetailSchema>(
	variables: GetResidentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ResidentDetailSchema,
			GetResidentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ResidentDetailSchema,
		GetResidentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/residents/{id}/',
			operationId: 'getResidentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetResidentDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateResidentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateResidentError = Fetcher.ErrorWrapper<undefined>

export type UpdateResidentVariables = {
	body: Schemas.ResidentInSchema
	pathParams: UpdateResidentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateResident = (
	variables: UpdateResidentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateResidentError,
		Schemas.ResidentInSchema,
		{},
		{},
		UpdateResidentPathParams
	>({ url: '/api/residents/{id}/', method: 'put', ...variables, signal })

export const useUpdateResident = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateResidentError,
			UpdateResidentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateResidentError,
		UpdateResidentVariables
	>(
		(variables: UpdateResidentVariables) =>
			fetchUpdateResident({ ...fetcherOptions, ...variables }),
		options
	)
}

export type InviteAllResidentsError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Record<string, any>
}>

export type InviteAllResidentsVariables = RentalizeContext['fetcherOptions']

/**
 * This API sends an invitation email to every resident, inviting them to the resident center.
 *
 * It does not send invites to residents if:
 * * They have already logged into the Resident Center, or
 * * They have received another invite to the Resident Center within the last 2 days
 */
export const fetchInviteAllResidents = (
	variables: InviteAllResidentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InviteAllResidentsError,
		undefined,
		{},
		{},
		{}
	>({
		url: '/api/invite-all-residents/',
		method: 'post',
		...variables,
		signal
	})

/**
 * This API sends an invitation email to every resident, inviting them to the resident center.
 *
 * It does not send invites to residents if:
 * * They have already logged into the Resident Center, or
 * * They have received another invite to the Resident Center within the last 2 days
 */
export const useInviteAllResidents = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InviteAllResidentsError,
			InviteAllResidentsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InviteAllResidentsError,
		InviteAllResidentsVariables
	>(
		(variables: InviteAllResidentsVariables) =>
			fetchInviteAllResidents({ ...fetcherOptions, ...variables }),
		options
	)
}

export type InviteResidentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type InviteResidentError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Record<string, any>
}>

export type InviteResidentVariables = {
	pathParams: InviteResidentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchInviteResident = (
	variables: InviteResidentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		InviteResidentError,
		undefined,
		{},
		{},
		InviteResidentPathParams
	>({
		url: '/api/residents/{id}/invite-to-resident-center/',
		method: 'post',
		...variables,
		signal
	})

export const useInviteResident = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			InviteResidentError,
			InviteResidentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		InviteResidentError,
		InviteResidentVariables
	>(
		(variables: InviteResidentVariables) =>
			fetchInviteResident({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateScheduledReportError = Fetcher.ErrorWrapper<undefined>

export type CreateScheduledReportVariables = {
	body: Schemas.ScheduledReportCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateScheduledReport = (
	variables: CreateScheduledReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateScheduledReportError,
		Schemas.ScheduledReportCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/scheduled-reports/', method: 'post', ...variables, signal })

export const useCreateScheduledReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateScheduledReportError,
			CreateScheduledReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateScheduledReportError,
		CreateScheduledReportVariables
	>(
		(variables: CreateScheduledReportVariables) =>
			fetchCreateScheduledReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListScheduledReportsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListScheduledReportsError = Fetcher.ErrorWrapper<undefined>

export type ListScheduledReportsVariables = {
	queryParams?: ListScheduledReportsQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all ScheduledReport objects for this User's PropertyManagementCompany.
 */
export const fetchListScheduledReports = (
	variables: ListScheduledReportsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedScheduledReportSchema,
		ListScheduledReportsError,
		undefined,
		{},
		ListScheduledReportsQueryParams,
		{}
	>({ url: '/api/scheduled-reports/', method: 'get', ...variables, signal })

/**
 * Returns a list of all ScheduledReport objects for this User's PropertyManagementCompany.
 */
export const useListScheduledReports = <
	TData = Schemas.PagedScheduledReportSchema
>(
	variables: ListScheduledReportsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedScheduledReportSchema,
			ListScheduledReportsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedScheduledReportSchema,
		ListScheduledReportsError,
		TData
	>(
		queryKeyFn({
			path: '/api/scheduled-reports/',
			operationId: 'listScheduledReports',
			variables
		}),
		({ signal }) =>
			fetchListScheduledReports({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteScheduledReportPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteScheduledReportError = Fetcher.ErrorWrapper<undefined>

export type DeleteScheduledReportVariables = {
	pathParams: DeleteScheduledReportPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteScheduledReport = (
	variables: DeleteScheduledReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteScheduledReportError,
		undefined,
		{},
		{},
		DeleteScheduledReportPathParams
	>({
		url: '/api/scheduled-reports/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteScheduledReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteScheduledReportError,
			DeleteScheduledReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteScheduledReportError,
		DeleteScheduledReportVariables
	>(
		(variables: DeleteScheduledReportVariables) =>
			fetchDeleteScheduledReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetScheduledReportDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetScheduledReportDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetScheduledReportDetailsVariables = {
	pathParams: GetScheduledReportDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetScheduledReportDetails = (
	variables: GetScheduledReportDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.ScheduledReportSchema,
		GetScheduledReportDetailsError,
		undefined,
		{},
		{},
		GetScheduledReportDetailsPathParams
	>({
		url: '/api/scheduled-reports/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetScheduledReportDetails = <
	TData = Schemas.ScheduledReportSchema
>(
	variables: GetScheduledReportDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.ScheduledReportSchema,
			GetScheduledReportDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.ScheduledReportSchema,
		GetScheduledReportDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/scheduled-reports/{id}/',
			operationId: 'getScheduledReportDetails',
			variables
		}),
		({ signal }) =>
			fetchGetScheduledReportDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateScheduledReportPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateScheduledReportError = Fetcher.ErrorWrapper<undefined>

export type UpdateScheduledReportVariables = {
	body: Schemas.ScheduledReportSchema
	pathParams: UpdateScheduledReportPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateScheduledReport = (
	variables: UpdateScheduledReportVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateScheduledReportError,
		Schemas.ScheduledReportSchema,
		{},
		{},
		UpdateScheduledReportPathParams
	>({
		url: '/api/scheduled-reports/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateScheduledReport = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateScheduledReportError,
			UpdateScheduledReportVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateScheduledReportError,
		UpdateScheduledReportVariables
	>(
		(variables: UpdateScheduledReportVariables) =>
			fetchUpdateScheduledReport({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListScheduledReportCadencesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListScheduledReportCadencesError = Fetcher.ErrorWrapper<undefined>

export type ListScheduledReportCadencesVariables = {
	queryParams?: ListScheduledReportCadencesQueryParams
} & RentalizeContext['fetcherOptions']

/**
 * Returns a list of all ScheduledReport objects for this User's PropertyManagementCompany.
 */
export const fetchListScheduledReportCadences = (
	variables: ListScheduledReportCadencesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedScheduledReportCadenceSchema,
		ListScheduledReportCadencesError,
		undefined,
		{},
		ListScheduledReportCadencesQueryParams,
		{}
	>({
		url: '/api/scheduled-reports/cadences',
		method: 'get',
		...variables,
		signal
	})

/**
 * Returns a list of all ScheduledReport objects for this User's PropertyManagementCompany.
 */
export const useListScheduledReportCadences = <
	TData = Schemas.PagedScheduledReportCadenceSchema
>(
	variables: ListScheduledReportCadencesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedScheduledReportCadenceSchema,
			ListScheduledReportCadencesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedScheduledReportCadenceSchema,
		ListScheduledReportCadencesError,
		TData
	>(
		queryKeyFn({
			path: '/api/scheduled-reports/cadences',
			operationId: 'listScheduledReportCadences',
			variables
		}),
		({ signal }) =>
			fetchListScheduledReportCadences(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateSecurityDepositClaimError = Fetcher.ErrorWrapper<undefined>

export type CreateSecurityDepositClaimVariables = {
	body: Schemas.SecurityDepositClaimInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSecurityDepositClaim = (
	variables: CreateSecurityDepositClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSecurityDepositClaimError,
		Schemas.SecurityDepositClaimInSchema,
		{},
		{},
		{}
	>({
		url: '/api/security-deposit-claims/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateSecurityDepositClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSecurityDepositClaimError,
			CreateSecurityDepositClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSecurityDepositClaimError,
		CreateSecurityDepositClaimVariables
	>(
		(variables: CreateSecurityDepositClaimVariables) =>
			fetchCreateSecurityDepositClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSecurityDepositClaimsQueryParams = {
	lease_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSecurityDepositClaimsError = Fetcher.ErrorWrapper<undefined>

export type ListSecurityDepositClaimsVariables = {
	queryParams?: ListSecurityDepositClaimsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSecurityDepositClaims = (
	variables: ListSecurityDepositClaimsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSecurityDepositClaimOutSchema,
		ListSecurityDepositClaimsError,
		undefined,
		{},
		ListSecurityDepositClaimsQueryParams,
		{}
	>({
		url: '/api/security-deposit-claims/',
		method: 'get',
		...variables,
		signal
	})

export const useListSecurityDepositClaims = <
	TData = Schemas.PagedSecurityDepositClaimOutSchema
>(
	variables: ListSecurityDepositClaimsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSecurityDepositClaimOutSchema,
			ListSecurityDepositClaimsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSecurityDepositClaimOutSchema,
		ListSecurityDepositClaimsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposit-claims/',
			operationId: 'listSecurityDepositClaims',
			variables
		}),
		({ signal }) =>
			fetchListSecurityDepositClaims(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSecurityDepositClaimPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSecurityDepositClaimError = Fetcher.ErrorWrapper<undefined>

export type DeleteSecurityDepositClaimVariables = {
	pathParams: DeleteSecurityDepositClaimPathParams
} & RentalizeContext['fetcherOptions']

/**
 * Deletes a SecurityDepositClaim object
 */
export const fetchDeleteSecurityDepositClaim = (
	variables: DeleteSecurityDepositClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSecurityDepositClaimError,
		undefined,
		{},
		{},
		DeleteSecurityDepositClaimPathParams
	>({
		url: '/api/security-deposit-claims/{id}/',
		method: 'delete',
		...variables,
		signal
	})

/**
 * Deletes a SecurityDepositClaim object
 */
export const useDeleteSecurityDepositClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSecurityDepositClaimError,
			DeleteSecurityDepositClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSecurityDepositClaimError,
		DeleteSecurityDepositClaimVariables
	>(
		(variables: DeleteSecurityDepositClaimVariables) =>
			fetchDeleteSecurityDepositClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSecurityDepositClaimDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSecurityDepositClaimDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetSecurityDepositClaimDetailsVariables = {
	pathParams: GetSecurityDepositClaimDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSecurityDepositClaimDetails = (
	variables: GetSecurityDepositClaimDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SecurityDepositClaimOutSchema,
		GetSecurityDepositClaimDetailsError,
		undefined,
		{},
		{},
		GetSecurityDepositClaimDetailsPathParams
	>({
		url: '/api/security-deposit-claims/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSecurityDepositClaimDetails = <
	TData = Schemas.SecurityDepositClaimOutSchema
>(
	variables: GetSecurityDepositClaimDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SecurityDepositClaimOutSchema,
			GetSecurityDepositClaimDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SecurityDepositClaimOutSchema,
		GetSecurityDepositClaimDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposit-claims/{id}/',
			operationId: 'getSecurityDepositClaimDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSecurityDepositClaimDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSecurityDepositClaimPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSecurityDepositClaimError = Fetcher.ErrorWrapper<undefined>

export type UpdateSecurityDepositClaimVariables = {
	body: Schemas.SecurityDepositClaimInSchema
	pathParams: UpdateSecurityDepositClaimPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSecurityDepositClaim = (
	variables: UpdateSecurityDepositClaimVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSecurityDepositClaimError,
		Schemas.SecurityDepositClaimInSchema,
		{},
		{},
		UpdateSecurityDepositClaimPathParams
	>({
		url: '/api/security-deposit-claims/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSecurityDepositClaim = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSecurityDepositClaimError,
			UpdateSecurityDepositClaimVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSecurityDepositClaimError,
		UpdateSecurityDepositClaimVariables
	>(
		(variables: UpdateSecurityDepositClaimVariables) =>
			fetchUpdateSecurityDepositClaim({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSecurityDepositPaymentError = Fetcher.ErrorWrapper<undefined>

export type CreateSecurityDepositPaymentVariables = {
	body: Schemas.SecurityDepositPaymentInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSecurityDepositPayment = (
	variables: CreateSecurityDepositPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSecurityDepositPaymentError,
		Schemas.SecurityDepositPaymentInSchema,
		{},
		{},
		{}
	>({
		url: '/api/security-deposit-payments/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateSecurityDepositPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSecurityDepositPaymentError,
			CreateSecurityDepositPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSecurityDepositPaymentError,
		CreateSecurityDepositPaymentVariables
	>(
		(variables: CreateSecurityDepositPaymentVariables) =>
			fetchCreateSecurityDepositPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSecurityDepositPaymentsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSecurityDepositPaymentsError = Fetcher.ErrorWrapper<undefined>

export type ListSecurityDepositPaymentsVariables = {
	queryParams?: ListSecurityDepositPaymentsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSecurityDepositPayments = (
	variables: ListSecurityDepositPaymentsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSecurityDepositPaymentOutSchema,
		ListSecurityDepositPaymentsError,
		undefined,
		{},
		ListSecurityDepositPaymentsQueryParams,
		{}
	>({
		url: '/api/security-deposit-payments/',
		method: 'get',
		...variables,
		signal
	})

export const useListSecurityDepositPayments = <
	TData = Schemas.PagedSecurityDepositPaymentOutSchema
>(
	variables: ListSecurityDepositPaymentsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSecurityDepositPaymentOutSchema,
			ListSecurityDepositPaymentsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSecurityDepositPaymentOutSchema,
		ListSecurityDepositPaymentsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposit-payments/',
			operationId: 'listSecurityDepositPayments',
			variables
		}),
		({ signal }) =>
			fetchListSecurityDepositPayments(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSecurityDepositPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSecurityDepositPaymentError = Fetcher.ErrorWrapper<undefined>

export type DeleteSecurityDepositPaymentVariables = {
	pathParams: DeleteSecurityDepositPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteSecurityDepositPayment = (
	variables: DeleteSecurityDepositPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSecurityDepositPaymentError,
		undefined,
		{},
		{},
		DeleteSecurityDepositPaymentPathParams
	>({
		url: '/api/security-deposit-payments/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteSecurityDepositPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSecurityDepositPaymentError,
			DeleteSecurityDepositPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSecurityDepositPaymentError,
		DeleteSecurityDepositPaymentVariables
	>(
		(variables: DeleteSecurityDepositPaymentVariables) =>
			fetchDeleteSecurityDepositPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSecurityDepositPaymentDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSecurityDepositPaymentDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetSecurityDepositPaymentDetailsVariables = {
	pathParams: GetSecurityDepositPaymentDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSecurityDepositPaymentDetails = (
	variables: GetSecurityDepositPaymentDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SecurityDepositPaymentOutSchema,
		GetSecurityDepositPaymentDetailsError,
		undefined,
		{},
		{},
		GetSecurityDepositPaymentDetailsPathParams
	>({
		url: '/api/security-deposit-payments/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSecurityDepositPaymentDetails = <
	TData = Schemas.SecurityDepositPaymentOutSchema
>(
	variables: GetSecurityDepositPaymentDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SecurityDepositPaymentOutSchema,
			GetSecurityDepositPaymentDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SecurityDepositPaymentOutSchema,
		GetSecurityDepositPaymentDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposit-payments/{id}/',
			operationId: 'getSecurityDepositPaymentDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSecurityDepositPaymentDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSecurityDepositPaymentPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSecurityDepositPaymentError = Fetcher.ErrorWrapper<undefined>

export type UpdateSecurityDepositPaymentVariables = {
	body: Schemas.SecurityDepositPaymentInSchema
	pathParams: UpdateSecurityDepositPaymentPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSecurityDepositPayment = (
	variables: UpdateSecurityDepositPaymentVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSecurityDepositPaymentError,
		Schemas.SecurityDepositPaymentInSchema,
		{},
		{},
		UpdateSecurityDepositPaymentPathParams
	>({
		url: '/api/security-deposit-payments/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSecurityDepositPayment = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSecurityDepositPaymentError,
			UpdateSecurityDepositPaymentVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSecurityDepositPaymentError,
		UpdateSecurityDepositPaymentVariables
	>(
		(variables: UpdateSecurityDepositPaymentVariables) =>
			fetchUpdateSecurityDepositPayment({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSecurityDepositError = Fetcher.ErrorWrapper<undefined>

export type CreateSecurityDepositVariables = {
	body: Schemas.SecurityDepositInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSecurityDeposit = (
	variables: CreateSecurityDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSecurityDepositError,
		Schemas.SecurityDepositInSchema,
		{},
		{},
		{}
	>({ url: '/api/security-deposits/', method: 'post', ...variables, signal })

export const useCreateSecurityDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSecurityDepositError,
			CreateSecurityDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSecurityDepositError,
		CreateSecurityDepositVariables
	>(
		(variables: CreateSecurityDepositVariables) =>
			fetchCreateSecurityDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSecurityDepositsQueryParams = {
	lease_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSecurityDepositsError = Fetcher.ErrorWrapper<undefined>

export type ListSecurityDepositsVariables = {
	queryParams?: ListSecurityDepositsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSecurityDeposits = (
	variables: ListSecurityDepositsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSecurityDepositOutSchema,
		ListSecurityDepositsError,
		undefined,
		{},
		ListSecurityDepositsQueryParams,
		{}
	>({ url: '/api/security-deposits/', method: 'get', ...variables, signal })

export const useListSecurityDeposits = <
	TData = Schemas.PagedSecurityDepositOutSchema
>(
	variables: ListSecurityDepositsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSecurityDepositOutSchema,
			ListSecurityDepositsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSecurityDepositOutSchema,
		ListSecurityDepositsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposits/',
			operationId: 'listSecurityDeposits',
			variables
		}),
		({ signal }) =>
			fetchListSecurityDeposits({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSecurityDepositPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSecurityDepositError = Fetcher.ErrorWrapper<undefined>

export type DeleteSecurityDepositVariables = {
	pathParams: DeleteSecurityDepositPathParams
} & RentalizeContext['fetcherOptions']

/**
 * Deletes a SecurityDeposit object, and its corresponding JournalEntry and Transaction objects.
 */
export const fetchDeleteSecurityDeposit = (
	variables: DeleteSecurityDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSecurityDepositError,
		undefined,
		{},
		{},
		DeleteSecurityDepositPathParams
	>({
		url: '/api/security-deposits/{id}/',
		method: 'delete',
		...variables,
		signal
	})

/**
 * Deletes a SecurityDeposit object, and its corresponding JournalEntry and Transaction objects.
 */
export const useDeleteSecurityDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSecurityDepositError,
			DeleteSecurityDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSecurityDepositError,
		DeleteSecurityDepositVariables
	>(
		(variables: DeleteSecurityDepositVariables) =>
			fetchDeleteSecurityDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSecurityDepositDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSecurityDepositDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSecurityDepositDetailsVariables = {
	pathParams: GetSecurityDepositDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSecurityDepositDetails = (
	variables: GetSecurityDepositDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SecurityDepositOutSchema,
		GetSecurityDepositDetailsError,
		undefined,
		{},
		{},
		GetSecurityDepositDetailsPathParams
	>({
		url: '/api/security-deposits/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSecurityDepositDetails = <
	TData = Schemas.SecurityDepositOutSchema
>(
	variables: GetSecurityDepositDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SecurityDepositOutSchema,
			GetSecurityDepositDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SecurityDepositOutSchema,
		GetSecurityDepositDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/security-deposits/{id}/',
			operationId: 'getSecurityDepositDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSecurityDepositDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSecurityDepositPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSecurityDepositError = Fetcher.ErrorWrapper<undefined>

export type UpdateSecurityDepositVariables = {
	body: Schemas.SecurityDepositInSchema
	pathParams: UpdateSecurityDepositPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSecurityDeposit = (
	variables: UpdateSecurityDepositVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSecurityDepositError,
		Schemas.SecurityDepositInSchema,
		{},
		{},
		UpdateSecurityDepositPathParams
	>({
		url: '/api/security-deposits/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSecurityDeposit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSecurityDepositError,
			UpdateSecurityDepositVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSecurityDepositError,
		UpdateSecurityDepositVariables
	>(
		(variables: UpdateSecurityDepositVariables) =>
			fetchUpdateSecurityDeposit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSignatureRequestSignerError = Fetcher.ErrorWrapper<undefined>

export type CreateSignatureRequestSignerVariables = {
	body: Schemas.SignatureRequestSignerInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSignatureRequestSigner = (
	variables: CreateSignatureRequestSignerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSignatureRequestSignerError,
		Schemas.SignatureRequestSignerInSchema,
		{},
		{},
		{}
	>({
		url: '/api/signature-request-signers/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateSignatureRequestSigner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSignatureRequestSignerError,
			CreateSignatureRequestSignerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSignatureRequestSignerError,
		CreateSignatureRequestSignerVariables
	>(
		(variables: CreateSignatureRequestSignerVariables) =>
			fetchCreateSignatureRequestSigner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSignatureRequestSignersQueryParams = {
	signature_request_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSignatureRequestSignersError = Fetcher.ErrorWrapper<undefined>

export type ListSignatureRequestSignersVariables = {
	queryParams?: ListSignatureRequestSignersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSignatureRequestSigners = (
	variables: ListSignatureRequestSignersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSignatureRequestSignerOutSchema,
		ListSignatureRequestSignersError,
		undefined,
		{},
		ListSignatureRequestSignersQueryParams,
		{}
	>({
		url: '/api/signature-request-signers/',
		method: 'get',
		...variables,
		signal
	})

export const useListSignatureRequestSigners = <
	TData = Schemas.PagedSignatureRequestSignerOutSchema
>(
	variables: ListSignatureRequestSignersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSignatureRequestSignerOutSchema,
			ListSignatureRequestSignersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSignatureRequestSignerOutSchema,
		ListSignatureRequestSignersError,
		TData
	>(
		queryKeyFn({
			path: '/api/signature-request-signers/',
			operationId: 'listSignatureRequestSigners',
			variables
		}),
		({ signal }) =>
			fetchListSignatureRequestSigners(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSignatureRequestSignerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSignatureRequestSignerError = Fetcher.ErrorWrapper<undefined>

export type DeleteSignatureRequestSignerVariables = {
	pathParams: DeleteSignatureRequestSignerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteSignatureRequestSigner = (
	variables: DeleteSignatureRequestSignerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSignatureRequestSignerError,
		undefined,
		{},
		{},
		DeleteSignatureRequestSignerPathParams
	>({
		url: '/api/signature-request-signers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteSignatureRequestSigner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSignatureRequestSignerError,
			DeleteSignatureRequestSignerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSignatureRequestSignerError,
		DeleteSignatureRequestSignerVariables
	>(
		(variables: DeleteSignatureRequestSignerVariables) =>
			fetchDeleteSignatureRequestSigner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSignatureRequestSignerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSignatureRequestSignerDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetSignatureRequestSignerDetailsVariables = {
	pathParams: GetSignatureRequestSignerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSignatureRequestSignerDetails = (
	variables: GetSignatureRequestSignerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SignatureRequestSignerOutSchema,
		GetSignatureRequestSignerDetailsError,
		undefined,
		{},
		{},
		GetSignatureRequestSignerDetailsPathParams
	>({
		url: '/api/signature-request-signers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSignatureRequestSignerDetails = <
	TData = Schemas.SignatureRequestSignerOutSchema
>(
	variables: GetSignatureRequestSignerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SignatureRequestSignerOutSchema,
			GetSignatureRequestSignerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SignatureRequestSignerOutSchema,
		GetSignatureRequestSignerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/signature-request-signers/{id}/',
			operationId: 'getSignatureRequestSignerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSignatureRequestSignerDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSignatureRequestSignerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSignatureRequestSignerError = Fetcher.ErrorWrapper<undefined>

export type UpdateSignatureRequestSignerVariables = {
	body: Schemas.SignatureRequestSignerInSchema
	pathParams: UpdateSignatureRequestSignerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSignatureRequestSigner = (
	variables: UpdateSignatureRequestSignerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSignatureRequestSignerError,
		Schemas.SignatureRequestSignerInSchema,
		{},
		{},
		UpdateSignatureRequestSignerPathParams
	>({
		url: '/api/signature-request-signers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSignatureRequestSigner = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSignatureRequestSignerError,
			UpdateSignatureRequestSignerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSignatureRequestSignerError,
		UpdateSignatureRequestSignerVariables
	>(
		(variables: UpdateSignatureRequestSignerVariables) =>
			fetchUpdateSignatureRequestSigner({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSignatureRequestError = Fetcher.ErrorWrapper<undefined>

export type CreateSignatureRequestVariables = {
	body: Schemas.SignatureRequestInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSignatureRequest = (
	variables: CreateSignatureRequestVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSignatureRequestError,
		Schemas.SignatureRequestInSchema,
		{},
		{},
		{}
	>({ url: '/api/signature-requests/', method: 'post', ...variables, signal })

export const useCreateSignatureRequest = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSignatureRequestError,
			CreateSignatureRequestVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSignatureRequestError,
		CreateSignatureRequestVariables
	>(
		(variables: CreateSignatureRequestVariables) =>
			fetchCreateSignatureRequest({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSignatureRequestsQueryParams = {
	building_id?: string | null
	lease_id?: string | null
	rental_unit_id?: string | null
	property_owner_id?: string | null
	resident_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSignatureRequestsError = Fetcher.ErrorWrapper<undefined>

export type ListSignatureRequestsVariables = {
	queryParams?: ListSignatureRequestsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSignatureRequests = (
	variables: ListSignatureRequestsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSignatureRequestOutSchema,
		ListSignatureRequestsError,
		undefined,
		{},
		ListSignatureRequestsQueryParams,
		{}
	>({ url: '/api/signature-requests/', method: 'get', ...variables, signal })

export const useListSignatureRequests = <
	TData = Schemas.PagedSignatureRequestOutSchema
>(
	variables: ListSignatureRequestsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSignatureRequestOutSchema,
			ListSignatureRequestsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSignatureRequestOutSchema,
		ListSignatureRequestsError,
		TData
	>(
		queryKeyFn({
			path: '/api/signature-requests/',
			operationId: 'listSignatureRequests',
			variables
		}),
		({ signal }) =>
			fetchListSignatureRequests({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSignatureRequestsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSignatureRequestsError = Fetcher.ErrorWrapper<undefined>

export type DeleteSignatureRequestsVariables = {
	pathParams: DeleteSignatureRequestsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteSignatureRequests = (
	variables: DeleteSignatureRequestsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSignatureRequestsError,
		undefined,
		{},
		{},
		DeleteSignatureRequestsPathParams
	>({
		url: '/api/signature-requests/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteSignatureRequests = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSignatureRequestsError,
			DeleteSignatureRequestsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSignatureRequestsError,
		DeleteSignatureRequestsVariables
	>(
		(variables: DeleteSignatureRequestsVariables) =>
			fetchDeleteSignatureRequests({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSignatureRequestDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSignatureRequestDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSignatureRequestDetailsVariables = {
	pathParams: GetSignatureRequestDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSignatureRequestDetails = (
	variables: GetSignatureRequestDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SignatureRequestOutSchema,
		GetSignatureRequestDetailsError,
		undefined,
		{},
		{},
		GetSignatureRequestDetailsPathParams
	>({
		url: '/api/signature-requests/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSignatureRequestDetails = <
	TData = Schemas.SignatureRequestOutSchema
>(
	variables: GetSignatureRequestDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SignatureRequestOutSchema,
			GetSignatureRequestDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SignatureRequestOutSchema,
		GetSignatureRequestDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/signature-requests/{id}/',
			operationId: 'getSignatureRequestDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSignatureRequestDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSignatureRequestPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSignatureRequestError = Fetcher.ErrorWrapper<undefined>

export type UpdateSignatureRequestVariables = {
	body: Schemas.SignatureRequestInSchema
	pathParams: UpdateSignatureRequestPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSignatureRequest = (
	variables: UpdateSignatureRequestVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSignatureRequestError,
		Schemas.SignatureRequestInSchema,
		{},
		{},
		UpdateSignatureRequestPathParams
	>({
		url: '/api/signature-requests/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSignatureRequest = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSignatureRequestError,
			UpdateSignatureRequestVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSignatureRequestError,
		UpdateSignatureRequestVariables
	>(
		(variables: UpdateSignatureRequestVariables) =>
			fetchUpdateSignatureRequest({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSmsMessageBatchError = Fetcher.ErrorWrapper<undefined>

export type CreateSmsMessageBatchVariables = {
	body: Schemas.SMSMessageBatchCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSmsMessageBatch = (
	variables: CreateSmsMessageBatchVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSmsMessageBatchError,
		Schemas.SMSMessageBatchCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/sms-message-batches/', method: 'post', ...variables, signal })

export const useCreateSmsMessageBatch = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSmsMessageBatchError,
			CreateSmsMessageBatchVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSmsMessageBatchError,
		CreateSmsMessageBatchVariables
	>(
		(variables: CreateSmsMessageBatchVariables) =>
			fetchCreateSmsMessageBatch({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSmsMessageBatchsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSmsMessageBatchsError = Fetcher.ErrorWrapper<undefined>

export type ListSmsMessageBatchsVariables = {
	queryParams?: ListSmsMessageBatchsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSmsMessageBatchs = (
	variables: ListSmsMessageBatchsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSMSMessageBatchSchema,
		ListSmsMessageBatchsError,
		undefined,
		{},
		ListSmsMessageBatchsQueryParams,
		{}
	>({ url: '/api/sms-message-batches/', method: 'get', ...variables, signal })

export const useListSmsMessageBatchs = <
	TData = Schemas.PagedSMSMessageBatchSchema
>(
	variables: ListSmsMessageBatchsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSMSMessageBatchSchema,
			ListSmsMessageBatchsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSMSMessageBatchSchema,
		ListSmsMessageBatchsError,
		TData
	>(
		queryKeyFn({
			path: '/api/sms-message-batches/',
			operationId: 'listSmsMessageBatchs',
			variables
		}),
		({ signal }) =>
			fetchListSmsMessageBatchs({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetSmsMessageBatchDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSmsMessageBatchDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSmsMessageBatchDetailsVariables = {
	pathParams: GetSmsMessageBatchDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSmsMessageBatchDetails = (
	variables: GetSmsMessageBatchDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SMSMessageBatchSchema,
		GetSmsMessageBatchDetailsError,
		undefined,
		{},
		{},
		GetSmsMessageBatchDetailsPathParams
	>({
		url: '/api/sms-message-batches/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetSmsMessageBatchDetails = <
	TData = Schemas.SMSMessageBatchSchema
>(
	variables: GetSmsMessageBatchDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SMSMessageBatchSchema,
			GetSmsMessageBatchDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SMSMessageBatchSchema,
		GetSmsMessageBatchDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/sms-message-batches/{id}/',
			operationId: 'getSmsMessageBatchDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSmsMessageBatchDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSmsMessageBatchPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSmsMessageBatchError = Fetcher.ErrorWrapper<undefined>

export type UpdateSmsMessageBatchVariables = {
	body: Schemas.SMSMessageBatchSchema
	pathParams: UpdateSmsMessageBatchPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSmsMessageBatch = (
	variables: UpdateSmsMessageBatchVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSmsMessageBatchError,
		Schemas.SMSMessageBatchSchema,
		{},
		{},
		UpdateSmsMessageBatchPathParams
	>({
		url: '/api/sms-message-batches/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateSmsMessageBatch = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSmsMessageBatchError,
			UpdateSmsMessageBatchVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSmsMessageBatchError,
		UpdateSmsMessageBatchVariables
	>(
		(variables: UpdateSmsMessageBatchVariables) =>
			fetchUpdateSmsMessageBatch({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSmsMessageError = Fetcher.ErrorWrapper<undefined>

export type CreateSmsMessageVariables = {
	body: Schemas.SMSMessageCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSmsMessage = (
	variables: CreateSmsMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSmsMessageError,
		Schemas.SMSMessageCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/sms-messages/', method: 'post', ...variables, signal })

export const useCreateSmsMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSmsMessageError,
			CreateSmsMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSmsMessageError,
		CreateSmsMessageVariables
	>(
		(variables: CreateSmsMessageVariables) =>
			fetchCreateSmsMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSmsMessagesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSmsMessagesError = Fetcher.ErrorWrapper<undefined>

export type ListSmsMessagesVariables = {
	queryParams?: ListSmsMessagesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSmsMessages = (
	variables: ListSmsMessagesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSMSMessageSchema,
		ListSmsMessagesError,
		undefined,
		{},
		ListSmsMessagesQueryParams,
		{}
	>({ url: '/api/sms-messages/', method: 'get', ...variables, signal })

export const useListSmsMessages = <TData = Schemas.PagedSMSMessageSchema>(
	variables: ListSmsMessagesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSMSMessageSchema,
			ListSmsMessagesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSMSMessageSchema,
		ListSmsMessagesError,
		TData
	>(
		queryKeyFn({
			path: '/api/sms-messages/',
			operationId: 'listSmsMessages',
			variables
		}),
		({ signal }) =>
			fetchListSmsMessages({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetSmsMessageDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSmsMessageDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSmsMessageDetailsVariables = {
	pathParams: GetSmsMessageDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSmsMessageDetails = (
	variables: GetSmsMessageDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SMSMessageSchema,
		GetSmsMessageDetailsError,
		undefined,
		{},
		{},
		GetSmsMessageDetailsPathParams
	>({ url: '/api/sms-messages/{id}/', method: 'get', ...variables, signal })

export const useGetSmsMessageDetails = <TData = Schemas.SMSMessageSchema>(
	variables: GetSmsMessageDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SMSMessageSchema,
			GetSmsMessageDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SMSMessageSchema,
		GetSmsMessageDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/sms-messages/{id}/',
			operationId: 'getSmsMessageDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSmsMessageDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSmsMessagePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSmsMessageError = Fetcher.ErrorWrapper<undefined>

export type UpdateSmsMessageVariables = {
	body: Schemas.SMSMessageSchema
	pathParams: UpdateSmsMessagePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSmsMessage = (
	variables: UpdateSmsMessageVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSmsMessageError,
		Schemas.SMSMessageSchema,
		{},
		{},
		UpdateSmsMessagePathParams
	>({ url: '/api/sms-messages/{id}/', method: 'put', ...variables, signal })

export const useUpdateSmsMessage = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSmsMessageError,
			UpdateSmsMessageVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSmsMessageError,
		UpdateSmsMessageVariables
	>(
		(variables: UpdateSmsMessageVariables) =>
			fetchUpdateSmsMessage({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateStorageCustomerError = Fetcher.ErrorWrapper<undefined>

export type CreateStorageCustomerVariables = {
	body: Schemas.StorageCustomerCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStorageCustomer = (
	variables: CreateStorageCustomerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageCustomerError,
		Schemas.StorageCustomerCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/storage-customers/', method: 'post', ...variables, signal })

export const useCreateStorageCustomer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageCustomerError,
			CreateStorageCustomerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageCustomerError,
		CreateStorageCustomerVariables
	>(
		(variables: CreateStorageCustomerVariables) =>
			fetchCreateStorageCustomer({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStorageCustomersQueryParams = {
	/**
	 * @default ALL
	 */
	storage_customer_type?: 'CURRENT_CUSTOMER' | 'FORMER_CUSTOMER' | 'ALL'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageCustomersError = Fetcher.ErrorWrapper<undefined>

export type ListStorageCustomersVariables = {
	queryParams?: ListStorageCustomersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageCustomers = (
	variables: ListStorageCustomersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStorageCustomerDetailSchema,
		ListStorageCustomersError,
		undefined,
		{},
		ListStorageCustomersQueryParams,
		{}
	>({ url: '/api/storage-customers/', method: 'get', ...variables, signal })

export const useListStorageCustomers = <
	TData = Schemas.PagedStorageCustomerDetailSchema
>(
	variables: ListStorageCustomersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStorageCustomerDetailSchema,
			ListStorageCustomersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStorageCustomerDetailSchema,
		ListStorageCustomersError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-customers/',
			operationId: 'listStorageCustomers',
			variables
		}),
		({ signal }) =>
			fetchListStorageCustomers({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageCustomerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageCustomerError = Fetcher.ErrorWrapper<undefined>

export type DeleteStorageCustomerVariables = {
	pathParams: DeleteStorageCustomerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageCustomer = (
	variables: DeleteStorageCustomerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageCustomerError,
		undefined,
		{},
		{},
		DeleteStorageCustomerPathParams
	>({
		url: '/api/storage-customers/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageCustomer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageCustomerError,
			DeleteStorageCustomerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageCustomerError,
		DeleteStorageCustomerVariables
	>(
		(variables: DeleteStorageCustomerVariables) =>
			fetchDeleteStorageCustomer({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetStorageCustomerDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageCustomerDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStorageCustomerDetailsVariables = {
	pathParams: GetStorageCustomerDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageCustomerDetails = (
	variables: GetStorageCustomerDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageCustomerDetailSchema,
		GetStorageCustomerDetailsError,
		undefined,
		{},
		{},
		GetStorageCustomerDetailsPathParams
	>({
		url: '/api/storage-customers/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageCustomerDetails = <
	TData = Schemas.StorageCustomerDetailSchema
>(
	variables: GetStorageCustomerDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageCustomerDetailSchema,
			GetStorageCustomerDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageCustomerDetailSchema,
		GetStorageCustomerDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-customers/{id}/',
			operationId: 'getStorageCustomerDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageCustomerDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageCustomerPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageCustomerError = Fetcher.ErrorWrapper<undefined>

export type UpdateStorageCustomerVariables = {
	body: Schemas.StorageCustomerSchema
	pathParams: UpdateStorageCustomerPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageCustomer = (
	variables: UpdateStorageCustomerVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageCustomerError,
		Schemas.StorageCustomerSchema,
		{},
		{},
		UpdateStorageCustomerPathParams
	>({
		url: '/api/storage-customers/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageCustomer = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageCustomerError,
			UpdateStorageCustomerVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageCustomerError,
		UpdateStorageCustomerVariables
	>(
		(variables: UpdateStorageCustomerVariables) =>
			fetchUpdateStorageCustomer({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateStorageFacilityGateError = Fetcher.ErrorWrapper<undefined>

export type CreateStorageFacilityGateVariables = {
	body: Schemas.StorageFacilityGateCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStorageFacilityGate = (
	variables: CreateStorageFacilityGateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageFacilityGateError,
		Schemas.StorageFacilityGateCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/storage-facility-gates/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateStorageFacilityGate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageFacilityGateError,
			CreateStorageFacilityGateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageFacilityGateError,
		CreateStorageFacilityGateVariables
	>(
		(variables: CreateStorageFacilityGateVariables) =>
			fetchCreateStorageFacilityGate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStorageFacilityGatesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageFacilityGatesError = Fetcher.ErrorWrapper<undefined>

export type ListStorageFacilityGatesVariables = {
	queryParams?: ListStorageFacilityGatesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageFacilityGates = (
	variables: ListStorageFacilityGatesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStorageFacilityGateDetailSchema,
		ListStorageFacilityGatesError,
		undefined,
		{},
		ListStorageFacilityGatesQueryParams,
		{}
	>({
		url: '/api/storage-facility-gates/',
		method: 'get',
		...variables,
		signal
	})

export const useListStorageFacilityGates = <
	TData = Schemas.PagedStorageFacilityGateDetailSchema
>(
	variables: ListStorageFacilityGatesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStorageFacilityGateDetailSchema,
			ListStorageFacilityGatesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStorageFacilityGateDetailSchema,
		ListStorageFacilityGatesError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-facility-gates/',
			operationId: 'listStorageFacilityGates',
			variables
		}),
		({ signal }) =>
			fetchListStorageFacilityGates(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageFacilityGatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageFacilityGateError = Fetcher.ErrorWrapper<undefined>

export type DeleteStorageFacilityGateVariables = {
	pathParams: DeleteStorageFacilityGatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageFacilityGate = (
	variables: DeleteStorageFacilityGateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageFacilityGateError,
		undefined,
		{},
		{},
		DeleteStorageFacilityGatePathParams
	>({
		url: '/api/storage-facility-gates/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageFacilityGate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageFacilityGateError,
			DeleteStorageFacilityGateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageFacilityGateError,
		DeleteStorageFacilityGateVariables
	>(
		(variables: DeleteStorageFacilityGateVariables) =>
			fetchDeleteStorageFacilityGate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetStorageFacilityGateDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageFacilityGateDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStorageFacilityGateDetailsVariables = {
	pathParams: GetStorageFacilityGateDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageFacilityGateDetails = (
	variables: GetStorageFacilityGateDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageFacilityGateDetailSchema,
		GetStorageFacilityGateDetailsError,
		undefined,
		{},
		{},
		GetStorageFacilityGateDetailsPathParams
	>({
		url: '/api/storage-facility-gates/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageFacilityGateDetails = <
	TData = Schemas.StorageFacilityGateDetailSchema
>(
	variables: GetStorageFacilityGateDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageFacilityGateDetailSchema,
			GetStorageFacilityGateDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageFacilityGateDetailSchema,
		GetStorageFacilityGateDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-facility-gates/{id}/',
			operationId: 'getStorageFacilityGateDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageFacilityGateDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageFacilityGatePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageFacilityGateError = Fetcher.ErrorWrapper<undefined>

export type UpdateStorageFacilityGateVariables = {
	body: Schemas.StorageFacilityGateUpdateSchema
	pathParams: UpdateStorageFacilityGatePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageFacilityGate = (
	variables: UpdateStorageFacilityGateVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageFacilityGateError,
		Schemas.StorageFacilityGateUpdateSchema,
		{},
		{},
		UpdateStorageFacilityGatePathParams
	>({
		url: '/api/storage-facility-gates/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageFacilityGate = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageFacilityGateError,
			UpdateStorageFacilityGateVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageFacilityGateError,
		UpdateStorageFacilityGateVariables
	>(
		(variables: UpdateStorageFacilityGateVariables) =>
			fetchUpdateStorageFacilityGate({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateStorageUnitInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type CreateStorageUnitInvoiceLineItemVariables = {
	body: Schemas.StorageUnitInvoiceLineItemCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStorageUnitInvoiceLineItem = (
	variables: CreateStorageUnitInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageUnitInvoiceLineItemError,
		Schemas.StorageUnitInvoiceLineItemCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/storage-unit-invoice-line-items/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateStorageUnitInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageUnitInvoiceLineItemError,
			CreateStorageUnitInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageUnitInvoiceLineItemError,
		CreateStorageUnitInvoiceLineItemVariables
	>(
		(variables: CreateStorageUnitInvoiceLineItemVariables) =>
			fetchCreateStorageUnitInvoiceLineItem({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type ListStorageUnitInvoiceLineItemsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageUnitInvoiceLineItemsError =
	Fetcher.ErrorWrapper<undefined>

export type ListStorageUnitInvoiceLineItemsVariables = {
	queryParams?: ListStorageUnitInvoiceLineItemsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageUnitInvoiceLineItems = (
	variables: ListStorageUnitInvoiceLineItemsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStorageUnitInvoiceLineItemDetailSchema,
		ListStorageUnitInvoiceLineItemsError,
		undefined,
		{},
		ListStorageUnitInvoiceLineItemsQueryParams,
		{}
	>({
		url: '/api/storage-unit-invoice-line-items/',
		method: 'get',
		...variables,
		signal
	})

export const useListStorageUnitInvoiceLineItems = <
	TData = Schemas.PagedStorageUnitInvoiceLineItemDetailSchema
>(
	variables: ListStorageUnitInvoiceLineItemsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStorageUnitInvoiceLineItemDetailSchema,
			ListStorageUnitInvoiceLineItemsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStorageUnitInvoiceLineItemDetailSchema,
		ListStorageUnitInvoiceLineItemsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-invoice-line-items/',
			operationId: 'listStorageUnitInvoiceLineItems',
			variables
		}),
		({ signal }) =>
			fetchListStorageUnitInvoiceLineItems(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageUnitInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageUnitInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type DeleteStorageUnitInvoiceLineItemVariables = {
	pathParams: DeleteStorageUnitInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageUnitInvoiceLineItem = (
	variables: DeleteStorageUnitInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageUnitInvoiceLineItemError,
		undefined,
		{},
		{},
		DeleteStorageUnitInvoiceLineItemPathParams
	>({
		url: '/api/storage-unit-invoice-line-items/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageUnitInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageUnitInvoiceLineItemError,
			DeleteStorageUnitInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageUnitInvoiceLineItemError,
		DeleteStorageUnitInvoiceLineItemVariables
	>(
		(variables: DeleteStorageUnitInvoiceLineItemVariables) =>
			fetchDeleteStorageUnitInvoiceLineItem({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type GetStorageUnitInvoiceLineItemDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageUnitInvoiceLineItemDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetStorageUnitInvoiceLineItemDetailsVariables = {
	pathParams: GetStorageUnitInvoiceLineItemDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageUnitInvoiceLineItemDetails = (
	variables: GetStorageUnitInvoiceLineItemDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageUnitInvoiceLineItemSchema,
		GetStorageUnitInvoiceLineItemDetailsError,
		undefined,
		{},
		{},
		GetStorageUnitInvoiceLineItemDetailsPathParams
	>({
		url: '/api/storage-unit-invoice-line-items/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageUnitInvoiceLineItemDetails = <
	TData = Schemas.StorageUnitInvoiceLineItemSchema
>(
	variables: GetStorageUnitInvoiceLineItemDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageUnitInvoiceLineItemSchema,
			GetStorageUnitInvoiceLineItemDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageUnitInvoiceLineItemSchema,
		GetStorageUnitInvoiceLineItemDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-invoice-line-items/{id}/',
			operationId: 'getStorageUnitInvoiceLineItemDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageUnitInvoiceLineItemDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageUnitInvoiceLineItemPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageUnitInvoiceLineItemError =
	Fetcher.ErrorWrapper<undefined>

export type UpdateStorageUnitInvoiceLineItemVariables = {
	body: Schemas.StorageUnitInvoiceLineItemSchema
	pathParams: UpdateStorageUnitInvoiceLineItemPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageUnitInvoiceLineItem = (
	variables: UpdateStorageUnitInvoiceLineItemVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageUnitInvoiceLineItemError,
		Schemas.StorageUnitInvoiceLineItemSchema,
		{},
		{},
		UpdateStorageUnitInvoiceLineItemPathParams
	>({
		url: '/api/storage-unit-invoice-line-items/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageUnitInvoiceLineItem = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageUnitInvoiceLineItemError,
			UpdateStorageUnitInvoiceLineItemVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageUnitInvoiceLineItemError,
		UpdateStorageUnitInvoiceLineItemVariables
	>(
		(variables: UpdateStorageUnitInvoiceLineItemVariables) =>
			fetchUpdateStorageUnitInvoiceLineItem({
				...fetcherOptions,
				...variables
			}),
		options
	)
}

export type CreateStorageUnitInvoiceError = Fetcher.ErrorWrapper<undefined>

export type CreateStorageUnitInvoiceVariables = {
	body: Schemas.StorageUnitInvoiceCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStorageUnitInvoice = (
	variables: CreateStorageUnitInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageUnitInvoiceError,
		Schemas.StorageUnitInvoiceCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/storage-unit-invoices/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateStorageUnitInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageUnitInvoiceError,
			CreateStorageUnitInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageUnitInvoiceError,
		CreateStorageUnitInvoiceVariables
	>(
		(variables: CreateStorageUnitInvoiceVariables) =>
			fetchCreateStorageUnitInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStorageUnitInvoicesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageUnitInvoicesError = Fetcher.ErrorWrapper<undefined>

export type ListStorageUnitInvoicesVariables = {
	queryParams?: ListStorageUnitInvoicesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageUnitInvoices = (
	variables: ListStorageUnitInvoicesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedListStorageUnitInvoicesSchema,
		ListStorageUnitInvoicesError,
		undefined,
		{},
		ListStorageUnitInvoicesQueryParams,
		{}
	>({
		url: '/api/storage-unit-invoices/',
		method: 'get',
		...variables,
		signal
	})

export const useListStorageUnitInvoices = <
	TData = Schemas.PagedListStorageUnitInvoicesSchema
>(
	variables: ListStorageUnitInvoicesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedListStorageUnitInvoicesSchema,
			ListStorageUnitInvoicesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedListStorageUnitInvoicesSchema,
		ListStorageUnitInvoicesError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-invoices/',
			operationId: 'listStorageUnitInvoices',
			variables
		}),
		({ signal }) =>
			fetchListStorageUnitInvoices({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageUnitInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageUnitInvoiceError = Fetcher.ErrorWrapper<undefined>

export type DeleteStorageUnitInvoiceVariables = {
	pathParams: DeleteStorageUnitInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageUnitInvoice = (
	variables: DeleteStorageUnitInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageUnitInvoiceError,
		undefined,
		{},
		{},
		DeleteStorageUnitInvoicePathParams
	>({
		url: '/api/storage-unit-invoices/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageUnitInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageUnitInvoiceError,
			DeleteStorageUnitInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageUnitInvoiceError,
		DeleteStorageUnitInvoiceVariables
	>(
		(variables: DeleteStorageUnitInvoiceVariables) =>
			fetchDeleteStorageUnitInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetStorageUnitInvoiceDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageUnitInvoiceDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStorageUnitInvoiceDetailsVariables = {
	pathParams: GetStorageUnitInvoiceDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageUnitInvoiceDetails = (
	variables: GetStorageUnitInvoiceDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageUnitInvoiceDetailSchema,
		GetStorageUnitInvoiceDetailsError,
		undefined,
		{},
		{},
		GetStorageUnitInvoiceDetailsPathParams
	>({
		url: '/api/storage-unit-invoices/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageUnitInvoiceDetails = <
	TData = Schemas.StorageUnitInvoiceDetailSchema
>(
	variables: GetStorageUnitInvoiceDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageUnitInvoiceDetailSchema,
			GetStorageUnitInvoiceDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageUnitInvoiceDetailSchema,
		GetStorageUnitInvoiceDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-invoices/{id}/',
			operationId: 'getStorageUnitInvoiceDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageUnitInvoiceDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageUnitInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageUnitInvoiceError = Fetcher.ErrorWrapper<undefined>

export type UpdateStorageUnitInvoiceVariables = {
	body: Schemas.StorageUnitInvoiceSchema
	pathParams: UpdateStorageUnitInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageUnitInvoice = (
	variables: UpdateStorageUnitInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageUnitInvoiceError,
		Schemas.StorageUnitInvoiceSchema,
		{},
		{},
		UpdateStorageUnitInvoicePathParams
	>({
		url: '/api/storage-unit-invoices/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageUnitInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageUnitInvoiceError,
			UpdateStorageUnitInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageUnitInvoiceError,
		UpdateStorageUnitInvoiceVariables
	>(
		(variables: UpdateStorageUnitInvoiceVariables) =>
			fetchUpdateStorageUnitInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateStorageUnitLeaseError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type CreateStorageUnitLeaseVariables = {
	body: Schemas.CreateStorageUnitLeaseSchema
} & RentalizeContext['fetcherOptions']

/**
 * Creates a new Lease objects in our database.
 */
export const fetchCreateStorageUnitLease = (
	variables: CreateStorageUnitLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageUnitLeaseError,
		Schemas.CreateStorageUnitLeaseSchema,
		{},
		{},
		{}
	>({ url: '/api/storage-unit-leases/', method: 'post', ...variables, signal })

/**
 * Creates a new Lease objects in our database.
 */
export const useCreateStorageUnitLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageUnitLeaseError,
			CreateStorageUnitLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageUnitLeaseError,
		CreateStorageUnitLeaseVariables
	>(
		(variables: CreateStorageUnitLeaseVariables) =>
			fetchCreateStorageUnitLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStorageUnitLeasesQueryParams = {
	/**
	 * @default ALL
	 */
	lease_type?: 'CURRENT_LEASE' | 'FORMER_LEASE' | 'FUTURE_LEASE' | 'ALL'
	days_before_expiration?: number | null
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageUnitLeasesError = Fetcher.ErrorWrapper<undefined>

export type ListStorageUnitLeasesVariables = {
	queryParams?: ListStorageUnitLeasesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageUnitLeases = (
	variables: ListStorageUnitLeasesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStorageUnitLeaseDetailSchema,
		ListStorageUnitLeasesError,
		undefined,
		{},
		ListStorageUnitLeasesQueryParams,
		{}
	>({ url: '/api/storage-unit-leases/', method: 'get', ...variables, signal })

export const useListStorageUnitLeases = <
	TData = Schemas.PagedStorageUnitLeaseDetailSchema
>(
	variables: ListStorageUnitLeasesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStorageUnitLeaseDetailSchema,
			ListStorageUnitLeasesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStorageUnitLeaseDetailSchema,
		ListStorageUnitLeasesError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-leases/',
			operationId: 'listStorageUnitLeases',
			variables
		}),
		({ signal }) =>
			fetchListStorageUnitLeases({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageUnitLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageUnitLeaseError = Fetcher.ErrorWrapper<undefined>

export type DeleteStorageUnitLeaseVariables = {
	pathParams: DeleteStorageUnitLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageUnitLease = (
	variables: DeleteStorageUnitLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageUnitLeaseError,
		undefined,
		{},
		{},
		DeleteStorageUnitLeasePathParams
	>({
		url: '/api/storage-unit-leases/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageUnitLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageUnitLeaseError,
			DeleteStorageUnitLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageUnitLeaseError,
		DeleteStorageUnitLeaseVariables
	>(
		(variables: DeleteStorageUnitLeaseVariables) =>
			fetchDeleteStorageUnitLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetStorageUnitLeaseDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageUnitLeaseDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStorageUnitLeaseDetailsVariables = {
	pathParams: GetStorageUnitLeaseDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageUnitLeaseDetails = (
	variables: GetStorageUnitLeaseDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageUnitLeaseDetailSchema,
		GetStorageUnitLeaseDetailsError,
		undefined,
		{},
		{},
		GetStorageUnitLeaseDetailsPathParams
	>({
		url: '/api/storage-unit-leases/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageUnitLeaseDetails = <
	TData = Schemas.StorageUnitLeaseDetailSchema
>(
	variables: GetStorageUnitLeaseDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageUnitLeaseDetailSchema,
			GetStorageUnitLeaseDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageUnitLeaseDetailSchema,
		GetStorageUnitLeaseDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-leases/{id}/',
			operationId: 'getStorageUnitLeaseDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageUnitLeaseDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageUnitLeasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageUnitLeaseError = Fetcher.ErrorWrapper<undefined>

export type UpdateStorageUnitLeaseVariables = {
	body: Schemas.CreateStorageUnitLeaseSchema
	pathParams: UpdateStorageUnitLeasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageUnitLease = (
	variables: UpdateStorageUnitLeaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageUnitLeaseError,
		Schemas.CreateStorageUnitLeaseSchema,
		{},
		{},
		UpdateStorageUnitLeasePathParams
	>({
		url: '/api/storage-unit-leases/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageUnitLease = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageUnitLeaseError,
			UpdateStorageUnitLeaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageUnitLeaseError,
		UpdateStorageUnitLeaseVariables
	>(
		(variables: UpdateStorageUnitLeaseVariables) =>
			fetchUpdateStorageUnitLease({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateStorageUnitTypeError = Fetcher.ErrorWrapper<undefined>

export type CreateStorageUnitTypeVariables = {
	body: Schemas.StorageUnitTypeSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStorageUnitType = (
	variables: CreateStorageUnitTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStorageUnitTypeError,
		Schemas.StorageUnitTypeSchema,
		{},
		{},
		{}
	>({ url: '/api/storage-unit-types/', method: 'post', ...variables, signal })

export const useCreateStorageUnitType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStorageUnitTypeError,
			CreateStorageUnitTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStorageUnitTypeError,
		CreateStorageUnitTypeVariables
	>(
		(variables: CreateStorageUnitTypeVariables) =>
			fetchCreateStorageUnitType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStorageUnitTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStorageUnitTypesError = Fetcher.ErrorWrapper<undefined>

export type ListStorageUnitTypesVariables = {
	queryParams?: ListStorageUnitTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStorageUnitTypes = (
	variables: ListStorageUnitTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStorageUnitTypeDetailSchema,
		ListStorageUnitTypesError,
		undefined,
		{},
		ListStorageUnitTypesQueryParams,
		{}
	>({ url: '/api/storage-unit-types/', method: 'get', ...variables, signal })

export const useListStorageUnitTypes = <
	TData = Schemas.PagedStorageUnitTypeDetailSchema
>(
	variables: ListStorageUnitTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStorageUnitTypeDetailSchema,
			ListStorageUnitTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStorageUnitTypeDetailSchema,
		ListStorageUnitTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-types/',
			operationId: 'listStorageUnitTypes',
			variables
		}),
		({ signal }) =>
			fetchListStorageUnitTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteStorageUnitTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteStorageUnitTypeError = Fetcher.ErrorWrapper<undefined>

export type DeleteStorageUnitTypeVariables = {
	pathParams: DeleteStorageUnitTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteStorageUnitType = (
	variables: DeleteStorageUnitTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteStorageUnitTypeError,
		undefined,
		{},
		{},
		DeleteStorageUnitTypePathParams
	>({
		url: '/api/storage-unit-types/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteStorageUnitType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteStorageUnitTypeError,
			DeleteStorageUnitTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteStorageUnitTypeError,
		DeleteStorageUnitTypeVariables
	>(
		(variables: DeleteStorageUnitTypeVariables) =>
			fetchDeleteStorageUnitType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetStorageUnitTypeDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStorageUnitTypeDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStorageUnitTypeDetailsVariables = {
	pathParams: GetStorageUnitTypeDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStorageUnitTypeDetails = (
	variables: GetStorageUnitTypeDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StorageUnitTypeDetailSchema,
		GetStorageUnitTypeDetailsError,
		undefined,
		{},
		{},
		GetStorageUnitTypeDetailsPathParams
	>({
		url: '/api/storage-unit-types/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStorageUnitTypeDetails = <
	TData = Schemas.StorageUnitTypeDetailSchema
>(
	variables: GetStorageUnitTypeDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StorageUnitTypeDetailSchema,
			GetStorageUnitTypeDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StorageUnitTypeDetailSchema,
		GetStorageUnitTypeDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/storage-unit-types/{id}/',
			operationId: 'getStorageUnitTypeDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStorageUnitTypeDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStorageUnitTypePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStorageUnitTypeError = Fetcher.ErrorWrapper<undefined>

export type UpdateStorageUnitTypeVariables = {
	body: Schemas.StorageUnitTypeSchema
	pathParams: UpdateStorageUnitTypePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStorageUnitType = (
	variables: UpdateStorageUnitTypeVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStorageUnitTypeError,
		Schemas.StorageUnitTypeSchema,
		{},
		{},
		UpdateStorageUnitTypePathParams
	>({
		url: '/api/storage-unit-types/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStorageUnitType = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStorageUnitTypeError,
			UpdateStorageUnitTypeVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStorageUnitTypeError,
		UpdateStorageUnitTypeVariables
	>(
		(variables: UpdateStorageUnitTypeVariables) =>
			fetchUpdateStorageUnitType({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateAccountOnboardingSessionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateAccountOnboardingSessionError =
	Fetcher.ErrorWrapper<undefined>

export type GenerateAccountOnboardingSessionVariables = {
	pathParams: GenerateAccountOnboardingSessionPathParams
} & RentalizeContext['fetcherOptions']

/**
 * Generates a Stripe account onboarindg session for one of the PropertyManagementCompany's Stripe Connect account.
 */
export const fetchGenerateAccountOnboardingSession = (
	variables: GenerateAccountOnboardingSessionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.AccountSessionSchema,
		GenerateAccountOnboardingSessionError,
		undefined,
		{},
		{},
		GenerateAccountOnboardingSessionPathParams
	>({
		url: '/api/stripe/stripe-accounts/{id}/account-onboarding-session/',
		method: 'get',
		...variables,
		signal
	})

/**
 * Generates a Stripe account onboarindg session for one of the PropertyManagementCompany's Stripe Connect account.
 */
export const useGenerateAccountOnboardingSession = <
	TData = Schemas.AccountSessionSchema
>(
	variables: GenerateAccountOnboardingSessionVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.AccountSessionSchema,
			GenerateAccountOnboardingSessionError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.AccountSessionSchema,
		GenerateAccountOnboardingSessionError,
		TData
	>(
		queryKeyFn({
			path: '/api/stripe/stripe-accounts/{id}/account-onboarding-session/',
			operationId: 'generateAccountOnboardingSession',
			variables
		}),
		({ signal }) =>
			fetchGenerateAccountOnboardingSession(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateStripeAccountError = Fetcher.ErrorWrapper<undefined>

export type CreateStripeAccountVariables = {
	body: Schemas.StripeAccountCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateStripeAccount = (
	variables: CreateStripeAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateStripeAccountError,
		Schemas.StripeAccountCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/stripe/stripe-accounts/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateStripeAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateStripeAccountError,
			CreateStripeAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateStripeAccountError,
		CreateStripeAccountVariables
	>(
		(variables: CreateStripeAccountVariables) =>
			fetchCreateStripeAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListStripeAccountsQueryParams = {
	property_owner_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListStripeAccountsError = Fetcher.ErrorWrapper<undefined>

export type ListStripeAccountsVariables = {
	queryParams?: ListStripeAccountsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListStripeAccounts = (
	variables: ListStripeAccountsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedStripeAccountDetailSchema,
		ListStripeAccountsError,
		undefined,
		{},
		ListStripeAccountsQueryParams,
		{}
	>({
		url: '/api/stripe/stripe-accounts/',
		method: 'get',
		...variables,
		signal
	})

export const useListStripeAccounts = <
	TData = Schemas.PagedStripeAccountDetailSchema
>(
	variables: ListStripeAccountsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedStripeAccountDetailSchema,
			ListStripeAccountsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedStripeAccountDetailSchema,
		ListStripeAccountsError,
		TData
	>(
		queryKeyFn({
			path: '/api/stripe/stripe-accounts/',
			operationId: 'listStripeAccounts',
			variables
		}),
		({ signal }) =>
			fetchListStripeAccounts({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetStripeAccountDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetStripeAccountDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetStripeAccountDetailsVariables = {
	pathParams: GetStripeAccountDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetStripeAccountDetails = (
	variables: GetStripeAccountDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.StripeAccountDetailSchema,
		GetStripeAccountDetailsError,
		undefined,
		{},
		{},
		GetStripeAccountDetailsPathParams
	>({
		url: '/api/stripe/stripe-accounts/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetStripeAccountDetails = <
	TData = Schemas.StripeAccountDetailSchema
>(
	variables: GetStripeAccountDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.StripeAccountDetailSchema,
			GetStripeAccountDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.StripeAccountDetailSchema,
		GetStripeAccountDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/stripe/stripe-accounts/{id}/',
			operationId: 'getStripeAccountDetails',
			variables
		}),
		({ signal }) =>
			fetchGetStripeAccountDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateStripeAccountPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateStripeAccountError = Fetcher.ErrorWrapper<undefined>

export type UpdateStripeAccountVariables = {
	body: Schemas.StripeAccountSchema
	pathParams: UpdateStripeAccountPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateStripeAccount = (
	variables: UpdateStripeAccountVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateStripeAccountError,
		Schemas.StripeAccountSchema,
		{},
		{},
		UpdateStripeAccountPathParams
	>({
		url: '/api/stripe/stripe-accounts/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateStripeAccount = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateStripeAccountError,
			UpdateStripeAccountVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateStripeAccountError,
		UpdateStripeAccountVariables
	>(
		(variables: UpdateStripeAccountVariables) =>
			fetchUpdateStripeAccount({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateSupportTicketError = Fetcher.ErrorWrapper<undefined>

export type CreateSupportTicketVariables = {
	body: Schemas.SupportTicketCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateSupportTicket = (
	variables: CreateSupportTicketVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateSupportTicketError,
		Schemas.SupportTicketCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/support-tickets/', method: 'post', ...variables, signal })

export const useCreateSupportTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateSupportTicketError,
			CreateSupportTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateSupportTicketError,
		CreateSupportTicketVariables
	>(
		(variables: CreateSupportTicketVariables) =>
			fetchCreateSupportTicket({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListSupportTicketsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListSupportTicketsError = Fetcher.ErrorWrapper<undefined>

export type ListSupportTicketsVariables = {
	queryParams?: ListSupportTicketsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListSupportTickets = (
	variables: ListSupportTicketsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedSupportTicketSchema,
		ListSupportTicketsError,
		undefined,
		{},
		ListSupportTicketsQueryParams,
		{}
	>({ url: '/api/support-tickets/', method: 'get', ...variables, signal })

export const useListSupportTickets = <TData = Schemas.PagedSupportTicketSchema>(
	variables: ListSupportTicketsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedSupportTicketSchema,
			ListSupportTicketsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedSupportTicketSchema,
		ListSupportTicketsError,
		TData
	>(
		queryKeyFn({
			path: '/api/support-tickets/',
			operationId: 'listSupportTickets',
			variables
		}),
		({ signal }) =>
			fetchListSupportTickets({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteSupportTicketPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteSupportTicketError = Fetcher.ErrorWrapper<undefined>

export type DeleteSupportTicketVariables = {
	pathParams: DeleteSupportTicketPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteSupportTicket = (
	variables: DeleteSupportTicketVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteSupportTicketError,
		undefined,
		{},
		{},
		DeleteSupportTicketPathParams
	>({
		url: '/api/support-tickets/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteSupportTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteSupportTicketError,
			DeleteSupportTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteSupportTicketError,
		DeleteSupportTicketVariables
	>(
		(variables: DeleteSupportTicketVariables) =>
			fetchDeleteSupportTicket({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetSupportTicketDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetSupportTicketDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetSupportTicketDetailsVariables = {
	pathParams: GetSupportTicketDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetSupportTicketDetails = (
	variables: GetSupportTicketDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.SupportTicketSchema,
		GetSupportTicketDetailsError,
		undefined,
		{},
		{},
		GetSupportTicketDetailsPathParams
	>({ url: '/api/support-tickets/{id}/', method: 'get', ...variables, signal })

export const useGetSupportTicketDetails = <TData = Schemas.SupportTicketSchema>(
	variables: GetSupportTicketDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.SupportTicketSchema,
			GetSupportTicketDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.SupportTicketSchema,
		GetSupportTicketDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/support-tickets/{id}/',
			operationId: 'getSupportTicketDetails',
			variables
		}),
		({ signal }) =>
			fetchGetSupportTicketDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateSupportTicketPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateSupportTicketError = Fetcher.ErrorWrapper<undefined>

export type UpdateSupportTicketVariables = {
	body: Schemas.SupportTicketSchema
	pathParams: UpdateSupportTicketPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateSupportTicket = (
	variables: UpdateSupportTicketVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateSupportTicketError,
		Schemas.SupportTicketSchema,
		{},
		{},
		UpdateSupportTicketPathParams
	>({ url: '/api/support-tickets/{id}/', method: 'put', ...variables, signal })

export const useUpdateSupportTicket = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateSupportTicketError,
			UpdateSupportTicketVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateSupportTicketError,
		UpdateSupportTicketVariables
	>(
		(variables: UpdateSupportTicketVariables) =>
			fetchUpdateSupportTicket({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateTaskError = Fetcher.ErrorWrapper<undefined>

export type CreateTaskVariables = {
	body: Schemas.TaskCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateTask = (
	variables: CreateTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateTaskError,
		Schemas.TaskCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/tasks/', method: 'post', ...variables, signal })

export const useCreateTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateTaskError,
			CreateTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateTaskError,
		CreateTaskVariables
	>(
		(variables: CreateTaskVariables) =>
			fetchCreateTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListTasksQueryParams = {
	/**
	 * @default ALL_TASKS
	 */
	task_filter?: 'ALL_TASKS' | 'MY_TASKS' | 'UNASSIGNED_TASKS'
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListTasksError = Fetcher.ErrorWrapper<undefined>

export type ListTasksVariables = {
	queryParams?: ListTasksQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListTasks = (
	variables: ListTasksVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedTaskDetailSchema,
		ListTasksError,
		undefined,
		{},
		ListTasksQueryParams,
		{}
	>({ url: '/api/tasks/', method: 'get', ...variables, signal })

export const useListTasks = <TData = Schemas.PagedTaskDetailSchema>(
	variables: ListTasksVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedTaskDetailSchema,
			ListTasksError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedTaskDetailSchema,
		ListTasksError,
		TData
	>(
		queryKeyFn({ path: '/api/tasks/', operationId: 'listTasks', variables }),
		({ signal }) => fetchListTasks({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteTaskPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteTaskError = Fetcher.ErrorWrapper<undefined>

export type DeleteTaskVariables = {
	pathParams: DeleteTaskPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteTask = (
	variables: DeleteTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteTaskError,
		undefined,
		{},
		{},
		DeleteTaskPathParams
	>({ url: '/api/tasks/{id}/', method: 'delete', ...variables, signal })

export const useDeleteTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteTaskError,
			DeleteTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteTaskError,
		DeleteTaskVariables
	>(
		(variables: DeleteTaskVariables) =>
			fetchDeleteTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetTaskDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetTaskDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetTaskDetailsVariables = {
	pathParams: GetTaskDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetTaskDetails = (
	variables: GetTaskDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.TaskDetailSchema,
		GetTaskDetailsError,
		undefined,
		{},
		{},
		GetTaskDetailsPathParams
	>({ url: '/api/tasks/{id}/', method: 'get', ...variables, signal })

export const useGetTaskDetails = <TData = Schemas.TaskDetailSchema>(
	variables: GetTaskDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TaskDetailSchema,
			GetTaskDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.TaskDetailSchema,
		GetTaskDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/tasks/{id}/',
			operationId: 'getTaskDetails',
			variables
		}),
		({ signal }) =>
			fetchGetTaskDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateTaskPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateTaskError = Fetcher.ErrorWrapper<undefined>

export type UpdateTaskVariables = {
	body: Schemas.TaskDetailSchema
	pathParams: UpdateTaskPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateTask = (
	variables: UpdateTaskVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateTaskError,
		Schemas.TaskDetailSchema,
		{},
		{},
		UpdateTaskPathParams
	>({ url: '/api/tasks/{id}/', method: 'put', ...variables, signal })

export const useUpdateTask = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateTaskError,
			UpdateTaskVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateTaskError,
		UpdateTaskVariables
	>(
		(variables: UpdateTaskVariables) =>
			fetchUpdateTask({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateTimeTrackingEntryError = Fetcher.ErrorWrapper<undefined>

export type CreateTimeTrackingEntryVariables = {
	body: Schemas.TimeTrackingEntryCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateTimeTrackingEntry = (
	variables: CreateTimeTrackingEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateTimeTrackingEntryError,
		Schemas.TimeTrackingEntryCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/time-tracking-entries/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateTimeTrackingEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateTimeTrackingEntryError,
			CreateTimeTrackingEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateTimeTrackingEntryError,
		CreateTimeTrackingEntryVariables
	>(
		(variables: CreateTimeTrackingEntryVariables) =>
			fetchCreateTimeTrackingEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListTimeTrackingEntriesQueryParams = {
	maintenance_technician_id?: string | null
	work_order_id?: string | null
	invoice_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListTimeTrackingEntriesError = Fetcher.ErrorWrapper<undefined>

export type ListTimeTrackingEntriesVariables = {
	queryParams?: ListTimeTrackingEntriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListTimeTrackingEntries = (
	variables: ListTimeTrackingEntriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedTimeTrackingEntryOutSchema,
		ListTimeTrackingEntriesError,
		undefined,
		{},
		ListTimeTrackingEntriesQueryParams,
		{}
	>({
		url: '/api/time-tracking-entries/',
		method: 'get',
		...variables,
		signal
	})

export const useListTimeTrackingEntries = <
	TData = Schemas.PagedTimeTrackingEntryOutSchema
>(
	variables: ListTimeTrackingEntriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedTimeTrackingEntryOutSchema,
			ListTimeTrackingEntriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedTimeTrackingEntryOutSchema,
		ListTimeTrackingEntriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/time-tracking-entries/',
			operationId: 'listTimeTrackingEntries',
			variables
		}),
		({ signal }) =>
			fetchListTimeTrackingEntries({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteTimeTrackingEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteTimeTrackingEntryError = Fetcher.ErrorWrapper<undefined>

export type DeleteTimeTrackingEntryVariables = {
	pathParams: DeleteTimeTrackingEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteTimeTrackingEntry = (
	variables: DeleteTimeTrackingEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteTimeTrackingEntryError,
		undefined,
		{},
		{},
		DeleteTimeTrackingEntryPathParams
	>({
		url: '/api/time-tracking-entries/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteTimeTrackingEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteTimeTrackingEntryError,
			DeleteTimeTrackingEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteTimeTrackingEntryError,
		DeleteTimeTrackingEntryVariables
	>(
		(variables: DeleteTimeTrackingEntryVariables) =>
			fetchDeleteTimeTrackingEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetTimeTrackingEntryDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetTimeTrackingEntryDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetTimeTrackingEntryDetailsVariables = {
	pathParams: GetTimeTrackingEntryDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetTimeTrackingEntryDetails = (
	variables: GetTimeTrackingEntryDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.TimeTrackingEntryOutSchema,
		GetTimeTrackingEntryDetailsError,
		undefined,
		{},
		{},
		GetTimeTrackingEntryDetailsPathParams
	>({
		url: '/api/time-tracking-entries/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetTimeTrackingEntryDetails = <
	TData = Schemas.TimeTrackingEntryOutSchema
>(
	variables: GetTimeTrackingEntryDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.TimeTrackingEntryOutSchema,
			GetTimeTrackingEntryDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.TimeTrackingEntryOutSchema,
		GetTimeTrackingEntryDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/time-tracking-entries/{id}/',
			operationId: 'getTimeTrackingEntryDetails',
			variables
		}),
		({ signal }) =>
			fetchGetTimeTrackingEntryDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateTimeTrackingEntryPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateTimeTrackingEntryError = Fetcher.ErrorWrapper<undefined>

export type UpdateTimeTrackingEntryVariables = {
	body: Schemas.TimeTrackingEntrySchema
	pathParams: UpdateTimeTrackingEntryPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateTimeTrackingEntry = (
	variables: UpdateTimeTrackingEntryVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateTimeTrackingEntryError,
		Schemas.TimeTrackingEntrySchema,
		{},
		{},
		UpdateTimeTrackingEntryPathParams
	>({
		url: '/api/time-tracking-entries/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateTimeTrackingEntry = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateTimeTrackingEntryError,
			UpdateTimeTrackingEntryVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateTimeTrackingEntryError,
		UpdateTimeTrackingEntryVariables
	>(
		(variables: UpdateTimeTrackingEntryVariables) =>
			fetchUpdateTimeTrackingEntry({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetUserPermissionsError = Fetcher.ErrorWrapper<undefined>

export type GetUserPermissionsVariables = RentalizeContext['fetcherOptions']

export const fetchGetUserPermissions = (
	variables: GetUserPermissionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.UserPermissionsSchema,
		GetUserPermissionsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/user-permissions]/', method: 'get', ...variables, signal })

export const useGetUserPermissions = <TData = Schemas.UserPermissionsSchema>(
	variables: GetUserPermissionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UserPermissionsSchema,
			GetUserPermissionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.UserPermissionsSchema,
		GetUserPermissionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/user-permissions]/',
			operationId: 'getUserPermissions',
			variables
		}),
		({ signal }) =>
			fetchGetUserPermissions({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type GetUserSettingsError = Fetcher.ErrorWrapper<undefined>

export type GetUserSettingsVariables = RentalizeContext['fetcherOptions']

export const fetchGetUserSettings = (
	variables: GetUserSettingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.UserSettingsSchema,
		GetUserSettingsError,
		undefined,
		{},
		{},
		{}
	>({ url: '/api/user-settings/', method: 'get', ...variables, signal })

export const useGetUserSettings = <TData = Schemas.UserSettingsSchema>(
	variables: GetUserSettingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UserSettingsSchema,
			GetUserSettingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.UserSettingsSchema,
		GetUserSettingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/user-settings/',
			operationId: 'getUserSettings',
			variables
		}),
		({ signal }) =>
			fetchGetUserSettings({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateUserSettingsError = Fetcher.ErrorWrapper<undefined>

export type UpdateUserSettingsVariables = {
	body: Schemas.UserSettingsSchema
} & RentalizeContext['fetcherOptions']

export const fetchUpdateUserSettings = (
	variables: UpdateUserSettingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateUserSettingsError,
		Schemas.UserSettingsSchema,
		{},
		{},
		{}
	>({ url: '/api/user_settings/', method: 'put', ...variables, signal })

export const useUpdateUserSettings = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateUserSettingsError,
			UpdateUserSettingsVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateUserSettingsError,
		UpdateUserSettingsVariables
	>(
		(variables: UpdateUserSettingsVariables) =>
			fetchUpdateUserSettings({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateUtilityMeterReadingError = Fetcher.ErrorWrapper<undefined>

export type CreateUtilityMeterReadingVariables = {
	body: Schemas.UtilityMeterReadingSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateUtilityMeterReading = (
	variables: CreateUtilityMeterReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateUtilityMeterReadingError,
		Schemas.UtilityMeterReadingSchema,
		{},
		{},
		{}
	>({
		url: '/api/utility-meter-readings/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateUtilityMeterReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateUtilityMeterReadingError,
			CreateUtilityMeterReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateUtilityMeterReadingError,
		CreateUtilityMeterReadingVariables
	>(
		(variables: CreateUtilityMeterReadingVariables) =>
			fetchCreateUtilityMeterReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListUtilityMeterReadingsQueryParams = {
	utility_meter_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListUtilityMeterReadingsError = Fetcher.ErrorWrapper<undefined>

export type ListUtilityMeterReadingsVariables = {
	queryParams?: ListUtilityMeterReadingsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListUtilityMeterReadings = (
	variables: ListUtilityMeterReadingsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedUtilityMeterReadingDetailSchema,
		ListUtilityMeterReadingsError,
		undefined,
		{},
		ListUtilityMeterReadingsQueryParams,
		{}
	>({
		url: '/api/utility-meter-readings/',
		method: 'get',
		...variables,
		signal
	})

export const useListUtilityMeterReadings = <
	TData = Schemas.PagedUtilityMeterReadingDetailSchema
>(
	variables: ListUtilityMeterReadingsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedUtilityMeterReadingDetailSchema,
			ListUtilityMeterReadingsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedUtilityMeterReadingDetailSchema,
		ListUtilityMeterReadingsError,
		TData
	>(
		queryKeyFn({
			path: '/api/utility-meter-readings/',
			operationId: 'listUtilityMeterReadings',
			variables
		}),
		({ signal }) =>
			fetchListUtilityMeterReadings(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteUtilityMeterReadingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteUtilityMeterReadingError = Fetcher.ErrorWrapper<undefined>

export type DeleteUtilityMeterReadingVariables = {
	pathParams: DeleteUtilityMeterReadingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteUtilityMeterReading = (
	variables: DeleteUtilityMeterReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteUtilityMeterReadingError,
		undefined,
		{},
		{},
		DeleteUtilityMeterReadingPathParams
	>({
		url: '/api/utility-meter-readings/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteUtilityMeterReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteUtilityMeterReadingError,
			DeleteUtilityMeterReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteUtilityMeterReadingError,
		DeleteUtilityMeterReadingVariables
	>(
		(variables: DeleteUtilityMeterReadingVariables) =>
			fetchDeleteUtilityMeterReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetUtilityMeterReadingDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetUtilityMeterReadingDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetUtilityMeterReadingDetailsVariables = {
	pathParams: GetUtilityMeterReadingDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetUtilityMeterReadingDetails = (
	variables: GetUtilityMeterReadingDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.UtilityMeterReadingDetailSchema,
		GetUtilityMeterReadingDetailsError,
		undefined,
		{},
		{},
		GetUtilityMeterReadingDetailsPathParams
	>({
		url: '/api/utility-meter-readings/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetUtilityMeterReadingDetails = <
	TData = Schemas.UtilityMeterReadingDetailSchema
>(
	variables: GetUtilityMeterReadingDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UtilityMeterReadingDetailSchema,
			GetUtilityMeterReadingDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.UtilityMeterReadingDetailSchema,
		GetUtilityMeterReadingDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/utility-meter-readings/{id}/',
			operationId: 'getUtilityMeterReadingDetails',
			variables
		}),
		({ signal }) =>
			fetchGetUtilityMeterReadingDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateUtilityMeterReadingPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateUtilityMeterReadingError = Fetcher.ErrorWrapper<undefined>

export type UpdateUtilityMeterReadingVariables = {
	body: Schemas.UtilityMeterReadingSchema
	pathParams: UpdateUtilityMeterReadingPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateUtilityMeterReading = (
	variables: UpdateUtilityMeterReadingVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateUtilityMeterReadingError,
		Schemas.UtilityMeterReadingSchema,
		{},
		{},
		UpdateUtilityMeterReadingPathParams
	>({
		url: '/api/utility-meter-readings/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateUtilityMeterReading = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateUtilityMeterReadingError,
			UpdateUtilityMeterReadingVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateUtilityMeterReadingError,
		UpdateUtilityMeterReadingVariables
	>(
		(variables: UpdateUtilityMeterReadingVariables) =>
			fetchUpdateUtilityMeterReading({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateUtilityMeterError = Fetcher.ErrorWrapper<undefined>

export type CreateUtilityMeterVariables = {
	body: Schemas.UtilityMeterSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateUtilityMeter = (
	variables: CreateUtilityMeterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateUtilityMeterError,
		Schemas.UtilityMeterSchema,
		{},
		{},
		{}
	>({ url: '/api/utility-meters/', method: 'post', ...variables, signal })

export const useCreateUtilityMeter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateUtilityMeterError,
			CreateUtilityMeterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateUtilityMeterError,
		CreateUtilityMeterVariables
	>(
		(variables: CreateUtilityMeterVariables) =>
			fetchCreateUtilityMeter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListUtilityMetersQueryParams = {
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListUtilityMetersError = Fetcher.ErrorWrapper<undefined>

export type ListUtilityMetersVariables = {
	queryParams?: ListUtilityMetersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListUtilityMeters = (
	variables: ListUtilityMetersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedUtilityMeterDetailSchema,
		ListUtilityMetersError,
		undefined,
		{},
		ListUtilityMetersQueryParams,
		{}
	>({ url: '/api/utility-meters/', method: 'get', ...variables, signal })

export const useListUtilityMeters = <
	TData = Schemas.PagedUtilityMeterDetailSchema
>(
	variables: ListUtilityMetersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedUtilityMeterDetailSchema,
			ListUtilityMetersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedUtilityMeterDetailSchema,
		ListUtilityMetersError,
		TData
	>(
		queryKeyFn({
			path: '/api/utility-meters/',
			operationId: 'listUtilityMeters',
			variables
		}),
		({ signal }) =>
			fetchListUtilityMeters({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteUtilityMeterPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteUtilityMeterError = Fetcher.ErrorWrapper<undefined>

export type DeleteUtilityMeterVariables = {
	pathParams: DeleteUtilityMeterPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteUtilityMeter = (
	variables: DeleteUtilityMeterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteUtilityMeterError,
		undefined,
		{},
		{},
		DeleteUtilityMeterPathParams
	>({
		url: '/api/utility-meters/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteUtilityMeter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteUtilityMeterError,
			DeleteUtilityMeterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteUtilityMeterError,
		DeleteUtilityMeterVariables
	>(
		(variables: DeleteUtilityMeterVariables) =>
			fetchDeleteUtilityMeter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetUtilityMeterDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetUtilityMeterDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetUtilityMeterDetailsVariables = {
	pathParams: GetUtilityMeterDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetUtilityMeterDetails = (
	variables: GetUtilityMeterDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.UtilityMeterDetailSchema,
		GetUtilityMeterDetailsError,
		undefined,
		{},
		{},
		GetUtilityMeterDetailsPathParams
	>({ url: '/api/utility-meters/{id}/', method: 'get', ...variables, signal })

export const useGetUtilityMeterDetails = <
	TData = Schemas.UtilityMeterDetailSchema
>(
	variables: GetUtilityMeterDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.UtilityMeterDetailSchema,
			GetUtilityMeterDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.UtilityMeterDetailSchema,
		GetUtilityMeterDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/utility-meters/{id}/',
			operationId: 'getUtilityMeterDetails',
			variables
		}),
		({ signal }) =>
			fetchGetUtilityMeterDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateUtilityMeterPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateUtilityMeterError = Fetcher.ErrorWrapper<undefined>

export type UpdateUtilityMeterVariables = {
	body: Schemas.UtilityMeterSchema
	pathParams: UpdateUtilityMeterPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateUtilityMeter = (
	variables: UpdateUtilityMeterVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateUtilityMeterError,
		Schemas.UtilityMeterSchema,
		{},
		{},
		UpdateUtilityMeterPathParams
	>({ url: '/api/utility-meters/{id}/', method: 'put', ...variables, signal })

export const useUpdateUtilityMeter = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateUtilityMeterError,
			UpdateUtilityMeterVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateUtilityMeterError,
		UpdateUtilityMeterVariables
	>(
		(variables: UpdateUtilityMeterVariables) =>
			fetchUpdateUtilityMeter({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateVehicleError = Fetcher.ErrorWrapper<undefined>

export type CreateVehicleVariables = {
	body: Schemas.VehicleSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateVehicle = (
	variables: CreateVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateVehicleError,
		Schemas.VehicleSchema,
		{},
		{},
		{}
	>({ url: '/api/vehicles/', method: 'post', ...variables, signal })

export const useCreateVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateVehicleError,
			CreateVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateVehicleError,
		CreateVehicleVariables
	>(
		(variables: CreateVehicleVariables) =>
			fetchCreateVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListVehiclesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListVehiclesError = Fetcher.ErrorWrapper<undefined>

export type ListVehiclesVariables = {
	queryParams?: ListVehiclesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListVehicles = (
	variables: ListVehiclesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedVehicleSchema,
		ListVehiclesError,
		undefined,
		{},
		ListVehiclesQueryParams,
		{}
	>({ url: '/api/vehicles/', method: 'get', ...variables, signal })

export const useListVehicles = <TData = Schemas.PagedVehicleSchema>(
	variables: ListVehiclesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedVehicleSchema,
			ListVehiclesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedVehicleSchema,
		ListVehiclesError,
		TData
	>(
		queryKeyFn({
			path: '/api/vehicles/',
			operationId: 'listVehicles',
			variables
		}),
		({ signal }) =>
			fetchListVehicles({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteVehiclePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteVehicleError = Fetcher.ErrorWrapper<undefined>

export type DeleteVehicleVariables = {
	pathParams: DeleteVehiclePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteVehicle = (
	variables: DeleteVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteVehicleError,
		undefined,
		{},
		{},
		DeleteVehiclePathParams
	>({ url: '/api/vehicles/{id}/', method: 'delete', ...variables, signal })

export const useDeleteVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteVehicleError,
			DeleteVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteVehicleError,
		DeleteVehicleVariables
	>(
		(variables: DeleteVehicleVariables) =>
			fetchDeleteVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetVehicleDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetVehicleDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetVehicleDetailsVariables = {
	pathParams: GetVehicleDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetVehicleDetails = (
	variables: GetVehicleDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.VehicleSchema,
		GetVehicleDetailsError,
		undefined,
		{},
		{},
		GetVehicleDetailsPathParams
	>({ url: '/api/vehicles/{id}/', method: 'get', ...variables, signal })

export const useGetVehicleDetails = <TData = Schemas.VehicleSchema>(
	variables: GetVehicleDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.VehicleSchema,
			GetVehicleDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.VehicleSchema,
		GetVehicleDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vehicles/{id}/',
			operationId: 'getVehicleDetails',
			variables
		}),
		({ signal }) =>
			fetchGetVehicleDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateVehiclePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateVehicleError = Fetcher.ErrorWrapper<undefined>

export type UpdateVehicleVariables = {
	body: Schemas.VehicleSchema
	pathParams: UpdateVehiclePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateVehicle = (
	variables: UpdateVehicleVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateVehicleError,
		Schemas.VehicleSchema,
		{},
		{},
		UpdateVehiclePathParams
	>({ url: '/api/vehicles/{id}/', method: 'put', ...variables, signal })

export const useUpdateVehicle = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateVehicleError,
			UpdateVehicleVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateVehicleError,
		UpdateVehicleVariables
	>(
		(variables: UpdateVehicleVariables) =>
			fetchUpdateVehicle({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateVendorCreditTransactionError = Fetcher.ErrorWrapper<undefined>

export type CreateVendorCreditTransactionVariables = {
	body: Schemas.VendorCreditTransactionSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateVendorCreditTransaction = (
	variables: CreateVendorCreditTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateVendorCreditTransactionError,
		Schemas.VendorCreditTransactionSchema,
		{},
		{},
		{}
	>({
		url: '/api/vendor-credit-transactions/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateVendorCreditTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateVendorCreditTransactionError,
			CreateVendorCreditTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateVendorCreditTransactionError,
		CreateVendorCreditTransactionVariables
	>(
		(variables: CreateVendorCreditTransactionVariables) =>
			fetchCreateVendorCreditTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListVendorCreditTransactionsQueryParams = {
	vendor_credit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListVendorCreditTransactionsError = Fetcher.ErrorWrapper<undefined>

export type ListVendorCreditTransactionsVariables = {
	queryParams?: ListVendorCreditTransactionsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListVendorCreditTransactions = (
	variables: ListVendorCreditTransactionsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedVendorCreditTransactionSchema,
		ListVendorCreditTransactionsError,
		undefined,
		{},
		ListVendorCreditTransactionsQueryParams,
		{}
	>({
		url: '/api/vendor-credit-transactions/',
		method: 'get',
		...variables,
		signal
	})

export const useListVendorCreditTransactions = <
	TData = Schemas.PagedVendorCreditTransactionSchema
>(
	variables: ListVendorCreditTransactionsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedVendorCreditTransactionSchema,
			ListVendorCreditTransactionsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedVendorCreditTransactionSchema,
		ListVendorCreditTransactionsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendor-credit-transactions/',
			operationId: 'listVendorCreditTransactions',
			variables
		}),
		({ signal }) =>
			fetchListVendorCreditTransactions(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteVendorCreditTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteVendorCreditTransactionError = Fetcher.ErrorWrapper<undefined>

export type DeleteVendorCreditTransactionVariables = {
	pathParams: DeleteVendorCreditTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteVendorCreditTransaction = (
	variables: DeleteVendorCreditTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteVendorCreditTransactionError,
		undefined,
		{},
		{},
		DeleteVendorCreditTransactionPathParams
	>({
		url: '/api/vendor-credit-transactions/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteVendorCreditTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteVendorCreditTransactionError,
			DeleteVendorCreditTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteVendorCreditTransactionError,
		DeleteVendorCreditTransactionVariables
	>(
		(variables: DeleteVendorCreditTransactionVariables) =>
			fetchDeleteVendorCreditTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetVendorCreditTransactionDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetVendorCreditTransactionDetailsError =
	Fetcher.ErrorWrapper<undefined>

export type GetVendorCreditTransactionDetailsVariables = {
	pathParams: GetVendorCreditTransactionDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetVendorCreditTransactionDetails = (
	variables: GetVendorCreditTransactionDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.VendorCreditTransactionSchema,
		GetVendorCreditTransactionDetailsError,
		undefined,
		{},
		{},
		GetVendorCreditTransactionDetailsPathParams
	>({
		url: '/api/vendor-credit-transactions/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetVendorCreditTransactionDetails = <
	TData = Schemas.VendorCreditTransactionSchema
>(
	variables: GetVendorCreditTransactionDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.VendorCreditTransactionSchema,
			GetVendorCreditTransactionDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.VendorCreditTransactionSchema,
		GetVendorCreditTransactionDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendor-credit-transactions/{id}/',
			operationId: 'getVendorCreditTransactionDetails',
			variables
		}),
		({ signal }) =>
			fetchGetVendorCreditTransactionDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateVendorCreditTransactionPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateVendorCreditTransactionError = Fetcher.ErrorWrapper<undefined>

export type UpdateVendorCreditTransactionVariables = {
	body: Schemas.VendorCreditTransactionSchema
	pathParams: UpdateVendorCreditTransactionPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateVendorCreditTransaction = (
	variables: UpdateVendorCreditTransactionVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateVendorCreditTransactionError,
		Schemas.VendorCreditTransactionSchema,
		{},
		{},
		UpdateVendorCreditTransactionPathParams
	>({
		url: '/api/vendor-credit-transactions/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateVendorCreditTransaction = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateVendorCreditTransactionError,
			UpdateVendorCreditTransactionVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateVendorCreditTransactionError,
		UpdateVendorCreditTransactionVariables
	>(
		(variables: UpdateVendorCreditTransactionVariables) =>
			fetchUpdateVendorCreditTransaction({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateVendorCreditError = Fetcher.ErrorWrapper<undefined>

export type CreateVendorCreditVariables = {
	body: Schemas.VendorCreditSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateVendorCredit = (
	variables: CreateVendorCreditVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateVendorCreditError,
		Schemas.VendorCreditSchema,
		{},
		{},
		{}
	>({ url: '/api/vendor-credits/', method: 'post', ...variables, signal })

export const useCreateVendorCredit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateVendorCreditError,
			CreateVendorCreditVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateVendorCreditError,
		CreateVendorCreditVariables
	>(
		(variables: CreateVendorCreditVariables) =>
			fetchCreateVendorCredit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListVendorCreditsQueryParams = {
	is_paid?: boolean | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListVendorCreditsError = Fetcher.ErrorWrapper<undefined>

export type ListVendorCreditsVariables = {
	queryParams?: ListVendorCreditsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListVendorCredits = (
	variables: ListVendorCreditsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedVendorCreditDetailSchema,
		ListVendorCreditsError,
		undefined,
		{},
		ListVendorCreditsQueryParams,
		{}
	>({ url: '/api/vendor-credits/', method: 'get', ...variables, signal })

export const useListVendorCredits = <
	TData = Schemas.PagedVendorCreditDetailSchema
>(
	variables: ListVendorCreditsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedVendorCreditDetailSchema,
			ListVendorCreditsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedVendorCreditDetailSchema,
		ListVendorCreditsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendor-credits/',
			operationId: 'listVendorCredits',
			variables
		}),
		({ signal }) =>
			fetchListVendorCredits({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteVendorCreditPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteVendorCreditError = Fetcher.ErrorWrapper<undefined>

export type DeleteVendorCreditVariables = {
	pathParams: DeleteVendorCreditPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteVendorCredit = (
	variables: DeleteVendorCreditVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteVendorCreditError,
		undefined,
		{},
		{},
		DeleteVendorCreditPathParams
	>({
		url: '/api/vendor-credits/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteVendorCredit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteVendorCreditError,
			DeleteVendorCreditVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteVendorCreditError,
		DeleteVendorCreditVariables
	>(
		(variables: DeleteVendorCreditVariables) =>
			fetchDeleteVendorCredit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetVendorCreditDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetVendorCreditDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetVendorCreditDetailsVariables = {
	pathParams: GetVendorCreditDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetVendorCreditDetails = (
	variables: GetVendorCreditDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.VendorCreditSchema,
		GetVendorCreditDetailsError,
		undefined,
		{},
		{},
		GetVendorCreditDetailsPathParams
	>({ url: '/api/vendor-credits/{id}/', method: 'get', ...variables, signal })

export const useGetVendorCreditDetails = <TData = Schemas.VendorCreditSchema>(
	variables: GetVendorCreditDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.VendorCreditSchema,
			GetVendorCreditDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.VendorCreditSchema,
		GetVendorCreditDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendor-credits/{id}/',
			operationId: 'getVendorCreditDetails',
			variables
		}),
		({ signal }) =>
			fetchGetVendorCreditDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateVendorCreditPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateVendorCreditError = Fetcher.ErrorWrapper<undefined>

export type UpdateVendorCreditVariables = {
	body: Schemas.VendorCreditSchema
	pathParams: UpdateVendorCreditPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateVendorCredit = (
	variables: UpdateVendorCreditVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateVendorCreditError,
		Schemas.VendorCreditSchema,
		{},
		{},
		UpdateVendorCreditPathParams
	>({ url: '/api/vendor-credits/{id}/', method: 'put', ...variables, signal })

export const useUpdateVendorCredit = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateVendorCreditError,
			UpdateVendorCreditVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateVendorCreditError,
		UpdateVendorCreditVariables
	>(
		(variables: UpdateVendorCreditVariables) =>
			fetchUpdateVendorCredit({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListVendorCreditTypesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListVendorCreditTypesError = Fetcher.ErrorWrapper<undefined>

export type ListVendorCreditTypesVariables = {
	queryParams?: ListVendorCreditTypesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListVendorCreditTypes = (
	variables: ListVendorCreditTypesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedVendorCreditTypeSchema,
		ListVendorCreditTypesError,
		undefined,
		{},
		ListVendorCreditTypesQueryParams,
		{}
	>({ url: '/api/vendor-credit-types/', method: 'get', ...variables, signal })

export const useListVendorCreditTypes = <
	TData = Schemas.PagedVendorCreditTypeSchema
>(
	variables: ListVendorCreditTypesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedVendorCreditTypeSchema,
			ListVendorCreditTypesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedVendorCreditTypeSchema,
		ListVendorCreditTypesError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendor-credit-types/',
			operationId: 'listVendorCreditTypes',
			variables
		}),
		({ signal }) =>
			fetchListVendorCreditTypes({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type CreateVendorError = Fetcher.ErrorWrapper<undefined>

export type CreateVendorVariables = {
	body: Schemas.VendorSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateVendor = (
	variables: CreateVendorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateVendorError,
		Schemas.VendorSchema,
		{},
		{},
		{}
	>({ url: '/api/vendors/', method: 'post', ...variables, signal })

export const useCreateVendor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateVendorError,
			CreateVendorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateVendorError,
		CreateVendorVariables
	>(
		(variables: CreateVendorVariables) =>
			fetchCreateVendor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListVendorsQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListVendorsError = Fetcher.ErrorWrapper<undefined>

export type ListVendorsVariables = {
	queryParams?: ListVendorsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListVendors = (
	variables: ListVendorsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedVendorSchema,
		ListVendorsError,
		undefined,
		{},
		ListVendorsQueryParams,
		{}
	>({ url: '/api/vendors/', method: 'get', ...variables, signal })

export const useListVendors = <TData = Schemas.PagedVendorSchema>(
	variables: ListVendorsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedVendorSchema,
			ListVendorsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedVendorSchema,
		ListVendorsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendors/',
			operationId: 'listVendors',
			variables
		}),
		({ signal }) =>
			fetchListVendors({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteVendorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteVendorError = Fetcher.ErrorWrapper<undefined>

export type DeleteVendorVariables = {
	pathParams: DeleteVendorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteVendor = (
	variables: DeleteVendorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteVendorError,
		undefined,
		{},
		{},
		DeleteVendorPathParams
	>({ url: '/api/vendors/{id}/', method: 'delete', ...variables, signal })

export const useDeleteVendor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteVendorError,
			DeleteVendorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteVendorError,
		DeleteVendorVariables
	>(
		(variables: DeleteVendorVariables) =>
			fetchDeleteVendor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetVendorDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetVendorDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetVendorDetailsVariables = {
	pathParams: GetVendorDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetVendorDetails = (
	variables: GetVendorDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.VendorDetailSchema,
		GetVendorDetailsError,
		undefined,
		{},
		{},
		GetVendorDetailsPathParams
	>({ url: '/api/vendors/{id}/', method: 'get', ...variables, signal })

export const useGetVendorDetails = <TData = Schemas.VendorDetailSchema>(
	variables: GetVendorDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.VendorDetailSchema,
			GetVendorDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.VendorDetailSchema,
		GetVendorDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/vendors/{id}/',
			operationId: 'getVendorDetails',
			variables
		}),
		({ signal }) =>
			fetchGetVendorDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateVendorPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateVendorError = Fetcher.ErrorWrapper<undefined>

export type UpdateVendorVariables = {
	body: Schemas.VendorSchema
	pathParams: UpdateVendorPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateVendor = (
	variables: UpdateVendorVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateVendorError,
		Schemas.VendorSchema,
		{},
		{},
		UpdateVendorPathParams
	>({ url: '/api/vendors/{id}/', method: 'put', ...variables, signal })

export const useUpdateVendor = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateVendorError,
			UpdateVendorVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateVendorError,
		UpdateVendorVariables
	>(
		(variables: UpdateVendorVariables) =>
			fetchUpdateVendor({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateWorkLogError = Fetcher.ErrorWrapper<undefined>

export type CreateWorkLogVariables = {
	body: Schemas.WorkLogCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateWorkLog = (
	variables: CreateWorkLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateWorkLogError,
		Schemas.WorkLogCreateSchema,
		{},
		{},
		{}
	>({ url: '/api/work-logs/', method: 'post', ...variables, signal })

export const useCreateWorkLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateWorkLogError,
			CreateWorkLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateWorkLogError,
		CreateWorkLogVariables
	>(
		(variables: CreateWorkLogVariables) =>
			fetchCreateWorkLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListWorkLogsQueryParams = {
	maintenance_technician_id?: string | null
	work_order_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkLogsError = Fetcher.ErrorWrapper<undefined>

export type ListWorkLogsVariables = {
	queryParams?: ListWorkLogsQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkLogs = (
	variables: ListWorkLogsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkLogDetailSchema,
		ListWorkLogsError,
		undefined,
		{},
		ListWorkLogsQueryParams,
		{}
	>({ url: '/api/work-logs/', method: 'get', ...variables, signal })

export const useListWorkLogs = <TData = Schemas.PagedWorkLogDetailSchema>(
	variables: ListWorkLogsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkLogDetailSchema,
			ListWorkLogsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkLogDetailSchema,
		ListWorkLogsError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-logs/',
			operationId: 'listWorkLogs',
			variables
		}),
		({ signal }) =>
			fetchListWorkLogs({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteWorkLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteWorkLogError = Fetcher.ErrorWrapper<undefined>

export type DeleteWorkLogVariables = {
	pathParams: DeleteWorkLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteWorkLog = (
	variables: DeleteWorkLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteWorkLogError,
		undefined,
		{},
		{},
		DeleteWorkLogPathParams
	>({ url: '/api/work-logs/{id}/', method: 'delete', ...variables, signal })

export const useDeleteWorkLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteWorkLogError,
			DeleteWorkLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteWorkLogError,
		DeleteWorkLogVariables
	>(
		(variables: DeleteWorkLogVariables) =>
			fetchDeleteWorkLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetWorkLogDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetWorkLogDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetWorkLogDetailsVariables = {
	pathParams: GetWorkLogDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetWorkLogDetails = (
	variables: GetWorkLogDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.WorkLogDetailSchema,
		GetWorkLogDetailsError,
		undefined,
		{},
		{},
		GetWorkLogDetailsPathParams
	>({ url: '/api/work-logs/{id}/', method: 'get', ...variables, signal })

export const useGetWorkLogDetails = <TData = Schemas.WorkLogDetailSchema>(
	variables: GetWorkLogDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.WorkLogDetailSchema,
			GetWorkLogDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.WorkLogDetailSchema,
		GetWorkLogDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-logs/{id}/',
			operationId: 'getWorkLogDetails',
			variables
		}),
		({ signal }) =>
			fetchGetWorkLogDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateWorkLogPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateWorkLogError = Fetcher.ErrorWrapper<undefined>

export type UpdateWorkLogVariables = {
	body: Schemas.WorkLogSchema
	pathParams: UpdateWorkLogPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateWorkLog = (
	variables: UpdateWorkLogVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateWorkLogError,
		Schemas.WorkLogSchema,
		{},
		{},
		UpdateWorkLogPathParams
	>({ url: '/api/work-logs/{id}/', method: 'put', ...variables, signal })

export const useUpdateWorkLog = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateWorkLogError,
			UpdateWorkLogVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateWorkLogError,
		UpdateWorkLogVariables
	>(
		(variables: UpdateWorkLogVariables) =>
			fetchUpdateWorkLog({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateWorkOrderPurchaseError = Fetcher.ErrorWrapper<undefined>

export type CreateWorkOrderPurchaseVariables = {
	body: Schemas.WorkOrderPurchaseCreateSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateWorkOrderPurchase = (
	variables: CreateWorkOrderPurchaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateWorkOrderPurchaseError,
		Schemas.WorkOrderPurchaseCreateSchema,
		{},
		{},
		{}
	>({
		url: '/api/work-order-purchases/',
		method: 'post',
		...variables,
		signal
	})

export const useCreateWorkOrderPurchase = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateWorkOrderPurchaseError,
			CreateWorkOrderPurchaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateWorkOrderPurchaseError,
		CreateWorkOrderPurchaseVariables
	>(
		(variables: CreateWorkOrderPurchaseVariables) =>
			fetchCreateWorkOrderPurchase({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListWorkOrderPurchasesQueryParams = {
	resident_id?: string | null
	building_id?: string | null
	rental_unit_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkOrderPurchasesError = Fetcher.ErrorWrapper<undefined>

export type ListWorkOrderPurchasesVariables = {
	queryParams?: ListWorkOrderPurchasesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkOrderPurchases = (
	variables: ListWorkOrderPurchasesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkOrderPurchaseDetailSchema,
		ListWorkOrderPurchasesError,
		undefined,
		{},
		ListWorkOrderPurchasesQueryParams,
		{}
	>({ url: '/api/work-order-purchases/', method: 'get', ...variables, signal })

export const useListWorkOrderPurchases = <
	TData = Schemas.PagedWorkOrderPurchaseDetailSchema
>(
	variables: ListWorkOrderPurchasesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkOrderPurchaseDetailSchema,
			ListWorkOrderPurchasesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkOrderPurchaseDetailSchema,
		ListWorkOrderPurchasesError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-order-purchases/',
			operationId: 'listWorkOrderPurchases',
			variables
		}),
		({ signal }) =>
			fetchListWorkOrderPurchases({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteWorkOrderPurchasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteWorkOrderPurchaseError = Fetcher.ErrorWrapper<undefined>

export type DeleteWorkOrderPurchaseVariables = {
	pathParams: DeleteWorkOrderPurchasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteWorkOrderPurchase = (
	variables: DeleteWorkOrderPurchaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteWorkOrderPurchaseError,
		undefined,
		{},
		{},
		DeleteWorkOrderPurchasePathParams
	>({
		url: '/api/work-order-purchases/{id}/',
		method: 'delete',
		...variables,
		signal
	})

export const useDeleteWorkOrderPurchase = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteWorkOrderPurchaseError,
			DeleteWorkOrderPurchaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteWorkOrderPurchaseError,
		DeleteWorkOrderPurchaseVariables
	>(
		(variables: DeleteWorkOrderPurchaseVariables) =>
			fetchDeleteWorkOrderPurchase({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetWorkOrderPurchaseDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetWorkOrderPurchaseDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetWorkOrderPurchaseDetailsVariables = {
	pathParams: GetWorkOrderPurchaseDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetWorkOrderPurchaseDetails = (
	variables: GetWorkOrderPurchaseDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.WorkOrderPurchaseDetailSchema,
		GetWorkOrderPurchaseDetailsError,
		undefined,
		{},
		{},
		GetWorkOrderPurchaseDetailsPathParams
	>({
		url: '/api/work-order-purchases/{id}/',
		method: 'get',
		...variables,
		signal
	})

export const useGetWorkOrderPurchaseDetails = <
	TData = Schemas.WorkOrderPurchaseDetailSchema
>(
	variables: GetWorkOrderPurchaseDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.WorkOrderPurchaseDetailSchema,
			GetWorkOrderPurchaseDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.WorkOrderPurchaseDetailSchema,
		GetWorkOrderPurchaseDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-order-purchases/{id}/',
			operationId: 'getWorkOrderPurchaseDetails',
			variables
		}),
		({ signal }) =>
			fetchGetWorkOrderPurchaseDetails(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateWorkOrderPurchasePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateWorkOrderPurchaseError = Fetcher.ErrorWrapper<undefined>

export type UpdateWorkOrderPurchaseVariables = {
	body: Schemas.WorkOrderPurchaseSchema
	pathParams: UpdateWorkOrderPurchasePathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateWorkOrderPurchase = (
	variables: UpdateWorkOrderPurchaseVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateWorkOrderPurchaseError,
		Schemas.WorkOrderPurchaseSchema,
		{},
		{},
		UpdateWorkOrderPurchasePathParams
	>({
		url: '/api/work-order-purchases/{id}/',
		method: 'put',
		...variables,
		signal
	})

export const useUpdateWorkOrderPurchase = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateWorkOrderPurchaseError,
			UpdateWorkOrderPurchaseVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateWorkOrderPurchaseError,
		UpdateWorkOrderPurchaseVariables
	>(
		(variables: UpdateWorkOrderPurchaseVariables) =>
			fetchUpdateWorkOrderPurchase({ ...fetcherOptions, ...variables }),
		options
	)
}

export type BulkGenerateWorkOrderInvoiceError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type BulkGenerateWorkOrderInvoiceVariables = {
	body: Schemas.BulkGenerateWorkOrderInvoiceSchema
} & RentalizeContext['fetcherOptions']

/**
 * Generates an Invoice, using several selected work orders to determine the line items.
 */
export const fetchBulkGenerateWorkOrderInvoice = (
	variables: BulkGenerateWorkOrderInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InvoiceSchema,
		BulkGenerateWorkOrderInvoiceError,
		Schemas.BulkGenerateWorkOrderInvoiceSchema,
		{},
		{},
		{}
	>({
		url: '/api/work-orders/generate-bulk-invoice',
		method: 'post',
		...variables,
		signal
	})

/**
 * Generates an Invoice, using several selected work orders to determine the line items.
 */
export const useBulkGenerateWorkOrderInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.InvoiceSchema,
			BulkGenerateWorkOrderInvoiceError,
			BulkGenerateWorkOrderInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.InvoiceSchema,
		BulkGenerateWorkOrderInvoiceError,
		BulkGenerateWorkOrderInvoiceVariables
	>(
		(variables: BulkGenerateWorkOrderInvoiceVariables) =>
			fetchBulkGenerateWorkOrderInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type CreateWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type CreateWorkOrderVariables = {
	body: Schemas.WorkOrderInSchema
} & RentalizeContext['fetcherOptions']

export const fetchCreateWorkOrder = (
	variables: CreateWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		CreateWorkOrderError,
		Schemas.WorkOrderInSchema,
		{},
		{},
		{}
	>({ url: '/api/work-orders/', method: 'post', ...variables, signal })

export const useCreateWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			CreateWorkOrderError,
			CreateWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		CreateWorkOrderError,
		CreateWorkOrderVariables
	>(
		(variables: CreateWorkOrderVariables) =>
			fetchCreateWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListWorkOrdersQueryParams = {
	resident_id?: string | null
	property_owner_id?: string | null
	building_id?: string | null
	rental_unit_id?: string | null
	make_ready_id?: string | null
	statuses?: ('NEW' | 'IN_PROGRESS' | 'COMPLETED' | 'DEFERRED' | 'CLOSED')[]
	/**
	 * @default false
	 */
	only_show_work_orders_with_outstanding_invoices?: boolean
	order_by?: 'BUILDING_ADDRESS' | null
	limit?: number | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkOrdersError = Fetcher.ErrorWrapper<undefined>

export type ListWorkOrdersVariables = {
	queryParams?: ListWorkOrdersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkOrders = (
	variables: ListWorkOrdersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedListWorkOrdersSchema,
		ListWorkOrdersError,
		undefined,
		{},
		ListWorkOrdersQueryParams,
		{}
	>({ url: '/api/work-orders/', method: 'get', ...variables, signal })

export const useListWorkOrders = <TData = Schemas.PagedListWorkOrdersSchema>(
	variables: ListWorkOrdersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedListWorkOrdersSchema,
			ListWorkOrdersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedListWorkOrdersSchema,
		ListWorkOrdersError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-orders/',
			operationId: 'listWorkOrders',
			variables
		}),
		({ signal }) =>
			fetchListWorkOrders({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type DeleteWorkOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DeleteWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type DeleteWorkOrderVariables = {
	pathParams: DeleteWorkOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDeleteWorkOrder = (
	variables: DeleteWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DeletedResponse,
		DeleteWorkOrderError,
		undefined,
		{},
		{},
		DeleteWorkOrderPathParams
	>({ url: '/api/work-orders/{id}/', method: 'delete', ...variables, signal })

export const useDeleteWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DeletedResponse,
			DeleteWorkOrderError,
			DeleteWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DeletedResponse,
		DeleteWorkOrderError,
		DeleteWorkOrderVariables
	>(
		(variables: DeleteWorkOrderVariables) =>
			fetchDeleteWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GetWorkOrderDetailsPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GetWorkOrderDetailsError = Fetcher.ErrorWrapper<undefined>

export type GetWorkOrderDetailsVariables = {
	pathParams: GetWorkOrderDetailsPathParams
} & RentalizeContext['fetcherOptions']

export const fetchGetWorkOrderDetails = (
	variables: GetWorkOrderDetailsVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.WorkOrderDetailSchema,
		GetWorkOrderDetailsError,
		undefined,
		{},
		{},
		GetWorkOrderDetailsPathParams
	>({ url: '/api/work-orders/{id}/', method: 'get', ...variables, signal })

export const useGetWorkOrderDetails = <TData = Schemas.WorkOrderDetailSchema>(
	variables: GetWorkOrderDetailsVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.WorkOrderDetailSchema,
			GetWorkOrderDetailsError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.WorkOrderDetailSchema,
		GetWorkOrderDetailsError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-orders/{id}/',
			operationId: 'getWorkOrderDetails',
			variables
		}),
		({ signal }) =>
			fetchGetWorkOrderDetails({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type UpdateWorkOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type UpdateWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type UpdateWorkOrderVariables = {
	body: Schemas.WorkOrderInSchema
	pathParams: UpdateWorkOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchUpdateWorkOrder = (
	variables: UpdateWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		UpdateWorkOrderError,
		Schemas.WorkOrderInSchema,
		{},
		{},
		UpdateWorkOrderPathParams
	>({ url: '/api/work-orders/{id}/', method: 'put', ...variables, signal })

export const useUpdateWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Record<string, any>,
			UpdateWorkOrderError,
			UpdateWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Record<string, any>,
		UpdateWorkOrderError,
		UpdateWorkOrderVariables
	>(
		(variables: UpdateWorkOrderVariables) =>
			fetchUpdateWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type DuplicateWorkOrderPathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type DuplicateWorkOrderError = Fetcher.ErrorWrapper<undefined>

export type DuplicateWorkOrderVariables = {
	pathParams: DuplicateWorkOrderPathParams
} & RentalizeContext['fetcherOptions']

export const fetchDuplicateWorkOrder = (
	variables: DuplicateWorkOrderVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.DuplicatedWorkOrderResponse,
		DuplicateWorkOrderError,
		undefined,
		{},
		{},
		DuplicateWorkOrderPathParams
	>({
		url: '/api/work-orders/{id}/duplicate/',
		method: 'post',
		...variables,
		signal
	})

export const useDuplicateWorkOrder = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.DuplicatedWorkOrderResponse,
			DuplicateWorkOrderError,
			DuplicateWorkOrderVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.DuplicatedWorkOrderResponse,
		DuplicateWorkOrderError,
		DuplicateWorkOrderVariables
	>(
		(variables: DuplicateWorkOrderVariables) =>
			fetchDuplicateWorkOrder({ ...fetcherOptions, ...variables }),
		options
	)
}

export type GenerateWorkOrderInvoicePathParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type GenerateWorkOrderInvoiceError = Fetcher.ErrorWrapper<{
	status: 400
	payload: Schemas.ErrorSchema
}>

export type GenerateWorkOrderInvoiceVariables = {
	pathParams: GenerateWorkOrderInvoicePathParams
} & RentalizeContext['fetcherOptions']

export const fetchGenerateWorkOrderInvoice = (
	variables: GenerateWorkOrderInvoiceVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.InvoiceSchema,
		GenerateWorkOrderInvoiceError,
		undefined,
		{},
		{},
		GenerateWorkOrderInvoicePathParams
	>({
		url: '/api/work-orders/{id}/generate-invoice/',
		method: 'post',
		...variables,
		signal
	})

export const useGenerateWorkOrderInvoice = (
	options?: Omit<
		reactQuery.UseMutationOptions<
			Schemas.InvoiceSchema,
			GenerateWorkOrderInvoiceError,
			GenerateWorkOrderInvoiceVariables
		>,
		'mutationFn'
	>
) => {
	const { fetcherOptions } = useRentalizeContext()
	return reactQuery.useMutation<
		Schemas.InvoiceSchema,
		GenerateWorkOrderInvoiceError,
		GenerateWorkOrderInvoiceVariables
	>(
		(variables: GenerateWorkOrderInvoiceVariables) =>
			fetchGenerateWorkOrderInvoice({ ...fetcherOptions, ...variables }),
		options
	)
}

export type ListInvoiceableWorkOrdersQueryParams = {
	resident_id?: string | null
	property_owner_id?: string | null
	building_id?: string | null
	rental_unit_id?: string | null
	make_ready_id?: string | null
	statuses?: ('NEW' | 'IN_PROGRESS' | 'COMPLETED' | 'DEFERRED' | 'CLOSED')[]
	/**
	 * @default false
	 */
	only_show_work_orders_with_outstanding_invoices?: boolean
	order_by?: 'BUILDING_ADDRESS' | null
	limit?: number | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListInvoiceableWorkOrdersError = Fetcher.ErrorWrapper<undefined>

export type ListInvoiceableWorkOrdersVariables = {
	queryParams?: ListInvoiceableWorkOrdersQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListInvoiceableWorkOrders = (
	variables: ListInvoiceableWorkOrdersVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkOrderDetailSchema,
		ListInvoiceableWorkOrdersError,
		undefined,
		{},
		ListInvoiceableWorkOrdersQueryParams,
		{}
	>({
		url: '/api/invoiceable-work-orders/',
		method: 'get',
		...variables,
		signal
	})

export const useListInvoiceableWorkOrders = <
	TData = Schemas.PagedWorkOrderDetailSchema
>(
	variables: ListInvoiceableWorkOrdersVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkOrderDetailSchema,
			ListInvoiceableWorkOrdersError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkOrderDetailSchema,
		ListInvoiceableWorkOrdersError,
		TData
	>(
		queryKeyFn({
			path: '/api/invoiceable-work-orders/',
			operationId: 'listInvoiceableWorkOrders',
			variables
		}),
		({ signal }) =>
			fetchListInvoiceableWorkOrders(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListWorkOrderCategoriesQueryParams = {
	budget_id?: string | null
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkOrderCategoriesError = Fetcher.ErrorWrapper<undefined>

export type ListWorkOrderCategoriesVariables = {
	queryParams?: ListWorkOrderCategoriesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkOrderCategories = (
	variables: ListWorkOrderCategoriesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkOrderCategorySchema,
		ListWorkOrderCategoriesError,
		undefined,
		{},
		ListWorkOrderCategoriesQueryParams,
		{}
	>({
		url: '/api/work-orders/categories',
		method: 'get',
		...variables,
		signal
	})

export const useListWorkOrderCategories = <
	TData = Schemas.PagedWorkOrderCategorySchema
>(
	variables: ListWorkOrderCategoriesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkOrderCategorySchema,
			ListWorkOrderCategoriesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkOrderCategorySchema,
		ListWorkOrderCategoriesError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-orders/categories',
			operationId: 'listWorkOrderCategories',
			variables
		}),
		({ signal }) =>
			fetchListWorkOrderCategories({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type ListWorkOrderStatusesQueryParams = {
	/**
	 * @default 1
	 * @minimum 1
	 */
	page?: number
}

export type ListWorkOrderStatusesError = Fetcher.ErrorWrapper<undefined>

export type ListWorkOrderStatusesVariables = {
	queryParams?: ListWorkOrderStatusesQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchListWorkOrderStatuses = (
	variables: ListWorkOrderStatusesVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Schemas.PagedWorkOrderStatusSchema,
		ListWorkOrderStatusesError,
		undefined,
		{},
		ListWorkOrderStatusesQueryParams,
		{}
	>({ url: '/api/work-orders/statuses', method: 'get', ...variables, signal })

export const useListWorkOrderStatuses = <
	TData = Schemas.PagedWorkOrderStatusSchema
>(
	variables: ListWorkOrderStatusesVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Schemas.PagedWorkOrderStatusSchema,
			ListWorkOrderStatusesError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Schemas.PagedWorkOrderStatusSchema,
		ListWorkOrderStatusesError,
		TData
	>(
		queryKeyFn({
			path: '/api/work-orders/statuses',
			operationId: 'listWorkOrderStatuses',
			variables
		}),
		({ signal }) =>
			fetchListWorkOrderStatuses({ ...fetcherOptions, ...variables }, signal),
		{
			...options,
			...queryOptions
		}
	)
}

export type TestZapierAuthenticationQueryParams = {
	/**
	 * @format uuid
	 */
	id: string
}

export type TestZapierAuthenticationError = Fetcher.ErrorWrapper<undefined>

export type TestZapierAuthenticationVariables = {
	queryParams: TestZapierAuthenticationQueryParams
} & RentalizeContext['fetcherOptions']

export const fetchTestZapierAuthentication = (
	variables: TestZapierAuthenticationVariables,
	signal?: AbortSignal
) =>
	rentalizeFetch<
		Record<string, any>,
		TestZapierAuthenticationError,
		undefined,
		{},
		TestZapierAuthenticationQueryParams,
		{}
	>({ url: '/api/zapier/', method: 'get', ...variables, signal })

export const useTestZapierAuthentication = <TData = Record<string, any>>(
	variables: TestZapierAuthenticationVariables,
	options?: Omit<
		reactQuery.UseQueryOptions<
			Record<string, any>,
			TestZapierAuthenticationError,
			TData
		>,
		'queryKey' | 'queryFn'
	>
) => {
	const { fetcherOptions, queryOptions, queryKeyFn } =
		useRentalizeContext(options)
	return reactQuery.useQuery<
		Record<string, any>,
		TestZapierAuthenticationError,
		TData
	>(
		queryKeyFn({
			path: '/api/zapier/',
			operationId: 'testZapierAuthentication',
			variables
		}),
		({ signal }) =>
			fetchTestZapierAuthentication(
				{ ...fetcherOptions, ...variables },
				signal
			),
		{
			...options,
			...queryOptions
		}
	)
}

export type QueryOperation =
	| {
			path: '/api/accounting/account-roles/'
			operationId: 'listAccountRoles'
			variables: ListAccountRolesVariables
	  }
	| {
			path: '/api/accounting/accounting-workflow-steps/'
			operationId: 'listAccountingWorkflowSteps'
			variables: ListAccountingWorkflowStepsVariables
	  }
	| {
			path: '/api/accounting/accounting-workflow-steps/{id}/'
			operationId: 'getAccountingWorkflowStepDetails'
			variables: GetAccountingWorkflowStepDetailsVariables
	  }
	| {
			path: '/api/accounting/accounting-workflows/'
			operationId: 'listAccountingWorkflows'
			variables: ListAccountingWorkflowsVariables
	  }
	| {
			path: '/api/accounting/accounting-workflows/{id}/'
			operationId: 'getAccountingWorkflowDetails'
			variables: GetAccountingWorkflowDetailsVariables
	  }
	| {
			path: '/api/accounting/accounts/'
			operationId: 'listAccounts'
			variables: ListAccountsVariables
	  }
	| {
			path: '/api/accounting/accounts/{id}/'
			operationId: 'getAccountDetails'
			variables: GetAccountDetailsVariables
	  }
	| {
			path: '/api/accounting/approval-requests/'
			operationId: 'listApprovalRequests'
			variables: ListApprovalRequestsVariables
	  }
	| {
			path: '/api/accounting/approval-requests/{id}/'
			operationId: 'getApprovalRequestDetails'
			variables: GetApprovalRequestDetailsVariables
	  }
	| {
			path: '/api/accounting/bank-deposits/'
			operationId: 'listBankDeposits'
			variables: ListBankDepositsVariables
	  }
	| {
			path: '/api/accounting/bank-deposits/{id}/'
			operationId: 'getBankDepositDetails'
			variables: GetBankDepositDetailsVariables
	  }
	| {
			path: '/api/accounting/bank-reconciliations/'
			operationId: 'listBankReconciliations'
			variables: ListBankReconciliationsVariables
	  }
	| {
			path: '/api/accounting/bank-reconciliations/{id}/'
			operationId: 'getBankReconciliationDetails'
			variables: GetBankReconciliationDetailsVariables
	  }
	| {
			path: '/api/accounting/bill-payments/'
			operationId: 'listBillPayments'
			variables: ListBillPaymentsVariables
	  }
	| {
			path: '/api/accounting/bill-payments/{id}/'
			operationId: 'getBillPaymentDetails'
			variables: GetBillPaymentDetailsVariables
	  }
	| {
			path: '/api/accounting/billable-expenses/'
			operationId: 'listBillableExpenses'
			variables: ListBillableExpensesVariables
	  }
	| {
			path: '/api/accounting/bills/'
			operationId: 'listBills'
			variables: ListBillsVariables
	  }
	| {
			path: '/api/accounting/bills/{id}/'
			operationId: 'getBillDetails'
			variables: GetBillDetailsVariables
	  }
	| {
			path: '/api/accounting/budget-line-item-charges/'
			operationId: 'listBudgetLineItemCharges'
			variables: ListBudgetLineItemChargesVariables
	  }
	| {
			path: '/api/accounting/budget-line-item-charges/{id}/'
			operationId: 'getBudgetLineItemChargeDetails'
			variables: GetBudgetLineItemChargeDetailsVariables
	  }
	| {
			path: '/api/accounting/budget-line-items/'
			operationId: 'listBudgetLineItems'
			variables: ListBudgetLineItemsVariables
	  }
	| {
			path: '/api/accounting/budget-line-items/{id}/'
			operationId: 'getBudgetLineItemDetails'
			variables: GetBudgetLineItemDetailsVariables
	  }
	| {
			path: '/api/accounting/budget-line-items/categories'
			operationId: 'listBudgetLineItemCategories'
			variables: ListBudgetLineItemCategoriesVariables
	  }
	| {
			path: '/api/accounting/budget-line-items/subcategories'
			operationId: 'listBudgetLineItemSubcategories'
			variables: ListBudgetLineItemSubcategoriesVariables
	  }
	| {
			path: '/api/accounting/budgets/'
			operationId: 'listBudgets'
			variables: ListBudgetsVariables
	  }
	| {
			path: '/api/accounting/budgets/{id}/'
			operationId: 'getBudgetDetails'
			variables: GetBudgetDetailsVariables
	  }
	| {
			path: '/api/accounting/budgets/budget-types'
			operationId: 'listBudgetTypes'
			variables: ListBudgetTypesVariables
	  }
	| {
			path: '/api/accounting/bulk-bill-payments/{id}/'
			operationId: 'getBulkBillPaymentDetails'
			variables: GetBulkBillPaymentDetailsVariables
	  }
	| {
			path: '/api/accounting/bulk-bill-payments/'
			operationId: 'listBulkBillPayments'
			variables: ListBulkBillPaymentsVariables
	  }
	| {
			path: '/api/accounting/bulk-invoice-payments/{id}/'
			operationId: 'getBulkInvoicePaymentDetails'
			variables: GetBulkInvoicePaymentDetailsVariables
	  }
	| {
			path: '/api/accounting/bulk-invoice-payments/'
			operationId: 'listBulkInvoicePayments'
			variables: ListBulkInvoicePaymentsVariables
	  }
	| {
			path: '/api/accounting/default-gl-accounts/'
			operationId: 'listDefaultGlAccounts'
			variables: ListDefaultGlAccountsVariables
	  }
	| {
			path: '/api/accounting/default-gl-accounts/{id}/'
			operationId: 'getDefaultGlAccountDetails'
			variables: GetDefaultGlAccountDetailsVariables
	  }
	| {
			path: '/api/accounting/imported-transactions/'
			operationId: 'listImportedTransactions'
			variables: ListImportedTransactionsVariables
	  }
	| {
			path: '/api/accounting/imported-transactions/{id}/'
			operationId: 'getImportedTransactionDetails'
			variables: GetImportedTransactionDetailsVariables
	  }
	| {
			path: '/api/accounting/invoice-line-items/'
			operationId: 'listInvoiceLineItems'
			variables: ListInvoiceLineItemsVariables
	  }
	| {
			path: '/api/accounting/invoice-line-items/{id}/'
			operationId: 'getInvoiceLineItemDetails'
			variables: GetInvoiceLineItemDetailsVariables
	  }
	| {
			path: '/api/accounting/invoice-payments/'
			operationId: 'listInvoicePayments'
			variables: ListInvoicePaymentsVariables
	  }
	| {
			path: '/api/accounting/invoice-payments/{id}/'
			operationId: 'getInvoicePaymentDetails'
			variables: GetInvoicePaymentDetailsVariables
	  }
	| {
			path: '/api/accounting/invoices/'
			operationId: 'listInvoices'
			variables: ListInvoicesVariables
	  }
	| {
			path: '/api/accounting/invoices/{id}/'
			operationId: 'getInvoiceDetails'
			variables: GetInvoiceDetailsVariables
	  }
	| {
			path: '/api/accounting/journal-entries/'
			operationId: 'listJournalEntries'
			variables: ListJournalEntriesVariables
	  }
	| {
			path: '/api/accounting/journal-entries/{id}/'
			operationId: 'getJournalEntryDetails'
			variables: GetJournalEntryDetailsVariables
	  }
	| {
			path: '/api/accounting/sales-tax-rates/'
			operationId: 'listSalesTaxRates'
			variables: ListSalesTaxRatesVariables
	  }
	| {
			path: '/api/accounting/markup-types/'
			operationId: 'listMarkupTypes'
			variables: ListMarkupTypesVariables
	  }
	| {
			path: '/api/accounting/markup-types/{id}/'
			operationId: 'getMarkupTypeDetails'
			variables: GetMarkupTypeDetailsVariables
	  }
	| {
			path: '/api/accounting/purchase-order-line-items/'
			operationId: 'listPurchaseOrderLineItems'
			variables: ListPurchaseOrderLineItemsVariables
	  }
	| {
			path: '/api/accounting/purchase-order-line-items/{id}/'
			operationId: 'getPurchaseOrderLineItemDetails'
			variables: GetPurchaseOrderLineItemDetailsVariables
	  }
	| {
			path: '/api/accounting/purchase-orders/'
			operationId: 'listPurchaseOrders'
			variables: ListPurchaseOrdersVariables
	  }
	| {
			path: '/api/accounting/purchase-orders/{id}/'
			operationId: 'getPurchaseOrderDetails'
			variables: GetPurchaseOrderDetailsVariables
	  }
	| {
			path: '/api/purchase-orders/statuses'
			operationId: 'listPurchaseOrderStatuses'
			variables: ListPurchaseOrderStatusesVariables
	  }
	| {
			path: '/api/accounting/recurring-invoice-line-items/'
			operationId: 'listRecurringInvoiceLineItems'
			variables: ListRecurringInvoiceLineItemsVariables
	  }
	| {
			path: '/api/accounting/recurring-invoice-line-items/{id}/'
			operationId: 'getRecurringInvoiceLineItemDetails'
			variables: GetRecurringInvoiceLineItemDetailsVariables
	  }
	| {
			path: '/api/accounting/recurring-invoices/'
			operationId: 'listRecurringInvoices'
			variables: ListRecurringInvoicesVariables
	  }
	| {
			path: '/api/accounting/recurring-invoices/{id}/'
			operationId: 'getRecurringInvoiceDetails'
			variables: GetRecurringInvoiceDetailsVariables
	  }
	| {
			path: '/api/accounting/recurring-transactions/'
			operationId: 'listRecurringTransactions'
			variables: ListRecurringTransactionsVariables
	  }
	| {
			path: '/api/accounting/recurring-transactions/{id}/'
			operationId: 'getRecurringTransactionDetails'
			variables: GetRecurringTransactionDetailsVariables
	  }
	| {
			path: '/api/accounting/remittance-letters/'
			operationId: 'listRemittanceLetters'
			variables: ListRemittanceLettersVariables
	  }
	| {
			path: '/api/accounting/remittance-letters/{id}/'
			operationId: 'getRemittanceLetterDetails'
			variables: GetRemittanceLetterDetailsVariables
	  }
	| {
			path: '/api/accounting/transaction-import-logs/'
			operationId: 'listTransactionImportLogs'
			variables: ListTransactionImportLogsVariables
	  }
	| {
			path: '/api/accounting/transaction-import-logs/{id}/'
			operationId: 'getTransactionImportLogDetails'
			variables: GetTransactionImportLogDetailsVariables
	  }
	| {
			path: '/api/accounting/transactions/'
			operationId: 'listTransactions'
			variables: ListTransactionsVariables
	  }
	| {
			path: '/api/accounting/transactions/{id}/'
			operationId: 'getTransactionDetails'
			variables: GetTransactionDetailsVariables
	  }
	| {
			path: '/api/ai-questions/'
			operationId: 'listAiQuestions'
			variables: ListAiQuestionsVariables
	  }
	| {
			path: '/api/ai-questions/{id}/'
			operationId: 'getAiQuestionDetails'
			variables: GetAiQuestionDetailsVariables
	  }
	| {
			path: '/api/ai-assistant-thread-messages/'
			operationId: 'listAssistantThreadMessages'
			variables: ListAssistantThreadMessagesVariables
	  }
	| {
			path: '/api/ai-assistant-thread-messages/{id}/'
			operationId: 'getAssistantThreadMessageDetails'
			variables: GetAssistantThreadMessageDetailsVariables
	  }
	| {
			path: '/api/ai-assistant-threads/'
			operationId: 'listAssistantThreads'
			variables: ListAssistantThreadsVariables
	  }
	| {
			path: '/api/ai-assistant-threads/{id}/'
			operationId: 'getAssistantThreadDetails'
			variables: GetAssistantThreadDetailsVariables
	  }
	| {
			path: '/api/api-keys/'
			operationId: 'listApiKeys'
			variables: ListApiKeysVariables
	  }
	| {
			path: '/api/api-keys/{id}/'
			operationId: 'getApiKeyDetails'
			variables: GetApiKeyDetailsVariables
	  }
	| {
			path: '/api/'
			operationId: 'checkApiStatus'
			variables: CheckApiStatusVariables
	  }
	| {
			path: '/api/appliances/'
			operationId: 'listAppliances'
			variables: ListAppliancesVariables
	  }
	| {
			path: '/api/appliances/{id}/'
			operationId: 'getApplianceDetails'
			variables: GetApplianceDetailsVariables
	  }
	| {
			path: '/api/appliance-types/'
			operationId: 'listApplianceTypes'
			variables: ListApplianceTypesVariables
	  }
	| {
			path: '/api/get-user-type/'
			operationId: 'getUserType'
			variables: GetUserTypeVariables
	  }
	| {
			path: '/api/availability-slot-rules/'
			operationId: 'listAvailabilitySlotRules'
			variables: ListAvailabilitySlotRulesVariables
	  }
	| {
			path: '/api/availability-slot-rules/{id}/'
			operationId: 'getAvailabilitySlotRuleDetails'
			variables: GetAvailabilitySlotRuleDetailsVariables
	  }
	| {
			path: '/api/background-checks/'
			operationId: 'listBackgroundChecks'
			variables: ListBackgroundChecksVariables
	  }
	| {
			path: '/api/background-checks/{id}/'
			operationId: 'getBackgroundCheckDetails'
			variables: GetBackgroundCheckDetailsVariables
	  }
	| {
			path: '/api/bank-accounts/'
			operationId: 'listBankAccounts'
			variables: ListBankAccountsVariables
	  }
	| {
			path: '/api/bank-accounts/{id}/'
			operationId: 'getBankAccountDetails'
			variables: GetBankAccountDetailsVariables
	  }
	| {
			path: '/api/saved-bi-queries/'
			operationId: 'listSavedBiQueries'
			variables: ListSavedBiQueriesVariables
	  }
	| {
			path: '/api/saved-bi-queries/{id}/'
			operationId: 'getSavedBiQueryDetails'
			variables: GetSavedBiQueryDetailsVariables
	  }
	| {
			path: '/api/buildings/'
			operationId: 'listBuildings'
			variables: ListBuildingsVariables
	  }
	| {
			path: '/api/buildings/{id}/'
			operationId: 'getBuildingDetails'
			variables: GetBuildingDetailsVariables
	  }
	| {
			path: '/api/bulk-rent-increase-notices/'
			operationId: 'listBulkRentIncreaseNotices'
			variables: ListBulkRentIncreaseNoticesVariables
	  }
	| {
			path: '/api/bulk-rent-increase-notices/{id}/'
			operationId: 'getBulkRentIncreaseNoticeDetails'
			variables: GetBulkRentIncreaseNoticeDetailsVariables
	  }
	| {
			path: '/api/calendar-events/'
			operationId: 'listCalendarEvents'
			variables: ListCalendarEventsVariables
	  }
	| {
			path: '/api/calendar-events/{id}/'
			operationId: 'getCalendarEventDetails'
			variables: GetCalendarEventDetailsVariables
	  }
	| {
			path: '/api/calendar-sharing-relationships/'
			operationId: 'listCalendarSharingRelationships'
			variables: ListCalendarSharingRelationshipsVariables
	  }
	| {
			path: '/api/calendar-sharing-relationships/{id}/'
			operationId: 'getCalendarSharingRelationshipDetails'
			variables: GetCalendarSharingRelationshipDetailsVariables
	  }
	| {
			path: '/api/command-palette/'
			operationId: 'getCommandPaletteData'
			variables: GetCommandPaletteDataVariables
	  }
	| {
			path: '/api/comments/'
			operationId: 'listComments'
			variables: ListCommentsVariables
	  }
	| {
			path: '/api/comments/{id}/'
			operationId: 'getCommentDetails'
			variables: GetCommentDetailsVariables
	  }
	| {
			path: '/api/conversations/'
			operationId: 'listConversations'
			variables: ListConversationsVariables
	  }
	| {
			path: '/api/conversations/{id}/'
			operationId: 'getConversationDetails'
			variables: GetConversationDetailsVariables
	  }
	| {
			path: '/api/credit-cards/'
			operationId: 'listCreditCards'
			variables: ListCreditCardsVariables
	  }
	| {
			path: '/api/credit-cards/{id}/'
			operationId: 'getCreditCardDetails'
			variables: GetCreditCardDetailsVariables
	  }
	| {
			path: '/api/dashboard/'
			operationId: 'getDashboardStats'
			variables: GetDashboardStatsVariables
	  }
	| {
			path: '/api/resident-dashboard/'
			operationId: 'getDashboardStatsForResident'
			variables: GetDashboardStatsForResidentVariables
	  }
	| {
			path: '/api/dashboard-metric-selections/'
			operationId: 'listDashboardMetricSubscriptions'
			variables: ListDashboardMetricSubscriptionsVariables
	  }
	| {
			path: '/api/dashboard-metric-selections/{id}/'
			operationId: 'getDashboardMetricSubscriptionDetails'
			variables: GetDashboardMetricSubscriptionDetailsVariables
	  }
	| {
			path: '/api/dashboard-metric-choices/summary/'
			operationId: 'listSummaryDashboardMetricChoices'
			variables: ListSummaryDashboardMetricChoicesVariables
	  }
	| {
			path: '/api/dashboard-metric-choices/leasing/'
			operationId: 'listLeasingDashboardMetricChoices'
			variables: ListLeasingDashboardMetricChoicesVariables
	  }
	| {
			path: '/api/dashboard-metric-choices/operations/'
			operationId: 'listOperationsDashboardMetricChoices'
			variables: ListOperationsDashboardMetricChoicesVariables
	  }
	| {
			path: '/api/documents/'
			operationId: 'listDocuments'
			variables: ListDocumentsVariables
	  }
	| {
			path: '/api/documents/{id}/'
			operationId: 'getDocumentDetails'
			variables: GetDocumentDetailsVariables
	  }
	| {
			path: '/api/document-types/'
			operationId: 'listDocumentTypes'
			variables: ListDocumentTypesVariables
	  }
	| {
			path: '/api/email-attachments/'
			operationId: 'listEmailAttachments'
			variables: ListEmailAttachmentsVariables
	  }
	| {
			path: '/api/email-attachments/{id}/'
			operationId: 'getEmailAttachmentDetails'
			variables: GetEmailAttachmentDetailsVariables
	  }
	| {
			path: '/api/email-domains/'
			operationId: 'listEmailDomains'
			variables: ListEmailDomainsVariables
	  }
	| {
			path: '/api/email-domains/{id}/'
			operationId: 'getEmailDomainDetails'
			variables: GetEmailDomainDetailsVariables
	  }
	| {
			path: '/api/email-message-batches/'
			operationId: 'listEmailMessageBatches'
			variables: ListEmailMessageBatchesVariables
	  }
	| {
			path: '/api/email-message-batches/{id}/'
			operationId: 'getEmailMessageBatchDetails'
			variables: GetEmailMessageBatchDetailsVariables
	  }
	| {
			path: '/api/email-messages/'
			operationId: 'listEmailMessages'
			variables: ListEmailMessagesVariables
	  }
	| {
			path: '/api/email-messages/{id}/'
			operationId: 'getEmailMessageDetails'
			variables: GetEmailMessageDetailsVariables
	  }
	| {
			path: '/api/email-senders/'
			operationId: 'listEmailSenders'
			variables: ListEmailSendersVariables
	  }
	| {
			path: '/api/email-senders/{id}/'
			operationId: 'getEmailSenderDetails'
			variables: GetEmailSenderDetailsVariables
	  }
	| {
			path: '/api/email-templates/'
			operationId: 'listEmailTemplates'
			variables: ListEmailTemplatesVariables
	  }
	| {
			path: '/api/email-templates/{id}/'
			operationId: 'getEmailTemplateDetails'
			variables: GetEmailTemplateDetailsVariables
	  }
	| {
			path: '/api/email-template-types/'
			operationId: 'listEmailTemplateTypes'
			variables: ListEmailTemplateTypesVariables
	  }
	| {
			path: '/api/equipment/'
			operationId: 'listEquipment'
			variables: ListEquipmentVariables
	  }
	| {
			path: '/api/equipment/{id}/'
			operationId: 'getEquipmentDetails'
			variables: GetEquipmentDetailsVariables
	  }
	| {
			path: '/api/equipment-rental-rates/'
			operationId: 'listEquipmentRentalRate'
			variables: ListEquipmentRentalRateVariables
	  }
	| {
			path: '/api/equipment-rental-rates/{id}/'
			operationId: 'getEquipmentRentalRateDetails'
			variables: GetEquipmentRentalRateDetailsVariables
	  }
	| {
			path: '/api/equipment-rentals/'
			operationId: 'listEquipmentRental'
			variables: ListEquipmentRentalVariables
	  }
	| {
			path: '/api/equipment-rentals/{id}/'
			operationId: 'getEquipmentRentalDetails'
			variables: GetEquipmentRentalDetailsVariables
	  }
	| {
			path: '/api/features/'
			operationId: 'listFeatureFlags'
			variables: ListFeatureFlagsVariables
	  }
	| {
			path: '/api/gate-access-codes/'
			operationId: 'listGateAccessCodes'
			variables: ListGateAccessCodesVariables
	  }
	| {
			path: '/api/gate-access-codes/{id}/'
			operationId: 'getGateAccessCodeDetails'
			variables: GetGateAccessCodeDetailsVariables
	  }
	| {
			path: '/api/gate-access-logs/'
			operationId: 'listGateAccessLogs'
			variables: ListGateAccessLogsVariables
	  }
	| {
			path: '/api/gate-access-logs/{id}/'
			operationId: 'getGateAccessLogDetails'
			variables: GetGateAccessLogDetailsVariables
	  }
	| {
			path: '/api/geo/cities/'
			operationId: 'listCities'
			variables: ListCitiesVariables
	  }
	| {
			path: '/api/geo/countries/'
			operationId: 'listCountries'
			variables: ListCountriesVariables
	  }
	| {
			path: '/api/geo/states/'
			operationId: 'listStates'
			variables: ListStatesVariables
	  }
	| {
			path: '/api/notifications/'
			operationId: 'listNotifications'
			variables: ListNotificationsVariables
	  }
	| {
			path: '/api/notifications/{id}/'
			operationId: 'getNotificationDetails'
			variables: GetNotificationDetailsVariables
	  }
	| {
			path: '/api/inspection-reports/'
			operationId: 'listInspectionReports'
			variables: ListInspectionReportsVariables
	  }
	| {
			path: '/api/inspection-reports/{id}/'
			operationId: 'getInspectionReportDetails'
			variables: GetInspectionReportDetailsVariables
	  }
	| {
			path: '/api/insurance-accounts/'
			operationId: 'listInsuranceAccounts'
			variables: ListInsuranceAccountsVariables
	  }
	| {
			path: '/api/insurance-accounts/{id}/'
			operationId: 'getInsuranceAccountDetails'
			variables: GetInsuranceAccountDetailsVariables
	  }
	| {
			path: '/api/insurance-claims/'
			operationId: 'listInsuranceClaims'
			variables: ListInsuranceClaimsVariables
	  }
	| {
			path: '/api/insurance-claims/{id}/'
			operationId: 'getInsuranceClaimDetails'
			variables: GetInsuranceClaimDetailsVariables
	  }
	| {
			path: '/api/insurance-policies/'
			operationId: 'listInsurancePolicys'
			variables: ListInsurancePolicysVariables
	  }
	| {
			path: '/api/insurance-policies/{id}/'
			operationId: 'getInsurancePolicyDetails'
			variables: GetInsurancePolicyDetailsVariables
	  }
	| {
			path: '/api/insurance-submissions/'
			operationId: 'listInsuranceSubmissions'
			variables: ListInsuranceSubmissionsVariables
	  }
	| {
			path: '/api/insurance-submissions/{id}/'
			operationId: 'getInsuranceSubmissionDetails'
			variables: GetInsuranceSubmissionDetailsVariables
	  }
	| {
			path: '/api/inventory-items/'
			operationId: 'listInventoryItems'
			variables: ListInventoryItemsVariables
	  }
	| {
			path: '/api/inventory-items/{id}/'
			operationId: 'getInventoryItemDetails'
			variables: GetInventoryItemDetailsVariables
	  }
	| {
			path: '/api/inventory-transactions/'
			operationId: 'listInventoryTransactions'
			variables: ListInventoryTransactionsVariables
	  }
	| {
			path: '/api/inventory-transactions/{id}/'
			operationId: 'getInventoryTransactionDetails'
			variables: GetInventoryTransactionDetailsVariables
	  }
	| {
			path: '/api/property-owners/'
			operationId: 'listPropertyOwners'
			variables: ListPropertyOwnersVariables
	  }
	| {
			path: '/api/property-owners/{id}/'
			operationId: 'getPropertyOwnerDetails'
			variables: GetPropertyOwnerDetailsVariables
	  }
	| {
			path: '/api/investors/'
			operationId: 'listInvestors'
			variables: ListInvestorsVariables
	  }
	| {
			path: '/api/investors/{id}/'
			operationId: 'getInvestorDetails'
			variables: GetInvestorDetailsVariables
	  }
	| {
			path: '/api/late-fee-schedules/'
			operationId: 'listLateFeeSchedules'
			variables: ListLateFeeSchedulesVariables
	  }
	| {
			path: '/api/late-fee-schedules/{id}/'
			operationId: 'getLateFeeScheduleDetails'
			variables: GetLateFeeScheduleDetailsVariables
	  }
	| {
			path: '/api/lease-attachments/'
			operationId: 'listLeaseAttachments'
			variables: ListLeaseAttachmentsVariables
	  }
	| {
			path: '/api/lease-attachments/{id}/'
			operationId: 'getLeaseAttachmentDetails'
			variables: GetLeaseAttachmentDetailsVariables
	  }
	| {
			path: '/api/leases/'
			operationId: 'listLeases'
			variables: ListLeasesVariables
	  }
	| {
			path: '/api/leases/{id}/'
			operationId: 'getLeaseDetails'
			variables: GetLeaseDetailsVariables
	  }
	| {
			path: '/api/leases/{id}/rent-account/'
			operationId: 'getLeaseRentAccount'
			variables: GetLeaseRentAccountVariables
	  }
	| {
			path: '/api/leases/make-ready-board'
			operationId: 'getMakeReadyBoard'
			variables: GetMakeReadyBoardVariables
	  }
	| {
			path: '/api/get-my-lease-details/'
			operationId: 'getMyLeaseDetails'
			variables: GetMyLeaseDetailsVariables
	  }
	| {
			path: '/api/vacancies/'
			operationId: 'getUpcomingVacancies'
			variables: GetUpcomingVacanciesVariables
	  }
	| {
			path: '/api/eligible-rent-increases/'
			operationId: 'listEligibleRentIncreases'
			variables: ListEligibleRentIncreasesVariables
	  }
	| {
			path: '/api/lenders/'
			operationId: 'listLenders'
			variables: ListLendersVariables
	  }
	| {
			path: '/api/lenders/{id}/'
			operationId: 'getLenderDetails'
			variables: GetLenderDetailsVariables
	  }
	| {
			path: '/api/days-of-week'
			operationId: 'listDaysOfWeek'
			variables: ListDaysOfWeekVariables
	  }
	| {
			path: '/api/workspace-warnings/'
			operationId: 'listWorkspaceWarnings'
			variables: ListWorkspaceWarningsVariables
	  }
	| {
			path: '/api/rental-unit-listings/'
			operationId: 'listRentalUnitListings'
			variables: ListRentalUnitListingsVariables
	  }
	| {
			path: '/api/rental-unit-listings/{id}/'
			operationId: 'getRentalUnitListingDetails'
			variables: GetRentalUnitListingDetailsVariables
	  }
	| {
			path: '/api/rental-unit-listing-photos/{id}/'
			operationId: 'listRentalUnitListingPhotos'
			variables: ListRentalUnitListingPhotosVariables
	  }
	| {
			path: '/api/maintenance-projects/'
			operationId: 'listMaintenanceProjects'
			variables: ListMaintenanceProjectsVariables
	  }
	| {
			path: '/api/maintenance-projects/{id}/'
			operationId: 'getMaintenanceProjectDetails'
			variables: GetMaintenanceProjectDetailsVariables
	  }
	| {
			path: '/api/maintenance-technicians/'
			operationId: 'listMaintenanceTechnicians'
			variables: ListMaintenanceTechniciansVariables
	  }
	| {
			path: '/api/maintenance-technicians/{id}/'
			operationId: 'getMaintenanceTechnicianDetails'
			variables: GetMaintenanceTechnicianDetailsVariables
	  }
	| {
			path: '/api/leasing/make-readies/'
			operationId: 'listMakeReadies'
			variables: ListMakeReadiesVariables
	  }
	| {
			path: '/api/leasing/make-readies/{id}/'
			operationId: 'getMakeReadyDetails'
			variables: GetMakeReadyDetailsVariables
	  }
	| {
			path: '/api/make-ready-task-categories/'
			operationId: 'listMakeReadyTaskCategories'
			variables: ListMakeReadyTaskCategoriesVariables
	  }
	| {
			path: '/api/management-fee-payments/'
			operationId: 'listManagementFeePayments'
			variables: ListManagementFeePaymentsVariables
	  }
	| {
			path: '/api/management-fee-payments/{id}/'
			operationId: 'getManagementFeePaymentDetails'
			variables: GetManagementFeePaymentDetailsVariables
	  }
	| {
			path: '/api/management-fee-receivables/'
			operationId: 'listManagementFeeReceivables'
			variables: ListManagementFeeReceivablesVariables
	  }
	| {
			path: '/api/management-fee-receivables/{id}/'
			operationId: 'getManagementFeeReceivableDetails'
			variables: GetManagementFeeReceivableDetailsVariables
	  }
	| {
			path: '/api/meeting-minutes/'
			operationId: 'listMeetingMinutess'
			variables: ListMeetingMinutessVariables
	  }
	| {
			path: '/api/meeting-minutes/{id}/'
			operationId: 'getMeetingMinutesDetails'
			variables: GetMeetingMinutesDetailsVariables
	  }
	| {
			path: '/api/messages/'
			operationId: 'listMessages'
			variables: ListMessagesVariables
	  }
	| {
			path: '/api/messages/{id}/'
			operationId: 'getMessageDetails'
			variables: GetMessageDetailsVariables
	  }
	| {
			path: '/api/mileage-readings/'
			operationId: 'listMileageReadings'
			variables: ListMileageReadingsVariables
	  }
	| {
			path: '/api/mileage-readings/{id}/'
			operationId: 'getMileageReadingDetails'
			variables: GetMileageReadingDetailsVariables
	  }
	| {
			path: '/api/mvft/'
			operationId: 'getMvft'
			variables: GetMvftVariables
	  }
	| {
			path: '/api/mortgages/'
			operationId: 'listMortgages'
			variables: ListMortgagesVariables
	  }
	| {
			path: '/api/mortgages/{id}/'
			operationId: 'getMortgageDetails'
			variables: GetMortgageDetailsVariables
	  }
	| {
			path: '/api/mortgages/{id}/amortization-schedule/'
			operationId: 'getMortgageAmortizationSchedule'
			variables: GetMortgageAmortizationScheduleVariables
	  }
	| {
			path: '/api/loan-amortizations/'
			operationId: 'listLoanAmortizations'
			variables: ListLoanAmortizationsVariables
	  }
	| {
			path: '/api/payment-frequencies/'
			operationId: 'listPaymentFrequencies'
			variables: ListPaymentFrequenciesVariables
	  }
	| {
			path: '/api/notes/'
			operationId: 'listNotes'
			variables: ListNotesVariables
	  }
	| {
			path: '/api/notes/{id}/'
			operationId: 'getNoteDetails'
			variables: GetNoteDetailsVariables
	  }
	| {
			path: '/api/nps/'
			operationId: 'listNpsSurveyResponses'
			variables: ListNpsSurveyResponsesVariables
	  }
	| {
			path: '/api/ownership-stakes/'
			operationId: 'listOwnershipStakess'
			variables: ListOwnershipStakessVariables
	  }
	| {
			path: '/api/ownership-stakes/{id}/'
			operationId: 'getOwnershipStakesDetails'
			variables: GetOwnershipStakesDetailsVariables
	  }
	| {
			path: '/api/parking-spaces/'
			operationId: 'listParkingSpaces'
			variables: ListParkingSpacesVariables
	  }
	| {
			path: '/api/parking-spaces/{id}/'
			operationId: 'getParkingSpaceDetails'
			variables: GetParkingSpaceDetailsVariables
	  }
	| {
			path: '/api/parking-space-types'
			operationId: 'listParkingSpaceTypes'
			variables: ListParkingSpaceTypesVariables
	  }
	| {
			path: '/api/phone-call-logs/'
			operationId: 'listPhoneCallLogs'
			variables: ListPhoneCallLogsVariables
	  }
	| {
			path: '/api/phone-call-logs/{id}/'
			operationId: 'getPhoneCallLogDetails'
			variables: GetPhoneCallLogDetailsVariables
	  }
	| {
			path: '/api/photos/'
			operationId: 'listPhotos'
			variables: ListPhotosVariables
	  }
	| {
			path: '/api/photos/{id}/'
			operationId: 'getPhotoDetails'
			variables: GetPhotoDetailsVariables
	  }
	| {
			path: '/api/property-groups/'
			operationId: 'listPropertyGroups'
			variables: ListPropertyGroupsVariables
	  }
	| {
			path: '/api/property-groups/{id}/'
			operationId: 'getPropertyGroupDetails'
			variables: GetPropertyGroupDetailsVariables
	  }
	| {
			path: '/api/property-inspection-question-answers/'
			operationId: 'listPropertyInspectionQuestionAnswers'
			variables: ListPropertyInspectionQuestionAnswersVariables
	  }
	| {
			path: '/api/property-inspection-question-answers/{id}/'
			operationId: 'getPropertyInspectionQuestionAnswerDetails'
			variables: GetPropertyInspectionQuestionAnswerDetailsVariables
	  }
	| {
			path: '/api/property-inspection-questions/'
			operationId: 'listPropertyInspectionQuestions'
			variables: ListPropertyInspectionQuestionsVariables
	  }
	| {
			path: '/api/property-inspection-questions/{id}/'
			operationId: 'getPropertyInspectionQuestionDetails'
			variables: GetPropertyInspectionQuestionDetailsVariables
	  }
	| {
			path: '/api/property-inspection-question-choices/'
			operationId: 'listPropertyInspectionQuestionChoices'
			variables: ListPropertyInspectionQuestionChoicesVariables
	  }
	| {
			path: '/api/property-inspection-submissions/'
			operationId: 'listPropertyInspectionSubmissions'
			variables: ListPropertyInspectionSubmissionsVariables
	  }
	| {
			path: '/api/property-inspection-submissions/{id}/'
			operationId: 'getPropertyInspectionSubmissionDetails'
			variables: GetPropertyInspectionSubmissionDetailsVariables
	  }
	| {
			path: '/api/property-inspections/'
			operationId: 'listPropertyInspections'
			variables: ListPropertyInspectionsVariables
	  }
	| {
			path: '/api/property-inspections/{id}/'
			operationId: 'getPropertyInspectionDetails'
			variables: GetPropertyInspectionDetailsVariables
	  }
	| {
			path: '/api/property-management-company/'
			operationId: 'getPropertyManagementCompanyDetails'
			variables: GetPropertyManagementCompanyDetailsVariables
	  }
	| {
			path: '/api/property-management-company/{slug}/'
			operationId: 'publicGetPropertyManagementCompanyDetails'
			variables: PublicGetPropertyManagementCompanyDetailsVariables
	  }
	| {
			path: '/api/property-management-fee-schedules/'
			operationId: 'listPropertyManagementFeeSchedules'
			variables: ListPropertyManagementFeeSchedulesVariables
	  }
	| {
			path: '/api/property-management-fee-schedules/{id}/'
			operationId: 'getPropertyManagementFeeScheduleDetails'
			variables: GetPropertyManagementFeeScheduleDetailsVariables
	  }
	| {
			path: '/api/property-management-revenue/'
			operationId: 'listPropertyManagementRevenue'
			variables: ListPropertyManagementRevenueVariables
	  }
	| {
			path: '/api/property-managers/'
			operationId: 'listPropertyManagers'
			variables: ListPropertyManagersVariables
	  }
	| {
			path: '/api/property-managers/{id}/'
			operationId: 'getPropertyManagerDetails'
			variables: GetPropertyManagerDetailsVariables
	  }
	| {
			path: '/api/public-calendar-event-types/'
			operationId: 'listPublicCalendarEventTypes'
			variables: ListPublicCalendarEventTypesVariables
	  }
	| {
			path: '/api/public-calendar-event-types/{id}/'
			operationId: 'getPublicCalendarEventTypeDetails'
			variables: GetPublicCalendarEventTypeDetailsVariables
	  }
	| {
			path: '/api/recurring-journal-entries/'
			operationId: 'listRecurringJournalEntries'
			variables: ListRecurringJournalEntriesVariables
	  }
	| {
			path: '/api/recurring-journal-entries/{id}/'
			operationId: 'getRecurringJournalEntryDetails'
			variables: GetRecurringJournalEntryDetailsVariables
	  }
	| {
			path: '/api/recurring-tasks/'
			operationId: 'listRecurringTasks'
			variables: ListRecurringTasksVariables
	  }
	| {
			path: '/api/recurring-tasks/{id}/'
			operationId: 'getRecurringTaskDetails'
			variables: GetRecurringTaskDetailsVariables
	  }
	| {
			path: '/api/recurring-work-orders/'
			operationId: 'listRecurringWorkOrders'
			variables: ListRecurringWorkOrdersVariables
	  }
	| {
			path: '/api/recurring-work-orders/{id}/'
			operationId: 'getRecurringWorkOrderDetails'
			variables: GetRecurringWorkOrderDetailsVariables
	  }
	| {
			path: '/api/referral/affiliates/'
			operationId: 'listAffiliates'
			variables: ListAffiliatesVariables
	  }
	| {
			path: '/api/referral/affiliate-incentives/'
			operationId: 'listAffiliateIncentives'
			variables: ListAffiliateIncentivesVariables
	  }
	| {
			path: '/api/referral/affiliate-invoice-line-items/'
			operationId: 'listAffiliateInvoiceLineItems'
			variables: ListAffiliateInvoiceLineItemsVariables
	  }
	| {
			path: '/api/referral/affiliate-invoices/'
			operationId: 'listAffiliateInvoices'
			variables: ListAffiliateInvoicesVariables
	  }
	| {
			path: '/api/rent-charges/'
			operationId: 'listRentCharges'
			variables: ListRentChargesVariables
	  }
	| {
			path: '/api/rent-charges/{id}/'
			operationId: 'getRentChargeDetails'
			variables: GetRentChargeDetailsVariables
	  }
	| {
			path: '/api/rent-increases/'
			operationId: 'listRentIncreases'
			variables: ListRentIncreasesVariables
	  }
	| {
			path: '/api/rent-increases/{id}/'
			operationId: 'getRentIncreaseDetails'
			variables: GetRentIncreaseDetailsVariables
	  }
	| {
			path: '/api/rent-payment-schedules/'
			operationId: 'listRentPaymentSchedules'
			variables: ListRentPaymentSchedulesVariables
	  }
	| {
			path: '/api/rent-payment-schedules/{id}/'
			operationId: 'getRentPaymentScheduleDetails'
			variables: GetRentPaymentScheduleDetailsVariables
	  }
	| {
			path: '/api/rent-payments/'
			operationId: 'listRentPayments'
			variables: ListRentPaymentsVariables
	  }
	| {
			path: '/api/rent-payments/{id}/'
			operationId: 'getRentPaymentDetails'
			variables: GetRentPaymentDetailsVariables
	  }
	| {
			path: '/api/rent-receivables/'
			operationId: 'listRentReceivables'
			variables: ListRentReceivablesVariables
	  }
	| {
			path: '/api/rent-receivables/{id}/'
			operationId: 'getRentReceivableDetails'
			variables: GetRentReceivableDetailsVariables
	  }
	| {
			path: '/api/rental-application-forms/'
			operationId: 'listRentalApplicationForms'
			variables: ListRentalApplicationFormsVariables
	  }
	| {
			path: '/api/rental-application-forms/{id}/'
			operationId: 'getRentalApplicationFormDetails'
			variables: GetRentalApplicationFormDetailsVariables
	  }
	| {
			path: '/api/rental-application-form-listings/{id}/'
			operationId: 'listRentalApplicationFormListings'
			variables: ListRentalApplicationFormListingsVariables
	  }
	| {
			path: '/api/rental-application-question-answers/'
			operationId: 'listRentalApplicationQuestionAnswers'
			variables: ListRentalApplicationQuestionAnswersVariables
	  }
	| {
			path: '/api/rental-application-question-answers/{id}/'
			operationId: 'getRentalApplicationQuestionAnswerDetails'
			variables: GetRentalApplicationQuestionAnswerDetailsVariables
	  }
	| {
			path: '/api/rental-application-questions/'
			operationId: 'listRentalApplicationQuestions'
			variables: ListRentalApplicationQuestionsVariables
	  }
	| {
			path: '/api/rental-application-questions/{id}/'
			operationId: 'getRentalApplicationQuestionDetails'
			variables: GetRentalApplicationQuestionDetailsVariables
	  }
	| {
			path: '/api/rental-application-question-choices/'
			operationId: 'listRentalApplicationQuestionChoices'
			variables: ListRentalApplicationQuestionChoicesVariables
	  }
	| {
			path: '/api/rental-application-submissions/'
			operationId: 'listRentalApplicationSubmissions'
			variables: ListRentalApplicationSubmissionsVariables
	  }
	| {
			path: '/api/rental-application-submissions/{id}/'
			operationId: 'getRentalApplicationSubmissionDetails'
			variables: GetRentalApplicationSubmissionDetailsVariables
	  }
	| {
			path: '/api/waitlist-subscribers/'
			operationId: 'listWaitlistSubscribers'
			variables: ListWaitlistSubscribersVariables
	  }
	| {
			path: '/api/waitlist-subscribers/{id}/'
			operationId: 'getWaitlistSubscriberDetails'
			variables: GetWaitlistSubscriberDetailsVariables
	  }
	| {
			path: '/api/rental-units/'
			operationId: 'listRentalUnits'
			variables: ListRentalUnitsVariables
	  }
	| {
			path: '/api/rental-units/{id}/'
			operationId: 'getRentalUnitDetails'
			variables: GetRentalUnitDetailsVariables
	  }
	| {
			path: '/api/rental-units/{id}/current-lease/'
			operationId: 'getRentalUnitCurrentLease'
			variables: GetRentalUnitCurrentLeaseVariables
	  }
	| {
			path: '/api/unit-types/'
			operationId: 'listUnitTypes'
			variables: ListUnitTypesVariables
	  }
	| {
			path: '/api/reporting/report-types/'
			operationId: 'listReportTypes'
			variables: ListReportTypesVariables
	  }
	| {
			path: '/api/resident-identification/'
			operationId: 'listResidentIdentifications'
			variables: ListResidentIdentificationsVariables
	  }
	| {
			path: '/api/resident-identification/{id}/'
			operationId: 'getResidentIdentificationDetails'
			variables: GetResidentIdentificationDetailsVariables
	  }
	| {
			path: '/api/resident-pets/'
			operationId: 'listResidentPets'
			variables: ListResidentPetsVariables
	  }
	| {
			path: '/api/resident-pets/{id}/'
			operationId: 'getResidentPetDetails'
			variables: GetResidentPetDetailsVariables
	  }
	| {
			path: '/api/pet-types/'
			operationId: 'listPetTypes'
			variables: ListPetTypesVariables
	  }
	| {
			path: '/api/resident-vehicles/'
			operationId: 'listResidentVehicles'
			variables: ListResidentVehiclesVariables
	  }
	| {
			path: '/api/resident-vehicles/{id}/'
			operationId: 'getResidentVehicleDetails'
			variables: GetResidentVehicleDetailsVariables
	  }
	| {
			path: '/api/residents/'
			operationId: 'listResidents'
			variables: ListResidentsVariables
	  }
	| {
			path: '/api/residents/{id}/'
			operationId: 'getResidentDetails'
			variables: GetResidentDetailsVariables
	  }
	| {
			path: '/api/scheduled-reports/'
			operationId: 'listScheduledReports'
			variables: ListScheduledReportsVariables
	  }
	| {
			path: '/api/scheduled-reports/{id}/'
			operationId: 'getScheduledReportDetails'
			variables: GetScheduledReportDetailsVariables
	  }
	| {
			path: '/api/scheduled-reports/cadences'
			operationId: 'listScheduledReportCadences'
			variables: ListScheduledReportCadencesVariables
	  }
	| {
			path: '/api/security-deposit-claims/'
			operationId: 'listSecurityDepositClaims'
			variables: ListSecurityDepositClaimsVariables
	  }
	| {
			path: '/api/security-deposit-claims/{id}/'
			operationId: 'getSecurityDepositClaimDetails'
			variables: GetSecurityDepositClaimDetailsVariables
	  }
	| {
			path: '/api/security-deposit-payments/'
			operationId: 'listSecurityDepositPayments'
			variables: ListSecurityDepositPaymentsVariables
	  }
	| {
			path: '/api/security-deposit-payments/{id}/'
			operationId: 'getSecurityDepositPaymentDetails'
			variables: GetSecurityDepositPaymentDetailsVariables
	  }
	| {
			path: '/api/security-deposits/'
			operationId: 'listSecurityDeposits'
			variables: ListSecurityDepositsVariables
	  }
	| {
			path: '/api/security-deposits/{id}/'
			operationId: 'getSecurityDepositDetails'
			variables: GetSecurityDepositDetailsVariables
	  }
	| {
			path: '/api/signature-request-signers/'
			operationId: 'listSignatureRequestSigners'
			variables: ListSignatureRequestSignersVariables
	  }
	| {
			path: '/api/signature-request-signers/{id}/'
			operationId: 'getSignatureRequestSignerDetails'
			variables: GetSignatureRequestSignerDetailsVariables
	  }
	| {
			path: '/api/signature-requests/'
			operationId: 'listSignatureRequests'
			variables: ListSignatureRequestsVariables
	  }
	| {
			path: '/api/signature-requests/{id}/'
			operationId: 'getSignatureRequestDetails'
			variables: GetSignatureRequestDetailsVariables
	  }
	| {
			path: '/api/sms-message-batches/'
			operationId: 'listSmsMessageBatchs'
			variables: ListSmsMessageBatchsVariables
	  }
	| {
			path: '/api/sms-message-batches/{id}/'
			operationId: 'getSmsMessageBatchDetails'
			variables: GetSmsMessageBatchDetailsVariables
	  }
	| {
			path: '/api/sms-messages/'
			operationId: 'listSmsMessages'
			variables: ListSmsMessagesVariables
	  }
	| {
			path: '/api/sms-messages/{id}/'
			operationId: 'getSmsMessageDetails'
			variables: GetSmsMessageDetailsVariables
	  }
	| {
			path: '/api/storage-customers/'
			operationId: 'listStorageCustomers'
			variables: ListStorageCustomersVariables
	  }
	| {
			path: '/api/storage-customers/{id}/'
			operationId: 'getStorageCustomerDetails'
			variables: GetStorageCustomerDetailsVariables
	  }
	| {
			path: '/api/storage-facility-gates/'
			operationId: 'listStorageFacilityGates'
			variables: ListStorageFacilityGatesVariables
	  }
	| {
			path: '/api/storage-facility-gates/{id}/'
			operationId: 'getStorageFacilityGateDetails'
			variables: GetStorageFacilityGateDetailsVariables
	  }
	| {
			path: '/api/storage-unit-invoice-line-items/'
			operationId: 'listStorageUnitInvoiceLineItems'
			variables: ListStorageUnitInvoiceLineItemsVariables
	  }
	| {
			path: '/api/storage-unit-invoice-line-items/{id}/'
			operationId: 'getStorageUnitInvoiceLineItemDetails'
			variables: GetStorageUnitInvoiceLineItemDetailsVariables
	  }
	| {
			path: '/api/storage-unit-invoices/'
			operationId: 'listStorageUnitInvoices'
			variables: ListStorageUnitInvoicesVariables
	  }
	| {
			path: '/api/storage-unit-invoices/{id}/'
			operationId: 'getStorageUnitInvoiceDetails'
			variables: GetStorageUnitInvoiceDetailsVariables
	  }
	| {
			path: '/api/storage-unit-leases/'
			operationId: 'listStorageUnitLeases'
			variables: ListStorageUnitLeasesVariables
	  }
	| {
			path: '/api/storage-unit-leases/{id}/'
			operationId: 'getStorageUnitLeaseDetails'
			variables: GetStorageUnitLeaseDetailsVariables
	  }
	| {
			path: '/api/storage-unit-types/'
			operationId: 'listStorageUnitTypes'
			variables: ListStorageUnitTypesVariables
	  }
	| {
			path: '/api/storage-unit-types/{id}/'
			operationId: 'getStorageUnitTypeDetails'
			variables: GetStorageUnitTypeDetailsVariables
	  }
	| {
			path: '/api/stripe/stripe-accounts/{id}/account-onboarding-session/'
			operationId: 'generateAccountOnboardingSession'
			variables: GenerateAccountOnboardingSessionVariables
	  }
	| {
			path: '/api/stripe/stripe-accounts/'
			operationId: 'listStripeAccounts'
			variables: ListStripeAccountsVariables
	  }
	| {
			path: '/api/stripe/stripe-accounts/{id}/'
			operationId: 'getStripeAccountDetails'
			variables: GetStripeAccountDetailsVariables
	  }
	| {
			path: '/api/support-tickets/'
			operationId: 'listSupportTickets'
			variables: ListSupportTicketsVariables
	  }
	| {
			path: '/api/support-tickets/{id}/'
			operationId: 'getSupportTicketDetails'
			variables: GetSupportTicketDetailsVariables
	  }
	| {
			path: '/api/tasks/'
			operationId: 'listTasks'
			variables: ListTasksVariables
	  }
	| {
			path: '/api/tasks/{id}/'
			operationId: 'getTaskDetails'
			variables: GetTaskDetailsVariables
	  }
	| {
			path: '/api/time-tracking-entries/'
			operationId: 'listTimeTrackingEntries'
			variables: ListTimeTrackingEntriesVariables
	  }
	| {
			path: '/api/time-tracking-entries/{id}/'
			operationId: 'getTimeTrackingEntryDetails'
			variables: GetTimeTrackingEntryDetailsVariables
	  }
	| {
			path: '/api/user-permissions]/'
			operationId: 'getUserPermissions'
			variables: GetUserPermissionsVariables
	  }
	| {
			path: '/api/user-settings/'
			operationId: 'getUserSettings'
			variables: GetUserSettingsVariables
	  }
	| {
			path: '/api/utility-meter-readings/'
			operationId: 'listUtilityMeterReadings'
			variables: ListUtilityMeterReadingsVariables
	  }
	| {
			path: '/api/utility-meter-readings/{id}/'
			operationId: 'getUtilityMeterReadingDetails'
			variables: GetUtilityMeterReadingDetailsVariables
	  }
	| {
			path: '/api/utility-meters/'
			operationId: 'listUtilityMeters'
			variables: ListUtilityMetersVariables
	  }
	| {
			path: '/api/utility-meters/{id}/'
			operationId: 'getUtilityMeterDetails'
			variables: GetUtilityMeterDetailsVariables
	  }
	| {
			path: '/api/vehicles/'
			operationId: 'listVehicles'
			variables: ListVehiclesVariables
	  }
	| {
			path: '/api/vehicles/{id}/'
			operationId: 'getVehicleDetails'
			variables: GetVehicleDetailsVariables
	  }
	| {
			path: '/api/vendor-credit-transactions/'
			operationId: 'listVendorCreditTransactions'
			variables: ListVendorCreditTransactionsVariables
	  }
	| {
			path: '/api/vendor-credit-transactions/{id}/'
			operationId: 'getVendorCreditTransactionDetails'
			variables: GetVendorCreditTransactionDetailsVariables
	  }
	| {
			path: '/api/vendor-credits/'
			operationId: 'listVendorCredits'
			variables: ListVendorCreditsVariables
	  }
	| {
			path: '/api/vendor-credits/{id}/'
			operationId: 'getVendorCreditDetails'
			variables: GetVendorCreditDetailsVariables
	  }
	| {
			path: '/api/vendor-credit-types/'
			operationId: 'listVendorCreditTypes'
			variables: ListVendorCreditTypesVariables
	  }
	| {
			path: '/api/vendors/'
			operationId: 'listVendors'
			variables: ListVendorsVariables
	  }
	| {
			path: '/api/vendors/{id}/'
			operationId: 'getVendorDetails'
			variables: GetVendorDetailsVariables
	  }
	| {
			path: '/api/work-logs/'
			operationId: 'listWorkLogs'
			variables: ListWorkLogsVariables
	  }
	| {
			path: '/api/work-logs/{id}/'
			operationId: 'getWorkLogDetails'
			variables: GetWorkLogDetailsVariables
	  }
	| {
			path: '/api/work-order-purchases/'
			operationId: 'listWorkOrderPurchases'
			variables: ListWorkOrderPurchasesVariables
	  }
	| {
			path: '/api/work-order-purchases/{id}/'
			operationId: 'getWorkOrderPurchaseDetails'
			variables: GetWorkOrderPurchaseDetailsVariables
	  }
	| {
			path: '/api/work-orders/'
			operationId: 'listWorkOrders'
			variables: ListWorkOrdersVariables
	  }
	| {
			path: '/api/work-orders/{id}/'
			operationId: 'getWorkOrderDetails'
			variables: GetWorkOrderDetailsVariables
	  }
	| {
			path: '/api/invoiceable-work-orders/'
			operationId: 'listInvoiceableWorkOrders'
			variables: ListInvoiceableWorkOrdersVariables
	  }
	| {
			path: '/api/work-orders/categories'
			operationId: 'listWorkOrderCategories'
			variables: ListWorkOrderCategoriesVariables
	  }
	| {
			path: '/api/work-orders/statuses'
			operationId: 'listWorkOrderStatuses'
			variables: ListWorkOrderStatusesVariables
	  }
	| {
			path: '/api/zapier/'
			operationId: 'testZapierAuthentication'
			variables: TestZapierAuthenticationVariables
	  }
