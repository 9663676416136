import mousetrap from 'mousetrap'
import { useEffect, useRef } from 'react'

/**
 * Use mousetrap hook
 *
 * @param  {(string | string[])} handlerKey - A key, key combo or array of combos according to Mousetrap documentation.
 * @param  { function } handlerCallback - A function that is triggered on key combo catch.
 * @param  { string } evtType - A string that specifies the type of event to listen for. It can be 'keypress', 'keydown' or 'keyup'.
 */
export default (
	handlerKey: string,
	handlerCallback: any,
	evtType: string
): void => {
	const actionRef = useRef(null)
	actionRef.current = handlerCallback

	useEffect(() => {
		mousetrap.bind(
			handlerKey,
			(evt, combo) => {
				if (typeof actionRef.current === 'function') {
					// @ts-expect-error this behavior is intended
					actionRef.current(evt, combo)
					return false
				}
				return true
			},
			evtType
		)
		return () => {
			mousetrap.unbind(handlerKey)
		}
	}, [handlerKey, evtType])
}
