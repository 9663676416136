import type { ReactElement } from 'react'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePageNavbar from 'components/HomePageNavBar'
import LoadingOrError from 'components/LoadingOrError'
import {
	propertyAccountingFeatureURL,
	companyFinancialsFeatureURL,
	onlineRentCollectionFeatureURL,
	taxReportingFeatureURL,
	residentCenterFeatureURL,
	ownerPortalFeatureURL,
	maintenanceRequestTrackingFeatureURL,
	analyticsAndInsightsFeatureURL,
	rentalListingFeatureURL,
	automaticResidentScreeningFeatureURL,
	onlineRentalApplicationFeatureURL,
	leaseESignatureFeatureURL,
	aboutPageURL,
	blogPageURL,
	careersPageURL,
	openAPIFeatureURL,
	signUpPageURL,
	publicRentalUnitListingURL,
	publicRentalApplicationFormURL,
	resetPasswordURL,
	loginURL,
	referralProgramURL,
	publicWaitlistSubscriberPageURL
} from 'urls'

const ReferralProgramPage = lazy(
	async () => import('../pages/public/ReferralProgramPage')
)
const ResetPasswordPage = lazy(
	async () => import('../pages/public/ResetPasswordPage')
)
const PublicRentalUnitListingPage = lazy(
	async () => import('../pages/public/PublicRentalUnitListingPage')
)
const PublicRentalApplicationPage = lazy(
	async () =>
		import(
			'../pages/public/PublicRentalApplicationPage/PublicRentalApplicationPage'
		)
)

const PublicWaitlistSubscriberPage = lazy(
	async () => import('../pages/public/PublicWaitlistSubscriberPage/index')
)
const LoginPage = lazy(async () => import('../pages/public/LoginPage'))
const HomePage = lazy(async () => import('../pages/public/HomePage'))
const FeaturesPage = lazy(async () => import('../pages/public/FeaturesPage'))
const PricingPage = lazy(async () => import('../pages/public/PricingPage'))
const IntegrationsPage = lazy(
	async () => import('../pages/public/IntegrationsPage')
)
const FAQsPage = lazy(async () => import('../pages/public/FAQsPage'))
const DirectorOfSalesPage = lazy(
	async () => import('../pages/public/DirectorOfSalesPage')
)

// Footer elements
const PropertyAccountingFeaturePage = lazy(
	async () => import('../pages/public/PropertyAccountingPage')
)
const CompanyFinancialsFeaturePage = lazy(
	async () => import('../pages/public/CompanyFinancialsPage')
)
const OnlineRentCollectionFeaturePage = lazy(
	async () => import('../pages/public/OnlineRentCollectionPage')
)
const TaxReportingFeaturePage = lazy(
	async () => import('../pages/public/TaxReportingPage')
)

const ResidentCenterFeaturePage = lazy(
	async () => import('../pages/public/ResidentCenterPage')
)
const OwnerPortalFeaturePage = lazy(
	async () => import('../pages/public/OwnerPortalPage')
)
const MaintenanceRequestTrackingFeaturePage = lazy(
	async () => import('../pages/public/MaintenanceRequestTrackingPage')
)
const AnalyticsAndInsightsFeaturePage = lazy(
	async () => import('../pages/public/AnalyticsAndInsightsPage')
)

const RentalListingsFeaturePage = lazy(
	async () => import('../pages/public/RentalListingsPage')
)
const AutomaticResidentScreeningFeaturePage = lazy(
	async () => import('../pages/public/AutomaticResidentScreeningPage')
)
const OnlineRentalApplicationFeaturePage = lazy(
	async () => import('../pages/public/OnlineRentalApplicationPage')
)
const LeaseESignatureFeaturePage = lazy(
	async () => import('../pages/public/LeaseESignaturePage')
)

const AboutPage = lazy(async () => import('../pages/public/AboutPage'))
const BlogPage = lazy(async () => import('../pages/public/Blog'))
const CareersPage = lazy(async () => import('../pages/public/CareersPage'))
const OpenAPIPage = lazy(async () => import('../pages/public/OpenApiPage'))

const SignUpPage = lazy(async () => import('../pages/public/SignUpPage'))

const addNavbarAndCommandPaletteToElement = (
	component: JSX.Element
): React.ReactElement => (
	<>
		<HomePageNavbar />
		{component}
	</>
)

export default function UnauthenticatedApp(): ReactElement {
	return (
		<Suspense fallback={<LoadingOrError />}>
			<Routes>
				<Route
					path='/'
					element={addNavbarAndCommandPaletteToElement(<HomePage />)}
				/>
				<Route
					path={loginURL}
					element={addNavbarAndCommandPaletteToElement(<LoginPage />)}
				/>
				<Route
					path={resetPasswordURL}
					element={addNavbarAndCommandPaletteToElement(<ResetPasswordPage />)}
				/>
				<Route
					path='/features'
					element={addNavbarAndCommandPaletteToElement(<FeaturesPage />)}
				/>
				<Route
					path='/pricing'
					element={addNavbarAndCommandPaletteToElement(<PricingPage />)}
				/>
				<Route
					path='/integrations'
					element={addNavbarAndCommandPaletteToElement(<IntegrationsPage />)}
				/>
				<Route
					path='/careers'
					element={addNavbarAndCommandPaletteToElement(<CareersPage />)}
				/>
				<Route
					path='/careers/director-of-sales'
					element={addNavbarAndCommandPaletteToElement(<DirectorOfSalesPage />)}
				/>
				<Route
					path='/faqs'
					element={addNavbarAndCommandPaletteToElement(<FAQsPage />)}
				/>

				{/* Footer elements */}
				<Route
					path={propertyAccountingFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<PropertyAccountingFeaturePage />
					)}
				/>
				<Route
					path={companyFinancialsFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<CompanyFinancialsFeaturePage />
					)}
				/>
				<Route
					path={onlineRentCollectionFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<OnlineRentCollectionFeaturePage />
					)}
				/>
				<Route
					path={taxReportingFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<TaxReportingFeaturePage />
					)}
				/>

				<Route
					path={residentCenterFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<ResidentCenterFeaturePage />
					)}
				/>
				<Route
					path={ownerPortalFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<OwnerPortalFeaturePage />
					)}
				/>
				<Route
					path={maintenanceRequestTrackingFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<MaintenanceRequestTrackingFeaturePage />
					)}
				/>
				<Route
					path={analyticsAndInsightsFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<AnalyticsAndInsightsFeaturePage />
					)}
				/>

				<Route
					path={rentalListingFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<RentalListingsFeaturePage />
					)}
				/>
				<Route
					path={automaticResidentScreeningFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<AutomaticResidentScreeningFeaturePage />
					)}
				/>
				<Route
					path={onlineRentalApplicationFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<OnlineRentalApplicationFeaturePage />
					)}
				/>
				<Route
					path={leaseESignatureFeatureURL}
					element={addNavbarAndCommandPaletteToElement(
						<LeaseESignatureFeaturePage />
					)}
				/>

				<Route
					path={aboutPageURL}
					element={addNavbarAndCommandPaletteToElement(<AboutPage />)}
				/>
				<Route
					path={blogPageURL}
					element={addNavbarAndCommandPaletteToElement(<BlogPage />)}
				/>
				<Route
					path={careersPageURL}
					element={addNavbarAndCommandPaletteToElement(<CareersPage />)}
				/>
				<Route
					path={openAPIFeatureURL}
					element={addNavbarAndCommandPaletteToElement(<OpenAPIPage />)}
				/>

				<Route
					path={signUpPageURL}
					element={addNavbarAndCommandPaletteToElement(<SignUpPage />)}
				/>
				<Route
					path={publicRentalUnitListingURL}
					element={addNavbarAndCommandPaletteToElement(
						<PublicRentalUnitListingPage />
					)}
				/>
				<Route
					path={publicRentalApplicationFormURL}
					element={addNavbarAndCommandPaletteToElement(
						<PublicRentalApplicationPage />
					)}
				/>
				<Route
					path={publicWaitlistSubscriberPageURL}
					element={addNavbarAndCommandPaletteToElement(
						<PublicWaitlistSubscriberPage />
					)}
				/>
				<Route
					path={referralProgramURL}
					element={addNavbarAndCommandPaletteToElement(<ReferralProgramPage />)}
				/>
			</Routes>
		</Suspense>
	)
}
