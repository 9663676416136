import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import {
	useGetPropertyManagementCompanyDetails,
	useGetUserPermissions,
	useListFeatureFlags
} from 'api/rentalizeComponents'
import classnames from 'classnames'
import LogoComponent from 'components/Logo'
import { useNavigate } from 'react-router-dom'

import {
	createAIConversationURL,
	propertyManagerDashboardURL,
	userSettingsURL,
	viewBuildingsURL,
	viewEmailMessageBatchesURL,
	viewPropertyOwnersURL,
	viewRentalUnitsURL,
	viewResidentsURL,
	viewSMSsURL
} from 'urls'
import {
	accountingNavigation,
	leasingNavigation,
	managementCompanyNavigation,
	operationsNavigation,
	reportingNavigation,
	signatureRequestEntry,
	storageNavigation
} from './Navigation'
import { useEffect } from 'react'

const smsEntry = { name: 'SMS', href: viewSMSsURL }

export default function BaseSideBar({
	sidebarOpen,
	setSidebarOpen
}: {
	sidebarOpen: boolean
	setSidebarOpen: (value: boolean) => void
}): React.ReactElement {
	const is14InchOrSmaller = window.innerWidth <= 1600
	const { data, isLoading } = useGetUserPermissions({})
	const { data: featureFlag } = useListFeatureFlags({})
	const navigate = useNavigate()
	const logOut = (): void => {
		localStorage.removeItem('access_token')
		navigate('/')
	}

	const aiNavigation = {
		name: 'AI',
		current: false,
		children: [{ name: 'Start a chat', href: createAIConversationURL }]
	}

	const communicationsNavigation = {
		name: 'Communications',
		current: false,
		children: [{ name: 'Emails', href: viewEmailMessageBatchesURL }]
	}

	const { data: propertyManagementCompanyDetails } =
		useGetPropertyManagementCompanyDetails({})

	if (featureFlag?.sms) {
		communicationsNavigation.children.push(smsEntry)
	}

	const navigation: {
		name: string
		href?: string
		current: boolean
		children?: { name: string; href: string }[]
	}[] = [
		{ name: 'Dashboard', href: propertyManagerDashboardURL, current: true }
	]

	if (data?.has_management_company_permissions) {
		navigation.push(managementCompanyNavigation)
	}

	navigation.push({
		name: 'Properties',
		current: false,
		children: [
			{ name: 'Property Owners', href: viewPropertyOwnersURL },
			{ name: 'Buildings', href: viewBuildingsURL },
			{ name: 'Rental Units', href: viewRentalUnitsURL },
			{ name: 'Residents', href: viewResidentsURL }
		]
	})

	useEffect(() => {
		if (propertyManagementCompanyDetails?.has_e_signature_enabled) {
			leasingNavigation.children.push(signatureRequestEntry)
		}
	}, [propertyManagementCompanyDetails])

	if (data?.has_leasing_permissions) {
		navigation.push(leasingNavigation)
	}

	if (data?.has_operations_permissions) {
		navigation.push(operationsNavigation)
	}

	if (data?.has_communications_permissions) {
		navigation.push(communicationsNavigation)
	}

	if (data?.has_storage_permissions) {
		navigation.push(storageNavigation)
	}

	if (data?.has_accounting_permissions) {
		navigation.push(accountingNavigation)
	}

	if (data?.has_reporting_permissions) {
		navigation.push(reportingNavigation)
	}

	if (data?.has_rentalize_ai_permissions) {
		navigation.push(aiNavigation)
	}

	navigation.push({
		name: 'Settings',
		href: userSettingsURL,
		current: false
	})

	const currentPath = window.location.pathname

	if (isLoading) {
		return <div />
	}
	return sidebarOpen ? (
		<div className='hidden  md:fixed md:inset-y-0 md:z-50 md:flex md:w-72 md:flex-col'>
			<div className='flex w-full grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6'>
				<button
					data-testid='close-sidebar'
					type='button'
					onClick={() => {
						setSidebarOpen(!sidebarOpen)
					}}
					aria-label='Close Sidebar'
					className='flex h-16 shrink-0 items-center'
				>
					<LogoComponent />
				</button>

				<nav className='flex flex-1 flex-col'>
					<ul className='flex flex-1 flex-col gap-y-7'>
						<li>
							<ul className='space-y-1'>
								{navigation.map(item => (
									<li key={item.name}>
										{!item.children ? (
											<button
												className={`block w-full rounded-md py-2 pl-2 pr-2 text-left text-xs font-semibold leading-6 text-gray-700 hover:bg-gray-200 ${
													currentPath === item.href ? 'bg-gray-100' : ''
												}`}
												onClick={(): void => {
													// setSidebarOpen(false)

													if (item.href) {
														if (is14InchOrSmaller) {
															setSidebarOpen(false)
														}
														navigate(item.href)
													}
												}}
											>
												{item.name}
											</button>
										) : (
											<Disclosure as='div'>
												{({ open }): React.ReactElement => (
													<>
														<Disclosure.Button
															className={`flex w-full items-center justify-between rounded-md py-2 pl-2 pr-2 text-left text-xs font-semibold leading-6 text-gray-700 hover:bg-gray-200 ${
																currentPath === item.href ? 'bg-gray-100' : ''
															}`}
														>
															<span>{item.name}</span>
															<ChevronRightIcon
																className={classnames(
																	open
																		? 'rotate-90 text-gray-500'
																		: 'text-gray-400',
																	'h-5 w-5 shrink-0'
																)}
																aria-hidden='true'
															/>
														</Disclosure.Button>
														<Disclosure.Panel as='ul' className='mt-1 px-2'>
															{item.children?.map(subItem => (
																<li key={subItem.name}>
																	<div
																		// to={subItem.href}
																		onClick={() => {
																			if (is14InchOrSmaller) {
																				setSidebarOpen(false)
																			}
																			navigate(subItem.href)
																		}}
																		className={`block rounded-md py-2 pl-6 pr-2 text-xs leading-6 text-gray-700 hover:bg-gray-200 ${
																			currentPath === subItem.href
																				? 'bg-gray-100'
																				: ''
																		}`}
																	>
																		{subItem.name}
																	</div>
																</li>
															))}
														</Disclosure.Panel>
													</>
												)}
											</Disclosure>
										)}
									</li>
								))}
								<button
									type='button'
									onClick={logOut}
									className='block w-full rounded-md py-2 pl-4 pr-2 text-left text-xs font-semibold leading-6 text-red-700 hover:bg-red-50'
									data-testid='logout-button'
								>
									Log out
								</button>
							</ul>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	) : (
		// For deskop and mobile view, sidebar is closed
		<div className='hidden md:fixed md:inset-y-0 md:z-50 md:flex md:h-8 md:w-72 md:flex-col'>
			<button
				data-testid='open-sidebar'
				type='button'
				onClick={() => {
					setSidebarOpen(!sidebarOpen)
				}}
				className='flex h-16 shrink-0 items-center px-6'
				aria-label='Open Sidebar'
			>
				<LogoComponent />
			</button>
		</div>
	)
}
