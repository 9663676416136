import {
	currentVacanciesURL,
	generateReportURL,
	viewAccountsURL,
	viewBankAccountsURL,
	viewBillsURL,
	viewBudgetsURL,
	viewEquipmentURL,
	viewFeeSchedulesURL,
	viewInventoryItemsURL,
	viewInvoicesURL,
	viewJournalEntriesURL,
	viewLeasesURL,
	viewMaintenanceProjectsURL,
	viewMaintenanceTechniciansURL,
	viewMakeReadyURL,
	viewManagementCompanyDetailsURL,
	viewMortgagesURL,
	viewInspectionReportsURL,
	viewPropertyManagersURL,
	viewPurchaseOrdersURL,
	viewRentReceivablesURL,
	viewRentalApplicationFormsURL,
	viewRentalApplicationsURL,
	viewRentalUnitListingsURL,
	viewScheduledReportsURL,
	viewSignatureRequestsURL,
	viewStorageCustomersURL,
	viewStorageFacilitiesURL,
	viewStorageUnitInvoicesURL,
	viewStorageUnitLeasesURL,
	viewStorageUnitsURL,
	viewTasksURL,
	viewUtilityMetersURL,
	viewVendorsURL,
	viewWaitlistSubscribersURL,
	viewWorkOrdersURL
} from 'urls'

export const managementCompanyNavigation = {
	name: 'Management Company',
	current: false,
	children: [
		{ name: 'Company Details', href: viewManagementCompanyDetailsURL },
		{ name: 'Fee Schedules', href: viewFeeSchedulesURL },
		{ name: 'Property Managers', href: viewPropertyManagersURL },
		{ name: 'Maintenance Technicians', href: viewMaintenanceTechniciansURL }
	]
}

export const leasingNavigation = {
	name: 'Leasing',
	current: false,
	children: [
		{ name: 'Leases', href: viewLeasesURL },
		{ name: 'Vacancies', href: currentVacanciesURL },
		{ name: 'Listings', href: viewRentalUnitListingsURL },
		{ name: 'Application Forms', href: viewRentalApplicationFormsURL },
		{ name: 'Rental Applications', href: viewRentalApplicationsURL },
		{ name: 'Waitlist', href: viewWaitlistSubscribersURL }
	]
}

export const signatureRequestEntry = {
	name: 'Signature Requests',
	href: viewSignatureRequestsURL
}

export const operationsNavigation = {
	name: 'Operations',
	current: false,
	children: [
		{ name: 'Work Orders', href: viewWorkOrdersURL },
		{ name: 'Inspection Reports', href: viewInspectionReportsURL },
		{ name: 'Tasks', href: viewTasksURL },
		{ name: 'Maintenance Projects', href: viewMaintenanceProjectsURL },
		{ name: 'Make Readies', href: viewMakeReadyURL },

		{ name: 'Equipment', href: viewEquipmentURL },
		{ name: 'Utility Meters', href: viewUtilityMetersURL }
	]
}

export const accountingNavigation = {
	name: 'Accounting',
	current: false,
	children: [
		{ name: 'Budgets', href: viewBudgetsURL },
		{ name: 'Accounts', href: viewAccountsURL },
		{ name: 'Inventory', href: viewInventoryItemsURL },
		{ name: 'Journal Entries', href: viewJournalEntriesURL },
		{ name: 'Mortgages', href: viewMortgagesURL },
		{ name: 'Vendors', href: viewVendorsURL },
		{ name: 'Payables (Bills)', href: viewBillsURL },
		{ name: 'Receivables (Invoices)', href: viewInvoicesURL },
		{ name: 'Rent Payments & Receivables', href: viewRentReceivablesURL },
		{ name: 'Banking', href: viewBankAccountsURL },
		{ name: 'Purchase Orders', href: viewPurchaseOrdersURL }
	]
}

export const reportingNavigation = {
	name: 'Reporting',
	current: false,
	children: [
		{ name: 'Generate Report', href: generateReportURL },
		{
			name: 'Scheduled Reports',
			href: viewScheduledReportsURL
		}
	]
}

export const storageNavigation = {
	name: 'Storage',
	current: false,
	children: [
		{ name: 'Customers', href: viewStorageCustomersURL },
		{ name: 'Facilities', href: viewStorageFacilitiesURL },
		{ name: 'Units', href: viewStorageUnitsURL },
		{ name: 'Storage Leases', href: viewStorageUnitLeasesURL },
		{ name: 'Storage Unit Invoices', href: viewStorageUnitInvoicesURL }
	]
}
