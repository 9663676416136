import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
	residentCenterChangePasswordURL,
	residentCenterCreateWorkOrderURL,
	residentCenterDashboardURL,
	residentCenterDocumentDetailURL,
	residentCenterDocumentsURL,
	residentCenterViewLeaseDetailsURL,
	residentCenterViewLeasesURL,
	residentCenterViewWorkOrderDetailURL,
	residentCenterViewWorkOrdersURL
} from 'urls'
import ResidentCenterNavbar from '../components/navbars/ResidentCenterNavbar'

// Dashboard
const ResidentCenterDashboard = lazy(
	async () => import('../pages/residents/ResidentCenterDashboard')
)

// Work Order
const ResidentCenterCreateWorkOrderPage = lazy(
	async () => import('../pages/residents/ResidentCenterCreateWorkOrderPage')
)

const ResidentCenterWorkOrderDetailPage = lazy(
	async () => import('../pages/residents/ResidentCenterWorkOrderDetailPage')
)

const ResidentCenterViewWorkOrdersPage = lazy(
	async () => import('../pages/residents/ResidentCenterViewWorkOrdersPage')
)

// Lease
const ResidentCenterLeasesPage = lazy(
	async () => import('../pages/residents/ResidentCenterLeasesPage')
)

const ResidentCenterLeaseDetailsPage = lazy(
	async () => import('../pages/residents/ResidentCenterLeaseDetailsPage')
)

// User Management
const ResidentCenterChangePasswordPage = lazy(
	async () => import('../pages/residents/ResidentCenterChangePasswordPage')
)

// Documents

const ResidentCenterDocumentsPage = lazy(
	async () => import('../pages/residents/ResidentCenterDocumentsPage')
)

const ResidentCenterDocumentDetailPage = lazy(
	async () => import('../pages/residents/ResidentCenterDocumentDetailPage')
)

const addNavbarToElement = (component: JSX.Element): React.ReactElement => (
	<>
		<ResidentCenterNavbar />
		{component}
	</>
)

export default function ResidentCenterApp(): React.ReactElement {
	return (
		<Routes>
			{/* Dashboard */}
			<Route
				path={residentCenterDashboardURL}
				element={addNavbarToElement(<ResidentCenterDashboard />)}
			/>

			{/* Work Order */}
			<Route
				path={residentCenterCreateWorkOrderURL}
				element={addNavbarToElement(<ResidentCenterCreateWorkOrderPage />)}
			/>

			<Route
				path={residentCenterViewWorkOrdersURL}
				element={addNavbarToElement(<ResidentCenterViewWorkOrdersPage />)}
			/>

			{/* Leases */}
			<Route
				path={residentCenterViewLeasesURL}
				element={addNavbarToElement(<ResidentCenterLeasesPage />)}
			/>
			<Route
				path={residentCenterViewLeaseDetailsURL}
				element={addNavbarToElement(<ResidentCenterLeaseDetailsPage />)}
			/>

			{/* Documents */}

			<Route
				path={residentCenterDocumentsURL}
				element={addNavbarToElement(<ResidentCenterDocumentsPage />)}
			/>

			<Route
				path={residentCenterDocumentDetailURL}
				element={addNavbarToElement(<ResidentCenterDocumentDetailPage />)}
			/>

			{/* Change Password */}

			<Route
				path={residentCenterChangePasswordURL}
				element={addNavbarToElement(<ResidentCenterChangePasswordPage />)}
			/>

			<Route
				path={residentCenterViewWorkOrderDetailURL}
				element={addNavbarToElement(<ResidentCenterWorkOrderDetailPage />)}
			/>
		</Routes>
	)
}
