import BaseSideBar from './BaseSideBar'

export default function PropertyManagerSideBar({
	sidebarOpen,
	setSidebarOpen
}: {
	sidebarOpen: boolean
	setSidebarOpen: (value: boolean) => void
}): React.ReactElement {
	return (
		<BaseSideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
	)
}
