import {
	useListBuildings,
	useListLeases,
	useListPropertyOwners,
	useListRentalUnits,
	useListResidents,
	useListWorkOrders
} from 'api/rentalizeComponents'
import MaintenanceTechnicianApp from 'apps/MaintenanceTechnicianApp'
import OwnerPortalApp from 'apps/OwnerPortalApp'
import PropertyManagerApp from 'apps/PropertyManagerApp'
import ResidentCenterApp from 'apps/ResidentCenterApp'
import UnauthenticatedApp from 'apps/UnauthenticatedApp'
import Footer from 'components/Footer'
import Head from 'components/Head'
import LoadingOrError from 'components/LoadingOrError'
import type { ReactElement } from 'react'
import { Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { getTitleFromPathname } from 'urls'

const CURRENT_FRONTEND_PAYLOAD_TIMESTAMP = Date.now() // Store the current Unix time

declare global {
	interface Window {
		CURRENT_FRONTEND_PAYLOAD_TIMESTAMP: number
	}
}

// Make the timestamp accessible globally
window.CURRENT_FRONTEND_PAYLOAD_TIMESTAMP = CURRENT_FRONTEND_PAYLOAD_TIMESTAMP

export default function App(): ReactElement {
	const location = useLocation()
	const title = getTitleFromPathname(location.pathname)

	// Prefetch a few APIs so that that the user doesn't have to wait for them to load before navigating to these popular pages
	useListWorkOrders({ queryParams: {} })
	useListBuildings({ queryParams: {} })
	useListRentalUnits({ queryParams: { unit_type: 'ALL' } })
	useListResidents({ queryParams: { resident_type: 'CURRENT_RESIDENT' } })
	useListLeases({ queryParams: { lease_type: 'CURRENT_LEASE' } })
	useListPropertyOwners({})

	return (
		<div style={{ minHeight: '100vh', background: 'white' }}>
			<Suspense fallback={<LoadingOrError />}>
				<Head title={title} />
				<UnauthenticatedApp />
				<PropertyManagerApp />
				<OwnerPortalApp />
				<ResidentCenterApp />
				<MaintenanceTechnicianApp />
			</Suspense>
			<Footer />
		</div>
	)
}
