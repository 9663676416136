export function setAccessToken(accessToken: string): void {
	localStorage.setItem('access_token', accessToken)
}

export function setRefreshToken(refreshToken: string): void {
	localStorage.setItem('refresh_token', refreshToken)
}

export function getAccessToken(): string {
	let tokenString = localStorage.getItem('access_token')
	if (!tokenString) {
		tokenString = ''
	}
	return tokenString
}

export function getRefreshToken(): string {
	let tokenString = localStorage.getItem('refresh_token')
	if (!tokenString) {
		tokenString = ''
	}
	return tokenString
}
