// Registration and auth

export const signUpPageURL = '/signup/'
export const loginURL = '/login/'
export const setupBillingURL = '/app/setupBilling'
export const resetPasswordURL = '/reset-password/'

// Landing pages

export const propertyAccountingFeatureURL = '/property-accounting/'
export const companyFinancialsFeatureURL = '/company-financials/'
export const onlineRentCollectionFeatureURL = `/online-rent-collection/`
export const taxReportingFeatureURL = `/tax-reporting/`

export const residentCenterFeatureURL = `/resident-center/`
export const ownerPortalFeatureURL = `/owner-portal/`
export const maintenanceRequestTrackingFeatureURL = `/maintenance-request-tracking/`
export const analyticsAndInsightsFeatureURL = `/analytics-and-insights/`

export const rentalListingFeatureURL = `/rental-listings/`
export const automaticResidentScreeningFeatureURL = `/automatic-resident-screening/`
export const onlineRentalApplicationFeatureURL = `/online-rental-application/`
export const leaseESignatureFeatureURL = `/lease-e-signature/`

export const aboutPageURL = `/about/`
export const blogPageURL = `/blog/`
export const careersPageURL = `/careers/`
export const openAPIFeatureURL = `/open-api/`

/// ///////////////
// Property Manager App
/// ///////////////

export const propertyManagerDashboardURL = '/app/'

export const userSettingsURL = '/app/user-settings/'

// Operations
export const propertyManagementRevenueURL = '/app/property-management-revenue/'

// Fee Schedule
export const addFeeScheduleURL = '/app/fee-schedules/new/'
export const viewFeeSchedulesURL = '/app/fee-schedules/'
export const viewFeeScheduleDetailURL = '/app/fee-schedules/:feeScheduleId/'

// Property Group
export const addPropertyGroupURL = '/app/property-groups/new/'
export const viewPropertyGroupsURL = '/app/property-groups/'
export const viewPropertyGroupDetailsURL =
	'/app/property-groups/:feeScheduleId/'

// Work Order
export const addWorkOrderURL = '/app/work-orders/new/'
export const viewWorkOrdersURL = '/app/work-orders/'
export const viewWorkOrderDetailURL = '/app/work-orders/:workOrderId/'

// Security Deposits
export const addSecurityDepositURL = '/app/security-deposits/new/'
export const viewSecurityDepositsURL = '/app/security-deposits/'
export const viewSecurityDepositDetailURL =
	'/app/security-deposits/:securityDepositId/'

// Note
export const addNoteURL = '/app/notes/new/'
export const viewNotesURL = '/app/notes/'
export const viewNoteDetailsURL = '/app/notes/:noteId/'

// Property Inspection
export const addPropertyInspectionURL = '/app/property-inspections/new/'
export const viewPropertyInspectionsURL = '/app/property-inspections/'
export const viewPropertyInspectionDetailsURL =
	'/app/property-inspections/:propertyInspectionId/'

// Inventory
export const addInventoryItemURL = '/app/inventory-items/new/'
export const viewInventoryItemsURL = '/app/inventory-items/'
export const viewInventoryItemDetailsURL =
	'/app/inventory-items/:inventoryItemId/'

// Inventory Transaction
export const addInventoryTransactionURL = '/app/inventory-transactions/new/'
export const viewInventoryTransactionsURL = '/app/inventory-transactions/'
export const viewInventoryTransactionDetailsURL =
	'/app/inventory-transactions/:inventoryTransactionId/'

// Property Inspection Form
export const addPropertyInspectionFormURL =
	'/app/property-inspection-forms/new/'
export const viewPropertyInspectionFormsURL = '/app/property-inspection-forms/'
export const viewPropertyInspectionFormDetailsURL =
	'/app/property-inspection-forms/:propertyInspectionId/'

// Property Owner
export const addPropertyOwnerURL = '/app/property-owners/new/'
export const viewPropertyOwnersURL = '/app/property-owners/'
export const viewPropertyOwnerDetailURL =
	'/app/property-owners/:propertyOwnerId/'

// Vendor
export const addVendorURL = '/app/vendors/new/'
export const viewVendorsURL = '/app/vendors/'
export const viewVendorDetailURL = '/app/vendors/:vendorId/'

// Vehicles (Management Company)
export const addVehicleURL = '/app/vehicles/new/'
export const viewVehiclesURL = '/app/vehicles/'
export const viewVehicleDetailURL = '/app/vehicles/:vehicleId/'

// Parking Spaces
export const addParkingSpaceURL = '/app/parking-spaces/new/'
export const viewParkingSpacesURL = '/app/parking-spaces/'
export const viewParkingSpaceDetailURL = '/app/parking-spaces/:parkingSpaceId/'

// Resident Vehicles
export const addResidentVehicleURL = '/app/resident-vehicles/new/'
export const viewResidentVehiclesURL = '/app/resident-vehicles/'
export const viewResidentVehicleDetailURL =
	'/app/resident-vehicles/:residentVehicleId/'

// Vendor Credit
export const addVendorCreditURL = '/app/vendor-credits/new/'
export const viewVendorCreditsURL = '/app/vendor-credits/'
export const viewVendorCreditDetailURL = '/app/vendor-credits/:vendorCreditId/'

// Bulk Bill Payment
export const viewBulkBillPaymentsURL = '/app/accounting/bulk-bill-payments/'
export const viewBulkBillPaymentDetailsURL =
	'/app/accounting/bulk-bill-payments/:bulkBillPaymentId/'

// Bulk Invoice Payment
export const viewBulkInvoicePaymentsURL =
	'/app/accounting/bulk-invoice-payments/'
export const viewBulkInvoicePaymentDetailsURL =
	'/app/accounting/bulk-invoice-payments/:bulkInvoicePaymentId/'

// Remittance Letter
export const viewRemittanceLettersURL = '/app/accounting/remittance-letters/'
export const viewRemittanceLetterDetailsURL =
	'/app/accounting/remittance-letters/:remittanceLetterId/'

// Email Domain
export const addEmailDomainURL = '/app/email-domains/new/'
export const viewEmailDomainsURL = '/app/email-domains/'
export const viewEmailDomainDetailURL = '/app/email-domains/:emailDomainId/'

// Email Sender
export const addEmailSenderURL = '/app/email-senders/new/'
export const viewEmailSendersURL = '/app/email-senders/'
export const viewEmailSenderDetailURL = '/app/email-senders/:emailSenderId/'

// Building
export const addBuildingURL = '/app/buildings/new/'
export const viewBuildingsURL = '/app/buildings/'
export const viewBuildingDetailURL = '/app/buildings/:buildingId/'

// Rental Unit
export const addRentalUnitURL = '/app/rental-units/new/'
export const viewRentalUnitsURL = '/app/rental-units/'
export const viewRentalUnitDetailURL = '/app/rental-units/:rentalUnitId/'

// Property Manager
export const createAIConversationURL = '/app/ai-conversations/new'
export const viewAIConversationsURL = '/app/ai-conversations/'

// Property Manager
export const invitePropertyManagerURL = '/app/property-managers/new'
export const viewPropertyManagersURL = '/app/property-managers/'
export const viewPropertyManagerDetailsURL =
	'/app/property-managers/:propertyManagerId'

// Invitation
export const acceptPropertyManagerInvitationURL =
	'/accept-invitation/:invitationId/'
export const acceptResidentCenterInvitationURL =
	'/accept-resident-invitation/:invitationId/'

// Equipment Rental Tracking
export const addEquipmentURL = '/app/equipment/new'
export const viewEquipmentURL = '/app/equipment/'
export const viewEquipmentDetailsURL = '/app/equipment/:equipmentId/'

// Equipment Rental
export const addEquipmentRentalURL = '/app/equipment-rentals/new'
export const viewEquipmentRentalsURL = '/app/equipment-rentals/'
export const viewEquipmentRentalDetailsURL =
	'/app/equipment-rentals/:equipmentRentalId/'

// Equipment Rental Rate
export const addEquipmentRentalRateURL = '/app/equipment-rental-rates/new'
export const viewEquipmentRentalRatesURL = '/app/equipment-rental-rates/'
export const viewEquipmentRentalRateDetailsURL =
	'/app/equipment-rental-rates/:equipmentRentalRateId/'

// Work Logs
export const addWorkLogURL = '/app/work-logs/new'
export const viewWorkLogsURL = '/app/work-logs/'
export const viewWorkLogDetailsURL = '/app/work-logs/:workLogId/'

// Maintenance Technician
export const addMaintenanceTechnicianURL = '/app/maintenance-technicians/new'
export const inviteMaintenanceTechnicianURL =
	'/app/maintenance-technicians/invite'
export const viewMaintenanceTechniciansURL = '/app/maintenance-technicians/'
export const viewMaintenanceTechnicianDetailsURL =
	'/app/maintenance-technicians/:maintenanceTechnicianId/'

// Maintenance Project
export const addMaintenanceProjectURL = '/app/maintenance-projects/new'
export const viewMaintenanceProjectsURL = '/app/maintenance-projects/'
export const viewMaintenanceProjectDetailsURL =
	'/app/maintenance-projects/:maintenanceProjectId/'

// Budget
export const addBudgetURL = '/app/budgets/new'
export const viewBudgetsURL = '/app/budgets/'
export const viewBudgetDetailsURL = '/app/budgets/:budgetId/'

// Email Template
export const addEmailTemplateURL = '/app/email-templates/new'
export const viewEmailTemplatesURL = '/app/email-templates/'
export const viewEmailTemplateDetailsURL =
	'/app/email-templates/:emailTemplateId/'

// API Key
export const addAPIKeyURL = '/app/api-keys/new'
export const viewAPIKeysURL = '/app/api-keys/'
export const viewAPIKeyDetailsURL = '/app/api-keys/:apiKeyId/'

export const viewManagementCompanyDetailsURL = '/app/management-company/'

// Contact Support
export const contactSupportURL = '/app/contact-support/'

// Vacancy
export const upcomingVacanciesURL = '/app/upcoming-vacancies/'
export const currentVacanciesURL = '/app/current-vacancies/'

// Record Expense
export const recordExpenseURL = '/app/record-expense/'

// Task
export const addTaskURL = '/app/tasks/new'
export const viewTasksURL = '/app/tasks/'
export const viewTaskDetailsURL = '/app/tasks/:taskId/'

// Recurring Task
export const addRecurringTaskURL = '/app/recurring-tasks/new'
export const viewRecurringTasksURL = '/app/recurring-tasks/'
export const viewRecurringTaskDetailsURL =
	'/app/recurring-tasks/:recurringTaskId/'

// Recurring Journal Entry
export const addRecurringJournalEntryURL = '/app/recurring-journal-entries/new'
export const viewRecurringJournalEntriesURL = '/app/recurring-journal-entries/'
export const viewRecurringJournalEntryDetailsURL =
	'/app/recurring-journal-entries/:recurringJournalEntryId/'

// Appliance
export const addApplianceURL = '/app/appliances/new'
export const viewAppliancesURL = '/app/appliances/'
export const viewApplianceDetailsURL = '/app/appliances/:applianceId/'

// Resident Pets
export const addResidentPetURL = '/app/resident-pets/new'
export const viewResidentPetsURL = '/app/resident-pets/'
export const viewResidentPetDetailsURL = '/app/resident-pets/:residentPetId/'

// Document
export const addDocumentURL = '/app/documents/new/'
export const viewDocumentsURL = '/app/documents/'
export const viewDocumentDetailURL = '/app/documents/:documentId/'

// Resident
export const addResidentURL = '/app/residents/new/'
export const viewResidentsURL = '/app/residents/'
export const viewResidentDetailURL = '/app/residents/:residentId/'

// Signature Requests
export const viewSignatureRequestsURL = '/app/signature-requests/'
export const viewSignatureRequestDetailURL =
	'/app/signature-requests/:signatureRequestId/'

// Former Resident
export const viewFormerResidentsURL = '/app/former-residents/'

// Rental Applicant
export const viewRentalApplicantsURL = '/app/rental-applicants/'

// Future Resident
export const viewFutureResidentsURL = '/app/future-residents/'

// Rent Receivable
export const addRentReceivableURL = '/app/rent-receivables/new/'
export const viewRentReceivablesURL = '/app/rent-receivables/'
export const viewRentReceivableDetailURL =
	'/app/rent-receivables/:rentReceivableId/'

// Rent Receivable
export const addRentChargeURL = '/app/rent-charges/new/'
export const viewRentChargesURL = '/app/rent-charges/'
export const viewRentChargeDetailURL = '/app/rent-charges/:rentReceivableId/'

// Rent Payment
export const addRentPaymentURL = '/app/rent-payments/new/'
export const viewRentPaymentsURL = '/app/rent-payments/'
export const viewRentPaymentDetailURL = '/app/rent-payments/:rentPaymentId/'

export const bulkMarkRentAsPaidURL = '/app/rent-payments/bulk-mark-as-paid/'

// Invoice
export const addInvoiceURL = '/app/accounting/invoices/new/'
export const viewInvoicesURL = '/app/accounting/invoices/'
export const viewInvoiceDetailURL = '/app/accounting/invoices/:invoiceId/'

export const bulkGenerateWorkOrderInvoicesURL =
	'/app/accounting/invoices/invoice-work-orders-to-property/'

// Bulk Pay Invoice
export const bulkPayInvoiceURL = '/app/accounting/invoices/bulk-pay/'

// Invoice Payment
export const addInvoicePaymentURL = '/app/accounting/invoice-payments/new/'
export const viewInvoicePaymentsURL = '/app/accounting/invoice-payments/'
export const viewInvoicePaymentDetailURL =
	'/app/accounting/invoice-payments/:invoicePaymentId/'

// Email
export const viewEmailMessagesURL = '/app/email-messages/'
export const viewEmailMessageDetailURL = '/app/email-messages/:emailMessageId/'

// Email Message Batch
export const addEmailMessageBatchURL = '/app/email-message-batches/new/'
export const viewEmailMessageBatchesURL = '/app/email-message-batches/'
export const viewEmailMessageBatchDetailURL =
	'/app/email-message-batches/:emailMessageBatchId/'
export const viewDraftEmailMessageBatchDetailURL =
	'/app/draft-email-message-batches/:emailMessageBatchId/'
// Stripe Accounts
export const addStripeAccountURL = '/app/stripe-accounts/new/'
export const viewStripeAccountsURL = '/app/stripe-accounts/'
export const viewStripeAccountDetailURL =
	'/app/stripe-accounts/:stripeAccountId/'

// Rentalize AI
export const askRentalizeAIQuestionURL = '/app/ai-question/'

// SMS
export const addSMSURL = '/app/sms/new/'
export const viewSMSsURL = '/app/sms/'
export const viewSMSDetailURL = '/app/sms/:emailId/'

// Lease
export const addLeaseURL = '/app/leases/new/'
export const viewLeasesURL = '/app/leases/'
export const viewLeaseDetailsURL = '/app/leases/:leaseId/'
export const viewLeasesExpiringIn60DaysURL = '/app/leases-expiring-60-days/'
export const viewLeasesExpiringIn120DaysURL = '/app/leases-expiring-120-days/'

// Lease Attachment
export const addLeaseAttachmentURL = '/app/lease-attachments/new/'
export const viewLeaseAttachmentsURL = '/app/lease-attachments/'
export const viewLeaseAttachmentDetailURL =
	'/app/lease-attachments/:leaseAttachmentId/'

// Make Ready
export const addMakeReadyURL = '/app/make-ready/new/'
export const viewMakeReadyURL = '/app/make-ready/'
export const viewMakeReadyDetailURL = '/app/make-ready/:makeReadyId/'

// Rent Payment Schedule
export const addRentPaymentScheduleURL = '/app/rent-payment-schedules/new/'
export const viewRentPaymentSchedulesURL = '/app/rent-payment-schedules/'
export const viewRentPaymentScheduleDetailsURL =
	'/app/rent-payment-schedules/:rentPaymentScheduleId/'

// Rent Increase
export const addRentIncreaseURL = '/app/rent-increases/new/'
export const viewRentIncreasesURL = '/app/rent-increases/'
export const viewRentIncreaseDetailsURL = '/app/rent-increases/:rentIncreaseId/'

// Bulk Rent Increases
export const addBulkRentIncreaseNoticeURL =
	'/app/bulk-rent-increase-notices/new/'
export const viewBulkRentIncreaseNoticesURL = '/app/bulk-rent-increase-notices/'
export const viewBulkRentIncreaseNoticeDetailsURL =
	'/app/bulk-rent-increase-notices/:bulkRentIncreaseNoticeId/'

// Rental Application Form
export const addRentalApplicationFormURL = '/app/rental-application-forms/new/'
export const viewRentalApplicationFormsURL = '/app/rental-application-forms/'
export const viewRentalApplicationFormDetailURL =
	'/app/rental-application-forms/:rentalApplicationFormId/'

// Inspection Reports
export const addInspectionReportURL = '/app/inspection-reports/new/'
export const viewInspectionReportsURL = '/app/inspection-reports/'
export const viewInspectionReportDetailsURL =
	'/app/inspection-reports/:inspectionReportId/'

// Rental Unit Listing
export const addRentalUnitListingURL = '/app/rental-unit-listings/new/'
export const viewRentalUnitListingsURL = '/app/rental-unit-listings/'
export const viewRentalUnitListingDetailURL =
	'/app/rental-unit-listings/:rentalUnitListingId/'

// Rental Application
export const addRentalApplicationURL = '/app/rental-applications/new/'
export const viewRentalApplicationsURL = '/app/rental-applications/'
export const viewRentalApplicationDetailURL =
	'/app/rental-applications/:rentalApplicationId/'

export const addWaitlistSubscriberURL = '/app/waitlist-subscribers/new/'
export const viewWaitlistSubscribersURL = '/app/waitlist-subscribers/'
export const viewWaitlistSubscriberDetailURL =
	'/app/waitlist-subscribers/:waitlistSubscriberId/'

// Accounting
export const chartOfAccountsURL = '/app/accounting/chart-of-accounts/'
export const propertyAccountingURL = '/app/accounting/property-accounting/'

// Journal Entry
export const addJournalEntryURL = '/app/accounting/journal-entries/new/'
export const viewJournalEntriesURL = '/app/accounting/journal-entries/'
export const viewJournalEntryDetailURL =
	'/app/accounting/journal-entries/:journalEntryId/'

// Mortgage
export const addMortgageURL = '/app/mortgages/new/'
export const viewMortgagesURL = '/app/mortgages/'
export const viewMortgageDetailsURL = '/app/mortgages/:mortgageId/'

// Lender
export const addLenderURL = '/app/lenders/new/'
export const viewLendersURL = '/app/lenders/'
export const viewLenderDetailsURL = '/app/lenders/:lenderId/'

// Bill
export const addBillURL = '/app/accounting/bills/new/'
export const viewBillsURL = '/app/accounting/bills/'
export const viewBillDetailURL = '/app/accounting/bills/:billId/'

// Billable Expenses
export const addBillableExpenseURL = '/app/accounting/billable-expenses/new/'
export const viewBillableExpensesURL = '/app/accounting/billable-expenses/'

// Bill Payment
export const addBillPaymentURL = '/app/accounting/bill-payments/new/'
export const viewBillPaymentsURL = '/app/accounting/bill-payments/'
export const viewBillPaymentDetailsURL =
	'/app/accounting/bill-payments/:billPaymentId/'

// Bulk Pay Bill
export const bulkPayBillURL = '/app/accounting/bills/bulk-pay/'

// Transaction
export const addTransactionURL = '/app/accounting/transactions/new/'
export const viewTransactionsURL = '/app/accounting/transactions/'
export const viewTransactionDetailsURL =
	'/app/accounting/transactions/:transactionId/'

// Storage Units
export const addStorageUnitURL = '/app/storage-units/new/'
export const viewStorageUnitsURL = '/app/storage-units/'

// Storage Facilities
export const viewStorageFacilitiesURL = '/app/storage-facilities/'

// Storage Unit Leases
export const addStorageUnitLeaseURL = '/app/storage-unit-leases/new/'
export const viewStorageUnitLeasesURL = '/app/storage-unit-leases/'
export const viewStorageUnitLeaseDetailsURL =
	'/app/storage-unit-leases/:storageUnitLeaseId/'

// Storage Unit Leases
export const addStorageUnitInvoiceURL = '/app/storage-unit-invoices/new/'
export const viewStorageUnitInvoicesURL = '/app/storage-unit-invoices/'
export const viewStorageUnitInvoiceDetailsURL =
	'/app/storage-unit-invoices/:storageUnitInvoiceId/'

// Storage Customers
export const addStorageCustomerURL = '/app/storage-customers/new/'
export const viewStorageCustomersURL = '/app/storage-customers/'

// Account
export const addAccountURL = '/app/accounting/accounts/new/'
export const viewAccountsURL = '/app/accounting/accounts/'
export const viewAccountDetailURL = '/app/accounting/accounts/:accountId/'

// Bank Account s
export const addBankAccountURL = '/app/accounting/bank-accounts/new/'
export const viewBankAccountsURL = '/app/accounting/bank-accounts/'
export const viewBankAccountDetailURL =
	'/app/accounting/bank-accounts/:bankAccountId/'

// Bank Account s
export const addCreditCardURL = '/app/accounting/credit-cards/new/'
export const viewCreditCardsURL = '/app/accounting/credit-cards/'
export const viewCreditCardDetailURL =
	'/app/accounting/credit-cards/:creditCardId/'

// Bank Reconciliations
export const addBankReconciliationURL =
	'/app/accounting/bank-reconciliations/new/'
export const viewBankReconciliationsURL =
	'/app/accounting/bank-reconciliations/'
export const viewBankReconciliationDetailURL =
	'/app/accounting/bank-reconciliations/:bankReconciliationId/'

// Markup Type
export const addMarkupTypeURL = '/app/accounting/markup-types/new/'
export const viewMarkupTypesURL = '/app/accounting/markup-types/'
export const viewMarkupTypeDetailURL =
	'/app/accounting/markup-types/:markupTypeId/'

// Generate Report
export const generateReportURL = '/app/reporting/generate-report/'

export const generateIncomeStatementURL = '/app/reporting/income-statement/'

export const generateEFTPaymentFileURL =
	'/app/reporting/generate-eft-payment-file/'

export const generateBalanceSheetURL = '/app/reporting/balance-sheet/'
export const generateRentLedgerReportURL = '/app/reporting/rent-ledger/'

export const generateTrialBalanceReportURL = '/app/reporting/trial-balance/'

export const generateManagementFeeSummaryReportURL =
	'/app/reporting/management-fee-summary/'

export const generateTransactionRegisterReportURL =
	'/app/reporting/transaction-register/'

export const generateAgedPayablesReportURL =
	'/app/reporting/aged-payables-report/'
export const generateAgedReceivablesReportURL =
	'/app/reporting/aged-receivables-report/'

export const generateDscrReportURL = '/app/reporting/dscr-report/'
export const generateDscrSensitivityReportURL =
	'/app/reporting/dscr-sensitivity-report/'
export const generateRentDelinquencyReportURL =
	'/app/reporting/rent-delinquency-report/'

export const generateRentRollURL = '/app/reporting/rent-roll/'
export const generatePhysicalInventoryReportURL =
	'/app/reporting/physical-inventory-report/'
export const generateRentRollComparisonReportURL =
	'/app/reporting/rent-roll-comparison-report/'
export const generateParkingSpaceAssignmentReportURL =
	'/app/reporting/parking-space-assignment-report/'

export const generateLossToLeaseReportURL =
	'/app/reporting/loss-to-lease-report/'
export const generateRentOutstandingReportURL =
	'/app/reporting/rent-outstanding-report/'
export const generateRentReceiptURL = '/app/reporting/rent-receipt/'

export const generateUpcomingRentIncreaseReportURL =
	'/app/reporting/upcoming-rent-increase-report/'

export const generateOpenTasksReportURL = '/app/reporting/open-tasks-report/'

export const generateClosedTasksReportURL =
	'/app/reporting/closed-tasks-report/'

export const generateTaskPerformanceReportURL =
	'/app/reporting/task-performance-report/'

export const generateChartOfAccountsReportURL =
	'/app/reporting/chart-of-accounts/'

export const generateServiceTechHoursURL = '/app/reporting/service-tech-hours/'

export const generateOpenWorkOrdersReportURL =
	'/app/reporting/open-work-orders-report/'

export const generateClosedWorkOrdersReportURL =
	'/app/reporting/closed-work-orders-report/'

export const generateWorkOrderSummaryReportURL =
	'/app/reporting/work-order-summary/'
export const generateWorkOrderPerformanceReportURL =
	'/app/reporting/work-order-performance-report/'

export const generateMoveInMoveOutReportURL =
	'/app/reporting/move-in-move-out-report/'

export const generateTimeToLeaseReportURL =
	'/app/reporting/time-to-lease-report/'

export const generateGeneralLedgerReportURL =
	'/app/reporting/general-ledger-report/'

export const generateJournalTransactionsReportURL =
	'/app/reporting/journal-transactions-report/'

export const generatePOListingReportURL = '/app/reporting/po-listing-report/'

// Scheduled Report
export const addScheduledReportURL = '/app/reporting/scheduled-reports/new/'
export const viewScheduledReportsURL = '/app/reporting/scheduled-reports/'
export const viewScheduledReportDetailsURL =
	'/app/reporting/scheduled-reports/:scheduledReportId'

// Account management
export const changePasswordURL = '/app/account/change-password/'

// Time Tracking
export const addTimeTrackingEntryURL = '/app/time-tracking-entries/new/'
export const viewTimeTrackingEntriesURL = '/app/time-tracking-entries/'
export const viewTimeTrackingEntryDetailURL =
	'/app/time-tracking-entries/:timeTrackingEntryId/'

// Purchase Orders
export const addPurchaseOrderURL = '/app/purchase-orders/new/'
export const viewPurchaseOrdersURL = '/app/purchase-orders/'
export const viewPurchaseOrderDetailURL =
	'/app/purchase-orders/:purchaseOrderId/'

// Bank Deposit
export const addBankDepositURL = '/app/bank-deposits/new/'
export const viewBankDepositsURL = '/app/bank-deposits/'
export const viewBankDepositDetailURL = '/app/bank-deposits/:bankDepositId/'

// Public listings and application forms
export const publicRentalUnitListingURL = '/listings/:rentalUnitListingId/'
export const publicRentalApplicationFormURL = '/apply/:rentalApplicationSlug/'
export const publicWaitlistSubscriberPageURL =
	'/:propertyManagementCompanySlug/waitlist/'
export const referralProgramURL = '/referral-program/'

export const publicRentalApplicationSuccessURL = '/rental-application/success/'

// Utility Meter
export const addUtilityMeterURL = '/app/utility-meters/new/'
export const viewUtilityMetersURL = '/app/utility-meters/'
export const viewUtilityMeterDetailURL = '/app/utility-meters/:utilityMeterId/'

// Utility Meter Reading
export const addUtilityMeterReadingURL = '/app/utility-meter-readings/new/'
export const viewUtilityMeterReadingsURL = '/app/utility-meter-readings/'
export const viewUtilityMeterReadingDetailURL =
	'/app/utility-meter-readings/:utilityMeterReadingId/'

// Management Fee Tracking

// Management Fee Receivables
export const addManagementFeeReceivableURL =
	'/app/management-fee-receivables/new/'
export const viewManagementFeeReceivablesURL =
	'/app/management-fee-receivables/'
export const viewManagementFeeReceivableDetailURL =
	'/app/management-fee-receivables/:managementFeeReceivableId/'

// Management Fee Payments
export const addManagementFeePaymentURL = '/app/management-fee-payments/new/'
export const viewManagementFeePaymentsURL = '/app/management-fee-payments/'
export const viewManagementFeePaymentDetailURL =
	'/app/management-fee-payments/:managementFeePaymentId/'

/// ///////////////
// Resident Center
/// ///////////////

// Dashboard
export const residentCenterDashboardURL = '/rc/'

// Rent
export const residentCenterPayRentURL = '/rc/rent/pay-rent/'
export const residentCenterRentPaymentsURL = '/rc/rent/payments/'

// Work Order
export const residentCenterCreateWorkOrderURL = '/rc/work-orders/new/'
export const residentCenterViewWorkOrdersURL = '/rc/work-orders/'
export const residentCenterViewWorkOrderDetailURL =
	'/rc/work-orders/:workOrderId/'

// Lease
export const residentCenterViewLeasesURL = '/rc/'
export const residentCenterViewLeaseDetailsURL = '/rc/leases/:leaseId/'

// Lease Documents
export const residentCenterViewLeaseDocumentsURL = '/rc/leases/documents/'
export const residentCenterViewLeaseDocumentDetailURL =
	'/rc/leases/documents/:documentId/'

// Resident Documents - Proof of tenance insurance, utility connection, void cheque
export const residentCenterAddDocumentURL = '/rc/documents/new/'
export const residentCenterDocumentsURL = '/rc/documents/'
export const residentCenterDocumentDetailURL = '/rc/documents/:residentId/'

// Account Management
export const residentCenterChangePasswordURL = '/rc/account/change-password/'

/// ///////////////
// Maintenance Technician
/// ///////////////

// Dashboard
export const maintenanceTechnicianDashboardURL = '/mt/'

// Maintenance Technician - Account Management
export const maintenanceTechnicianChangePasswordURL =
	'/mt/account/change-password/'

// Maintenance Technician - Work Order
export const maintenanceTechnicianAddWorkOrderURL = '/mt/work-orders/new/'
export const maintenanceTechnicianViewWorkOrdersURL = '/mt/work-orders/'
export const maintenanceTechnicianViewWorkOrderDetailURL =
	'/mt/work-orders/:workOrderId/'

// Maintenance Technician - Time Tracking
export const maintenanceTechnicianAddTimeTrackingEntryURL =
	'/mt/time-tracking-entries/new/'
export const maintenanceTechnicianViewTimeTrackingEntriesURL =
	'/mt/time-tracking-entries/'
export const maintenanceTechnicianViewTimeTrackingEntryDetailURL =
	'/mt/time-tracking-entries/:timeTrackingEntryId/'

/// ///////////////
// Owner Portal
/// ///////////////

export const getTitleFromPathname = (pathname: string): string => {
	let title = 'Rentalize'

	// Property Manager
	if (pathname === propertyManagerDashboardURL) {
		title = 'Property Manager Dashboard | Rentalize'
	} else if (pathname === viewBuildingsURL) {
		title = 'Buildings | Rentalize'
	} else if (pathname === viewRentalUnitsURL) {
		title = 'Rental Units | Rentalize'
	} else if (pathname === viewTransactionsURL) {
		title = 'Transactions | Rentalize'
	} else if (pathname === viewJournalEntriesURL) {
		title = 'Journal Entries | Rentalize'
	} else if (pathname === viewLeasesURL) {
		title = 'Leases | Rentalize'
	} else if (pathname === viewResidentsURL) {
		title = 'Residents | Rentalize'
	} else if (pathname === viewPropertyOwnersURL) {
		title = 'Property Owners | Rentalize'
	} else if (pathname === currentVacanciesURL) {
		title = 'Current Vacancies | Rentalize'
	} else if (pathname === viewLeasesExpiringIn60DaysURL) {
		title = 'Leases Expiring in 60 Days | Rentalize'
	} else if (pathname === viewLeasesExpiringIn120DaysURL) {
		title = 'Leases Expiring in 120 Days | Rentalize'
	} else if (pathname === viewWorkOrdersURL) {
		title = 'Work Orders | Rentalize'
	} else if (pathname === viewTasksURL) {
		title = 'Tasks | Rentalize'
	} else if (pathname === viewEmailMessageBatchesURL) {
		title = 'Emails | Rentalize'
	} else if (pathname === viewBudgetsURL) {
		title = 'Budgets | Rentalize'
	} else if (pathname === viewManagementCompanyDetailsURL) {
		title = 'Management Company Details | Rentalize'
	} else if (pathname === viewFeeSchedulesURL) {
		title = 'Fee Schedules | Rentalize'
	} else if (pathname === viewPropertyManagersURL) {
		title = 'Property Managers | Rentalize'
	} else if (pathname === viewMaintenanceTechniciansURL) {
		title = 'Maintenance Technicians | Rentalize'
	} else if (pathname === viewRentalUnitListingsURL) {
		title = 'Listings | Rentalize'
	} else if (pathname === viewRentalApplicationFormsURL) {
		title = 'Application Forms | Rentalize'
	} else if (pathname === viewRentalApplicationsURL) {
		title = 'Rental Applications | Rentalize'
	} else if (pathname === viewMaintenanceProjectsURL) {
		title = 'Maintenance Projects | Rentalize'
	} else if (pathname === viewSMSsURL) {
		title = 'SMS Messages | Rentalize'
	} else if (pathname === viewAccountsURL) {
		title = 'GL Accounts | Rentalize'
	} else if (pathname === viewInventoryItemsURL) {
		title = 'Inventory Items | Rentalize'
	} else if (pathname === viewMortgagesURL) {
		title = 'Mortgages | Rentalize'
	} else if (pathname === viewVendorsURL) {
		title = 'Vendors | Rentalize'
	} else if (pathname === addBillURL) {
		title = 'Add Bill | Rentalize'
	} else if (pathname === viewBillsURL) {
		title = 'Bills | Rentalize'
	} else if (pathname === viewInvoicesURL) {
		title = 'Invoices | Rentalize'

		// Generate
	} else if (pathname === generateReportURL) {
		title = 'Generate Report | Rentalize'
	} else if (pathname === generateIncomeStatementURL) {
		title = 'Income Statement | Rentalize'
	} else if (pathname === generateBalanceSheetURL) {
		title = 'Balance Sheet | Rentalize'
	} else if (pathname === generateRentRollURL) {
		title = 'Rent Roll | Rentalize'
	} else if (pathname === generateTransactionRegisterReportURL) {
		title = 'Transaction Register | Rentalize'
	} else if (pathname === generateGeneralLedgerReportURL) {
		title = 'General Ledger Report | Rentalize'
	} else if (pathname === generateJournalTransactionsReportURL) {
		title = 'Journal Transactions Report | Rentalize'
	} else if (pathname === addJournalEntryURL) {
		title = 'Add Journal Entry | Rentalize'

		// Equipment Rental Tracking

		// Equipment
	} else if (pathname === addEquipmentURL) {
		title = 'Add Equipment | Rentalize'
	} else if (pathname === viewEquipmentDetailsURL) {
		title = 'Equipment Details | Rentalize'
	} else if (pathname === viewEquipmentURL) {
		title = 'Equipment | Rentalize'
	}

	// Equipment Rental
	else if (pathname === addEquipmentRentalURL) {
		title = 'Add Equipment Rental Rate | Rentalize'
	} else if (pathname === viewEquipmentRentalDetailsURL) {
		title = 'Equipment Rental Details | Rentalize'
	} else if (pathname === viewEquipmentRentalsURL) {
		title = 'Equipment Rentals | Rentalize'
	}

	// Equipment Rental Rate
	else if (pathname === addEquipmentRentalRateURL) {
		title = 'Add Equipment Rental Rate | Rentalize'
	} else if (pathname === viewEquipmentRentalRatesURL) {
		title = 'Equipment Rental Rates | Rentalize'
	} else if (pathname === viewEquipmentRentalRateDetailsURL) {
		title = 'Equipment Rental Rate Details | Rentalize'
	}

	// Management Fee Tracking

	// Management Fee Receivables
	else if (pathname === addManagementFeeReceivableURL) {
		title = 'Add Management Fee Receivable | Rentalize'
	} else if (pathname === viewManagementFeeReceivablesURL) {
		title = 'Management Fee Receivables | Rentalize'
	} else if (pathname === viewManagementFeeReceivableDetailURL) {
		title = 'Management Fee Receivable Detail | Rentalize'

		// Management Fee Payments
	} else if (pathname === addManagementFeePaymentURL) {
		title = 'Add Management Fee Payment | Rentalize'
	} else if (pathname === viewManagementFeePaymentsURL) {
		title = 'Management Fee Payments | Rentalize'
	} else if (pathname === viewManagementFeePaymentDetailURL) {
		title = 'Management Fee Payment Detail | Rentalize'
	}

	// Time Tracking
	else if (pathname === addTimeTrackingEntryURL) {
		title = 'Add Time Tracking Entry | Rentalize'
	} else if (pathname === viewTimeTrackingEntriesURL) {
		title = 'Time Tracking Entries | Rentalize'
	} else if (pathname === viewTimeTrackingEntryDetailURL) {
		title = 'Time Tracking Entry Detail | Rentalize'
	}

	// Purchase Orders
	else if (pathname === addPurchaseOrderURL) {
		title = 'Add Purchase Order | Rentalize'
	} else if (pathname === viewPurchaseOrdersURL) {
		title = 'Purchase Orders | Rentalize'
	} else if (pathname === viewPurchaseOrderDetailURL) {
		title = 'Purchase Order Detail | Rentalize'
	}

	// Bank Deposit
	else if (pathname === addBankDepositURL) {
		title = 'Add Bank Deposit | Rentalize'
	} else if (pathname === viewBankDepositsURL) {
		title = 'Bank Deposits | Rentalize'
	} else if (pathname === viewBankDepositDetailURL) {
		title = 'Bank Deposit Details | Rentalize'
	}

	// Bank Accounts
	else if (pathname === addBankAccountURL) {
		title = 'Add Bank Account | Rentalize'
	} else if (pathname === viewBankAccountsURL) {
		title = 'Bank Accounts | Rentalize'
	} else if (pathname === viewBankAccountDetailURL) {
		title = 'Bank Account Details | Rentalize'
	}

	// Bank Accounts
	else if (pathname === addBankReconciliationURL) {
		title = 'Add Bank Reconciliation | Rentalize'
	} else if (pathname === viewBankReconciliationsURL) {
		title = 'Bank Reconciliations | Rentalize'
	} else if (pathname === viewBankReconciliationDetailURL) {
		title = 'Bank Reconciliation Details | Rentalize'
	}

	// Resident Center
	else if (pathname === residentCenterDashboardURL) {
		title = 'Resident Center Dashboard | Rentalize'
	}

	// Maintenance Technician Dashboard
	else if (pathname === maintenanceTechnicianDashboardURL) {
		title = 'Maintenance Technician Dashboard | Rentalize'
	} else if (pathname === maintenanceTechnicianChangePasswordURL) {
		title = 'Change Password | Rentalize'
	}

	// Utility Meter
	else if (pathname === addUtilityMeterURL) {
		title = 'Add Utility Meter | Rentalize'
	} else if (pathname === viewUtilityMetersURL) {
		title = 'Utility Meters | Rentalize'
	} else if (pathname === viewUtilityMeterDetailURL) {
		title = 'Utility Meter Details | Rentalize'
	}

	// Utility Meter Reading
	else if (pathname === addUtilityMeterReadingURL) {
		title = 'Add Utility Meter Reading | Rentalize'
	} else if (pathname === viewUtilityMeterReadingsURL) {
		title = 'Utility Meter Readings | Rentalize'
	} else if (pathname === viewUtilityMeterReadingDetailURL) {
		title = 'Utility Meter Reading Details | Rentalize'
	}

	// Inspection Report
	else if (pathname === addInspectionReportURL) {
		title = 'Add Inspection Report | Rentalize'
	} else if (pathname === viewInspectionReportsURL) {
		title = 'Inspection Reports | Rentalize'
	} else if (pathname.startsWith(viewInspectionReportDetailsURL)) {
		title = 'Inspection Report Details | Rentalize'
	}

	// Others
	else {
		title = 'Rentalize'
	}
	return title
}
