import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { ChevronRightIcon, XCircleIcon } from '@heroicons/react/outline'
import { Fragment, Suspense, useEffect, useState } from 'react'

import {
	useGetMvft,
	useGetUserPermissions,
	useListFeatureFlags,
	useRecordFrontendCacheRefresh
} from 'api/rentalizeComponents'
import { Link, useNavigate } from 'react-router-dom'
import {
	propertyManagerDashboardURL,
	viewBuildingsURL,
	viewEmailMessageBatchesURL,
	viewPropertyOwnersURL,
	viewRentalUnitsURL,
	viewResidentsURL,
	viewSMSsURL
} from 'urls'
import LogoComponent from './Logo'
import {
	leasingNavigation,
	managementCompanyNavigation,
	operationsNavigation,
	reportingNavigation
} from './navbars/Navigation'
import PropertyManagerSideBar from './navbars/PropertyManagerSideBar'
import LoadingOrError from './LoadingOrError'
import WorkspaceWarningDrawer from './drawers/WorkspaceWarningDrawer'

const smsEntry = { name: 'SMS', href: viewSMSsURL }

export function VerticalMobileNavigation({
	closeSidebar
}: {
	closeSidebar: () => void
}) {
	const { data } = useGetUserPermissions({})
	const { data: featureFlag } = useListFeatureFlags({})
	const navigate = useNavigate()
	const logOut = (): void => {
		localStorage.removeItem('access_token')
		navigate('/')
	}
	window.addEventListener('vite:preloadError', () => {
		window.location.reload() // for example, refresh the page
	})

	const communicationsNavigation = {
		name: 'Communications',
		current: false,
		children: [{ name: 'Emails', href: viewEmailMessageBatchesURL }]
	}

	if (featureFlag?.sms) {
		communicationsNavigation.children.push(smsEntry)
	}

	const navigation: {
		name: string
		href?: string
		current: boolean
		children?: { name: string; href: string }[]
	}[] = [
		{ name: 'Dashboard', href: propertyManagerDashboardURL, current: true }
	]

	if (data?.has_management_company_permissions) {
		navigation.push(managementCompanyNavigation)
	}

	navigation.push({
		name: 'Properties',
		current: false,
		children: [
			{ name: 'Property Owners', href: viewPropertyOwnersURL },
			{ name: 'Buildings', href: viewBuildingsURL },
			{ name: 'Rental Units', href: viewRentalUnitsURL },
			{ name: 'Residents', href: viewResidentsURL }
		]
	})

	if (data?.has_leasing_permissions) {
		navigation.push(leasingNavigation)
	}

	if (data?.has_operations_permissions) {
		navigation.push(operationsNavigation)
	}

	if (data?.has_communications_permissions) {
		navigation.push(communicationsNavigation)
	}

	// Don't show accounting menus on mobile.
	// if (data?.has_accounting_permissions) {
	// 	navigation.push(accountingNavigation)
	// }

	if (data?.has_reporting_permissions) {
		navigation.push(reportingNavigation)
	}

	return (
		<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
			<div className='flex h-16 shrink-0 items-center justify-center'>
				<LogoComponent />
			</div>
			<nav className='items-left justify-left flex flex-col'>
				<ul className='space-y-1'>
					{navigation.map(item => (
						<li key={item.name}>
							{item.children ? (
								// For parent items with children
								<Disclosure as='div' className='text-center'>
									{({ open }) => (
										<>
											<Disclosure.Button className='group flex justify-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600'>
												<ChevronRightIcon
													className={`${
														open ? 'rotate-90' : ''
													} h-5 w-5 shrink-0 text-gray-400`}
													aria-hidden='true'
												/>
												{item.name}
											</Disclosure.Button>
											<Disclosure.Panel as='ul' className='space-y-1'>
												{item.children?.map(subItem => (
													<li key={subItem.name} className='text-left'>
														<Link
															to={subItem.href}
															onClick={closeSidebar}
															className='justify-left block cursor-pointer rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-50'
														>
															{subItem.name}
														</Link>
													</li>
												)) ?? null}
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							) : (
								// For items without children (if any exist)
								<Link
									to={item.href ?? '/default-path'}
									onClick={closeSidebar}
									className='justify-left block cursor-pointer rounded-md py-2 pl-10 pr-2 text-sm text-gray-700 hover:bg-gray-50'
								>
									{item.name}
								</Link>
							)}
						</li>
					))}
				</ul>
			</nav>
			<button
				type='button'
				onClick={logOut}
				className='mt-4 block rounded-md px-4 py-2 text-sm font-semibold leading-6 text-red-700 hover:bg-red-50'
			>
				Log out
			</button>
		</div>
	)
}

export default function ApplicationShell({
	component
}: {
	component: JSX.Element
}): React.ReactElement {
	const [sidebarOpen, setSidebarOpen] = useState(true)
	const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

	// Function to toggle the sidebar state
	const toggleSidebar = (newState: boolean) => {
		setSidebarOpen(newState)
	}

	const { data: mvft } = useGetMvft(
		{},
		{
			refetchInterval: 60000,
			staleTime: 60000,
			onSuccess: () => {}
		}
	)
	const { mutate } = useRecordFrontendCacheRefresh({})

	useEffect(() => {
		if (mvft) {
			const lastUpdateTime = localStorage.getItem('lastUpdateTime')
			if (!lastUpdateTime || parseInt(lastUpdateTime, 10) < mvft) {
				localStorage.setItem('lastUpdateTime', mvft.toString())
				mutate({})
				window.location.reload()
			}
		}
	}, [mvft, mutate])

	useEffect(() => {
		const handleResize = () => {
			const is14InchOrSmaller = window.innerWidth <= 1600 // 1440px is typically the width of a 14" MacBook
			setIsMobileView(window.innerWidth < 768)
			setSidebarOpen(!is14InchOrSmaller)
		}

		// Call the function initially
		handleResize()

		// Add event listener
		window.addEventListener('resize', handleResize)

		// Cleanup
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div>
			{/* Sidebar for mobile */}
			{isMobileView && (
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as='div'
						className='relative z-50 lg:hidden'
						onClose={() => !setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter='transition-opacity ease-linear duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='transition-opacity ease-linear duration-300'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<div className='fixed inset-0 bg-gray-900/80' />
						</Transition.Child>

						<div className='fixed inset-0 flex'>
							<Transition.Child
								as={Fragment}
								enter='transition ease-in-out duration-300 transform'
								enterFrom='-translate-x-full'
								enterTo='translate-x-0'
								leave='transition ease-in-out duration-300 transform'
								leaveFrom='translate-x-0'
								leaveTo='-translate-x-full'
							>
								<Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
									<Transition.Child
										as={Fragment}
										enter='ease-in-out duration-300'
										enterFrom='opacity-0'
										enterTo='opacity-100'
										leave='ease-in-out duration-300'
										leaveFrom='opacity-100'
										leaveTo='opacity-0'
									>
										<div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
											<button
												type='button'
												className='-m-2.5 p-2.5'
												onClick={() => setSidebarOpen(false)}
											>
												<span className='sr-only'>Close sidebar</span>
												<XCircleIcon
													className='h-6 w-6 text-white'
													aria-hidden='true'
												/>
											</button>
										</div>
									</Transition.Child>
									<VerticalMobileNavigation
										closeSidebar={() => setSidebarOpen(false)}
									/>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
			)}

			{/* Sidebar for desktop */}
			<PropertyManagerSideBar
				sidebarOpen={sidebarOpen}
				setSidebarOpen={toggleSidebar}
			/>
			<div>
				<div className='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8'>
					<button
						data-testid='open-mobile-sidebar'
						type='button'
						className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
						onClick={() => toggleSidebar(true)} // Use toggleSidebar to change state
					>
						<span className='sr-only'>Open sidebar</span>
						<LogoComponent />
					</button>
				</div>
				<Suspense fallback={<LoadingOrError />}>
					<main className='py-10'>
						<div className='px-4 sm:px-6 lg:px-8'>{component}</div>
					</main>
				</Suspense>
			</div>
			<WorkspaceWarningDrawer />
		</div>
	)
}
