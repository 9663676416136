import {
	ExclamationCircleIcon,
	ExclamationIcon
} from '@heroicons/react/outline'
import BaseDrawer from './BaseDrawer'
import { useState } from 'react'
import { useListWorkspaceWarnings } from 'api/rentalizeComponents'

function FloatingActionButton({
	onClick
}: {
	onClick: () => void
}): React.ReactElement {
	return (
		<div className='fixed bottom-11 right-0 p-4'>
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<button
				// label='Open Command Palette'
				className='flex h-10 w-10 items-center justify-center rounded-full bg-red-800 text-white'
				onClick={onClick}
			>
				<svg
					className='h-6 w-6'
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'
				>
					<ExclamationCircleIcon className='h-6 w-6 bg-red-600' />
				</svg>
			</button>
		</div>
	)
}

export default function WorkspaceWarningDrawer() {
	const [open, setOpen] = useState(false)
	const { data } = useListWorkspaceWarnings({})

	if (data?.items.length === 0) {
		return null
	}

	return (
		<>
			{' '}
			<FloatingActionButton onClick={() => setOpen(true)} />
			<BaseDrawer open={open} setOpen={setOpen}>
				<div className='space-y-4'>
					<h2 className='text-xl font-semibold text-gray-900'>
						Workspace Warnings
					</h2>
					<p className='text-sm text-gray-600'>
						Configuration issues with your Rentalize workspace that might cause
						you trouble.
					</p>
				</div>
				{data && data.items.length > 0 ? (
					<div className='mt-6 grid gap-4'>
						{data.items.map(warning => (
							<div
								key={warning.slug}
								className='rounded-lg border border-yellow-200 bg-yellow-50 p-4'
							>
								<div className='flex items-start'>
									<div className='flex-shrink-0'>
										<ExclamationIcon
											className='h-5 w-5 text-yellow-400'
											aria-hidden='true'
										/>
									</div>
									<div className='ml-3'>
										<h3 className='text-sm font-medium text-yellow-800'>
											{warning.name}
										</h3>
										<div className='mt-2 text-sm text-yellow-700'>
											<p>{warning.description}</p>
										</div>
										{warning.action_url && (
											<div className='mt-4'>
												<a
													href={warning.action_url}
													className='text-sm font-medium text-yellow-800 hover:text-yellow-700'
												>
													Fix Issue <span aria-hidden='true'>&rarr;</span>
												</a>
											</div>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<p className='mt-6 text-sm text-gray-500'>No warnings found.</p>
				)}
			</BaseDrawer>
		</>
	)
}
