import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

const navigation = [
	{ name: 'Features', href: '/features/' },
	{ name: 'Pricing', href: '/pricing/' },
	{ name: 'Integrations', href: '/integrations/' },
	{ name: 'FAQs', href: '/FAQs/' }
]

export default function HomePageNavbar(): React.ReactElement {
	const token = localStorage.getItem('access_token')

	return (
		<div className='relative bg-white pt-6'>
			<Popover>
				<nav
					className='relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6'
					aria-label='Global'
				>
					<div className='flex flex-1 items-center'>
						<div className='flex w-full items-center justify-between md:w-auto'>
							<Link to='/'>
								<span className='sr-only'>Rentalize</span>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth='1.5'
									stroke='currentColor'
									className='h-6 w-auto text-blue-600'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
									/>
								</svg>
							</Link>
							<div className='-mr-2 flex items-center md:hidden'>
								<Popover.Button className='focus-ring-inset inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-white focus:outline-none focus:ring-2 focus:ring-white'>
									<span className='sr-only'>Open main menu</span>
									<MenuIcon className='h-6 w-6' aria-hidden='true' />
								</Popover.Button>
							</div>
						</div>
						<div className='hidden space-x-10  md:ml-10 md:flex'>
							{navigation.map(item => (
								<Link
									key={item.name}
									to={item.href}
									className='font-medium text-gray-700 hover:text-gray-600'
								>
									{item.name}
								</Link>
							))}
						</div>
					</div>
					<div className='hidden md:flex'>
						<a
							href={token ? '/app/' : '/login/'}
							className='inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-white'
						>
							Log in
						</a>
					</div>
				</nav>

				<Transition
					as={Fragment}
					enter='duration-150 ease-out'
					enterFrom='opacity-0 scale-95'
					enterTo='opacity-100 scale-100'
					leave='duration-100 ease-in'
					leaveFrom='opacity-100 scale-100'
					leaveTo='opacity-0 scale-95'
				>
					<Popover.Panel
						focus
						className='absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden'
					>
						<div className='overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5'>
							<div className='flex items-center justify-between px-5 pt-4'>
								<div>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth='1.5'
										stroke='currentColor'
										className='h-6 w-6 text-blue-600'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
										/>
									</svg>
								</div>
								<div className='-mr-2'>
									<Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
										<span className='sr-only'>Close menu</span>
										<XIcon className='h-6 w-6' aria-hidden='true' />
									</Popover.Button>
								</div>
							</div>
							<div className='space-y-1 px-2 pb-3 pt-2'>
								{navigation.map(item => (
									<a
										key={item.name}
										href={item.href}
										className='block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-white hover:text-gray-900'
									>
										{item.name}
									</a>
								))}
							</div>
							<a
								href={token ? '/app/' : '/login/'}
								className='block w-full bg-white px-5 py-3 text-center font-medium text-blue-600 hover:bg-white'
							>
								Log in
							</a>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	)
}
