import {
	ClipboardListIcon,
	DocumentTextIcon,
	FolderOpenIcon,
	KeyIcon,
	LockClosedIcon
} from '@heroicons/react/outline'

import {
	residentCenterChangePasswordURL,
	residentCenterCreateWorkOrderURL,
	residentCenterDashboardURL,
	residentCenterDocumentsURL,
	residentCenterViewLeasesURL,
	residentCenterViewWorkOrdersURL
} from 'urls'
import BaseNavBar from './BaseNavBar'

const mainHeadings = [
	// Temporarily Removed the View Rent Payments
	// {
	// 	heading: 'Rent',
	// 	dashboard: '/rc/rent',
	// 	icon: OfficeBuildingIcon,
	// 	subheadings: [
	// 		{
	// 			name: 'View rent payments',
	// 			description: 'View and export historical rent payments.',
	// 			href: '/rc/rent/payments/',
	// 			icon: OfficeBuildingIcon
	// 		}
	// 	]
	// },
	{
		heading: 'Leases',
		icon: KeyIcon,
		dashboard: residentCenterViewLeasesURL,
		subheadings: [
			{
				name: 'View Your Leases',
				description: 'View your leases.',
				href: residentCenterViewLeasesURL,
				icon: FolderOpenIcon
			}
		]
	},
	{
		heading: 'Work Orders',
		dashboard: residentCenterViewWorkOrdersURL,
		icon: ClipboardListIcon,
		subheadings: [
			{
				name: 'Create Work Order',
				description: 'Create a work order for your rental unit.',
				href: residentCenterCreateWorkOrderURL,
				icon: ClipboardListIcon
			},
			{
				name: 'View Work Orders',
				description: 'View historical work orders.',
				href: residentCenterViewWorkOrdersURL,
				icon: FolderOpenIcon
			}
		]
	},
	{
		heading: 'Documents',
		dashboard: residentCenterDocumentsURL,
		icon: DocumentTextIcon,
		subheadings: [
			{
				name: 'View Documents',
				description: 'View uploaded documents.',
				href: residentCenterDocumentsURL,
				icon: FolderOpenIcon
			}
		]
	},
	{
		heading: 'Account',
		dashboard: residentCenterChangePasswordURL,
		icon: LockClosedIcon,
		subheadings: [
			{
				name: 'Change Password',
				description: 'Change your account password',
				href: residentCenterChangePasswordURL,
				icon: LockClosedIcon
			}
		]
	}
]

export default function ResidentCenterNavbar(): React.ReactElement {
	return (
		<BaseNavBar
			homeURL={residentCenterDashboardURL}
			mainHeadings={mainHeadings}
		/>
	)
}
