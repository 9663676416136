import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classnames from 'classnames'
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface BaseNavBarProps {
	homeURL: string
	mainHeadings: any[]
}

export default function BaseNavBar({
	homeURL,
	mainHeadings
}: BaseNavBarProps): React.ReactElement {
	const navigate = useNavigate()

	const logOut = (): void => {
		localStorage.removeItem('access_token')
		navigate('/')
	}
	return (
		<Popover className='relative bg-white'>
			<div className='mx-auto max-w-7xl px-4 sm:px-6'>
				<div className='flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10'>
					<div className='flex justify-start lg:w-0 lg:flex-1'>
						<Link to={homeURL}>
							<span className='sr-only'>Rentalize</span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth='1.5'
								stroke='currentColor'
								className='h-8 w-8 text-blue-600'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
								/>
							</svg>
						</Link>
					</div>
					<div className='-my-2 -mr-2 md:hidden'>
						<Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
							<span className='sr-only'>Open menu</span>
							<MenuIcon className='h-6 w-6' aria-hidden='true' />
						</Popover.Button>
					</div>
					<Popover.Group as='nav' className='hidden space-x-10 md:flex'>
						{mainHeadings.map(
							(heading): React.ReactElement => (
								<Popover className='relative' key={heading.heading}>
									{({ open }): React.ReactElement => (
										<>
											<Popover.Button
												className={classnames(
													open ? 'text-gray-900' : 'text-gray-500',
													'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
												)}
											>
												<span>{heading.heading}</span>
												<ChevronDownIcon
													className={classnames(
														open ? 'text-gray-600' : 'text-gray-400',
														'ml-2 h-5 w-5 group-hover:text-gray-500'
													)}
													aria-hidden='true'
												/>
											</Popover.Button>

											<Transition
												as={Fragment}
												enter='transition ease-out duration-200'
												enterFrom='opacity-0 translate-y-1'
												enterTo='opacity-100 translate-y-0'
												leave='transition ease-in duration-150'
												leaveFrom='opacity-100 translate-y-0'
												leaveTo='opacity-0 translate-y-1'
											>
												<Popover.Panel className='absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2'>
													<div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
														<div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
															{heading.subheadings.map((item: any) => (
																<Popover.Button
																	as={Link}
																	key={item.name}
																	to={item.href}
																	className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
																>
																	<item.icon
																		className='h-6 w-6 flex-shrink-0 text-blue-600'
																		aria-hidden='true'
																	/>
																	<div className='ml-4'>
																		<p className='text-base font-medium text-gray-900'>
																			{item.name}
																		</p>
																		<p className='mt-1 text-sm text-gray-500'>
																			{item.description}
																		</p>
																	</div>
																</Popover.Button>
															))}
														</div>
													</div>
												</Popover.Panel>
											</Transition>
										</>
									)}
								</Popover>
							)
						)}
					</Popover.Group>
					<div className='hidden items-center justify-end md:flex md:flex-1 lg:w-0'>
						<Link
							onClick={logOut}
							to='/'
							className='ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700'
						>
							Log out
						</Link>
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter='duration-200 ease-out'
				enterFrom='opacity-0 scale-95'
				enterTo='opacity-100 scale-100'
				leave='duration-100 ease-in'
				leaveFrom='opacity-100 scale-100'
				leaveTo='opacity-0 scale-95'
			>
				<Popover.Panel
					focus
					className='absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden'
				>
					<div className='divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
						<div className='px-5 pb-6 pt-5'>
							<div className='flex items-center justify-between'>
								<div>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth='1.5'
										stroke='currentColor'
										className='h-8 w-8 text-blue-600'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819'
										/>
									</svg>
								</div>
								<div className='-mr-2'>
									<Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
										<span className='sr-only'>Close menu</span>
										<XIcon className='h-6 w-6' aria-hidden='true' />
									</Popover.Button>
								</div>
							</div>
							<div className='mt-6'>
								<nav className='grid gap-y-8'>
									{mainHeadings.map(item => (
										<a
											key={item.heading}
											href={item.dashboard}
											className='-m-3 flex items-center rounded-md p-3 hover:bg-gray-50'
										>
											<item.icon
												className='h-6 w-6 flex-shrink-0 text-blue-600'
												aria-hidden='true'
											/>
											<span className='ml-3 text-base font-medium text-gray-900'>
												{item.heading}
											</span>
										</a>
									))}
								</nav>
							</div>
						</div>
						<div className='space-y-6 px-5 py-6'>
							<div>
								<Link
									onClick={logOut}
									to='/'
									className='flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700'
								>
									Log Out
								</Link>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
