import MaintenanceTechnicianNavbar from 'components/navbars/MaintenanceTechnicianNavbar'
import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
	maintenanceTechnicianAddTimeTrackingEntryURL,
	maintenanceTechnicianAddWorkOrderURL,
	maintenanceTechnicianChangePasswordURL,
	maintenanceTechnicianDashboardURL,
	maintenanceTechnicianViewTimeTrackingEntriesURL,
	maintenanceTechnicianViewTimeTrackingEntryDetailURL,
	maintenanceTechnicianViewWorkOrderDetailURL,
	maintenanceTechnicianViewWorkOrdersURL
} from 'urls'

// Dashboard
const MaintenanceTechnicianDashboard = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianDashboard')
)

// Work Order
const MaintenanceTechnicianViewWorkOrdersPage = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianViewWorkOrdersPage')
)
const MaintenanceTechnicianWorkOrderDetailPage = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianWorkOrderDetailPage')
)
const MaintenanceTechnicianAddWorkOrderPage = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianAddWorkOrderPage')
)

// Time Tracking
const MaintenanceTechnicianAddTimeTrackingEntryPage = lazy(
	async () =>
		import('../pages/mt/MaintenanceTechnicianAddTimeTrackingEntryPage')
)
const MaintenanceTechnicianTimeTrackingEntriesPage = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianTimeTrackingEntriesPage')
)
const MaintenanceTechnicianTimeTrackingEntryDetailPage = lazy(
	async () =>
		import('../pages/mt/MaintenanceTechnicianTimeTrackingEntryDetailPage')
)

// Account
const MaintenanceTechnicianChangePasswordPage = lazy(
	async () => import('../pages/mt/MaintenanceTechnicianChangePasswordPage')
)

const addNavbarToElement = (component: JSX.Element): React.ReactElement => (
	<>
		<MaintenanceTechnicianNavbar />
		{component}
	</>
)

export default function MaintenanceTechnicianApp(): React.ReactElement {
	return (
		<Routes>
			{/* Dashboard */}
			<Route
				path={maintenanceTechnicianDashboardURL}
				element={addNavbarToElement(<MaintenanceTechnicianDashboard />)}
			/>

			{/* View Work Orders */}
			<Route
				path={maintenanceTechnicianViewWorkOrdersURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianViewWorkOrdersPage />
				)}
			/>
			{/* Add Work Order */}
			<Route
				path={maintenanceTechnicianAddWorkOrderURL}
				element={addNavbarToElement(<MaintenanceTechnicianAddWorkOrderPage />)}
			/>

			<Route
				path={maintenanceTechnicianViewWorkOrderDetailURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianWorkOrderDetailPage />
				)}
			/>

			{/* Time Tracking */}
			<Route
				path={maintenanceTechnicianAddTimeTrackingEntryURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianAddTimeTrackingEntryPage />
				)}
			/>
			<Route
				path={maintenanceTechnicianViewTimeTrackingEntriesURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianTimeTrackingEntriesPage />
				)}
			/>
			<Route
				path={maintenanceTechnicianViewTimeTrackingEntryDetailURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianTimeTrackingEntryDetailPage />
				)}
			/>

			{/* Account */}
			<Route
				path={maintenanceTechnicianChangePasswordURL}
				element={addNavbarToElement(
					<MaintenanceTechnicianChangePasswordPage />
				)}
			/>
		</Routes>
	)
}
