import ApplicationShell from 'components/ApplicationShell'
import CommandPalette from 'components/CommandPalette'
import type { ReactElement } from 'react'
import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
	acceptPropertyManagerInvitationURL,
	addAPIKeyURL,
	addAccountURL,
	addApplianceURL,
	addBankAccountURL,
	addBankDepositURL,
	addBankReconciliationURL,
	addBillURL,
	addBillableExpenseURL,
	addBudgetURL,
	addBuildingURL,
	addBulkRentIncreaseNoticeURL,
	addCreditCardURL,
	addDocumentURL,
	addEmailDomainURL,
	addEmailMessageBatchURL,
	addEmailSenderURL,
	addEquipmentRentalRateURL,
	addEquipmentRentalURL,
	addEquipmentURL,
	addFeeScheduleURL,
	addInventoryItemURL,
	addInventoryTransactionURL,
	addInvoiceURL,
	addJournalEntryURL,
	addLeaseAttachmentURL,
	addLeaseURL,
	addLenderURL,
	addMaintenanceProjectURL,
	addMaintenanceTechnicianURL,
	addMakeReadyURL,
	addManagementFeePaymentURL,
	addManagementFeeReceivableURL,
	addMarkupTypeURL,
	addMortgageURL,
	addParkingSpaceURL,
	addPropertyGroupURL,
	addPropertyInspectionFormURL,
	addPropertyOwnerURL,
	addPurchaseOrderURL,
	addRecurringJournalEntryURL,
	addRecurringTaskURL,
	addRentPaymentScheduleURL,
	addRentPaymentURL,
	addRentReceivableURL,
	addRentalApplicationFormURL,
	addRentalUnitListingURL,
	addRentalUnitURL,
	addResidentPetURL,
	addResidentURL,
	addResidentVehicleURL,
	addSMSURL,
	addScheduledReportURL,
	addSecurityDepositURL,
	addStorageUnitLeaseURL,
	addStripeAccountURL,
	addTaskURL,
	addTimeTrackingEntryURL,
	addUtilityMeterReadingURL,
	addUtilityMeterURL,
	addVehicleURL,
	addVendorCreditURL,
	addVendorURL,
	addWaitlistSubscriberURL,
	addWorkOrderURL,
	askRentalizeAIQuestionURL,
	bulkGenerateWorkOrderInvoicesURL,
	bulkMarkRentAsPaidURL,
	bulkPayBillURL,
	changePasswordURL,
	contactSupportURL,
	createAIConversationURL,
	currentVacanciesURL,
	generateAgedPayablesReportURL,
	generateAgedReceivablesReportURL,
	generateBalanceSheetURL,
	generateChartOfAccountsReportURL,
	generateClosedTasksReportURL,
	generateClosedWorkOrdersReportURL,
	generateDscrReportURL,
	generateDscrSensitivityReportURL,
	generateEFTPaymentFileURL,
	generateGeneralLedgerReportURL,
	generateIncomeStatementURL,
	generateJournalTransactionsReportURL,
	generateLossToLeaseReportURL,
	generateManagementFeeSummaryReportURL,
	generateMoveInMoveOutReportURL,
	generateOpenTasksReportURL,
	generateOpenWorkOrdersReportURL,
	generatePOListingReportURL,
	generateParkingSpaceAssignmentReportURL,
	generatePhysicalInventoryReportURL,
	generateRentDelinquencyReportURL,
	generateRentLedgerReportURL,
	generateRentOutstandingReportURL,
	generateRentReceiptURL,
	generateRentRollComparisonReportURL,
	generateRentRollURL,
	generateReportURL,
	generateServiceTechHoursURL,
	generateTaskPerformanceReportURL,
	generateTimeToLeaseReportURL,
	generateTransactionRegisterReportURL,
	generateTrialBalanceReportURL,
	generateUpcomingRentIncreaseReportURL,
	generateWorkOrderPerformanceReportURL,
	generateWorkOrderSummaryReportURL,
	inviteMaintenanceTechnicianURL,
	invitePropertyManagerURL,
	propertyManagementRevenueURL,
	recordExpenseURL,
	upcomingVacanciesURL,
	userSettingsURL,
	viewAPIKeysURL,
	viewAccountDetailURL,
	viewAccountsURL,
	viewApplianceDetailsURL,
	viewAppliancesURL,
	viewBankAccountDetailURL,
	viewBankAccountsURL,
	viewBankDepositDetailURL,
	viewBankDepositsURL,
	viewBankReconciliationDetailURL,
	viewBankReconciliationsURL,
	viewBillDetailURL,
	viewBillPaymentDetailsURL,
	viewBillPaymentsURL,
	viewBillableExpensesURL,
	viewBillsURL,
	viewBudgetDetailsURL,
	viewBudgetsURL,
	viewBuildingDetailURL,
	viewBuildingsURL,
	viewBulkBillPaymentDetailsURL,
	viewBulkBillPaymentsURL,
	viewBulkRentIncreaseNoticeDetailsURL,
	viewBulkRentIncreaseNoticesURL,
	viewCreditCardDetailURL,
	viewCreditCardsURL,
	viewDocumentDetailURL,
	viewDocumentsURL,
	viewDraftEmailMessageBatchDetailURL,
	viewEmailDomainDetailURL,
	viewEmailDomainsURL,
	viewEmailMessageBatchDetailURL,
	viewEmailMessageBatchesURL,
	viewEmailMessageDetailURL,
	viewEmailMessagesURL,
	viewEmailSenderDetailURL,
	viewEmailSendersURL,
	viewEquipmentDetailsURL,
	viewEquipmentRentalDetailsURL,
	viewEquipmentRentalRateDetailsURL,
	viewEquipmentRentalRatesURL,
	viewEquipmentRentalsURL,
	viewEquipmentURL,
	viewFeeScheduleDetailURL,
	viewFeeSchedulesURL,
	viewFormerResidentsURL,
	viewFutureResidentsURL,
	viewInventoryItemDetailsURL,
	viewInventoryItemsURL,
	viewInventoryTransactionDetailsURL,
	viewInventoryTransactionsURL,
	viewInvoiceDetailURL,
	viewInvoicesURL,
	viewJournalEntriesURL,
	viewJournalEntryDetailURL,
	viewLeaseAttachmentDetailURL,
	viewLeaseAttachmentsURL,
	viewLeaseDetailsURL,
	viewLeasesExpiringIn120DaysURL,
	viewLeasesExpiringIn60DaysURL,
	viewLeasesURL,
	viewLenderDetailsURL,
	viewLendersURL,
	viewMaintenanceProjectDetailsURL,
	viewMaintenanceProjectsURL,
	viewMaintenanceTechnicianDetailsURL,
	viewMaintenanceTechniciansURL,
	viewMakeReadyDetailURL,
	viewMakeReadyURL,
	viewManagementCompanyDetailsURL,
	viewManagementFeePaymentDetailURL,
	viewManagementFeePaymentsURL,
	viewManagementFeeReceivableDetailURL,
	viewManagementFeeReceivablesURL,
	viewMarkupTypeDetailURL,
	viewMarkupTypesURL,
	viewMortgageDetailsURL,
	viewMortgagesURL,
	viewNoteDetailsURL,
	viewParkingSpaceDetailURL,
	viewParkingSpacesURL,
	viewPropertyGroupDetailsURL,
	viewPropertyGroupsURL,
	viewPropertyInspectionDetailsURL,
	viewPropertyInspectionFormDetailsURL,
	viewPropertyInspectionFormsURL,
	viewPropertyInspectionsURL,
	viewPropertyManagerDetailsURL,
	viewPropertyManagersURL,
	viewPropertyOwnerDetailURL,
	viewPropertyOwnersURL,
	viewPurchaseOrderDetailURL,
	viewPurchaseOrdersURL,
	viewRecurringJournalEntriesURL,
	viewRecurringTaskDetailsURL,
	viewRecurringTasksURL,
	viewRemittanceLetterDetailsURL,
	viewRemittanceLettersURL,
	viewRentIncreaseDetailsURL,
	viewRentIncreasesURL,
	viewRentPaymentDetailURL,
	viewRentPaymentScheduleDetailsURL,
	viewRentPaymentSchedulesURL,
	viewRentPaymentsURL,
	viewRentReceivableDetailURL,
	viewRentReceivablesURL,
	viewRentalApplicantsURL,
	viewRentalApplicationDetailURL,
	viewRentalApplicationFormDetailURL,
	viewRentalApplicationFormsURL,
	viewRentalApplicationsURL,
	viewRentalUnitDetailURL,
	viewRentalUnitListingDetailURL,
	viewRentalUnitListingsURL,
	viewRentalUnitsURL,
	viewResidentDetailURL,
	viewResidentPetDetailsURL,
	viewResidentPetsURL,
	viewResidentVehicleDetailURL,
	viewResidentVehiclesURL,
	viewResidentsURL,
	viewSMSDetailURL,
	viewSMSsURL,
	viewScheduledReportDetailsURL,
	viewScheduledReportsURL,
	viewSecurityDepositDetailURL,
	viewSecurityDepositsURL,
	viewSignatureRequestDetailURL,
	viewSignatureRequestsURL,
	viewStorageCustomersURL,
	viewStorageFacilitiesURL,
	viewStorageUnitInvoiceDetailsURL,
	viewStorageUnitInvoicesURL,
	viewStorageUnitLeaseDetailsURL,
	viewStorageUnitLeasesURL,
	viewStorageUnitsURL,
	viewStripeAccountDetailURL,
	viewStripeAccountsURL,
	viewTaskDetailsURL,
	viewTasksURL,
	viewTimeTrackingEntriesURL,
	viewTimeTrackingEntryDetailURL,
	viewTransactionDetailsURL,
	viewTransactionsURL,
	viewUtilityMeterDetailURL,
	viewUtilityMeterReadingDetailURL,
	viewUtilityMeterReadingsURL,
	viewUtilityMetersURL,
	viewVehicleDetailURL,
	viewVehiclesURL,
	viewVendorCreditDetailURL,
	viewVendorCreditsURL,
	viewVendorDetailURL,
	viewVendorsURL,
	viewWaitlistSubscriberDetailURL,
	viewWaitlistSubscribersURL,
	viewWorkOrderDetailURL,
	viewWorkOrdersURL,
	addInspectionReportURL,
	viewInspectionReportsURL,
	viewInspectionReportDetailsURL
} from 'urls'

/// /////////////////
// Property Manager //
/// /////////////////

// Listings
const AddListingPage = lazy(async () => import('../pages/pms/AddListingPage'))
const ViewListingsPage = lazy(
	async () => import('../pages/pms/ViewListingsPage')
)

// Buildings
const AddBuildingPage = lazy(async () => import('../pages/pms/AddBuildingPage'))
const BuildingsPage = lazy(async () => import('../pages/pms/BuildingsPage'))
const BuildingDetailPage = lazy(
	async () => import('../pages/pms/BuildingDetailPage')
)

// Waitlist
const AddWaitlistSubscriberPage = lazy(
	async () => import('../pages/pms/AddWaitlistSubscriberPage')
)
const WaitlistSubscribersPage = lazy(
	async () => import('../pages/pms/WaitlistSubscribersPage')
)
const WaitlistSubscriberDetailPage = lazy(
	async () => import('../pages/pms/WaitlistSubscriberDetailsPage')
)

// Fee schedule
const AddFeeSchedulePage = lazy(
	async () => import('../pages/pms/AddFeeSchedulePage')
)
const FeeSchedulesPage = lazy(
	async () => import('../pages/pms/FeeSchedulesPage')
)
const FeeScheduleDetailPage = lazy(
	async () => import('../pages/pms/FeeScheduleDetailsPage')
)

// Property group
const AddPropertyGroupPage = lazy(
	async () => import('../pages/pms/AddPropertyGroupPage')
)
const PropertyGroupsPage = lazy(
	async () => import('../pages/pms/PropertyGroupsPage')
)
const PropertyGroupDetailPage = lazy(
	async () => import('../pages/pms/PropertyGroupDetailsPage')
)

// Recurring task
const AddRecurringTaskPage = lazy(
	async () => import('../pages/pms/AddRecurringTaskPage')
)
const RecurringTasksPage = lazy(
	async () => import('../pages/pms/RecurringTasksPage')
)
const RecurringTaskDetailsPage = lazy(
	async () => import('../pages/pms/RecurringTaskDetailsPage')
)

// Journal entry
const AddJournalEntryPage = lazy(
	async () => import('../pages/pms/AddJournalEntryPage')
)
const JournalEntriesPage = lazy(
	async () => import('../pages/pms/JournalEntriesPage')
)
const JournalEntryDetailPage = lazy(
	async () => import('../pages/pms/JournalEntryDetailPage/index')
)

// Markup
const AddMarkupTypePage = lazy(
	async () => import('../pages/pms/AddMarkupTypePage')
)
const MarkupTypesPage = lazy(async () => import('../pages/pms/MarkupTypesPage'))
const MarkupTypeDetailsPage = lazy(
	async () => import('../pages/pms/MarkupTypeDetailsPage')
)

// Transactions
const TransactionsPage = lazy(
	async () => import('../pages/pms/TransactionsPage')
)
const TransactionDetailsPage = lazy(
	async () => import('../pages/pms/TransactionDetailsPage')
)

// Journal entry
const AddRecurringJournalEntryPage = lazy(
	async () => import('../pages/pms/AddRecurringJournalEntryPage')
)
const RecurringJournalEntriesPage = lazy(
	async () => import('../pages/pms/RecurringJournalEntriesPage')
)

// Mortgage
const AddMortgagePage = lazy(async () => import('../pages/pms/AddMortgagePage'))
const MortgagesPage = lazy(async () => import('../pages/pms/MortgagesPage'))
const MortgageDetailPage = lazy(
	async () => import('../pages/pms/MortgageDetailsPage')
)

// Lender
const AddLenderPage = lazy(async () => import('../pages/pms/AddLenderPage'))
const LendersPage = lazy(async () => import('../pages/pms/LendersPage'))
const LenderDetailPage = lazy(
	async () => import('../pages/pms/LenderDetailsPage')
)

// Bill
const AddBillPage = lazy(async () => import('../pages/pms/AddBillPage'))
const BillsPage = lazy(async () => import('../pages/pms/BillsPage'))
const BillDetailPage = lazy(async () => import('../pages/pms/BillDetailPage'))

// Billable Expenses
const AddBillableExpensePage = lazy(
	async () => import('../pages/pms/AddBillableExpensePage')
)
const BillableExpensesPage = lazy(
	async () => import('../pages/pms/BillableExpensesPage')
)

// Bulk Bill
const BulkPayBillPage = lazy(async () => import('../pages/pms/BulkPayBillPage'))

// Email domain
const AddEmailDomainPage = lazy(
	async () => import('../pages/pms/AddEmailDomainPage')
)
const EmailDomainsPage = lazy(
	async () => import('../pages/pms/EmailDomainsPage')
)
const EmailDomainDetailPage = lazy(
	async () => import('../pages/pms/EmailDomainDetailsPage')
)

// Email sender
const AddEmailSenderPage = lazy(
	async () => import('../pages/pms/AddEmailSenderPage')
)
const EmailSendersPage = lazy(
	async () => import('../pages/pms/EmailSendersPage')
)

const EmailSenderDetailPage = lazy(
	async () => import('../pages/pms/EmailSenderDetailsPage')
)

// Account
const AddAccountPage = lazy(async () => import('../pages/pms/AddAccountPage'))
const AccountsPage = lazy(async () => import('../pages/pms/AccountsPage'))
const AccountDetailPage = lazy(
	async () => import('../pages/pms/AccountDetailsPage')
)

// Task
const AddTaskPage = lazy(async () => import('../pages/pms/AddTaskPage'))
const TasksPage = lazy(async () => import('../pages/pms/TasksPage'))
const TaskDetailsPage = lazy(async () => import('../pages/pms/TaskDetailsPage'))

// Property manager
const InvitePropertyManagerPage = lazy(
	async () => import('../pages/pms/InvitePropertyManagerPage')
)
const PropertyManagersPage = lazy(
	async () => import('../pages/pms/PropertyManagersPage')
)
const PropertyManagerDetailsPage = lazy(
	async () => import('../pages/pms/PropertyManagerDetailsPage')
)
const AcceptPropertyManagerInvitationPage = lazy(
	async () => import('../pages/pms/AcceptPropertyManagerInvitationPage')
)

// Property Management Revenue
const PropertyManagementRevenuePage = lazy(
	async () => import('../pages/pms/PropertyManagementRevenuePage')
)

/// /////////
// Others //
/// /////////

// Storage
const StorageFacilitiesPage = lazy(
	async () => import('../pages/pms/StorageFacilitiesPage')
)
const StorageCustomersPage = lazy(
	async () => import('../pages/pms/StorageCustomersPage')
)
const StorageUnitsPage = lazy(
	async () => import('../pages/pms/StorageUnitsPage')
)

// Management Company Details
const ManagementCompanyDetailsPage = lazy(
	async () =>
		import(
			'../pages/pms/ManagementCompanyDetailsPage/ManagementCompanyDetailsPage'
		)
)

// Dashboard
const DashboardPage = lazy(async () => import('../pages/pms/DashboardPage'))

// Ask Rentalize AI question
const AskRentalizeAIQuestionPage = lazy(
	async () => import('../pages/pms/AddRentalizeAIQuestionPage')
)

// Current vacancies
const CurrentVacanciesPage = lazy(
	async () => import('../pages/pms/CurrentVacanciesPage')
)

// User settings
const UserSettingsPage = lazy(
	async () => import('../pages/pms/UserSettingsPage')
)

// Contact support
const ContactSupportPage = lazy(
	async () => import('../pages/pms/ContactSupportPage')
)

// Note
const NoteDetailsPage = lazy(async () => import('../pages/pms/NoteDetailsPage'))

// Appliance
const AddAppliancePage = lazy(
	async () => import('../pages/pms/AddAppliancePage')
)
const AppliancesPage = lazy(async () => import('../pages/pms/AppliancesPage'))
const ApplianceDetailsPage = lazy(
	async () => import('../pages/pms/ApplianceDetailsPage')
)

// Appliance
const AddSecurityDepositPage = lazy(
	async () => import('../pages/pms/AddSecurityDepositPage')
)
const SecurityDepositsPage = lazy(
	async () => import('../pages/pms/SecurityDepositsPage')
)
const SecurityDepositDetailsPage = lazy(
	async () => import('../pages/pms/SecurityDepositDetailsPage')
)

// Resident Pets
const AddResidentPetPage = lazy(
	async () => import('../pages/pms/AddResidentPetPage')
)
const ResidentPetsPage = lazy(
	async () => import('../pages/pms/ResidentPetsPage')
)
const ResidentPetDetailsPage = lazy(
	async () => import('../pages/pms/ResidentPetDetailsPage')
)

// Documents
const AddDocumentPage = lazy(async () => import('../pages/pms/AddDocumentPage'))
const DocumentsPage = lazy(async () => import('../pages/pms/DocumentsPage'))
const DocumentDetailPage = lazy(
	async () => import('../pages/pms/DocumentDetailPage')
)

// Lease attachment
const AddLeaseAttachmentPage = lazy(
	async () => import('../pages/pms/AddLeaseAttachmentPage')
)
const LeaseAttachmentsPage = lazy(
	async () => import('../pages/pms/LeaseAttachmentsPage')
)
const LeaseAttachmentDetailPage = lazy(
	async () => import('../pages/pms/LeaseAttachmentDetailsPage')
)

// Storage unit leases
const AddStorageUnitLeasePage = lazy(
	async () => import('../pages/pms/AddStorageUnitLeasePage')
)
const StorageUnitLeasesPage = lazy(
	async () => import('../pages/pms/StorageUnitLeasesPage')
)
const StorageUnitLeaseDetailsPage = lazy(
	async () =>
		import(
			'../pages/pms/StorageUnitLeaseDetailsPage/StorageUnitLeaseDetailsPage'
		)
)

// Storage unit invoices
const StorageUnitInvoicesPage = lazy(
	async () => import('../pages/pms/StorageUnitInvoicesPage')
)
const StorageUnitInvoiceDetailsPage = lazy(
	async () =>
		import(
			'../pages/pms/StorageUnitInvoiceDetailsPage/StorageUnitInvoiceDetailsPage'
		)
)

// Make Ready
const AddMakeReadyPage = lazy(
	async () => import('../pages/pms/AddMakeReadyPage')
)
const MakeReadyPage = lazy(async () => import('../pages/pms/MakeReadyPage'))
const MakeReadyDetailPage = lazy(
	async () => import('../pages/pms/MakeReadyDetailsPage/MakeReadyDetailsPage')
)

// Rent receivable
const AddRentReceivablePage = lazy(
	async () => import('../pages/pms/AddRentReceivablePage')
)
const RentReceivablesPage = lazy(
	async () => import('../pages/pms/RentReceivablesPage')
)
const RentReceivableDetailPage = lazy(
	async () => import('../pages/pms/RentReceivableDetailsPage')
)

// Rent payment
const AddRentPaymentPage = lazy(
	async () => import('../pages/pms/AddRentPaymentPage')
)
const RentPaymentsPage = lazy(
	async () => import('../pages/pms/RentPaymentsPage')
)
const RentPaymentDetailPage = lazy(
	async () => import('../pages/pms/RentPaymentDetailsPage')
)
const BulkMarkRentAsPaidPage = lazy(
	async () => import('../pages/pms/BulkMarkRentAsPaidPage')
)

// Invoice
const AddInvoicePage = lazy(async () => import('../pages/pms/AddInvoicePage'))
const InvoicesPage = lazy(async () => import('../pages/pms/InvoicesPage'))
const InvoiceDetailPage = lazy(
	async () => import('../pages/pms/InvoiceDetailPage/index')
)
const BulkGenerateWorkOrderInvoicePage = lazy(
	async () => import('../pages/pms/BulkGenerateWorkOrderInvoicePage')
)

// Rental unit
const AddRentalUnitPage = lazy(
	async () => import('../pages/pms/AddRentalUnitPage')
)
const RentalUnitsPage = lazy(async () => import('../pages/pms/RentalUnitsPage'))
const RentalUnitDetailPage = lazy(
	async () => import('../pages/pms/RentalUnitDetailPage')
)

// Rentalize AI
const AddRentalizeAIConversationPage = lazy(
	async () => import('../pages/pms/AddRentalizeAIConversationPage')
)

// Rent payment schedule
const AddRentPaymentSchedulePage = lazy(
	async () => import('../pages/pms/AddRentPaymentSchedulePage')
)
const RentPaymentSchedulesPage = lazy(
	async () => import('../pages/pms/RentPaymentSchedulesPage')
)
const RentPaymentScheduleDetailPage = lazy(
	async () => import('../pages/pms/RentPaymentScheduleDetailPage/index')
)

// Residents
const AddResidentPage = lazy(async () => import('../pages/pms/AddResidentPage'))
const ResidentsPage = lazy(async () => import('../pages/pms/ResidentsPage'))
const ResidentDetailPage = lazy(
	async () => import('../pages/pms/ResidentDetailPage')
)
const FormerResidentsPage = lazy(
	async () => import('../pages/pms/FormerResidentsPage')
)
const FutureResidentsPage = lazy(
	async () => import('../pages/pms/FutureResidentsPage')
)
const RentalApplicantsPage = lazy(
	async () => import('../pages/pms/RentalApplicantsPage')
)

// Signature Requests
const SignatureRequestsPage = lazy(
	async () => import('../pages/pms/SignatureRequestsPage')
)
const SignatureRequestDetailPage = lazy(
	async () => import('../pages/pms/SignatureRequestDetailsPage')
)

// Maintenance technician page
const InviteMaintenanceTechnicianPage = lazy(
	async () => import('../pages/pms/InviteMaintenanceTechnicianPage')
)
const AddMaintenanceTechnicianPage = lazy(
	async () => import('../pages/pms/AddMaintenanceTechnicianPage')
)
const MaintenanceTechniciansPage = lazy(
	async () => import('../pages/pms/MaintenanceTechniciansPage')
)
const MaintenanceTechnicianDetailsPage = lazy(
	async () => import('../pages/pms/MaintenanceTechnicianDetailsPage')
)

// Maintenance project
const AddMaintenanceProjectPage = lazy(
	async () => import('../pages/pms/AddMaintenanceProjectPage')
)
const MaintenanceProjectsPage = lazy(
	async () => import('../pages/pms/MaintenanceProjectsPage')
)
const MaintenanceProjectDetailsPage = lazy(
	async () => import('../pages/pms/MaintenanceProjectDetailsPage')
)

// Budget
const AddBudgetPage = lazy(async () => import('../pages/pms/AddBudgetPage'))
const BudgetsPage = lazy(async () => import('../pages/pms/BudgetsPage'))
const BudgetDetailsPage = lazy(
	async () => import('../pages/pms/BudgetDetailsPage/index')
)

// API
const AddAPIKeyPage = lazy(async () => import('../pages/pms/AddAPIKeyPage'))
const APIKeysPage = lazy(async () => import('../pages/pms/APIKeysPage'))
const APIKeyDetailsPage = lazy(
	async () => import('../pages/pms/APIKeyDetailsPage')
)

// Rental Applications
const RentalApplicationsPage = lazy(
	async () => import('../pages/pms/RentalApplicationsPage')
)
const RentalApplicationDetailPage = lazy(
	async () =>
		import(
			'../pages/pms/RentalApplicationSubmissionDetailsPage/RentalApplicationSubmissionDetailsPage'
		)
)

// Rental Application Forms
const AddRentalApplicationFormPage = lazy(
	async () => import('../pages/pms/AddRentalApplicationFormPage')
)
const RentalApplicationFormsPage = lazy(
	async () => import('../pages/pms/RentalApplicationFormsPage')
)
const RentalApplicationFormDetailPage = lazy(
	async () => import('../pages/pms/RentalApplicationFormDetailsPage/index')
)

// Property Inspections
const PropertyInspectionsPage = lazy(
	async () => import('../pages/pms/PropertyInspectionsPage')
)
const PropertyInspectionDetailPage = lazy(
	async () => import('../pages/pms/PropertyInspectionDetailsPage')
)

// Rental Application Forms
const AddPropertyInspectionFormPage = lazy(
	async () => import('../pages/pms/AddPropertyInspectionFormPage')
)
const PropertyInspectionFormsPage = lazy(
	async () => import('../pages/pms/PropertyInspectionFormsPage')
)
const PropertyInspectionFormDetailPage = lazy(
	async () => import('../pages/pms/PropertyInspectionFormDetailsPage')
)

// Inventory
const AddInventoryItemPage = lazy(
	async () => import('../pages/pms/AddInventoryItemPage')
)
const InventoryItemsPage = lazy(
	async () => import('../pages/pms/InventoryItemsPage')
)
const InventoryItemDetailPage = lazy(
	async () => import('../pages/pms/InventoryItemDetailsPage')
)

// Inventory
const AddInventoryTransactionPage = lazy(
	async () => import('../pages/pms/AddInventoryTransactionPage')
)
const InventoryTransactionsPage = lazy(
	async () => import('../pages/pms/InventoryTransactionsPage')
)
const InventoryTransactionDetailPage = lazy(
	async () => import('../pages/pms/InventoryTransactionDetailsPage')
)

/// /////////////
// Operations //
/// /////////////

// Work order
const AddWorkOrderPage = lazy(
	async () => import('../pages/pms/AddWorkOrderPage')
)
const WorkOrdersPage = lazy(async () => import('../pages/pms/WorkOrdersPage'))
const WorkOrderDetailPage = lazy(
	async () => import('../pages/pms/WorkOrderDetailPage/index')
)

// Property owner
const PropertyOwnersPage = lazy(
	async () => import('../pages/pms/PropertyOwnersPage')
)
const AddPropertyOwnerPage = lazy(
	async () => import('../pages/pms/AddPropertyOwnerPage')
)
const PropertyOwnerDetailPage = lazy(
	async () => import('../pages/pms/PropertyOwnerDetailPage')
)

// Rental unit listing
const RentalUnitListingsPage = lazy(
	async () => import('../pages/pms/RentalUnitListingsPage')
)
const RentalUnitListingDetailPage = lazy(
	async () => import('../pages/pms/RentalUnitListingDetailsPage')
)

// Vendor
const VendorsPage = lazy(async () => import('../pages/pms/VendorsPage'))
const AddVendorPage = lazy(async () => import('../pages/pms/AddVendorPage'))
const VendorDetailPage = lazy(
	async () => import('../pages/pms/VendorDetailPage/VendorDetailPage')
)

// Vehicle
const VehiclesPage = lazy(async () => import('../pages/pms/VehiclesPage'))
const AddVehiclePage = lazy(async () => import('../pages/pms/AddVehiclePage'))
const VehicleDetailPage = lazy(
	async () => import('../pages/pms/VehicleDetailPage')
)

// Resident vehicle
const ResidentVehiclesPage = lazy(
	async () => import('../pages/pms/ResidentVehiclesPage')
)
const AddResidentVehiclePage = lazy(
	async () => import('../pages/pms/AddResidentVehiclePage')
)
const ResidentVehicleDetailPage = lazy(
	async () => import('../pages/pms/ResidentVehicleDetailPage')
)
const StripeAccountsPage = lazy(
	async () => import('../pages/pms/StripeAccountsPage')
)
const AddStripeAccountPage = lazy(
	async () => import('../pages/pms/AddStripeAccountPage')
)
const StripeAccountDetailPage = lazy(
	async () => import('../pages/pms/StripeAccountDetailsPage')
)

// Vendor credit
const VendorCreditsPage = lazy(
	async () => import('../pages/pms/VendorCreditsPage')
)
const AddVendorCreditPage = lazy(
	async () => import('../pages/pms/AddVendorCreditPage')
)
const VendorCreditDetailPage = lazy(
	async () =>
		import('../pages/pms/VendorCreditDetailPage/VendorCreditDetailPage')
)

// Parking space
const ParkingSpacesPage = lazy(
	async () => import('../pages/pms/ParkingSpacesPage')
)
const AddParkingSpacePage = lazy(
	async () => import('../pages/pms/AddParkingSpacePage')
)
const ParkingSpaceDetailPage = lazy(
	async () => import('../pages/pms/ParkingSpaceDetailPage')
)

// Inspection Reports
const InspectionReportsPage = lazy(
	async () => import('../pages/pms/InspectionReportsPage')
)
const AddInspectionReportPage = lazy(
	async () => import('../pages/pms/AddInspectionReportPage')
)
const InspectionReportDetailsPage = lazy(
	async () => import('../pages/pms/InspectionReportDetailsPage')
)

// Parking space
const BillPaymentsPage = lazy(
	async () => import('../pages/pms/BillPaymentsPage')
)
// const AddBillPaymentPage = lazy(
// 	async () => import('../pages/pms/AddBillPaymentPage')
// )
const BillPaymentDetailsPage = lazy(
	async () => import('../pages/pms/BillPaymentDetailsPage')
)

// Bulk bill payment
const BulkBillPaymentsPage = lazy(
	async () => import('../pages/pms/BulkBillPaymentsPage')
)
const BulkBillPaymentDetailsPage = lazy(
	async () => import('../pages/pms/BulkBillPaymentDetailsPage')
)

// Remittance
const RemittanceLettersPage = lazy(
	async () => import('../pages/pms/RemittanceLettersPage')
)
const RemittanceLetterDetailsPage = lazy(
	async () => import('../pages/pms/RemittanceLetterDetailsPage')
)

// Email message
const EmailMessagesPage = lazy(
	async () => import('../pages/pms/EmailMessagesPage')
)
const EmailMessageDetailsPage = lazy(
	async () => import('../pages/pms/EmailMessageDetailsPage')
)

// Email message batch
const AddEmailMessageBatchPage = lazy(
	async () => import('../pages/pms/AddEmailMessageBatchPage')
)
const EmailMessageBatchesPage = lazy(
	async () => import('../pages/pms/EmailMessageBatchesPage')
)
const EmailMessageBatchDetailsPage = lazy(
	async () => import('../pages/pms/EmailMessageBatchDetailsPage')
)
const DraftEmailMessageBatchDetailsPage = lazy(
	async () => import('../components/forms/DraftEmailMessageBatchDetailsPage')
)

// Time Tracking
const AddTimeTrackingEntryPage = lazy(
	async () => import('../pages/pms/AddTimeTrackingEntryPage')
)
const TimeTrackingEntriesPage = lazy(
	async () => import('../pages/pms/TimeTrackingEntriesPage')
)
const TimeTrackingEntryDetailPage = lazy(
	async () => import('../pages/pms/TimeTrackingEntryDetailPage')
)

// Purchase Orders
const AddPurchaseOrderPage = lazy(
	async () => import('../pages/pms/AddPurchaseOrderPage')
)
const PurchaseOrdersPage = lazy(
	async () => import('../pages/pms/PurchaseOrdersPage')
)
const PurchaseOrderDetailPage = lazy(
	async () => import('../pages/pms/PurchaseOrderDetailPage')
)

// Bank Deposit
const AddBankDepositPage = lazy(
	async () => import('../pages/pms/AddBankDepositPage')
)
const BankDepositsPage = lazy(
	async () => import('../pages/pms/BankDepositsPage')
)
const BankDepositDetailPage = lazy(
	async () => import('../pages/pms/BankDepositDetailPage')
)

// Bank Accounts
const AddBankAccountPage = lazy(
	async () => import('../pages/pms/AddBankAccountPage')
)
const BankAccountsPage = lazy(
	async () => import('../pages/pms/BankAccountsPage')
)
const BankAccountDetailsPage = lazy(
	async () =>
		import('../pages/pms/BankAccountDetailsPage/BankAccountDetailsPage')
)

// Bank Accounts
const AddCreditCardPage = lazy(
	async () => import('../pages/pms/AddCreditCardPage')
)
const CreditCardsPage = lazy(async () => import('../pages/pms/CreditCardsPage'))
const CreditCardDetailsPage = lazy(
	async () => import('../pages/pms/CreditCardDetailsPage/CreditCardDetailsPage')
)

// Bank Reconciliations
const AddBankReconciliationPage = lazy(
	async () => import('../pages/pms/AddBankReconciliationPage')
)
const BankReconciliationsPage = lazy(
	async () => import('../pages/pms/BankReconciliationsPage')
)
const BankReconciliationDetailsPage = lazy(
	async () =>
		import(
			'../pages/pms/BankReconciliationDetailsPage/BankReconciliationDetailsPage'
		)
)

// Equipment Rental Tracking
const AddEquipmentPage = lazy(
	async () => import('../pages/pms/AddEquipmentPage')
)
const EquipmentPage = lazy(async () => import('../pages/pms/EquipmentPage'))
const EquipmentDetailsPage = lazy(
	async () => import('../pages/pms/EquipmentDetailsPage')
)

// Equipment Rental Rate
const AddEquipmentRentalRatePage = lazy(
	async () => import('../pages/pms/AddEquipmentRentalRatePage')
)
const EquipmentRentalRatesPage = lazy(
	async () => import('../pages/pms/EquipmentRentalRatesPage')
)
const EquipmentRentalRateDetailsPage = lazy(
	async () => import('../pages/pms/EquipmentRentalRateDetailsPage')
)

// Equipment Rental
const AddEquipmentRentalPage = lazy(
	async () => import('../pages/pms/AddEquipmentRentalPage')
)
const EquipmentRentalsPage = lazy(
	async () => import('../pages/pms/EquipmentRentalsPage')
)
const EquipmentRentalDetailsPage = lazy(
	async () => import('../pages/pms/EquipmentRentalDetailsPage')
)

// Utility Meter
const AddUtilityMeterPage = lazy(
	async () => import('../pages/pms/AddUtilityMeterPage')
)
const UtilityMetersPage = lazy(
	async () => import('../pages/pms/UtilityMetersPage')
)
const UtilityMeterDetailsPage = lazy(
	async () => import('../pages/pms/UtilityMeterDetailsPage')
)

// Utility Meter Reading
const AddUtilityMeterReadingPage = lazy(
	async () => import('../pages/pms/AddUtilityMeterReadingPage')
)
const UtilityMeterReadingsPage = lazy(
	async () => import('../pages/pms/UtilityMeterReadingsPage')
)
const UtilityMeterReadingDetailsPage = lazy(
	async () => import('../pages/pms/UtilityMeterReadingDetailsPage')
)
// Management fee receivables
const AddManagementFeeReceivablePage = lazy(
	async () => import('../pages/pms/AddManagementFeeReceivablePage')
)
const ManagementFeeReceivablesPage = lazy(
	async () => import('../pages/pms/ManagementFeeReceivablesPage')
)
const ManagementFeeReceivableDetailsPage = lazy(
	async () => import('../pages/pms/ManagementFeeReceivableDetailsPage')
)

// Management fee payments
const AddManagementFeePaymentPage = lazy(
	async () => import('../pages/pms/AddManagementFeePaymentPage')
)
const ManagementFeePaymentsPage = lazy(
	async () => import('../pages/pms/ManagementFeePaymentsPage')
)
const ManagementFeePaymentDetailsPage = lazy(
	async () => import('../pages/pms/ManagementFeePaymentDetailsPage')
)

// SMS
const AddSMSPage = lazy(async () => import('../pages/pms/AddSMSPage'))
const SMSsPage = lazy(async () => import('../pages/pms/SMSPage'))
const SMSDetailsPage = lazy(async () => import('../pages/pms/SMSDetailsPage'))

// Lease
const LeasesPage = lazy(async () => import('../pages/pms/LeasesPage'))
const LeaseDetailsPage = lazy(
	async () => import('../pages/pms/LeaseDetailsPage')
)
const LeasesExpiringIn60DaysPage = lazy(
	async () => import('../pages/pms/LeasesExpiringIn60DaysPage')
)
const LeasesExpiringIn120DaysPage = lazy(
	async () => import('../pages/pms/LeasesExpiringIn120DaysPage')
)

// Rent increase
const RentIncreasesPage = lazy(
	async () => import('../pages/pms/RentIncreasesPage')
)
const RentIncreaseDetailsPage = lazy(
	async () =>
		import('../pages/pms/RentIncreaseDetailsPage/RentIncreaseDetailsPage')
)

const LeasingPage = lazy(async () => import('../pages/pms/LeasingPage'))

const AddLeasePage = lazy(async () => import('../pages/pms/AddLeasePage'))

// Bulk rent increase notice
const AddBulkRentIncreaseNoticePage = lazy(
	async () => import('../pages/pms/AddBulkRentIncreaseNoticePage')
)
const BulkRentIncreaseNoticesPage = lazy(
	async () => import('../pages/pms/BulkRentIncreaseNoticesPage')
)
const BulkRentIncreaseNoticeDetailsPage = lazy(
	async () => import('../pages/pms/BulkRentIncreaseNoticeDetailsPage')
)

// Accounting
const RecordExpensePage = lazy(
	async () => import('../pages/pms/RecordExpensePage')
)

// User Management
const ChangePasswordPage = lazy(
	async () => import('../pages/pms/ChangePasswordPage')
)

// Reporting
const GenerateReportPage = lazy(
	async () => import('../pages/pms/GenerateReportPage')
)
const ScheduledReportsPage = lazy(
	async () => import('../pages/pms/ScheduledReportsPage')
)
const AddScheduledReportPage = lazy(
	async () => import('../pages/pms/AddScheduledReportPage')
)
const ScheduledReportDetailPage = lazy(
	async () => import('../pages/pms/ScheduledReportDetailsPage')
)
const GenerateIncomeStatementPage = lazy(
	async () => import('../pages/pms/GenerateIncomeStatementPage')
)
const GenerateDSCRReportPage = lazy(
	async () => import('../pages/pms/GenerateDSCRReportPage')
)
const GenerateDSCRSensitivityReportPage = lazy(
	async () => import('../pages/pms/GenerateDSCRSensitivityReportPage')
)
const GenerateEFTPaymentFilePage = lazy(
	async () => import('../pages/pms/GenerateEFTPaymentFilePage')
)
const GenerateTrialBalancePage = lazy(
	async () => import('../pages/pms/GenerateTrialBalancePage')
)
const GenerateTransactionRegisterReportPage = lazy(
	async () => import('../pages/pms/GenerateTransactionRegisterReportPage')
)
const GenerateManagementFeeSummaryPage = lazy(
	async () => import('../pages/pms/GenerateManagementFeeSummaryPage')
)
const GenerateRentRollPage = lazy(
	async () => import('../pages/pms/GenerateRentRollPage')
)
const GenerateRentOutstandingReportPage = lazy(
	async () => import('../pages/pms/GenerateRentOutstandingReportPage')
)
const GenerateJournalTransactionsReportPage = lazy(
	async () => import('../pages/pms/GenerateJournalTransactionsReportPage')
)
const GeneratePOListingReportPage = lazy(
	async () => import('../pages/pms/GeneratePOListingReportPage')
)
const GenerateGeneralLedgerReportPage = lazy(
	async () => import('../pages/pms/GenerateGeneralLedgerReportPage')
)
const GenerateTimeToLeaseReportPage = lazy(
	async () => import('../pages/pms/GenerateTimeToLeaseReportPage')
)
const GeneratePhysicalInventoryReportPage = lazy(
	async () => import('../pages/pms/GeneratePhysicalInventoryReportPage')
)
const GenerateRentRollComparisonReportPage = lazy(
	async () => import('../pages/pms/GenerateRentRollComparisonReportPage')
)
const GenerateRentLedgerReportPage = lazy(
	async () => import('../pages/pms/GenerateRentLedgerReportPage')
)
const GenerateParkingSpaceAssignmentReportPage = lazy(
	async () => import('../pages/pms/GenerateParkingSpaceAssignmentReportPage')
)
const GenerateRentDelinquencyReportPage = lazy(
	async () => import('../pages/pms/GenerateRentDelinquencyReportPage')
)
const GenerateAgedPayablesReportPage = lazy(
	async () => import('../pages/pms/GenerateAgedPayablesReportPage')
)
const GenerateAgedReceivablesReportPage = lazy(
	async () => import('../pages/pms/GenerateAgedReceivablesReportPage')
)
const GenerateLossToLeaseReportPage = lazy(
	async () => import('../pages/pms/GenerateLossToLeaseReportPage')
)
const GenerateUpcomingRentIncreaseReportPage = lazy(
	async () => import('../pages/pms/GenerateUpcomingRentIncreasesReportPage')
)
const GenerateRentReceiptPage = lazy(
	async () => import('../pages/pms/GenerateRentReceiptPage')
)
const GenerateBalanceSheetPage = lazy(
	async () => import('../pages/pms/GenerateBalanceSheetPage')
)
const GenerateOpenTasksReportPage = lazy(
	async () => import('../pages/pms/GenerateOpenTasksReportPage')
)
const GenerateClosedTasksReportPage = lazy(
	async () => import('../pages/pms/GenerateClosedTasksReportPage')
)
const GenerateTaskPerformanceReportPage = lazy(
	async () => import('../pages/pms/GenerateTaskPerformanceReportPage')
)
const GenerateServiceTechHoursReportPage = lazy(
	async () => import('../pages/pms/GenerateServiceTechHoursReportPage')
)

const GenerateOpenWorkOrdersReportPage = lazy(
	async () => import('../pages/pms/GenerateOpenWorkOrdersReportPage')
)
const GenerateClosedWorkOrdersReportPage = lazy(
	async () => import('../pages/pms/GenerateClosedWorkOrdersReportPage')
)
const GenerateWorkOrderPerformanceReportPage = lazy(
	async () => import('../pages/pms/GenerateWorkOrderPerformanceReportPage')
)
const GenerateMoveInMoveOutReportPage = lazy(
	async () => import('../pages/pms/GenerateMoveInMoveOutReportPage')
)
const GenerateWorkOrderSummaryReportPage = lazy(
	async () => import('../pages/pms/GenerateWorkOrderSummaryReportPage')
)
const GenerateChartOfAccountsReportPage = lazy(
	async () => import('../pages/pms/GenerateChartOfAccountsReportPage')
)

const addNavbarAndCommandPaletteToElement = (
	component: JSX.Element
): React.ReactElement => (
	<div className='h-full bg-white lg:min-h-[30rem]'>
		<CommandPalette />
		<ApplicationShell component={component} />
	</div>
)

export default function PropertyManagerApp(): ReactElement {
	return (
		<Routes>
			<Route
				path={propertyManagementRevenueURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyManagementRevenuePage />
				)}
			/>
			<Route
				path={viewManagementCompanyDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<ManagementCompanyDetailsPage />
				)}
			/>
			<Route
				path={askRentalizeAIQuestionURL}
				element={addNavbarAndCommandPaletteToElement(
					<AskRentalizeAIQuestionPage />
				)}
			/>
			<Route
				path={currentVacanciesURL}
				element={addNavbarAndCommandPaletteToElement(<CurrentVacanciesPage />)}
			/>
			<Route
				path={changePasswordURL}
				element={addNavbarAndCommandPaletteToElement(<ChangePasswordPage />)}
			/>
			<Route
				path='/app/'
				element={addNavbarAndCommandPaletteToElement(<DashboardPage />)}
			/>
			<Route
				path={userSettingsURL}
				element={addNavbarAndCommandPaletteToElement(<UserSettingsPage />)}
			/>
			<Route
				path={contactSupportURL}
				element={addNavbarAndCommandPaletteToElement(<ContactSupportPage />)}
			/>

			{/* Operations */}
			<Route
				path={addWorkOrderURL}
				element={addNavbarAndCommandPaletteToElement(<AddWorkOrderPage />)}
			/>
			<Route
				path={viewWorkOrdersURL}
				element={addNavbarAndCommandPaletteToElement(<WorkOrdersPage />)}
			/>
			<Route
				path={viewWorkOrderDetailURL}
				element={addNavbarAndCommandPaletteToElement(<WorkOrderDetailPage />)}
			/>
			<Route
				path={addRecurringTaskURL}
				element={addNavbarAndCommandPaletteToElement(<AddRecurringTaskPage />)}
			/>
			<Route
				path={viewRecurringTasksURL}
				element={addNavbarAndCommandPaletteToElement(<RecurringTasksPage />)}
			/>
			<Route
				path={viewRecurringTaskDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RecurringTaskDetailsPage />
				)}
			/>
			<Route
				path={addTaskURL}
				element={addNavbarAndCommandPaletteToElement(<AddTaskPage />)}
			/>
			<Route
				path={viewTasksURL}
				element={addNavbarAndCommandPaletteToElement(<TasksPage />)}
			/>
			<Route
				path={viewTaskDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<TaskDetailsPage />)}
			/>

			{/* Property Managers */}
			<Route
				path={invitePropertyManagerURL}
				element={addNavbarAndCommandPaletteToElement(
					<InvitePropertyManagerPage />
				)}
			/>
			<Route
				path={viewPropertyManagersURL}
				element={addNavbarAndCommandPaletteToElement(<PropertyManagersPage />)}
			/>
			<Route
				path={viewPropertyManagerDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyManagerDetailsPage />
				)}
			/>
			<Route
				path={acceptPropertyManagerInvitationURL}
				element={addNavbarAndCommandPaletteToElement(
					<AcceptPropertyManagerInvitationPage />
				)}
			/>

			{/* Time Tracking */}
			<Route
				path={addTimeTrackingEntryURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddTimeTrackingEntryPage />
				)}
			/>
			<Route
				path={viewTimeTrackingEntriesURL}
				element={addNavbarAndCommandPaletteToElement(
					<TimeTrackingEntriesPage />
				)}
			/>
			<Route
				path={viewTimeTrackingEntryDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<TimeTrackingEntryDetailPage />
				)}
			/>

			{/* Purchase Orders */}
			<Route
				path={addPurchaseOrderURL}
				element={addNavbarAndCommandPaletteToElement(<AddPurchaseOrderPage />)}
			/>
			<Route
				path={viewPurchaseOrdersURL}
				element={addNavbarAndCommandPaletteToElement(<PurchaseOrdersPage />)}
			/>
			<Route
				path={viewPurchaseOrderDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<PurchaseOrderDetailPage />
				)}
			/>

			{/* Bank Deposit */}
			<Route
				path={addBankDepositURL}
				element={addNavbarAndCommandPaletteToElement(<AddBankDepositPage />)}
			/>
			<Route
				path={viewBankDepositsURL}
				element={addNavbarAndCommandPaletteToElement(<BankDepositsPage />)}
			/>
			<Route
				path={viewBankDepositDetailURL}
				element={addNavbarAndCommandPaletteToElement(<BankDepositDetailPage />)}
			/>

			{/* Bank Account */}
			<Route
				path={addBankAccountURL}
				element={addNavbarAndCommandPaletteToElement(<AddBankAccountPage />)}
			/>
			<Route
				path={viewBankAccountsURL}
				element={addNavbarAndCommandPaletteToElement(<BankAccountsPage />)}
			/>
			<Route
				path={viewBankAccountDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<BankAccountDetailsPage />
				)}
			/>
			{/* Credit Cards */}
			<Route
				path={addCreditCardURL}
				element={addNavbarAndCommandPaletteToElement(<AddCreditCardPage />)}
			/>
			<Route
				path={viewCreditCardsURL}
				element={addNavbarAndCommandPaletteToElement(<CreditCardsPage />)}
			/>
			<Route
				path={viewCreditCardDetailURL}
				element={addNavbarAndCommandPaletteToElement(<CreditCardDetailsPage />)}
			/>

			{/* Bank Reconciliations */}
			<Route
				path={addBankReconciliationURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddBankReconciliationPage />
				)}
			/>
			<Route
				path={viewBankReconciliationsURL}
				element={addNavbarAndCommandPaletteToElement(
					<BankReconciliationsPage />
				)}
			/>
			<Route
				path={viewBankReconciliationDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<BankReconciliationDetailsPage />
				)}
			/>

			{/* Equipment Rental Tracking */}

			{/* Equipment */}
			<Route
				path={addEquipmentURL}
				element={addNavbarAndCommandPaletteToElement(<AddEquipmentPage />)}
			/>
			<Route
				path={viewEquipmentURL}
				element={addNavbarAndCommandPaletteToElement(<EquipmentPage />)}
			/>
			<Route
				path={viewEquipmentDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<EquipmentDetailsPage />)}
			/>

			{/* Equipment Rental */}
			<Route
				path={addEquipmentRentalURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddEquipmentRentalPage />
				)}
			/>
			<Route
				path={viewEquipmentRentalsURL}
				element={addNavbarAndCommandPaletteToElement(<EquipmentRentalsPage />)}
			/>
			<Route
				path={viewEquipmentRentalDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<EquipmentRentalDetailsPage />
				)}
			/>

			{/* Equipment Rental Rates */}
			<Route
				path={addEquipmentRentalRateURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddEquipmentRentalRatePage />
				)}
			/>
			<Route
				path={viewEquipmentRentalRatesURL}
				element={addNavbarAndCommandPaletteToElement(
					<EquipmentRentalRatesPage />
				)}
			/>
			<Route
				path={viewEquipmentRentalRateDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<EquipmentRentalRateDetailsPage />
				)}
			/>

			{/* Management Fee Receivables */}
			<Route
				path={addManagementFeeReceivableURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddManagementFeeReceivablePage />
				)}
			/>
			<Route
				path={viewManagementFeeReceivablesURL}
				element={addNavbarAndCommandPaletteToElement(
					<ManagementFeeReceivablesPage />
				)}
			/>
			<Route
				path={viewManagementFeeReceivableDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<ManagementFeeReceivableDetailsPage />
				)}
			/>

			{/* Management Fee Payments */}
			<Route
				path={addManagementFeePaymentURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddManagementFeePaymentPage />
				)}
			/>
			<Route
				path={viewManagementFeePaymentsURL}
				element={addNavbarAndCommandPaletteToElement(
					<ManagementFeePaymentsPage />
				)}
			/>
			<Route
				path={viewManagementFeePaymentDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<ManagementFeePaymentDetailsPage />
				)}
			/>

			{/* Maintenance Technicians */}
			<Route
				path={inviteMaintenanceTechnicianURL}
				element={addNavbarAndCommandPaletteToElement(
					<InviteMaintenanceTechnicianPage />
				)}
			/>
			<Route
				path={addMaintenanceTechnicianURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddMaintenanceTechnicianPage />
				)}
			/>
			<Route
				path={viewMaintenanceTechniciansURL}
				element={addNavbarAndCommandPaletteToElement(
					<MaintenanceTechniciansPage />
				)}
			/>
			<Route
				path={viewMaintenanceTechnicianDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<MaintenanceTechnicianDetailsPage />
				)}
			/>

			{/* Maintenance Projects */}
			<Route
				path={addMaintenanceProjectURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddMaintenanceProjectPage />
				)}
			/>
			<Route
				path={viewMaintenanceProjectsURL}
				element={addNavbarAndCommandPaletteToElement(
					<MaintenanceProjectsPage />
				)}
			/>
			<Route
				path={viewMaintenanceProjectDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<MaintenanceProjectDetailsPage />
				)}
			/>

			{/* Budgets */}
			<Route
				path={addBudgetURL}
				element={addNavbarAndCommandPaletteToElement(<AddBudgetPage />)}
			/>
			<Route
				path={viewBudgetsURL}
				element={addNavbarAndCommandPaletteToElement(<BudgetsPage />)}
			/>
			<Route
				path={viewBudgetDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<BudgetDetailsPage />)}
			/>

			{/* API Keys */}
			<Route
				path={addAPIKeyURL}
				element={addNavbarAndCommandPaletteToElement(<AddAPIKeyPage />)}
			/>
			<Route
				path={viewAPIKeysURL}
				element={addNavbarAndCommandPaletteToElement(<APIKeysPage />)}
			/>
			<Route
				path={viewAPIKeysURL}
				element={addNavbarAndCommandPaletteToElement(<APIKeyDetailsPage />)}
			/>

			{/* Appliances */}
			<Route
				path={addApplianceURL}
				element={addNavbarAndCommandPaletteToElement(<AddAppliancePage />)}
			/>
			<Route
				path={viewAppliancesURL}
				element={addNavbarAndCommandPaletteToElement(<AppliancesPage />)}
			/>
			<Route
				path={viewApplianceDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<ApplianceDetailsPage />)}
			/>
			{/* Security Deposits */}
			<Route
				path={addSecurityDepositURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddSecurityDepositPage />
				)}
			/>
			<Route
				path={viewSecurityDepositsURL}
				element={addNavbarAndCommandPaletteToElement(<SecurityDepositsPage />)}
			/>
			<Route
				path={viewSecurityDepositDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<SecurityDepositDetailsPage />
				)}
			/>
			{/* Resident Pets */}
			<Route
				path={addResidentPetURL}
				element={addNavbarAndCommandPaletteToElement(<AddResidentPetPage />)}
			/>
			<Route
				path={viewResidentPetsURL}
				element={addNavbarAndCommandPaletteToElement(<ResidentPetsPage />)}
			/>
			<Route
				path={viewResidentPetDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<ResidentPetDetailsPage />
				)}
			/>
			{/* Property Owners */}
			<Route
				path={addPropertyOwnerURL}
				element={addNavbarAndCommandPaletteToElement(<AddPropertyOwnerPage />)}
			/>
			<Route
				path={viewPropertyOwnersURL}
				element={addNavbarAndCommandPaletteToElement(<PropertyOwnersPage />)}
			/>
			<Route
				path={viewPropertyOwnerDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyOwnerDetailPage />
				)}
			/>
			<Route
				path={viewRentalUnitListingsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalUnitListingsPage />
				)}
			/>
			<Route
				path={viewRentalUnitListingDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalUnitListingDetailPage />
				)}
			/>

			{/* Vendors */}
			<Route
				path={addVendorURL}
				element={addNavbarAndCommandPaletteToElement(<AddVendorPage />)}
			/>
			<Route
				path={viewVendorsURL}
				element={addNavbarAndCommandPaletteToElement(<VendorsPage />)}
			/>
			<Route
				path={viewVendorDetailURL}
				element={addNavbarAndCommandPaletteToElement(<VendorDetailPage />)}
			/>

			{/* Vehicles */}
			<Route
				path={addVehicleURL}
				element={addNavbarAndCommandPaletteToElement(<AddVehiclePage />)}
			/>
			<Route
				path={viewVehiclesURL}
				element={addNavbarAndCommandPaletteToElement(<VehiclesPage />)}
			/>
			<Route
				path={viewVehicleDetailURL}
				element={addNavbarAndCommandPaletteToElement(<VehicleDetailPage />)}
			/>

			{/* Resident Vehicles */}
			<Route
				path={addResidentVehicleURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddResidentVehiclePage />
				)}
			/>
			<Route
				path={viewResidentVehiclesURL}
				element={addNavbarAndCommandPaletteToElement(<ResidentVehiclesPage />)}
			/>
			<Route
				path={viewResidentVehicleDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<ResidentVehicleDetailPage />
				)}
			/>

			{/* Stripe Accounts */}
			<Route
				path={addStripeAccountURL}
				element={addNavbarAndCommandPaletteToElement(<AddStripeAccountPage />)}
			/>
			<Route
				path={viewStripeAccountsURL}
				element={addNavbarAndCommandPaletteToElement(<StripeAccountsPage />)}
			/>
			<Route
				path={viewStripeAccountDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<StripeAccountDetailPage />
				)}
			/>

			{/* Vendor Credits */}
			<Route
				path={viewVendorCreditsURL}
				element={addNavbarAndCommandPaletteToElement(<VendorCreditsPage />)}
			/>
			<Route
				path={addVendorCreditURL}
				element={addNavbarAndCommandPaletteToElement(<AddVendorCreditPage />)}
			/>
			<Route
				path={viewVendorCreditDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<VendorCreditDetailPage />
				)}
			/>

			{/* Parking Spaces */}
			<Route
				path={viewParkingSpacesURL}
				element={addNavbarAndCommandPaletteToElement(<ParkingSpacesPage />)}
			/>
			<Route
				path={addParkingSpaceURL}
				element={addNavbarAndCommandPaletteToElement(<AddParkingSpacePage />)}
			/>
			<Route
				path={viewParkingSpaceDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<ParkingSpaceDetailPage />
				)}
			/>

			{/* Bill Payments */}
			<Route
				path={viewBillPaymentsURL}
				element={addNavbarAndCommandPaletteToElement(<BillPaymentsPage />)}
			/>
			<Route
				path={viewBillPaymentDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<BillPaymentDetailsPage />
				)}
			/>

			{/* Bulk Bill Payments */}
			<Route
				path={viewBulkBillPaymentsURL}
				element={addNavbarAndCommandPaletteToElement(<BulkBillPaymentsPage />)}
			/>
			<Route
				path={viewBulkBillPaymentDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<BulkBillPaymentDetailsPage />
				)}
			/>

			{/* Remittance Letters */}
			<Route
				path={viewRemittanceLettersURL}
				element={addNavbarAndCommandPaletteToElement(<RemittanceLettersPage />)}
			/>
			<Route
				path={viewRemittanceLetterDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RemittanceLetterDetailsPage />
				)}
			/>

			{/* Emails */}
			<Route
				path={viewEmailMessagesURL}
				element={addNavbarAndCommandPaletteToElement(<EmailMessagesPage />)}
			/>
			<Route
				path={viewEmailMessageDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<EmailMessageDetailsPage />
				)}
			/>

			{/* Email Message Batches */}
			<Route
				path={addEmailMessageBatchURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddEmailMessageBatchPage />
				)}
			/>
			<Route
				path={viewEmailMessageBatchesURL}
				element={addNavbarAndCommandPaletteToElement(
					<EmailMessageBatchesPage />
				)}
			/>
			<Route
				path={viewEmailMessageBatchDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<EmailMessageBatchDetailsPage />
				)}
			/>
			<Route
				path={viewDraftEmailMessageBatchDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<DraftEmailMessageBatchDetailsPage />
				)}
			/>

			{/* SMS Messages */}
			<Route
				path={addSMSURL}
				element={addNavbarAndCommandPaletteToElement(<AddSMSPage />)}
			/>
			<Route
				path={viewSMSsURL}
				element={addNavbarAndCommandPaletteToElement(<SMSsPage />)}
			/>
			<Route
				path={viewSMSDetailURL}
				element={addNavbarAndCommandPaletteToElement(<SMSDetailsPage />)}
			/>

			{/* Buildings */}
			<Route
				path={addBuildingURL}
				element={addNavbarAndCommandPaletteToElement(<AddBuildingPage />)}
			/>
			<Route
				path={viewBuildingsURL}
				element={addNavbarAndCommandPaletteToElement(<BuildingsPage />)}
			/>
			<Route
				path={viewBuildingDetailURL}
				element={addNavbarAndCommandPaletteToElement(<BuildingDetailPage />)}
			/>

			{/* Storage Facilities */}
			<Route
				path={viewStorageFacilitiesURL}
				element={addNavbarAndCommandPaletteToElement(<StorageFacilitiesPage />)}
			/>

			{/* Storage Customers */}
			<Route
				path={viewStorageCustomersURL}
				element={addNavbarAndCommandPaletteToElement(<StorageCustomersPage />)}
			/>

			{/* Waitlist Subscribers */}
			<Route
				path={addWaitlistSubscriberURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddWaitlistSubscriberPage />
				)}
			/>
			<Route
				path={viewWaitlistSubscribersURL}
				element={addNavbarAndCommandPaletteToElement(
					<WaitlistSubscribersPage />
				)}
			/>
			<Route
				path={viewWaitlistSubscriberDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<WaitlistSubscriberDetailPage />
				)}
			/>

			{/* Property Management Fee Schedules */}
			<Route
				path={addFeeScheduleURL}
				element={addNavbarAndCommandPaletteToElement(<AddFeeSchedulePage />)}
			/>
			<Route
				path={viewFeeSchedulesURL}
				element={addNavbarAndCommandPaletteToElement(<FeeSchedulesPage />)}
			/>
			<Route
				path={viewFeeScheduleDetailURL}
				element={addNavbarAndCommandPaletteToElement(<FeeScheduleDetailPage />)}
			/>

			{/* Property Groups */}
			<Route
				path={addPropertyGroupURL}
				element={addNavbarAndCommandPaletteToElement(<AddPropertyGroupPage />)}
			/>
			<Route
				path={viewPropertyGroupsURL}
				element={addNavbarAndCommandPaletteToElement(<PropertyGroupsPage />)}
			/>
			<Route
				path={viewPropertyGroupDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyGroupDetailPage />
				)}
			/>

			{/* Documents */}
			<Route
				path={addDocumentURL}
				element={addNavbarAndCommandPaletteToElement(<AddDocumentPage />)}
			/>
			<Route
				path={viewDocumentsURL}
				element={addNavbarAndCommandPaletteToElement(<DocumentsPage />)}
			/>
			<Route
				path={viewDocumentDetailURL}
				element={addNavbarAndCommandPaletteToElement(<DocumentDetailPage />)}
			/>

			{/* Markups */}
			<Route
				path={addMarkupTypeURL}
				element={addNavbarAndCommandPaletteToElement(<AddMarkupTypePage />)}
			/>
			<Route
				path={viewMarkupTypesURL}
				element={addNavbarAndCommandPaletteToElement(<MarkupTypesPage />)}
			/>
			<Route
				path={viewMarkupTypeDetailURL}
				element={addNavbarAndCommandPaletteToElement(<MarkupTypeDetailsPage />)}
			/>
			<Route
				path={viewNoteDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<NoteDetailsPage />)}
			/>

			{/* Lease Attachments */}
			<Route
				path={addLeaseAttachmentURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddLeaseAttachmentPage />
				)}
			/>
			<Route
				path={viewLeaseAttachmentsURL}
				element={addNavbarAndCommandPaletteToElement(<LeaseAttachmentsPage />)}
			/>
			<Route
				path={viewLeaseAttachmentDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<LeaseAttachmentDetailPage />
				)}
			/>

			{/* Storage Unit Leases */}
			<Route
				path={addStorageUnitLeaseURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddStorageUnitLeasePage />
				)}
			/>
			<Route
				path={viewStorageUnitLeasesURL}
				element={addNavbarAndCommandPaletteToElement(<StorageUnitLeasesPage />)}
			/>
			<Route
				path={viewStorageUnitLeaseDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<StorageUnitLeaseDetailsPage />
				)}
			/>

			{/* Storage Unit Invoices */}
			<Route
				path={viewStorageUnitInvoicesURL}
				element={addNavbarAndCommandPaletteToElement(
					<StorageUnitInvoicesPage />
				)}
			/>
			<Route
				path={viewStorageUnitInvoiceDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<StorageUnitInvoiceDetailsPage />
				)}
			/>

			{/* Make Ready */}
			<Route
				path={addMakeReadyURL}
				element={addNavbarAndCommandPaletteToElement(<AddMakeReadyPage />)}
			/>
			<Route
				path={viewMakeReadyURL}
				element={addNavbarAndCommandPaletteToElement(<MakeReadyPage />)}
			/>
			<Route
				path={viewMakeReadyDetailURL}
				element={addNavbarAndCommandPaletteToElement(<MakeReadyDetailPage />)}
			/>

			{/* Utility Meters */}
			<Route
				path={addUtilityMeterURL}
				element={addNavbarAndCommandPaletteToElement(<AddUtilityMeterPage />)}
			/>
			<Route
				path={viewUtilityMetersURL}
				element={addNavbarAndCommandPaletteToElement(<UtilityMetersPage />)}
			/>
			<Route
				path={viewUtilityMeterDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<UtilityMeterDetailsPage />
				)}
			/>

			{/* Utility Meter Readings */}
			<Route
				path={addUtilityMeterReadingURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddUtilityMeterReadingPage />
				)}
			/>
			<Route
				path={viewUtilityMeterReadingsURL}
				element={addNavbarAndCommandPaletteToElement(
					<UtilityMeterReadingsPage />
				)}
			/>
			<Route
				path={viewUtilityMeterReadingDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<UtilityMeterReadingDetailsPage />
				)}
			/>

			{/* Rent Receivables */}
			<Route
				path={addRentReceivableURL}
				element={addNavbarAndCommandPaletteToElement(<AddRentReceivablePage />)}
			/>
			<Route
				path={viewRentReceivablesURL}
				element={addNavbarAndCommandPaletteToElement(<RentReceivablesPage />)}
			/>
			<Route
				path={viewRentReceivableDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentReceivableDetailPage />
				)}
			/>

			{/* Rent Payments */}
			<Route
				path={addRentPaymentURL}
				element={addNavbarAndCommandPaletteToElement(<AddRentPaymentPage />)}
			/>
			<Route
				path={viewRentPaymentsURL}
				element={addNavbarAndCommandPaletteToElement(<RentPaymentsPage />)}
			/>
			<Route
				path={viewRentPaymentDetailURL}
				element={addNavbarAndCommandPaletteToElement(<RentPaymentDetailPage />)}
			/>
			<Route
				path={bulkMarkRentAsPaidURL}
				element={addNavbarAndCommandPaletteToElement(
					<BulkMarkRentAsPaidPage />
				)}
			/>

			{/* Invoices */}
			<Route
				path={addInvoiceURL}
				element={addNavbarAndCommandPaletteToElement(<AddInvoicePage />)}
			/>
			<Route
				path={viewInvoicesURL}
				element={addNavbarAndCommandPaletteToElement(<InvoicesPage />)}
			/>
			<Route
				path={viewInvoiceDetailURL}
				element={addNavbarAndCommandPaletteToElement(<InvoiceDetailPage />)}
			/>
			<Route
				path={bulkGenerateWorkOrderInvoicesURL}
				element={addNavbarAndCommandPaletteToElement(
					<BulkGenerateWorkOrderInvoicePage />
				)}
			/>

			{/* Rental Units */}
			<Route
				path={addRentalUnitURL}
				element={addNavbarAndCommandPaletteToElement(<AddRentalUnitPage />)}
			/>
			<Route
				path={viewRentalUnitsURL}
				element={addNavbarAndCommandPaletteToElement(<RentalUnitsPage />)}
			/>
			<Route
				path={viewRentalUnitDetailURL}
				element={addNavbarAndCommandPaletteToElement(<RentalUnitDetailPage />)}
			/>
			<Route
				path={viewStorageUnitsURL}
				element={addNavbarAndCommandPaletteToElement(<StorageUnitsPage />)}
			/>
			{/* Rentalize AI */}
			<Route
				path={createAIConversationURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddRentalizeAIConversationPage />
				)}
			/>

			{/* Rent Payment Schedules */}
			<Route
				path={addRentPaymentScheduleURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddRentPaymentSchedulePage />
				)}
			/>
			<Route
				path={viewRentPaymentSchedulesURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentPaymentSchedulesPage />
				)}
			/>
			<Route
				path={viewRentPaymentScheduleDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentPaymentScheduleDetailPage />
				)}
			/>

			{/* Residents */}
			<Route
				path={addResidentURL}
				element={addNavbarAndCommandPaletteToElement(<AddResidentPage />)}
			/>
			<Route
				path={viewResidentsURL}
				element={addNavbarAndCommandPaletteToElement(<ResidentsPage />)}
			/>
			<Route
				path={viewResidentDetailURL}
				element={addNavbarAndCommandPaletteToElement(<ResidentDetailPage />)}
			/>

			{/* Future Residents */}
			<Route
				path={viewFutureResidentsURL}
				element={addNavbarAndCommandPaletteToElement(<FutureResidentsPage />)}
			/>
			<Route
				path={viewFormerResidentsURL}
				element={addNavbarAndCommandPaletteToElement(<FormerResidentsPage />)}
			/>
			<Route
				path={viewRentalApplicantsURL}
				element={addNavbarAndCommandPaletteToElement(<RentalApplicantsPage />)}
			/>
			{/* Signature Requests */}
			<Route
				path={viewSignatureRequestsURL}
				element={addNavbarAndCommandPaletteToElement(<SignatureRequestsPage />)}
			/>
			<Route
				path={viewSignatureRequestDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<SignatureRequestDetailPage />
				)}
			/>

			{/* Journal Entries */}
			<Route
				path={addJournalEntryURL}
				element={addNavbarAndCommandPaletteToElement(<AddJournalEntryPage />)}
			/>
			<Route
				path={viewJournalEntriesURL}
				element={addNavbarAndCommandPaletteToElement(<JournalEntriesPage />)}
			/>
			<Route
				path={viewJournalEntryDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<JournalEntryDetailPage />
				)}
			/>

			{/* Transactions */}
			<Route
				path={viewTransactionsURL}
				element={addNavbarAndCommandPaletteToElement(<TransactionsPage />)}
			/>
			<Route
				path={viewTransactionDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<TransactionDetailsPage />
				)}
			/>

			{/* Recurring Journal Entries */}
			<Route
				path={addRecurringJournalEntryURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddRecurringJournalEntryPage />
				)}
			/>
			<Route
				path={viewRecurringJournalEntriesURL}
				element={addNavbarAndCommandPaletteToElement(
					<RecurringJournalEntriesPage />
				)}
			/>

			{/* Mortgages */}
			<Route
				path={addMortgageURL}
				element={addNavbarAndCommandPaletteToElement(<AddMortgagePage />)}
			/>
			<Route
				path={viewMortgagesURL}
				element={addNavbarAndCommandPaletteToElement(<MortgagesPage />)}
			/>
			<Route
				path={viewMortgageDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<MortgageDetailPage />)}
			/>

			{/* Lenders */}
			<Route
				path={addLenderURL}
				element={addNavbarAndCommandPaletteToElement(<AddLenderPage />)}
			/>
			<Route
				path={viewLendersURL}
				element={addNavbarAndCommandPaletteToElement(<LendersPage />)}
			/>
			<Route
				path={viewLenderDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<LenderDetailPage />)}
			/>
			{/* Billable Expenses */}
			<Route
				path={addBillableExpenseURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddBillableExpensePage />
				)}
			/>
			<Route
				path={viewBillableExpensesURL}
				element={addNavbarAndCommandPaletteToElement(<BillableExpensesPage />)}
			/>
			{/* Bills */}
			<Route
				path={addBillURL}
				element={addNavbarAndCommandPaletteToElement(<AddBillPage />)}
			/>
			<Route
				path={viewBillsURL}
				element={addNavbarAndCommandPaletteToElement(<BillsPage />)}
			/>
			<Route
				path={viewBillDetailURL}
				element={addNavbarAndCommandPaletteToElement(<BillDetailPage />)}
			/>
			<Route
				path={bulkPayBillURL}
				element={addNavbarAndCommandPaletteToElement(<BulkPayBillPage />)}
			/>

			{/* Email Domains */}
			<Route
				path={addEmailDomainURL}
				element={addNavbarAndCommandPaletteToElement(<AddEmailDomainPage />)}
			/>
			<Route
				path={viewEmailDomainsURL}
				element={addNavbarAndCommandPaletteToElement(<EmailDomainsPage />)}
			/>
			<Route
				path={viewEmailDomainDetailURL}
				element={addNavbarAndCommandPaletteToElement(<EmailDomainDetailPage />)}
			/>

			{/* Bills */}
			<Route
				path={addEmailSenderURL}
				element={addNavbarAndCommandPaletteToElement(<AddEmailSenderPage />)}
			/>
			<Route
				path={viewEmailSendersURL}
				element={addNavbarAndCommandPaletteToElement(<EmailSendersPage />)}
			/>
			<Route
				path={viewEmailSenderDetailURL}
				element={addNavbarAndCommandPaletteToElement(<EmailSenderDetailPage />)}
			/>

			{/* Accounts */}
			<Route
				path={addAccountURL}
				element={addNavbarAndCommandPaletteToElement(<AddAccountPage />)}
			/>
			<Route
				path={viewAccountsURL}
				element={addNavbarAndCommandPaletteToElement(<AccountsPage />)}
			/>
			<Route
				path={viewAccountDetailURL}
				element={addNavbarAndCommandPaletteToElement(<AccountDetailPage />)}
			/>

			{/* Rental Applications */}
			<Route
				path={viewRentalApplicationsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalApplicationsPage />
				)}
			/>
			<Route
				path={viewRentalApplicationDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalApplicationDetailPage />
				)}
			/>

			{/* Rental Application forms */}
			<Route
				path={addRentalApplicationFormURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddRentalApplicationFormPage />
				)}
			/>
			<Route
				path={viewRentalApplicationFormsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalApplicationFormsPage />
				)}
			/>
			<Route
				path={viewRentalApplicationFormDetailURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentalApplicationFormDetailPage />
				)}
			/>

			{/* Property Inspections */}
			<Route
				path={viewPropertyInspectionsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyInspectionsPage />
				)}
			/>
			<Route
				path={viewPropertyInspectionDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyInspectionDetailPage />
				)}
			/>

			{/* Property Inspection forms */}
			<Route
				path={addPropertyInspectionFormURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddPropertyInspectionFormPage />
				)}
			/>
			<Route
				path={viewPropertyInspectionFormsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyInspectionFormsPage />
				)}
			/>
			<Route
				path={viewPropertyInspectionFormDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<PropertyInspectionFormDetailPage />
				)}
			/>

			{/* Inventory Items */}
			<Route
				path={addInventoryItemURL}
				element={addNavbarAndCommandPaletteToElement(<AddInventoryItemPage />)}
			/>
			<Route
				path={viewInventoryItemsURL}
				element={addNavbarAndCommandPaletteToElement(<InventoryItemsPage />)}
			/>
			<Route
				path={viewInventoryItemDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<InventoryItemDetailPage />
				)}
			/>

			{/* Inventory Transactions */}
			<Route
				path={addInventoryTransactionURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddInventoryTransactionPage />
				)}
			/>
			<Route
				path={viewInventoryTransactionsURL}
				element={addNavbarAndCommandPaletteToElement(
					<InventoryTransactionsPage />
				)}
			/>
			<Route
				path={viewInventoryTransactionDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<InventoryTransactionDetailPage />
				)}
			/>

			{/* Leases */}
			<Route
				path={viewLeasesURL}
				element={addNavbarAndCommandPaletteToElement(<LeasesPage />)}
			/>
			<Route
				path={viewLeaseDetailsURL}
				element={addNavbarAndCommandPaletteToElement(<LeaseDetailsPage />)}
			/>
			<Route
				path={viewLeasesExpiringIn60DaysURL}
				element={addNavbarAndCommandPaletteToElement(
					<LeasesExpiringIn60DaysPage />
				)}
			/>
			<Route
				path={viewLeasesExpiringIn120DaysURL}
				element={addNavbarAndCommandPaletteToElement(
					<LeasesExpiringIn120DaysPage />
				)}
			/>

			{/* Bulk Rent Increases */}
			<Route
				path={viewBulkRentIncreaseNoticesURL}
				element={addNavbarAndCommandPaletteToElement(
					<BulkRentIncreaseNoticesPage />
				)}
			/>
			<Route
				path={viewBulkRentIncreaseNoticeDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<BulkRentIncreaseNoticeDetailsPage />
				)}
			/>
			<Route
				path={addBulkRentIncreaseNoticeURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddBulkRentIncreaseNoticePage />
				)}
			/>

			{/* Rent Increases */}
			<Route
				path={viewRentIncreasesURL}
				element={addNavbarAndCommandPaletteToElement(<RentIncreasesPage />)}
			/>
			<Route
				path={viewRentIncreaseDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<RentIncreaseDetailsPage />
				)}
			/>
			<Route
				path={addLeaseURL}
				element={addNavbarAndCommandPaletteToElement(<AddLeasePage />)}
			/>

			{/* Leasing */}
			<Route
				path='/app/leasing/'
				element={addNavbarAndCommandPaletteToElement(<LeasingPage />)}
			/>
			<Route
				path={upcomingVacanciesURL}
				element={addNavbarAndCommandPaletteToElement(<LeasingPage />)}
			/>
			<Route
				path={addRentalUnitListingURL}
				element={addNavbarAndCommandPaletteToElement(<AddListingPage />)}
			/>
			<Route
				path={viewRentalUnitListingsURL}
				element={addNavbarAndCommandPaletteToElement(<ViewListingsPage />)}
			/>

			{/* Accounting */}
			<Route
				path={recordExpenseURL}
				element={addNavbarAndCommandPaletteToElement(<RecordExpensePage />)}
			/>

			{/* Reporting */}
			<Route
				path={generateReportURL}
				element={addNavbarAndCommandPaletteToElement(<GenerateReportPage />)}
			/>

			{/* Scheduled Reports */}
			<Route
				path={addScheduledReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddScheduledReportPage />
				)}
			/>
			<Route
				path={viewScheduledReportsURL}
				element={addNavbarAndCommandPaletteToElement(<ScheduledReportsPage />)}
			/>
			<Route
				path={viewScheduledReportDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<ScheduledReportDetailPage />
				)}
			/>

			<Route
				path={generateIncomeStatementURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateIncomeStatementPage />
				)}
			/>
			<Route
				path={generateEFTPaymentFileURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateEFTPaymentFilePage />
				)}
			/>
			<Route
				path={generateDscrReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateDSCRReportPage />
				)}
			/>
			<Route
				path={generateDscrSensitivityReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateDSCRSensitivityReportPage />
				)}
			/>
			<Route
				path={generateTrialBalanceReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateTrialBalancePage />
				)}
			/>
			<Route
				path={generateTransactionRegisterReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateTransactionRegisterReportPage />
				)}
			/>
			<Route
				path={generateManagementFeeSummaryReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateManagementFeeSummaryPage />
				)}
			/>
			<Route
				path={generateRentRollURL}
				element={addNavbarAndCommandPaletteToElement(<GenerateRentRollPage />)}
			/>
			<Route
				path={generateRentOutstandingReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateRentOutstandingReportPage />
				)}
			/>
			<Route
				path={generateJournalTransactionsReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateJournalTransactionsReportPage />
				)}
			/>
			<Route
				path={generatePOListingReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GeneratePOListingReportPage />
				)}
			/>
			<Route
				path={generateGeneralLedgerReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateGeneralLedgerReportPage />
				)}
			/>
			<Route
				path={generateTimeToLeaseReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateTimeToLeaseReportPage />
				)}
			/>
			<Route
				path={generatePhysicalInventoryReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GeneratePhysicalInventoryReportPage />
				)}
			/>
			<Route
				path={generateRentRollComparisonReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateRentRollComparisonReportPage />
				)}
			/>
			<Route
				path={generateRentLedgerReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateRentLedgerReportPage />
				)}
			/>
			<Route
				path={generateParkingSpaceAssignmentReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateParkingSpaceAssignmentReportPage />
				)}
			/>
			<Route
				path={generateRentDelinquencyReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateRentDelinquencyReportPage />
				)}
			/>
			<Route
				path={generateAgedPayablesReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateAgedPayablesReportPage />
				)}
			/>
			<Route
				path={generateAgedReceivablesReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateAgedReceivablesReportPage />
				)}
			/>
			<Route
				path={generateLossToLeaseReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateLossToLeaseReportPage />
				)}
			/>
			<Route
				path={generateUpcomingRentIncreaseReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateUpcomingRentIncreaseReportPage />
				)}
			/>
			<Route
				path={generateRentReceiptURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateRentReceiptPage />
				)}
			/>
			<Route
				path={generateBalanceSheetURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateBalanceSheetPage />
				)}
			/>
			<Route
				path={generateOpenTasksReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateOpenTasksReportPage />
				)}
			/>
			<Route
				path={generateClosedTasksReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateClosedTasksReportPage />
				)}
			/>
			<Route
				path={generateTaskPerformanceReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateTaskPerformanceReportPage />
				)}
			/>
			<Route
				path={generateServiceTechHoursURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateServiceTechHoursReportPage />
				)}
			/>
			<Route
				path={generateOpenWorkOrdersReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateOpenWorkOrdersReportPage />
				)}
			/>
			<Route
				path={generateClosedWorkOrdersReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateClosedWorkOrdersReportPage />
				)}
			/>
			<Route
				path={generateWorkOrderPerformanceReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateWorkOrderPerformanceReportPage />
				)}
			/>
			<Route
				path={generateWorkOrderSummaryReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateWorkOrderSummaryReportPage />
				)}
			/>
			<Route
				path={generateMoveInMoveOutReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateMoveInMoveOutReportPage />
				)}
			/>
			<Route
				path={generateChartOfAccountsReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<GenerateChartOfAccountsReportPage />
				)}
			/>
			<Route
				path={addInspectionReportURL}
				element={addNavbarAndCommandPaletteToElement(
					<AddInspectionReportPage />
				)}
			/>
			<Route
				path={viewInspectionReportsURL}
				element={addNavbarAndCommandPaletteToElement(<InspectionReportsPage />)}
			/>
			<Route
				path={viewInspectionReportDetailsURL}
				element={addNavbarAndCommandPaletteToElement(
					<InspectionReportDetailsPage />
				)}
			/>
		</Routes>
	)
}
