import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import App from 'App'
import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { createRoot } from 'react-dom/client'

import './index.css'

if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://7c682e588690406a97d713b31448850d@o1336522.ingest.sentry.io/6608427',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	})
}
const MAX_RETRIES = 1

// eslint-disable-next-line import/prefer-default-export
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Number.POSITIVE_INFINITY,
			retry: MAX_RETRIES
		}
	}
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function enableMocking() {
	if (import.meta.env.PROD) {
		return
	}

	const { worker } = await import('./mocks/browser')

	return worker.start()
}

if (!import.meta.env.PROD && !document.querySelector('#root')) {
	const root = document.createElement('div')
	root.setAttribute('id', 'root')
	document.body.appendChild(root)
}

const root = createRoot(document.querySelector('#root')!)

root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} />
			<BrowserRouter>
				<App />
			</BrowserRouter>
			<ToastContainer />
		</QueryClientProvider>
	</StrictMode>
)
